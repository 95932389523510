import { Box, Button, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { HiChevronRight } from "react-icons/hi2";
import CartBoxItem from "./CartBoxItem";
import { CartModeContext } from "../Context/CartContext";

// Called in Line 262 in MarketplaceCollection component
const CartBox = () => {
    const { isCartActive, showCart, ToggleShowCart } =
        useContext(CartModeContext);
    return (
        <Box
            pos={"fixed"}
            right={"0px"}
            top={0}
            bottom={0}
            display={showCart && isCartActive ? "block" : "none"}
            zIndex="1000"
            py="50px"
            bgColor={"#160D1A"}
        >
            <Box w="463px" zIndex={"modal"} px="16px">
                <Flex px="9px" align={"center"} justify={"space-between"}>
                    <Text fontWeight={"900"} fontSize={"30px"}>
                        Your cart
                    </Text>

                    <Icon
                        as={HiChevronRight}
                        cursor={"pointer"}
                        fontSize="20px"
                        onClick={ToggleShowCart}
                    />
                </Flex>
                <Divider borderColor={"#616161"} my="18px" />

                {/* Clear Items Section  */}
                <Flex
                    align="center"
                    justify={"space-between"}
                    fontWeight={"900"}
                    fontSize={"18px"}
                >
                    <Text>3 Items</Text>
                    <Text as="button" fontWeight={"900"}>
                        Clear all
                    </Text>
                </Flex>

                {/* SIngle Cart Box */}

                <Flex
                    mt="30px"
                    flexDir={"column"}
                    gap="30px"
                    h="389px"
                    overflowY={"auto"}
                    className="cart"
                    pr="1rem"
                >
                    {[1, 2, 3, 4, 5, 6].map((item, i) => {
                        return <CartBoxItem key={i} data={item} />;
                    })}
                </Flex>
                {/* Divider */}
                <Divider borderColor={"#616161"} my="18px" />
                {/* TOtal price section  */}

                <Flex justify={"space-between"} gap="20px">
                    <Text fontWeight={"900"} fontSize={"18px"}>
                        Total price
                    </Text>
                    <Box textAlign={"right"}>
                        <Text fontWeight={"900"} fontSize={"18px"}>
                            172 KDA
                        </Text>
                        <Text fontWeight={"400"} fontSize={"14px"}>
                            All fees included
                        </Text>
                    </Box>
                </Flex>

                {/* Button */}

                <Box mt="20px">
                    <Button
                        bgColor={"#BC3CF0"}
                        w="100%"
                        _focus={{}}
                        _active={{}}
                        _hover={{}}
                        rounded={"full"}
                    >
                        Complete purchase
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CartBox;

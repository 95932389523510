import React from "react";
import { MainBody } from "./StyledLayoutComponents";
import Footer from "../CommonComponents/Footer";

const LayoutDefault = ({ children }) => {
    return (
        <MainBody>
            <main className="site-content">
                {children}
                <Footer/>
            </main>
        </MainBody>
    );
};

export default LayoutDefault;

import { Flex, Image, Input } from "@chakra-ui/react";
import React from "react";
import MagnifyingGlass from "../assets/images/MagnifyingGlass.svg";

const SearchInput = ({ placeholder, maxW, fontSize }) => {
    return (
        <Flex
            position="relative"
            w="100%"
            maxW={maxW || "578px"}
            border="1px"
            borderColor="#757575"
            rounded="8px"
        >
            <Image
                src={MagnifyingGlass}
                alt="Magnifying Glass"
                pos="absolute"
                top="50%"
                transform={"auto"}
                translateY="-50%"
                left="0.75rem"
                w="1.5rem"
                h="1.5rem"
            />
            <Input
                minW={"100%"}
                _focus={{}}
                _active={{}}
                _focusVisible={{}}
                _hover={{}}
                border="0px"
                boxShadow={"none"}
                type="text"
                placeholder={
                    placeholder || "Search items, collections, and accounts"
                }
                py="0.75rem"
                fontSize={fontSize || "1rem"}
                fontWeight="500"
                pl="3rem"
                pr="2rem"
            />
        </Flex>
    );
};

export default SearchInput;

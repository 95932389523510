import {
    Box,
    Flex,
    Text,
    Menu,
    Icon,
    MenuButton,
    MenuList,
    Divider,
    MenuItem,
    SimpleGrid,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import ScreenSize from "../layouts/ScreenSize";
import { BsArrowDownUp } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubCollectionCard from "../Cards/SubCollectionCard";
import { useCollectionProviderFactory } from "../CollectionProviders/CollectionProviderFactory";
import { checkIfNullOrUndefined } from "../utils/utils";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import { FiChevronLeft } from "react-icons/fi";
import { db } from "../Firestore/FirestoreService";
import { doc, query } from "@firebase/firestore";
import { useCallback } from "react";
import { useEffect } from "react";
import { MarketplaceContext } from "../MarketplaceContext/MarketplaceContextProvider";
import { useCollectionProvider } from "../CollectionProviders/CollectionProvider";

const ProjectCollectionsPage = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const marketplaceContext = useContext(MarketplaceContext);
    const projectName = projectId.split("-")[0];
    const collectionName = projectId.split("-")[1];
    const collectionProvider = useCollectionProvider(projectName, collectionName);
    const collectionConfig = collectionProvider.getConfig();
    const mintProvider = collectionProvider.getMintProvider();
    const burnNftMap = marketplaceContext?.collectionProvider.getBurnNftsMap();
    const [subCollectionBurnMap, setSubCollectionBurnMap] = useState({});

    const separateBurnMap = useCallback(() => {
        if (!checkIfNullOrUndefined(burnNftMap)) {
            let burn = {};
            burnNftMap?.forEach((nft) => {
                let prefix = nft.split(collectionConfig["nft-id-separator-token"])[0];
                if (checkIfNullOrUndefined(burn[prefix])) {
                    burn[prefix] = [nft];
                } else {
                    burn[prefix].push(nft);
                }
            });
            setSubCollectionBurnMap(burn);
        }
    }, [burnNftMap, setSubCollectionBurnMap]);

    useEffect(() => {
        separateBurnMap();
    }, [separateBurnMap]);

    function handleCollectionCardClick(subCollectionId) {
        navigate(`/marketplace/group/${projectId}/${subCollectionId}`);
    }

    return (
        <Box className="latestDesign">
            <ScreenSize>
                <Box>
                    <Flex
                        align={"center"}
                        className="backBtn"
                        style={{ cursor: "pointer" }}
                        my={["20px", null, "20px", null]}
                        onClick={() => navigate(-1)}
                    >
                        <Icon
                            as={FiChevronLeft}
                            boxSize="1.25rem"
                            display={"inline-block"}
                        />
                        <Text>Back</Text>
                    </Flex>
                    <Text fontWeight="700" fontSize="2.25rem" pt="3.75rem">
                        {collectionConfig && collectionConfig["project-name"]}
                    </Text>

                    <Flex
                        mt="5rem"
                        py="1.5rem"
                        px="1.5rem"
                        bgColor="#160D1A"
                        rounded="1rem"
                        justify="space-between"
                        align="center"
                    >
                        <Flex maxW="40rem" w="100%" align="center">
                            <Text fontSize="1rem" fontWeight="700">
                                {collectionConfig &&
                                    Object.entries(
                                        collectionConfig["child-collection-map"]
                                    ).length}{" "}
                                COLLECTIONS
                            </Text>
                        </Flex>

                        {/* <RecentlyListed /> */}
                    </Flex>
                </Box>

                <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    spacing="1.25rem"
                    className=""
                    mt="5rem"
                >
                    {collectionConfig && Object.entries(
                        collectionConfig["child-collection-map"]
                    ).map(([id, details], index) => {
                        return (
                            <SubCollectionCard
                                type={"live"}
                                key={index}
                                clickable={true}
                                subCollection={details}
                                collectionConfig={collectionConfig}
                                cardCallback={handleCollectionCardClick}
                                subCollectionId={`${collectionConfig["nft-prefix"]}-${id}-1`}
                                burnNfts={subCollectionBurnMap?.[`${collectionConfig["nft-prefix"]}-${id}-1`]}
                                collectionProvider={collectionProvider}
                                mintProvider={mintProvider}
                            />
                        );
                    })}
                </SimpleGrid>
            </ScreenSize>
        </Box>
    );
};

export default ProjectCollectionsPage;

const RecentlyListed = () => {
    const [listedSort, setListedSort] = useState("Recently Listed");
    return (
        <Box flexShrink={0}>
            <Menu>
                <MenuButton
                    as={"div"}
                    display="flex"
                    bgColor="transparent"
                    _focus={{}}
                    _active={{}}
                    _hover={{}}
                    _focusVisible={{}}
                    cursor="pointer"
                    className="dropDown"
                >
                    <Flex align="center" gap="0.75rem" cursor="pointer">
                        <Text fontSize="1rem" fontWeight="900">
                            {listedSort}
                        </Text>

                        <Icon
                            as={BsArrowDownUp}
                            w="1.5rem"
                            h="1.5rem"
                            color="#BC3CF0"
                        />
                    </Flex>
                </MenuButton>

                <MenuList
                    bgColor="#160D1A"
                    minWidth="187px"
                    rounded="16px"
                    border="1px"
                    borderColor="#424242"
                    overflow="hidden"
                    py="0"
                    px="0.75rem"
                >
                    <MenuItem
                        bgColor="#160D1A"
                        className="menuItem"
                        fontWeight="900"
                        _hover={{ color: "#28172E" }}
                        onClick={() => setListedSort("Recently Listed")}
                        maxW={"100%"}
                        h="58px"
                        px="0.75rem"
                        cursor="pointer"
                    >
                        Recently Listed
                    </MenuItem>
                    <Box>
                        <Divider border="1px" borderColor={"#424242"} />
                    </Box>
                    <MenuItem
                        bgColor="#160D1A"
                        className="menuItem"
                        fontWeight="900"
                        _hover={{ color: "#28172E" }}
                        maxW={"100%"}
                        h="58px"
                        px="0.75rem"
                        cursor="pointer"
                    // onClick={() => setListedSort("Price: High to Low")}
                    >
                        Price: High to Low
                    </MenuItem>
                    <Box>
                        <Divider border="1px" borderColor={"#424242"} />
                    </Box>
                    <MenuItem
                        bgColor="#160D1A"
                        className="menuItem"
                        fontWeight="900"
                        _hover={{ color: "#28172E" }}
                        maxW={"100%"}
                        h="58px"
                        px="0.75rem"
                        cursor="pointer"
                    // onClick={() => setListedSort("Price: Low to high")}
                    >
                        Price: Low to high
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

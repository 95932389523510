import {
    Box,
    Flex,
    Button,
    Text,
    Divider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Image,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    SimpleGrid,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScreenSize from "../layouts/ScreenSize";
import { AiFillInfoCircle, AiFillCopy } from "react-icons/ai";
import { GiCheckMark } from "react-icons/gi";
import CollectionStack from "../Cards/CollectionStack";
import { PactContext } from "../pact/PactContextProvider";
import { checkIfNullOrUndefined } from "../utils/utils";
import UserNFTs from "../Cards/UserNFTs";
import { UserContext } from "./UserContextProvider";
import { Back } from "../CommonComponents/BackButton";
import ProfileLoadingBar from "./ProfileLoadingBar";
import { KADCARS_PROJECT_NAME_KEY, KAWAII_K9S_PROJECT_NAME_KEY, STACKED_TYPE } from "../utils/Constants";
import LoadingCard from "../Cards/LoadingCard";

const UserProfile = () => {
    const pactContext = useContext(PactContext);
    const userContext = useContext(UserContext);
    const { userId } = useParams();
    const navigate = useNavigate();

    return (
        <Box className="latestDesign">
            <ScreenSize>
                {userContext.fetchingProgress > 0 && (
                    <ProfileLoadingBar
                        progress={userContext.fetchingProgress}
                    />
                )}
                <Back callback={() => navigate(-1)} />

                <UserDisplay
                    userId={userId}
                    userNftMap={userContext.userNftMap}
                    userCollections={userContext.userCollections}
                    userNftData={userContext.userNftData}
                />

                <Box mt="40px">
                    <ItemsTab
                        loading={userContext.fetchingProgress > 0}
                        userItems={userContext.userItems}
                        userCollections={userContext.userCollections}
                        userNftMap={userContext.userNftMap}
                    />
                </Box>
            </ScreenSize>
        </Box>
    );
};

export default UserProfile;

const UserDisplay = ({ userId, userNftMap, userCollections, userNftData }) => {
    const editedUserId =
        userId?.slice(0, 10) +
        "..." +
        userId?.slice(userId?.length - 6, userId?.length);
    const avatarUserId = editedUserId?.slice(0, 3);

    const [copied, setCopied] = useState(false);
    const [textToCopy, setTextToCopy] = useState(userId && userId);
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        if (!checkIfNullOrUndefined(userNftMap)) {
            let av = getAvatar();
        }
    }, [userNftMap]);

    const handleCopyClick = () => {
        // Create a new text area element to copy text
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // Append the text area to the DOM and select its contents
        document.body.appendChild(textArea);
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the text area from the DOM
        document.body.removeChild(textArea);

        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    function getTotals(type) {
        if (checkIfNullOrUndefined(userNftData)) {
            return 0;
        }
        if (checkIfNullOrUndefined(userNftData[type])) {
            return 0;
        }
        return userNftData[type];
    }

    function getAvatar() {
        // console.log(userCollections)
        // console.log(userNftMap)
    }

    return (
        <Box>
            <Flex
                gap={["40px", null, "80px"]}
                align="center"
                flexDir={["column", "row", "row"]}
            >
                {avatar ? (
                    <Avatar size={"2xl"} src={avatar} />
                ) : (
                    <Flex
                        w="139px"
                        h="148px"
                        minW={"139px"}
                        minH={"148px"}
                        bgColor="#BC3CF0"
                        align="center"
                        justify="center"
                        rounded="full"
                    >
                        <Text fontSize="80px" color="white" fontWeight="900">
                            {avatarUserId}
                        </Text>
                    </Flex>
                )}

                <Box w="100%">
                    <Box mt="23px" mb="50px">
                        <Text
                            maxW="210px"
                            rounded="8px"
                            fontSize="16px"
                            fontWeight={"800"}
                            p="8px"
                            bgcolor="rgba(56, 55, 56, 0.36)"
                            border="1px"
                            borderColor="rgba(189, 61, 240, 0.36)"
                            justifyContent={"space-between"}
                            flexDirection={"row"}
                            display={"flex"}
                        >
                            {editedUserId}

                            {!copied ? (
                                <AiFillCopy
                                    style={{ cursor: "pointer" }}
                                    onClick={handleCopyClick}
                                />
                            ) : (
                                <GiCheckMark
                                    style={{ cursor: "pointer" }}
                                    onClick={handleCopyClick}
                                />
                            )}
                        </Text>
                    </Box>
                    <Box>
                        <Flex
                            align="center"
                            justify={"space-between"}
                            maxW="713px"
                            w="100%"
                            gap="20px"
                            flexWrap={"wrap"}
                        >
                            <Flex gap="10px" align="center">
                                <Text fontSize="12px" fontWeight="400">
                                    TOTAL ITEMS:
                                </Text>
                                <Text fontSize="32px" fontWeight="700">
                                    {getTotals("total")}
                                </Text>
                            </Flex>

                            <Flex gap="10px" align="center">
                                <Text fontSize="12px" fontWeight="400">
                                    TOTAL UNLISTED:
                                </Text>
                                <Text fontSize="32px" fontWeight="700">
                                    {getTotals("unlisted")}
                                </Text>
                            </Flex>

                            <Flex gap="10px" align="center">
                                <Text fontSize="12px" fontWeight="400">
                                    LISTED ITEMS:
                                </Text>
                                <Text fontSize="32px" fontWeight="700">
                                    {getTotals("listed")}
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

const ItemsTab = ({ loading, userItems, userCollections, userNftMap }) => {
    const [activeTab, setActiveTab] = useState("My Items");

    return (
        <Tabs variant="unstyled" pos="relative">
            <TabList>
                <Tab
                    _selected={{
                        color: "#BC3CF0",
                        borderBottom: "4px",
                        borderBottomColor: "#BC3CF0",
                    }}
                    fontWeight="700"
                    fontSize="16px"
                    w="218px"
                    mr="16px"
                    onClick={() => setActiveTab("My Items")}
                >
                    My items
                </Tab>
                <Tab
                    _selected={{
                        color: "#BC3CF0",
                        borderBottom: "4px",
                        borderBottomColor: "#BC3CF0",
                    }}
                    fontWeight="700"
                    fontSize="16px"
                    w="218px"
                    mr="16px"
                    onClick={() => setActiveTab("Listed Items")}
                >
                    Listed Items
                </Tab>
                {/* <Tab
                    _selected={{
                        color: "#BC3CF0",
                        borderBottom: "4px",
                        borderBottomColor: "#BC3CF0",
                    }}
                    fontWeight="700"
                    fontSize="16px"
                    w="218px"
                    mr="16px"
                    onClick={() => setActiveTab("Activity")}
                >
                    Activities
                </Tab> */}
            </TabList>

            <TabPanels>
                <TabPanel>
                    <MyItems
                        loading={loading}
                        header={activeTab}
                        category={"all"}
                        userItems={userItems}
                        userCollections={userCollections}
                        userNftMap={userNftMap}
                    />
                </TabPanel>
                <TabPanel>
                    <MyItems
                        loading={loading}
                        header={activeTab}
                        category={"listed"}
                        userItems={userItems}
                        userCollections={userCollections}
                        userNftMap={userNftMap}
                    />
                </TabPanel>
                {/* <TabPanel>
                    <Activities />
                </TabPanel> */}
            </TabPanels>
        </Tabs>
    );
};

const MyItems = ({ loading, header, userItems, category, userCollections, userNftMap }) => {
    const [selectedCollection, setSelectedCollection] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedCollection(null);
        // console.log(header)
        // console.log(userCollections)
    }, [header, category]);

    function handleCollectionClick(collectionConfig) {
        let collectionName = collectionConfig["path"];

        if (checkIfNullOrUndefined(userNftMap)) {
            return;
        }

        let allNfts = Object.entries(userNftMap[collectionName]).map(
            ([nftId, nftData]) => {
                return nftData;
            }
        );
        let clickedCollection = {
            collectionConfig: collectionConfig,
            nfts: allNfts,
            listedNfts: allNfts.filter((nft) => {
                return nft["status"] === "listed";
            }),
        };
        setSelectedCollection(clickedCollection);
    }

    function handleNftCardClick(nftData) {
        const collectionConfig = selectedCollection["collectionConfig"];
        const projectName = collectionConfig["policy-info"]["project-name"];
        const collectionName = collectionConfig["policy-info"]["collection-name"];

        if (collectionConfig["policy-info"]["collection-name"] !== KAWAII_K9S_PROJECT_NAME_KEY) {
            if (collectionConfig["standard"] === STACKED_TYPE) {
                let prefix = nftData["nft-id"].split(collectionConfig["nft-id-separator-token"])[0];
                let num = nftData["nft-id"].split(collectionConfig["nft-id-separator-token"])[1];

                navigate(`/listNft/${projectName}-${collectionName}/${prefix}/${num}`);
            } else if (collectionConfig["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY) {
                navigate(`/listNft/${projectName}-${collectionName}/${nftData["token-id"]}`)
            } else {
                navigate(`/listNft/${projectName}-${collectionName}/${nftData["nft-id"]}`);
            }
        }
    }

    return (
        <Box>
            <Flex align="center" gap="40px" justify="space-between" mt="40px">
                <Text fontWeight="700" fontSize="36px">
                    {header}
                </Text>
                <Box>
                    <Flex align="center" gap="10px" cursor="pointer">
                        {/* <Text fontSize="20px" fontWeight="500">
                            Recently Added
                        </Text>{" "}
                        <FiChevronDown /> */}
                    </Flex>
                </Box>
            </Flex>
            <Divider my="40px" border="1px" borderColor="#5E1E78" />
            {selectedCollection === null ? (
                <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    spacing="1.25rem"
                    className=""
                >
                    {userItems && userCollections[category] &&
                        Object.entries(userCollections[category]).map(
                            ([collName, collectionConfig], index) => {
                                if (
                                    !checkIfNullOrUndefined(userItems[collectionConfig["policy-info"]["collection-name"]]) &&
                                    userItems[collectionConfig["policy-info"]["collection-name"]].length > 0
                                ) {
                                    return (
                                        <Flex
                                            key={index}
                                            padding={"11px"}
                                            paddingBottom={"5"}
                                        >
                                            <CollectionStack
                                                key={index}
                                                collectionConfig={
                                                    collectionConfig
                                                }
                                                collectionName={
                                                    collectionConfig[
                                                    "collection-name"
                                                    ]
                                                }
                                                nftImage={
                                                    collectionConfig[
                                                    "collection_thumbnail_image"
                                                    ]
                                                }
                                                nftCount={
                                                    userItems[
                                                        collectionConfig[
                                                        "policy-info"
                                                        ]["collection-name"]
                                                    ].length
                                                }
                                                cardCallback={() =>
                                                    handleCollectionClick(
                                                        collectionConfig
                                                    )
                                                }
                                            />
                                        </Flex>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                    {loading && <LoadingCard />}
                    {loading && <LoadingCard />}
                    {loading && <LoadingCard />}
                </SimpleGrid>
            ) : (
                <Box mt="48px">
                    <Back callback={() => setSelectedCollection(null)} />
                    <UserNFTs
                        header={null}
                        onCardClickCallback={handleNftCardClick}
                        collectionName={
                            selectedCollection["collectionConfig"]["collection-name"]
                        }
                        userNftList={
                            header === "My Items"
                                ? selectedCollection["nfts"]
                                : selectedCollection["listedNfts"]
                        }
                    />
                    <Box mt="50px" align="right">
                        <Button
                            _hover={{}}
                            _active={{}}
                            focus={{}}
                            bgColor="#BC3CF0"
                            rounded="full"
                            color="white"
                            w="100%"
                            maxW="329px"
                            onClick={() => setSelectedCollection(null)}
                        >
                            View your Collections
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const Activities = () => {
    return (
        <Box>
            {!true ? (
                <Box>
                    <Flex align="center" justify="center">
                        <Box>
                            <Text
                                fontWeight="700"
                                fontSize="
                        28px"
                                mb="10px"
                            >
                                No Activity yet
                            </Text>
                            <Box>
                                <Image src={File} alt="file" />
                            </Box>
                        </Box>
                    </Flex>
                    <Divider my="40px" border="1px" borderColor="#5E1E78" />
                </Box>
            ) : (
                <>
                    <Divider my="40px" border="1px" borderColor="#5E1E78" />
                    <Box py="10px">
                        <Flex align="center" gap="10px" p="10px">
                            <Text fontWeight="700" fontSize="28px">
                                Pending transactions
                            </Text>
                            <AiFillInfoCircle color="#BC3CF0" fontSize="19px" />
                        </Flex>
                        <Box mt="30px">
                            <PendingTransaction tableData={PendingTableData} />
                        </Box>
                        <Box mt="40px">
                            <Text fontWeight="700" fontSize="28px" mt="10px">
                                Transactions
                            </Text>

                            <TransactionsTable tableData={transactionsData} />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};
// componetnts under  Activities
const PendingTableData = [
    {
        status: "Completed",
        price: "12.643",
        from: "Chain 5",
        to: "Chain 8",
    },
    {
        status: "pending",
        price: "12.643",
        from: "Chain 5",
        to: "Chain 8",
    },
    {
        status: "pending",
        price: "12.643",
        from: "Chain 5",
        to: "Chain 8",
    },
];
const PendingTransaction = ({ tableData }) => {
    const tableHead = ["From", "To", "Amount", "Status"];
    return (
        <Box
            mt="20px"
            border="1px"
            borderColor="#5E1E78"
            rounded={"10px"}
            overflow={"hidden"}
            maxW="692px"
        >
            <Table
            // variant="stripe"
            >
                <Thead px="16px">
                    <Tr>
                        {tableHead &&
                            tableHead.map((item, i) => {
                                return (
                                    <Th
                                        key={i}
                                        pl="16px"
                                        py="14px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        color="white"
                                        borderBottomColor={"#5E1E78"}
                                    >
                                        {item}
                                    </Th>
                                );
                            })}
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.map((event, index) => (
                        <Tr key={index} _odd={{ bgColor: "#160D1A" }}>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                            >
                                {event.from}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                            >
                                {event.to}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                            >
                                {`${event.price} KDA`}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={
                                    event.status === "Completed"
                                        ? "#00994F"
                                        : "white"
                                }
                            >
                                {event.status}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};
const TransactionsTable = ({ tableData }) => {
    const tableHead = [
        "Image",
        "Name",
        "Transaction type",
        "Seller",
        "Buyer",
        "Total",
        "Time",
    ];
    return (
        <Box
            mt="20px"
            border="1px"
            borderColor="#5E1E78"
            rounded={"10px"}
            overflow={"hidden"}
        // maxW="692px"
        >
            <Table
                // variant="stripe"
                overflowX={"auto"}
            >
                <Thead px="16px">
                    <Tr>
                        {tableHead &&
                            tableHead.map((item, i) => {
                                return (
                                    <Th
                                        key={i}
                                        pl="16px"
                                        py="14px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        color="white"
                                        borderBottomColor={"#5E1E78"}
                                    >
                                        {item}
                                    </Th>
                                );
                            })}
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.map((event, index) => (
                        <Tr key={index} _odd={{ bgColor: "#160D1A" }}>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                            >
                                {event.image}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                            >
                                {event.name}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                            >
                                {`${event.transactionType}`}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={
                                    event.status === "Completed"
                                        ? "#00994F"
                                        : "white"
                                }
                            >
                                {event.seller}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={
                                    event.status === "Completed"
                                        ? "#00994F"
                                        : "white"
                                }
                            >
                                {event.buyer}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={
                                    event.status === "Completed"
                                        ? "#00994F"
                                        : "white"
                                }
                            >
                                {event.total}
                            </Td>
                            <Td
                                px="16px"
                                py="14px"
                                border={"none"}
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={
                                    event.status === "Completed"
                                        ? "#00994F"
                                        : "white"
                                }
                            >
                                {event.time}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

const transactionsData = [
    {
        image: "IMAGE",
        name: "KADCAR 22",
        transactionType: "LISTING",
        seller: "GFKYGF...SFD",
        buyer: "",
        total: "54",
        time: "1 MINUTE AGO",
    },
    {
        image: "IMAGE",
        name: "KADCAR 22",
        transactionType: "LISTING",
        seller: "GFKYGF...SFD",
        buyer: "GRHRG...RHT",
        total: "54",
        time: "1 MINUTE AGO",
    },
    {
        image: "IMAGE",
        name: "KADCAR 22",
        transactionType: "LISTING",
        seller: "GFKYGF...SFD",
        buyer: "GRHRG...RHT",
        total: "54",
        time: "1 MINUTE AGO",
    },
];

function getContractMeta(collection) {
    let contractMeta = {};

    switch (collection) {
        case "secret_garden_of_kadena":
            contractMeta["code"] = `(free.secret-garden-of-kadena-gen-1-nfts.`;
            contractMeta["policy"] = `free.secret-garden-of-kadena-gen-1-nfts`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "sgk-gen-1";
            contractMeta["project-name"] = "secret_garden_of_kadena";
            contractMeta["collection-name"] = "secret_garden_of_kadena";
            contractMeta["base_url"] = "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/";
            contractMeta["separator-token"] = ":";
            contractMeta["total_supply"] = 4100
            break;
        case "secret_garden_of_kadena_gen_0":
            contractMeta["code"] = `(n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-gen-0-policy.`;
            contractMeta["policy"] = `n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-gen-0-policy`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "sgk-gen-0";
            contractMeta["project-name"] = "secret_garden_of_kadena";
            contractMeta["collection-name"] = "secret_garden_of_kadena_gen_0";
            contractMeta["base_url"] = "https://arkade-prod.s3.us-east-1.amazonaws.com/secret-garden-of-kadena/";
            contractMeta["separator-token"] = ":";
            contractMeta["total_supply"] = 1050
            break;
        case "pit_bull_bushido":
            contractMeta["code"] = `(free.pit-bull-bushido-policy.`;
            contractMeta["policy"] = `free.pit-bull-bushido-policy`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "pit-bull-bushido";
            contractMeta["project-name"] = "islands_of_damaya";
            contractMeta["collection-name"] = "pit_bull_bushido";
            contractMeta["base_url"] = "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/";
            contractMeta["separator-token"] = " #";
            contractMeta["total_supply"] = 2209
            break;
        case "boxing_badger":
            contractMeta["code"] = `(free.boxing-badger-policy.`;
            contractMeta["policy"] = `free.boxing-badger-policy`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "boxing-badger";
            contractMeta["separator-token"] = " #";
            contractMeta["total_supply"] = 2600;
            contractMeta["project-name"] = "boxing_badger";
            contractMeta["collection-name"] = "boxing_badger";
            break;
        case "sgk_battle_heroes":
            contractMeta["code"] = `(n_7d47538766e6f80008f253dccd30451f4d483c38.battle-heroes-nft-policy.`;
            contractMeta["policy"] = `n_7d47538766e6f80008f253dccd30451f4d483c38.battle-heroes-nft-policy`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "battle-heroes";
            contractMeta["project-name"] = "secret_garden_of_kadena";
            contractMeta["collection-name"] = "sgk_battle_heroes";
            contractMeta["base_url"] = "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/";
            contractMeta["separator-token"] = ":";
            break;
        case "isoko_test_collection":
            contractMeta["code"] = `(n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-battle-heroes-policy.`;
            contractMeta["policy"] = `n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-battle-heroes-policy`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "sgk-battle-heroes";
            contractMeta["project-name"] = "isoko_test_collections";
            contractMeta["collection-name"] = "isoko_test_collection";
            contractMeta["base_url"] = "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/";
            contractMeta["separator-token"] = ":";
            contractMeta["has-number-id"] = true;
        case "isoko_test_stacked_collection":
            contractMeta["code"] = `(n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-battle-heroes-policy.`;
            contractMeta["policy"] = `n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-battle-heroes-policy`;
            contractMeta["chainId"] = "8";
            contractMeta["batch-size"] = 500;
            contractMeta["name-prefix"] = "sgk-battle-heroes";
            contractMeta["project-name"] = "isoko_test_stacked_collections";
            contractMeta["collection-name"] = "isoko_test_stacked_collection";
            contractMeta["base_url"] = "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/";
            contractMeta["separator-token"] = ":";
            contractMeta["has-number-id"] = true;
        case "sgk_weapons":
            contractMeta["batch-size"] = 2000;
        default:
            break;
    }

    return contractMeta;
}

export {
    getContractMeta
};

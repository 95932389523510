import { useContext, useEffect } from "react";
import { PactContext } from "../pact/PactContextProvider";
import {
    buildExecCommand,
    getNetworkUrlWithChainId,
    pollForTransaction,
} from "../pact/PactUtils";
import Pact from "pact-lang-api";
import {
    CONNECTED_WALLET_TYPE,
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_LIMIT_X_CHAIN_CONT,
    DEFAULT_GAS_LIMIT_X_CHAIN_EXEC,
    DEFAULT_GAS_PRICE,
    DEFAULT_GAS_PRICE_X_CHAIN_CONT,
    DEFAULT_GAS_PRICE_X_CHAIN_EXEC,
    DEFAULT_REQUEST_HEADERS,
    ECKO_WALLET_NAME,
    ISOKO_CONTRACTS,
    ISOKO_GAS_STATION,
    KADENA_SIGNING_API_URL,
    LOCAL_ACCOUNT_KEY,
    LOCAL_GAS_STATION_KEY,
    NETWORK_ID,
    POST_METHOD,
    XCHAIN_COMMANDS,
} from "../utils/Constants";
import { quickSignEcko } from "../kadenaInteraction/KadenaApi";
import { toast } from "react-toastify";
import {
    checkIfFundsOnChainAreSufficient,
    confirmTransactionWithNetwork,
    getTimestamp,
    logLine,
    mkReq,
    parseResponse,
    tryLoadLocal,
    validateGasStationAvailabilityOnChain,
    wait,
} from "../utils/utils";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import {
    getBalanceForAllChains,
    getBalanceForChain,
    getChainsWithFundsToTransfer,
} from "./CrossChainBalanceUtils";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { CrossChainContext } from "./CrossChainContextProvider";
import {
    EZ_BUY_CURRENT_STEP,
    EZ_BUY_DONE_STEP,
    EZ_BUY_FAIL_STEP,
} from "./EZBuyProgressBar";
import { useQuickSign } from "../pact/QuickSignUtils";
import { addEzBuyCapabilityToBusinessTransaction, addGasStationCapability, buildListOfExecCmdsForXChainFundTransfer, getIsInsufficientBalanceErrorWithSufficientBalance } from "./EzBuyHelpers";

const GAS_STATION = "746d0601603d1cc907ae82fed1c4bdf3";
const XCHAIN_CMD_TRACKING_STRUCTURE = {
    exec: [],
    cont: [],
    business: [],
};

const CONT_TX_TYPE = "cont";
const EXEC_TX_TYPE = "exec";
const BUSINESS_TX_TYPE = "business";
const TX_PENDING = "pending";
const TX_COMPLETE = "complete";
const TX_NOT_STARTED = "not started";
const TX_FULFILLED = "fulfilled";
const TX_REJECTED = "rejected";
const CONTINUED_ATTRIBUTE = "continued";
const SIGNED_QUICKBUY_COMMANDS = "SIGNED_QUICKBUY_COMMANDS";
const CREATE_ACCOUNT_KEY = "CREATE_ACCOUNT_KEY";

let triggerSpv = {};

const useQuickBuy = () => {
    const isokoContext = useContext(IsokoDialogContext);
    const crossChainContext = useContext(CrossChainContext);
    const quickSignUtil = useQuickSign();
    const {
        account,
        isEckoWallet,
        sendTransaction,
        pollForAccountBalanceDataOnAllChains,
    } = useContext(PactContext);
    const [crossChainTransactions, setCrossChainTransactions, getCrossChainTransactions, removeCrossChainTransactions] = useLocalStorage(`${XCHAIN_COMMANDS}`, null);
    const [signedCommands, setSignedCommands, getSignedCommands, removeSignedCommands] = useLocalStorage(`${SIGNED_QUICKBUY_COMMANDS}`, null);
    const [needCreateAccount, setNeedCreateAccount, getNeedCreateAccount] = useLocalStorage(CREATE_ACCOUNT_KEY, false);

    useEffect(() => {
        if (crossChainTransactions && signedCommands && Object.keys(crossChainTransactions).length > 0) {
            console.log("Beginning of useffect loop");
            console.log(crossChainTransactions);
            console.log(signedCommands);
            Object.entries(crossChainTransactions).forEach(
                ([timeStampKey, cmdsAtTimeStamp]) => {
                    if (cmdsAtTimeStamp) {
                        // updateProgressBarSteps(EXEC_TX_TYPE, timeStampKey);
                        // updateProgressBarSteps(CONT_TX_TYPE, timeStampKey);

                        let readyForBusiness = checkIfCrossChainTransactionReadyForBusiness(cmdsAtTimeStamp);
                        let xChainTxComplete = checkIfCrossChainTransactionComplete(cmdsAtTimeStamp);

                        if (readyForBusiness && signedCommands[timeStampKey]?.length > 0) {
                            executeBusinessTransactions(timeStampKey);
                            return;
                        } else if (xChainTxComplete && signedCommands[timeStampKey]?.length > 0) {
                            onQuickbuyTransactionFinish(timeStampKey);
                            return;
                        } else {
                            if (cmdsAtTimeStamp[EXEC_TX_TYPE]) {
                                for (let i = 0; i < cmdsAtTimeStamp[EXEC_TX_TYPE].length; i++) {
                                    let cmdObj = cmdsAtTimeStamp[EXEC_TX_TYPE][i];
                                    if (cmdObj["status"] === TX_PENDING && triggerSpv[cmdObj.requestKey] !== true) {
                                        triggerSpv[cmdObj.requestKey] = true;
                                        getSpv(cmdObj, timeStampKey);
                                    }
                                }
                            }
                            if (cmdsAtTimeStamp[CONT_TX_TYPE] && cmdsAtTimeStamp[CONT_TX_TYPE].length > 0) {
                                for (let i = 0; i < cmdsAtTimeStamp[CONT_TX_TYPE].length; i++) {
                                    let cmdObj = cmdsAtTimeStamp[CONT_TX_TYPE][i];

                                    if (cmdObj["status"] === TX_PENDING && triggerSpv[cmdObj.requestKey + "_cont"] !== true) {
                                        triggerSpv[cmdObj.requestKey + "_cont"] = true;
                                        // pollMultipleTransactions(cmdsAtTimeStamp[CONT_TX_TYPE], CONT_TX_TYPE, timeStampKey);
                                        pollTxAndUpdateLocalStorage(cmdObj, CONT_TX_TYPE, timeStampKey);
                                    }
                                }
                            }
                            if (cmdsAtTimeStamp[BUSINESS_TX_TYPE] && cmdsAtTimeStamp[BUSINESS_TX_TYPE].length > 0) {
                                for (let i = 0; i < cmdsAtTimeStamp[BUSINESS_TX_TYPE].length; i++) {
                                    let cmdObj = cmdsAtTimeStamp[BUSINESS_TX_TYPE][i];

                                    if (cmdObj["status"] === TX_PENDING && triggerSpv[cmdObj.requestKey + "_business"] !== true) {
                                        triggerSpv[cmdObj.requestKey + "_business"] = true;
                                        // pollMultipleTransactions(cmdsAtTimeStamp[BUSINESS_TX_TYPE], BUSINESS_TX_TYPE, timeStampKey);
                                        pollTxAndUpdateLocalStorage(cmdObj, BUSINESS_TX_TYPE, timeStampKey);
                                    }
                                }
                            }
                        }
                    }
                }
            );
        }
    }, [crossChainTransactions]);

    async function shouldExecuteQuickBuy(businessTxDetails, priceToPay = null, targetChainId = null, transactionCompleteCallback = null) {
        // let gasPrice = businessTxDetails["cmd"].gasPrice;
        // let gasLimit = businessTxDetails["cmd"].gasLimit;
        // let costOfGas = gasPrice * gasLimit;
        let cmdType = businessTxDetails.cmdType;
        let isExec = cmdType === EXEC_TX_TYPE;
        let isCont = cmdType === CONT_TX_TYPE;
        let costOfGas = 0.003;
        let transactionInProgress = await checkIfQuickBuyInProgress();
        let gasStationAvailable = await validateGasStationAvailabilityOnChain(ISOKO_GAS_STATION, targetChainId);
        console.log(gasStationAvailable)
        if (gasStationAvailable) {
            costOfGas = 0.0;
        }
        let hasEnoughFunds = checkIfFundsOnChainAreSufficient(priceToPay, targetChainId, costOfGas);
        console.log(hasEnoughFunds)
        if (transactionInProgress) {
            return;
        }
        if (!hasEnoughFunds) {
            crossChainContext.setQuickBuyErrorMessage(`Not Enough Funds on Chain ${targetChainId}`);
            crossChainContext.onOpenFailedQuickBuyModal();
            console.log("NOT ENOUGH")
            // buildBusinessTransactionForEzBuy(businessTxDetails, targetChainId, gasStationAvailable);
            // prepareCommandsForEzBuy(priceToPay, targetChainId, businessTxDetails, costOfGas);
            // crossChainContext.setEzBuyHeader("Use EZ-Buy");
        } else {
            let newCmdGasCap = null;
            if (gasStationAvailable) {
                newCmdGasCap = addGasStationCapability(account, businessTxDetails);
                businessTxDetails["cmd"] = newCmdGasCap["newCmd"];
            }
            if (isExec) {
                sendTransaction(
                    businessTxDetails["cmd"],
                    businessTxDetails["previewContent"],
                    businessTxDetails["transactionMessage"],
                    businessTxDetails["callback"],
                    businessTxDetails["errorHandler"],
                    businessTxDetails["errorMessage"],
                    businessTxDetails["shouldSign"]
                );
            } else {
                let sendSignedResponse = await quickSignUtil.quickSignAndSend(businessTxDetails["cmd"]);
                transactionCompleteCallback && transactionCompleteCallback(sendSignedResponse);
            }
        }
    }

    async function prepareCommandsForEzBuy(itemPrice, targetChainId, businessTxDetails, costOfGas) {
        crossChainContext.changeTransactionStepData(0, "status", EZ_BUY_CURRENT_STEP);
        console.log(itemPrice)
        let priceWithGas = costOfGas + itemPrice;
        console.log(businessTxDetails);
        console.log("CURRENT: " + account.chainMap[parseInt(targetChainId)].accountData.balance);
        console.log("TOTAL PRICE: " + priceWithGas);

        let createAccountCmd = await createAccountOnChainCmd(targetChainId);

        //TODO: remove redundant check
        if (account.chainMap[parseInt(targetChainId)].accountData.balance < priceWithGas) {
            let cmdList = null;
            let mintCmd = null;
            let chainsWithFunds = await getChainsWithFundsToTransfer(targetChainId, priceWithGas);

            if (!chainsWithFunds) {
                crossChainContext.setQuickBuyErrorMessage("Not enough funds found across all chains");
                crossChainContext.onOpenFailedQuickBuyModal();
                return;
            }
            console.log(chainsWithFunds);

            let gasInvalid = await validateGasStationAvailabilityForEzBuy(GAS_STATION, chainsWithFunds, businessTxDetails);
            crossChainContext.setChainsWithFunds(chainsWithFunds);
            if (gasInvalid) {
                console.log(gasInvalid);
                crossChainContext.setQuickBuyErrorMessage(gasInvalid);
                crossChainContext.onOpenFailedQuickBuyModal();
                return;
            }

            let transferDetails = Object.entries(chainsWithFunds).map(
                ([chainId, balance]) => {
                    return `${balance * 1} KDA from Chain ${chainId}`;
                }
            );
            console.log(transferDetails);
            crossChainContext.setQuickBuyTargetChainId(targetChainId);
            crossChainContext.setQuickBuyTransferDetails(transferDetails);
            addEzBuyCapabilityToBusinessTransaction(account, businessTxDetails, targetChainId);

            chainsWithFunds ? cmdList = buildListOfExecCmdsForXChainFundTransfer(account, chainsWithFunds, targetChainId) : console.log("insufficient funds");

            if (createAccountCmd.status === "success") {
                if (businessTxDetails["cmdType"] === EXEC_TX_TYPE) {
                    crossChainContext.setQuickSignCmdMap({
                        ...isokoContext.quickSignCmdMap,
                        createAccountCmd: [createAccountCmd["data"]],
                        transferCmds: cmdList,
                        businessCmds: [businessTxDetails["bzCommand"]]
                    });
                } else {
                    let callbackCmds = [];
                    if (businessTxDetails["ezBuyCommand"]) {
                        callbackCmds.push(businessTxDetails["ezBuyCommand"]);
                    }
                    callbackCmds.push(businessTxDetails["marketFeeCommand"]);
                    crossChainContext.setQuickSignCmdMap({
                        ...isokoContext.quickSignCmdMap,
                        createAccountCmd: [createAccountCmd["data"]],
                        transferCmds: cmdList,
                        callbackCmds: callbackCmds,
                        businessCmds: [businessTxDetails["bzCommand"]]
                    });
                }
                setNeedCreateAccount(true);
                console.log(createAccountCmd);
            } else {
                if (businessTxDetails["cmdType"] === EXEC_TX_TYPE) {
                    crossChainContext.setQuickSignCmdMap({
                        ...isokoContext.quickSignCmdMap,
                        transferCmds: cmdList,
                        businessCmds: [businessTxDetails["bzCommand"]]
                    });
                } else {
                    let callbackCmds = [];
                    if (businessTxDetails["ezBuyCommand"]) {
                        callbackCmds.push(businessTxDetails["ezBuyCommand"]);
                    }
                    callbackCmds.push(businessTxDetails["marketFeeCommand"]);
                    crossChainContext.setQuickSignCmdMap({
                        ...isokoContext.quickSignCmdMap,
                        transferCmds: cmdList,
                        callbackCmds: callbackCmds,
                        businessCmds: [businessTxDetails["bzCommand"]]
                    });
                }
            }

            crossChainContext.onOpenQuickBuyModal();
            crossChainContext.setShowEzBarProgress(true);
            crossChainContext.changeTransactionStepData(0, "status", EZ_BUY_DONE_STEP);
        }
    }

    /*
     *********************************
     **** Build Quickbuy Commands ****
     *********************************
     */
    async function createAccountOnChainCmd(targetChainId) {
        let chainResult = await getBalanceForChain(
            account.account,
            targetChainId
        );

        if (chainResult === null) {
            let pactCode = `(coin.create-account "${account.account}" (read-keyset "user-ks"))`;

            let envData = {
                "user-ks": account.accountGuard,
                account: account.account,
            };

            let clist = [
                Pact.lang.mkCap(
                    "Create Account",
                    "Create account on chain",
                    `${ISOKO_CONTRACTS}.VALID-ACC-GUARD`,
                    [account.account, account.accountGuard]
                )
            ]

            let execCommand = buildExecCommand(
                pactCode,
                targetChainId,
                GAS_STATION,
                account.accountGuard.keys[0],
                envData,
                clist,
                DEFAULT_GAS_PRICE_X_CHAIN_EXEC,
                DEFAULT_GAS_LIMIT_X_CHAIN_EXEC
            );
            execCommand["payload"]["exec"]["code"] = pactCode;

            let cmdPubKeyObj = {
                cmd: execCommand,
                pubKey: account.accountGuard.keys[0],
            };

            chainResult = {
                status: "success",
                data: cmdPubKeyObj,
            };
        } else {
            chainResult = {
                status: "failure",
                data: null,
            };
        }

        return chainResult;
    }

    /*
     ********************************************
     **** Sign and Execute Quickbuy Commands ****
     ********************************************
     */

    //TODO: dont overwrite localstorage states, add to them
    async function executeQuickSignAndTx(targetChainId, businessTxList) {
        crossChainContext.changeTransactionStepData(1, "status", EZ_BUY_CURRENT_STEP);
        let timeStamp = getTimestamp();

        let crossChainObjectStructure = {};
        crossChainObjectStructure[timeStamp] = { exec: [], cont: [], business: [], callbacks: [] };

        let hasCreateAccount = await getNeedCreateAccount();
        let signedCmdListMap = await signEzBuyCommands(timeStamp, hasCreateAccount);
        let signedCmdList = signedCmdListMap["signedCmds"];

        console.log(hasCreateAccount);
        console.log(signedCmdList);

        if (signedCmdList === undefined || signedCmdList?.length < 1) {
            crossChainContext.changeTransactionStepData(1, "status", EZ_BUY_FAIL_STEP);
            clearTransactionFromLocalStorage(timeStamp);
            toast.error("Please make sure your wallet is connected properly");
            return;
        }

        let signedMintCmd = [signedCmdList[signedCmdList.length - 1]];
        let signedCommandsObject = { ...signedCommands };
        signedCommandsObject[timeStamp] = signedCmdList;
        setSignedCommands(signedCommandsObject);

        crossChainContext.changeTransactionStepData(1, "status", EZ_BUY_DONE_STEP);

        //Create account here
        if (needCreateAccount) {
            let createAccountRequestKey = await executeCreateAccountTransaction(signedCmdList, targetChainId, timeStamp);
            console.log(createAccountRequestKey);
            let reqDataObj = {
                requestKey: createAccountRequestKey["requestKeys"][0],
                targetChainId: targetChainId,
            };
            let pollRes = await pollForTransaction(reqDataObj);
            if (pollRes && pollRes?.result.status !== "success") {
                crossChainContext.setQuickBuyErrorMessage(`QuickSign failed, Create Account Failed, ${pollRes.result?.error?.message}`);
                crossChainContext.onOpenFailedQuickBuyModal();
                clearTransactionFromLocalStorage();
            }
        }

        let listOfXChainRequestDataObjects = await sendExecXChainCmdsToTransferFunds(signedCmdList, targetChainId, timeStamp, needCreateAccount);
        if (listOfXChainRequestDataObjects === null) {
            return;
        }
        crossChainObjectStructure[timeStamp]["exec"] = listOfXChainRequestDataObjects;
        crossChainObjectStructure[timeStamp]["callbacks"] = signedCmdListMap["signedCmdCallbacks"];
        setCrossChainTransactions(crossChainObjectStructure);

        crossChainContext.changeTransactionStepData(2, "status", EZ_BUY_CURRENT_STEP);
        crossChainContext.changeTransactionStepData(2, "label", `Transferring funds (0/${crossChainObjectStructure[timeStamp].exec.length})`);
        crossChainContext.changeTransactionStepData(3, "label", `Continuing funds (0/${crossChainObjectStructure[timeStamp].exec.length})`);
    }

    async function signEzBuyCommands(timeStamp, hasCreateAccount = false) {
        let date = new Date(timeStamp);
        let signedCmdList = [];
        let quickSignResult = {
            status: "failure",
            data: "not initiated",
        };
        let numCallbacks = 0;
        let cmdsToSign = [];
        console.log(crossChainContext.quickSignCmdMap["transferCmds"]);


        if (hasCreateAccount) {
            cmdsToSign = [
                ...crossChainContext.quickSignCmdMap["createAccountCmd"],
                ...crossChainContext.quickSignCmdMap["transferCmds"],
                ...crossChainContext.quickSignCmdMap["businessCmds"]
            ];
        } else {
            cmdsToSign = [
                ...crossChainContext.quickSignCmdMap["transferCmds"],
                ...crossChainContext.quickSignCmdMap["businessCmds"]
            ];
        }
        if (crossChainContext.quickSignCmdMap["callbackCmds"]) {
            cmdsToSign = [
                ...cmdsToSign,
                ...crossChainContext.quickSignCmdMap["callbackCmds"],
            ];
            numCallbacks = crossChainContext.quickSignCmdMap["callbackCmds"].length;
        }

        let quickSignCallbacksResult = null;
        let signedCallbackCmdList = [];
        let walletType = tryLoadLocal(CONNECTED_WALLET_TYPE);
        // if (isEckoWallet) {
        if (walletType === ECKO_WALLET_NAME) {
            quickSignResult = await quickSignEcko(cmdsToSign);
        } else {
            console.log(cmdsToSign)
            quickSignResult = await quickSign(cmdsToSign);
        }
        console.log(quickSignResult);
        if (quickSignResult?.status === "failure") {
            crossChainContext.setQuickBuyErrorMessage(`QuickSign failed, ${signedCmdList.data}`);
            crossChainContext.onOpenFailedQuickBuyModal();
        } else if (quickSignResult?.status === "success") {
            if (quickSignResult.data?.length === 0) {
                crossChainContext.setQuickBuyErrorMessage(`QuickSign failed, ${quickSignResult.wallet} failure`);
                crossChainContext.onOpenFailedQuickBuyModal();
            } else {
                let fullSignedCmdList = quickSignResult.data;
                for (let i; i < numCallbacks; i++) {
                    signedCallbackCmdList.push(fullSignedCmdList.pop());
                }
                signedCmdList = fullSignedCmdList;
            }
        }

        return {
            "signedCmds": signedCmdList,
            "signedCmdCallbacks": signedCallbackCmdList
        };
    }

    async function sendExecXChainCmdsToTransferFunds(signedCmdList, targetChainId, timeStamp, hasCreateAccount = false) {
        let date = new Date(timeStamp);
        let xChainCmds = [];
        let execCommandIndex = 0;

        if (hasCreateAccount) {
            xChainCmds = signedCmdList.filter((cmd, index) => {
                if (index !== 0 && index < signedCmdList.length - 1) {
                    return cmd;
                }
            });
            execCommandIndex = 1;
        } else {
            xChainCmds = signedCmdList.filter((cmd, index) => {
                if (index < signedCmdList.length - 1) {
                    return cmd;
                }
            });
        }
        console.log(xChainCmds);

        //TODO: CHANGE THE INDEX FROM 0
        //TODO, before firing cont, check exec again after send signed
        let networkUrl = getNetworkUrlWithChainId(NETWORK_ID, targetChainId);
        signedCmdList[execCommandIndex]["commandSigData"]["hash"] = signedCmdList[execCommandIndex].outcome["hash"];
        let localRes = await confirmTransactionWithNetwork(
            networkUrl,
            POST_METHOD,
            DEFAULT_REQUEST_HEADERS,
            signedCmdList[0]["commandSigData"]
        );
        const parsedLocalRes = await parseResponse(localRes);
        console.log(parsedLocalRes);

        let isSuccessfulResult = parsedLocalRes?.result?.status === "success";
        let isInsufficientBalanceErrorWithSufficientBalance = getIsInsufficientBalanceErrorWithSufficientBalance(parsedLocalRes);

        if (isSuccessfulResult || isInsufficientBalanceErrorWithSufficientBalance) {
            toast.info(
                `Transferring funds to chain ${targetChainId} initiated. This can take a few minutes!`,
                {
                    toastId: "Funds Transfer",
                }
            );
            let requestKeyObjList =
                await sendSignedForAllExecCrossTransfersWithChainId(
                    xChainCmds,
                    targetChainId,
                    timeStamp
                );

            return requestKeyObjList;
        } else {
            crossChainContext.changeTransactionStepData(2, "status", EZ_BUY_FAIL_STEP);

            toast.error(
                `Unable to complete transaction, ${parsedLocalRes?.result?.error?.message}`,
                {
                    toastId: "abort",
                }
            );
            clearTransactionFromLocalStorage(timeStamp);
            return null;
        }
    }

    async function executeCreateAccountTransaction(signedCmdList, targetChainId, timeStamp) {
        let networkUrl = getNetworkUrlWithChainId(NETWORK_ID, targetChainId);
        signedCmdList[0]["commandSigData"]["hash"] = signedCmdList[0].outcome["hash"];
        let localRes = await confirmTransactionWithNetwork(
            networkUrl,
            POST_METHOD,
            DEFAULT_REQUEST_HEADERS,
            signedCmdList[0]["commandSigData"]
        );
        const parsedLocalRes = await parseResponse(localRes);
        console.log(signedCmdList);
        console.log(parsedLocalRes);

        let isSuccessfulResult = parsedLocalRes?.result?.status === "success";
        let isInsufficientBalanceErrorWithSufficientBalance =
            getIsInsufficientBalanceErrorWithSufficientBalance(parsedLocalRes);
        if (
            isSuccessfulResult ||
            isInsufficientBalanceErrorWithSufficientBalance
        ) {
            toast.info(
                `Adding account to chain ${targetChainId}. This takes a few, hang in there!`,
                {
                    toastId: "Funds Transfer",
                }
            );

            let cmd = signedCmdList[0];
            let parsedCmd = JSON.parse(cmd.commandSigData["cmd"]);
            console.log(parsedCmd);
            let apiHost = getNetworkUrlWithChainId(
                NETWORK_ID,
                parsedCmd["meta"]["chainId"]
            );

            let formattedCmd = {
                cmd: cmd.commandSigData["cmd"],
                sigs: cmd.commandSigData["sigs"],
                hash: cmd.outcome["hash"],
            };

            return Pact.wallet.sendSigned(formattedCmd, apiHost);
        } else {
            toast.error(
                `Unable to complete transaction to create account on chain ${targetChainId}, ${parsedLocalRes?.result?.error?.message}`,
                {
                    toastId: "abort",
                }
            );
            clearTransactionFromLocalStorage(timeStamp);
        }
    }

    //TODO: index signed commands in local storage by type
    async function executeBusinessTransactions(timeStamp) {
        crossChainContext.changeTransactionStepData(4, "status", EZ_BUY_CURRENT_STEP);

        console.log("EXECUTING BUSINESS TRANSACTION");
        let date = new Date(timeStamp);

        let signedCmds = await getSignedCommands();

        console.log(signedCmds);

        let signedBusinessCmd = signedCmds[timeStamp][signedCmds[timeStamp].length - 1];
        signedBusinessCmd["commandSigData"]["hash"] = signedBusinessCmd.outcome["hash"];

        let businessTxList = [signedBusinessCmd];
        let targetChainId = JSON.parse(businessTxList[0]["commandSigData"]["cmd"])["meta"]["chainId"];
        let networkUrl = getNetworkUrlWithChainId(NETWORK_ID, targetChainId);

        console.log(signedCmds);
        console.log(signedBusinessCmd);

        let localRes = await confirmTransactionWithNetwork(
            networkUrl,
            POST_METHOD,
            DEFAULT_REQUEST_HEADERS,
            signedBusinessCmd["commandSigData"]
        );
        const parsedLocalRes = await parseResponse(localRes);

        let isSuccessfulResult = parsedLocalRes?.result?.status === "success";
        let isInsufficientBalanceErrorWithSufficientBalance = getIsInsufficientBalanceErrorWithSufficientBalance(parsedLocalRes);

        if (isSuccessfulResult || isInsufficientBalanceErrorWithSufficientBalance) {
            toast.info(`Finalizing last step of Quickbuy transaction`, {
                toastId: "Finalizing",
            });

            let businessTxObjReqKeyList = await sendSignedForAllExecCrossTransfersWithChainId(businessTxList, targetChainId, timeStamp);
            let crossChainTxsCopy = { ...crossChainTransactions };
            crossChainTxsCopy[timeStamp].business = businessTxObjReqKeyList;
            setCrossChainTransactions(crossChainTxsCopy);
        } else {
            crossChainContext.changeTransactionStepData(4, "status", EZ_BUY_FAIL_STEP);
            toast.error(
                `Unable to complete transaction, ${parsedLocalRes?.result?.error?.message}`,
                {
                    toastId: "abort",
                }
            );

            clearTransactionFromLocalStorage(timeStamp);
        }
    }

    async function executeCallbackTransactions(timeStamp) {
        console.log("EXECUTING CALLBACK TRANSACTION");

        let signedCmds = crossChainTransactions["callbacks"];

        console.log(signedCmds);

        let signedCallbackCmds = signedCmds[timeStamp]["callbacks"][0];
        signedCallbackCmds["commandSigData"]["hash"] = signedCallbackCmds.outcome["hash"];

        let callbackTxList = [signedCallbackCmds];
        let targetChainId = JSON.parse(callbackTxList[0]["commandSigData"]["cmd"])["meta"]["chainId"];
        let networkUrl = getNetworkUrlWithChainId(NETWORK_ID, targetChainId);

        console.log(signedCmds);
        console.log(signedCallbackCmds);

        let localRes = await confirmTransactionWithNetwork(
            networkUrl,
            POST_METHOD,
            DEFAULT_REQUEST_HEADERS,
            signedCallbackCmds["commandSigData"]
        );
        const parsedLocalRes = await parseResponse(localRes);

        let isSuccessfulResult = parsedLocalRes?.result?.status === "success";
        let isInsufficientBalanceErrorWithSufficientBalance = getIsInsufficientBalanceErrorWithSufficientBalance(parsedLocalRes);

        if (isSuccessfulResult || isInsufficientBalanceErrorWithSufficientBalance) {
            toast.info(`Finalizing last step of EZ Buy transaction`, {
                toastId: "Finalizing",
            });

            let callbackTxObjReqKeyList = await sendSignedForAllExecCrossTransfersWithChainId(callbackTxList, targetChainId, null);
            let crossChainTxsCopy = { ...crossChainTransactions };
            crossChainTxsCopy[timeStamp]["callbacks"] = callbackTxObjReqKeyList;
            setCrossChainTransactions(crossChainTxsCopy);
        }
    }

    /*
     ****************************
     ******** Utilities *********
     ****************************
     */

    async function checkIfQuickBuyInProgress() {
        let xChainTransactions = await getCrossChainTransactions();
        if (xChainTransactions && Object.keys(xChainTransactions).length > 0) {
            toast.error("EZ-Buy transaction already in progress!", {
                toastId: "IN PROGRESS",
            });
            return true;
        }
        return false;
    }

    async function pollMultipleTransactions(reqObjList, type, timeStamp) {
        console.log("POLLING FOR " + type.toUpperCase() + " TRANSACTIONS");
        console.log(reqObjList);
        await Promise.all(
            reqObjList.map((reqObj) => {
                if (reqObj.status === TX_PENDING) {
                    return pollTxAndUpdateLocalStorage(reqObj, type, timeStamp);
                }
            })
        );
    }

    function checkIfCrossChainTransactionReadyForBusiness(cmdsAtTimeStampObj) {
        let txComplete = true;
        let numComplete = 0;

        Object.entries(cmdsAtTimeStampObj).map(([txGroupName, txList]) => {
            for (let i = 0; i < txList.length; i++) {
                if (txGroupName === BUSINESS_TX_TYPE) {
                    if (
                        txList[i]?.status === TX_PENDING ||
                        txList[i]?.status === TX_COMPLETE
                    ) {
                        txComplete = false;
                    }
                } else {
                    if (txList[i]?.status !== TX_COMPLETE) {
                        txComplete = false;
                    } else {
                        numComplete += 1;
                    }
                }
            }
        });

        if (numComplete !== 2 * cmdsAtTimeStampObj[EXEC_TX_TYPE].length) {
            txComplete = false;
        }

        if (txComplete) {
            crossChainContext.changeTransactionStepData(3, "status", EZ_BUY_DONE_STEP);
            crossChainContext.changeTransactionStepData(4, "status", EZ_BUY_CURRENT_STEP);
        }

        return txComplete;
    }

    function checkIfCrossChainTransactionComplete(cmdsAtTimeStampObj) {
        let txComplete = true;
        let hasCompletedBusiness = false;

        Object.entries(cmdsAtTimeStampObj).map(([txGroupName, txList]) => {
            for (let i = 0; i < txList.length; i++) {
                if (txList[i]?.status !== TX_COMPLETE) {
                    txComplete = false;
                }
            }
            //TODO: this needs to account for multiple business
            if (txGroupName === BUSINESS_TX_TYPE) {
                if (txList.length > 0) {
                    if (txList[0]?.status === TX_COMPLETE) {
                        hasCompletedBusiness = true;
                    }
                }
            }
        });

        if (txComplete && hasCompletedBusiness) {
            crossChainContext.changeTransactionStepData(4, "status", EZ_BUY_DONE_STEP);
        }
        return txComplete && hasCompletedBusiness;
    }

    //TODO: handle multiple tx
    function clearTransactionFromLocalStorage(timeStamp, success = false) {
        let signedCommandsCopy = {
            ...signedCommands,
        };
        let crossChainTransactionsCopy = {
            ...crossChainTransactions,
        };
        delete signedCommandsCopy[timeStamp];
        delete crossChainTransactionsCopy[timeStamp];

        // setSignedCommands(signedCommandsCopy);
        // setCrossChainTransactions(crossChainTransactionsCopy);
        setSignedCommands(null);
        setCrossChainTransactions(null);
        setNeedCreateAccount(false);

        removeSignedCommands();
        removeCrossChainTransactions();

        localStorage.removeItem(XCHAIN_COMMANDS);
        localStorage.removeItem(SIGNED_QUICKBUY_COMMANDS);

        crossChainContext.resetEzBuyTransactionSteps();

        triggerSpv = {}; //TODO: remove specific timestamp related data ONLY
    }

    //TODO CRITICAL: account for 'balance.decimal'
    async function validateGasStationAvailabilityForEzBuy(gasStation, chainsWithFunds, businessTx) {
        let gasStationBalanceData = await getBalanceForAllChains(gasStation, LOCAL_GAS_STATION_KEY);
        let execRequiredAmount = DEFAULT_GAS_LIMIT_X_CHAIN_EXEC * DEFAULT_GAS_PRICE_X_CHAIN_EXEC;
        let contRequiredAmount = DEFAULT_GAS_LIMIT_X_CHAIN_CONT * DEFAULT_GAS_PRICE_X_CHAIN_CONT * Object.entries(chainsWithFunds).length;

        if (gasStationBalanceData.chainMap[parseInt(businessTx.cmd.chainId)].accountData.balance < contRequiredAmount) {
            return `Gas Station at Chain "${businessTx.cmd.chainId}" Down, Please Contact Isoko Admin`;
        }

        for (const [chainId, transferAmt] of Object.entries(chainsWithFunds)) {
            let chainBalance = gasStationBalanceData.chainMap[parseInt(chainId)].accountData.balance;

            if (chainBalance < execRequiredAmount) {
                return `Gas Station at Chain "${chainId}" Down, Please Contact Isoko Admin`;
            }
        }

        return null;
    }

    async function quickSign(cmdList) {
        let quickSignResponse = null;
        let signedCmds = [];

        let walletType = tryLoadLocal(CONNECTED_WALLET_TYPE);
        // if (isEckoWallet) {
        if (walletType === ECKO_WALLET_NAME) {
            let response = await quickSignEcko(cmdList);
            if (response?.status === "success") {
                signedCmds = response.quickSignData;
            } else if (response?.status === "fail") {
                crossChainContext.setQuickBuyErrorMessage(
                    `Quicksign failed, ${response.data}`
                );
                crossChainContext.onOpenFailedQuickBuyModal();
            } else {
                crossChainContext.setQuickBuyErrorMessage(
                    "Quicksign failed, please update your eckoWallet chrome extension"
                );
                crossChainContext.onOpenFailedQuickBuyModal();
            }
        } else {
            let quickSignCmd = {
                cmdSigDatas: [],
            };

            cmdList.forEach((cmdObj) => {
                let cmd = {
                    sigs: [
                        {
                            pubKey: cmdObj.pubKey,
                            sig: null,
                        },
                    ],
                    cmd: JSON.stringify(cmdObj.cmd),
                };
                quickSignCmd["cmdSigDatas"].push(cmd);
                console.log(cmdObj.cmd);
            });
            console.log(quickSignCmd);
            await fetch(
                `${KADENA_SIGNING_API_URL}/v1/quicksign`,
                mkReq(quickSignCmd)
            )
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    console.log(res);
                    signedCmds = res.responses;
                    quickSignResponse = {
                        status: "success",
                        data: signedCmds,
                        wallet: "Pact Server",
                    };
                })
                .catch((e) => {
                    console.log(e);
                    // toast.error("Pact API Failed to QuickSign")
                    quickSignResponse = {
                        status: "failure",
                        data: "Failed to QuickSign with Pact Server",
                        wallet: "Pact Server",
                    };
                });
        }

        return quickSignResponse;
    }

    //TODO CRITICAL IF BROWSER CLOSES : AND REOPENS WE RE-TRIGGER, BUT IS CALLBACK SAVED ? WILL IT STILL PAY US LOL
    async function sendSignedForAllExecCrossTransfersWithChainId(cmdList, targetChainId, timeStamp) {
        let reqKeyDataObjectList = [];
        let chainIdMap = {};

        await Promise.allSettled(
            cmdList.map((cmd, index) => {
                let parsedCmd = JSON.parse(cmd.commandSigData["cmd"]);
                chainIdMap[index] = parsedCmd["meta"]["chainId"];

                let apiHost = getNetworkUrlWithChainId(NETWORK_ID, parsedCmd["meta"]["chainId"]);

                let formattedCmd = {
                    cmd: cmd.commandSigData["cmd"],
                    sigs: cmd.commandSigData["sigs"],
                    hash: cmd.outcome["hash"],
                };

                return Pact.wallet.sendSigned(formattedCmd, apiHost);
            })
        ).then((res) => {
            res.forEach((result, index) => {
                if (result.status === TX_FULFILLED) {
                    let reqData = {
                        requestKey: result.value["requestKeys"][0],
                        sourceChainId: chainIdMap[index],
                        targetChainId: targetChainId,
                        status: "pending",
                    };

                    reqKeyDataObjectList.push(reqData);
                } else {
                    timeStamp && crossChainContext.changeTransactionStepData(2, "status", EZ_BUY_FAIL_STEP);

                    timeStamp && clearTransactionFromLocalStorage(timeStamp);
                    toast.error(
                        "Error sending QuickBuy, please make sure your wallet is updated",
                        {
                            toastId: "update wallet",
                        }
                    );
                }
            });
        });

        return reqKeyDataObjectList;
    }

    async function getSpv(reqObj, timeStampKey) {
        const { requestKey, targetChainId } = reqObj;
        const spvCmd = {
            requestKey,
            targetChainId,
        };

        /************************************/
        console.log(
            "THIS REQ " +
            reqObj.requestKey +
            " HAS CONTINUED? " +
            reqObj?.continued
        );
        let spvProof = await fetchProofForExecCompletion(
            spvCmd,
            reqObj,
            timeStampKey
        );
        if (!spvProof) {
            crossChainContext.changeTransactionStepData(
                2,
                "status",
                EZ_BUY_FAIL_STEP
            );

            clearTransactionFromLocalStorage(timeStampKey);
            toast.error(spvProof, {
                toastId: "spv proof",
            });
            return;
        }
        console.log("PROOF RECEIVED");
        console.log(reqObj.sourceChainId);

        let pollContFinishResult;
        let crosschainTxs = {
            ...crossChainTransactions,
        };
        if (spvProof) {
            let txObjIndex;
            let localReqObj = crosschainTxs[timeStampKey][EXEC_TX_TYPE].filter(
                (reqObject, i) => {
                    if (reqObject.requestKey === reqObj.requestKey) {
                        txObjIndex = i;
                    }
                    return reqObject.requestKey === reqObj.requestKey;
                }
            );

            if (localReqObj && localReqObj?.length > 0) {
                localReqObj[0]["status"] = TX_COMPLETE;
                localReqObj[0]["continued"] = true;
                crosschainTxs[timeStampKey][EXEC_TX_TYPE].forEach(
                    (requestObj, i) => {
                        if (requestObj.requestKey === localReqObj.requestKey) {
                            crosschainTxs[timeStampKey][EXEC_TX_TYPE][i] =
                                localReqObj[0];
                        }
                    }
                );

                let numExec = crosschainTxs[timeStampKey][EXEC_TX_TYPE].length;
                let numCompleteTxOfType = crosschainTxs[timeStampKey][
                    EXEC_TX_TYPE
                ].filter((reqObject) => {
                    return reqObject.status === TX_COMPLETE;
                });
                if (numCompleteTxOfType.length === numExec) {
                    crossChainContext.changeTransactionStepData(
                        2,
                        "status",
                        EZ_BUY_DONE_STEP
                    );
                }
                crossChainContext.changeTransactionStepData(
                    2,
                    "label",
                    `Transferring funds (${numCompleteTxOfType?.length}/${numExec})`
                );

                console.log(crosschainTxs);
                console.log(
                    "EXEC FINISHED, SETTING TO COMPLETE IN LOCAL STORAGE"
                );
                console.log(
                    "-----------------------------------------------------"
                );

                let contCommand = buildContCommandWithProof(
                    reqObj,
                    spvProof,
                    timeStampKey
                );
                let finishContRequestKey = await finishCrossChainTransaction(
                    contCommand,
                    reqObj,
                    timeStampKey
                );

                if (finishContRequestKey) {
                    let contReqObj = {
                        status: TX_PENDING,
                        requestKey: finishContRequestKey,
                        targetChainId: reqObj.targetChainId,
                        sourceChainId: reqObj.sourceChainId,
                    };
                    crosschainTxs[timeStampKey][CONT_TX_TYPE].push(contReqObj);

                    console.log("BEFORE PUSHING CONT FOR " + contReqObj["sourceChainId"]);
                    console.log("AFTER PUSHING CONT FOR " + contReqObj["sourceChainId"]);
                    console.log(crosschainTxs[timeStampKey][CONT_TX_TYPE].length);

                    crossChainContext.changeTransactionStepData(3, "status", EZ_BUY_CURRENT_STEP);

                    // await pollTxAndUpdateLocalStorage(contReqObj, CONT_TX_TYPE, timeStampKey)
                    setCrossChainTransactions(crosschainTxs);
                }
            }
        }
    }

    async function fetchProofForExecCompletion(spvCmd, reqObj, timeStampKey) {
        console.log("SPV FETCH STARTED:");
        let proofNotAvailable = true;
        let spvProof = null;

        while (proofNotAvailable) {
            await wait(10000);

            try {
                spvProof = await Pact.fetch.spv(
                    spvCmd,
                    getNetworkUrlWithChainId(
                        NETWORK_ID,
                        reqObj["sourceChainId"]
                    )
                );

                if (spvProof.includes("SPV target not reachable") || spvProof.includes("Transaction hash not found")) {
                    console.log("SPV target not reachable");

                    proofNotAvailable = true;
                    continue;
                }
                if (spvProof.includes(" ")) {
                    return null;
                }

                proofNotAvailable = false;
            } catch (e) {
                proofNotAvailable = true;
                continue;
            }
        }

        return spvProof;
    }

    function buildContCommandWithProof(reqObj, spvProof, timeStampKey) {
        const proof = spvProof;
        const pactId =
            reqObj.requestKey.length === 44
                ? reqObj.requestKey.slice(0, 43)
                : reqObj.requestKey;
        const host = getNetworkUrlWithChainId(
            NETWORK_ID,
            reqObj["targetChainId"]
        );
        const gasStation = GAS_STATION;
        const m = Pact.lang.mkMeta(
            GAS_STATION,
            reqObj["targetChainId"],
            DEFAULT_GAS_PRICE_X_CHAIN_CONT,
            DEFAULT_GAS_LIMIT_X_CHAIN_CONT,
            parseFloat(getTimestamp().toFixed(2)),
            600
        );

        const cmd = {
            type: "cont",
            keyPairs: [],
            pactId,
            rollback: false,
            step: 1,
            meta: m,
            proof,
            networkId: NETWORK_ID,
        };

        const contCmd = Pact.simple.cont.createCommand(
            cmd.keyPairs,
            undefined,
            cmd.step,
            cmd.pactId,
            cmd.rollback,
            undefined,
            cmd.meta,
            cmd.proof,
            cmd.networkId
        );

        return contCmd;
    }

    async function finishCrossChainTransaction(contCmd, reqObj, timeStamp) {
        let data = null;
        let networkUrl = getNetworkUrlWithChainId(
            NETWORK_ID,
            reqObj.targetChainId
        );
        console.log("------------------------------");
        console.log("FINISHING CONT COMMAND");
        console.log(reqObj);
        console.log(contCmd);

        try {
            data = await Pact.wallet.sendSigned(contCmd["cmds"][0], networkUrl);
        } catch (e) {
            console.log(e);
        }

        if (data) {
            let contRequestObj = {
                status: TX_PENDING,
                requestKey: data.requestKeys[0],
                sourceChainId: reqObj.sourceChainId,
                targetChainId: reqObj.targetChainId,
            };

            // await addNewRequestObjectToLocalStorage(contRequestObj, CONT_TX_TYPE, timeStamp);

            return data.requestKeys[0];
        }

        return data;
    }

    async function pollTxAndUpdateLocalStorage(requestObj, txType, timeStampKey) {
        console.log(`-------------------------------------------------`);
        console.log(`POLLING ${txType.toUpperCase()} TRANSACTION`);
        console.log(requestObj);
        let pollRes = await pollForTransaction(requestObj);
        console.log(pollRes);

        if (pollRes && pollRes?.result.status !== "failure") {
            console.log(`---------------------------------------------------------`);
            console.log(`POLL RESULT RECEIVED ${txType.toUpperCase()} TRANSACTION`);
            console.log(pollRes);

            let crosschainTxs = {
                ...crossChainTransactions,
            };
            let txObjIndex;
            let localReqObj = crosschainTxs[timeStampKey][txType].filter(
                (reqObject, i) => {
                    if (reqObject.requestKey === requestObj.requestKey) {
                        txObjIndex = i;
                    }
                    return reqObject.requestKey === requestObj.requestKey;
                }
            );

            if (localReqObj && localReqObj?.length > 0) {
                localReqObj[0]["status"] = TX_COMPLETE;
                crosschainTxs[timeStampKey][txType].forEach((requestObj, i) => {
                    if (requestObj.requestKey === localReqObj.requestKey) {
                        crosschainTxs[timeStampKey][txType][i] = localReqObj[0];
                    }
                });
                setCrossChainTransactions(crosschainTxs);

                console.log(crosschainTxs);
                console.log("CONT FINISHED, SETTING TO COMPLETE IN LOCAL STORAGE");
                console.log(`------------------------------------------------------`);

                if (txType === CONT_TX_TYPE) {
                    // crosschainTxs[timeStampKey][CONT_TX_TYPE][txObjIndex] = localReqObj[0]; //TODO:CRITICAL
                    let numExec = crosschainTxs[timeStampKey][EXEC_TX_TYPE].length;
                    let numCompleteTxOfType = crosschainTxs[timeStampKey][CONT_TX_TYPE].filter((reqObject) => {
                        return reqObject.status === TX_COMPLETE;
                    });
                    crossChainContext.changeTransactionStepData(3, "label", `Continuing transfer (${numCompleteTxOfType?.length}/${numExec})`);
                } else if (txType === BUSINESS_TX_TYPE) {

                }
            }
        } else {
            toast.error(
                "Transaction failed to continue, contact an Isoko admin",
                {
                    toastId: "contact admin",
                }
            );
            clearTransactionFromLocalStorage();
        }

        return pollRes;
    }

    function buildBusinessTransactionForEzBuy(businessTxDetails, chainId, isGasStationAvailable) {
        let caps = null;
        let signers = null;
        let sender = account.account;
        let cmdWithGasCap = null;
        console.log(isGasStationAvailable)
        // if (businessTxDetails["cmdType"] === CONT_TX_TYPE) return
        if (isGasStationAvailable) {
            cmdWithGasCap = addGasStationCapability(account, businessTxDetails);
            sender = ISOKO_GAS_STATION;
        } else {
            return;
        }

        let meta = Pact.lang.mkMeta(
            sender,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200
        );

        let businessCmd = null;
        console.log(businessTxDetails)
        if (businessTxDetails["cmdType"] === CONT_TX_TYPE) {
            businessCmd = cmdWithGasCap["newCmd"];
            logLine("NEW CONT CMD", businessCmd)
        } else {
            let pactCode = businessTxDetails["cmd"].pactCode;
            let envData = businessTxDetails["cmd"].envData;
            businessCmd = JSON.parse(
                Pact.simple.exec.createCommand(
                    cmdWithGasCap["signers"],
                    (Date.now() / 1000).toString(),
                    pactCode,
                    envData,
                    meta,
                    NETWORK_ID
                ).cmds[0]["cmd"]
            );
        }

        let cmdPubKeyObj = {
            cmd: businessCmd,
            pubKey: account.accountGuard.keys[0],
        };

        businessTxDetails["bzCommand"] = cmdPubKeyObj;

        return businessTxDetails;
    }

    function updateReqObj(reqObject, type, timeStampKey, attributes) {
        let crosschainTxs = {
            ...crossChainTransactions,
        };
        let txObjIndex;
        let localReqObj = crosschainTxs[timeStampKey][type].filter(
            (reqObj, i) => {
                txObjIndex = i;
                return reqObject.requestKey === reqObj.requestKey;
            }
        );

        Object.entries(attributes).forEach(([att, value]) => {
            localReqObj[0][att] = value;
        });

        crosschainTxs[timeStampKey][type][txObjIndex] = localReqObj[0];
        setCrossChainTransactions(crosschainTxs);
    }

    function performActionBasedOnTransactionStatus(localCommandsObj, type) {
        for (let i = 0; i < localCommandsObj.length; i++) {
            let cmdObj = localCommandsObj[i];
            if (cmdObj["status"] === TX_PENDING) {
                if (type === EXEC_TX_TYPE) {
                    getSpv(cmdObj);
                }
            } else if (cmdObj["status"] === TX_NOT_STARTED) {
            }
        }
    }

    function updateRequestObjectInLocalStorage(requestObj, status, type) {
        console.log("--------------------------------------------------------");
        console.log("UPDATING LOCAL STORAGE WITH NEW TX BELOW");
        console.log(crossChainTransactions);
        console.log(requestObj);
        console.log(status);
        console.log(type);
        let timeStampObj = {};
        let match = {};
        let index;
        for (const [timeStampKey, cmdsAtTimeStamp] of Object.entries(crossChainTransactions)) {
            console.log(cmdsAtTimeStamp);
            match = cmdsAtTimeStamp[type].filter((reqObj, i) => {
                index = i;
                return reqObj.requestKey === requestObj.requestKey;
            });

            if (match) {
                match[0]["status"] = status;
                timeStampObj["key"] = timeStampKey;
                timeStampObj["value"] = match[0];
                break;
            }
        }

        let crossChainTransactionsCopy = {
            ...crossChainTransactions,
        };

        crossChainTransactionsCopy[timeStampObj["key"]][type][index] =
            timeStampObj["value"];
        setCrossChainTransactions(crossChainTransactionsCopy);

        console.log("CURRENT LOCAL STORAGE CONTENTS");
        console.log(crossChainTransactionsCopy);
        console.log("TX UPDATED IN LOCAL STORAGE");
        console.log("--------------------------------------------------------");
    }

    function addNewRequestObjectToLocalStorage(requestObj, type, timeStamp) {
        console.log("ADDING CONT TX TO LOCAL STORAGE");

        let crossChainTransactionsCopy = {
            ...crossChainTransactions,
        };

        // crossChainTransactionsCopy[timeStamp] = XCHAIN_CMD_TRACKING_STRUCTURE;
        crossChainTransactionsCopy[timeStamp][type].push(requestObj);

        setCrossChainTransactions(crossChainTransactionsCopy);
    }

    function updateProgressBarSteps(txType, timeStampKey) {
        let stepIndex = null;
        let stepText = null;
        if (txType === CONT_TX_TYPE) {
            stepIndex = 3;
            stepText = "Continuing transfer";
        } else if (txType === EXEC_TX_TYPE) {
            stepIndex = 2;
            stepText = "Transferring funds";
        }

        let txObjIndex = null;
        let crossChainTxs = {
            ...crossChainTransactions,
        };
        let numExec = crossChainTxs[timeStampKey][EXEC_TX_TYPE].length;
        let numTxOfType = crossChainTxs[timeStampKey][txType].length;
        let numCompleteTxOfType = crossChainTxs[timeStampKey][txType].filter(
            (reqObject, i) => {
                txObjIndex = i;
                return reqObject.status === TX_COMPLETE;
            }
        );
        console.log("UPDATING PROGRESS BAR");
        console.log(numExec);
        console.log(numCompleteTxOfType);
        console.log(crossChainTxs);
        crossChainContext.changeTransactionStepData(
            stepIndex,
            "label",
            `${stepText} (${numCompleteTxOfType?.length}/${numExec})`
        );

        if (numCompleteTxOfType?.length === numExec) {
            crossChainContext.changeTransactionStepData(
                stepIndex,
                "status",
                EZ_BUY_DONE_STEP
            ); //TODO FIX THIS FOR CONT
        }
    }

    /*
     *****************
     *** Callbacks ***
     *****************
     */
    async function updateUserBalance() {
        let accountLocalStorage = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        if (accountLocalStorage) {
            await pollForAccountBalanceDataOnAllChains(account.account);
        }
    }

    async function executeCallback(quickBuyCallback) {
        quickBuyCallback && (await quickBuyCallback());
    }

    async function onQuickbuyTransactionFinish(timeStampKey) {
        console.log("QUICKBUY TRANSACTION COMPLETE");
        toast.success(`EZ-Buy transaction completed!`, {
            toastId: "TX COMPLETE",
        });
        await updateUserBalance();
        await executeCallbackTransactions(timeStampKey);
        clearTransactionFromLocalStorage(timeStampKey);
    }

    /*************************************************/
    /*************************************************/
    async function getSpvList(requestKeyObjList, targetChainId) {
        let spvResults = null;

        await Promise.allSettled(
            requestKeyObjList.map((requestKeyObj) => {
                return getSpv(
                    requestKeyObj.reqKey.requestKeys[0],
                    requestKeyObj.chainId,
                    targetChainId
                );
            })
        ).then((spvResponses) => {
            console.log("SPV RESPONSES");
            console.log(spvResponses);
            spvResults = spvResponses.map((spvResponse) => {
                return spvResponse.value;
            });
        });

        return spvResults;
    }

    return {
        prepareCommandsForEzBuy,
        executeQuickSignAndTx,
        shouldExecuteQuickBuy,
    };
};

export { useQuickBuy };

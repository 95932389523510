import { Box, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { PactContext } from "../pact/PactContextProvider";
import { useInterval } from "../utils/utils";

const PHASE_NAME = "phase_name";
const PHASE_START_TIME = "start_time";
const PHASE_END_TIME = "end_time";
const PHASE_BASE_PRICE = "base_price";
const PHASE_ENDED = "ENDED";
const PHASE_STARTED = "STARTED";
const WINDOW_UPDATE_INTERVAL = 1000;

const MintWindow = ({ phase, phaseData, mintProvider }) => {
    const { account } = useContext(PactContext);
    const [phaseState, setPhaseState] = useState(null);
    const [phaseRequirement, setPhaseRequirement] = useState(null);

    useEffect(() => {
        getPhaseState();

        async function getPhaseRequirement() {
            let requirement =
                await mintProvider?.getPhaseParticipationRequirement(
                    account?.account,
                    phase
                );
            setPhaseRequirement(requirement);
        }
        getPhaseRequirement();
    }, [account]);

    useInterval(
        () => {
            getPhaseState();
        },
        WINDOW_UPDATE_INTERVAL,
        false
    );

    function getPhaseState() {
        let currentTime = Date.now();
        let windowStartTime = Date.parse(phaseData[PHASE_START_TIME]);
        let windowEndTime = Date.parse(phaseData[PHASE_END_TIME]);

        if (currentTime < windowStartTime) {
            let time = windowStartTime - currentTime;
            let days = Math.floor(time / (1000 * 60 * 60 * 24));
            let hours = Math.floor((time / (1000 * 60 * 60)) % 24);
            let minutes = Math.floor((time / 1000 / 60) % 60);
            let seconds = Math.floor((time / 1000) % 60);

            setPhaseState(`STARTS IN ${days}:${hours}:${minutes}:${seconds}`);
        } else if (
            currentTime > windowStartTime &&
            currentTime < windowEndTime
        ) {
            setPhaseState(PHASE_STARTED);
        } else if (currentTime > windowEndTime) {
            setPhaseState(PHASE_ENDED);
        }
    }

    return (
        <Box mt="22px">
            <Box
                border="4px"
                borderColor={
                    phaseState !== PHASE_STARTED ? "#616161" : "#BC3CF0"
                }
                className="whiteList_box alignCenter"
            >
                <div className="flex sBtw alignCenter">
                    <Text className="noMarginB">{phaseData[PHASE_NAME]}</Text>
                    <Text
                        className="badge noMarginB"
                        w={
                            phaseState !== PHASE_STARTED ||
                                phaseState !== PHASE_ENDED
                                ? "fit-content"
                                : "135px"
                        }
                        textAlign="center"
                    >
                        {phaseState}
                    </Text>
                </div>
                <div
                    className="flex walletStatus"
                    style={{
                        marginTop: "20px",
                        marginBottom: "0px",
                    }}
                >
                    <p className="noMarginB">
                        {phaseRequirement?.toUpperCase()}
                    </p>
                    {/* {" "}
                    <p
                        className="noMarginB"
                        style={{ fontSize: "16px" }}
                    >
                        .
                    </p>
                    {" "}
                    <p className="noMarginB">FREE MINT</p> */}
                </div>
            </Box>
        </Box>
    );
};

export { MintWindow };

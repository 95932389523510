const arkade_80s_bulls_metadata = {
    "1": {
        "name": "80's Arkade Bulls #1",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1.png",
        "dna": "0d403b99dbc0cbd29c7d6b98ae668d409dd018ba",
        "edition": 1,
        "date": 1654567580166,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "1",
        "rarity_score": 132.21156987176641,
        "rarity_level": "COMMON"
    },
    "2": {
        "name": "80's Arkade Bulls #2",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/2.png",
        "dna": "e5b49a33d7bea9faf327a2b845ae60ad66085508",
        "edition": 2,
        "date": 1654568107010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "2",
        "rarity_score": 261.1377895183735,
        "rarity_level": "RARE"
    },
    "3": {
        "name": "80's Arkade Bulls #3",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/3.png",
        "dna": "f863205af835b7774508ef7f439bf2980bd62911",
        "edition": 3,
        "date": 1654567236506,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666666,
        "id": "3",
        "rarity_score": 191.66661503191446,
        "rarity_level": "COMMON"
    },
    "4": {
        "name": "80's Arkade Bulls #4",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/4.png",
        "dna": "dd5b7f44f7afc2c2c39b620179f815ec31f2d3f2",
        "edition": 4,
        "date": 1654567112074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Orange Turtle Mask",
                "score": 1980.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "4",
        "rarity_score": 2110.061773376645,
        "rarity_level": "EPIC"
    },
    "5": {
        "name": "80's Arkade Bulls #5",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/5.png",
        "dna": "078b7102f97a26278458e42c1c3aa526ba49e10b",
        "edition": 5,
        "date": 1654567205824,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.5,
        "id": "5",
        "rarity_score": 61.9467964994943,
        "rarity_level": "COMMON"
    },
    "6": {
        "name": "80's Arkade Bulls #6",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/6.png",
        "dna": "d80213f44a705b382cc520dd497e85120371b3dc",
        "edition": 6,
        "date": 1654567860151,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "6",
        "rarity_score": 108.07652610412708,
        "rarity_level": "COMMON"
    },
    "7": {
        "name": "80's Arkade Bulls #7",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/7.png",
        "dna": "c3a1f12ed8bf05e2c3946261f981b577cd45afe7",
        "edition": 7,
        "date": 1654568067594,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "7",
        "rarity_score": 59.89808234371446,
        "rarity_level": "COMMON"
    },
    "8": {
        "name": "80's Arkade Bulls #8",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/8.png",
        "dna": "d1030f732484a527830f8d69e16a75a2c5e981a5",
        "edition": 8,
        "date": 1654567640674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.75,
        "id": "8",
        "rarity_score": 144.715618424161,
        "rarity_level": "COMMON"
    },
    "9": {
        "name": "80's Arkade Bulls #9",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/9.png",
        "dna": "e0ee2699a8804861bda7d6f7dd2c947fc55d447d",
        "edition": 9,
        "date": 1654567644987,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.125,
        "id": "9",
        "rarity_score": 95.37051905747984,
        "rarity_level": "COMMON"
    },
    "10": {
        "name": "80's Arkade Bulls #10",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/10.png",
        "dna": "5ffe0c4f2053d7f385bdf3f2c85d28c50488f161",
        "edition": 10,
        "date": 1654568169683,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "10",
        "rarity_score": 209.41402418450934,
        "rarity_level": "RARE"
    },
    "11": {
        "name": "80's Arkade Bulls #11",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/11.png",
        "dna": "08aaea05b19e27f4cea39133f044f46233c566eb",
        "edition": 11,
        "date": 1654567471841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "11",
        "rarity_score": 99.00463524102383,
        "rarity_level": "COMMON"
    },
    "12": {
        "name": "80's Arkade Bulls #12",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/12.png",
        "dna": "d8711c99618a47f6d942fae93d142a6cd10f09fc",
        "edition": 12,
        "date": 1654568085540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "12",
        "rarity_score": 62.31118972255552,
        "rarity_level": "COMMON"
    },
    "13": {
        "name": "80's Arkade Bulls #13",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/13.png",
        "dna": "8537a4a324e5a52847446dfe94042cd0ae8e73f9",
        "edition": 13,
        "date": 1654567133276,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 6.199999999999999,
        "id": "13",
        "rarity_score": 106.23907799517556,
        "rarity_level": "COMMON"
    },
    "14": {
        "name": "80's Arkade Bulls #14",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/14.png",
        "dna": "1375bef2caf82bf2e0f931eadfedab609efa2681",
        "edition": 14,
        "date": 1654568162252,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "14",
        "rarity_score": 163.4365243152405,
        "rarity_level": "COMMON"
    },
    "15": {
        "name": "80's Arkade Bulls #15",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/15.png",
        "dna": "e11daa853bb866f10289751c24bf09337a57366b",
        "edition": 15,
        "date": 1654567683227,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "15",
        "rarity_score": 126.5640738030543,
        "rarity_level": "COMMON"
    },
    "16": {
        "name": "80's Arkade Bulls #16",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/16.png",
        "dna": "d3eade582033fad736cbf2b8a2a0f71872cac55d",
        "edition": 16,
        "date": 1654568156714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "16",
        "rarity_score": 174.79582583991453,
        "rarity_level": "COMMON"
    },
    "17": {
        "name": "80's Arkade Bulls #17",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/17.png",
        "dna": "5829199699d5d589dd511b1aa8138efbb58dc94e",
        "edition": 17,
        "date": 1654568031136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "17",
        "rarity_score": 68.07184561139599,
        "rarity_level": "COMMON"
    },
    "18": {
        "name": "80's Arkade Bulls #18",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/18.png",
        "dna": "97712abdbe206da5c9c10fd95b561382cc1d85ea",
        "edition": 18,
        "date": 1654567254424,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "18",
        "rarity_score": 64.17544660686056,
        "rarity_level": "COMMON"
    },
    "19": {
        "name": "80's Arkade Bulls #19",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/19.png",
        "dna": "a5f4b563cc3e8832e9b434281756e507fbd4af81",
        "edition": 19,
        "date": 1654567914740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "19",
        "rarity_score": 112.44570378503019,
        "rarity_level": "COMMON"
    },
    "20": {
        "name": "80's Arkade Bulls #20",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/20.png",
        "dna": "a62c205e244b297b9fbf715337eda752a017b1a2",
        "edition": 20,
        "date": 1654567540238,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "20",
        "rarity_score": 156.4296433674931,
        "rarity_level": "COMMON"
    },
    "21": {
        "name": "80's Arkade Bulls #21",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/21.png",
        "dna": "9c1dcb6ff83a4494fb173e5fb61af62d83dee876",
        "edition": 21,
        "date": 1654567963167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "21",
        "rarity_score": 99.44108028163149,
        "rarity_level": "COMMON"
    },
    "22": {
        "name": "80's Arkade Bulls #22",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/22.png",
        "dna": "ffcf164ae2d74f20b50488fef1fa4945090bc5d7",
        "edition": 22,
        "date": 1654567422218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "22",
        "rarity_score": 150.4040310450955,
        "rarity_level": "COMMON"
    },
    "23": {
        "name": "80's Arkade Bulls #23",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/23.png",
        "dna": "2e3f8df7c4e94fd866e9b3d9fb0a0518694b6252",
        "edition": 23,
        "date": 1654567160061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "23",
        "rarity_score": 72.10057768147954,
        "rarity_level": "COMMON"
    },
    "24": {
        "name": "80's Arkade Bulls #24",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/24.png",
        "dna": "a9e3b827e89431291c9b9954db6b402366fc2696",
        "edition": 24,
        "date": 1654567257743,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "24",
        "rarity_score": 194.64178996988946,
        "rarity_level": "COMMON"
    },
    "25": {
        "name": "80's Arkade Bulls #25",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/25.png",
        "dna": "601702d86b0b25018dd02ff34502aab7ce5bdc8a",
        "edition": 25,
        "date": 1654567706056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "25",
        "rarity_score": 207.56597525959913,
        "rarity_level": "RARE"
    },
    "26": {
        "name": "80's Arkade Bulls #26",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/26.png",
        "dna": "a584564075e9fd67b54d3dfc49014a0f14a4117b",
        "edition": 26,
        "date": 1654567554045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "26",
        "rarity_score": 161.7811188487084,
        "rarity_level": "COMMON"
    },
    "27": {
        "name": "80's Arkade Bulls #27",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/27.png",
        "dna": "c9d9c258eb7cbde6c8b825cc31d39c582794451c",
        "edition": 27,
        "date": 1654568170206,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Super",
        "rarity": 2.666666666666667,
        "id": "27",
        "rarity_score": 256.1962706434529,
        "rarity_level": "RARE"
    },
    "28": {
        "name": "80's Arkade Bulls #28",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/28.png",
        "dna": "3844e3d4395489174bd477b8163e8f4cc8023021",
        "edition": 28,
        "date": 1654568128479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9.8,
        "id": "28",
        "rarity_score": 51.913333054944886,
        "rarity_level": "COMMON"
    },
    "29": {
        "name": "80's Arkade Bulls #29",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/29.png",
        "dna": "5ca59fb85447283a4d5eaed93a2292da89f866c5",
        "edition": 29,
        "date": 1654567524814,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "29",
        "rarity_score": 175.22033701775348,
        "rarity_level": "COMMON"
    },
    "30": {
        "name": "80's Arkade Bulls #30",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/30.png",
        "dna": "05490692f48bcb7998aa903a0eeb502ded44dd7e",
        "edition": 30,
        "date": 1654568035813,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "30",
        "rarity_score": 84.89812825673815,
        "rarity_level": "COMMON"
    },
    "31": {
        "name": "80's Arkade Bulls #31",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/31.png",
        "dna": "fb7359bb4f8f0a4ababeced352bef39e109117d2",
        "edition": 31,
        "date": 1654568113620,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "31",
        "rarity_score": 55.768863065615776,
        "rarity_level": "COMMON"
    },
    "32": {
        "name": "80's Arkade Bulls #32",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/32.png",
        "dna": "8a73430943e37055cc219ac83ebc3507e9dc27cd",
        "edition": 32,
        "date": 1654567211522,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "32",
        "rarity_score": 96.91624406121609,
        "rarity_level": "COMMON"
    },
    "33": {
        "name": "80's Arkade Bulls #33",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/33.png",
        "dna": "6f37ec0fc5090af98ba7e3a79aaf65000e55277d",
        "edition": 33,
        "date": 1654567572615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "33",
        "rarity_score": 147.79812489512767,
        "rarity_level": "COMMON"
    },
    "34": {
        "name": "80's Arkade Bulls #34",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/34.png",
        "dna": "a9d930aff72716eae8f3e599ce8c727b6edcd697",
        "edition": 34,
        "date": 1654567844862,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "34",
        "rarity_score": 80.59671895265032,
        "rarity_level": "COMMON"
    },
    "35": {
        "name": "80's Arkade Bulls #35",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/35.png",
        "dna": "2f3835e6d90d608cc6583eb6fe181f7cd37f80c6",
        "edition": 35,
        "date": 1654567174732,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "35",
        "rarity_score": 92.45851038493839,
        "rarity_level": "COMMON"
    },
    "36": {
        "name": "80's Arkade Bulls #36",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/36.png",
        "dna": "1dde1652c6973ffce532e3a876e117c83329a748",
        "edition": 36,
        "date": 1654568084614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "36",
        "rarity_score": 140.6696703296703,
        "rarity_level": "COMMON"
    },
    "37": {
        "name": "80's Arkade Bulls #37",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/37.png",
        "dna": "afab727b37206d8cf95829c43b15277607509d24",
        "edition": 37,
        "date": 1654567535278,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "37",
        "rarity_score": 161.8511303929856,
        "rarity_level": "COMMON"
    },
    "38": {
        "name": "80's Arkade Bulls #38",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/38.png",
        "dna": "8ce19ed06a8a9fd489a96ef8f81ce4d3fe5ae5ad",
        "edition": 38,
        "date": 1654567265988,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "38",
        "rarity_score": 102.84369183812913,
        "rarity_level": "COMMON"
    },
    "39": {
        "name": "80's Arkade Bulls #39",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/39.png",
        "dna": "adb437314bf802f2ae5ecaede2336f4012c3cc51",
        "edition": 39,
        "date": 1654567972545,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "39",
        "rarity_score": 100.27150836089487,
        "rarity_level": "COMMON"
    },
    "40": {
        "name": "80's Arkade Bulls #40",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/40.png",
        "dna": "1b6f76c304ba24d8d8f1e1c440aaf8f2cac2526d",
        "edition": 40,
        "date": 1654567682079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "40",
        "rarity_score": 112.2886971248104,
        "rarity_level": "COMMON"
    },
    "41": {
        "name": "80's Arkade Bulls #41",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/41.png",
        "dna": "b0e8918a9f7d221e9bac223db0abe186befd681e",
        "edition": 41,
        "date": 1654567492041,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.285714285714286,
        "id": "41",
        "rarity_score": 229.73039215686276,
        "rarity_level": "RARE"
    },
    "42": {
        "name": "80's Arkade Bulls #42",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/42.png",
        "dna": "4f9625581f4eed63c2f87805f918a319f2d2ff54",
        "edition": 42,
        "date": 1654568146138,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "42",
        "rarity_score": 137.80354355223238,
        "rarity_level": "COMMON"
    },
    "43": {
        "name": "80's Arkade Bulls #43",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/43.png",
        "dna": "e06529418d3341f27549bf6e8e002c2f276e613b",
        "edition": 43,
        "date": 1654567884843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "43",
        "rarity_score": 89.04699709114782,
        "rarity_level": "COMMON"
    },
    "44": {
        "name": "80's Arkade Bulls #44",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/44.png",
        "dna": "8fba376faff18cf501d26b668a1d6a0226f5c11e",
        "edition": 44,
        "date": 1654567109917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Blue Turtle Mask",
                "score": 1980.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "44",
        "rarity_score": 2110.061773376645,
        "rarity_level": "EPIC"
    },
    "45": {
        "name": "80's Arkade Bulls #45",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/45.png",
        "dna": "ffd72bbab9dc3c2ebe550e8a73e06cbb2e66994b",
        "edition": 45,
        "date": 1654567269709,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "45",
        "rarity_score": 164.66923963133644,
        "rarity_level": "COMMON"
    },
    "46": {
        "name": "80's Arkade Bulls #46",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/46.png",
        "dna": "64e97b4ac1a8cec6c651dc8e4acfa6a150d9360b",
        "edition": 46,
        "date": 1654567438782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "46",
        "rarity_score": 81.93349062436549,
        "rarity_level": "COMMON"
    },
    "47": {
        "name": "80's Arkade Bulls #47",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/47.png",
        "dna": "3142df3db79db186f2af67e5cb33cf3362e448c6",
        "edition": 47,
        "date": 1654567645599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.625,
        "id": "47",
        "rarity_score": 165.4364892289341,
        "rarity_level": "COMMON"
    },
    "48": {
        "name": "80's Arkade Bulls #48",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/48.png",
        "dna": "723590aa53283c2ed2dd7c496692c825971cd102",
        "edition": 48,
        "date": 1654567578591,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "48",
        "rarity_score": 182.10865506396232,
        "rarity_level": "COMMON"
    },
    "49": {
        "name": "80's Arkade Bulls #49",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/49.png",
        "dna": "9a2b61bdb8f341a3519ed749bb9317390961da05",
        "edition": 49,
        "date": 1654567840436,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666666,
        "id": "49",
        "rarity_score": 115.29446407720037,
        "rarity_level": "COMMON"
    },
    "50": {
        "name": "80's Arkade Bulls #50",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/50.png",
        "dna": "92ebdbe406bb6d3960a583ba720b5db63d5b2c88",
        "edition": 50,
        "date": 1654567466775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "50",
        "rarity_score": 97.25783272308684,
        "rarity_level": "COMMON"
    },
    "51": {
        "name": "80's Arkade Bulls #51",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/51.png",
        "dna": "f9f633dc10c0dbacdfc04aa1a479247fa4ce094c",
        "edition": 51,
        "date": 1654568220053,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Super",
        "rarity": 1.166666666666667,
        "id": "51",
        "rarity_score": 386.3609603290371,
        "rarity_level": "RARE"
    },
    "52": {
        "name": "80's Arkade Bulls #52",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/52.png",
        "dna": "3b82dfd9388ee7de424e5d460b72fdbf5053ece2",
        "edition": 52,
        "date": 1654567121594,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "52",
        "rarity_score": 74.05261244196794,
        "rarity_level": "COMMON"
    },
    "53": {
        "name": "80's Arkade Bulls #53",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/53.png",
        "dna": "fa73a0ecc95ec80ce17386cb8dbb8bc5c612b4e2",
        "edition": 53,
        "date": 1654568133112,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "53",
        "rarity_score": 54.79893046289291,
        "rarity_level": "COMMON"
    },
    "54": {
        "name": "80's Arkade Bulls #54",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/54.png",
        "dna": "43f97922440624364054bb4e55c99b086e44161d",
        "edition": 54,
        "date": 1654567913209,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857142,
        "id": "54",
        "rarity_score": 120.54773765327741,
        "rarity_level": "COMMON"
    },
    "55": {
        "name": "80's Arkade Bulls #55",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/55.png",
        "dna": "989554e562e3075b29491064041c6a78fa6a9964",
        "edition": 55,
        "date": 1654567111167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Purple Turtle Mask",
                "score": 1980.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "55",
        "rarity_score": 2110.061773376645,
        "rarity_level": "EPIC"
    },
    "56": {
        "name": "80's Arkade Bulls #56",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/56.png",
        "dna": "c45c9359178b23adf725f9f22bf7392ec6d1c397",
        "edition": 56,
        "date": 1654567835817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "56",
        "rarity_score": 181.37343664647577,
        "rarity_level": "COMMON"
    },
    "57": {
        "name": "80's Arkade Bulls #57",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/57.png",
        "dna": "335feea4f372f5bb383838c82b48943c472ee656",
        "edition": 57,
        "date": 1654568082955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "57",
        "rarity_score": 59.84511149596255,
        "rarity_level": "COMMON"
    },
    "58": {
        "name": "80's Arkade Bulls #58",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/58.png",
        "dna": "d2f5694500767d6ebb6aee7d17f06648d5fff26f",
        "edition": 58,
        "date": 1654568148613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "58",
        "rarity_score": 119.29107714450633,
        "rarity_level": "COMMON"
    },
    "59": {
        "name": "80's Arkade Bulls #59",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/59.png",
        "dna": "56c3c54070fd56a802ccde8a6eb61c7bf3dcc17c",
        "edition": 59,
        "date": 1654567692170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "59",
        "rarity_score": 126.77588788569867,
        "rarity_level": "COMMON"
    },
    "60": {
        "name": "80's Arkade Bulls #60",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/60.png",
        "dna": "b143d451bfeabb02297cdf75ea7976ae3fcaf304",
        "edition": 60,
        "date": 1654568068056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "60",
        "rarity_score": 59.61114920016833,
        "rarity_level": "COMMON"
    },
    "61": {
        "name": "80's Arkade Bulls #61",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/61.png",
        "dna": "9a2ec218fa1517278437f36d7513254612bc973b",
        "edition": 61,
        "date": 1654567404427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "61",
        "rarity_score": 251.6093515290156,
        "rarity_level": "RARE"
    },
    "62": {
        "name": "80's Arkade Bulls #62",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/62.png",
        "dna": "cc2e1f2fe59b39a4306d01bc8debb793afd58ed6",
        "edition": 62,
        "date": 1654567433773,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "62",
        "rarity_score": 171.26241581669927,
        "rarity_level": "COMMON"
    },
    "63": {
        "name": "80's Arkade Bulls #63",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/63.png",
        "dna": "a10564fb9ecac78ab604c02c47931b9ab6fada0d",
        "edition": 63,
        "date": 1654568155704,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "63",
        "rarity_score": 251.31177746613687,
        "rarity_level": "RARE"
    },
    "64": {
        "name": "80's Arkade Bulls #64",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/64.png",
        "dna": "55d48faf245b6e6401fc2c871b4557ce2fbe0bae",
        "edition": 64,
        "date": 1654567172361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "64",
        "rarity_score": 84.79829149320675,
        "rarity_level": "COMMON"
    },
    "65": {
        "name": "80's Arkade Bulls #65",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/65.png",
        "dna": "71a3bf05b47ed9b9faee468596641bd15dc2cbf7",
        "edition": 65,
        "date": 1654568174365,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "65",
        "rarity_score": 118.43563287968607,
        "rarity_level": "COMMON"
    },
    "66": {
        "name": "80's Arkade Bulls #66",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/66.png",
        "dna": "2adf20a0d4e6e9fe26af6a4492f4d80f4cef524b",
        "edition": 66,
        "date": 1654567212559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rarity": 7.333333333333334,
        "rank": "Common",
        "id": "66",
        "rarity_score": 106.25061518950233,
        "rarity_level": "COMMON"
    },
    "67": {
        "name": "80's Arkade Bulls #67",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/67.png",
        "dna": "5e4178f36d47007eb7d7bb844ed98eedb1bee4f0",
        "edition": 67,
        "date": 1654567403918,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "67",
        "rarity_score": 113.32865610561964,
        "rarity_level": "COMMON"
    },
    "68": {
        "name": "80's Arkade Bulls #68",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/68.png",
        "dna": "291652645ded57ea4df841572e8d2bdcff9e4b31",
        "edition": 68,
        "date": 1654567193775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Epic",
        "rarity": 3.5999999999999996,
        "id": "68",
        "rarity_score": 159.42257939355304,
        "rarity_level": "COMMON"
    },
    "69": {
        "name": "80's Arkade Bulls #69",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/69.png",
        "dna": "55b57dfda815539a13a0326f7a9c56446d2fad9b",
        "edition": 69,
        "date": 1654567998256,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "69",
        "rarity_score": 96.37813673149823,
        "rarity_level": "COMMON"
    },
    "70": {
        "name": "80's Arkade Bulls #70",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/70.png",
        "dna": "c8582debbb33fa2941d280dfaed37c2c63b5513a",
        "edition": 70,
        "date": 1654567592367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "70",
        "rarity_score": 180.85638875542188,
        "rarity_level": "COMMON"
    },
    "71": {
        "name": "80's Arkade Bulls #71",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/71.png",
        "dna": "cf71806ebcaaf22be9be5cf968379ee5c3f8e9d2",
        "edition": 71,
        "date": 1654567514385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.571428571428571,
        "id": "71",
        "rarity_score": 182.07716219585313,
        "rarity_level": "COMMON"
    },
    "72": {
        "name": "80's Arkade Bulls #72",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/72.png",
        "dna": "946b9bd89ead115d0f41efb3d1049777342e6fef",
        "edition": 72,
        "date": 1654568119957,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "72",
        "rarity_score": 86.79001891801497,
        "rarity_level": "COMMON"
    },
    "73": {
        "name": "80's Arkade Bulls #73",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/73.png",
        "dna": "a2ce86be8bda4fb9b679380db90a63c5f13c57b0",
        "edition": 73,
        "date": 1654567244879,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "73",
        "rarity_score": 63.13530127471315,
        "rarity_level": "COMMON"
    },
    "74": {
        "name": "80's Arkade Bulls #74",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/74.png",
        "dna": "ab959337e205dcf0429b328ddbf78090de1e0beb",
        "edition": 74,
        "date": 1654567355725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.555555555555555,
        "id": "74",
        "rarity_score": 228.36920182404083,
        "rarity_level": "RARE"
    },
    "75": {
        "name": "80's Arkade Bulls #75",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/75.png",
        "dna": "2ae379444c9f0227cf740681791ce2a8e9b03927",
        "edition": 75,
        "date": 1654567350509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.111111111111111,
        "id": "75",
        "rarity_score": 225.79509162307363,
        "rarity_level": "RARE"
    },
    "76": {
        "name": "80's Arkade Bulls #76",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/76.png",
        "dna": "71c57b33825ef206664dd99801c0adf7decfc3c5",
        "edition": 76,
        "date": 1654567904600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "76",
        "rarity_score": 111.84672473453138,
        "rarity_level": "COMMON"
    },
    "77": {
        "name": "80's Arkade Bulls #77",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/77.png",
        "dna": "74ed5c3ac3ea5ac66a6dc61d082964ff1442f11e",
        "edition": 77,
        "date": 1654567143085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "77",
        "rarity_score": 92.18039677603497,
        "rarity_level": "COMMON"
    },
    "78": {
        "name": "80's Arkade Bulls #78",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/78.png",
        "dna": "a2ce3eef82411544813e3281875f686b5bfd23c5",
        "edition": 78,
        "date": 1654567842222,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "78",
        "rarity_score": 95.09169096915583,
        "rarity_level": "COMMON"
    },
    "79": {
        "name": "80's Arkade Bulls #79",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/79.png",
        "dna": "4b94cfb25233edd97942e79328e26613b5b318f8",
        "edition": 79,
        "date": 1654567483252,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "79",
        "rarity_score": 141.304708974782,
        "rarity_level": "COMMON"
    },
    "80": {
        "name": "80's Arkade Bulls #80",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/80.png",
        "dna": "17e0b8ed960c28e78163f29a173d9e7969724995",
        "edition": 80,
        "date": 1654567611883,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.25,
        "id": "80",
        "rarity_score": 158.9260809648133,
        "rarity_level": "COMMON"
    },
    "81": {
        "name": "80's Arkade Bulls #81",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/81.png",
        "dna": "bbf8da22f7a9fdb4a70f39340c978c40ecbffa52",
        "edition": 81,
        "date": 1654568103323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Epic",
        "rarity": 3.5999999999999996,
        "id": "81",
        "rarity_score": 146.82233594582263,
        "rarity_level": "COMMON"
    },
    "82": {
        "name": "80's Arkade Bulls #82",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/82.png",
        "dna": "0b4a768061561cefbcbddd76aa9968744fcf7d99",
        "edition": 82,
        "date": 1654568174889,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "82",
        "rarity_score": 89.89233014260006,
        "rarity_level": "COMMON"
    },
    "83": {
        "name": "80's Arkade Bulls #83",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/83.png",
        "dna": "4d7b8eb202d974a7c4958bcdfa47f51948dc6eac",
        "edition": 83,
        "date": 1654567118438,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "83",
        "rarity_score": 163.25182724252494,
        "rarity_level": "COMMON"
    },
    "84": {
        "name": "80's Arkade Bulls #84",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/84.png",
        "dna": "8cbcf399cfca4afcb258cfdcc39450c052f03417",
        "edition": 84,
        "date": 1654568143002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "84",
        "rarity_score": 151.44509913618327,
        "rarity_level": "COMMON"
    },
    "85": {
        "name": "80's Arkade Bulls #85",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/85.png",
        "dna": "1528ea2214443fc918120d878d009e681778e535",
        "edition": 85,
        "date": 1654567307015,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "85",
        "rarity_score": 103.95833989868979,
        "rarity_level": "COMMON"
    },
    "86": {
        "name": "80's Arkade Bulls #86",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/86.png",
        "dna": "39e7707c7702942288994d16ba0cda60ba3d071f",
        "edition": 86,
        "date": 1654567491003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "86",
        "rarity_score": 157.0156807868916,
        "rarity_level": "COMMON"
    },
    "87": {
        "name": "80's Arkade Bulls #87",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/87.png",
        "dna": "dbb7901de15bf37ae04bf701e6e0aa12a08b4ecf",
        "edition": 87,
        "date": 1654567819822,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "87",
        "rarity_score": 67.095049440353,
        "rarity_level": "COMMON"
    },
    "88": {
        "name": "80's Arkade Bulls #88",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/88.png",
        "dna": "a8e5e06353d97f747a61c6d875d95c0a714877a6",
        "edition": 88,
        "date": 1654567409033,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "88",
        "rarity_score": 196.80307933984406,
        "rarity_level": "COMMON"
    },
    "89": {
        "name": "80's Arkade Bulls #89",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/89.png",
        "dna": "8770cf19cb3369943759c48016fa56626c06723a",
        "edition": 89,
        "date": 1654568165225,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rarity": 4.5,
        "rank": "Epic",
        "id": "89",
        "rarity_score": 229.34422768303827,
        "rarity_level": "RARE"
    },
    "90": {
        "name": "80's Arkade Bulls #90",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/90.png",
        "dna": "88921703e29cfd21ed0cdcb4ac2e70819b00393f",
        "edition": 90,
        "date": 1654568020053,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "90",
        "rarity_score": 97.42926149705102,
        "rarity_level": "COMMON"
    },
    "91": {
        "name": "80's Arkade Bulls #91",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/91.png",
        "dna": "f1ada7e496eedf8d7aaa6e27c211db24d75f3ef4",
        "edition": 91,
        "date": 1654567667887,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "91",
        "rarity_score": 107.8050835323298,
        "rarity_level": "COMMON"
    },
    "92": {
        "name": "80's Arkade Bulls #92",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/92.png",
        "dna": "7df20f22e840e9811dad15fac3d737fe987baa25",
        "edition": 92,
        "date": 1654568089122,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "92",
        "rarity_score": 95.41882483987747,
        "rarity_level": "COMMON"
    },
    "93": {
        "name": "80's Arkade Bulls #93",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/93.png",
        "dna": "31a0ab10d28b45e344fad6bad4a56e84a3487efb",
        "edition": 93,
        "date": 1654567584090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "93",
        "rarity_score": 140.6830075446768,
        "rarity_level": "COMMON"
    },
    "94": {
        "name": "80's Arkade Bulls #94",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/94.png",
        "dna": "83640976b1e1c5aa3d1ea47c1f05d2381fc860d1",
        "edition": 94,
        "date": 1654567400488,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "94",
        "rarity_score": 197.65152045411327,
        "rarity_level": "COMMON"
    },
    "95": {
        "name": "80's Arkade Bulls #95",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/95.png",
        "dna": "7e7cedebc77055998bfdba30013a4a0078f47e29",
        "edition": 95,
        "date": 1654567555621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "95",
        "rarity_score": 191.75626952226267,
        "rarity_level": "COMMON"
    },
    "96": {
        "name": "80's Arkade Bulls #96",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/96.png",
        "dna": "673fadb9a894fcb81a118da0cdc737455fec4a39",
        "edition": 96,
        "date": 1654567497765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "96",
        "rarity_score": 138.1462758349221,
        "rarity_level": "COMMON"
    },
    "97": {
        "name": "80's Arkade Bulls #97",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/97.png",
        "dna": "cee968e49680d57b5906b91bb74179d443e4fb56",
        "edition": 97,
        "date": 1654567679302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "97",
        "rarity_score": 115.29646802874345,
        "rarity_level": "COMMON"
    },
    "98": {
        "name": "80's Arkade Bulls #98",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/98.png",
        "dna": "3aecafe2b66c6f80003a608a652a17e568cf6d4b",
        "edition": 98,
        "date": 1654567295666,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "98",
        "rarity_score": 90.98239122215698,
        "rarity_level": "COMMON"
    },
    "99": {
        "name": "80's Arkade Bulls #99",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/99.png",
        "dna": "b818e9e67fc03193373724efeca1943ff41c4ca3",
        "edition": 99,
        "date": 1654567426984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "99",
        "rarity_score": 135.62399918092927,
        "rarity_level": "COMMON"
    },
    "100": {
        "name": "80's Arkade Bulls #100",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/100.png",
        "dna": "aab4210aec4b84ef9e77a522878c6bdc7f505f1d",
        "edition": 100,
        "date": 1654567162377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "100",
        "rarity_score": 59.85081294478209,
        "rarity_level": "COMMON"
    },
    "101": {
        "name": "80's Arkade Bulls #101",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/101.png",
        "dna": "5d56c86316e2118548d39536f15d941362d8992c",
        "edition": 101,
        "date": 1654567296164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "101",
        "rarity_score": 145.8370823295886,
        "rarity_level": "COMMON"
    },
    "102": {
        "name": "80's Arkade Bulls #102",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/102.png",
        "dna": "c21c2bbea598dbb854399da71dcd2b03ccb35059",
        "edition": 102,
        "date": 1654567432584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "102",
        "rarity_score": 123.77325333114855,
        "rarity_level": "COMMON"
    },
    "103": {
        "name": "80's Arkade Bulls #103",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/103.png",
        "dna": "d94fd0d65a9ecd499c432befb866e9504ae74578",
        "edition": 103,
        "date": 1654567405974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "103",
        "rarity_score": 220.8533767512173,
        "rarity_level": "RARE"
    },
    "104": {
        "name": "80's Arkade Bulls #104",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/104.png",
        "dna": "f25c74e8c97b2b9a99aaef8acd33ae32679e0012",
        "edition": 104,
        "date": 1654567598916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "104",
        "rarity_score": 174.12900022662484,
        "rarity_level": "COMMON"
    },
    "105": {
        "name": "80's Arkade Bulls #105",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/105.png",
        "dna": "6f92d6779aee853cb57700aedd20383942577ea4",
        "edition": 105,
        "date": 1654567827362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "105",
        "rarity_score": 73.73637755874725,
        "rarity_level": "COMMON"
    },
    "106": {
        "name": "80's Arkade Bulls #106",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/106.png",
        "dna": "6c61dceccf5c901a093f24e4803b25cd23700539",
        "edition": 106,
        "date": 1654567978102,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "106",
        "rarity_score": 214.83329632448738,
        "rarity_level": "RARE"
    },
    "107": {
        "name": "80's Arkade Bulls #107",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/107.png",
        "dna": "5fa7dc584a4dc0464052aefa740a710445703315",
        "edition": 107,
        "date": 1654567460095,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "107",
        "rarity_score": 98.18257325054945,
        "rarity_level": "COMMON"
    },
    "108": {
        "name": "80's Arkade Bulls #108",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/108.png",
        "dna": "126103e8a2825d0d83b4020dc2ba71292cf00da6",
        "edition": 108,
        "date": 1654567976547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "108",
        "rarity_score": 165.4767977708713,
        "rarity_level": "COMMON"
    },
    "109": {
        "name": "80's Arkade Bulls #109",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/109.png",
        "dna": "4fdd47ba20cc5296e909a93fba8b0f60dc801267",
        "edition": 109,
        "date": 1654567965190,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "109",
        "rarity_score": 95.74505011437796,
        "rarity_level": "COMMON"
    },
    "110": {
        "name": "80's Arkade Bulls #110",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/110.png",
        "dna": "42c8a6516ff4b0b300bc5526e2e3ae067d33fa69",
        "edition": 110,
        "date": 1654567163295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "110",
        "rarity_score": 67.20615233526256,
        "rarity_level": "COMMON"
    },
    "111": {
        "name": "80's Arkade Bulls #111",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/111.png",
        "dna": "fa870630c37529dbdd3db5ff047c6386ee3e3616",
        "edition": 111,
        "date": 1654567836670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "111",
        "rarity_score": 90.48918233268141,
        "rarity_level": "COMMON"
    },
    "112": {
        "name": "80's Arkade Bulls #112",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/112.png",
        "dna": "0291815036348975e8024c7176356e36b3a8522c",
        "edition": 112,
        "date": 1654567631597,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Epic",
        "rarity": 4.25,
        "id": "112",
        "rarity_score": 234.3086833126739,
        "rarity_level": "RARE"
    },
    "113": {
        "name": "80's Arkade Bulls #113",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/113.png",
        "dna": "29d4e2da067d9cea64c09c67781f8e4d8e45bbf1",
        "edition": 113,
        "date": 1654568139634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "113",
        "rarity_score": 73.52048552115292,
        "rarity_level": "COMMON"
    },
    "114": {
        "name": "80's Arkade Bulls #114",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/114.png",
        "dna": "a9edd7055b1c1c504025c62bd8a11166e486d4bc",
        "edition": 114,
        "date": 1654568077588,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "114",
        "rarity_score": 77.03766281264022,
        "rarity_level": "COMMON"
    },
    "115": {
        "name": "80's Arkade Bulls #115",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/115.png",
        "dna": "c18ef60e6e1118e125b4cb377b1ce00fb9a8ae5e",
        "edition": 115,
        "date": 1654568046701,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 9.6,
        "id": "115",
        "rarity_score": 54.915340459177685,
        "rarity_level": "COMMON"
    },
    "116": {
        "name": "80's Arkade Bulls #116",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/116.png",
        "dna": "fd0cad6b6e2734a68e54ebac23783bc6d0c3ccf7",
        "edition": 116,
        "date": 1654568109831,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "116",
        "rarity_score": 79.64766762856948,
        "rarity_level": "COMMON"
    },
    "117": {
        "name": "80's Arkade Bulls #117",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/117.png",
        "dna": "c1bd31976c6b1bf9963e74e89b9168381a931f3a",
        "edition": 117,
        "date": 1654567957679,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "117",
        "rarity_score": 135.0971982898304,
        "rarity_level": "COMMON"
    },
    "118": {
        "name": "80's Arkade Bulls #118",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/118.png",
        "dna": "dd0124f3635d7609b1bf8b8e0d351464da3f5166",
        "edition": 118,
        "date": 1654567855646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "118",
        "rarity_score": 98.32280039161029,
        "rarity_level": "COMMON"
    },
    "119": {
        "name": "80's Arkade Bulls #119",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/119.png",
        "dna": "e56b07f2bc5e0502d3de424165257001fb584c12",
        "edition": 119,
        "date": 1654567519237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "119",
        "rarity_score": 170.77523282353687,
        "rarity_level": "COMMON"
    },
    "120": {
        "name": "80's Arkade Bulls #120",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/120.png",
        "dna": "b28c3b5038a4318d23ccbd8c1dd04efff8d16473",
        "edition": 120,
        "date": 1654567145898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "120",
        "rarity_score": 110.35893604031112,
        "rarity_level": "COMMON"
    },
    "121": {
        "name": "80's Arkade Bulls #121",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/121.png",
        "dna": "f221c9c538999ad396f5101ac030859b9eaa4540",
        "edition": 121,
        "date": 1654567911562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rarity": 6.857142857142858,
        "rank": "Common",
        "id": "121",
        "rarity_score": 120.17748801901129,
        "rarity_level": "COMMON"
    },
    "122": {
        "name": "80's Arkade Bulls #122",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/122.png",
        "dna": "f2079e4d5c819a41bd69e68c7b05cf8feb82ecd0",
        "edition": 122,
        "date": 1654567118887,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "122",
        "rarity_score": 99.96186440677967,
        "rarity_level": "COMMON"
    },
    "123": {
        "name": "80's Arkade Bulls #123",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/123.png",
        "dna": "787b51310982b2ae0dac57e659e87be76bc08aff",
        "edition": 123,
        "date": 1654567541831,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "123",
        "rarity_score": 131.25972763932734,
        "rarity_level": "COMMON"
    },
    "124": {
        "name": "80's Arkade Bulls #124",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/124.png",
        "dna": "8be7607e2635af4a01bb20c6895e861366ff287e",
        "edition": 124,
        "date": 1654567549538,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "124",
        "rarity_score": 151.53153949001074,
        "rarity_level": "COMMON"
    },
    "125": {
        "name": "80's Arkade Bulls #125",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/125.png",
        "dna": "d0d67fccbc77749a34f810b98b1fb67bfb152842",
        "edition": 125,
        "date": 1654567412561,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 2.666666666666667,
        "id": "125",
        "rarity_score": 261.5708801044443,
        "rarity_level": "RARE"
    },
    "126": {
        "name": "80's Arkade Bulls #126",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/126.png",
        "dna": "6ff2d8182a808615ddc07ed9db3509389db61931",
        "edition": 126,
        "date": 1654567424294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "126",
        "rarity_score": 153.626893630352,
        "rarity_level": "COMMON"
    },
    "127": {
        "name": "80's Arkade Bulls #127",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/127.png",
        "dna": "fdcd216a21bd0668bb068e5be8c8ed52e8ca2ef0",
        "edition": 127,
        "date": 1654567701088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "127",
        "rarity_score": 110.83929233549551,
        "rarity_level": "COMMON"
    },
    "128": {
        "name": "80's Arkade Bulls #128",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/128.png",
        "dna": "e25f99357099c82967ac20179041ec9fae1ad045",
        "edition": 128,
        "date": 1654568122781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "128",
        "rarity_score": 72.98800027241153,
        "rarity_level": "COMMON"
    },
    "129": {
        "name": "80's Arkade Bulls #129",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/129.png",
        "dna": "72b6869fff744a4354194b7f4e503916db40aa87",
        "edition": 129,
        "date": 1654567895035,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.571428571428571,
        "id": "129",
        "rarity_score": 80.48112243604764,
        "rarity_level": "COMMON"
    },
    "130": {
        "name": "80's Arkade Bulls #130",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/130.png",
        "dna": "63ea09261a527b8d12c2213149f995987b514d55",
        "edition": 130,
        "date": 1654567166035,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "130",
        "rarity_score": 104.53193985372079,
        "rarity_level": "COMMON"
    },
    "131": {
        "name": "80's Arkade Bulls #131",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/131.png",
        "dna": "2222e4f500de21994c1c82fd87ec038671feaf53",
        "edition": 131,
        "date": 1654567183562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "131",
        "rarity_score": 94.43466040777115,
        "rarity_level": "COMMON"
    },
    "132": {
        "name": "80's Arkade Bulls #132",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/132.png",
        "dna": "de4c17dd304141419d8ca45b24c17ecbfe1ac229",
        "edition": 132,
        "date": 1654567592877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "132",
        "rarity_score": 338.2674005173792,
        "rarity_level": "RARE"
    },
    "133": {
        "name": "80's Arkade Bulls #133",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/133.png",
        "dna": "63d97299006e2a8624d872a832a44bb21ae58271",
        "edition": 133,
        "date": 1654568091360,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "133",
        "rarity_score": 169.4500125549278,
        "rarity_level": "COMMON"
    },
    "134": {
        "name": "80's Arkade Bulls #134",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/134.png",
        "dna": "aa3e809f2da70ca90392d134b281d95985fa99ad",
        "edition": 134,
        "date": 1654567421224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "134",
        "rarity_score": 106.81618859768776,
        "rarity_level": "COMMON"
    },
    "135": {
        "name": "80's Arkade Bulls #135",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/135.png",
        "dna": "00145704f2f8907806e3109d5c558a9274c90ef1",
        "edition": 135,
        "date": 1654567870217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "135",
        "rarity_score": 108.0969114888284,
        "rarity_level": "COMMON"
    },
    "136": {
        "name": "80's Arkade Bulls #136",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/136.png",
        "dna": "c837f889f08f1f769ab039d982fcae0f5647ca9f",
        "edition": 136,
        "date": 1654567706625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "136",
        "rarity_score": 125.09742150109723,
        "rarity_level": "COMMON"
    },
    "137": {
        "name": "80's Arkade Bulls #137",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/137.png",
        "dna": "02f39a29863b30b8833f939135806a2276ce7079",
        "edition": 137,
        "date": 1654567842666,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "137",
        "rarity_score": 84.31639309421965,
        "rarity_level": "COMMON"
    },
    "138": {
        "name": "80's Arkade Bulls #138",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/138.png",
        "dna": "b5b5fe4b66ef7bd6628c58fd1e2e68954b4feba2",
        "edition": 138,
        "date": 1654567664519,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "138",
        "rarity_score": 154.63427661940315,
        "rarity_level": "COMMON"
    },
    "139": {
        "name": "80's Arkade Bulls #139",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/139.png",
        "dna": "4e43c03d89ece82b3fa1d0e40983887adfbc30a5",
        "edition": 139,
        "date": 1654567380053,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "139",
        "rarity_score": 144.28355161962725,
        "rarity_level": "COMMON"
    },
    "140": {
        "name": "80's Arkade Bulls #140",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/140.png",
        "dna": "0833eb245766980ec0cc5db54ad1696fb4f73335",
        "edition": 140,
        "date": 1654567923573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "140",
        "rarity_score": 101.79063031957389,
        "rarity_level": "COMMON"
    },
    "141": {
        "name": "80's Arkade Bulls #141",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/141.png",
        "dna": "c5b566c38dd8919b1f1322f8aaea2947b3503d22",
        "edition": 141,
        "date": 1654567960656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "141",
        "rarity_score": 124.07024860544524,
        "rarity_level": "COMMON"
    },
    "142": {
        "name": "80's Arkade Bulls #142",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/142.png",
        "dna": "8f7b7facff050b85d57ae25ae23be97b5dbb7b08",
        "edition": 142,
        "date": 1654568118569,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Epic",
        "rarity": 3.5999999999999996,
        "id": "142",
        "rarity_score": 270.0311921378721,
        "rarity_level": "RARE"
    },
    "143": {
        "name": "80's Arkade Bulls #143",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/143.png",
        "dna": "dfe9eea5b4f595502cb5a7fcc10aa360f5ddf2d8",
        "edition": 143,
        "date": 1654567192810,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "143",
        "rarity_score": 105.48373683857555,
        "rarity_level": "COMMON"
    },
    "144": {
        "name": "80's Arkade Bulls #144",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/144.png",
        "dna": "eeccc288dd0ad352c7c1b701289478a980dbb01d",
        "edition": 144,
        "date": 1654567461266,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "144",
        "rarity_score": 123.42290565621063,
        "rarity_level": "COMMON"
    },
    "145": {
        "name": "80's Arkade Bulls #145",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/145.png",
        "dna": "f8d508e50b3d3741eb8f594f13c7ff3e0e2b3efc",
        "edition": 145,
        "date": 1654567640080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.25,
        "id": "145",
        "rarity_score": 111.78620107515566,
        "rarity_level": "COMMON"
    },
    "146": {
        "name": "80's Arkade Bulls #146",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/146.png",
        "dna": "0d028f78b327f84798801e0590c4214d180f2069",
        "edition": 146,
        "date": 1654568206639,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "146",
        "rarity_score": 148.36491632306573,
        "rarity_level": "COMMON"
    },
    "147": {
        "name": "80's Arkade Bulls #147",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/147.png",
        "dna": "89f3a3c0b29a177511d281bdd0e6e6bb8125badf",
        "edition": 147,
        "date": 1654567245350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666666,
        "id": "147",
        "rarity_score": 147.4579186923022,
        "rarity_level": "COMMON"
    },
    "148": {
        "name": "80's Arkade Bulls #148",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/148.png",
        "dna": "1bbd355f33242ad2ddc78cb24544b5cbb0c6869b",
        "edition": 148,
        "date": 1654567115282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "MkFly Jacket",
                "score": 1980.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rarity": 0,
        "rank": "Legendary",
        "id": "148",
        "rarity_score": 2183.899615479325,
        "rarity_level": "EPIC"
    },
    "149": {
        "name": "80's Arkade Bulls #149",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/149.png",
        "dna": "3003f914309fc3e6cb5f10b4152106fbc09aab08",
        "edition": 149,
        "date": 1654567362964,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "149",
        "rarity_score": 224.2913788850355,
        "rarity_level": "RARE"
    },
    "150": {
        "name": "80's Arkade Bulls #150",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/150.png",
        "dna": "4bac9313d61809fe5faa5e9f2f55d0b2eb5ba150",
        "edition": 150,
        "date": 1654567401466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "150",
        "rarity_score": 133.1430283215005,
        "rarity_level": "COMMON"
    },
    "151": {
        "name": "80's Arkade Bulls #151",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/151.png",
        "dna": "8a3a28369d97d6c52ef1d48016b6389003e01fdb",
        "edition": 151,
        "date": 1654567516579,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "151",
        "rarity_score": 209.97418077993498,
        "rarity_level": "RARE"
    },
    "152": {
        "name": "80's Arkade Bulls #152",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/152.png",
        "dna": "40b6d2c64312d8e361b79a2ce4bd023df2b0bf66",
        "edition": 152,
        "date": 1654567425352,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "152",
        "rarity_score": 95.92935502792241,
        "rarity_level": "COMMON"
    },
    "153": {
        "name": "80's Arkade Bulls #153",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/153.png",
        "dna": "0b84120cac157e798de178d79f42f630172c263b",
        "edition": 153,
        "date": 1654567953245,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "153",
        "rarity_score": 154.98122806087093,
        "rarity_level": "COMMON"
    },
    "154": {
        "name": "80's Arkade Bulls #154",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/154.png",
        "dna": "dc6b2615ce29137e37266552f08fe7bd7b6d442b",
        "edition": 154,
        "date": 1654567215651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "154",
        "rarity_score": 62.41212234306387,
        "rarity_level": "COMMON"
    },
    "155": {
        "name": "80's Arkade Bulls #155",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/155.png",
        "dna": "6a702b9d57d6b6f9517a104b9af0e1dada0fbf80",
        "edition": 155,
        "date": 1654568172791,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "155",
        "rarity_score": 160.31415541262743,
        "rarity_level": "COMMON"
    },
    "156": {
        "name": "80's Arkade Bulls #156",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/156.png",
        "dna": "ade88d09984a090bd098ca788d70bb3b9db8ccd0",
        "edition": 156,
        "date": 1654568030221,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "156",
        "rarity_score": 186.87645320197046,
        "rarity_level": "COMMON"
    },
    "157": {
        "name": "80's Arkade Bulls #157",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/157.png",
        "dna": "467e0f7521e7a557945674b2ba84241c949f8f9d",
        "edition": 157,
        "date": 1654567504999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "157",
        "rarity_score": 156.0892064518752,
        "rarity_level": "COMMON"
    },
    "158": {
        "name": "80's Arkade Bulls #158",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/158.png",
        "dna": "f98bb5064f73f752ccc1e279bb57dfb2631ba635",
        "edition": 158,
        "date": 1654567501676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "158",
        "rarity_score": 290.43194183400067,
        "rarity_level": "RARE"
    },
    "159": {
        "name": "80's Arkade Bulls #159",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/159.png",
        "dna": "f9a42d774238773e69682cd1bbdf411a718745e4",
        "edition": 159,
        "date": 1654568166146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "159",
        "rarity_score": 106.01026555587626,
        "rarity_level": "COMMON"
    },
    "160": {
        "name": "80's Arkade Bulls #160",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/160.png",
        "dna": "f67a95bd3f4addb02de05e5ba6a4e06d4e6202d7",
        "edition": 160,
        "date": 1654567328840,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.222222222222222,
        "id": "160",
        "rarity_score": 241.60671086113575,
        "rarity_level": "RARE"
    },
    "161": {
        "name": "80's Arkade Bulls #161",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/161.png",
        "dna": "2f59b57727c5100149588bb24f1c996bee4fa614",
        "edition": 161,
        "date": 1654568021046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "161",
        "rarity_score": 129.4307605986428,
        "rarity_level": "COMMON"
    },
    "162": {
        "name": "80's Arkade Bulls #162",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/162.png",
        "dna": "0e1c7ae9d3450f64d91ce284896541998f29b03c",
        "edition": 162,
        "date": 1654567874305,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "162",
        "rarity_score": 107.68398190488874,
        "rarity_level": "COMMON"
    },
    "163": {
        "name": "80's Arkade Bulls #163",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/163.png",
        "dna": "db6efd1274fec60ad4cd7291abdf114d8244c493",
        "edition": 163,
        "date": 1654568000236,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "163",
        "rarity_score": 191.04496797276244,
        "rarity_level": "COMMON"
    },
    "164": {
        "name": "80's Arkade Bulls #164",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/164.png",
        "dna": "1934eee68ee76660dffdb31dc83495aa60ce3986",
        "edition": 164,
        "date": 1654568028459,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "164",
        "rarity_score": 66.03237376729078,
        "rarity_level": "COMMON"
    },
    "165": {
        "name": "80's Arkade Bulls #165",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/165.png",
        "dna": "2da516f8ee2c524b9ddf253a9d33f9f7147775c0",
        "edition": 165,
        "date": 1654567870703,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "165",
        "rarity_score": 108.61366054508156,
        "rarity_level": "COMMON"
    },
    "166": {
        "name": "80's Arkade Bulls #166",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/166.png",
        "dna": "dfbb33501170be5aef5a9f2ac519d673fb7f3912",
        "edition": 166,
        "date": 1654568107479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "166",
        "rarity_score": 58.999257886095336,
        "rarity_level": "COMMON"
    },
    "167": {
        "name": "80's Arkade Bulls #167",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/167.png",
        "dna": "fbd24cb6905bcffb1c1a55073168db59b96f06a0",
        "edition": 167,
        "date": 1654567348566,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "167",
        "rarity_score": 200.4630959364642,
        "rarity_level": "COMMON"
    },
    "168": {
        "name": "80's Arkade Bulls #168",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/168.png",
        "dna": "ca9972b4fb2f0d0bf7d954032c84c2fdcfae33db",
        "edition": 168,
        "date": 1654567841794,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "168",
        "rarity_score": 99.12968780275018,
        "rarity_level": "COMMON"
    },
    "169": {
        "name": "80's Arkade Bulls #169",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/169.png",
        "dna": "04f34bb842ac8e573ded57c3f839c104b9007fd0",
        "edition": 169,
        "date": 1654567200794,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "169",
        "rarity_score": 80.56236262133265,
        "rarity_level": "COMMON"
    },
    "170": {
        "name": "80's Arkade Bulls #170",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/170.png",
        "dna": "d5c137376923a680267ac309c5522c130edc2ec2",
        "edition": 170,
        "date": 1654567567775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "170",
        "rarity_score": 199.39671099602708,
        "rarity_level": "COMMON"
    },
    "171": {
        "name": "80's Arkade Bulls #171",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/171.png",
        "dna": "1cbf30fafaf3e05bb08bb5a28d5775142a94d985",
        "edition": 171,
        "date": 1654567341434,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.777777777777778,
        "id": "171",
        "rarity_score": 241.91059168502923,
        "rarity_level": "RARE"
    },
    "172": {
        "name": "80's Arkade Bulls #172",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/172.png",
        "dna": "6773867c2bdfa66e79f0a9e9278aeb3be97f2347",
        "edition": 172,
        "date": 1654567863099,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "172",
        "rarity_score": 229.54705583227266,
        "rarity_level": "RARE"
    },
    "173": {
        "name": "80's Arkade Bulls #173",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/173.png",
        "dna": "273186d4571469bd9d46e091502cd1444834d9d3",
        "edition": 173,
        "date": 1654567951735,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "173",
        "rarity_score": 93.47950634418325,
        "rarity_level": "COMMON"
    },
    "174": {
        "name": "80's Arkade Bulls #174",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/174.png",
        "dna": "9175f5e8a6e04f9a853be8b20313c435999b557f",
        "edition": 174,
        "date": 1654567429778,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "174",
        "rarity_score": 93.05858520494142,
        "rarity_level": "COMMON"
    },
    "175": {
        "name": "80's Arkade Bulls #175",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/175.png",
        "dna": "999bb047744ffaf438ad02f257898886a726f6d2",
        "edition": 175,
        "date": 1654567995378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "175",
        "rarity_score": 91.58982948169852,
        "rarity_level": "COMMON"
    },
    "176": {
        "name": "80's Arkade Bulls #176",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/176.png",
        "dna": "8bcc7c0f244408653eff15ce78ec7faae63e823a",
        "edition": 176,
        "date": 1654567527601,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "176",
        "rarity_score": 167.46472310629667,
        "rarity_level": "COMMON"
    },
    "177": {
        "name": "80's Arkade Bulls #177",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/177.png",
        "dna": "5393d4168693271897e41e92cbb60fa85439105c",
        "edition": 177,
        "date": 1654567714838,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666666,
        "id": "177",
        "rarity_score": 178.46884653199965,
        "rarity_level": "COMMON"
    },
    "178": {
        "name": "80's Arkade Bulls #178",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/178.png",
        "dna": "aedfe5d13538a580199d83979d200f066eb9d66a",
        "edition": 178,
        "date": 1654567375136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "178",
        "rarity_score": 175.69452052506765,
        "rarity_level": "COMMON"
    },
    "179": {
        "name": "80's Arkade Bulls #179",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/179.png",
        "dna": "18db0089ea6d5b2ddffd11333f3b023990ee52b8",
        "edition": 179,
        "date": 1654568110299,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "179",
        "rarity_score": 93.17141666807012,
        "rarity_level": "COMMON"
    },
    "180": {
        "name": "80's Arkade Bulls #180",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/180.png",
        "dna": "a0c99ab556ac44332191f4433b35a9b6de755a7f",
        "edition": 180,
        "date": 1654567122494,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "180",
        "rarity_score": 92.48388018350015,
        "rarity_level": "COMMON"
    },
    "181": {
        "name": "80's Arkade Bulls #181",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/181.png",
        "dna": "884603ee6ec207a5b9782f631ee10972a3aad1d3",
        "edition": 181,
        "date": 1654568078448,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "181",
        "rarity_score": 180.27777158608802,
        "rarity_level": "COMMON"
    },
    "182": {
        "name": "80's Arkade Bulls #182",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/182.png",
        "dna": "3193f9460bb68e8cf96f4367b7e9d5b73742535a",
        "edition": 182,
        "date": 1654567833780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333334,
        "id": "182",
        "rarity_score": 119.28193594401947,
        "rarity_level": "COMMON"
    },
    "183": {
        "name": "80's Arkade Bulls #183",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/183.png",
        "dna": "87521400dd90fdbf910071d5bca5abc49b95b745",
        "edition": 183,
        "date": 1654567576987,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "183",
        "rarity_score": 179.2842480336337,
        "rarity_level": "COMMON"
    },
    "184": {
        "name": "80's Arkade Bulls #184",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/184.png",
        "dna": "9092eac3db2855cd62eab13088e7d1a43c143b1c",
        "edition": 184,
        "date": 1654567232335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "184",
        "rarity_score": 85.42138163390666,
        "rarity_level": "COMMON"
    },
    "185": {
        "name": "80's Arkade Bulls #185",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/185.png",
        "dna": "5e637353c9b2fe8746d9fadde922ad631a3a17c9",
        "edition": 185,
        "date": 1654567587307,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "185",
        "rarity_score": 234.386526191566,
        "rarity_level": "RARE"
    },
    "186": {
        "name": "80's Arkade Bulls #186",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/186.png",
        "dna": "c0bfd65f959b0198a6669d277ad56cf518410dcf",
        "edition": 186,
        "date": 1654567594403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.166666666666667,
        "id": "186",
        "rarity_score": 226.9433714381425,
        "rarity_level": "RARE"
    },
    "187": {
        "name": "80's Arkade Bulls #187",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/187.png",
        "dna": "8c9296a99d97c4e958183774b6bcebac249eccd6",
        "edition": 187,
        "date": 1654567263909,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "187",
        "rarity_score": 115.70570963428108,
        "rarity_level": "COMMON"
    },
    "188": {
        "name": "80's Arkade Bulls #188",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/188.png",
        "dna": "8734946ae385b3f2cbdbba311bc47b0c57d5b7c0",
        "edition": 188,
        "date": 1654567150437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "188",
        "rarity_score": 101.19677577364145,
        "rarity_level": "COMMON"
    },
    "189": {
        "name": "80's Arkade Bulls #189",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/189.png",
        "dna": "d5413eddc368dbaec64e1c82eecc5876b79483c7",
        "edition": 189,
        "date": 1654567962173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "189",
        "rarity_score": 127.73034636340473,
        "rarity_level": "COMMON"
    },
    "190": {
        "name": "80's Arkade Bulls #190",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/190.png",
        "dna": "61e3e88690807e2da030315674fdbe524570c9cc",
        "edition": 190,
        "date": 1654567862625,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "190",
        "rarity_score": 98.45150979645473,
        "rarity_level": "COMMON"
    },
    "191": {
        "name": "80's Arkade Bulls #191",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/191.png",
        "dna": "8bed022235da3f44712d064955fb568339476854",
        "edition": 191,
        "date": 1654567715888,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666666,
        "id": "191",
        "rarity_score": 118.75750703423078,
        "rarity_level": "COMMON"
    },
    "192": {
        "name": "80's Arkade Bulls #192",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/192.png",
        "dna": "2a3cbe9a482c62868a65f1ec5f3e016a72be5bd5",
        "edition": 192,
        "date": 1654568082076,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "192",
        "rarity_score": 73.7415793424628,
        "rarity_level": "COMMON"
    },
    "193": {
        "name": "80's Arkade Bulls #193",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/193.png",
        "dna": "26fde1dcdee0a6640202759dc55a25431c5f8dc4",
        "edition": 193,
        "date": 1654567274687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "193",
        "rarity_score": 99.59785222214194,
        "rarity_level": "COMMON"
    },
    "194": {
        "name": "80's Arkade Bulls #194",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/194.png",
        "dna": "a963078c7ad63c165e29d4088db2e4e97ff50d82",
        "edition": 194,
        "date": 1654568221619,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Super",
        "rarity": 1.7999999999999998,
        "id": "194",
        "rarity_score": 218.67493796526054,
        "rarity_level": "RARE"
    },
    "195": {
        "name": "80's Arkade Bulls #195",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/195.png",
        "dna": "66ad30285c0c4e4e31a662602a00486f7834310a",
        "edition": 195,
        "date": 1654567458456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "195",
        "rarity_score": 103.15932198617594,
        "rarity_level": "COMMON"
    },
    "196": {
        "name": "80's Arkade Bulls #196",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/196.png",
        "dna": "ab3c8d764e2d5c86797b9c0f869d0807ca50115a",
        "edition": 196,
        "date": 1654568200391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "196",
        "rarity_score": 94.57692297478523,
        "rarity_level": "COMMON"
    },
    "197": {
        "name": "80's Arkade Bulls #197",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/197.png",
        "dna": "d266af39491216ea5936a4b9c2b4f99309ed2fda",
        "edition": 197,
        "date": 1654567520851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "197",
        "rarity_score": 202.1484260571436,
        "rarity_level": "COMMON"
    },
    "198": {
        "name": "80's Arkade Bulls #198",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/198.png",
        "dna": "182ddd0e53d60933e6c67386c40f3e9b5bce31dd",
        "edition": 198,
        "date": 1654567138500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "198",
        "rarity_score": 120.56309590029457,
        "rarity_level": "COMMON"
    },
    "199": {
        "name": "80's Arkade Bulls #199",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/199.png",
        "dna": "2d3335f844d601931753ced42f151461b3512621",
        "edition": 199,
        "date": 1654567540763,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "199",
        "rarity_score": 120.62741940922325,
        "rarity_level": "COMMON"
    },
    "200": {
        "name": "80's Arkade Bulls #200",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/200.png",
        "dna": "85e8ec41da3618b0745a593a44ca3dec8ca537bd",
        "edition": 200,
        "date": 1654567285439,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "200",
        "rarity_score": 152.6212966240445,
        "rarity_level": "COMMON"
    },
    "201": {
        "name": "80's Arkade Bulls #201",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/201.png",
        "dna": "fabbf315b9936eab3b20cef831b7e52cef2c8c97",
        "edition": 201,
        "date": 1654567722084,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666666,
        "id": "201",
        "rarity_score": 131.10381640448642,
        "rarity_level": "COMMON"
    },
    "202": {
        "name": "80's Arkade Bulls #202",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/202.png",
        "dna": "bd572e17e2dcc2c74c7ea7ee5e05b6dcc6ddcac9",
        "edition": 202,
        "date": 1654567885878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "202",
        "rarity_score": 91.00314480062684,
        "rarity_level": "COMMON"
    },
    "203": {
        "name": "80's Arkade Bulls #203",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/203.png",
        "dna": "57bfddb45d955d7854a4be78d327b21f19a8e44d",
        "edition": 203,
        "date": 1654567857666,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "203",
        "rarity_score": 112.88773011677267,
        "rarity_level": "COMMON"
    },
    "204": {
        "name": "80's Arkade Bulls #204",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/204.png",
        "dna": "88de4fb01c143a1ebae3ef52e26812cac447efce",
        "edition": 204,
        "date": 1654567636952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "204",
        "rarity_score": 116.30170805764607,
        "rarity_level": "COMMON"
    },
    "205": {
        "name": "80's Arkade Bulls #205",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/205.png",
        "dna": "a5b011ae146071d04d94993172674c91393c4237",
        "edition": 205,
        "date": 1654567344725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.2222222222222223,
        "id": "205",
        "rarity_score": 336.99606645652284,
        "rarity_level": "RARE"
    },
    "206": {
        "name": "80's Arkade Bulls #206",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/206.png",
        "dna": "9de6cecd14f3e578b83200555d71136178bafc6d",
        "edition": 206,
        "date": 1654567561668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "206",
        "rarity_score": 159.6842630248505,
        "rarity_level": "COMMON"
    },
    "207": {
        "name": "80's Arkade Bulls #207",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/207.png",
        "dna": "61282ae65fb1a645f132b89dade4861dde3037d7",
        "edition": 207,
        "date": 1654568151142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "207",
        "rarity_score": 167.01775260432248,
        "rarity_level": "COMMON"
    },
    "208": {
        "name": "80's Arkade Bulls #208",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/208.png",
        "dna": "538e9743a49a523e37b03ee831776212dd90581a",
        "edition": 208,
        "date": 1654567456813,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "208",
        "rarity_score": 83.92719540693727,
        "rarity_level": "COMMON"
    },
    "209": {
        "name": "80's Arkade Bulls #209",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/209.png",
        "dna": "dcbe24f02ba5cc353ae3e1b520407b18f1d62c80",
        "edition": 209,
        "date": 1654567952288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "209",
        "rarity_score": 122.72011519661847,
        "rarity_level": "COMMON"
    },
    "210": {
        "name": "80's Arkade Bulls #210",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/210.png",
        "dna": "4a17b905739f3c538a4fec35f6bca892949084d5",
        "edition": 210,
        "date": 1654567517107,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "210",
        "rarity_score": 141.61414913231073,
        "rarity_level": "COMMON"
    },
    "211": {
        "name": "80's Arkade Bulls #211",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/211.png",
        "dna": "5361e973ec67ae47ba13378852aeeb9d663d7f48",
        "edition": 211,
        "date": 1654568056239,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "211",
        "rarity_score": 60.02955911759952,
        "rarity_level": "COMMON"
    },
    "212": {
        "name": "80's Arkade Bulls #212",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/212.png",
        "dna": "a98871012046dbe23b524f531a4be8b134c47170",
        "edition": 212,
        "date": 1654567886934,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "212",
        "rarity_score": 207.8248260325638,
        "rarity_level": "RARE"
    },
    "213": {
        "name": "80's Arkade Bulls #213",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/213.png",
        "dna": "09e00b6d51631d048cdcf676b6894ad1c3d1845f",
        "edition": 213,
        "date": 1654567509358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "213",
        "rarity_score": 173.65204040901847,
        "rarity_level": "COMMON"
    },
    "214": {
        "name": "80's Arkade Bulls #214",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/214.png",
        "dna": "efa08545f5a818187955bded3d5118e86800bc6c",
        "edition": 214,
        "date": 1654568002760,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "214",
        "rarity_score": 101.11399877292355,
        "rarity_level": "COMMON"
    },
    "215": {
        "name": "80's Arkade Bulls #215",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/215.png",
        "dna": "c4134c2a9d85ecd853603c1fb93a2bfdef9fd204",
        "edition": 215,
        "date": 1654567399512,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "215",
        "rarity_score": 169.7338707793977,
        "rarity_level": "COMMON"
    },
    "216": {
        "name": "80's Arkade Bulls #216",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/216.png",
        "dna": "fb824e1dd4196ee2ad3fd590da2ecb99f2216b2d",
        "edition": 216,
        "date": 1654567342745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "216",
        "rarity_score": 188.1227365162843,
        "rarity_level": "COMMON"
    },
    "217": {
        "name": "80's Arkade Bulls #217",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/217.png",
        "dna": "d61fd55059493c6b3701f6cdeae44239ca484005",
        "edition": 217,
        "date": 1654568117722,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "217",
        "rarity_score": 219.05238095238096,
        "rarity_level": "RARE"
    },
    "218": {
        "name": "80's Arkade Bulls #218",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/218.png",
        "dna": "dcac2d21a92daa38be806d5d810600388b9dcddd",
        "edition": 218,
        "date": 1654567941127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "218",
        "rarity_score": 93.41873026176559,
        "rarity_level": "COMMON"
    },
    "219": {
        "name": "80's Arkade Bulls #219",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/219.png",
        "dna": "e181a1aff149df7806e72d6f53c54d55b29ed164",
        "edition": 219,
        "date": 1654568083380,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "219",
        "rarity_score": 58.60052498776332,
        "rarity_level": "COMMON"
    },
    "220": {
        "name": "80's Arkade Bulls #220",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/220.png",
        "dna": "1308cf3516d25362f1f415400cc4858bf0e92881",
        "edition": 220,
        "date": 1654567691632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "220",
        "rarity_score": 186.483905333504,
        "rarity_level": "COMMON"
    },
    "221": {
        "name": "80's Arkade Bulls #221",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/221.png",
        "dna": "521a7ce41396fe225ad64014d18e682faa217afc",
        "edition": 221,
        "date": 1654567651633,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "221",
        "rarity_score": 121.68292805262728,
        "rarity_level": "COMMON"
    },
    "222": {
        "name": "80's Arkade Bulls #222",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/222.png",
        "dna": "a83608e180004dd618edc74ff89e592e06a7da54",
        "edition": 222,
        "date": 1654567269170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "222",
        "rarity_score": 108.85178868384604,
        "rarity_level": "COMMON"
    },
    "223": {
        "name": "80's Arkade Bulls #223",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/223.png",
        "dna": "772a6c0f524d7cddfb8d17efe9b8f213e56d53df",
        "edition": 223,
        "date": 1654567384989,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "223",
        "rarity_score": 195.66077331909267,
        "rarity_level": "COMMON"
    },
    "224": {
        "name": "80's Arkade Bulls #224",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/224.png",
        "dna": "205a742447e044f0aae2d9b714892daf693cdaee",
        "edition": 224,
        "date": 1654567826513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.333333333333334,
        "id": "224",
        "rarity_score": 67.38362741993987,
        "rarity_level": "COMMON"
    },
    "225": {
        "name": "80's Arkade Bulls #225",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/225.png",
        "dna": "91f55544dd9075c11c9e463b61613d7751f9b3ec",
        "edition": 225,
        "date": 1654567521933,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 3.7142857142857135,
        "id": "225",
        "rarity_score": 176.384428118616,
        "rarity_level": "COMMON"
    },
    "226": {
        "name": "80's Arkade Bulls #226",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/226.png",
        "dna": "d1cc995eaef00773acf2251e7038b81e0a444c55",
        "edition": 226,
        "date": 1654567839188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "226",
        "rarity_score": 135.96288629674834,
        "rarity_level": "COMMON"
    },
    "227": {
        "name": "80's Arkade Bulls #227",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/227.png",
        "dna": "c0ced867dc60a6ddb33299905ba158dcffdbf10b",
        "edition": 227,
        "date": 1654567353759,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "227",
        "rarity_score": 233.35732917539644,
        "rarity_level": "RARE"
    },
    "228": {
        "name": "80's Arkade Bulls #228",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/228.png",
        "dna": "358af1b2144bada4366eed4a3962e4f91f8f021b",
        "edition": 228,
        "date": 1654567989961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "228",
        "rarity_score": 81.08555110409827,
        "rarity_level": "COMMON"
    },
    "229": {
        "name": "80's Arkade Bulls #229",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/229.png",
        "dna": "4e5065bf412aa9675f02774f2f605f98926d2353",
        "edition": 229,
        "date": 1654567648689,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "229",
        "rarity_score": 207.1413901868441,
        "rarity_level": "RARE"
    },
    "230": {
        "name": "80's Arkade Bulls #230",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/230.png",
        "dna": "6470ddc4e89f88983f56e44d4ffc846665245f18",
        "edition": 230,
        "date": 1654567723653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "230",
        "rarity_score": 73.89181362279926,
        "rarity_level": "COMMON"
    },
    "231": {
        "name": "80's Arkade Bulls #231",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/231.png",
        "dna": "a3ee61cdaa80b6cda6c39a55b6d864172a159aa4",
        "edition": 231,
        "date": 1654567117126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "231",
        "rarity_score": 73.05013796563402,
        "rarity_level": "COMMON"
    },
    "232": {
        "name": "80's Arkade Bulls #232",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/232.png",
        "dna": "85b347be7b873442df6b8548b394d8c85ef5e6f0",
        "edition": 232,
        "date": 1654567913688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "232",
        "rarity_score": 150.42170076517397,
        "rarity_level": "COMMON"
    },
    "233": {
        "name": "80's Arkade Bulls #233",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/233.png",
        "dna": "960762f96c3bb656c26e76bc3639ea7cadc898dd",
        "edition": 233,
        "date": 1654567166493,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "233",
        "rarity_score": 80.27175726442383,
        "rarity_level": "COMMON"
    },
    "234": {
        "name": "80's Arkade Bulls #234",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/234.png",
        "dna": "563eeaeb641617ea83e4c5f8730aa5eb9d192921",
        "edition": 234,
        "date": 1654568158740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "234",
        "rarity_score": 202.95256398968007,
        "rarity_level": "RARE"
    },
    "235": {
        "name": "80's Arkade Bulls #235",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/235.png",
        "dna": "30fcb57d2755e3acf2b0d7dca9221b2595eb9542",
        "edition": 235,
        "date": 1654568072078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "235",
        "rarity_score": 88.84165840120741,
        "rarity_level": "COMMON"
    },
    "236": {
        "name": "80's Arkade Bulls #236",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/236.png",
        "dna": "8ddb4280fc3a15be272d6b0019c379c9127c8b9d",
        "edition": 236,
        "date": 1654568156225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "236",
        "rarity_score": 143.09203445187956,
        "rarity_level": "COMMON"
    },
    "237": {
        "name": "80's Arkade Bulls #237",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/237.png",
        "dna": "3afc84cc94e08f493ef721532346fdbd97f2c996",
        "edition": 237,
        "date": 1654567577509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857142,
        "id": "237",
        "rarity_score": 205.42784996970516,
        "rarity_level": "RARE"
    },
    "238": {
        "name": "80's Arkade Bulls #238",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/238.png",
        "dna": "b61c89c5a8261fb4a664914ec82a24190bbdf41f",
        "edition": 238,
        "date": 1654567335294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "238",
        "rarity_score": 306.94278144065805,
        "rarity_level": "RARE"
    },
    "239": {
        "name": "80's Arkade Bulls #239",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/239.png",
        "dna": "bccbf308fb4516d2da5c0c2a1c7a9a69a51d4975",
        "edition": 239,
        "date": 1654567144955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "239",
        "rarity_score": 68.84638110286406,
        "rarity_level": "COMMON"
    },
    "240": {
        "name": "80's Arkade Bulls #240",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/240.png",
        "dna": "d6d1f916c1415657478c895d9451b69110ba3951",
        "edition": 240,
        "date": 1654567284402,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "240",
        "rarity_score": 93.98151126190874,
        "rarity_level": "COMMON"
    },
    "241": {
        "name": "80's Arkade Bulls #241",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/241.png",
        "dna": "a0db9c9ba67b0b66c0e1f6a2d6ac57598154b668",
        "edition": 241,
        "date": 1654567848645,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "241",
        "rarity_score": 103.67845043459863,
        "rarity_level": "COMMON"
    },
    "242": {
        "name": "80's Arkade Bulls #242",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/242.png",
        "dna": "7925fe85ae53f589a0884cfadef8278bd6174de0",
        "edition": 242,
        "date": 1654567226543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "242",
        "rarity_score": 83.18494364538911,
        "rarity_level": "COMMON"
    },
    "243": {
        "name": "80's Arkade Bulls #243",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/243.png",
        "dna": "4ae6f89b39d4b8a4cd5a7087ebb7ec64ac8ff315",
        "edition": 243,
        "date": 1654567607113,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.125,
        "id": "243",
        "rarity_score": 231.97482446346785,
        "rarity_level": "RARE"
    },
    "244": {
        "name": "80's Arkade Bulls #244",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/244.png",
        "dna": "170df23a0301888d24118ba7e9f4950361971143",
        "edition": 244,
        "date": 1654567378088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "244",
        "rarity_score": 400.70637065637067,
        "rarity_level": "RARE"
    },
    "245": {
        "name": "80's Arkade Bulls #245",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/245.png",
        "dna": "80f9bce02c7f3d8de157c5fb6d6a7d25174e596f",
        "edition": 245,
        "date": 1654568075821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Epic",
        "rarity": 4.2,
        "id": "245",
        "rarity_score": 98.19357840217344,
        "rarity_level": "COMMON"
    },
    "246": {
        "name": "80's Arkade Bulls #246",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/246.png",
        "dna": "80b52aa27a4a7111511262ddd41f5ef3898d3fc8",
        "edition": 246,
        "date": 1654568092665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "246",
        "rarity_score": 55.40843644090292,
        "rarity_level": "COMMON"
    },
    "247": {
        "name": "80's Arkade Bulls #247",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/247.png",
        "dna": "26a941f966e6c4600f721c6c92c5095439333c1e",
        "edition": 247,
        "date": 1654567845733,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "247",
        "rarity_score": 69.49893861979356,
        "rarity_level": "COMMON"
    },
    "248": {
        "name": "80's Arkade Bulls #248",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/248.png",
        "dna": "2d253efabcf16a19c39b2434dcc295d27256f487",
        "edition": 248,
        "date": 1654567573174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "248",
        "rarity_score": 250.53280574371414,
        "rarity_level": "RARE"
    },
    "249": {
        "name": "80's Arkade Bulls #249",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/249.png",
        "dna": "fb13c3051e955f427f0a7e3cb65acee1d3d76e84",
        "edition": 249,
        "date": 1654567256161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "249",
        "rarity_score": 169.70302511494333,
        "rarity_level": "COMMON"
    },
    "250": {
        "name": "80's Arkade Bulls #250",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/250.png",
        "dna": "c123ec578ede245c25080d7bc543c0c3ea224b68",
        "edition": 250,
        "date": 1654567833353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "250",
        "rarity_score": 100.2914364051175,
        "rarity_level": "COMMON"
    },
    "251": {
        "name": "80's Arkade Bulls #251",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/251.png",
        "dna": "a8b89e53cdfe402ad64baedcbd464a34a2050cb3",
        "edition": 251,
        "date": 1654568079377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "251",
        "rarity_score": 59.94140394547077,
        "rarity_level": "COMMON"
    },
    "252": {
        "name": "80's Arkade Bulls #252",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/252.png",
        "dna": "82e606edfc61f8fb0358dfc40229b28787bd0bca",
        "edition": 252,
        "date": 1654567930103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "252",
        "rarity_score": 99.37540756507985,
        "rarity_level": "COMMON"
    },
    "253": {
        "name": "80's Arkade Bulls #253",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/253.png",
        "dna": "7790dfb1f35bd842395235afb708e90e3dfb4332",
        "edition": 253,
        "date": 1654567154519,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "253",
        "rarity_score": 70.88678406839313,
        "rarity_level": "COMMON"
    },
    "254": {
        "name": "80's Arkade Bulls #254",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/254.png",
        "dna": "71131baa84207ed90ccde96c2c7fbdd5ed09f082",
        "edition": 254,
        "date": 1654568064490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "254",
        "rarity_score": 64.24185494529752,
        "rarity_level": "COMMON"
    },
    "255": {
        "name": "80's Arkade Bulls #255",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/255.png",
        "dna": "9a206dcd21ac993ae277c0865bcf0f853f8b41af",
        "edition": 255,
        "date": 1654567410052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.166666666666667,
        "id": "255",
        "rarity_score": 177.8396043916625,
        "rarity_level": "COMMON"
    },
    "256": {
        "name": "80's Arkade Bulls #256",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/256.png",
        "dna": "e2f5f7d519974a7067f0ece36daaff972dcb2ab6",
        "edition": 256,
        "date": 1654567898184,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "256",
        "rarity_score": 81.03989524874257,
        "rarity_level": "COMMON"
    },
    "257": {
        "name": "80's Arkade Bulls #257",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/257.png",
        "dna": "11b3a345965c29a7c5722a4dc19e240d8b86474a",
        "edition": 257,
        "date": 1654567969000,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "257",
        "rarity_score": 84.02371153331589,
        "rarity_level": "COMMON"
    },
    "258": {
        "name": "80's Arkade Bulls #258",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/258.png",
        "dna": "5c23755e6e7d90e48a9ecca260905b39ec49bbe9",
        "edition": 258,
        "date": 1654567623525,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.875,
        "id": "258",
        "rarity_score": 118.39855565359497,
        "rarity_level": "COMMON"
    },
    "259": {
        "name": "80's Arkade Bulls #259",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/259.png",
        "dna": "9e5a6aa70ffec39c62782c1acc49bceae7dd4aad",
        "edition": 259,
        "date": 1654568187395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "259",
        "rarity_score": 94.17970464332332,
        "rarity_level": "COMMON"
    },
    "260": {
        "name": "80's Arkade Bulls #260",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/260.png",
        "dna": "8c57075e5c7260182915f393dc1bc5bd2abe33da",
        "edition": 260,
        "date": 1654567856156,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "260",
        "rarity_score": 151.5404082322927,
        "rarity_level": "COMMON"
    },
    "261": {
        "name": "80's Arkade Bulls #261",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/261.png",
        "dna": "183d07fcf0e39e6eebc9837222a1722adf8283fa",
        "edition": 261,
        "date": 1654567388432,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "261",
        "rarity_score": 114.7243288609212,
        "rarity_level": "COMMON"
    },
    "262": {
        "name": "80's Arkade Bulls #262",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/262.png",
        "dna": "48460749609dbb3faeb56cd1cf5671ff505b4b8b",
        "edition": 262,
        "date": 1654568209189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "262",
        "rarity_score": 100.0741005412176,
        "rarity_level": "COMMON"
    },
    "263": {
        "name": "80's Arkade Bulls #263",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/263.png",
        "dna": "338a2784e67623ff82a0a6a9098d9e8240d643f5",
        "edition": 263,
        "date": 1654567922497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "263",
        "rarity_score": 121.49867778207738,
        "rarity_level": "COMMON"
    },
    "264": {
        "name": "80's Arkade Bulls #264",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/264.png",
        "dna": "e6f4d6d26c98ecf1161d51c7777bc8091e968071",
        "edition": 264,
        "date": 1654567653817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "264",
        "rarity_score": 215.455934562852,
        "rarity_level": "RARE"
    },
    "265": {
        "name": "80's Arkade Bulls #265",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/265.png",
        "dna": "13ef6ac4913e04032b0c0e09e6af43dc6883abb6",
        "edition": 265,
        "date": 1654567651061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142857,
        "id": "265",
        "rarity_score": 93.9112884715922,
        "rarity_level": "COMMON"
    },
    "266": {
        "name": "80's Arkade Bulls #266",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/266.png",
        "dna": "50ed3b946cf238b199df85a2e96116239a382c6b",
        "edition": 266,
        "date": 1654568022598,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "266",
        "rarity_score": 110.49481311366773,
        "rarity_level": "COMMON"
    },
    "267": {
        "name": "80's Arkade Bulls #267",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/267.png",
        "dna": "9af82aeb34c34c643215f91d8bc855810e2e708f",
        "edition": 267,
        "date": 1654567516055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.2857142857142865,
        "id": "267",
        "rarity_score": 205.31192472162516,
        "rarity_level": "RARE"
    },
    "268": {
        "name": "80's Arkade Bulls #268",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/268.png",
        "dna": "c166a4f915170c3cd40b21e5ac9956dcebacd511",
        "edition": 268,
        "date": 1654568180049,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "268",
        "rarity_score": 114.1844850278382,
        "rarity_level": "COMMON"
    },
    "269": {
        "name": "80's Arkade Bulls #269",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/269.png",
        "dna": "e1bc029ab587f93b3fda8f5a88fe500584874160",
        "edition": 269,
        "date": 1654568038022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "269",
        "rarity_score": 60.20762641356221,
        "rarity_level": "COMMON"
    },
    "270": {
        "name": "80's Arkade Bulls #270",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/270.png",
        "dna": "b03f52ac5ece52cf5a10b84fd9898c8047a89c8c",
        "edition": 270,
        "date": 1654567688241,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "270",
        "rarity_score": 127.63168418907257,
        "rarity_level": "COMMON"
    },
    "271": {
        "name": "80's Arkade Bulls #271",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/271.png",
        "dna": "ca70a0a76a6c89ae55f8fc35d309dea364b7fd6e",
        "edition": 271,
        "date": 1654567891296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "271",
        "rarity_score": 83.91950269908145,
        "rarity_level": "COMMON"
    },
    "272": {
        "name": "80's Arkade Bulls #272",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/272.png",
        "dna": "6147b0e06927e667f24ddb29090c3f793a650b9c",
        "edition": 272,
        "date": 1654567656029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.714285714285714,
        "id": "272",
        "rarity_score": 95.35024190628708,
        "rarity_level": "COMMON"
    },
    "273": {
        "name": "80's Arkade Bulls #273",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/273.png",
        "dna": "2bb7d40b715d0cd589ddba08040391093a999614",
        "edition": 273,
        "date": 1654567709704,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "273",
        "rarity_score": 70.02922958714628,
        "rarity_level": "COMMON"
    },
    "274": {
        "name": "80's Arkade Bulls #274",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/274.png",
        "dna": "bd7d8611364470191b863a016dad3250c5e90809",
        "edition": 274,
        "date": 1654567873782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "274",
        "rarity_score": 79.5778840340646,
        "rarity_level": "COMMON"
    },
    "275": {
        "name": "80's Arkade Bulls #275",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/275.png",
        "dna": "277f9f2764f55b596d768f2d73881e1af9ab8a87",
        "edition": 275,
        "date": 1654568207665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "275",
        "rarity_score": 128.6220218319886,
        "rarity_level": "COMMON"
    },
    "276": {
        "name": "80's Arkade Bulls #276",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/276.png",
        "dna": "1f45ffb031cee019155f7d135d68b27487ecb248",
        "edition": 276,
        "date": 1654567427553,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.714285714285714,
        "id": "276",
        "rarity_score": 77.88196705212737,
        "rarity_level": "COMMON"
    },
    "277": {
        "name": "80's Arkade Bulls #277",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/277.png",
        "dna": "a7a51410ad72328223571dc0361810089b0eb212",
        "edition": 277,
        "date": 1654567324909,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.444444444444445,
        "id": "277",
        "rarity_score": 241.75715004311584,
        "rarity_level": "RARE"
    },
    "278": {
        "name": "80's Arkade Bulls #278",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/278.png",
        "dna": "89b783cdb1036b2152c9206ef07b8b3e2f419b4b",
        "edition": 278,
        "date": 1654567942182,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "278",
        "rarity_score": 96.16366821384896,
        "rarity_level": "COMMON"
    },
    "279": {
        "name": "80's Arkade Bulls #279",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/279.png",
        "dna": "59c445d6f6e893783de65ae1133642db5b2e447c",
        "edition": 279,
        "date": 1654567685437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "279",
        "rarity_score": 157.67579667329082,
        "rarity_level": "COMMON"
    },
    "280": {
        "name": "80's Arkade Bulls #280",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/280.png",
        "dna": "c91969624ab30942e5e4a45cb7c487294d4b6c6e",
        "edition": 280,
        "date": 1654567280677,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "280",
        "rarity_score": 95.36222313517668,
        "rarity_level": "COMMON"
    },
    "281": {
        "name": "80's Arkade Bulls #281",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/281.png",
        "dna": "4b520edf6d510b94ecae581ce80943f9a0bd827b",
        "edition": 281,
        "date": 1654567423268,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "281",
        "rarity_score": 166.7182057103093,
        "rarity_level": "COMMON"
    },
    "282": {
        "name": "80's Arkade Bulls #282",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/282.png",
        "dna": "c98e9d133a9658dd5cb847bccdc55b16ce7f81d6",
        "edition": 282,
        "date": 1654567924677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8.571428571428571,
        "id": "282",
        "rarity_score": 78.89106148495604,
        "rarity_level": "COMMON"
    },
    "283": {
        "name": "80's Arkade Bulls #283",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/283.png",
        "dna": "f45eb7e7f763023a7313b9a50a5b41a02ad8d61d",
        "edition": 283,
        "date": 1654567308205,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 3.2222222222222223,
        "id": "283",
        "rarity_score": 321.02675869355187,
        "rarity_level": "RARE"
    },
    "284": {
        "name": "80's Arkade Bulls #284",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/284.png",
        "dna": "08f5f5e1dc826fc20b8663eaaa8d4218182295e3",
        "edition": 284,
        "date": 1654567559995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "284",
        "rarity_score": 144.63680508409976,
        "rarity_level": "COMMON"
    },
    "285": {
        "name": "80's Arkade Bulls #285",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/285.png",
        "dna": "6ac3013fd0e3886f6382121a392fbbcea1f02e59",
        "edition": 285,
        "date": 1654567968523,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "285",
        "rarity_score": 133.8276042106822,
        "rarity_level": "COMMON"
    },
    "286": {
        "name": "80's Arkade Bulls #286",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/286.png",
        "dna": "91bb946f4263bab218d1c4d044918b56d50f203a",
        "edition": 286,
        "date": 1654567338812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 3.4444444444444446,
        "id": "286",
        "rarity_score": 351.61164536893347,
        "rarity_level": "RARE"
    },
    "287": {
        "name": "80's Arkade Bulls #287",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/287.png",
        "dna": "03d7a78ba090f7d79fa7b3684ed83ccc14b32629",
        "edition": 287,
        "date": 1654568085081,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "287",
        "rarity_score": 79.97395113633023,
        "rarity_level": "COMMON"
    },
    "288": {
        "name": "80's Arkade Bulls #288",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/288.png",
        "dna": "6d7501ce0c5cbda4c2ea41b0e33137b52883c306",
        "edition": 288,
        "date": 1654567955225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "288",
        "rarity_score": 123.79616119147437,
        "rarity_level": "COMMON"
    },
    "289": {
        "name": "80's Arkade Bulls #289",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/289.png",
        "dna": "cff276c51282484f234506cce8396c3823f0ac3d",
        "edition": 289,
        "date": 1654568173843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "289",
        "rarity_score": 182.26504034545223,
        "rarity_level": "COMMON"
    },
    "290": {
        "name": "80's Arkade Bulls #290",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/290.png",
        "dna": "7b5de62c05e9a89610c88b94f90cb322d6b5aa2d",
        "edition": 290,
        "date": 1654567271316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "290",
        "rarity_score": 148.39024103962086,
        "rarity_level": "COMMON"
    },
    "291": {
        "name": "80's Arkade Bulls #291",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/291.png",
        "dna": "f0d266610ee178a7a19230eca79b848a3f08712d",
        "edition": 291,
        "date": 1654568132654,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "291",
        "rarity_score": 72.88962583578824,
        "rarity_level": "COMMON"
    },
    "292": {
        "name": "80's Arkade Bulls #292",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/292.png",
        "dna": "711beadb07caa9da4983c0e914c6c1c8a9d7f3ce",
        "edition": 292,
        "date": 1654568053370,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "292",
        "rarity_score": 54.8810466678083,
        "rarity_level": "COMMON"
    },
    "293": {
        "name": "80's Arkade Bulls #293",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/293.png",
        "dna": "7ec3fc08b15f146e9d4a8d02d8fc4acf8ea87187",
        "edition": 293,
        "date": 1654567639442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.375,
        "id": "293",
        "rarity_score": 189.01481163788202,
        "rarity_level": "COMMON"
    },
    "294": {
        "name": "80's Arkade Bulls #294",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/294.png",
        "dna": "b51869043ab3ae9aba75c3e80a939f041061ed05",
        "edition": 294,
        "date": 1654567195147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "294",
        "rarity_score": 108.69542765848186,
        "rarity_level": "COMMON"
    },
    "295": {
        "name": "80's Arkade Bulls #295",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/295.png",
        "dna": "b810ff2f287a16c3062850879822bd2208c3813d",
        "edition": 295,
        "date": 1654568024129,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "295",
        "rarity_score": 84.81493865135822,
        "rarity_level": "COMMON"
    },
    "296": {
        "name": "80's Arkade Bulls #296",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/296.png",
        "dna": "d7033a97eca438a8b6598fe4a90f20b9dde892e3",
        "edition": 296,
        "date": 1654567877411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "296",
        "rarity_score": 119.093215610946,
        "rarity_level": "COMMON"
    },
    "297": {
        "name": "80's Arkade Bulls #297",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/297.png",
        "dna": "ce2e0679812681d4b7d7e03a1477786934248472",
        "edition": 297,
        "date": 1654568098631,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "297",
        "rarity_score": 95.90889366644426,
        "rarity_level": "COMMON"
    },
    "298": {
        "name": "80's Arkade Bulls #298",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/298.png",
        "dna": "4f3dc1a7adb2ed0240853c3e21ac8897b906618f",
        "edition": 298,
        "date": 1654567160522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "298",
        "rarity_score": 76.50231644453198,
        "rarity_level": "COMMON"
    },
    "299": {
        "name": "80's Arkade Bulls #299",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/299.png",
        "dna": "0a55126dee86130b2a21a2dc25230af496a960e5",
        "edition": 299,
        "date": 1654567450458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rarity": 7.428571428571429,
        "rank": "Common",
        "id": "299",
        "rarity_score": 101.3840428813922,
        "rarity_level": "COMMON"
    },
    "300": {
        "name": "80's Arkade Bulls #300",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/300.png",
        "dna": "c5460d9ee5cc809ff6613f0dde15fa9dbf1c9924",
        "edition": 300,
        "date": 1654567826926,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "300",
        "rarity_score": 66.5671852825856,
        "rarity_level": "COMMON"
    },
    "301": {
        "name": "80's Arkade Bulls #301",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/301.png",
        "dna": "4fd2a3bbb2365c960dfe459965f0f390eb31a710",
        "edition": 301,
        "date": 1654567585797,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "301",
        "rarity_score": 303.9000605494981,
        "rarity_level": "RARE"
    },
    "302": {
        "name": "80's Arkade Bulls #302",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/302.png",
        "dna": "f407ab67b832f9c45745266e68c60867424306fe",
        "edition": 302,
        "date": 1654568087815,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "302",
        "rarity_score": 99.00587832069661,
        "rarity_level": "COMMON"
    },
    "303": {
        "name": "80's Arkade Bulls #303",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/303.png",
        "dna": "e72d8ec062f47861fc8c1c1cf96792965cb1e215",
        "edition": 303,
        "date": 1654567899782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "303",
        "rarity_score": 110.29480022375219,
        "rarity_level": "COMMON"
    },
    "304": {
        "name": "80's Arkade Bulls #304",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/304.png",
        "dna": "64d0d40c68613c80922423fa262ba51c3859c5c8",
        "edition": 304,
        "date": 1654567975520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "304",
        "rarity_score": 99.52307526375392,
        "rarity_level": "COMMON"
    },
    "305": {
        "name": "80's Arkade Bulls #305",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/305.png",
        "dna": "38a3d1bdac925ba1b1470024d411b1eec32030d9",
        "edition": 305,
        "date": 1654567196556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "305",
        "rarity_score": 112.86864745898359,
        "rarity_level": "COMMON"
    },
    "306": {
        "name": "80's Arkade Bulls #306",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/306.png",
        "dna": "7ea931b61836b088fed5b4170cefe40d0c2d266f",
        "edition": 306,
        "date": 1654567428130,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "306",
        "rarity_score": 91.87558416505807,
        "rarity_level": "COMMON"
    },
    "307": {
        "name": "80's Arkade Bulls #307",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/307.png",
        "dna": "1332a75bfceaa1bd270d81b5a0866f4e47185753",
        "edition": 307,
        "date": 1654567114698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "307",
        "rarity_score": 262.17928788536693,
        "rarity_level": "RARE"
    },
    "308": {
        "name": "80's Arkade Bulls #308",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/308.png",
        "dna": "d35dbd40819346ff3e9d3b0a2fb1c4535b22b97f",
        "edition": 308,
        "date": 1654567312246,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.555555555555555,
        "id": "308",
        "rarity_score": 268.8862654996599,
        "rarity_level": "RARE"
    },
    "309": {
        "name": "80's Arkade Bulls #309",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/309.png",
        "dna": "3c923b6e1f4e6df0a4f757bcbb5305618930b336",
        "edition": 309,
        "date": 1654567402959,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "309",
        "rarity_score": 181.57426833648805,
        "rarity_level": "COMMON"
    },
    "310": {
        "name": "80's Arkade Bulls #310",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/310.png",
        "dna": "0dbbbf34d717c81173fcafd46f885e64dca7f960",
        "edition": 310,
        "date": 1654567880604,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "310",
        "rarity_score": 155.9039285648282,
        "rarity_level": "COMMON"
    },
    "311": {
        "name": "80's Arkade Bulls #311",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/311.png",
        "dna": "97a8fbd49dcd62110e41388ddbdc3eab109b02e8",
        "edition": 311,
        "date": 1654567124296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "311",
        "rarity_score": 65.30339814918887,
        "rarity_level": "COMMON"
    },
    "312": {
        "name": "80's Arkade Bulls #312",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/312.png",
        "dna": "02f3983d0849c6cc5314e1f68455d8060fbe2e20",
        "edition": 312,
        "date": 1654567530272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "312",
        "rarity_score": 159.11262364582285,
        "rarity_level": "COMMON"
    },
    "313": {
        "name": "80's Arkade Bulls #313",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/313.png",
        "dna": "9fb7fec608b0854ffeeca8e692a62373331e2338",
        "edition": 313,
        "date": 1654567952765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "313",
        "rarity_score": 104.7465700965455,
        "rarity_level": "COMMON"
    },
    "314": {
        "name": "80's Arkade Bulls #314",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/314.png",
        "dna": "e4334c388d33fbf2174d6ad5a82c3474c1404807",
        "edition": 314,
        "date": 1654568038447,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "314",
        "rarity_score": 58.73710627994753,
        "rarity_level": "COMMON"
    },
    "315": {
        "name": "80's Arkade Bulls #315",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/315.png",
        "dna": "cadf86e810e1a22b278c07f29edd283b76331afd",
        "edition": 315,
        "date": 1654568000738,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "315",
        "rarity_score": 101.70962894708865,
        "rarity_level": "COMMON"
    },
    "316": {
        "name": "80's Arkade Bulls #316",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/316.png",
        "dna": "47dc4a6bc0cb88895c117f9cf0ca3a12399e97a0",
        "edition": 316,
        "date": 1654567506079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "316",
        "rarity_score": 165.51846355563504,
        "rarity_level": "COMMON"
    },
    "317": {
        "name": "80's Arkade Bulls #317",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/317.png",
        "dna": "a1424404998dcbc6f4e95f8df21a7e8e703866ed",
        "edition": 317,
        "date": 1654567179763,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "317",
        "rarity_score": 89.27871501594903,
        "rarity_level": "COMMON"
    },
    "318": {
        "name": "80's Arkade Bulls #318",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/318.png",
        "dna": "64e95ac90e3afb5ad463006a6b5921b1c63eaaf0",
        "edition": 318,
        "date": 1654567374126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "318",
        "rarity_score": 162.95526273239835,
        "rarity_level": "COMMON"
    },
    "319": {
        "name": "80's Arkade Bulls #319",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/319.png",
        "dna": "ef3be41c4d92da8b29f5dbc9dcb33c39d6d380c6",
        "edition": 319,
        "date": 1654567115008,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "319",
        "rarity_score": 280.30698627724666,
        "rarity_level": "RARE"
    },
    "320": {
        "name": "80's Arkade Bulls #320",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/320.png",
        "dna": "fb3f36893c53e5c12e41a3bbba8ec9afb2d18ac3",
        "edition": 320,
        "date": 1654568227685,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "320",
        "rarity_score": 230.58505035407993,
        "rarity_level": "RARE"
    },
    "321": {
        "name": "80's Arkade Bulls #321",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/321.png",
        "dna": "403eb25055560a265c4e6d0bb3da2d8ea9077fb8",
        "edition": 321,
        "date": 1654567307535,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "321",
        "rarity_score": 90.56919019595573,
        "rarity_level": "COMMON"
    },
    "322": {
        "name": "80's Arkade Bulls #322",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/322.png",
        "dna": "ff2911458a58bc2446933f298ca2261006b84bdb",
        "edition": 322,
        "date": 1654567387475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "322",
        "rarity_score": 310.0221238938053,
        "rarity_level": "RARE"
    },
    "323": {
        "name": "80's Arkade Bulls #323",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/323.png",
        "dna": "5e31c393f351456c62b10ae60c2fb49a3f7a17bc",
        "edition": 323,
        "date": 1654567365612,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.111111111111111,
        "id": "323",
        "rarity_score": 258.08736235821215,
        "rarity_level": "RARE"
    },
    "324": {
        "name": "80's Arkade Bulls #324",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/324.png",
        "dna": "f6a77d8ac3e20affe86b541aa3e3a4978662a722",
        "edition": 324,
        "date": 1654568147661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rarity": 4.5,
        "rank": "Epic",
        "id": "324",
        "rarity_score": 137.36976935013354,
        "rarity_level": "COMMON"
    },
    "325": {
        "name": "80's Arkade Bulls #325",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/325.png",
        "dna": "477ee901b80dbf94f38621e69a79bf061e1c8e9d",
        "edition": 325,
        "date": 1654567449371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "325",
        "rarity_score": 151.77843880898632,
        "rarity_level": "COMMON"
    },
    "326": {
        "name": "80's Arkade Bulls #326",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/326.png",
        "dna": "949e1c3003df26da100a059c4b9e4b75d6f64309",
        "edition": 326,
        "date": 1654567960183,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "326",
        "rarity_score": 108.99627731684183,
        "rarity_level": "COMMON"
    },
    "327": {
        "name": "80's Arkade Bulls #327",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/327.png",
        "dna": "69fe7c84a4dddb96e78c8305dcc01c1757e942df",
        "edition": 327,
        "date": 1654567126143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "327",
        "rarity_score": 61.933838830849304,
        "rarity_level": "COMMON"
    },
    "328": {
        "name": "80's Arkade Bulls #328",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/328.png",
        "dna": "b141a22ea306f47183365e1ac87cf5fb3ffce919",
        "edition": 328,
        "date": 1654568059453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "328",
        "rarity_score": 137.09572675553468,
        "rarity_level": "COMMON"
    },
    "329": {
        "name": "80's Arkade Bulls #329",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/329.png",
        "dna": "47e6400273cf6ef5691276fa871d89da7b6b68e2",
        "edition": 329,
        "date": 1654567916346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "329",
        "rarity_score": 101.78619930945196,
        "rarity_level": "COMMON"
    },
    "330": {
        "name": "80's Arkade Bulls #330",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/330.png",
        "dna": "93f80319b0e2b2e0cd0ece2e7c0b89e0f455f417",
        "edition": 330,
        "date": 1654567830699,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "330",
        "rarity_score": 94.25938933737807,
        "rarity_level": "COMMON"
    },
    "331": {
        "name": "80's Arkade Bulls #331",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/331.png",
        "dna": "f07fd0e6cb0bd27209fd0925888329dca875187a",
        "edition": 331,
        "date": 1654568106563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "331",
        "rarity_score": 80.70332474362596,
        "rarity_level": "COMMON"
    },
    "332": {
        "name": "80's Arkade Bulls #332",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/332.png",
        "dna": "9a611b31aab9d0acc10af1af77230c5c11a6a3c2",
        "edition": 332,
        "date": 1654567190505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "332",
        "rarity_score": 163.40919450217365,
        "rarity_level": "COMMON"
    },
    "333": {
        "name": "80's Arkade Bulls #333",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/333.png",
        "dna": "71b9b096abf044a313a992ff447d279ec0feca89",
        "edition": 333,
        "date": 1654567301336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "333",
        "rarity_score": 220.86980221365363,
        "rarity_level": "RARE"
    },
    "334": {
        "name": "80's Arkade Bulls #334",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/334.png",
        "dna": "c24d8e662177576caebd2290474b911aa23f23a1",
        "edition": 334,
        "date": 1654567569381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "334",
        "rarity_score": 209.20141105474937,
        "rarity_level": "RARE"
    },
    "335": {
        "name": "80's Arkade Bulls #335",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/335.png",
        "dna": "1299f20d688792eec56d3bc1d2b34b0d2ff3b550",
        "edition": 335,
        "date": 1654567183079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "335",
        "rarity_score": 113.398692362328,
        "rarity_level": "COMMON"
    },
    "336": {
        "name": "80's Arkade Bulls #336",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/336.png",
        "dna": "98d6c834cf0096faa5adb2183507390c799c5011",
        "edition": 336,
        "date": 1654568078919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "336",
        "rarity_score": 77.18445359353862,
        "rarity_level": "COMMON"
    },
    "337": {
        "name": "80's Arkade Bulls #337",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/337.png",
        "dna": "736aaf690aa21195d960260e55e018565085c333",
        "edition": 337,
        "date": 1654568037602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "337",
        "rarity_score": 83.51248033044847,
        "rarity_level": "COMMON"
    },
    "338": {
        "name": "80's Arkade Bulls #338",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/338.png",
        "dna": "90fa4b2d437242efcf6e4c2d9088a3eedd340249",
        "edition": 338,
        "date": 1654567278553,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "338",
        "rarity_score": 83.28892141568676,
        "rarity_level": "COMMON"
    },
    "339": {
        "name": "80's Arkade Bulls #339",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/339.png",
        "dna": "a94aa27f8a54308a2363a062e25904aafb2abafe",
        "edition": 339,
        "date": 1654567287512,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "339",
        "rarity_score": 117.21608450295275,
        "rarity_level": "COMMON"
    },
    "340": {
        "name": "80's Arkade Bulls #340",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/340.png",
        "dna": "c985f50e191c435b5f8a87e08c09471e0a131e54",
        "edition": 340,
        "date": 1654568041166,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "340",
        "rarity_score": 63.79080336807654,
        "rarity_level": "COMMON"
    },
    "341": {
        "name": "80's Arkade Bulls #341",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/341.png",
        "dna": "9dfe4c3fc5cb2c4338c3de296853245bc923381d",
        "edition": 341,
        "date": 1654568009920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "341",
        "rarity_score": 97.67232207128357,
        "rarity_level": "COMMON"
    },
    "342": {
        "name": "80's Arkade Bulls #342",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/342.png",
        "dna": "bddbdc59dca2c2c07e011538e21ef4b2e0f1e1b8",
        "edition": 342,
        "date": 1654567566724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "342",
        "rarity_score": 179.64796988873414,
        "rarity_level": "COMMON"
    },
    "343": {
        "name": "80's Arkade Bulls #343",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/343.png",
        "dna": "5e7ce1443158a229c0431f246303224eab0985d7",
        "edition": 343,
        "date": 1654567993885,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "343",
        "rarity_score": 206.7721573456969,
        "rarity_level": "RARE"
    },
    "344": {
        "name": "80's Arkade Bulls #344",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/344.png",
        "dna": "07af69b9497d1dee034fcd1f2a745ffecd7ed579",
        "edition": 344,
        "date": 1654567572068,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "344",
        "rarity_score": 141.99439557704324,
        "rarity_level": "COMMON"
    },
    "345": {
        "name": "80's Arkade Bulls #345",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/345.png",
        "dna": "ecd1aa721accd33a5b2ad8226d9f6e85861451dd",
        "edition": 345,
        "date": 1654567613109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.375,
        "id": "345",
        "rarity_score": 224.3355981202716,
        "rarity_level": "RARE"
    },
    "346": {
        "name": "80's Arkade Bulls #346",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/346.png",
        "dna": "56010dd9f1cbf86cd83bbec14c630aa0e115fc2d",
        "edition": 346,
        "date": 1654567845296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rarity": 8.5,
        "rank": "Common",
        "id": "346",
        "rarity_score": 83.3777495185409,
        "rarity_level": "COMMON"
    },
    "347": {
        "name": "80's Arkade Bulls #347",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/347.png",
        "dna": "dedd2ccbcd85c4da98a3a00257d4a28e0f3131a2",
        "edition": 347,
        "date": 1654567911019,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "347",
        "rarity_score": 151.87200768060444,
        "rarity_level": "COMMON"
    },
    "348": {
        "name": "80's Arkade Bulls #348",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/348.png",
        "dna": "7b28e26c3610f1c1e739bc46b64049bbd573193b",
        "edition": 348,
        "date": 1654568214928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 2.333333333333333,
        "id": "348",
        "rarity_score": 311.7733902494,
        "rarity_level": "RARE"
    },
    "349": {
        "name": "80's Arkade Bulls #349",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/349.png",
        "dna": "418063d61636ef87f991b35d28d04e8caac5062a",
        "edition": 349,
        "date": 1654567596399,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "349",
        "rarity_score": 236.04507797270958,
        "rarity_level": "RARE"
    },
    "350": {
        "name": "80's Arkade Bulls #350",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/350.png",
        "dna": "5e129da3bce2ae00de3c6f88a3b4919adf4ca95d",
        "edition": 350,
        "date": 1654567846184,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rarity": 8,
        "rank": "Common",
        "id": "350",
        "rarity_score": 101.67759671607118,
        "rarity_level": "COMMON"
    },
    "351": {
        "name": "80's Arkade Bulls #351",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/351.png",
        "dna": "3b3d4270e3e7fcb1597fc54a819b964f52dceb7b",
        "edition": 351,
        "date": 1654567453340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "351",
        "rarity_score": 117.70058592107148,
        "rarity_level": "COMMON"
    },
    "352": {
        "name": "80's Arkade Bulls #352",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/352.png",
        "dna": "f1daa98e422b66447ebc14f75fc359895643db7f",
        "edition": 352,
        "date": 1654567897679,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "352",
        "rarity_score": 184.72968885811395,
        "rarity_level": "COMMON"
    },
    "353": {
        "name": "80's Arkade Bulls #353",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/353.png",
        "dna": "e3731e8615bd63217cd37eee7c1294cbc44fdb7e",
        "edition": 353,
        "date": 1654567684373,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "353",
        "rarity_score": 124.76859542088651,
        "rarity_level": "COMMON"
    },
    "354": {
        "name": "80's Arkade Bulls #354",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/354.png",
        "dna": "bd623a648fe810e375007a10f42fa0fa0151b54f",
        "edition": 354,
        "date": 1654568182624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "354",
        "rarity_score": 82.92000374898565,
        "rarity_level": "COMMON"
    },
    "355": {
        "name": "80's Arkade Bulls #355",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/355.png",
        "dna": "3a901da5e45e68ea56142a37e6026938ed61f7ec",
        "edition": 355,
        "date": 1654567545159,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "355",
        "rarity_score": 174.40605403966026,
        "rarity_level": "COMMON"
    },
    "356": {
        "name": "80's Arkade Bulls #356",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/356.png",
        "dna": "c88fef48f9f0ebfd7ef13095e6126068057878e8",
        "edition": 356,
        "date": 1654567202264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "356",
        "rarity_score": 83.08204826535624,
        "rarity_level": "COMMON"
    },
    "357": {
        "name": "80's Arkade Bulls #357",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/357.png",
        "dna": "216c3f626387ae6b99a88ee6372fcb28bcef6039",
        "edition": 357,
        "date": 1654567887974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "357",
        "rarity_score": 117.05802874374866,
        "rarity_level": "COMMON"
    },
    "358": {
        "name": "80's Arkade Bulls #358",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/358.png",
        "dna": "2110f14be81226c359ddab2b31cb5a09026c3f0e",
        "edition": 358,
        "date": 1654567883215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "358",
        "rarity_score": 186.83735217646037,
        "rarity_level": "COMMON"
    },
    "359": {
        "name": "80's Arkade Bulls #359",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/359.png",
        "dna": "d59c2cc1d299df00ebf2de946b90a545e8a5364e",
        "edition": 359,
        "date": 1654567410532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "359",
        "rarity_score": 164.8252544002618,
        "rarity_level": "COMMON"
    },
    "360": {
        "name": "80's Arkade Bulls #360",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/360.png",
        "dna": "a6a7a5507ef103fcb40aa414e2e9b62bb18ed597",
        "edition": 360,
        "date": 1654567695596,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "360",
        "rarity_score": 163.88310725622878,
        "rarity_level": "COMMON"
    },
    "361": {
        "name": "80's Arkade Bulls #361",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/361.png",
        "dna": "c437134d621fa82b6fe63571cf231cbc638d75db",
        "edition": 361,
        "date": 1654567542384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "361",
        "rarity_score": 153.14292190501118,
        "rarity_level": "COMMON"
    },
    "362": {
        "name": "80's Arkade Bulls #362",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/362.png",
        "dna": "cba4e2dbe3b0f5b9048b110003b6349e01e7f4a2",
        "edition": 362,
        "date": 1654568124172,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "362",
        "rarity_score": 64.94405514909494,
        "rarity_level": "COMMON"
    },
    "363": {
        "name": "80's Arkade Bulls #363",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/363.png",
        "dna": "8230622dd1c75f7f4a4f86c5d6c87f648974dd8a",
        "edition": 363,
        "date": 1654568060881,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "363",
        "rarity_score": 93.4897038156209,
        "rarity_level": "COMMON"
    },
    "364": {
        "name": "80's Arkade Bulls #364",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/364.png",
        "dna": "daa8c1544eba9a4f8b61e6576643fbf6d3f11f1e",
        "edition": 364,
        "date": 1654567233393,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "364",
        "rarity_score": 104.3977334531984,
        "rarity_level": "COMMON"
    },
    "365": {
        "name": "80's Arkade Bulls #365",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/365.png",
        "dna": "2b68b2142f77502ff74ddc67d399c275dd24cb7f",
        "edition": 365,
        "date": 1654567500022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "365",
        "rarity_score": 176.2773259243857,
        "rarity_level": "COMMON"
    },
    "366": {
        "name": "80's Arkade Bulls #366",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/366.png",
        "dna": "c520ddf7833bfd59db2f1468d0d3f1f8779a0833",
        "edition": 366,
        "date": 1654568168151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "366",
        "rarity_score": 267.82419647792784,
        "rarity_level": "RARE"
    },
    "367": {
        "name": "80's Arkade Bulls #367",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/367.png",
        "dna": "464c60ae5f29376dddf4659ec58d32783b4c2210",
        "edition": 367,
        "date": 1654567590338,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "367",
        "rarity_score": 291.7451037095,
        "rarity_level": "RARE"
    },
    "368": {
        "name": "80's Arkade Bulls #368",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/368.png",
        "dna": "e8b779143ca964f042bc4c12b22be117bb798d6a",
        "edition": 368,
        "date": 1654567492581,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "368",
        "rarity_score": 132.59175327533222,
        "rarity_level": "COMMON"
    },
    "369": {
        "name": "80's Arkade Bulls #369",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/369.png",
        "dna": "550f4f1c80f6c61b74f549394d1beb00ebe2aa42",
        "edition": 369,
        "date": 1654567154058,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "369",
        "rarity_score": 104.05880318228986,
        "rarity_level": "COMMON"
    },
    "370": {
        "name": "80's Arkade Bulls #370",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/370.png",
        "dna": "13d8772f9a11d963f9d484deb5538891146d5f9b",
        "edition": 370,
        "date": 1654567635753,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.375,
        "id": "370",
        "rarity_score": 159.50661432527994,
        "rarity_level": "COMMON"
    },
    "371": {
        "name": "80's Arkade Bulls #371",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/371.png",
        "dna": "cb851b2af774dad5e4f6d4ff3800fefc3ebc293b",
        "edition": 371,
        "date": 1654567627451,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "371",
        "rarity_score": 132.7467119165029,
        "rarity_level": "COMMON"
    },
    "372": {
        "name": "80's Arkade Bulls #372",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/372.png",
        "dna": "09b17292ccf18d11fcfd9782015b33fece699715",
        "edition": 372,
        "date": 1654568041625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "372",
        "rarity_score": 79.55186879844973,
        "rarity_level": "COMMON"
    },
    "373": {
        "name": "80's Arkade Bulls #373",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/373.png",
        "dna": "26cb2a29ce6a334cb0e9ae574d0202a9e0be4ec2",
        "edition": 373,
        "date": 1654567605781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 4.75,
        "id": "373",
        "rarity_score": 234.00521309783417,
        "rarity_level": "RARE"
    },
    "374": {
        "name": "80's Arkade Bulls #374",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/374.png",
        "dna": "ed8d6de76ee2efb6f29bbc5fab83568bdb6f0226",
        "edition": 374,
        "date": 1654567578033,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "374",
        "rarity_score": 171.5063207939857,
        "rarity_level": "COMMON"
    },
    "375": {
        "name": "80's Arkade Bulls #375",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/375.png",
        "dna": "04aabc8d6f6e99f6210d070c7779f3b7c7cf6731",
        "edition": 375,
        "date": 1654567148657,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "375",
        "rarity_score": 54.499085722829314,
        "rarity_level": "COMMON"
    },
    "376": {
        "name": "80's Arkade Bulls #376",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/376.png",
        "dna": "c53065f95ea07eb3bc3959351000305e8727c4e8",
        "edition": 376,
        "date": 1654567315589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "376",
        "rarity_score": 305.42926518339004,
        "rarity_level": "RARE"
    },
    "377": {
        "name": "80's Arkade Bulls #377",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/377.png",
        "dna": "bdf3b02d028d0f63a7070a03978ff993334618e0",
        "edition": 377,
        "date": 1654567296710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "377",
        "rarity_score": 117.04368602431558,
        "rarity_level": "COMMON"
    },
    "378": {
        "name": "80's Arkade Bulls #378",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/378.png",
        "dna": "05ff8300876fa7ac0a39fc19c0eb0832e78e6026",
        "edition": 378,
        "date": 1654567212047,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "378",
        "rarity_score": 72.68264547871578,
        "rarity_level": "COMMON"
    },
    "379": {
        "name": "80's Arkade Bulls #379",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/379.png",
        "dna": "a22fa7dc3e93f0b88751123ddc9e50bf338fe628",
        "edition": 379,
        "date": 1654567268079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "379",
        "rarity_score": 102.74794129854648,
        "rarity_level": "COMMON"
    },
    "380": {
        "name": "80's Arkade Bulls #380",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/380.png",
        "dna": "f5d465674208d85c6b18b2f7dafecd963c8d2442",
        "edition": 380,
        "date": 1654567357740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.1111111111111107,
        "id": "380",
        "rarity_score": 223.54500018921271,
        "rarity_level": "RARE"
    },
    "381": {
        "name": "80's Arkade Bulls #381",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/381.png",
        "dna": "4e91dc183df81603e0c35ab7e8a355bb2bebabe1",
        "edition": 381,
        "date": 1654567360377,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.777777777777778,
        "id": "381",
        "rarity_score": 186.92408044116505,
        "rarity_level": "COMMON"
    },
    "382": {
        "name": "80's Arkade Bulls #382",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/382.png",
        "dna": "64c10079e62b9671bf2a328d597e8cd7e90ae659",
        "edition": 382,
        "date": 1654568050788,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "382",
        "rarity_score": 108.87734561741274,
        "rarity_level": "COMMON"
    },
    "383": {
        "name": "80's Arkade Bulls #383",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/383.png",
        "dna": "08e875b2367ddb6c6c822b7cc0a22c90cd54970c",
        "edition": 383,
        "date": 1654567414109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.166666666666667,
        "id": "383",
        "rarity_score": 242.8718558663103,
        "rarity_level": "RARE"
    },
    "384": {
        "name": "80's Arkade Bulls #384",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/384.png",
        "dna": "6e384a9f933bae91f9835265a1ae43fca80391d2",
        "edition": 384,
        "date": 1654567489372,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "384",
        "rarity_score": 181.6860115693787,
        "rarity_level": "COMMON"
    },
    "385": {
        "name": "80's Arkade Bulls #385",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/385.png",
        "dna": "9237b0406b02c0c62cc8717e9eebf96fced1f3d2",
        "edition": 385,
        "date": 1654567557738,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "385",
        "rarity_score": 293.1553580659611,
        "rarity_level": "RARE"
    },
    "386": {
        "name": "80's Arkade Bulls #386",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/386.png",
        "dna": "eba4a282e21ebcef4080dfba906a2eaec59be4e2",
        "edition": 386,
        "date": 1654567241137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666666,
        "id": "386",
        "rarity_score": 125.94163134215093,
        "rarity_level": "COMMON"
    },
    "387": {
        "name": "80's Arkade Bulls #387",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/387.png",
        "dna": "76f885d2dc4a80ae162e97365af01fc1c9509ec6",
        "edition": 387,
        "date": 1654568131736,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "387",
        "rarity_score": 72.24737847407386,
        "rarity_level": "COMMON"
    },
    "388": {
        "name": "80's Arkade Bulls #388",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/388.png",
        "dna": "0b6c923cc1a26ccdac86fee6675a7862f4f6858a",
        "edition": 388,
        "date": 1654567358403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "388",
        "rarity_score": 217.37138524437904,
        "rarity_level": "RARE"
    },
    "389": {
        "name": "80's Arkade Bulls #389",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/389.png",
        "dna": "8b7d1c9ec203515345b1f86521b6aa269f10eb8f",
        "edition": 389,
        "date": 1654568100054,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "389",
        "rarity_score": 66.4703481392557,
        "rarity_level": "COMMON"
    },
    "390": {
        "name": "80's Arkade Bulls #390",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/390.png",
        "dna": "c6555531f9db4dd44ce7f59e5e6ca971c44c34e1",
        "edition": 390,
        "date": 1654568088662,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.2,
        "id": "390",
        "rarity_score": 112.4419842007624,
        "rarity_level": "COMMON"
    },
    "391": {
        "name": "80's Arkade Bulls #391",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/391.png",
        "dna": "7b0ed0f9588b30b2dd84a1a267fe3bb8893c0e80",
        "edition": 391,
        "date": 1654568090532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "391",
        "rarity_score": 68.15865012502785,
        "rarity_level": "COMMON"
    },
    "392": {
        "name": "80's Arkade Bulls #392",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/392.png",
        "dna": "8476c7c7aa07e1b9ac94e63188e3be3bf3ac4829",
        "edition": 392,
        "date": 1654567192300,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "392",
        "rarity_score": 83.62488141119248,
        "rarity_level": "COMMON"
    },
    "393": {
        "name": "80's Arkade Bulls #393",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/393.png",
        "dna": "e071c22a3aa77e6bb3a874837f0734d88f5f9a4b",
        "edition": 393,
        "date": 1654567851148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "393",
        "rarity_score": 104.12353295977236,
        "rarity_level": "COMMON"
    },
    "394": {
        "name": "80's Arkade Bulls #394",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/394.png",
        "dna": "8990986c0d80525e04188529915254ed03a14438",
        "edition": 394,
        "date": 1654567875320,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "394",
        "rarity_score": 94.93834698796356,
        "rarity_level": "COMMON"
    },
    "395": {
        "name": "80's Arkade Bulls #395",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/395.png",
        "dna": "0b001d4068fbca176b883493dafa6deebbd88d3a",
        "edition": 395,
        "date": 1654567586829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.833333333333333,
        "id": "395",
        "rarity_score": 207.09582001285276,
        "rarity_level": "RARE"
    },
    "396": {
        "name": "80's Arkade Bulls #396",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/396.png",
        "dna": "5ab7034dee9f9bac23e9f7aa3c6e2217d38f17cd",
        "edition": 396,
        "date": 1654568004322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 6.166666666666667,
        "rank": "Common",
        "id": "396",
        "rarity_score": 156.1186151811152,
        "rarity_level": "COMMON"
    },
    "397": {
        "name": "80's Arkade Bulls #397",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/397.png",
        "dna": "e3366d67114af7d1221549c9a0334aa0f522e597",
        "edition": 397,
        "date": 1654567488817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "397",
        "rarity_score": 204.5779531324725,
        "rarity_level": "RARE"
    },
    "398": {
        "name": "80's Arkade Bulls #398",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/398.png",
        "dna": "154735abb1c82ea3d9c87c72cf76b3bfc8913e31",
        "edition": 398,
        "date": 1654567852163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857142,
        "id": "398",
        "rarity_score": 108.73341589976512,
        "rarity_level": "COMMON"
    },
    "399": {
        "name": "80's Arkade Bulls #399",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/399.png",
        "dna": "9726e086d9d4196d4d51fe706ba6d92cfcf592e4",
        "edition": 399,
        "date": 1654568164277,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 2.333333333333333,
        "id": "399",
        "rarity_score": 290.5216367676137,
        "rarity_level": "RARE"
    },
    "400": {
        "name": "80's Arkade Bulls #400",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/400.png",
        "dna": "7e783177016d455d4e7cc6a5c8cb8371a168d291",
        "edition": 400,
        "date": 1654568042547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "400",
        "rarity_score": 94.0338658047998,
        "rarity_level": "COMMON"
    },
    "401": {
        "name": "80's Arkade Bulls #401",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/401.png",
        "dna": "7854a2f510aef86e8020b933db30c4b1b229395f",
        "edition": 401,
        "date": 1654568150117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "401",
        "rarity_score": 146.76977017134186,
        "rarity_level": "COMMON"
    },
    "402": {
        "name": "80's Arkade Bulls #402",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/402.png",
        "dna": "63a259e9b479faecd3e0884b35a865fab3842468",
        "edition": 402,
        "date": 1654567326860,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.555555555555555,
        "id": "402",
        "rarity_score": 148.08369914372952,
        "rarity_level": "COMMON"
    },
    "403": {
        "name": "80's Arkade Bulls #403",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/403.png",
        "dna": "23375e69ff85cbdb9de3f0c274381f7ade2c828f",
        "edition": 403,
        "date": 1654567119751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "403",
        "rarity_score": 79.50696753797305,
        "rarity_level": "COMMON"
    },
    "404": {
        "name": "80's Arkade Bulls #404",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/404.png",
        "dna": "29fee6c93b08840480ee79110ac36ee577095500",
        "edition": 404,
        "date": 1654568213317,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "404",
        "rarity_score": 98.38779477892851,
        "rarity_level": "COMMON"
    },
    "405": {
        "name": "80's Arkade Bulls #405",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/405.png",
        "dna": "0401ee49e6ce7bd874b1cb43e74eaf48c43b3dc0",
        "edition": 405,
        "date": 1654567663964,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "405",
        "rarity_score": 132.16689009164932,
        "rarity_level": "COMMON"
    },
    "406": {
        "name": "80's Arkade Bulls #406",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/406.png",
        "dna": "71ceff727731dcff0fcc8e2d8850c72cd2e7b66a",
        "edition": 406,
        "date": 1654568111714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.199999999999999,
        "id": "406",
        "rarity_score": 135.54021608643458,
        "rarity_level": "COMMON"
    },
    "407": {
        "name": "80's Arkade Bulls #407",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/407.png",
        "dna": "dc7fc296d32b10b3091a2ecb955c62ed1383701b",
        "edition": 407,
        "date": 1654567180233,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "407",
        "rarity_score": 110.97956937727403,
        "rarity_level": "COMMON"
    },
    "408": {
        "name": "80's Arkade Bulls #408",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/408.png",
        "dna": "a990e7822eb1682aec809cb46666fd006cf83c20",
        "edition": 408,
        "date": 1654567846677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "408",
        "rarity_score": 95.05604700158362,
        "rarity_level": "COMMON"
    },
    "409": {
        "name": "80's Arkade Bulls #409",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/409.png",
        "dna": "d9c09fd679a45c5f8e7506df79a8898618933b90",
        "edition": 409,
        "date": 1654567430349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "409",
        "rarity_score": 106.58830653254022,
        "rarity_level": "COMMON"
    },
    "410": {
        "name": "80's Arkade Bulls #410",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/410.png",
        "dna": "63d59784abd1c0b995171dc959a7bc077a851707",
        "edition": 410,
        "date": 1654567447669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "410",
        "rarity_score": 108.95880595053737,
        "rarity_level": "COMMON"
    },
    "411": {
        "name": "80's Arkade Bulls #411",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/411.png",
        "dna": "0257dc3abc83146aea45871cfaeb74d5baa54084",
        "edition": 411,
        "date": 1654567459018,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "411",
        "rarity_score": 105.63884187256632,
        "rarity_level": "COMMON"
    },
    "412": {
        "name": "80's Arkade Bulls #412",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/412.png",
        "dna": "4c5e44ff9c43903d1d9a2c81220fcedf54860467",
        "edition": 412,
        "date": 1654567945072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "412",
        "rarity_score": 111.26525803877104,
        "rarity_level": "COMMON"
    },
    "413": {
        "name": "80's Arkade Bulls #413",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/413.png",
        "dna": "841d4d02688e0addb6cc7162f13b79775bf5a031",
        "edition": 413,
        "date": 1654568161741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "413",
        "rarity_score": 170.94536707109654,
        "rarity_level": "COMMON"
    },
    "414": {
        "name": "80's Arkade Bulls #414",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/414.png",
        "dna": "3080864b1cd933c5cd752147acc30a45f85b7ffc",
        "edition": 414,
        "date": 1654567503327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "414",
        "rarity_score": 161.08556701813518,
        "rarity_level": "COMMON"
    },
    "415": {
        "name": "80's Arkade Bulls #415",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/415.png",
        "dna": "eeee7e66b0d1242bbd91baff8dcfe91e35db14dd",
        "edition": 415,
        "date": 1654567318241,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "415",
        "rarity_score": 311.9691304397278,
        "rarity_level": "RARE"
    },
    "416": {
        "name": "80's Arkade Bulls #416",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/416.png",
        "dna": "7edebc6834fabdc7634e91df04246dbbdfd0023b",
        "edition": 416,
        "date": 1654567993396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "416",
        "rarity_score": 85.67871031594866,
        "rarity_level": "COMMON"
    },
    "417": {
        "name": "80's Arkade Bulls #417",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/417.png",
        "dna": "7c3d750856abd18bb8d91ddd5d30377f5aaa8542",
        "edition": 417,
        "date": 1654568052497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "417",
        "rarity_score": 59.03027486570171,
        "rarity_level": "COMMON"
    },
    "418": {
        "name": "80's Arkade Bulls #418",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/418.png",
        "dna": "2e0ac4993cc3a07b28ae5fcf2baaf4817ae26238",
        "edition": 418,
        "date": 1654568004849,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "418",
        "rarity_score": 91.04836065815665,
        "rarity_level": "COMMON"
    },
    "419": {
        "name": "80's Arkade Bulls #419",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/419.png",
        "dna": "909042c9bc2110befe36bf0312835b360ed02974",
        "edition": 419,
        "date": 1654567161910,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "419",
        "rarity_score": 82.71421283703266,
        "rarity_level": "COMMON"
    },
    "420": {
        "name": "80's Arkade Bulls #420",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/420.png",
        "dna": "133bc242bb7ccebb26e9eeb2eaf9474d79660317",
        "edition": 420,
        "date": 1654568206130,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "420",
        "rarity_score": 91.30636198885651,
        "rarity_level": "COMMON"
    },
    "421": {
        "name": "80's Arkade Bulls #421",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/421.png",
        "dna": "40dd4a4e5d873710645af661c92d699570e1e9a5",
        "edition": 421,
        "date": 1654568078022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.4000000000000004,
        "id": "421",
        "rarity_score": 206.40260976382038,
        "rarity_level": "RARE"
    },
    "422": {
        "name": "80's Arkade Bulls #422",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/422.png",
        "dna": "fc4a002e6c85f0aa690e009fbee192a78b68ac6d",
        "edition": 422,
        "date": 1654568043466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "422",
        "rarity_score": 118.47579031612645,
        "rarity_level": "COMMON"
    },
    "423": {
        "name": "80's Arkade Bulls #423",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/423.png",
        "dna": "dd3ba2e7e9dfaf6592bad14bffc2a1efaceeaab9",
        "edition": 423,
        "date": 1654567135202,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "423",
        "rarity_score": 63.185996927360264,
        "rarity_level": "COMMON"
    },
    "424": {
        "name": "80's Arkade Bulls #424",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/424.png",
        "dna": "7cccf84de6863f4334d81a56eed4a0f44ef491cb",
        "edition": 424,
        "date": 1654567607720,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.375,
        "id": "424",
        "rarity_score": 107.9245732767684,
        "rarity_level": "COMMON"
    },
    "425": {
        "name": "80's Arkade Bulls #425",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/425.png",
        "dna": "d5765c5eb00104bd34137173cfbc112d4054405e",
        "edition": 425,
        "date": 1654567527047,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 3.571428571428571,
        "id": "425",
        "rarity_score": 176.5284426986434,
        "rarity_level": "COMMON"
    },
    "426": {
        "name": "80's Arkade Bulls #426",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/426.png",
        "dna": "56e962176ad2ce9c78eba0ad7436997c60d45e46",
        "edition": 426,
        "date": 1654567354427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.2222222222222214,
        "id": "426",
        "rarity_score": 194.0708214985982,
        "rarity_level": "COMMON"
    },
    "427": {
        "name": "80's Arkade Bulls #427",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/427.png",
        "dna": "17af4556c0f82ecda676b0488603326c96f86ce1",
        "edition": 427,
        "date": 1654567887477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "427",
        "rarity_score": 101.16729022732297,
        "rarity_level": "COMMON"
    },
    "428": {
        "name": "80's Arkade Bulls #428",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/428.png",
        "dna": "bafdd40a09a41518cf5d42b4625b15f2352878e5",
        "edition": 428,
        "date": 1654567465103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "428",
        "rarity_score": 81.9063923470084,
        "rarity_level": "COMMON"
    },
    "429": {
        "name": "80's Arkade Bulls #429",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/429.png",
        "dna": "7e582e06c3c27efa5d2417343dc09cf058978ccc",
        "edition": 429,
        "date": 1654567132319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "429",
        "rarity_score": 82.75050782024063,
        "rarity_level": "COMMON"
    },
    "430": {
        "name": "80's Arkade Bulls #430",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/430.png",
        "dna": "8a6c1afbb4c17a453bd359420e5d90b4cea2eeee",
        "edition": 430,
        "date": 1654567434350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "430",
        "rarity_score": 90.61728932730072,
        "rarity_level": "COMMON"
    },
    "431": {
        "name": "80's Arkade Bulls #431",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/431.png",
        "dna": "abb93c3d89b35996db2b5fdc98da43beec96747b",
        "edition": 431,
        "date": 1654567475153,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "431",
        "rarity_score": 217.56299317999014,
        "rarity_level": "RARE"
    },
    "432": {
        "name": "80's Arkade Bulls #432",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/432.png",
        "dna": "34030b7521b8bc4093692f68e6f49a58345299b3",
        "edition": 432,
        "date": 1654567900829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "432",
        "rarity_score": 114.99635262322856,
        "rarity_level": "COMMON"
    },
    "433": {
        "name": "80's Arkade Bulls #433",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/433.png",
        "dna": "292151047d71f5873cec4ee31800f0ff7294978f",
        "edition": 433,
        "date": 1654567908378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "433",
        "rarity_score": 184.2014810433635,
        "rarity_level": "COMMON"
    },
    "434": {
        "name": "80's Arkade Bulls #434",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/434.png",
        "dna": "662c272c6c6206775b2b72be0ebf4bed1315f29b",
        "edition": 434,
        "date": 1654568039320,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9.6,
        "id": "434",
        "rarity_score": 54.75441914347865,
        "rarity_level": "COMMON"
    },
    "435": {
        "name": "80's Arkade Bulls #435",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/435.png",
        "dna": "57d034512e0479995bf200c1a4d9bb6d0316bde4",
        "edition": 435,
        "date": 1654567571511,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.428571428571429,
        "id": "435",
        "rarity_score": 224.14812857106898,
        "rarity_level": "RARE"
    },
    "436": {
        "name": "80's Arkade Bulls #436",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/436.png",
        "dna": "1d8d30e905c941860e7dfe608bed4db58885dab4",
        "edition": 436,
        "date": 1654568185832,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666667,
        "id": "436",
        "rarity_score": 141.7409272637077,
        "rarity_level": "COMMON"
    },
    "437": {
        "name": "80's Arkade Bulls #437",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/437.png",
        "dna": "d676b36c3848a1e59fd18c190ada6633b4084767",
        "edition": 437,
        "date": 1654567333359,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "437",
        "rarity_score": 226.22898160504897,
        "rarity_level": "RARE"
    },
    "438": {
        "name": "80's Arkade Bulls #438",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/438.png",
        "dna": "d47cbd363518b0624004cb26977cb5b4b0ef5cc7",
        "edition": 438,
        "date": 1654567376135,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "438",
        "rarity_score": 166.3834520605855,
        "rarity_level": "COMMON"
    },
    "439": {
        "name": "80's Arkade Bulls #439",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/439.png",
        "dna": "f50a784b4a1fff8d4bdf6d6e42eb97e7df515f1e",
        "edition": 439,
        "date": 1654567116220,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "439",
        "rarity_score": 77.39104965626488,
        "rarity_level": "COMMON"
    },
    "440": {
        "name": "80's Arkade Bulls #440",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/440.png",
        "dna": "cb1b99c3d6a88d981635b271f13d6e78f0ce978c",
        "edition": 440,
        "date": 1654568210705,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "440",
        "rarity_score": 143.58663068527727,
        "rarity_level": "COMMON"
    },
    "441": {
        "name": "80's Arkade Bulls #441",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/441.png",
        "dna": "88861d371e47045e18baeaf2014145898db3a0d7",
        "edition": 441,
        "date": 1654567877953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "441",
        "rarity_score": 227.45133620077084,
        "rarity_level": "RARE"
    },
    "442": {
        "name": "80's Arkade Bulls #442",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/442.png",
        "dna": "cf0ce2748be54c3c54a7a33444f493bda5d882eb",
        "edition": 442,
        "date": 1654568125635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "442",
        "rarity_score": 86.1197396176122,
        "rarity_level": "COMMON"
    },
    "443": {
        "name": "80's Arkade Bulls #443",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/443.png",
        "dna": "abdb99ffead6c9b438a674f90b8eead2fe6ba8f7",
        "edition": 443,
        "date": 1654568168599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "443",
        "rarity_score": 169.3125686162038,
        "rarity_level": "COMMON"
    },
    "444": {
        "name": "80's Arkade Bulls #444",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/444.png",
        "dna": "daba13d96f9ee9f8d7efc122d3e94acc8b1a3f49",
        "edition": 444,
        "date": 1654568074422,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "444",
        "rarity_score": 109.33401639344262,
        "rarity_level": "COMMON"
    },
    "445": {
        "name": "80's Arkade Bulls #445",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/445.png",
        "dna": "0abe7e96db2470fd9f4e57a73873edeb9d18e78b",
        "edition": 445,
        "date": 1654567246383,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666666,
        "id": "445",
        "rarity_score": 151.59721196158557,
        "rarity_level": "COMMON"
    },
    "446": {
        "name": "80's Arkade Bulls #446",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/446.png",
        "dna": "9e575e1ce38b5dd2625daa87ba9aceab58bb1a37",
        "edition": 446,
        "date": 1654567980505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "446",
        "rarity_score": 122.90272058089366,
        "rarity_level": "COMMON"
    },
    "447": {
        "name": "80's Arkade Bulls #447",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/447.png",
        "dna": "0521ecd41908d54c84687014abcb92068f684e69",
        "edition": 447,
        "date": 1654568095019,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "447",
        "rarity_score": 78.77887457272708,
        "rarity_level": "COMMON"
    },
    "448": {
        "name": "80's Arkade Bulls #448",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/448.png",
        "dna": "6184115dacfa2edf6ec91ae78e4f49f727fb4acb",
        "edition": 448,
        "date": 1654567453925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.428571428571429,
        "id": "448",
        "rarity_score": 84.16128579760743,
        "rarity_level": "COMMON"
    },
    "449": {
        "name": "80's Arkade Bulls #449",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/449.png",
        "dna": "5b96f4b3f1675271c14fa8e2de51455ae440aca4",
        "edition": 449,
        "date": 1654567389431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "449",
        "rarity_score": 196.4006761016704,
        "rarity_level": "COMMON"
    },
    "450": {
        "name": "80's Arkade Bulls #450",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/450.png",
        "dna": "0571c8e2692d01425ca61e921ecf4d16d67780d4",
        "edition": 450,
        "date": 1654567440408,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "450",
        "rarity_score": 214.17550926380898,
        "rarity_level": "RARE"
    },
    "451": {
        "name": "80's Arkade Bulls #451",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/451.png",
        "dna": "eacc5839735a63e78767ca385cc03f5e7de0992a",
        "edition": 451,
        "date": 1654567507716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "451",
        "rarity_score": 153.73054093388998,
        "rarity_level": "COMMON"
    },
    "452": {
        "name": "80's Arkade Bulls #452",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/452.png",
        "dna": "6d9f9f7e08b7c335623e27675812fd61ec9e5f45",
        "edition": 452,
        "date": 1654567381549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "452",
        "rarity_score": 161.11243779638193,
        "rarity_level": "COMMON"
    },
    "453": {
        "name": "80's Arkade Bulls #453",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/453.png",
        "dna": "4bc1230d80d14c65390b9ccbfa9126d7c7be2bf0",
        "edition": 453,
        "date": 1654567268610,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "453",
        "rarity_score": 107.200462929832,
        "rarity_level": "COMMON"
    },
    "454": {
        "name": "80's Arkade Bulls #454",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/454.png",
        "dna": "97e1760f449a8164d7adea228d6a05afb644370f",
        "edition": 454,
        "date": 1654568087384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "454",
        "rarity_score": 58.34271709664083,
        "rarity_level": "COMMON"
    },
    "455": {
        "name": "80's Arkade Bulls #455",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/455.png",
        "dna": "2a003497af5979f41b04d223161cbe382665a7ae",
        "edition": 455,
        "date": 1654567938375,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "455",
        "rarity_score": 219.10069817759734,
        "rarity_level": "RARE"
    },
    "456": {
        "name": "80's Arkade Bulls #456",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/456.png",
        "dna": "7b8a303cf2b8b49a06c1ab108da181cdab0fedf7",
        "edition": 456,
        "date": 1654567139410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "456",
        "rarity_score": 144.31219059027737,
        "rarity_level": "COMMON"
    },
    "457": {
        "name": "80's Arkade Bulls #457",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/457.png",
        "dna": "1137821232581d5d11845e37a21114db94c1f04a",
        "edition": 457,
        "date": 1654567621636,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.125,
        "id": "457",
        "rarity_score": 96.71977763743789,
        "rarity_level": "COMMON"
    },
    "458": {
        "name": "80's Arkade Bulls #458",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/458.png",
        "dna": "ac9d17c8a868516fbdf4369aa14174f79ec90f2a",
        "edition": 458,
        "date": 1654567947336,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "458",
        "rarity_score": 242.59106790198092,
        "rarity_level": "RARE"
    },
    "459": {
        "name": "80's Arkade Bulls #459",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/459.png",
        "dna": "f0954d1109bd5ccee41bbcbf10d4e83668885e3c",
        "edition": 459,
        "date": 1654567670148,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "459",
        "rarity_score": 126.29039115646258,
        "rarity_level": "COMMON"
    },
    "460": {
        "name": "80's Arkade Bulls #460",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/460.png",
        "dna": "9f403c6e78360c02691918868c07908b1e066fda",
        "edition": 460,
        "date": 1654567493119,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "460",
        "rarity_score": 173.89706088489808,
        "rarity_level": "COMMON"
    },
    "461": {
        "name": "80's Arkade Bulls #461",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/461.png",
        "dna": "12dd1d5375d4d2f46e7e6f545bfa5cdfbbb98325",
        "edition": 461,
        "date": 1654567631025,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 4.875,
        "id": "461",
        "rarity_score": 143.92950114390632,
        "rarity_level": "COMMON"
    },
    "462": {
        "name": "80's Arkade Bulls #462",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/462.png",
        "dna": "7bb034f263e397829a42bc2f0cebeec17212c708",
        "edition": 462,
        "date": 1654567218210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "462",
        "rarity_score": 99.17322877806919,
        "rarity_level": "COMMON"
    },
    "463": {
        "name": "80's Arkade Bulls #463",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/463.png",
        "dna": "619e8b169e428bdfea810589dded7410a5775e9e",
        "edition": 463,
        "date": 1654567156356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "463",
        "rarity_score": 87.08991182679969,
        "rarity_level": "COMMON"
    },
    "464": {
        "name": "80's Arkade Bulls #464",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/464.png",
        "dna": "8e2451125001ceb64498f80fef8adea445659c3f",
        "edition": 464,
        "date": 1654567208382,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "464",
        "rarity_score": 94.6606475032141,
        "rarity_level": "COMMON"
    },
    "465": {
        "name": "80's Arkade Bulls #465",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/465.png",
        "dna": "09ed0b071bc2b3c1506352d84220d9b1ab0ace1c",
        "edition": 465,
        "date": 1654567177482,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "465",
        "rarity_score": 54.36164169546844,
        "rarity_level": "COMMON"
    },
    "466": {
        "name": "80's Arkade Bulls #466",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/466.png",
        "dna": "d4cecfb575bba39630c0b2b5001b47cf6e971e47",
        "edition": 466,
        "date": 1654567570989,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.571428571428571,
        "id": "466",
        "rarity_score": 249.13081622494389,
        "rarity_level": "RARE"
    },
    "467": {
        "name": "80's Arkade Bulls #467",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/467.png",
        "dna": "eea856d3a6d553019f8b5cf4cefc5dcc9cf7c7f5",
        "edition": 467,
        "date": 1654567843980,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "467",
        "rarity_score": 86.98421744195474,
        "rarity_level": "COMMON"
    },
    "468": {
        "name": "80's Arkade Bulls #468",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/468.png",
        "dna": "f495e9ab7911ea7430ed2262e404c57cfb0a34e2",
        "edition": 468,
        "date": 1654567143552,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "468",
        "rarity_score": 63.531530652089025,
        "rarity_level": "COMMON"
    },
    "469": {
        "name": "80's Arkade Bulls #469",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/469.png",
        "dna": "e1ae5abac1df3d87648b7b7193735c896f7d8bb5",
        "edition": 469,
        "date": 1654567624725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.625,
        "id": "469",
        "rarity_score": 148.07549565864508,
        "rarity_level": "COMMON"
    },
    "470": {
        "name": "80's Arkade Bulls #470",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/470.png",
        "dna": "666d6cbe3e1251da7716a9719de38de82d147eb7",
        "edition": 470,
        "date": 1654567588295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "470",
        "rarity_score": 153.08490213250826,
        "rarity_level": "COMMON"
    },
    "471": {
        "name": "80's Arkade Bulls #471",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/471.png",
        "dna": "8d6cf353c66ba4fc45e7e9d3dfe62445ed91a891",
        "edition": 471,
        "date": 1654567671870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "471",
        "rarity_score": 215.5903473575485,
        "rarity_level": "RARE"
    },
    "472": {
        "name": "80's Arkade Bulls #472",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/472.png",
        "dna": "681a80257607b27f1f5b79775e4d31fdbff3f994",
        "edition": 472,
        "date": 1654567424825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "472",
        "rarity_score": 94.34551683610214,
        "rarity_level": "COMMON"
    },
    "473": {
        "name": "80's Arkade Bulls #473",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/473.png",
        "dna": "b986ec8d3cb9feae1682ffb2b6a180323444e2d8",
        "edition": 473,
        "date": 1654567275234,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "473",
        "rarity_score": 142.16869760383773,
        "rarity_level": "COMMON"
    },
    "474": {
        "name": "80's Arkade Bulls #474",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/474.png",
        "dna": "b92da3ef7fde27695ef0dfa50118507bf812644e",
        "edition": 474,
        "date": 1654567906208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.1428571428571423,
        "id": "474",
        "rarity_score": 173.88464243874756,
        "rarity_level": "COMMON"
    },
    "475": {
        "name": "80's Arkade Bulls #475",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/475.png",
        "dna": "3bb00becbfafa0d70f8e59eedd45cb1bdc4dafca",
        "edition": 475,
        "date": 1654568135433,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "475",
        "rarity_score": 77.82931943858617,
        "rarity_level": "COMMON"
    },
    "476": {
        "name": "80's Arkade Bulls #476",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/476.png",
        "dna": "c5a5b0f80e663f9ce190fe4778ccb23d030dd694",
        "edition": 476,
        "date": 1654568096311,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "476",
        "rarity_score": 109.56713480796293,
        "rarity_level": "COMMON"
    },
    "477": {
        "name": "80's Arkade Bulls #477",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/477.png",
        "dna": "b5f314b7677fb4eb8516d69e81c80029af096989",
        "edition": 477,
        "date": 1654567394536,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "477",
        "rarity_score": 175.52151471851727,
        "rarity_level": "COMMON"
    },
    "478": {
        "name": "80's Arkade Bulls #478",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/478.png",
        "dna": "3450c242eea6ab9edb288b7e477f25144cbaaf55",
        "edition": 478,
        "date": 1654568154659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "478",
        "rarity_score": 202.6243769801732,
        "rarity_level": "RARE"
    },
    "479": {
        "name": "80's Arkade Bulls #479",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/479.png",
        "dna": "08a5469ececf820bbdcde83b1b89a463d9239b99",
        "edition": 479,
        "date": 1654567834196,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "479",
        "rarity_score": 79.24674618543743,
        "rarity_level": "COMMON"
    },
    "480": {
        "name": "80's Arkade Bulls #480",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/480.png",
        "dna": "ea2fbf30f469b6c0068d147b8c5226dacb94ef1d",
        "edition": 480,
        "date": 1654568082532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "480",
        "rarity_score": 74.88280203318836,
        "rarity_level": "COMMON"
    },
    "481": {
        "name": "80's Arkade Bulls #481",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/481.png",
        "dna": "5f1b7aff99d56a43f4aa9ae855bc5bbe332aab14",
        "edition": 481,
        "date": 1654567429243,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "481",
        "rarity_score": 135.8004236182787,
        "rarity_level": "COMMON"
    },
    "482": {
        "name": "80's Arkade Bulls #482",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/482.png",
        "dna": "be1bd17c3d75b62f7839b3ed1f1c7188939a88cc",
        "edition": 482,
        "date": 1654567951179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "482",
        "rarity_score": 164.07098966979657,
        "rarity_level": "COMMON"
    },
    "483": {
        "name": "80's Arkade Bulls #483",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/483.png",
        "dna": "12d33ede6d4ce17bc3dcf98605e75aa220bf72e0",
        "edition": 483,
        "date": 1654567499451,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8571428571428577,
        "id": "483",
        "rarity_score": 201.59943977591035,
        "rarity_level": "COMMON"
    },
    "484": {
        "name": "80's Arkade Bulls #484",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/484.png",
        "dna": "6dac9555c35dc7607ac7b2f0812dc4b04265a746",
        "edition": 484,
        "date": 1654568141090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "484",
        "rarity_score": 70.77415998823616,
        "rarity_level": "COMMON"
    },
    "485": {
        "name": "80's Arkade Bulls #485",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/485.png",
        "dna": "9842a4c43ebe4e413e614de2b310ac6cc8c4a62f",
        "edition": 485,
        "date": 1654567981478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "485",
        "rarity_score": 98.22716485377046,
        "rarity_level": "COMMON"
    },
    "486": {
        "name": "80's Arkade Bulls #486",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/486.png",
        "dna": "bf409a7c479bff81451d8bb14b64d5d76b6a0324",
        "edition": 486,
        "date": 1654567883740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "486",
        "rarity_score": 96.41321898273866,
        "rarity_level": "COMMON"
    },
    "487": {
        "name": "80's Arkade Bulls #487",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/487.png",
        "dna": "0b5decbbf74c9aea7a6720a8be7bf760516c97c4",
        "edition": 487,
        "date": 1654567513292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "487",
        "rarity_score": 239.4334846046684,
        "rarity_level": "RARE"
    },
    "488": {
        "name": "80's Arkade Bulls #488",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/488.png",
        "dna": "deb5df50c4b28cff34ff1b54a37e92a4ecd07cbc",
        "edition": 488,
        "date": 1654567313603,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "488",
        "rarity_score": 306.9669963488642,
        "rarity_level": "RARE"
    },
    "489": {
        "name": "80's Arkade Bulls #489",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/489.png",
        "dna": "eddd65595f542110c8442ee6a4a870a22b56cbd6",
        "edition": 489,
        "date": 1654567519759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "489",
        "rarity_score": 159.6729684321062,
        "rarity_level": "COMMON"
    },
    "490": {
        "name": "80's Arkade Bulls #490",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/490.png",
        "dna": "0cbd389703447411c29bb345648d10b2c4bf2818",
        "edition": 490,
        "date": 1654567408513,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "490",
        "rarity_score": 191.22297808012095,
        "rarity_level": "COMMON"
    },
    "491": {
        "name": "80's Arkade Bulls #491",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/491.png",
        "dna": "7db40e7f5bde751234dc170c70bd97776cd5bb75",
        "edition": 491,
        "date": 1654567468989,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "491",
        "rarity_score": 114.20548418076135,
        "rarity_level": "COMMON"
    },
    "492": {
        "name": "80's Arkade Bulls #492",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/492.png",
        "dna": "13c12b4523fea16d8c2668f03d41d2278e7c9411",
        "edition": 492,
        "date": 1654568171808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "492",
        "rarity_score": 145.09179969746526,
        "rarity_level": "COMMON"
    },
    "493": {
        "name": "80's Arkade Bulls #493",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/493.png",
        "dna": "447a8fdff871c33c81dbb7b4c473e24ea0310dfd",
        "edition": 493,
        "date": 1654567406486,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "493",
        "rarity_score": 239.61888031267847,
        "rarity_level": "RARE"
    },
    "494": {
        "name": "80's Arkade Bulls #494",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/494.png",
        "dna": "84855c04b31c4766b5d12c9a6edab87e430c3e5a",
        "edition": 494,
        "date": 1654567115771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "494",
        "rarity_score": 173.98036605005655,
        "rarity_level": "COMMON"
    },
    "495": {
        "name": "80's Arkade Bulls #495",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/495.png",
        "dna": "c31b51e977dfab5094ede92eb93eb13d0db5bc17",
        "edition": 495,
        "date": 1654567251566,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "495",
        "rarity_score": 87.47885191385768,
        "rarity_level": "COMMON"
    },
    "496": {
        "name": "80's Arkade Bulls #496",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/496.png",
        "dna": "0229699f2da4823989f25a9c00f6c50dcd6b795f",
        "edition": 496,
        "date": 1654567605183,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.875,
        "id": "496",
        "rarity_score": 165.39328198644552,
        "rarity_level": "COMMON"
    },
    "497": {
        "name": "80's Arkade Bulls #497",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/497.png",
        "dna": "919862f305b239278048b1939bbf1b826f6293c1",
        "edition": 497,
        "date": 1654567187706,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "497",
        "rarity_score": 116.02875755565385,
        "rarity_level": "COMMON"
    },
    "498": {
        "name": "80's Arkade Bulls #498",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/498.png",
        "dna": "87caab4a8ffeeb455871b983632efa18343a4a53",
        "edition": 498,
        "date": 1654568048059,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "498",
        "rarity_score": 71.48349531421596,
        "rarity_level": "COMMON"
    },
    "499": {
        "name": "80's Arkade Bulls #499",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/499.png",
        "dna": "e8eb73806aacb99ccd951281b9634d7fd2137696",
        "edition": 499,
        "date": 1654567702192,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857143,
        "id": "499",
        "rarity_score": 180.19317189040305,
        "rarity_level": "COMMON"
    },
    "500": {
        "name": "80's Arkade Bulls #500",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/500.png",
        "dna": "9a6b61e4659c28fb90760a9ce8bf09cf77b4a51d",
        "edition": 500,
        "date": 1654567686526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857143,
        "id": "500",
        "rarity_score": 134.51940680534992,
        "rarity_level": "COMMON"
    },
    "501": {
        "name": "80's Arkade Bulls #501",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/501.png",
        "dna": "e061668cc71ec6b68da711a056b98e9a9bf44456",
        "edition": 501,
        "date": 1654567462908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "501",
        "rarity_score": 97.01809045064832,
        "rarity_level": "COMMON"
    },
    "502": {
        "name": "80's Arkade Bulls #502",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/502.png",
        "dna": "79b0163df10e6b4d6d6b0f9fb2246237452efc68",
        "edition": 502,
        "date": 1654567525936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "502",
        "rarity_score": 184.30882746412232,
        "rarity_level": "COMMON"
    },
    "503": {
        "name": "80's Arkade Bulls #503",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/503.png",
        "dna": "73a4d86c5b7028bb03489f0b3d0cde882eabfb25",
        "edition": 503,
        "date": 1654567652735,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "503",
        "rarity_score": 163.42773495524838,
        "rarity_level": "COMMON"
    },
    "504": {
        "name": "80's Arkade Bulls #504",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/504.png",
        "dna": "a4080610a9e2da90a7cc3afb79fd53b35c060843",
        "edition": 504,
        "date": 1654567304923,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rarity": 7,
        "rank": "Common",
        "id": "504",
        "rarity_score": 109.30207403830875,
        "rarity_level": "COMMON"
    },
    "505": {
        "name": "80's Arkade Bulls #505",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/505.png",
        "dna": "a8763634658cfa9c0627ed3226560e0f30fe5f3b",
        "edition": 505,
        "date": 1654567837931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666666,
        "id": "505",
        "rarity_score": 106.13198714551324,
        "rarity_level": "COMMON"
    },
    "506": {
        "name": "80's Arkade Bulls #506",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/506.png",
        "dna": "4269c69dceee10c71921559822b21bf20d2ca8d3",
        "edition": 506,
        "date": 1654567294121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "506",
        "rarity_score": 102.3915527385105,
        "rarity_level": "COMMON"
    },
    "507": {
        "name": "80's Arkade Bulls #507",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/507.png",
        "dna": "24ae3cc6b1c4e508ee34b2324fef057be4e5c50e",
        "edition": 507,
        "date": 1654567935073,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "507",
        "rarity_score": 240.26538469071895,
        "rarity_level": "RARE"
    },
    "508": {
        "name": "80's Arkade Bulls #508",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/508.png",
        "dna": "f2e09124af1935318f88e3f866c18e0324f3af43",
        "edition": 508,
        "date": 1654567518158,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "508",
        "rarity_score": 164.66262694716823,
        "rarity_level": "COMMON"
    },
    "509": {
        "name": "80's Arkade Bulls #509",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/509.png",
        "dna": "ebf228def7f000e3f5445046a08d1f7a2fc33fb5",
        "edition": 509,
        "date": 1654567901354,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "509",
        "rarity_score": 108.58490200884324,
        "rarity_level": "COMMON"
    },
    "510": {
        "name": "80's Arkade Bulls #510",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/510.png",
        "dna": "55fc50779037e3e3d3d4619204dfce765d045ac7",
        "edition": 510,
        "date": 1654567238558,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "510",
        "rarity_score": 104.72571014028111,
        "rarity_level": "COMMON"
    },
    "511": {
        "name": "80's Arkade Bulls #511",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/511.png",
        "dna": "09c93523b7cb4c0063ec6a6cd2eb9560438f6589",
        "edition": 511,
        "date": 1654567278002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "511",
        "rarity_score": 93.72710171601798,
        "rarity_level": "COMMON"
    },
    "512": {
        "name": "80's Arkade Bulls #512",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/512.png",
        "dna": "279c282d6393d26581a4b28549875d746553f158",
        "edition": 512,
        "date": 1654568161222,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "512",
        "rarity_score": 247.55426906094905,
        "rarity_level": "RARE"
    },
    "513": {
        "name": "80's Arkade Bulls #513",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/513.png",
        "dna": "c58b10d345feb08c494473edf5150172104c4a5c",
        "edition": 513,
        "date": 1654567832935,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "513",
        "rarity_score": 108.58585789257,
        "rarity_level": "COMMON"
    },
    "514": {
        "name": "80's Arkade Bulls #514",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/514.png",
        "dna": "8dc918bdfcf6e6d97a4201b8dde798389d92928d",
        "edition": 514,
        "date": 1654567251057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "514",
        "rarity_score": 150.06154362828363,
        "rarity_level": "COMMON"
    },
    "515": {
        "name": "80's Arkade Bulls #515",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/515.png",
        "dna": "ab9d3bed0194d580cf513284bb3bd2e9de77a0bd",
        "edition": 515,
        "date": 1654567333990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "515",
        "rarity_score": 265.2163263776935,
        "rarity_level": "RARE"
    },
    "516": {
        "name": "80's Arkade Bulls #516",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/516.png",
        "dna": "38e6e46382fe9cd71514c131c91395a2641534ce",
        "edition": 516,
        "date": 1654567933398,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "516",
        "rarity_score": 76.583469179838,
        "rarity_level": "COMMON"
    },
    "517": {
        "name": "80's Arkade Bulls #517",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/517.png",
        "dna": "7b9198b41085f161063093ca67323ba84b22c6e3",
        "edition": 517,
        "date": 1654567395039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "517",
        "rarity_score": 164.24781825365807,
        "rarity_level": "COMMON"
    },
    "518": {
        "name": "80's Arkade Bulls #518",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/518.png",
        "dna": "c2c907896a28da134a30f25fb8f91ac85a1da933",
        "edition": 518,
        "date": 1654567919620,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "518",
        "rarity_score": 111.8590468788266,
        "rarity_level": "COMMON"
    },
    "519": {
        "name": "80's Arkade Bulls #519",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/519.png",
        "dna": "5c8bf1fef141d45e85e97d47312b8ad606d22518",
        "edition": 519,
        "date": 1654567369632,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "519",
        "rarity_score": 252.52686993502635,
        "rarity_level": "RARE"
    },
    "520": {
        "name": "80's Arkade Bulls #520",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/520.png",
        "dna": "3a8afd33147fbc720e999b194b80a5efa7f7dce3",
        "edition": 520,
        "date": 1654567554570,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "520",
        "rarity_score": 234.0597161662191,
        "rarity_level": "RARE"
    },
    "521": {
        "name": "80's Arkade Bulls #521",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/521.png",
        "dna": "6b928c01c5a233e7a331f8f45fc1ece625cf8aa3",
        "edition": 521,
        "date": 1654567539682,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "521",
        "rarity_score": 165.95052816249324,
        "rarity_level": "COMMON"
    },
    "522": {
        "name": "80's Arkade Bulls #522",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/522.png",
        "dna": "a322aeaf595706c645d8ae2619c25a1512549c90",
        "edition": 522,
        "date": 1654567859162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "522",
        "rarity_score": 126.19273169801309,
        "rarity_level": "COMMON"
    },
    "523": {
        "name": "80's Arkade Bulls #523",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/523.png",
        "dna": "0f2555dd7f520f8b15b19e48c9e9a92724595367",
        "edition": 523,
        "date": 1654567927397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.571428571428571,
        "id": "523",
        "rarity_score": 90.87498711407386,
        "rarity_level": "COMMON"
    },
    "524": {
        "name": "80's Arkade Bulls #524",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/524.png",
        "dna": "9b4bb289e3c77a476aeac1d2223b0c2e1225d08a",
        "edition": 524,
        "date": 1654567312941,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "524",
        "rarity_score": 246.0410655491098,
        "rarity_level": "RARE"
    },
    "525": {
        "name": "80's Arkade Bulls #525",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/525.png",
        "dna": "f01a7e015ed9f6c21383adb853f0baecccd053f3",
        "edition": 525,
        "date": 1654567521375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "525",
        "rarity_score": 152.0118564448823,
        "rarity_level": "COMMON"
    },
    "526": {
        "name": "80's Arkade Bulls #526",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/526.png",
        "dna": "49d8095baa104ed5279df4fd8433a6a807dd49d5",
        "edition": 526,
        "date": 1654568204575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "526",
        "rarity_score": 127.47385978834521,
        "rarity_level": "COMMON"
    },
    "527": {
        "name": "80's Arkade Bulls #527",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/527.png",
        "dna": "343919eca614924504ba4dc8f62027b8c93a3984",
        "edition": 527,
        "date": 1654567909442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "527",
        "rarity_score": 98.66231612266219,
        "rarity_level": "COMMON"
    },
    "528": {
        "name": "80's Arkade Bulls #528",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/528.png",
        "dna": "8a68ce223b053a592fbc8a01cb95562f12f3a36b",
        "edition": 528,
        "date": 1654568116837,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 6.199999999999999,
        "id": "528",
        "rarity_score": 95.9504176721986,
        "rarity_level": "COMMON"
    },
    "529": {
        "name": "80's Arkade Bulls #529",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/529.png",
        "dna": "2d12dc2a800da4371380b13f9792d7e0d9ee5e93",
        "edition": 529,
        "date": 1654568220577,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 1.7999999999999998,
        "id": "529",
        "rarity_score": 254.37570634661185,
        "rarity_level": "RARE"
    },
    "530": {
        "name": "80's Arkade Bulls #530",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/530.png",
        "dna": "fcb32fd9857f7a484c44dbf17fff1ae43bd98081",
        "edition": 530,
        "date": 1654567240623,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "530",
        "rarity_score": 74.84364238011796,
        "rarity_level": "COMMON"
    },
    "531": {
        "name": "80's Arkade Bulls #531",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/531.png",
        "dna": "7d35f72c46339ed2ae6eda97373cc418da7c3312",
        "edition": 531,
        "date": 1654567217190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "531",
        "rarity_score": 63.6373627355806,
        "rarity_level": "COMMON"
    },
    "532": {
        "name": "80's Arkade Bulls #532",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/532.png",
        "dna": "d2c3beb0a5fde2f45a601414bbdad47d8f7328ef",
        "edition": 532,
        "date": 1654568050333,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "532",
        "rarity_score": 74.55038793267519,
        "rarity_level": "COMMON"
    },
    "533": {
        "name": "80's Arkade Bulls #533",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/533.png",
        "dna": "e47b9fad1fc497813948fec2ff197211b5064666",
        "edition": 533,
        "date": 1654567937278,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857143,
        "id": "533",
        "rarity_score": 189.43654283007763,
        "rarity_level": "COMMON"
    },
    "534": {
        "name": "80's Arkade Bulls #534",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/534.png",
        "dna": "d3161deb2c1cd584d45cc2b9c3e8f36d2b6af0ed",
        "edition": 534,
        "date": 1654567968003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "534",
        "rarity_score": 89.04908285685124,
        "rarity_level": "COMMON"
    },
    "535": {
        "name": "80's Arkade Bulls #535",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/535.png",
        "dna": "7bd5f52c42b9a2d3d5ff5b6f14e94e340bc86958",
        "edition": 535,
        "date": 1654568184785,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Pilot Goggles", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "535",
        "rarity_score": 138.77443779229827,
        "rarity_level": "COMMON"
    },
    "536": {
        "name": "80's Arkade Bulls #536",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/536.png",
        "dna": "8ade90a2c1ac1cb6cd1b46f4ec65003e3138456b",
        "edition": 536,
        "date": 1654567711226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "536",
        "rarity_score": 80.82554882418083,
        "rarity_level": "COMMON"
    },
    "537": {
        "name": "80's Arkade Bulls #537",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/537.png",
        "dna": "6be7a297791f88cca10dc910345099ddf74c692d",
        "edition": 537,
        "date": 1654567340164,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Rare",
        "rarity": 4.777777777777778,
        "id": "537",
        "rarity_score": 256.8897387846465,
        "rarity_level": "RARE"
    },
    "538": {
        "name": "80's Arkade Bulls #538",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/538.png",
        "dna": "889982147930904d63ac293e7173fde0533b33cc",
        "edition": 538,
        "date": 1654567189582,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "538",
        "rarity_score": 72.51739521454098,
        "rarity_level": "COMMON"
    },
    "539": {
        "name": "80's Arkade Bulls #539",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/539.png",
        "dna": "2d40e49bb93bc9dfecfb8d10276bc05cd2dc2759",
        "edition": 539,
        "date": 1654567880059,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "539",
        "rarity_score": 84.58684868173762,
        "rarity_level": "COMMON"
    },
    "540": {
        "name": "80's Arkade Bulls #540",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/540.png",
        "dna": "13213a1b6e0983a5102aa2ceb26660ae8aa3c773",
        "edition": 540,
        "date": 1654567328168,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8888888888888893,
        "id": "540",
        "rarity_score": 337.1393477772957,
        "rarity_level": "RARE"
    },
    "541": {
        "name": "80's Arkade Bulls #541",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/541.png",
        "dna": "7e827eb108ed15f6d273e9b6e6a470182f2e0f4c",
        "edition": 541,
        "date": 1654567510437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "541",
        "rarity_score": 183.66939428553385,
        "rarity_level": "COMMON"
    },
    "542": {
        "name": "80's Arkade Bulls #542",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/542.png",
        "dna": "1861cd1afe23c10e601b7faa72ee087ffa9954e5",
        "edition": 542,
        "date": 1654568186316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "542",
        "rarity_score": 151.58327853360328,
        "rarity_level": "COMMON"
    },
    "543": {
        "name": "80's Arkade Bulls #543",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/543.png",
        "dna": "465ba61f98175c97c8375651d3b219a878a41ba4",
        "edition": 543,
        "date": 1654567370295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "543",
        "rarity_score": 244.77328211807222,
        "rarity_level": "RARE"
    },
    "544": {
        "name": "80's Arkade Bulls #544",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/544.png",
        "dna": "40453737a631617342cb12438b866d16d027f651",
        "edition": 544,
        "date": 1654568172297,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "544",
        "rarity_score": 157.4506795688469,
        "rarity_level": "COMMON"
    },
    "545": {
        "name": "80's Arkade Bulls #545",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/545.png",
        "dna": "0be621e3794838fbcdf5513c9396322f753c15af",
        "edition": 545,
        "date": 1654567381074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "545",
        "rarity_score": 176.4202416066003,
        "rarity_level": "COMMON"
    },
    "546": {
        "name": "80's Arkade Bulls #546",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/546.png",
        "dna": "a3450befe25148e3ff5b7441b039a991b73cef4f",
        "edition": 546,
        "date": 1654567966907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "546",
        "rarity_score": 114.02950498618287,
        "rarity_level": "COMMON"
    },
    "547": {
        "name": "80's Arkade Bulls #547",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/547.png",
        "dna": "a804d8f354bf8b1d73506b94f673cfebbd38cb57",
        "edition": 547,
        "date": 1654567985386,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "547",
        "rarity_score": 85.9122770826915,
        "rarity_level": "COMMON"
    },
    "548": {
        "name": "80's Arkade Bulls #548",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/548.png",
        "dna": "7773f92f08ea59f6bf5f29006fa1c3d0f1dafd1a",
        "edition": 548,
        "date": 1654567576465,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.7142857142857135,
        "id": "548",
        "rarity_score": 182.4783186462871,
        "rarity_level": "COMMON"
    },
    "549": {
        "name": "80's Arkade Bulls #549",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/549.png",
        "dna": "754df042c703531d7ee25756fc36b3a96403580e",
        "edition": 549,
        "date": 1654568151622,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "549",
        "rarity_score": 285.519600107559,
        "rarity_level": "RARE"
    },
    "550": {
        "name": "80's Arkade Bulls #550",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/550.png",
        "dna": "49686f3cbaf5900ea7c92eb5f092e656b728c807",
        "edition": 550,
        "date": 1654568094538,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "550",
        "rarity_score": 85.42474844543369,
        "rarity_level": "COMMON"
    },
    "551": {
        "name": "80's Arkade Bulls #551",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/551.png",
        "dna": "7a94119e9712b1df4f0cc9dd01a0e17f002d55f9",
        "edition": 551,
        "date": 1654568163761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "551",
        "rarity_score": 133.54320770586534,
        "rarity_level": "COMMON"
    },
    "552": {
        "name": "80's Arkade Bulls #552",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/552.png",
        "dna": "76892de1bb13a0d5953ad5c1dc67ed5871390bf4",
        "edition": 552,
        "date": 1654567616678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "552",
        "rarity_score": 135.91322220885547,
        "rarity_level": "COMMON"
    },
    "553": {
        "name": "80's Arkade Bulls #553",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/553.png",
        "dna": "ee63f214e5c222eaa8db79b942ff60ce9ef433ff",
        "edition": 553,
        "date": 1654567117533,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "553",
        "rarity_score": 144.68042694564218,
        "rarity_level": "COMMON"
    },
    "554": {
        "name": "80's Arkade Bulls #554",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/554.png",
        "dna": "58072b2e952440b8bdffd925fc53918ead76ae78",
        "edition": 554,
        "date": 1654567276409,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "554",
        "rarity_score": 150.92453737413416,
        "rarity_level": "COMMON"
    },
    "555": {
        "name": "80's Arkade Bulls #555",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/555.png",
        "dna": "597db39c751e097ab4eb47aad1b86d899f209c01",
        "edition": 555,
        "date": 1654567825681,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "555",
        "rarity_score": 76.44551885034878,
        "rarity_level": "COMMON"
    },
    "556": {
        "name": "80's Arkade Bulls #556",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/556.png",
        "dna": "87c5e317e3f649fff9f4969c556c3cac108161d0",
        "edition": 556,
        "date": 1654567114357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "556",
        "rarity_score": 281.9203146374829,
        "rarity_level": "RARE"
    },
    "557": {
        "name": "80's Arkade Bulls #557",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/557.png",
        "dna": "1d64f81bf36598c7b7479cb092357dfa809af6ba",
        "edition": 557,
        "date": 1654567988950,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "557",
        "rarity_score": 95.89733092695361,
        "rarity_level": "COMMON"
    },
    "558": {
        "name": "80's Arkade Bulls #558",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/558.png",
        "dna": "174b2a92d87fd9036631bfdcfad1ef058f6f8933",
        "edition": 558,
        "date": 1654567906751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "558",
        "rarity_score": 77.3558574738899,
        "rarity_level": "COMMON"
    },
    "559": {
        "name": "80's Arkade Bulls #559",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/559.png",
        "dna": "7ae8595c19da61dfb6d599a568ace5a14078082c",
        "edition": 559,
        "date": 1654567393542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "559",
        "rarity_score": 137.763219640736,
        "rarity_level": "COMMON"
    },
    "560": {
        "name": "80's Arkade Bulls #560",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/560.png",
        "dna": "317b7628a33b99864a5d8a504271b0c4aee52c54",
        "edition": 560,
        "date": 1654567697215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "560",
        "rarity_score": 95.09837123073504,
        "rarity_level": "COMMON"
    },
    "561": {
        "name": "80's Arkade Bulls #561",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/561.png",
        "dna": "890ada6d578f4cf7f849157de4df6443d65094ed",
        "edition": 561,
        "date": 1654567920242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "561",
        "rarity_score": 94.24397892536396,
        "rarity_level": "COMMON"
    },
    "562": {
        "name": "80's Arkade Bulls #562",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/562.png",
        "dna": "0e1b7a16ef3a98cd5b7b3e543a5907362917d7d1",
        "edition": 562,
        "date": 1654567235999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "562",
        "rarity_score": 68.48324377847482,
        "rarity_level": "COMMON"
    },
    "563": {
        "name": "80's Arkade Bulls #563",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/563.png",
        "dna": "b126305c021df4a355d72c3ee9696d26cce6d674",
        "edition": 563,
        "date": 1654568190054,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "563",
        "rarity_score": 107.98632057597575,
        "rarity_level": "COMMON"
    },
    "564": {
        "name": "80's Arkade Bulls #564",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/564.png",
        "dna": "297767bef50875d2d1eb51112888d14dbb1d9dac",
        "edition": 564,
        "date": 1654568218967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Super",
        "rarity": 2.833333333333333,
        "id": "564",
        "rarity_score": 396.3357035121741,
        "rarity_level": "RARE"
    },
    "565": {
        "name": "80's Arkade Bulls #565",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/565.png",
        "dna": "b78815f6d6b8ef9caa0285878e1f66431d0b2a43",
        "edition": 565,
        "date": 1654567336759,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "565",
        "rarity_score": 326.7676447447683,
        "rarity_level": "RARE"
    },
    "566": {
        "name": "80's Arkade Bulls #566",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/566.png",
        "dna": "ab2b72e0769f3fb1b338f947dcfe0832034dc037",
        "edition": 566,
        "date": 1654567529231,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "566",
        "rarity_score": 127.65476701777098,
        "rarity_level": "COMMON"
    },
    "567": {
        "name": "80's Arkade Bulls #567",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/567.png",
        "dna": "4c976db6b3e339bcda4ae2cd0c76c027ee45130d",
        "edition": 567,
        "date": 1654567505521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "567",
        "rarity_score": 160.6039119031582,
        "rarity_level": "COMMON"
    },
    "568": {
        "name": "80's Arkade Bulls #568",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/568.png",
        "dna": "6f108e52e9d3dbad45bae8f60629b1a999fc88b0",
        "edition": 568,
        "date": 1654567508803,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "568",
        "rarity_score": 180.36986464908097,
        "rarity_level": "COMMON"
    },
    "569": {
        "name": "80's Arkade Bulls #569",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/569.png",
        "dna": "98cb5e9e1dd1acc9e478b71d4a1bc9a490b66b27",
        "edition": 569,
        "date": 1654568123247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "569",
        "rarity_score": 146.39433633918685,
        "rarity_level": "COMMON"
    },
    "570": {
        "name": "80's Arkade Bulls #570",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/570.png",
        "dna": "c213e234621a4c146d53aea158c6efa5fef0db22",
        "edition": 570,
        "date": 1654567423761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "570",
        "rarity_score": 126.90710418513072,
        "rarity_level": "COMMON"
    },
    "571": {
        "name": "80's Arkade Bulls #571",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/571.png",
        "dna": "d3094a6e729f8b933b0638468c8f84c6b9260d2d",
        "edition": 571,
        "date": 1654567228156,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "571",
        "rarity_score": 86.65241749813667,
        "rarity_level": "COMMON"
    },
    "572": {
        "name": "80's Arkade Bulls #572",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/572.png",
        "dna": "d078fe0b1e6c1b5138c70aa0fe9e1a2f80fe126a",
        "edition": 572,
        "date": 1654567265479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "572",
        "rarity_score": 154.68356931934295,
        "rarity_level": "COMMON"
    },
    "573": {
        "name": "80's Arkade Bulls #573",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/573.png",
        "dna": "8e8af6a137a955789fd29d814fc8c0c8864ddcdb",
        "edition": 573,
        "date": 1654568069889,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "573",
        "rarity_score": 76.7211375873525,
        "rarity_level": "COMMON"
    },
    "574": {
        "name": "80's Arkade Bulls #574",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/574.png",
        "dna": "7bf119e7b35e6fdcd9f01a4068e6e5fee1f047fc",
        "edition": 574,
        "date": 1654567197019,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "574",
        "rarity_score": 60.24836008841644,
        "rarity_level": "COMMON"
    },
    "575": {
        "name": "80's Arkade Bulls #575",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/575.png",
        "dna": "ab83d946e714629196a011815727014c58ebbdb2",
        "edition": 575,
        "date": 1654567457898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.571428571428571,
        "id": "575",
        "rarity_score": 79.73259105602851,
        "rarity_level": "COMMON"
    },
    "576": {
        "name": "80's Arkade Bulls #576",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/576.png",
        "dna": "37a6a6b54d232c1d252bcd813f6a45e918fbf4c0",
        "edition": 576,
        "date": 1654567112977,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forrest Tunic",
                "score": 1980.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Forrest Tunic Hat",
                "score": 1980.0
            }
        ],
        "rarity": 0,
        "rank": "Legendary",
        "id": "576",
        "rarity_score": 4046.3261324041814,
        "rarity_level": "LEGENDARY"
    },
    "577": {
        "name": "80's Arkade Bulls #577",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/577.png",
        "dna": "035407fca82f5d8d285b0244ee9813a0e1029fd1",
        "edition": 577,
        "date": 1654567595413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "577",
        "rarity_score": 205.66360904827047,
        "rarity_level": "RARE"
    },
    "578": {
        "name": "80's Arkade Bulls #578",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/578.png",
        "dna": "626716cfc311b52e7ad39ab42d321345c0f9cbd6",
        "edition": 578,
        "date": 1654567247053,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "578",
        "rarity_score": 155.98946288285447,
        "rarity_level": "COMMON"
    },
    "579": {
        "name": "80's Arkade Bulls #579",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/579.png",
        "dna": "c2d70e83f7fa8ca73176eb7f0ef91f879676f0d5",
        "edition": 579,
        "date": 1654567448834,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8571428571428577,
        "id": "579",
        "rarity_score": 173.09235357198133,
        "rarity_level": "COMMON"
    },
    "580": {
        "name": "80's Arkade Bulls #580",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/580.png",
        "dna": "0fe534bef3700c666fb1e320b459df0a62b2f2f4",
        "edition": 580,
        "date": 1654567698829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857143,
        "id": "580",
        "rarity_score": 145.62295870383247,
        "rarity_level": "COMMON"
    },
    "581": {
        "name": "80's Arkade Bulls #581",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/581.png",
        "dna": "71b451f9b463ccada258eca351eb630e230ff9ff",
        "edition": 581,
        "date": 1654567850644,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "581",
        "rarity_score": 101.2910526913414,
        "rarity_level": "COMMON"
    },
    "582": {
        "name": "80's Arkade Bulls #582",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/582.png",
        "dna": "fda2985686693e854145dce5b54c57305d4f3ab7",
        "edition": 582,
        "date": 1654567547368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "582",
        "rarity_score": 130.14903275792477,
        "rarity_level": "COMMON"
    },
    "583": {
        "name": "80's Arkade Bulls #583",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/583.png",
        "dna": "322f08ebd2c970e7c3af7adc032d076d2b03287f",
        "edition": 583,
        "date": 1654567617990,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 4.875,
        "id": "583",
        "rarity_score": 211.92736678103927,
        "rarity_level": "RARE"
    },
    "584": {
        "name": "80's Arkade Bulls #584",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/584.png",
        "dna": "3dfe2fb4ca70479ba02c09085a6b9fe2457b71ee",
        "edition": 584,
        "date": 1654567250547,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "584",
        "rarity_score": 99.12833011485603,
        "rarity_level": "COMMON"
    },
    "585": {
        "name": "80's Arkade Bulls #585",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/585.png",
        "dna": "7f3aa6bbb0fa8d9e8b9bf84b1d682b6cb93c39d1",
        "edition": 585,
        "date": 1654567721559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "585",
        "rarity_score": 92.78007095055821,
        "rarity_level": "COMMON"
    },
    "586": {
        "name": "80's Arkade Bulls #586",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/586.png",
        "dna": "8b432d0e6b7e4c513adc79a7849a850845816e09",
        "edition": 586,
        "date": 1654567382978,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "586",
        "rarity_score": 100.533080586577,
        "rarity_level": "COMMON"
    },
    "587": {
        "name": "80's Arkade Bulls #587",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/587.png",
        "dna": "e96b46a187171601beba29e8389a89977249972b",
        "edition": 587,
        "date": 1654567657121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "587",
        "rarity_score": 123.03394524943519,
        "rarity_level": "COMMON"
    },
    "588": {
        "name": "80's Arkade Bulls #588",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/588.png",
        "dna": "a9612dc5e4d3915d690d1acaaf5035d98502ae9c",
        "edition": 588,
        "date": 1654568230158,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Super",
        "rarity": 2.6,
        "id": "588",
        "rarity_score": 234.40497397339502,
        "rarity_level": "RARE"
    },
    "589": {
        "name": "80's Arkade Bulls #589",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/589.png",
        "dna": "928240522e23d19e83a3dd3dd602c7d763584900",
        "edition": 589,
        "date": 1654567539120,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "589",
        "rarity_score": 162.76747860367794,
        "rarity_level": "COMMON"
    },
    "590": {
        "name": "80's Arkade Bulls #590",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/590.png",
        "dna": "20fc03dfd7d42d5190ac5884a8f60aaf577e530b",
        "edition": 590,
        "date": 1654567441547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "590",
        "rarity_score": 115.61232659745139,
        "rarity_level": "COMMON"
    },
    "591": {
        "name": "80's Arkade Bulls #591",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/591.png",
        "dna": "eb6e99972c78edad72e456832ca7816fcde8af99",
        "edition": 591,
        "date": 1654567510925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "591",
        "rarity_score": 224.93863980823284,
        "rarity_level": "RARE"
    },
    "592": {
        "name": "80's Arkade Bulls #592",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/592.png",
        "dna": "5083f6a765f4769d8d61385cb5c37baac1ddee59",
        "edition": 592,
        "date": 1654568074890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "592",
        "rarity_score": 103.43984831789712,
        "rarity_level": "COMMON"
    },
    "593": {
        "name": "80's Arkade Bulls #593",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/593.png",
        "dna": "e520d0e50d7bf72ba43cd54f4ad38dd994cdff4d",
        "edition": 593,
        "date": 1654568219559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "593",
        "rarity_score": 260.57308402396654,
        "rarity_level": "RARE"
    },
    "594": {
        "name": "80's Arkade Bulls #594",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/594.png",
        "dna": "47cb65fc37cfa1cb50c678d3513516fc6623f395",
        "edition": 594,
        "date": 1654567822613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "594",
        "rarity_score": 70.21976404343997,
        "rarity_level": "COMMON"
    },
    "595": {
        "name": "80's Arkade Bulls #595",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/595.png",
        "dna": "1a3acb013e02f2f12d4684580b8f163ab4e65d04",
        "edition": 595,
        "date": 1654567816746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333334,
        "id": "595",
        "rarity_score": 120.40194918424561,
        "rarity_level": "COMMON"
    },
    "596": {
        "name": "80's Arkade Bulls #596",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/596.png",
        "dna": "dd4119fa8cb3d25e6c6fb783ab987affd5c1ef32",
        "edition": 596,
        "date": 1654567191427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "596",
        "rarity_score": 78.77952827222336,
        "rarity_level": "COMMON"
    },
    "597": {
        "name": "80's Arkade Bulls #597",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/597.png",
        "dna": "21fac5c4fbbe1cb0eb9d6a8f5e36e815cac796c5",
        "edition": 597,
        "date": 1654567950091,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "597",
        "rarity_score": 90.37596951754801,
        "rarity_level": "COMMON"
    },
    "598": {
        "name": "80's Arkade Bulls #598",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/598.png",
        "dna": "28e825b42ffefe0205d732e7223025ca6ce2b2c8",
        "edition": 598,
        "date": 1654567850174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "598",
        "rarity_score": 139.49314363313619,
        "rarity_level": "COMMON"
    },
    "599": {
        "name": "80's Arkade Bulls #599",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/599.png",
        "dna": "be644c08dea40fbc9ad7bdc73f9f7464000444cc",
        "edition": 599,
        "date": 1654567146362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "599",
        "rarity_score": 79.27403467297084,
        "rarity_level": "COMMON"
    },
    "600": {
        "name": "80's Arkade Bulls #600",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/600.png",
        "dna": "9340499fc9c8dbc32d50e6ae95e35917b7a538ca",
        "edition": 600,
        "date": 1654568205611,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "600",
        "rarity_score": 106.00410052910053,
        "rarity_level": "COMMON"
    },
    "601": {
        "name": "80's Arkade Bulls #601",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/601.png",
        "dna": "a1352869caaec6dae5c9b8870f459b880b0db537",
        "edition": 601,
        "date": 1654567638193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.25,
        "id": "601",
        "rarity_score": 125.38777552334479,
        "rarity_level": "COMMON"
    },
    "602": {
        "name": "80's Arkade Bulls #602",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/602.png",
        "dna": "73ff13c5cf9e81acce63296729d385b2923015a4",
        "edition": 602,
        "date": 1654567322966,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Epic",
        "rarity": 3.7777777777777777,
        "id": "602",
        "rarity_score": 355.5958855630166,
        "rarity_level": "RARE"
    },
    "603": {
        "name": "80's Arkade Bulls #603",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/603.png",
        "dna": "db318b91b0a1ac16b00eaaa929d40f4a68de9697",
        "edition": 603,
        "date": 1654567619202,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.25,
        "id": "603",
        "rarity_score": 105.50873694741699,
        "rarity_level": "COMMON"
    },
    "604": {
        "name": "80's Arkade Bulls #604",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/604.png",
        "dna": "d72ebeb81c33d049ddf9778fffc65c9c77390c94",
        "edition": 604,
        "date": 1654567841330,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "604",
        "rarity_score": 63.89685930941385,
        "rarity_level": "COMMON"
    },
    "605": {
        "name": "80's Arkade Bulls #605",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/605.png",
        "dna": "11892cbbedde93a13caa029f552226fad2cceaae",
        "edition": 605,
        "date": 1654568190575,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "605",
        "rarity_score": 102.95817910160017,
        "rarity_level": "COMMON"
    },
    "606": {
        "name": "80's Arkade Bulls #606",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/606.png",
        "dna": "ac1358258d6c1160958df961e1a49f206b447a9f",
        "edition": 606,
        "date": 1654567493710,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.571428571428571,
        "id": "606",
        "rarity_score": 179.94079568676727,
        "rarity_level": "COMMON"
    },
    "607": {
        "name": "80's Arkade Bulls #607",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/607.png",
        "dna": "5a8966d7b117bf6cdc7c215f5458e020f33807e6",
        "edition": 607,
        "date": 1654567138953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "607",
        "rarity_score": 83.7076354679803,
        "rarity_level": "COMMON"
    },
    "608": {
        "name": "80's Arkade Bulls #608",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/608.png",
        "dna": "d6480c7f2e717eaa4920af2c2ad0d86ab1fd76d6",
        "edition": 608,
        "date": 1654567171371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.2,
        "id": "608",
        "rarity_score": 92.28443266981228,
        "rarity_level": "COMMON"
    },
    "609": {
        "name": "80's Arkade Bulls #609",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/609.png",
        "dna": "848cf4ae0e629022fac3408d7bb1ef2f1eca06ee",
        "edition": 609,
        "date": 1654567221103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333334,
        "id": "609",
        "rarity_score": 143.2805013075734,
        "rarity_level": "COMMON"
    },
    "610": {
        "name": "80's Arkade Bulls #610",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/610.png",
        "dna": "d5f657d30908c23d0014f0b1ac59b43ff39e024a",
        "edition": 610,
        "date": 1654567958659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "610",
        "rarity_score": 91.64314237370074,
        "rarity_level": "COMMON"
    },
    "611": {
        "name": "80's Arkade Bulls #611",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/611.png",
        "dna": "1013bf590e7149ee2ecc705ac7bdf804091edf0c",
        "edition": 611,
        "date": 1654567523035,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "611",
        "rarity_score": 122.31003271522037,
        "rarity_level": "COMMON"
    },
    "612": {
        "name": "80's Arkade Bulls #612",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/612.png",
        "dna": "abd66aae972185a31accdf3eef222b9f93483ab6",
        "edition": 612,
        "date": 1654567917976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "612",
        "rarity_score": 122.14375412482877,
        "rarity_level": "COMMON"
    },
    "613": {
        "name": "80's Arkade Bulls #613",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/613.png",
        "dna": "2e7286ab18e3b54e31e1bc0c976d207a12c99c79",
        "edition": 613,
        "date": 1654567198415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "613",
        "rarity_score": 151.1762797829677,
        "rarity_level": "COMMON"
    },
    "614": {
        "name": "80's Arkade Bulls #614",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/614.png",
        "dna": "50a2f2cea0119f79d2913fe1beb20ebb032fd838",
        "edition": 614,
        "date": 1654568171273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "614",
        "rarity_score": 215.88638814016173,
        "rarity_level": "RARE"
    },
    "615": {
        "name": "80's Arkade Bulls #615",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/615.png",
        "dna": "e6474aca670ee5cf234567b31020410ca361f36c",
        "edition": 615,
        "date": 1654567494237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.1428571428571423,
        "id": "615",
        "rarity_score": 252.80157742296328,
        "rarity_level": "RARE"
    },
    "616": {
        "name": "80's Arkade Bulls #616",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/616.png",
        "dna": "b05be8709aee8ea53ec6c96f58a73c4fbd2c8a37",
        "edition": 616,
        "date": 1654568113150,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "616",
        "rarity_score": 107.07835677614065,
        "rarity_level": "COMMON"
    },
    "617": {
        "name": "80's Arkade Bulls #617",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/617.png",
        "dna": "fa67a022396c6c878976a5593cf60af0f31bab0c",
        "edition": 617,
        "date": 1654567258261,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "617",
        "rarity_score": 110.16553171835429,
        "rarity_level": "COMMON"
    },
    "618": {
        "name": "80's Arkade Bulls #618",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/618.png",
        "dna": "6744e040523b7f639b1772183f83872107e76917",
        "edition": 618,
        "date": 1654567248531,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "618",
        "rarity_score": 87.84581738252581,
        "rarity_level": "COMMON"
    },
    "619": {
        "name": "80's Arkade Bulls #619",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/619.png",
        "dna": "24ae85f117189f69d97aca34b95e3f4942f774da",
        "edition": 619,
        "date": 1654568191093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Eyewear", "value": "Pilot Goggles", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "619",
        "rarity_score": 236.44956173683943,
        "rarity_level": "RARE"
    },
    "620": {
        "name": "80's Arkade Bulls #620",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/620.png",
        "dna": "1d27c4961a2a66534842eb5d24af9340dac8c5c9",
        "edition": 620,
        "date": 1654567953702,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "620",
        "rarity_score": 183.6881446548843,
        "rarity_level": "COMMON"
    },
    "621": {
        "name": "80's Arkade Bulls #621",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/621.png",
        "dna": "8f94a28de5a65d8c7f8972720a9816ae93d672e7",
        "edition": 621,
        "date": 1654568126105,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "621",
        "rarity_score": 59.56629739428928,
        "rarity_level": "COMMON"
    },
    "622": {
        "name": "80's Arkade Bulls #622",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/622.png",
        "dna": "77e9ace712c4e1dd4dd6b95a3bfe0f2439db1e8c",
        "edition": 622,
        "date": 1654568104245,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rarity": 5.4,
        "rank": "Rare",
        "id": "622",
        "rarity_score": 130.04692910258916,
        "rarity_level": "COMMON"
    },
    "623": {
        "name": "80's Arkade Bulls #623",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/623.png",
        "dna": "8ec5fc0f23d0f9dff01381f934fd1ffa4e1350d1",
        "edition": 623,
        "date": 1654567529715,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "623",
        "rarity_score": 205.92276912169342,
        "rarity_level": "RARE"
    },
    "624": {
        "name": "80's Arkade Bulls #624",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/624.png",
        "dna": "7013ab798100a99269c2009b7236e0ac89a0455d",
        "edition": 624,
        "date": 1654567110273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "624",
        "rarity_score": 188.45154077510335,
        "rarity_level": "COMMON"
    },
    "625": {
        "name": "80's Arkade Bulls #625",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/625.png",
        "dna": "15b5895ee8fc94dca73ab77f77da9febb5804748",
        "edition": 625,
        "date": 1654567110687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "625",
        "rarity_score": 227.79858129343543,
        "rarity_level": "RARE"
    },
    "626": {
        "name": "80's Arkade Bulls #626",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/626.png",
        "dna": "a133ec492646cdfa9481d1d478134c57066a813b",
        "edition": 626,
        "date": 1654567127152,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "626",
        "rarity_score": 60.53193985372077,
        "rarity_level": "COMMON"
    },
    "627": {
        "name": "80's Arkade Bulls #627",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/627.png",
        "dna": "bb1ac46c3d1ce3d64da746a8a9c690e97268cb75",
        "edition": 627,
        "date": 1654567855143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 7.571428571428571,
        "rank": "Common",
        "id": "627",
        "rarity_score": 108.19627055641006,
        "rarity_level": "COMMON"
    },
    "628": {
        "name": "80's Arkade Bulls #628",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/628.png",
        "dna": "6431de84f539f7e652234e613bd2c5359c41963b",
        "edition": 628,
        "date": 1654567933958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "628",
        "rarity_score": 113.88381517466952,
        "rarity_level": "COMMON"
    },
    "629": {
        "name": "80's Arkade Bulls #629",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/629.png",
        "dna": "59c8d780062bc8e6329e8bc3392344bdb3e5e21f",
        "edition": 629,
        "date": 1654568188492,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Epic",
        "rarity": 3.8333333333333335,
        "id": "629",
        "rarity_score": 212.0691637059195,
        "rarity_level": "RARE"
    },
    "630": {
        "name": "80's Arkade Bulls #630",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/630.png",
        "dna": "c7e18757d59ae4483fd35ed57616d724ac829a06",
        "edition": 630,
        "date": 1654568097714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "630",
        "rarity_score": 72.24737847407386,
        "rarity_level": "COMMON"
    },
    "631": {
        "name": "80's Arkade Bulls #631",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/631.png",
        "dna": "86b600ef745b23c123a1da0cc6e2743cfbb8deaa",
        "edition": 631,
        "date": 1654568102387,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "631",
        "rarity_score": 134.71613995082612,
        "rarity_level": "COMMON"
    },
    "632": {
        "name": "80's Arkade Bulls #632",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/632.png",
        "dna": "bd03b71b16014db1c707d906efc3cbcd400b191e",
        "edition": 632,
        "date": 1654568142508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "632",
        "rarity_score": 101.95271107864575,
        "rarity_level": "COMMON"
    },
    "633": {
        "name": "80's Arkade Bulls #633",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/633.png",
        "dna": "4a9dd38782d3c0488a037fefde8f09a72b2833ae",
        "edition": 633,
        "date": 1654567819286,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "633",
        "rarity_score": 160.36507332489643,
        "rarity_level": "COMMON"
    },
    "634": {
        "name": "80's Arkade Bulls #634",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/634.png",
        "dna": "b44fb8dcbe270a71708514685673d3907ae5c881",
        "edition": 634,
        "date": 1654567698300,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "634",
        "rarity_score": 101.92915553911385,
        "rarity_level": "COMMON"
    },
    "635": {
        "name": "80's Arkade Bulls #635",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/635.png",
        "dna": "7ec433d622acfbf5b3d41cb62caa5dfcedfa8dd5",
        "edition": 635,
        "date": 1654567958188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "635",
        "rarity_score": 100.14746630317094,
        "rarity_level": "COMMON"
    },
    "636": {
        "name": "80's Arkade Bulls #636",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/636.png",
        "dna": "68fe462fd570df66773040cfa26b923ce0f9f466",
        "edition": 636,
        "date": 1654567113335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "636",
        "rarity_score": 238.99955340226276,
        "rarity_level": "RARE"
    },
    "637": {
        "name": "80's Arkade Bulls #637",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/637.png",
        "dna": "441406b6783b4f14f37f628bc5f25f63c5efb4d2",
        "edition": 637,
        "date": 1654567382502,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "637",
        "rarity_score": 162.09036783791467,
        "rarity_level": "COMMON"
    },
    "638": {
        "name": "80's Arkade Bulls #638",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/638.png",
        "dna": "7da95079db4517936d01a4ba9945ba44a6d78aae",
        "edition": 638,
        "date": 1654567862139,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "638",
        "rarity_score": 99.53000480916722,
        "rarity_level": "COMMON"
    },
    "639": {
        "name": "80's Arkade Bulls #639",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/639.png",
        "dna": "934ec7a01715351e38b32ffddc786fbca25e0aa8",
        "edition": 639,
        "date": 1654567111599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "639",
        "rarity_score": 183.00442539048797,
        "rarity_level": "COMMON"
    },
    "640": {
        "name": "80's Arkade Bulls #640",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/640.png",
        "dna": "4e634566a73543aae2949033cad960d4a26ec5c6",
        "edition": 640,
        "date": 1654567704928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "640",
        "rarity_score": 124.85283529533612,
        "rarity_level": "COMMON"
    },
    "641": {
        "name": "80's Arkade Bulls #641",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/641.png",
        "dna": "f4b943b8fa71a52a3f9596fa5160cfb7027c83c9",
        "edition": 641,
        "date": 1654567405449,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "641",
        "rarity_score": 161.2373685085931,
        "rarity_level": "COMMON"
    },
    "642": {
        "name": "80's Arkade Bulls #642",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/642.png",
        "dna": "469a2ab9e7e29bbf03620b66a975f6504b4e1bbe",
        "edition": 642,
        "date": 1654567722602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 9.333333333333334,
        "id": "642",
        "rarity_score": 65.59974764636236,
        "rarity_level": "COMMON"
    },
    "643": {
        "name": "80's Arkade Bulls #643",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/643.png",
        "dna": "03d49619ba46552e1538024ebf91576ee5122b5e",
        "edition": 643,
        "date": 1654567237529,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "643",
        "rarity_score": 174.3783869382467,
        "rarity_level": "COMMON"
    },
    "644": {
        "name": "80's Arkade Bulls #644",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/644.png",
        "dna": "d1c2e3b35ada22ff9bce762901ff329f9b13b378",
        "edition": 644,
        "date": 1654568055771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "644",
        "rarity_score": 69.34860738999896,
        "rarity_level": "COMMON"
    },
    "645": {
        "name": "80's Arkade Bulls #645",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/645.png",
        "dna": "118b39b949e6a02f658a6eb69e82179b0e72cc28",
        "edition": 645,
        "date": 1654567977119,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "645",
        "rarity_score": 170.18470144408082,
        "rarity_level": "COMMON"
    },
    "646": {
        "name": "80's Arkade Bulls #646",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/646.png",
        "dna": "88b95365f951a3792319c0f6eb09a08b4085c9a7",
        "edition": 646,
        "date": 1654568027515,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "646",
        "rarity_score": 105.55836928205218,
        "rarity_level": "COMMON"
    },
    "647": {
        "name": "80's Arkade Bulls #647",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/647.png",
        "dna": "c9286b3df26efa46a48e3e6f487b7ff8861677c8",
        "edition": 647,
        "date": 1654567194238,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "647",
        "rarity_score": 72.62948782813442,
        "rarity_level": "COMMON"
    },
    "648": {
        "name": "80's Arkade Bulls #648",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/648.png",
        "dna": "a2c6357baa4297397f1b1bb170612a20178ff3b4",
        "edition": 648,
        "date": 1654568093573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "648",
        "rarity_score": 75.54120350710664,
        "rarity_level": "COMMON"
    },
    "649": {
        "name": "80's Arkade Bulls #649",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/649.png",
        "dna": "17914ebbfdf79ad9821568492248ff876c012400",
        "edition": 649,
        "date": 1654567661711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "649",
        "rarity_score": 113.90241045777722,
        "rarity_level": "COMMON"
    },
    "650": {
        "name": "80's Arkade Bulls #650",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/650.png",
        "dna": "34bbcd9249ce66daa5fceae66d1ba115c271f6fe",
        "edition": 650,
        "date": 1654567615498,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "650",
        "rarity_score": 174.4655414633787,
        "rarity_level": "COMMON"
    },
    "651": {
        "name": "80's Arkade Bulls #651",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/651.png",
        "dna": "a5eb82465b0a0a49b1f9f2f71aaae22596de82b6",
        "edition": 651,
        "date": 1654567323606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.444444444444445,
        "id": "651",
        "rarity_score": 194.77824023875775,
        "rarity_level": "COMMON"
    },
    "652": {
        "name": "80's Arkade Bulls #652",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/652.png",
        "dna": "e608dc371400eb3e720808e3d6fb2c5cd8ac46fc",
        "edition": 652,
        "date": 1654567998741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "652",
        "rarity_score": 90.50708702140156,
        "rarity_level": "COMMON"
    },
    "653": {
        "name": "80's Arkade Bulls #653",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/653.png",
        "dna": "0f9c4289a30b29aaab30bc24cb2489d9fa0bb106",
        "edition": 653,
        "date": 1654567581250,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "653",
        "rarity_score": 173.07883187106714,
        "rarity_level": "COMMON"
    },
    "654": {
        "name": "80's Arkade Bulls #654",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/654.png",
        "dna": "401cd34c12b06f28b9cd7dbd6da3a8ec6111208c",
        "edition": 654,
        "date": 1654568145615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "654",
        "rarity_score": 205.1953898363986,
        "rarity_level": "RARE"
    },
    "655": {
        "name": "80's Arkade Bulls #655",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/655.png",
        "dna": "2320610a78e70e1eb246fa27fbcde7d822b49741",
        "edition": 655,
        "date": 1654568081164,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "655",
        "rarity_score": 77.72068830004599,
        "rarity_level": "COMMON"
    },
    "656": {
        "name": "80's Arkade Bulls #656",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/656.png",
        "dna": "da26068c052f5e4ec9ff7cab23f7453dd253b394",
        "edition": 656,
        "date": 1654568230686,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 1.7999999999999998,
        "id": "656",
        "rarity_score": 266.222188770134,
        "rarity_level": "RARE"
    },
    "657": {
        "name": "80's Arkade Bulls #657",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/657.png",
        "dna": "b2c1ffd3c3ace9d97f6b8414a818a33f2b3d4d4a",
        "edition": 657,
        "date": 1654567969513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "657",
        "rarity_score": 95.1785909027218,
        "rarity_level": "COMMON"
    },
    "658": {
        "name": "80's Arkade Bulls #658",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/658.png",
        "dna": "0195c0e1f8e8a69ca9777277fe789643f4a68d3e",
        "edition": 658,
        "date": 1654568163284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333334,
        "id": "658",
        "rarity_score": 137.08813561525753,
        "rarity_level": "COMMON"
    },
    "659": {
        "name": "80's Arkade Bulls #659",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/659.png",
        "dna": "0e1e5cd4c93f0c4c52c20c9331245b4587e3de32",
        "edition": 659,
        "date": 1654567448255,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "659",
        "rarity_score": 127.9056836448895,
        "rarity_level": "COMMON"
    },
    "660": {
        "name": "80's Arkade Bulls #660",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/660.png",
        "dna": "a4e5630a38d5720c84441f2b736cbb4773d124c1",
        "edition": 660,
        "date": 1654568183146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "660",
        "rarity_score": 130.82634470563997,
        "rarity_level": "COMMON"
    },
    "661": {
        "name": "80's Arkade Bulls #661",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/661.png",
        "dna": "6c8095a66739b465ff24432882df2a6296e6d016",
        "edition": 661,
        "date": 1654567687674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "661",
        "rarity_score": 201.47039029224055,
        "rarity_level": "COMMON"
    },
    "662": {
        "name": "80's Arkade Bulls #662",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/662.png",
        "dna": "4a22844eb58da12ad50de225d2ee2d0687b85a82",
        "edition": 662,
        "date": 1654567989455,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "662",
        "rarity_score": 96.589983688323,
        "rarity_level": "COMMON"
    },
    "663": {
        "name": "80's Arkade Bulls #663",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/663.png",
        "dna": "14cbb036a052f8f53e8e73eb8c70016d5b05329d",
        "edition": 663,
        "date": 1654567129511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "663",
        "rarity_score": 78.31779752411214,
        "rarity_level": "COMMON"
    },
    "664": {
        "name": "80's Arkade Bulls #664",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/664.png",
        "dna": "ab7f67001e93679c6c25dfccca8ff1416884bc82",
        "edition": 664,
        "date": 1654567497224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "664",
        "rarity_score": 182.53530920023852,
        "rarity_level": "COMMON"
    },
    "665": {
        "name": "80's Arkade Bulls #665",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/665.png",
        "dna": "9e0dba691a764a8e47cc32d5a8018829eee7946f",
        "edition": 665,
        "date": 1654567181614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "665",
        "rarity_score": 114.67956142710827,
        "rarity_level": "COMMON"
    },
    "666": {
        "name": "80's Arkade Bulls #666",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/666.png",
        "dna": "8038edb784c8a5196033f23ff281dca5df116c9f",
        "edition": 666,
        "date": 1654567147261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "666",
        "rarity_score": 83.69021953442842,
        "rarity_level": "COMMON"
    },
    "667": {
        "name": "80's Arkade Bulls #667",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/667.png",
        "dna": "d37bf3363f0742c87b463d520ef37b2803a51f19",
        "edition": 667,
        "date": 1654567708682,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "667",
        "rarity_score": 64.96332054149666,
        "rarity_level": "COMMON"
    },
    "668": {
        "name": "80's Arkade Bulls #668",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/668.png",
        "dna": "309517817f5f8de5da875c799aedc798769fd83a",
        "edition": 668,
        "date": 1654567944463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "668",
        "rarity_score": 255.16200988301978,
        "rarity_level": "RARE"
    },
    "669": {
        "name": "80's Arkade Bulls #669",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/669.png",
        "dna": "f5534c49dda3c6bde42d337bbb01c4912eb17297",
        "edition": 669,
        "date": 1654567340795,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.777777777777778,
        "id": "669",
        "rarity_score": 361.18403512252615,
        "rarity_level": "RARE"
    },
    "670": {
        "name": "80's Arkade Bulls #670",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/670.png",
        "dna": "2c12cf00d6dda6a518ae99c86e07f8fe3fed5af9",
        "edition": 670,
        "date": 1654568192665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "670",
        "rarity_score": 82.3787784679089,
        "rarity_level": "COMMON"
    },
    "671": {
        "name": "80's Arkade Bulls #671",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/671.png",
        "dna": "82cccb99d2582c699bb7dfa806db2a6dfc00d28a",
        "edition": 671,
        "date": 1654568095458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "671",
        "rarity_score": 80.40264865635498,
        "rarity_level": "COMMON"
    },
    "672": {
        "name": "80's Arkade Bulls #672",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/672.png",
        "dna": "59326447ec44c5089d912b49b76bae131663d047",
        "edition": 672,
        "date": 1654568169127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "672",
        "rarity_score": 146.6196853317798,
        "rarity_level": "COMMON"
    },
    "673": {
        "name": "80's Arkade Bulls #673",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/673.png",
        "dna": "2357a8fb1f281a4af9d37bedc545108416bb2d34",
        "edition": 673,
        "date": 1654567128557,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Super",
        "rarity": 2,
        "id": "673",
        "rarity_score": 248.22056829921155,
        "rarity_level": "RARE"
    },
    "674": {
        "name": "80's Arkade Bulls #674",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/674.png",
        "dna": "d98eb4977cec8938fad1005a945989a82175e6b2",
        "edition": 674,
        "date": 1654567693330,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "674",
        "rarity_score": 96.25298687037463,
        "rarity_level": "COMMON"
    },
    "675": {
        "name": "80's Arkade Bulls #675",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/675.png",
        "dna": "4064bc67eca955d11c6ae4d8d1afd42671a0faaf",
        "edition": 675,
        "date": 1654567502766,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "675",
        "rarity_score": 161.01195182627484,
        "rarity_level": "COMMON"
    },
    "676": {
        "name": "80's Arkade Bulls #676",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/676.png",
        "dna": "42c7075516d2e4036cde05dc694279c72af9a71a",
        "edition": 676,
        "date": 1654567582929,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "676",
        "rarity_score": 187.54603547209183,
        "rarity_level": "COMMON"
    },
    "677": {
        "name": "80's Arkade Bulls #677",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/677.png",
        "dna": "b48f376cc61f33c5fefe50931f5a426d54cef00f",
        "edition": 677,
        "date": 1654567658874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.2857142857142856,
        "id": "677",
        "rarity_score": 225.2175623017984,
        "rarity_level": "RARE"
    },
    "678": {
        "name": "80's Arkade Bulls #678",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/678.png",
        "dna": "b65f51e0b13dbf8cab614eacf89f02cad3df5f64",
        "edition": 678,
        "date": 1654567941664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "678",
        "rarity_score": 110.17294457844125,
        "rarity_level": "COMMON"
    },
    "679": {
        "name": "80's Arkade Bulls #679",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/679.png",
        "dna": "b4dd5c405c1ddbcee5fb37bae95f5d9494d89730",
        "edition": 679,
        "date": 1654567879554,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "679",
        "rarity_score": 108.46684148914868,
        "rarity_level": "COMMON"
    },
    "680": {
        "name": "80's Arkade Bulls #680",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/680.png",
        "dna": "ab1e171b9902ef39bed4ee28a70ed83ea7080dd1",
        "edition": 680,
        "date": 1654567175653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "680",
        "rarity_score": 101.58063255068521,
        "rarity_level": "COMMON"
    },
    "681": {
        "name": "80's Arkade Bulls #681",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/681.png",
        "dna": "dece65a82565f9021630e3814eacad35aa9db275",
        "edition": 681,
        "date": 1654568232640,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Super",
        "rarity": 3.4000000000000004,
        "id": "681",
        "rarity_score": 232.40665167475441,
        "rarity_level": "RARE"
    },
    "682": {
        "name": "80's Arkade Bulls #682",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/682.png",
        "dna": "e72502eba3dd6c156d0c1c66a2ac97d5c9d37efb",
        "edition": 682,
        "date": 1654567512547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "682",
        "rarity_score": 155.4020209968378,
        "rarity_level": "COMMON"
    },
    "683": {
        "name": "80's Arkade Bulls #683",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/683.png",
        "dna": "2e2dc154c685de04ade84e3298cb9c4a4b972a79",
        "edition": 683,
        "date": 1654567172825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "683",
        "rarity_score": 117.34053692841991,
        "rarity_level": "COMMON"
    },
    "684": {
        "name": "80's Arkade Bulls #684",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/684.png",
        "dna": "ec424e1c4313f943012390d25d2905bd7ba15dad",
        "edition": 684,
        "date": 1654568104698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "684",
        "rarity_score": 69.8747144599523,
        "rarity_level": "COMMON"
    },
    "685": {
        "name": "80's Arkade Bulls #685",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/685.png",
        "dna": "66a877570f64ad1232c34ad560ba547db3bf1cbb",
        "edition": 685,
        "date": 1654568109364,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "685",
        "rarity_score": 96.93958182564985,
        "rarity_level": "COMMON"
    },
    "686": {
        "name": "80's Arkade Bulls #686",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/686.png",
        "dna": "75c621bd951d4c4d8f670f08b51660637bfdb9de",
        "edition": 686,
        "date": 1654567178393,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "686",
        "rarity_score": 70.80928676694191,
        "rarity_level": "COMMON"
    },
    "687": {
        "name": "80's Arkade Bulls #687",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/687.png",
        "dna": "1b1db12fd3a6e4a765edb4d5f8ceff31bcc02b47",
        "edition": 687,
        "date": 1654568049864,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "687",
        "rarity_score": 96.64432161534953,
        "rarity_level": "COMMON"
    },
    "688": {
        "name": "80's Arkade Bulls #688",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/688.png",
        "dna": "c9bed6821b5eaa42a625749061e3324dbe0755e7",
        "edition": 688,
        "date": 1654567899247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "688",
        "rarity_score": 164.9731895731942,
        "rarity_level": "COMMON"
    },
    "689": {
        "name": "80's Arkade Bulls #689",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/689.png",
        "dna": "1c115196aec897643561b21681b0fa5ab36f0ba1",
        "edition": 689,
        "date": 1654567723124,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "689",
        "rarity_score": 73.62474319752714,
        "rarity_level": "COMMON"
    },
    "690": {
        "name": "80's Arkade Bulls #690",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/690.png",
        "dna": "ccbb99138b43343982e52ef523e7537cebefad1a",
        "edition": 690,
        "date": 1654567979049,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "690",
        "rarity_score": 125.44988102567001,
        "rarity_level": "COMMON"
    },
    "691": {
        "name": "80's Arkade Bulls #691",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/691.png",
        "dna": "9b0c54486731dd378a02f9a5ef472693fb6156e6",
        "edition": 691,
        "date": 1654567464010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "691",
        "rarity_score": 127.06379795864612,
        "rarity_level": "COMMON"
    },
    "692": {
        "name": "80's Arkade Bulls #692",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/692.png",
        "dna": "69ab6e1d0522b4c8fa43cf67dad57fa564157045",
        "edition": 692,
        "date": 1654567992921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "692",
        "rarity_score": 111.70025525318553,
        "rarity_level": "COMMON"
    },
    "693": {
        "name": "80's Arkade Bulls #693",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/693.png",
        "dna": "c6506568312237be821efaa756792c6e12c7df7b",
        "edition": 693,
        "date": 1654568100977,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "693",
        "rarity_score": 91.98333486508345,
        "rarity_level": "COMMON"
    },
    "694": {
        "name": "80's Arkade Bulls #694",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/694.png",
        "dna": "738782956035d7a7eeb057dc4e88df9fd447a7bc",
        "edition": 694,
        "date": 1654567674205,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "694",
        "rarity_score": 158.94747811109028,
        "rarity_level": "COMMON"
    },
    "695": {
        "name": "80's Arkade Bulls #695",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/695.png",
        "dna": "54a5d939faa2b9f50512cf5557f5356b340f5d0b",
        "edition": 695,
        "date": 1654567828579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "695",
        "rarity_score": 136.84597310912622,
        "rarity_level": "COMMON"
    },
    "696": {
        "name": "80's Arkade Bulls #696",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/696.png",
        "dna": "24c658ec0cfe065a398447fae7120e0fc0f7dd00",
        "edition": 696,
        "date": 1654567996867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "696",
        "rarity_score": 87.4062594797374,
        "rarity_level": "COMMON"
    },
    "697": {
        "name": "80's Arkade Bulls #697",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/697.png",
        "dna": "11c0fa81b97ee3a8be2eb020d2de3b816ead89c8",
        "edition": 697,
        "date": 1654567532529,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "697",
        "rarity_score": 166.90763363120618,
        "rarity_level": "COMMON"
    },
    "698": {
        "name": "80's Arkade Bulls #698",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/698.png",
        "dna": "4751cf9ce79a23b3c4d039e07d6ad763ffb2df03",
        "edition": 698,
        "date": 1654568195695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "698",
        "rarity_score": 124.0590313124713,
        "rarity_level": "COMMON"
    },
    "699": {
        "name": "80's Arkade Bulls #699",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/699.png",
        "dna": "d437475d192cce9f6d0a6d79ca030c8c81921a2c",
        "edition": 699,
        "date": 1654568175928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "699",
        "rarity_score": 174.89978529115038,
        "rarity_level": "COMMON"
    },
    "700": {
        "name": "80's Arkade Bulls #700",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/700.png",
        "dna": "95f3ba54727fc1d329098905b16926048afd12d7",
        "edition": 700,
        "date": 1654567487696,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "700",
        "rarity_score": 256.312092867847,
        "rarity_level": "RARE"
    },
    "701": {
        "name": "80's Arkade Bulls #701",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/701.png",
        "dna": "aefb8d4578d5db33f2c1f2455307a1180cafc96c",
        "edition": 701,
        "date": 1654567823445,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "701",
        "rarity_score": 73.00061746806742,
        "rarity_level": "COMMON"
    },
    "702": {
        "name": "80's Arkade Bulls #702",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/702.png",
        "dna": "568ef6766d7b9b73f08db27c194bc3a99600e15a",
        "edition": 702,
        "date": 1654567895589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4.285714285714286,
        "id": "702",
        "rarity_score": 204.93853904316683,
        "rarity_level": "RARE"
    },
    "703": {
        "name": "80's Arkade Bulls #703",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/703.png",
        "dna": "4cb5984849c71f4204c45833e1f65d45195fccb1",
        "edition": 703,
        "date": 1654567124751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "703",
        "rarity_score": 63.18985713337954,
        "rarity_level": "COMMON"
    },
    "704": {
        "name": "80's Arkade Bulls #704",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/704.png",
        "dna": "7c4fe4c05415d47947ae1e90b4016fedbc6ea9cc",
        "edition": 704,
        "date": 1654567593385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "704",
        "rarity_score": 197.2367952869625,
        "rarity_level": "COMMON"
    },
    "705": {
        "name": "80's Arkade Bulls #705",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/705.png",
        "dna": "6498d338a78ccc6ff3aae30904ecd348bee8eb0d",
        "edition": 705,
        "date": 1654567207353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.333333333333334,
        "id": "705",
        "rarity_score": 64.93530502845478,
        "rarity_level": "COMMON"
    },
    "706": {
        "name": "80's Arkade Bulls #706",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/706.png",
        "dna": "a45f9dbd2db6b3069ff2a4a6fe9312f4feee2e8f",
        "edition": 706,
        "date": 1654567196092,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "706",
        "rarity_score": 88.6298773690078,
        "rarity_level": "COMMON"
    },
    "707": {
        "name": "80's Arkade Bulls #707",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/707.png",
        "dna": "ea2765bd8bd1c48de09a2f8d633cf90bdf732f0b",
        "edition": 707,
        "date": 1654567611291,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.75,
        "id": "707",
        "rarity_score": 116.44988149991843,
        "rarity_level": "COMMON"
    },
    "708": {
        "name": "80's Arkade Bulls #708",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/708.png",
        "dna": "8d353a0f060576f40cd2042c5abacc71596ddd95",
        "edition": 708,
        "date": 1654567671297,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "708",
        "rarity_score": 162.05974584955933,
        "rarity_level": "COMMON"
    },
    "709": {
        "name": "80's Arkade Bulls #709",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/709.png",
        "dna": "23770a5b35ff5e243caeaf6486689d5d708ecbc4",
        "edition": 709,
        "date": 1654568084229,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "709",
        "rarity_score": 59.363896328610316,
        "rarity_level": "COMMON"
    },
    "710": {
        "name": "80's Arkade Bulls #710",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/710.png",
        "dna": "d7a6318ebc2779f31ff0eefe63121a5929d961e7",
        "edition": 710,
        "date": 1654567155901,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "710",
        "rarity_score": 80.78225099368717,
        "rarity_level": "COMMON"
    },
    "711": {
        "name": "80's Arkade Bulls #711",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/711.png",
        "dna": "c8837475c5eb1ad13131d91d8b5b26a0f2eac5d3",
        "edition": 711,
        "date": 1654567500582,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "711",
        "rarity_score": 143.25927170148242,
        "rarity_level": "COMMON"
    },
    "712": {
        "name": "80's Arkade Bulls #712",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/712.png",
        "dna": "b646bee15bd244417ba1a5ca827080b585be3760",
        "edition": 712,
        "date": 1654568007897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "712",
        "rarity_score": 73.08080330839104,
        "rarity_level": "COMMON"
    },
    "713": {
        "name": "80's Arkade Bulls #713",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/713.png",
        "dna": "c999059d676f6ddc6c80919f29b37dd5eae59dcd",
        "edition": 713,
        "date": 1654567116669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "713",
        "rarity_score": 106.28858398349924,
        "rarity_level": "COMMON"
    },
    "714": {
        "name": "80's Arkade Bulls #714",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/714.png",
        "dna": "44faeffd949cb2204549e58fb14df24839870412",
        "edition": 714,
        "date": 1654567580691,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "714",
        "rarity_score": 182.74045590376394,
        "rarity_level": "COMMON"
    },
    "715": {
        "name": "80's Arkade Bulls #715",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/715.png",
        "dna": "f3772bc97bda7424ad1e6d99dea5fb5222fdea7f",
        "edition": 715,
        "date": 1654568138233,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Super",
        "rarity": 3.2,
        "id": "715",
        "rarity_score": 212.61243386243387,
        "rarity_level": "RARE"
    },
    "716": {
        "name": "80's Arkade Bulls #716",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/716.png",
        "dna": "1b1d0fee712852cb2c756246a64bff8a1be098d6",
        "edition": 716,
        "date": 1654567117984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "716",
        "rarity_score": 99.02799159365979,
        "rarity_level": "COMMON"
    },
    "717": {
        "name": "80's Arkade Bulls #717",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/717.png",
        "dna": "7d61f168fe9bcfdb0461a03e42203e97948ae0d1",
        "edition": 717,
        "date": 1654568072546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "717",
        "rarity_score": 61.24546573936682,
        "rarity_level": "COMMON"
    },
    "718": {
        "name": "80's Arkade Bulls #718",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/718.png",
        "dna": "bb28698392a370b89fad801e28151ea2ddd6f9e7",
        "edition": 718,
        "date": 1654567823873,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "718",
        "rarity_score": 171.49528804460348,
        "rarity_level": "COMMON"
    },
    "719": {
        "name": "80's Arkade Bulls #719",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/719.png",
        "dna": "9a2bceec2585e65936a67325a2da7bc5f77ae1bd",
        "edition": 719,
        "date": 1654567259857,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "719",
        "rarity_score": 98.06773443246033,
        "rarity_level": "COMMON"
    },
    "720": {
        "name": "80's Arkade Bulls #720",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/720.png",
        "dna": "006a778d24e9f5cd8d7f018c03ef49f6eb0c64c6",
        "edition": 720,
        "date": 1654567279088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "720",
        "rarity_score": 173.88085395381802,
        "rarity_level": "COMMON"
    },
    "721": {
        "name": "80's Arkade Bulls #721",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/721.png",
        "dna": "1d3cf2c51001e482fc2ae6a2a638be51ab90ec0c",
        "edition": 721,
        "date": 1654567142155,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "721",
        "rarity_score": 89.06612736928075,
        "rarity_level": "COMMON"
    },
    "722": {
        "name": "80's Arkade Bulls #722",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/722.png",
        "dna": "92d867f7805a96a49268e6eac31e2b3afc7a8ded",
        "edition": 722,
        "date": 1654567187229,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "722",
        "rarity_score": 130.10228190355696,
        "rarity_level": "COMMON"
    },
    "723": {
        "name": "80's Arkade Bulls #723",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/723.png",
        "dna": "dda30fbbdb500c94351b9d4b133c354fd084e4e3",
        "edition": 723,
        "date": 1654567148189,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "723",
        "rarity_score": 88.28391229974042,
        "rarity_level": "COMMON"
    },
    "724": {
        "name": "80's Arkade Bulls #724",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/724.png",
        "dna": "2e7e5e5570af04be14153af47c22b987bf8f3ab8",
        "edition": 724,
        "date": 1654567113674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Invaders",
                "score": 1980.0
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "Mega Bull Mask", "score": 1980.0 }
        ],
        "rarity": 0,
        "rank": "Legendary",
        "id": "724",
        "rarity_score": 4043.856588758879,
        "rarity_level": "LEGENDARY"
    },
    "725": {
        "name": "80's Arkade Bulls #725",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/725.png",
        "dna": "2c7821410968a05f62a3af041fb4237f01686f88",
        "edition": 725,
        "date": 1654567864609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.714285714285714,
        "id": "725",
        "rarity_score": 76.59300180094581,
        "rarity_level": "COMMON"
    },
    "726": {
        "name": "80's Arkade Bulls #726",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/726.png",
        "dna": "45bebc81b2a81b91d7054821f1ea2767feb26eba",
        "edition": 726,
        "date": 1654568120861,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "726",
        "rarity_score": 212.5621542724991,
        "rarity_level": "RARE"
    },
    "727": {
        "name": "80's Arkade Bulls #727",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/727.png",
        "dna": "fc667fd2ddeb9f49ccb9ce2f8982937900d41cab",
        "edition": 727,
        "date": 1654568119491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Super",
        "rarity": 2.4000000000000004,
        "id": "727",
        "rarity_score": 213.87028657616892,
        "rarity_level": "RARE"
    },
    "728": {
        "name": "80's Arkade Bulls #728",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/728.png",
        "dna": "5e1afbf811ff78b35c5456fd686c3b8b7e1446de",
        "edition": 728,
        "date": 1654568103782,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "728",
        "rarity_score": 54.593618564176936,
        "rarity_level": "COMMON"
    },
    "729": {
        "name": "80's Arkade Bulls #729",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/729.png",
        "dna": "c140f246e4f7e436f4c81d9982ab868e268fe61b",
        "edition": 729,
        "date": 1654567174271,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "729",
        "rarity_score": 86.01673757514159,
        "rarity_level": "COMMON"
    },
    "730": {
        "name": "80's Arkade Bulls #730",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/730.png",
        "dna": "53b97a57506a79ed5eca34bf5d4202d5c1b94872",
        "edition": 730,
        "date": 1654567672430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "730",
        "rarity_score": 166.82229974529315,
        "rarity_level": "COMMON"
    },
    "731": {
        "name": "80's Arkade Bulls #731",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/731.png",
        "dna": "61bfc2eb46e3442ef40363b62b9b8e1432a17267",
        "edition": 731,
        "date": 1654567338098,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4.222222222222222,
        "id": "731",
        "rarity_score": 225.9397161050172,
        "rarity_level": "RARE"
    },
    "732": {
        "name": "80's Arkade Bulls #732",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/732.png",
        "dna": "216ad581024ef6f9edef2057e17834abd6a6250c",
        "edition": 732,
        "date": 1654567155441,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "732",
        "rarity_score": 65.23515330062693,
        "rarity_level": "COMMON"
    },
    "733": {
        "name": "80's Arkade Bulls #733",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/733.png",
        "dna": "7662b9c8b5c09d3604094b5825a9bcf6fc6f4302",
        "edition": 733,
        "date": 1654567283857,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "733",
        "rarity_score": 104.23414676308302,
        "rarity_level": "COMMON"
    },
    "734": {
        "name": "80's Arkade Bulls #734",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/734.png",
        "dna": "24a87203848dcc81034ad0f6354114da768c4f9e",
        "edition": 734,
        "date": 1654567693893,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857143,
        "id": "734",
        "rarity_score": 203.58370155954248,
        "rarity_level": "RARE"
    },
    "735": {
        "name": "80's Arkade Bulls #735",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/735.png",
        "dna": "7512642d1dba7437245d2b5570dacb7c9121d31d",
        "edition": 735,
        "date": 1654567195625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "735",
        "rarity_score": 61.28221796626366,
        "rarity_level": "COMMON"
    },
    "736": {
        "name": "80's Arkade Bulls #736",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/736.png",
        "dna": "64213726b3457ea054f9bdc402ee088cd4967864",
        "edition": 736,
        "date": 1654567438247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "736",
        "rarity_score": 94.86164583842026,
        "rarity_level": "COMMON"
    },
    "737": {
        "name": "80's Arkade Bulls #737",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/737.png",
        "dna": "7342788485f94e4483eaffa5e926fbdfda82522e",
        "edition": 737,
        "date": 1654568154170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "737",
        "rarity_score": 168.93371314627547,
        "rarity_level": "COMMON"
    },
    "738": {
        "name": "80's Arkade Bulls #738",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/738.png",
        "dna": "65cd18b1e1875ed5c6c7ae8d46c5c2fa945df9e8",
        "edition": 738,
        "date": 1654567329505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "738",
        "rarity_score": 331.7955844444381,
        "rarity_level": "RARE"
    },
    "739": {
        "name": "80's Arkade Bulls #739",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/739.png",
        "dna": "1d32f40f1b09d538dfd41799f8c315b9678b7803",
        "edition": 739,
        "date": 1654567220050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "739",
        "rarity_score": 220.72267594948275,
        "rarity_level": "RARE"
    },
    "740": {
        "name": "80's Arkade Bulls #740",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/740.png",
        "dna": "fee0e69db866eea166781060f89b1737420181ca",
        "edition": 740,
        "date": 1654567392082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "740",
        "rarity_score": 268.0717509265672,
        "rarity_level": "RARE"
    },
    "741": {
        "name": "80's Arkade Bulls #741",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/741.png",
        "dna": "24381638558a6203b766d30015a43385682147c0",
        "edition": 741,
        "date": 1654567414616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "741",
        "rarity_score": 297.43485882195563,
        "rarity_level": "RARE"
    },
    "742": {
        "name": "80's Arkade Bulls #742",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/742.png",
        "dna": "cd2b0159e96fcc59e60a2cd01d63b011e61fa9d5",
        "edition": 742,
        "date": 1654567310946,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.555555555555555,
        "id": "742",
        "rarity_score": 214.39376543408855,
        "rarity_level": "RARE"
    },
    "743": {
        "name": "80's Arkade Bulls #743",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/743.png",
        "dna": "89dfe767fd74f59375e0e498a389fbcf837983a1",
        "edition": 743,
        "date": 1654567158686,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "743",
        "rarity_score": 58.798217130965156,
        "rarity_level": "COMMON"
    },
    "744": {
        "name": "80's Arkade Bulls #744",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/744.png",
        "dna": "463e227c81a1c901cda55d91777ea0b52cc24cde",
        "edition": 744,
        "date": 1654567865648,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "744",
        "rarity_score": 83.21586287712897,
        "rarity_level": "COMMON"
    },
    "745": {
        "name": "80's Arkade Bulls #745",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/745.png",
        "dna": "08d103d5205a7ea32b9248d8ddae951633a6e0f7",
        "edition": 745,
        "date": 1654567857146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rarity": 6.428571428571429,
        "rank": "Common",
        "id": "745",
        "rarity_score": 126.70101028088132,
        "rarity_level": "COMMON"
    },
    "746": {
        "name": "80's Arkade Bulls #746",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/746.png",
        "dna": "be7d6c2d43daeab88fcb3504a75c9d6c7fe55f6b",
        "edition": 746,
        "date": 1654567314931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 4.555555555555555,
        "id": "746",
        "rarity_score": 239.88799826077354,
        "rarity_level": "RARE"
    },
    "747": {
        "name": "80's Arkade Bulls #747",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/747.png",
        "dna": "63a43cc5cf757a652afe0e37c26b2ead9e2ae3b1",
        "edition": 747,
        "date": 1654568020549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "747",
        "rarity_score": 121.56162829724023,
        "rarity_level": "COMMON"
    },
    "748": {
        "name": "80's Arkade Bulls #748",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/748.png",
        "dna": "ba4d82857f2c06d5a4cfc5f66c6cfb5cb46130e9",
        "edition": 748,
        "date": 1654567561120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "748",
        "rarity_score": 168.52779527035474,
        "rarity_level": "COMMON"
    },
    "749": {
        "name": "80's Arkade Bulls #749",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/749.png",
        "dna": "2a5a01d0430ae07e0fcf470225af855c29f769f4",
        "edition": 749,
        "date": 1654567347896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 4.888888888888889,
        "id": "749",
        "rarity_score": 231.6138333633764,
        "rarity_level": "RARE"
    },
    "750": {
        "name": "80's Arkade Bulls #750",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/750.png",
        "dna": "9749abf2d284821f602821a4da3a9113d84d67ff",
        "edition": 750,
        "date": 1654567582357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "750",
        "rarity_score": 163.50557476182627,
        "rarity_level": "COMMON"
    },
    "751": {
        "name": "80's Arkade Bulls #751",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/751.png",
        "dna": "578fa5d2c3182e837d07bffb4d7fd881a8d06ca7",
        "edition": 751,
        "date": 1654567629736,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.625,
        "id": "751",
        "rarity_score": 176.49070818347843,
        "rarity_level": "COMMON"
    },
    "752": {
        "name": "80's Arkade Bulls #752",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/752.png",
        "dna": "36732a6efb3d85466059ce5dca8e558746ebd244",
        "edition": 752,
        "date": 1654567657651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "752",
        "rarity_score": 153.21741728964403,
        "rarity_level": "COMMON"
    },
    "753": {
        "name": "80's Arkade Bulls #753",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/753.png",
        "dna": "7e504749e586eeabdd94d93b73ff0928859cbb5c",
        "edition": 753,
        "date": 1654567597383,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "753",
        "rarity_score": 199.05994397759105,
        "rarity_level": "COMMON"
    },
    "754": {
        "name": "80's Arkade Bulls #754",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/754.png",
        "dna": "77083dcf6b2d394afc2f972028e44938d0c26b57",
        "edition": 754,
        "date": 1654567712214,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.333333333333334,
        "id": "754",
        "rarity_score": 67.2467957570931,
        "rarity_level": "COMMON"
    },
    "755": {
        "name": "80's Arkade Bulls #755",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/755.png",
        "dna": "95edf496eabd3683a2cd2915e039a3aaca1de1f8",
        "edition": 755,
        "date": 1654567271870,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "755",
        "rarity_score": 124.28898245735382,
        "rarity_level": "COMMON"
    },
    "756": {
        "name": "80's Arkade Bulls #756",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/756.png",
        "dna": "013dbb69741dca41b114b869a5b86b00cefae1ec",
        "edition": 756,
        "date": 1654568114077,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9.8,
        "id": "756",
        "rarity_score": 54.43776593074506,
        "rarity_level": "COMMON"
    },
    "757": {
        "name": "80's Arkade Bulls #757",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/757.png",
        "dna": "06e2c8cc23aaa67eaeb9e504942b917522528539",
        "edition": 757,
        "date": 1654568024601,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "757",
        "rarity_score": 160.70314167114137,
        "rarity_level": "COMMON"
    },
    "758": {
        "name": "80's Arkade Bulls #758",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/758.png",
        "dna": "2ff6b72b85de12ee68b2b512eaacf85c9ba54820",
        "edition": 758,
        "date": 1654567904052,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "758",
        "rarity_score": 118.26035855269951,
        "rarity_level": "COMMON"
    },
    "759": {
        "name": "80's Arkade Bulls #759",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/759.png",
        "dna": "a9ddfcaa5080376e6b65f8597fa98e9b64e5f07e",
        "edition": 759,
        "date": 1654567915801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rarity": 5.571428571428571,
        "rank": "Rare",
        "id": "759",
        "rarity_score": 168.7327422302089,
        "rarity_level": "COMMON"
    },
    "760": {
        "name": "80's Arkade Bulls #760",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/760.png",
        "dna": "c7f23d18057016484048067af6ee408a24343d92",
        "edition": 760,
        "date": 1654568014496,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "760",
        "rarity_score": 124.9461605439508,
        "rarity_level": "COMMON"
    },
    "761": {
        "name": "80's Arkade Bulls #761",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/761.png",
        "dna": "d055cc3a3ec254957728a1185f9fa33702755e24",
        "edition": 761,
        "date": 1654568032049,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "761",
        "rarity_score": 64.29274481223936,
        "rarity_level": "COMMON"
    },
    "762": {
        "name": "80's Arkade Bulls #762",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/762.png",
        "dna": "b7de7233ce21b5a07fb30f08b421bc8db52e4a1e",
        "edition": 762,
        "date": 1654567606403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.625,
        "id": "762",
        "rarity_score": 161.22159138512814,
        "rarity_level": "COMMON"
    },
    "763": {
        "name": "80's Arkade Bulls #763",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/763.png",
        "dna": "98efdaac2c760a0cfb42dac7fbcaa760f5dec81f",
        "edition": 763,
        "date": 1654567224917,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "763",
        "rarity_score": 77.66221040455136,
        "rarity_level": "COMMON"
    },
    "764": {
        "name": "80's Arkade Bulls #764",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/764.png",
        "dna": "e97e01a0534f7363c291c32f9e2eaf612612904c",
        "edition": 764,
        "date": 1654567201262,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "764",
        "rarity_score": 78.74041122602415,
        "rarity_level": "COMMON"
    },
    "765": {
        "name": "80's Arkade Bulls #765",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/765.png",
        "dna": "eb0c741b34f68aef6a7cc9ce2c0ef6a30e1ef250",
        "edition": 765,
        "date": 1654567579641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "765",
        "rarity_score": 147.6350884912837,
        "rarity_level": "COMMON"
    },
    "766": {
        "name": "80's Arkade Bulls #766",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/766.png",
        "dna": "7c9a538dc875dc34671a76eb51533425b282c9de",
        "edition": 766,
        "date": 1654567647484,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.75,
        "id": "766",
        "rarity_score": 170.9588627549249,
        "rarity_level": "COMMON"
    },
    "767": {
        "name": "80's Arkade Bulls #767",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/767.png",
        "dna": "72fe13f764b4ffc78b69e26e34f48dee90ee0cf2",
        "edition": 767,
        "date": 1654567847664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "767",
        "rarity_score": 165.09725173072331,
        "rarity_level": "COMMON"
    },
    "768": {
        "name": "80's Arkade Bulls #768",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/768.png",
        "dna": "9d035e121c000dc04531fabdbcdc67d1a220dfd6",
        "edition": 768,
        "date": 1654567473546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "768",
        "rarity_score": 98.66005140917919,
        "rarity_level": "COMMON"
    },
    "769": {
        "name": "80's Arkade Bulls #769",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/769.png",
        "dna": "ed35c78363975ca6285855614a085b29c8925952",
        "edition": 769,
        "date": 1654567390411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "769",
        "rarity_score": 192.84704166796843,
        "rarity_level": "COMMON"
    },
    "770": {
        "name": "80's Arkade Bulls #770",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/770.png",
        "dna": "c1e3c34910a1eed5efe0648a76b7817f9929c822",
        "edition": 770,
        "date": 1654567609480,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "770",
        "rarity_score": 113.13800814622594,
        "rarity_level": "COMMON"
    },
    "771": {
        "name": "80's Arkade Bulls #771",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/771.png",
        "dna": "344ac4c8f41c17295c7b6c410e591290de500a3e",
        "edition": 771,
        "date": 1654567821178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "771",
        "rarity_score": 73.29891277565329,
        "rarity_level": "COMMON"
    },
    "772": {
        "name": "80's Arkade Bulls #772",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/772.png",
        "dna": "dc43ae9725545fa2591a16fd428a94b706f09165",
        "edition": 772,
        "date": 1654567201740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "772",
        "rarity_score": 76.44752548215868,
        "rarity_level": "COMMON"
    },
    "773": {
        "name": "80's Arkade Bulls #773",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/773.png",
        "dna": "88a4c6d5e087a73e77d87a507d2106d0ca420d4d",
        "edition": 773,
        "date": 1654567907855,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857142,
        "id": "773",
        "rarity_score": 129.54130780336178,
        "rarity_level": "COMMON"
    },
    "774": {
        "name": "80's Arkade Bulls #774",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/774.png",
        "dna": "9a294bb9584bda2e8c31a348340e2ca3f761f54a",
        "edition": 774,
        "date": 1654567848158,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "774",
        "rarity_score": 98.36896774565847,
        "rarity_level": "COMMON"
    },
    "775": {
        "name": "80's Arkade Bulls #775",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/775.png",
        "dna": "a2925ccec928e9b51184079655c4b95e48041156",
        "edition": 775,
        "date": 1654567233953,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "775",
        "rarity_score": 79.82620335562108,
        "rarity_level": "COMMON"
    },
    "776": {
        "name": "80's Arkade Bulls #776",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/776.png",
        "dna": "726d380a7330e1d0ed115e90dad95844c06fc0e8",
        "edition": 776,
        "date": 1654567610059,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.875,
        "id": "776",
        "rarity_score": 166.99673082108555,
        "rarity_level": "COMMON"
    },
    "777": {
        "name": "80's Arkade Bulls #777",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/777.png",
        "dna": "496ba04183591af95768c3bb20f8266d5ff5931b",
        "edition": 777,
        "date": 1654568160710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "777",
        "rarity_score": 158.6011590843234,
        "rarity_level": "COMMON"
    },
    "778": {
        "name": "80's Arkade Bulls #778",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/778.png",
        "dna": "c36f8d044e58765e7c517d5aea954f3acd5a8109",
        "edition": 778,
        "date": 1654567368294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 4.777777777777778,
        "id": "778",
        "rarity_score": 182.69636510865328,
        "rarity_level": "COMMON"
    },
    "779": {
        "name": "80's Arkade Bulls #779",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/779.png",
        "dna": "1952a94d6c4704de0ecceab657c4c68ced02fa23",
        "edition": 779,
        "date": 1654568193178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "779",
        "rarity_score": 206.55637537753648,
        "rarity_level": "RARE"
    },
    "780": {
        "name": "80's Arkade Bulls #780",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/780.png",
        "dna": "824479a81b10c3587f600999c6a3abd0642ec141",
        "edition": 780,
        "date": 1654567695056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "780",
        "rarity_score": 184.80432922757035,
        "rarity_level": "COMMON"
    },
    "781": {
        "name": "80's Arkade Bulls #781",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/781.png",
        "dna": "c111a8236d6cf99e95aafcad77affba1adae3efb",
        "edition": 781,
        "date": 1654567650527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.875,
        "id": "781",
        "rarity_score": 116.95601858278063,
        "rarity_level": "COMMON"
    },
    "782": {
        "name": "80's Arkade Bulls #782",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/782.png",
        "dna": "370b288f5fb28994a4b298e4ba0c1b88ded1570b",
        "edition": 782,
        "date": 1654568134040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "782",
        "rarity_score": 106.27643998678482,
        "rarity_level": "COMMON"
    },
    "783": {
        "name": "80's Arkade Bulls #783",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/783.png",
        "dna": "52963905c0f4c73de6ba5145027318097e589ed5",
        "edition": 783,
        "date": 1654567534748,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "783",
        "rarity_score": 130.07940392298545,
        "rarity_level": "COMMON"
    },
    "784": {
        "name": "80's Arkade Bulls #784",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/784.png",
        "dna": "0336a71cc975b4093cbc7516d3b5cd736558ed5a",
        "edition": 784,
        "date": 1654567384513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "784",
        "rarity_score": 159.4655922947407,
        "rarity_level": "COMMON"
    },
    "785": {
        "name": "80's Arkade Bulls #785",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/785.png",
        "dna": "03fd518f8073c92789b411a38084939c33102437",
        "edition": 785,
        "date": 1654567565022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "785",
        "rarity_score": 168.383967859721,
        "rarity_level": "COMMON"
    },
    "786": {
        "name": "80's Arkade Bulls #786",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/786.png",
        "dna": "3771549c60ad8fd42dcfbc93cbe7c5436400eb10",
        "edition": 786,
        "date": 1654567843100,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333334,
        "id": "786",
        "rarity_score": 99.6213436982413,
        "rarity_level": "COMMON"
    },
    "787": {
        "name": "80's Arkade Bulls #787",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/787.png",
        "dna": "f857cd11f33a931b4c7acb51bd75aa26c63a36bf",
        "edition": 787,
        "date": 1654567235489,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "787",
        "rarity_score": 76.87779243544614,
        "rarity_level": "COMMON"
    },
    "788": {
        "name": "80's Arkade Bulls #788",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/788.png",
        "dna": "54d46461d5363d8aa02de37cd1c8af0bfb6cf6fa",
        "edition": 788,
        "date": 1654568107955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "788",
        "rarity_score": 65.0756230757814,
        "rarity_level": "COMMON"
    },
    "789": {
        "name": "80's Arkade Bulls #789",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/789.png",
        "dna": "89ad04fc9361d1adc604695e0dd34fa623cb4d27",
        "edition": 789,
        "date": 1654567243327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "789",
        "rarity_score": 74.18491652287663,
        "rarity_level": "COMMON"
    },
    "790": {
        "name": "80's Arkade Bulls #790",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/790.png",
        "dna": "71bf6c9620e016cf0d0d169f6a345ab939c87c3a",
        "edition": 790,
        "date": 1654567223206,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666666,
        "id": "790",
        "rarity_score": 166.78980947369533,
        "rarity_level": "COMMON"
    },
    "791": {
        "name": "80's Arkade Bulls #791",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/791.png",
        "dna": "2c09eb8ba27308e175ac2e032da6025c03227b14",
        "edition": 791,
        "date": 1654567366268,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "791",
        "rarity_score": 160.81385458149398,
        "rarity_level": "COMMON"
    },
    "792": {
        "name": "80's Arkade Bulls #792",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/792.png",
        "dna": "55382df3ea7c7ea555a989457d6a06dece021f6c",
        "edition": 792,
        "date": 1654567829863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "792",
        "rarity_score": 82.27046310703362,
        "rarity_level": "COMMON"
    },
    "793": {
        "name": "80's Arkade Bulls #793",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/793.png",
        "dna": "f126f00c1560f492745bb99255a5a37f2922a5c3",
        "edition": 793,
        "date": 1654568062269,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "793",
        "rarity_score": 98.88345953593746,
        "rarity_level": "COMMON"
    },
    "794": {
        "name": "80's Arkade Bulls #794",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/794.png",
        "dna": "f0b934b6a3d6af5a30e0872a61fa93b9494d8f57",
        "edition": 794,
        "date": 1654567305960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "794",
        "rarity_score": 99.91544012664411,
        "rarity_level": "COMMON"
    },
    "795": {
        "name": "80's Arkade Bulls #795",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/795.png",
        "dna": "088d190cb046f835f98b512e8bda849e54411672",
        "edition": 795,
        "date": 1654567400992,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "795",
        "rarity_score": 178.57979093096318,
        "rarity_level": "COMMON"
    },
    "796": {
        "name": "80's Arkade Bulls #796",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/796.png",
        "dna": "96442359f38784f5c955d76d38f670025c10f942",
        "edition": 796,
        "date": 1654568222131,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 1.4,
        "id": "796",
        "rarity_score": 334.2414763115412,
        "rarity_level": "RARE"
    },
    "797": {
        "name": "80's Arkade Bulls #797",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/797.png",
        "dna": "bb41ae6b852fe61aeb264f91b7bf61f506efc17d",
        "edition": 797,
        "date": 1654567886415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "797",
        "rarity_score": 156.10333656400243,
        "rarity_level": "COMMON"
    },
    "798": {
        "name": "80's Arkade Bulls #798",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/798.png",
        "dna": "1c15558bb6c57549f532b4c4f1b3a1cf9a323147",
        "edition": 798,
        "date": 1654567374618,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "798",
        "rarity_score": 192.56148901768273,
        "rarity_level": "COMMON"
    },
    "799": {
        "name": "80's Arkade Bulls #799",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/799.png",
        "dna": "bc06b29077f0ad6ac96a85b9d5b42ee19bbff4c9",
        "edition": 799,
        "date": 1654567214088,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "799",
        "rarity_score": 128.68653832811188,
        "rarity_level": "COMMON"
    },
    "800": {
        "name": "80's Arkade Bulls #800",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/800.png",
        "dna": "b4208b4a8e614b82b4a4987f1f1742675ab54515",
        "edition": 800,
        "date": 1654568194698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "800",
        "rarity_score": 229.02020284352363,
        "rarity_level": "RARE"
    },
    "801": {
        "name": "80's Arkade Bulls #801",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/801.png",
        "dna": "7ce2b2dc71eeb45b06cee359325ea4e8f776ca75",
        "edition": 801,
        "date": 1654567356396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Super",
        "rarity": 3.2222222222222223,
        "id": "801",
        "rarity_score": 328.421926416101,
        "rarity_level": "RARE"
    },
    "802": {
        "name": "80's Arkade Bulls #802",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/802.png",
        "dna": "b59c6564bd46317a0ab0e8b39a91178fbcbdaacb",
        "edition": 802,
        "date": 1654567213092,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "802",
        "rarity_score": 59.91617542539403,
        "rarity_level": "COMMON"
    },
    "803": {
        "name": "80's Arkade Bulls #803",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/803.png",
        "dna": "91f5ea27794bfdc457e3cc23d797a4ff8b2b68cc",
        "edition": 803,
        "date": 1654568108894,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "803",
        "rarity_score": 58.96144623538046,
        "rarity_level": "COMMON"
    },
    "804": {
        "name": "80's Arkade Bulls #804",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/804.png",
        "dna": "ca2169845fbe69387464c3f96c8ce4f95059f74b",
        "edition": 804,
        "date": 1654567621010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rarity": 7.25,
        "rank": "Common",
        "id": "804",
        "rarity_score": 121.82083123722644,
        "rarity_level": "COMMON"
    },
    "805": {
        "name": "80's Arkade Bulls #805",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/805.png",
        "dna": "186954313f244ed7734dcfb676d3206fe8ec8983",
        "edition": 805,
        "date": 1654568042080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "805",
        "rarity_score": 93.36067595682447,
        "rarity_level": "COMMON"
    },
    "806": {
        "name": "80's Arkade Bulls #806",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/806.png",
        "dna": "4e1f56b24893f4e97e5e3018478ba271050bcf53",
        "edition": 806,
        "date": 1654567567246,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857143,
        "id": "806",
        "rarity_score": 231.2556696858227,
        "rarity_level": "RARE"
    },
    "807": {
        "name": "80's Arkade Bulls #807",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/807.png",
        "dna": "6a1782c7b85c9ff73392c3ef41a0b0063d44857d",
        "edition": 807,
        "date": 1654567666152,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "807",
        "rarity_score": 116.64069195081848,
        "rarity_level": "COMMON"
    },
    "808": {
        "name": "80's Arkade Bulls #808",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/808.png",
        "dna": "48f729eccdb650d1d6116314c6e1f28b23dfed87",
        "edition": 808,
        "date": 1654567545718,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "808",
        "rarity_score": 161.9138287311432,
        "rarity_level": "COMMON"
    },
    "809": {
        "name": "80's Arkade Bulls #809",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/809.png",
        "dna": "8100e069972f6598468008d0573e0c73be816632",
        "edition": 809,
        "date": 1654567835010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "809",
        "rarity_score": 115.2654589267531,
        "rarity_level": "COMMON"
    },
    "810": {
        "name": "80's Arkade Bulls #810",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/810.png",
        "dna": "c6e3d338d76df01a36730d0cbdeb21752c170361",
        "edition": 810,
        "date": 1654568019069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rarity": 7.333333333333333,
        "rank": "Common",
        "id": "810",
        "rarity_score": 112.62482211843192,
        "rarity_level": "COMMON"
    },
    "811": {
        "name": "80's Arkade Bulls #811",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/811.png",
        "dna": "f6d02374f7a20b1585703af21b89b42f822264fd",
        "edition": 811,
        "date": 1654567718134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "811",
        "rarity_score": 79.15955240134768,
        "rarity_level": "COMMON"
    },
    "812": {
        "name": "80's Arkade Bulls #812",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/812.png",
        "dna": "c6599ea1a69486001ac615561e94163822959c6f",
        "edition": 812,
        "date": 1654568194212,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "812",
        "rarity_score": 121.09940593501885,
        "rarity_level": "COMMON"
    },
    "813": {
        "name": "80's Arkade Bulls #813",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/813.png",
        "dna": "7962b9a54affadd2f04ca51874d76521b3748c51",
        "edition": 813,
        "date": 1654567564454,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.571428571428571,
        "id": "813",
        "rarity_score": 180.08034983317617,
        "rarity_level": "COMMON"
    },
    "814": {
        "name": "80's Arkade Bulls #814",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/814.png",
        "dna": "a316ac25e0fb3c37ca021155ec2ef29e7d542e66",
        "edition": 814,
        "date": 1654567514947,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 3.7142857142857135,
        "id": "814",
        "rarity_score": 184.38081193345022,
        "rarity_level": "COMMON"
    },
    "815": {
        "name": "80's Arkade Bulls #815",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/815.png",
        "dna": "c36ac5d9172e53095e6078f281636fe3988f337c",
        "edition": 815,
        "date": 1654567626557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.75,
        "id": "815",
        "rarity_score": 113.31784547985089,
        "rarity_level": "COMMON"
    },
    "816": {
        "name": "80's Arkade Bulls #816",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/816.png",
        "dna": "c7fc3d9495da8fc87fb2d84ecbb5421e60589bed",
        "edition": 816,
        "date": 1654568210200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "816",
        "rarity_score": 110.55631183631775,
        "rarity_level": "COMMON"
    },
    "817": {
        "name": "80's Arkade Bulls #817",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/817.png",
        "dna": "856b9b3f7bcc2be32fe6f65fec5c2e9997448e44",
        "edition": 817,
        "date": 1654568159264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "817",
        "rarity_score": 150.27795676414647,
        "rarity_level": "COMMON"
    },
    "818": {
        "name": "80's Arkade Bulls #818",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/818.png",
        "dna": "9d7d60e30043568e9e20fb1bdc974ec56d636a08",
        "edition": 818,
        "date": 1654567919070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "818",
        "rarity_score": 97.60706658592642,
        "rarity_level": "COMMON"
    },
    "819": {
        "name": "80's Arkade Bulls #819",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/819.png",
        "dna": "4eaf092489b3e3e49413aceaec0178e7aed312f5",
        "edition": 819,
        "date": 1654567847171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "819",
        "rarity_score": 90.34256428790269,
        "rarity_level": "COMMON"
    },
    "820": {
        "name": "80's Arkade Bulls #820",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/820.png",
        "dna": "67e54ad84353a1f68724a536597c98999645d2fa",
        "edition": 820,
        "date": 1654567653309,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "820",
        "rarity_score": 182.19239631336404,
        "rarity_level": "COMMON"
    },
    "821": {
        "name": "80's Arkade Bulls #821",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/821.png",
        "dna": "03b8fe9978e489a39a5685607640a32603122c3c",
        "edition": 821,
        "date": 1654567241749,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "821",
        "rarity_score": 81.85926091349077,
        "rarity_level": "COMMON"
    },
    "822": {
        "name": "80's Arkade Bulls #822",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/822.png",
        "dna": "11a85bc35d917f963e924f6cb8b535a5fa722c8f",
        "edition": 822,
        "date": 1654567556960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "822",
        "rarity_score": 162.38681579612043,
        "rarity_level": "COMMON"
    },
    "823": {
        "name": "80's Arkade Bulls #823",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/823.png",
        "dna": "fe4ead5520f4358dc74dc8a188c84e80f44673aa",
        "edition": 823,
        "date": 1654567471268,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "823",
        "rarity_score": 109.29623163301362,
        "rarity_level": "COMMON"
    },
    "824": {
        "name": "80's Arkade Bulls #824",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/824.png",
        "dna": "50ae513bb96c4461bc867fbe3f44366501aabb22",
        "edition": 824,
        "date": 1654567551753,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.1428571428571423,
        "id": "824",
        "rarity_score": 206.85537895224508,
        "rarity_level": "RARE"
    },
    "825": {
        "name": "80's Arkade Bulls #825",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/825.png",
        "dna": "7108aad86e19a0fe0526d96a7006c3034fe66b60",
        "edition": 825,
        "date": 1654567327491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.444444444444445,
        "id": "825",
        "rarity_score": 267.0742527983336,
        "rarity_level": "RARE"
    },
    "826": {
        "name": "80's Arkade Bulls #826",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/826.png",
        "dna": "eb147942ed626ef654e2f5c23f583cd306f387b1",
        "edition": 826,
        "date": 1654567256696,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "826",
        "rarity_score": 92.20101749507067,
        "rarity_level": "COMMON"
    },
    "827": {
        "name": "80's Arkade Bulls #827",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/827.png",
        "dna": "76348b80f6d50ce0054a57e7a82770781186ee21",
        "edition": 827,
        "date": 1654568201451,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "827",
        "rarity_score": 217.87462712942676,
        "rarity_level": "RARE"
    },
    "828": {
        "name": "80's Arkade Bulls #828",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/828.png",
        "dna": "7492e5806a055966dfb6ca7646d909d3314f1720",
        "edition": 828,
        "date": 1654567300841,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "828",
        "rarity_score": 163.96110580104406,
        "rarity_level": "COMMON"
    },
    "829": {
        "name": "80's Arkade Bulls #829",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/829.png",
        "dna": "d795257d95c54b9a83bb1e3cf05efe3b6997130b",
        "edition": 829,
        "date": 1654567125676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "829",
        "rarity_score": 61.311508613210314,
        "rarity_level": "COMMON"
    },
    "830": {
        "name": "80's Arkade Bulls #830",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/830.png",
        "dna": "5462e7789c14a2ab57964cd52b26804f2a8990d9",
        "edition": 830,
        "date": 1654567140331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "830",
        "rarity_score": 52.62544256665129,
        "rarity_level": "COMMON"
    },
    "831": {
        "name": "80's Arkade Bulls #831",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/831.png",
        "dna": "bf87a97f08ffdf3c421340d2fd7d8fd3beefe597",
        "edition": 831,
        "date": 1654568179538,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "831",
        "rarity_score": 95.97930684945413,
        "rarity_level": "COMMON"
    },
    "832": {
        "name": "80's Arkade Bulls #832",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/832.png",
        "dna": "fca28c5028be655aa5f4c3984bf13665e9e5fcdd",
        "edition": 832,
        "date": 1654567597893,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "832",
        "rarity_score": 197.03530731031472,
        "rarity_level": "COMMON"
    },
    "833": {
        "name": "80's Arkade Bulls #833",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/833.png",
        "dna": "f68fc510d67edcba84e047ba266072195570d4f5",
        "edition": 833,
        "date": 1654567589356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "833",
        "rarity_score": 212.86307352640335,
        "rarity_level": "RARE"
    },
    "834": {
        "name": "80's Arkade Bulls #834",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/834.png",
        "dna": "3f780e41345f0f53b8e629c3061b80ade7accc48",
        "edition": 834,
        "date": 1654567228710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333334,
        "id": "834",
        "rarity_score": 151.79592803787227,
        "rarity_level": "COMMON"
    },
    "835": {
        "name": "80's Arkade Bulls #835",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/835.png",
        "dna": "3f8b02e4b0dc47b429c8510ddbdc65f630ce2dd8",
        "edition": 835,
        "date": 1654568134965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "835",
        "rarity_score": 62.240740252843764,
        "rarity_level": "COMMON"
    },
    "836": {
        "name": "80's Arkade Bulls #836",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/836.png",
        "dna": "08c218eda0627c197a2a7877b7772d32002e2e62",
        "edition": 836,
        "date": 1654567144028,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "836",
        "rarity_score": 66.94780147762958,
        "rarity_level": "COMMON"
    },
    "837": {
        "name": "80's Arkade Bulls #837",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/837.png",
        "dna": "a8f646b66709950add99c567837a95a8ecfd45c1",
        "edition": 837,
        "date": 1654567247557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "837",
        "rarity_score": 77.54089628242626,
        "rarity_level": "COMMON"
    },
    "838": {
        "name": "80's Arkade Bulls #838",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/838.png",
        "dna": "e93ebe82243ceaea7d92c35f2d7c4964291cdbc9",
        "edition": 838,
        "date": 1654567462349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rarity": 5.142857142857142,
        "rank": "Rare",
        "id": "838",
        "rarity_score": 208.14569450038115,
        "rarity_level": "RARE"
    },
    "839": {
        "name": "80's Arkade Bulls #839",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/839.png",
        "dna": "95858e76f2495dc5621862a202ca4f8aba823240",
        "edition": 839,
        "date": 1654567260363,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "839",
        "rarity_score": 99.27785307671456,
        "rarity_level": "COMMON"
    },
    "840": {
        "name": "80's Arkade Bulls #840",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/840.png",
        "dna": "152cf9b59b5395be4aa51ab9562b94c62251d493",
        "edition": 840,
        "date": 1654567885347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "840",
        "rarity_score": 89.52384955596678,
        "rarity_level": "COMMON"
    },
    "841": {
        "name": "80's Arkade Bulls #841",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/841.png",
        "dna": "ef00e8906c7eccc72542c613a1d5956d7bb082b5",
        "edition": 841,
        "date": 1654567943778,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "841",
        "rarity_score": 121.6551346593944,
        "rarity_level": "COMMON"
    },
    "842": {
        "name": "80's Arkade Bulls #842",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/842.png",
        "dna": "8b3fe04e2f8104e25df97804ce8280de82a73467",
        "edition": 842,
        "date": 1654567220582,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "842",
        "rarity_score": 110.48478387971711,
        "rarity_level": "COMMON"
    },
    "843": {
        "name": "80's Arkade Bulls #843",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/843.png",
        "dna": "8fcbb4799583236f3e49536a05d00d62731c0aa4",
        "edition": 843,
        "date": 1654568124681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "843",
        "rarity_score": 84.23444963915435,
        "rarity_level": "COMMON"
    },
    "844": {
        "name": "80's Arkade Bulls #844",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/844.png",
        "dna": "7278f629636f2cb11d66c3d8cbae216767957833",
        "edition": 844,
        "date": 1654568127052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "844",
        "rarity_score": 76.27175557819629,
        "rarity_level": "COMMON"
    },
    "845": {
        "name": "80's Arkade Bulls #845",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/845.png",
        "dna": "40ed6076de1f2900ecd6c57c24736b21adf1dfdb",
        "edition": 845,
        "date": 1654567991428,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "845",
        "rarity_score": 96.70289048183525,
        "rarity_level": "COMMON"
    },
    "846": {
        "name": "80's Arkade Bulls #846",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/846.png",
        "dna": "aaff342270439ed8c611a58ce33bc0e72c3f232d",
        "edition": 846,
        "date": 1654567489929,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.1428571428571423,
        "id": "846",
        "rarity_score": 172.40829137143658,
        "rarity_level": "COMMON"
    },
    "847": {
        "name": "80's Arkade Bulls #847",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/847.png",
        "dna": "91290581f50f4650609b821a233a06950f741ea8",
        "edition": 847,
        "date": 1654567164181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "847",
        "rarity_score": 140.13417540929416,
        "rarity_level": "COMMON"
    },
    "848": {
        "name": "80's Arkade Bulls #848",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/848.png",
        "dna": "34e811f5628e3e5182563c7c13a7a4ba0b6ceef4",
        "edition": 848,
        "date": 1654567243847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "848",
        "rarity_score": 77.5298696312119,
        "rarity_level": "COMMON"
    },
    "849": {
        "name": "80's Arkade Bulls #849",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/849.png",
        "dna": "e1bd5b962619fa20fe1cae1e1efb80ac86b8ce09",
        "edition": 849,
        "date": 1654567162837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "849",
        "rarity_score": 61.43921945856455,
        "rarity_level": "COMMON"
    },
    "850": {
        "name": "80's Arkade Bulls #850",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/850.png",
        "dna": "f49caf2924c31aead266d0b63a9248ff0922f586",
        "edition": 850,
        "date": 1654567150893,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "850",
        "rarity_score": 61.50490196078431,
        "rarity_level": "COMMON"
    },
    "851": {
        "name": "80's Arkade Bulls #851",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/851.png",
        "dna": "1f8c5176b83974cfaa7a281e30941b5423065065",
        "edition": 851,
        "date": 1654568204052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "851",
        "rarity_score": 100.91469772966565,
        "rarity_level": "COMMON"
    },
    "852": {
        "name": "80's Arkade Bulls #852",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/852.png",
        "dna": "4ce5c298c97c2a44450e68871b4f43cd2085632c",
        "edition": 852,
        "date": 1654567131367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "852",
        "rarity_score": 61.523840758577435,
        "rarity_level": "COMMON"
    },
    "853": {
        "name": "80's Arkade Bulls #853",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/853.png",
        "dna": "245f0aa9db0a566773ba117393b802bdf2a8b886",
        "edition": 853,
        "date": 1654567257216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "853",
        "rarity_score": 136.84202140627136,
        "rarity_level": "COMMON"
    },
    "854": {
        "name": "80's Arkade Bulls #854",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/854.png",
        "dna": "eafec429fdaed5d877665711f53377eb76d042fc",
        "edition": 854,
        "date": 1654567575908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "854",
        "rarity_score": 166.39619435786364,
        "rarity_level": "COMMON"
    },
    "855": {
        "name": "80's Arkade Bulls #855",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/855.png",
        "dna": "c05627489873038cc38cd8c7178f013b98d6cee6",
        "edition": 855,
        "date": 1654567125216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "855",
        "rarity_score": 57.58340615925226,
        "rarity_level": "COMMON"
    },
    "856": {
        "name": "80's Arkade Bulls #856",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/856.png",
        "dna": "b937801f87f933abb8802be741ac26ada98ad564",
        "edition": 856,
        "date": 1654568184207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666667,
        "id": "856",
        "rarity_score": 119.00450367144408,
        "rarity_level": "COMMON"
    },
    "857": {
        "name": "80's Arkade Bulls #857",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/857.png",
        "dna": "4495b70c1e937bcafaf603bb402627ee53aed886",
        "edition": 857,
        "date": 1654567208893,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "857",
        "rarity_score": 71.01989955155025,
        "rarity_level": "COMMON"
    },
    "858": {
        "name": "80's Arkade Bulls #858",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/858.png",
        "dna": "23f95e1918641e2027672d62b0b14c843b4bcfec",
        "edition": 858,
        "date": 1654567996357,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "858",
        "rarity_score": 124.6784667504092,
        "rarity_level": "COMMON"
    },
    "859": {
        "name": "80's Arkade Bulls #859",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/859.png",
        "dna": "14574c604482784d0e2949b2cfbe1d592ea8163d",
        "edition": 859,
        "date": 1654568152135,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "859",
        "rarity_score": 151.1952776337377,
        "rarity_level": "COMMON"
    },
    "860": {
        "name": "80's Arkade Bulls #860",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/860.png",
        "dna": "147f4d5ab8ec30cf54a9c8d171f08ea72583722d",
        "edition": 860,
        "date": 1654567622918,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "860",
        "rarity_score": 176.3689932639162,
        "rarity_level": "COMMON"
    },
    "861": {
        "name": "80's Arkade Bulls #861",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/861.png",
        "dna": "3d9057424100a0a82aab6d51a602c4fc964d4c19",
        "edition": 861,
        "date": 1654567548454,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "861",
        "rarity_score": 134.35923112166813,
        "rarity_level": "COMMON"
    },
    "862": {
        "name": "80's Arkade Bulls #862",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/862.png",
        "dna": "b5074cfd42e22d796e9aa1d92fcc5c91d5ecebe2",
        "edition": 862,
        "date": 1654567930624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "862",
        "rarity_score": 82.87685546010101,
        "rarity_level": "COMMON"
    },
    "863": {
        "name": "80's Arkade Bulls #863",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/863.png",
        "dna": "b802ade1e45dcff39569761c87639c046e3c8ea5",
        "edition": 863,
        "date": 1654567699403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "863",
        "rarity_score": 113.73919955232762,
        "rarity_level": "COMMON"
    },
    "864": {
        "name": "80's Arkade Bulls #864",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/864.png",
        "dna": "96ba8737648e79bc2b21a4322ac9d868b391b964",
        "edition": 864,
        "date": 1654567555097,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "864",
        "rarity_score": 156.31906446458942,
        "rarity_level": "COMMON"
    },
    "865": {
        "name": "80's Arkade Bulls #865",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/865.png",
        "dna": "8ad5dcf2a9db9bfce147d2413ec41d2bce1f3b07",
        "edition": 865,
        "date": 1654567276965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rarity": 7.333333333333333,
        "rank": "Common",
        "id": "865",
        "rarity_score": 117.73945664025425,
        "rarity_level": "COMMON"
    },
    "866": {
        "name": "80's Arkade Bulls #866",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/866.png",
        "dna": "db7f4c091318e8d29663dd59c12000699fd561fa",
        "edition": 866,
        "date": 1654567397070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "866",
        "rarity_score": 152.3441173079401,
        "rarity_level": "COMMON"
    },
    "867": {
        "name": "80's Arkade Bulls #867",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/867.png",
        "dna": "ba36b44078568de53463dbbaa3143be7e24afd5a",
        "edition": 867,
        "date": 1654567286999,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "867",
        "rarity_score": 132.44448001734776,
        "rarity_level": "COMMON"
    },
    "868": {
        "name": "80's Arkade Bulls #868",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/868.png",
        "dna": "ea84532705f0dd8d5e1aeef5f8f7d75b5e4a1285",
        "edition": 868,
        "date": 1654567470693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "868",
        "rarity_score": 122.21416578602097,
        "rarity_level": "COMMON"
    },
    "869": {
        "name": "80's Arkade Bulls #869",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/869.png",
        "dna": "29038e2bae8942f79d117478d72899bb33c79672",
        "edition": 869,
        "date": 1654568090960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "869",
        "rarity_score": 137.41986030695708,
        "rarity_level": "COMMON"
    },
    "870": {
        "name": "80's Arkade Bulls #870",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/870.png",
        "dna": "119f4274892d0a9b30f6dcaa5836eb326b0089d4",
        "edition": 870,
        "date": 1654567536415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "870",
        "rarity_score": 157.78123324966677,
        "rarity_level": "COMMON"
    },
    "871": {
        "name": "80's Arkade Bulls #871",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/871.png",
        "dna": "f595662b5e78685b7628e96dae0d6131fff54543",
        "edition": 871,
        "date": 1654568121847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "871",
        "rarity_score": 85.2115495935405,
        "rarity_level": "COMMON"
    },
    "872": {
        "name": "80's Arkade Bulls #872",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/872.png",
        "dna": "3cd83b15573e1777622bc9044d4ed6f92909c8e0",
        "edition": 872,
        "date": 1654567393052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "872",
        "rarity_score": 166.0207683688813,
        "rarity_level": "COMMON"
    },
    "873": {
        "name": "80's Arkade Bulls #873",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/873.png",
        "dna": "3ee3bdce726e02d65afe2ddcc06d398bc7e35b8a",
        "edition": 873,
        "date": 1654567502237,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "873",
        "rarity_score": 178.7116013142608,
        "rarity_level": "COMMON"
    },
    "874": {
        "name": "80's Arkade Bulls #874",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/874.png",
        "dna": "61c6fa5456a826d1eef655882fcc1138920bbe44",
        "edition": 874,
        "date": 1654568028030,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "874",
        "rarity_score": 89.97358267670407,
        "rarity_level": "COMMON"
    },
    "875": {
        "name": "80's Arkade Bulls #875",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/875.png",
        "dna": "beba8ca5d47e30ee94c39a5f15ca5227b0f9bf73",
        "edition": 875,
        "date": 1654567680966,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "875",
        "rarity_score": 114.20289820574064,
        "rarity_level": "COMMON"
    },
    "876": {
        "name": "80's Arkade Bulls #876",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/876.png",
        "dna": "dd768c3749676b992609d2dcbbf3cb204982a4fb",
        "edition": 876,
        "date": 1654567261862,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "876",
        "rarity_score": 101.41865833504687,
        "rarity_level": "COMMON"
    },
    "877": {
        "name": "80's Arkade Bulls #877",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/877.png",
        "dna": "064c4463f068f99762f5f2972b9cb9764ead6da0",
        "edition": 877,
        "date": 1654567254976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "877",
        "rarity_score": 73.24148365438226,
        "rarity_level": "COMMON"
    },
    "878": {
        "name": "80's Arkade Bulls #878",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/878.png",
        "dna": "97ec76e7f3c42c846312a55460f6044dadee6314",
        "edition": 878,
        "date": 1654567353127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.555555555555555,
        "id": "878",
        "rarity_score": 230.1546868986662,
        "rarity_level": "RARE"
    },
    "879": {
        "name": "80's Arkade Bulls #879",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/879.png",
        "dna": "2d722c3771ea5fea0df46e3de6354ce3341abf41",
        "edition": 879,
        "date": 1654567186766,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "879",
        "rarity_score": 77.56371399031714,
        "rarity_level": "COMMON"
    },
    "880": {
        "name": "80's Arkade Bulls #880",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/880.png",
        "dna": "2e528926e822fc5b4a5bf377bfd4c2b613869e4a",
        "edition": 880,
        "date": 1654567280139,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "880",
        "rarity_score": 218.04801200300076,
        "rarity_level": "RARE"
    },
    "881": {
        "name": "80's Arkade Bulls #881",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/881.png",
        "dna": "7f3ed98a2dc3e03ef7ed29e23617fab5a504ca91",
        "edition": 881,
        "date": 1654567292510,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "881",
        "rarity_score": 82.28139980645906,
        "rarity_level": "COMMON"
    },
    "882": {
        "name": "80's Arkade Bulls #882",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/882.png",
        "dna": "19afbae907ca181260a984d89b56d1e8551e8ae7",
        "edition": 882,
        "date": 1654567173773,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "882",
        "rarity_score": 66.54442867791455,
        "rarity_level": "COMMON"
    },
    "883": {
        "name": "80's Arkade Bulls #883",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/883.png",
        "dna": "7ecb03dd6bb27e6e6d5f52aa18fcec1ef19ee2c9",
        "edition": 883,
        "date": 1654568133575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 9.6,
        "id": "883",
        "rarity_score": 51.533640073568115,
        "rarity_level": "COMMON"
    },
    "884": {
        "name": "80's Arkade Bulls #884",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/884.png",
        "dna": "edd8f51e03456b45032c7ea9b728162bdefff0a2",
        "edition": 884,
        "date": 1654567389937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "884",
        "rarity_score": 190.8165236854067,
        "rarity_level": "COMMON"
    },
    "885": {
        "name": "80's Arkade Bulls #885",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/885.png",
        "dna": "a94830e41bcaafe36c76d88281417057949e3265",
        "edition": 885,
        "date": 1654568153180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "885",
        "rarity_score": 148.57306580416179,
        "rarity_level": "COMMON"
    },
    "886": {
        "name": "80's Arkade Bulls #886",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/886.png",
        "dna": "6123723fda53499974a283abe208da909ac54988",
        "edition": 886,
        "date": 1654567131846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "886",
        "rarity_score": 156.3098939415876,
        "rarity_level": "COMMON"
    },
    "887": {
        "name": "80's Arkade Bulls #887",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/887.png",
        "dna": "df376b6085ebc061771d2ecdd1c4c28f1d6ea80c",
        "edition": 887,
        "date": 1654567485323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "887",
        "rarity_score": 166.2944719428342,
        "rarity_level": "COMMON"
    },
    "888": {
        "name": "80's Arkade Bulls #888",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/888.png",
        "dna": "9d64a9fd438d3624399a0a3e0643dba1926ce6f5",
        "edition": 888,
        "date": 1654567839616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "888",
        "rarity_score": 84.01666712278964,
        "rarity_level": "COMMON"
    },
    "889": {
        "name": "80's Arkade Bulls #889",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/889.png",
        "dna": "3e209d3fff25ebbacf6e65757d723c6b288644ab",
        "edition": 889,
        "date": 1654568129415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rarity": 9.2,
        "rank": "Common",
        "id": "889",
        "rarity_score": 74.72734324499032,
        "rarity_level": "COMMON"
    },
    "890": {
        "name": "80's Arkade Bulls #890",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/890.png",
        "dna": "50e5b1a39fd68b01d9fcf6d0c23f2af06c27e860",
        "edition": 890,
        "date": 1654567818695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "890",
        "rarity_score": 94.8499327273976,
        "rarity_level": "COMMON"
    },
    "891": {
        "name": "80's Arkade Bulls #891",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/891.png",
        "dna": "78c12a2b84ed82708056ea54944f869aa0c66aa2",
        "edition": 891,
        "date": 1654568132196,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "891",
        "rarity_score": 79.04121961039958,
        "rarity_level": "COMMON"
    },
    "892": {
        "name": "80's Arkade Bulls #892",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/892.png",
        "dna": "0920f6b1c38e6b0e84034fd9539ca6bcc1d7ab30",
        "edition": 892,
        "date": 1654567673635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "892",
        "rarity_score": 105.38201660181808,
        "rarity_level": "COMMON"
    },
    "893": {
        "name": "80's Arkade Bulls #893",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/893.png",
        "dna": "46a2a9a98eeebc537482c534d7efdab7f54a0726",
        "edition": 893,
        "date": 1654567496684,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "893",
        "rarity_score": 232.5078106933673,
        "rarity_level": "RARE"
    },
    "894": {
        "name": "80's Arkade Bulls #894",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/894.png",
        "dna": "01c57eb096d93fd46a7fb2d2e12276a6ab92408b",
        "edition": 894,
        "date": 1654567604635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "894",
        "rarity_score": 126.19061122657484,
        "rarity_level": "COMMON"
    },
    "895": {
        "name": "80's Arkade Bulls #895",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/895.png",
        "dna": "3288934eb80c3b4dcbf7636541e7b58f9a9b20c6",
        "edition": 895,
        "date": 1654568043007,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "895",
        "rarity_score": 74.3964273741679,
        "rarity_level": "COMMON"
    },
    "896": {
        "name": "80's Arkade Bulls #896",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/896.png",
        "dna": "a2bbf01d219411076339b0d40bffc0e50337b0d8",
        "edition": 896,
        "date": 1654567474111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.714285714285714,
        "id": "896",
        "rarity_score": 80.51831164359035,
        "rarity_level": "COMMON"
    },
    "897": {
        "name": "80's Arkade Bulls #897",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/897.png",
        "dna": "a8dc30d7aa39749770db07a7446353e498f3cd75",
        "edition": 897,
        "date": 1654567634553,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.75,
        "id": "897",
        "rarity_score": 142.38758304128734,
        "rarity_level": "COMMON"
    },
    "898": {
        "name": "80's Arkade Bulls #898",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/898.png",
        "dna": "a9ba03ba9da6463dd49f38f120d345857d88f152",
        "edition": 898,
        "date": 1654567649911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.125,
        "id": "898",
        "rarity_score": 138.33961733274148,
        "rarity_level": "COMMON"
    },
    "899": {
        "name": "80's Arkade Bulls #899",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/899.png",
        "dna": "1379a09b02907a5add1e715a7410c3b82f79d984",
        "edition": 899,
        "date": 1654567318910,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 4.777777777777778,
        "id": "899",
        "rarity_score": 308.0006227508135,
        "rarity_level": "RARE"
    },
    "900": {
        "name": "80's Arkade Bulls #900",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/900.png",
        "dna": "92ecaf146c82ed133d5c5452dd3821ae3f8c791d",
        "edition": 900,
        "date": 1654567415637,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "900",
        "rarity_score": 194.34026984961733,
        "rarity_level": "COMMON"
    },
    "901": {
        "name": "80's Arkade Bulls #901",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/901.png",
        "dna": "974e66b08a13bd0d39012d2c8d241b64a1c78827",
        "edition": 901,
        "date": 1654567407532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "901",
        "rarity_score": 183.7445078999868,
        "rarity_level": "COMMON"
    },
    "902": {
        "name": "80's Arkade Bulls #902",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/902.png",
        "dna": "ebf2c6cd75e357fdc9fd4d0accbd7b65662fe3a7",
        "edition": 902,
        "date": 1654567281694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "902",
        "rarity_score": 157.81562924264352,
        "rarity_level": "COMMON"
    },
    "903": {
        "name": "80's Arkade Bulls #903",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/903.png",
        "dna": "6d2d584838c5a89d55aed5e52fb9859ad5762f64",
        "edition": 903,
        "date": 1654567856651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "903",
        "rarity_score": 94.11210067763079,
        "rarity_level": "COMMON"
    },
    "904": {
        "name": "80's Arkade Bulls #904",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/904.png",
        "dna": "66df513486f90dfac4f5393fad1f557034c1d4ae",
        "edition": 904,
        "date": 1654567501113,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "904",
        "rarity_score": 170.35550274123153,
        "rarity_level": "COMMON"
    },
    "905": {
        "name": "80's Arkade Bulls #905",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/905.png",
        "dna": "f9b51993b2476fdcbd57c14f7d48373f3cb577bd",
        "edition": 905,
        "date": 1654568231623,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Super",
        "rarity": 3.2,
        "id": "905",
        "rarity_score": 243.73571428571427,
        "rarity_level": "RARE"
    },
    "906": {
        "name": "80's Arkade Bulls #906",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/906.png",
        "dna": "25dc4818fb8638bbf0c64013bdaa7d9eea511ffb",
        "edition": 906,
        "date": 1654568086451,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "906",
        "rarity_score": 66.29805886516941,
        "rarity_level": "COMMON"
    },
    "907": {
        "name": "80's Arkade Bulls #907",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/907.png",
        "dna": "3453f0579ea0c89e36ec59b74c77255b5808d01b",
        "edition": 907,
        "date": 1654567484744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "907",
        "rarity_score": 164.22232986769032,
        "rarity_level": "COMMON"
    },
    "908": {
        "name": "80's Arkade Bulls #908",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/908.png",
        "dna": "baddef5d3ef9853311036a26d287faaedd59d6ad",
        "edition": 908,
        "date": 1654567543494,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "908",
        "rarity_score": 195.60709730898623,
        "rarity_level": "COMMON"
    },
    "909": {
        "name": "80's Arkade Bulls #909",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/909.png",
        "dna": "b7cabe33fd7177126030a73097e7fc653985b4d4",
        "edition": 909,
        "date": 1654567136540,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "909",
        "rarity_score": 132.37065637065638,
        "rarity_level": "COMMON"
    },
    "910": {
        "name": "80's Arkade Bulls #910",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/910.png",
        "dna": "81a254777516fa6872d00f8c6462296b06569a4c",
        "edition": 910,
        "date": 1654567482379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "910",
        "rarity_score": 120.02716787640276,
        "rarity_level": "COMMON"
    },
    "911": {
        "name": "80's Arkade Bulls #911",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/911.png",
        "dna": "300a5d7d03874fece7bec8c952d4d376c12626d2",
        "edition": 911,
        "date": 1654568128952,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "911",
        "rarity_score": 95.55458263720519,
        "rarity_level": "COMMON"
    },
    "912": {
        "name": "80's Arkade Bulls #912",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/912.png",
        "dna": "371fea764729ba5adc64660f08c3225a8bdae2f5",
        "edition": 912,
        "date": 1654567153176,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "912",
        "rarity_score": 74.25018489124935,
        "rarity_level": "COMMON"
    },
    "913": {
        "name": "80's Arkade Bulls #913",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/913.png",
        "dna": "0b8994fee016ebc4e38506cadf345f630aff1ed1",
        "edition": 913,
        "date": 1654567924124,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "913",
        "rarity_score": 93.36501509416371,
        "rarity_level": "COMMON"
    },
    "914": {
        "name": "80's Arkade Bulls #914",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/914.png",
        "dna": "60e75b642cfd8b8cc9f312f82fc2b5ec0a2e5e33",
        "edition": 914,
        "date": 1654567435466,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "914",
        "rarity_score": 104.79157850439753,
        "rarity_level": "COMMON"
    },
    "915": {
        "name": "80's Arkade Bulls #915",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/915.png",
        "dna": "88437b6dc2e65d1d37a67fb8fbce40ff7077ed41",
        "edition": 915,
        "date": 1654567947866,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "915",
        "rarity_score": 102.564690580269,
        "rarity_level": "COMMON"
    },
    "916": {
        "name": "80's Arkade Bulls #916",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/916.png",
        "dna": "13347d1321bda14281297073147874380afc35ce",
        "edition": 916,
        "date": 1654567704360,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857143,
        "id": "916",
        "rarity_score": 210.13669572534863,
        "rarity_level": "RARE"
    },
    "917": {
        "name": "80's Arkade Bulls #917",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/917.png",
        "dna": "cfe68f07e18668069f3e6f2bef08cea362a8ced9",
        "edition": 917,
        "date": 1654568175407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "917",
        "rarity_score": 92.24566173864086,
        "rarity_level": "COMMON"
    },
    "918": {
        "name": "80's Arkade Bulls #918",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/918.png",
        "dna": "aba5b2e618cf68f388926d3d7b8f19bedad0b0cd",
        "edition": 918,
        "date": 1654567707141,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "918",
        "rarity_score": 72.5218123119991,
        "rarity_level": "COMMON"
    },
    "919": {
        "name": "80's Arkade Bulls #919",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/919.png",
        "dna": "8e1d011c6f36a85d6d838a6fcf99ed0a1b1fbf39",
        "edition": 919,
        "date": 1654568046280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Super",
        "rarity": 3.2,
        "id": "919",
        "rarity_score": 141.6738446991785,
        "rarity_level": "COMMON"
    },
    "920": {
        "name": "80's Arkade Bulls #920",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/920.png",
        "dna": "5f676e844c46698e29e75ab8e23ff8759c3e1bb0",
        "edition": 920,
        "date": 1654568036234,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "920",
        "rarity_score": 53.70337581476002,
        "rarity_level": "COMMON"
    },
    "921": {
        "name": "80's Arkade Bulls #921",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/921.png",
        "dna": "45b251b4b3f3f2fc4a2d5124435d9f6423a8776d",
        "edition": 921,
        "date": 1654568118145,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Epic",
        "rarity": 3.8,
        "id": "921",
        "rarity_score": 219.28205128205127,
        "rarity_level": "RARE"
    },
    "922": {
        "name": "80's Arkade Bulls #922",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/922.png",
        "dna": "b497ee77e7bafe4cef73b983bd004a0afef54678",
        "edition": 922,
        "date": 1654567495896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "922",
        "rarity_score": 167.82125870404968,
        "rarity_level": "COMMON"
    },
    "923": {
        "name": "80's Arkade Bulls #923",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/923.png",
        "dna": "2e45e5f80cd3e0b42027c2d0416b034ad14fe675",
        "edition": 923,
        "date": 1654568052949,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "923",
        "rarity_score": 98.47639500923881,
        "rarity_level": "COMMON"
    },
    "924": {
        "name": "80's Arkade Bulls #924",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/924.png",
        "dna": "81be302507c69174f04618a7a021f3b40c2da095",
        "edition": 924,
        "date": 1654567918522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "924",
        "rarity_score": 86.78336205457092,
        "rarity_level": "COMMON"
    },
    "925": {
        "name": "80's Arkade Bulls #925",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/925.png",
        "dna": "b4ffb81e748221fe9255f5669affa831952a6532",
        "edition": 925,
        "date": 1654567652200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.714285714285714,
        "id": "925",
        "rarity_score": 105.12172062937579,
        "rarity_level": "COMMON"
    },
    "926": {
        "name": "80's Arkade Bulls #926",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/926.png",
        "dna": "1fb0ac7e88723411ccdb6256b14c76cf8d805438",
        "edition": 926,
        "date": 1654567974062,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "926",
        "rarity_score": 132.38068919992145,
        "rarity_level": "COMMON"
    },
    "927": {
        "name": "80's Arkade Bulls #927",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/927.png",
        "dna": "db268687b00c483411f8d5249c2fd5740069b040",
        "edition": 927,
        "date": 1654567506633,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "927",
        "rarity_score": 150.51254124889746,
        "rarity_level": "COMMON"
    },
    "928": {
        "name": "80's Arkade Bulls #928",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/928.png",
        "dna": "0ca74c9fec95f35cdb191e66823fe70c4f9850fb",
        "edition": 928,
        "date": 1654568191611,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "928",
        "rarity_score": 95.42636981991835,
        "rarity_level": "COMMON"
    },
    "929": {
        "name": "80's Arkade Bulls #929",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/929.png",
        "dna": "c5e1bb82725acb21ac3b92148d26920953d9b748",
        "edition": 929,
        "date": 1654567894495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "929",
        "rarity_score": 98.71948060221229,
        "rarity_level": "COMMON"
    },
    "930": {
        "name": "80's Arkade Bulls #930",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/930.png",
        "dna": "77e80f74b7b76c229cd0cf8b9cc96bf3a13c9572",
        "edition": 930,
        "date": 1654567979559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "930",
        "rarity_score": 77.95015278149107,
        "rarity_level": "COMMON"
    },
    "931": {
        "name": "80's Arkade Bulls #931",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/931.png",
        "dna": "d8a1431be5a209f67aedf5e5689a80c556429d03",
        "edition": 931,
        "date": 1654567901895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "931",
        "rarity_score": 92.2350908597634,
        "rarity_level": "COMMON"
    },
    "932": {
        "name": "80's Arkade Bulls #932",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/932.png",
        "dna": "1e901563ab2f87e0d8545e94937d87f33acb2fe7",
        "edition": 932,
        "date": 1654567379078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "932",
        "rarity_score": 152.98808280903717,
        "rarity_level": "COMMON"
    },
    "933": {
        "name": "80's Arkade Bulls #933",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/933.png",
        "dna": "312e7d78a7c1c64f6007a514e8e17ff8941beade",
        "edition": 933,
        "date": 1654568214354,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "933",
        "rarity_score": 190.04136522118995,
        "rarity_level": "COMMON"
    },
    "934": {
        "name": "80's Arkade Bulls #934",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/934.png",
        "dna": "2ff2c99bfbb399ddf38919a8ca027d9feafd355d",
        "edition": 934,
        "date": 1654567866164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "934",
        "rarity_score": 130.0933526108177,
        "rarity_level": "COMMON"
    },
    "935": {
        "name": "80's Arkade Bulls #935",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/935.png",
        "dna": "0eb19fb111ae6ea2031167ed30d3e9fa751b7795",
        "edition": 935,
        "date": 1654567836250,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "935",
        "rarity_score": 180.38912316617055,
        "rarity_level": "COMMON"
    },
    "936": {
        "name": "80's Arkade Bulls #936",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/936.png",
        "dna": "cedf37354edc31a897dfc0465c3535c857c36899",
        "edition": 936,
        "date": 1654568026979,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "936",
        "rarity_score": 138.46205330086002,
        "rarity_level": "COMMON"
    },
    "937": {
        "name": "80's Arkade Bulls #937",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/937.png",
        "dna": "2aeab5af8163aefb6fd729a3177670cdea2a708f",
        "edition": 937,
        "date": 1654567903004,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "937",
        "rarity_score": 149.49613403667058,
        "rarity_level": "COMMON"
    },
    "938": {
        "name": "80's Arkade Bulls #938",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/938.png",
        "dna": "97e134de1783efca388ebb9f5070133b017b4a2a",
        "edition": 938,
        "date": 1654567552914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "938",
        "rarity_score": 178.32468041905628,
        "rarity_level": "COMMON"
    },
    "939": {
        "name": "80's Arkade Bulls #939",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/939.png",
        "dna": "ad9973d32ad9f14578d2c8d852aff85d07994e8e",
        "edition": 939,
        "date": 1654567560557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "939",
        "rarity_score": 207.2222990450691,
        "rarity_level": "RARE"
    },
    "940": {
        "name": "80's Arkade Bulls #940",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/940.png",
        "dna": "e8cf51588b9acabfe7a575442301fc1e04f862ad",
        "edition": 940,
        "date": 1654567574295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "940",
        "rarity_score": 124.94658417237429,
        "rarity_level": "COMMON"
    },
    "941": {
        "name": "80's Arkade Bulls #941",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/941.png",
        "dna": "ff0f76facb7755d7ae9a4a7a36f940d1398207c7",
        "edition": 941,
        "date": 1654567362373,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Super",
        "rarity": 3.1111111111111107,
        "id": "941",
        "rarity_score": 323.1658516741186,
        "rarity_level": "RARE"
    },
    "942": {
        "name": "80's Arkade Bulls #942",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/942.png",
        "dna": "66a6616b5aeff03c1c56d40076e7397d49625ab7",
        "edition": 942,
        "date": 1654568086913,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "942",
        "rarity_score": 95.52789729475498,
        "rarity_level": "COMMON"
    },
    "943": {
        "name": "80's Arkade Bulls #943",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/943.png",
        "dna": "a3c0c841178e9e68f06e8eb78119efb9e464d7e7",
        "edition": 943,
        "date": 1654567934510,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "943",
        "rarity_score": 182.61955429653878,
        "rarity_level": "COMMON"
    },
    "944": {
        "name": "80's Arkade Bulls #944",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/944.png",
        "dna": "04b88059cd5ec6043974e4df9c6d2622d0552c97",
        "edition": 944,
        "date": 1654567994363,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "944",
        "rarity_score": 115.18252104716872,
        "rarity_level": "COMMON"
    },
    "945": {
        "name": "80's Arkade Bulls #945",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/945.png",
        "dna": "e1b54ed1ac22a763a5720d16768c5d0eb58114ea",
        "edition": 945,
        "date": 1654567377118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "945",
        "rarity_score": 239.20209870078756,
        "rarity_level": "RARE"
    },
    "946": {
        "name": "80's Arkade Bulls #946",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/946.png",
        "dna": "20d13b48c0c509c78d2cb7a8c75c80b20d1f02c0",
        "edition": 946,
        "date": 1654568142035,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "946",
        "rarity_score": 57.92429826921352,
        "rarity_level": "COMMON"
    },
    "947": {
        "name": "80's Arkade Bulls #947",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/947.png",
        "dna": "86042cdb8e49997f7ceb12fe12f0da9d161c28fb",
        "edition": 947,
        "date": 1654568116396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "947",
        "rarity_score": 81.69267230465387,
        "rarity_level": "COMMON"
    },
    "948": {
        "name": "80's Arkade Bulls #948",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/948.png",
        "dna": "615fa20c6b2ec902a0965830601874b7c0504ca2",
        "edition": 948,
        "date": 1654568105163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "948",
        "rarity_score": 77.01357680056732,
        "rarity_level": "COMMON"
    },
    "949": {
        "name": "80's Arkade Bulls #949",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/949.png",
        "dna": "c5b3b26a1b1482b717372ae01c5d50a74e6ddae5",
        "edition": 949,
        "date": 1654567867168,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "949",
        "rarity_score": 128.35424467344504,
        "rarity_level": "COMMON"
    },
    "950": {
        "name": "80's Arkade Bulls #950",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/950.png",
        "dna": "f17a6fc9523f4e2b439f5342f4811ec62c4883b5",
        "edition": 950,
        "date": 1654567310295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8888888888888893,
        "id": "950",
        "rarity_score": 259.2304904170032,
        "rarity_level": "RARE"
    },
    "951": {
        "name": "80's Arkade Bulls #951",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/951.png",
        "dna": "65679c7bbaec7236f865c22b0eebfb560388ae90",
        "edition": 951,
        "date": 1654567151351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "951",
        "rarity_score": 86.59726154307427,
        "rarity_level": "COMMON"
    },
    "952": {
        "name": "80's Arkade Bulls #952",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/952.png",
        "dna": "5af263a7b610d5c8f4d1f41497d783ccfe4fc3a4",
        "edition": 952,
        "date": 1654567963678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "952",
        "rarity_score": 93.90987095057275,
        "rarity_level": "COMMON"
    },
    "953": {
        "name": "80's Arkade Bulls #953",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/953.png",
        "dna": "89c57dac23b51e106ac254ec440ed66922cf804c",
        "edition": 953,
        "date": 1654568036687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "953",
        "rarity_score": 80.01679211671956,
        "rarity_level": "COMMON"
    },
    "954": {
        "name": "80's Arkade Bulls #954",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/954.png",
        "dna": "148cf6e022f871544c74432ad14878a389a4d6e4",
        "edition": 954,
        "date": 1654568115462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "954",
        "rarity_score": 64.24186091994768,
        "rarity_level": "COMMON"
    },
    "955": {
        "name": "80's Arkade Bulls #955",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/955.png",
        "dna": "630ba363458e0511c5caf380a702fb5e3e3ed506",
        "edition": 955,
        "date": 1654568068984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "955",
        "rarity_score": 66.23003926647651,
        "rarity_level": "COMMON"
    },
    "956": {
        "name": "80's Arkade Bulls #956",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/956.png",
        "dna": "5c28184d63c51dfb23ea37f46c7fe6f597e39d8b",
        "edition": 956,
        "date": 1654567306488,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rarity": 5.666666666666667,
        "rank": "Rare",
        "id": "956",
        "rarity_score": 108.79704596478788,
        "rarity_level": "COMMON"
    },
    "957": {
        "name": "80's Arkade Bulls #957",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/957.png",
        "dna": "7f061f21cfecd392d141d6ce9ad60606b363dca3",
        "edition": 957,
        "date": 1654567379549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "957",
        "rarity_score": 197.47857235337275,
        "rarity_level": "COMMON"
    },
    "958": {
        "name": "80's Arkade Bulls #958",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/958.png",
        "dna": "59e16aa9880e43497bf17f6503096f07eff06c70",
        "edition": 958,
        "date": 1654567469556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "958",
        "rarity_score": 125.8038350603978,
        "rarity_level": "COMMON"
    },
    "959": {
        "name": "80's Arkade Bulls #959",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/959.png",
        "dna": "d9da7e93f7819d58242c48548dac4fc99e2fd8ba",
        "edition": 959,
        "date": 1654567710717,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333334,
        "id": "959",
        "rarity_score": 114.79305633330829,
        "rarity_level": "COMMON"
    },
    "960": {
        "name": "80's Arkade Bulls #960",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/960.png",
        "dna": "b0a8da3339d95cd3767e2a688cb8094a42f3ca49",
        "edition": 960,
        "date": 1654567319586,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "960",
        "rarity_score": 236.645449407384,
        "rarity_level": "RARE"
    },
    "961": {
        "name": "80's Arkade Bulls #961",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/961.png",
        "dna": "79f5ee23159b2a938e9071bbda5f8246cf70270f",
        "edition": 961,
        "date": 1654567575344,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "961",
        "rarity_score": 195.5681430884651,
        "rarity_level": "COMMON"
    },
    "962": {
        "name": "80's Arkade Bulls #962",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/962.png",
        "dna": "2bd72fb1b52848e03b61c3912bad0680a340aaf1",
        "edition": 962,
        "date": 1654568029832,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "962",
        "rarity_score": 73.69910391234379,
        "rarity_level": "COMMON"
    },
    "963": {
        "name": "80's Arkade Bulls #963",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/963.png",
        "dna": "7ea435d7773722b4dc7c9440e51c4395a442773c",
        "edition": 963,
        "date": 1654567332698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.444444444444445,
        "id": "963",
        "rarity_score": 181.38998051271716,
        "rarity_level": "COMMON"
    },
    "964": {
        "name": "80's Arkade Bulls #964",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/964.png",
        "dna": "0be4ac70a0881b001483589c95186c81274fa2ab",
        "edition": 964,
        "date": 1654567412031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 2.833333333333333,
        "id": "964",
        "rarity_score": 222.76870752619305,
        "rarity_level": "RARE"
    },
    "965": {
        "name": "80's Arkade Bulls #965",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/965.png",
        "dna": "f452ab08e01d9972c6f84c4cb76da5d0b7a51d66",
        "edition": 965,
        "date": 1654567231316,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "965",
        "rarity_score": 77.33059768407338,
        "rarity_level": "COMMON"
    },
    "966": {
        "name": "80's Arkade Bulls #966",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/966.png",
        "dna": "78fedb813e08bdec418fe1a2d36cda2b2e00a6e5",
        "edition": 966,
        "date": 1654567573736,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "966",
        "rarity_score": 152.13394126743725,
        "rarity_level": "COMMON"
    },
    "967": {
        "name": "80's Arkade Bulls #967",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/967.png",
        "dna": "ffc359636de8dbe9fa2458f046c629b813102df3",
        "edition": 967,
        "date": 1654568213838,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "967",
        "rarity_score": 106.32574925461475,
        "rarity_level": "COMMON"
    },
    "968": {
        "name": "80's Arkade Bulls #968",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/968.png",
        "dna": "1fb0d2c8ff127f88d9c61cda5474865eae72a95c",
        "edition": 968,
        "date": 1654567513828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "968",
        "rarity_score": 141.7920311446793,
        "rarity_level": "COMMON"
    },
    "969": {
        "name": "80's Arkade Bulls #969",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/969.png",
        "dna": "6816f47be11dbc74b3a662f9777bb42c8bdfc570",
        "edition": 969,
        "date": 1654567351147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "969",
        "rarity_score": 250.8793546139746,
        "rarity_level": "RARE"
    },
    "970": {
        "name": "80's Arkade Bulls #970",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/970.png",
        "dna": "c798a075a9be10deaa92679c958d976af4990147",
        "edition": 970,
        "date": 1654568006873,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "970",
        "rarity_score": 118.83209835973344,
        "rarity_level": "COMMON"
    },
    "971": {
        "name": "80's Arkade Bulls #971",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/971.png",
        "dna": "ef62248b1b97906651557e9e944bc50528a724f3",
        "edition": 971,
        "date": 1654568081620,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "971",
        "rarity_score": 64.19356327189271,
        "rarity_level": "COMMON"
    },
    "972": {
        "name": "80's Arkade Bulls #972",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/972.png",
        "dna": "be88ee0e53287112fbe702aa2b9bc676804cf07b",
        "edition": 972,
        "date": 1654567926270,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "972",
        "rarity_score": 109.8787647114172,
        "rarity_level": "COMMON"
    },
    "973": {
        "name": "80's Arkade Bulls #973",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/973.png",
        "dna": "dafa54db076232694fd49a3bd3ce2275e3dd13dc",
        "edition": 973,
        "date": 1654567139873,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "973",
        "rarity_score": 81.4919050447378,
        "rarity_level": "COMMON"
    },
    "974": {
        "name": "80's Arkade Bulls #974",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/974.png",
        "dna": "e3ddc8f7c2f7999a0857dd3ee2315eb647a1cb29",
        "edition": 974,
        "date": 1654568121323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "974",
        "rarity_score": 71.82409805079342,
        "rarity_level": "COMMON"
    },
    "975": {
        "name": "80's Arkade Bulls #975",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/975.png",
        "dna": "1cee0aa707b1ade90f5749a0d4a714d87cba1cdf",
        "edition": 975,
        "date": 1654568063546,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "975",
        "rarity_score": 101.49608580874653,
        "rarity_level": "COMMON"
    },
    "976": {
        "name": "80's Arkade Bulls #976",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/976.png",
        "dna": "2347da7cd69031439143fb7f3efd70b50c902655",
        "edition": 976,
        "date": 1654567119304,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "976",
        "rarity_score": 116.29220859089742,
        "rarity_level": "COMMON"
    },
    "977": {
        "name": "80's Arkade Bulls #977",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/977.png",
        "dna": "571d692fdd4d68525d9c0da38749b88cdfd60eaa",
        "edition": 977,
        "date": 1654567444335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "977",
        "rarity_score": 117.35011255460039,
        "rarity_level": "COMMON"
    },
    "978": {
        "name": "80's Arkade Bulls #978",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/978.png",
        "dna": "aa7a1db50a46abb61c181d947a8e217ac249bff9",
        "edition": 978,
        "date": 1654568011453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "978",
        "rarity_score": 93.41233535596454,
        "rarity_level": "COMMON"
    },
    "979": {
        "name": "80's Arkade Bulls #979",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/979.png",
        "dna": "a207343de4b478a22d18d90e3c8337555739a7e2",
        "edition": 979,
        "date": 1654567891826,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "979",
        "rarity_score": 179.20327963030067,
        "rarity_level": "COMMON"
    },
    "980": {
        "name": "80's Arkade Bulls #980",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/980.png",
        "dna": "5601ef85dbcd9d7b1e37129d303506d1b9adb5cd",
        "edition": 980,
        "date": 1654567425928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "980",
        "rarity_score": 89.44177026312101,
        "rarity_level": "COMMON"
    },
    "981": {
        "name": "80's Arkade Bulls #981",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/981.png",
        "dna": "23c673506195e3502686465458bfc13f6e921d03",
        "edition": 981,
        "date": 1654568089593,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "981",
        "rarity_score": 74.92546698639151,
        "rarity_level": "COMMON"
    },
    "982": {
        "name": "80's Arkade Bulls #982",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/982.png",
        "dna": "139dae67d69564e91d0771a311393cb0f1c846d9",
        "edition": 982,
        "date": 1654567624148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.25,
        "id": "982",
        "rarity_score": 127.9678975155427,
        "rarity_level": "COMMON"
    },
    "983": {
        "name": "80's Arkade Bulls #983",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/983.png",
        "dna": "f232d27e23ba771c0950334702a172a60e2ab57a",
        "edition": 983,
        "date": 1654568203531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "983",
        "rarity_score": 201.37653707518368,
        "rarity_level": "COMMON"
    },
    "984": {
        "name": "80's Arkade Bulls #984",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/984.png",
        "dna": "6c1c5f8d3e7b58c5ecc02263ff2c0d723ced7e1e",
        "edition": 984,
        "date": 1654567120201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "984",
        "rarity_score": 66.04081619844001,
        "rarity_level": "COMMON"
    },
    "985": {
        "name": "80's Arkade Bulls #985",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/985.png",
        "dna": "78191743fea6275d532d8c89a46006e7ec2b8b46",
        "edition": 985,
        "date": 1654568012557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "985",
        "rarity_score": 231.49894350282213,
        "rarity_level": "RARE"
    },
    "986": {
        "name": "80's Arkade Bulls #986",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/986.png",
        "dna": "f29795c0e104ca0236ae825a77de650d1636e608",
        "edition": 986,
        "date": 1654567439878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "986",
        "rarity_score": 78.47918428299161,
        "rarity_level": "COMMON"
    },
    "987": {
        "name": "80's Arkade Bulls #987",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/987.png",
        "dna": "d616dd92d265c941531b4f4d9a38688f5ae061d0",
        "edition": 987,
        "date": 1654567983897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "987",
        "rarity_score": 97.59763807224803,
        "rarity_level": "COMMON"
    },
    "988": {
        "name": "80's Arkade Bulls #988",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/988.png",
        "dna": "4a3643b5e805adc8e12df8e191f9e7389b7e7379",
        "edition": 988,
        "date": 1654567589867,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "988",
        "rarity_score": 208.6308204507357,
        "rarity_level": "RARE"
    },
    "989": {
        "name": "80's Arkade Bulls #989",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/989.png",
        "dna": "2a4fb27c68f47ed65cbc0dd2c80f56598a144d86",
        "edition": 989,
        "date": 1654567599902,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.166666666666667,
        "id": "989",
        "rarity_score": 211.615668202765,
        "rarity_level": "RARE"
    },
    "990": {
        "name": "80's Arkade Bulls #990",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/990.png",
        "dna": "9fb203232b03ec9543eb8532913f26852d255643",
        "edition": 990,
        "date": 1654568122309,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Epic",
        "rarity": 3.5999999999999996,
        "id": "990",
        "rarity_score": 137.0797073000502,
        "rarity_level": "COMMON"
    },
    "991": {
        "name": "80's Arkade Bulls #991",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/991.png",
        "dna": "1f7cff4c37ad38052b4c4ffe9a075cd9c790b5b6",
        "edition": 991,
        "date": 1654567418650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "991",
        "rarity_score": 133.24537476982624,
        "rarity_level": "COMMON"
    },
    "992": {
        "name": "80's Arkade Bulls #992",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/992.png",
        "dna": "412877283c3cb1c402d5647a33f6b8828562ee49",
        "edition": 992,
        "date": 1654567721032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rarity": 7.5,
        "rank": "Common",
        "id": "992",
        "rarity_score": 92.55845012362262,
        "rarity_level": "COMMON"
    },
    "993": {
        "name": "80's Arkade Bulls #993",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/993.png",
        "dna": "1aab4c7a75d19964800a03c7d5ac8000c426a0f0",
        "edition": 993,
        "date": 1654568135895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "993",
        "rarity_score": 71.98856626661505,
        "rarity_level": "COMMON"
    },
    "994": {
        "name": "80's Arkade Bulls #994",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/994.png",
        "dna": "5360374426784141a9d3b65ec71ea98f3e621f58",
        "edition": 994,
        "date": 1654567480722,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "994",
        "rarity_score": 165.85367415836214,
        "rarity_level": "COMMON"
    },
    "995": {
        "name": "80's Arkade Bulls #995",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/995.png",
        "dna": "aa9e73071a44ea65f129aac5f72853b6c542b625",
        "edition": 995,
        "date": 1654567171895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Epic",
        "rarity": 4.2,
        "id": "995",
        "rarity_score": 153.05988027224765,
        "rarity_level": "COMMON"
    },
    "996": {
        "name": "80's Arkade Bulls #996",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/996.png",
        "dna": "1d0fca03d19c27d9c7854b33c70c66bcbf1e4f78",
        "edition": 996,
        "date": 1654567122940,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 4.8,
        "id": "996",
        "rarity_score": 115.57429826921353,
        "rarity_level": "COMMON"
    },
    "997": {
        "name": "80's Arkade Bulls #997",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/997.png",
        "dna": "e132d669a9f7ed84f6276f5f5431cbc8dd9ea231",
        "edition": 997,
        "date": 1654567675919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "997",
        "rarity_score": 143.29788761659933,
        "rarity_level": "COMMON"
    },
    "998": {
        "name": "80's Arkade Bulls #998",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/998.png",
        "dna": "fa8178fd044c5353d5bc292b45b275ec12ccf6ca",
        "edition": 998,
        "date": 1654568176958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "998",
        "rarity_score": 130.7878156821435,
        "rarity_level": "COMMON"
    },
    "999": {
        "name": "80's Arkade Bulls #999",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/999.png",
        "dna": "d48c5dd3a0353f9f4b5805b44715d3af07e40591",
        "edition": 999,
        "date": 1654567352484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rarity": 4.333333333333333,
        "rank": "Epic",
        "id": "999",
        "rarity_score": 260.0521292635408,
        "rarity_level": "RARE"
    },
    "1000": {
        "name": "80's Arkade Bulls #1000",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1000.png",
        "dna": "6d07713d3ed3a21549e8fea932ff6e0c2118d895",
        "edition": 1000,
        "date": 1654568227172,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1000",
        "rarity_score": 221.23282363343216,
        "rarity_level": "RARE"
    },
    "1001": {
        "name": "80's Arkade Bulls #1001",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1001.png",
        "dna": "3caf2ff99baa24c0f2ff6f813e24ad4a7b9b58fe",
        "edition": 1001,
        "date": 1654568197255,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1001",
        "rarity_score": 94.92271943768549,
        "rarity_level": "COMMON"
    },
    "1002": {
        "name": "80's Arkade Bulls #1002",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1002.png",
        "dna": "2b8fb1511e777256736c66dd0cc6e6bcd5f53b05",
        "edition": 1002,
        "date": 1654567294605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1002",
        "rarity_score": 171.03133065481734,
        "rarity_level": "COMMON"
    },
    "1003": {
        "name": "80's Arkade Bulls #1003",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1003.png",
        "dna": "d15ca5e6813cbdb5b7bb710b394a3c56a3571c6f",
        "edition": 1003,
        "date": 1654568216695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1003",
        "rarity_score": 285.5139559171479,
        "rarity_level": "RARE"
    },
    "1004": {
        "name": "80's Arkade Bulls #1004",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1004.png",
        "dna": "405e9dfc74949669e866c3bf565ca17598280589",
        "edition": 1004,
        "date": 1654567854646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1004",
        "rarity_score": 113.44413130167054,
        "rarity_level": "COMMON"
    },
    "1005": {
        "name": "80's Arkade Bulls #1005",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1005.png",
        "dna": "06e6156c2a1edf12e530fec613f9c3ecd4b077cf",
        "edition": 1005,
        "date": 1654568180564,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666667,
        "id": "1005",
        "rarity_score": 102.0813588774893,
        "rarity_level": "COMMON"
    },
    "1006": {
        "name": "80's Arkade Bulls #1006",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1006.png",
        "dna": "7fbb76fb4d909a4ea0203cbea5e57e202c34a960",
        "edition": 1006,
        "date": 1654567635172,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "1006",
        "rarity_score": 118.84682507102607,
        "rarity_level": "COMMON"
    },
    "1007": {
        "name": "80's Arkade Bulls #1007",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1007.png",
        "dna": "f483d9b5861d1519cb0616d1422024469b209b64",
        "edition": 1007,
        "date": 1654567123385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1007",
        "rarity_score": 81.43896048639024,
        "rarity_level": "COMMON"
    },
    "1008": {
        "name": "80's Arkade Bulls #1008",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1008.png",
        "dna": "f8f5754e2a4e575aa1fd9b50dd3b0cd2e35a85e0",
        "edition": 1008,
        "date": 1654568189014,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1008",
        "rarity_score": 85.79232634164349,
        "rarity_level": "COMMON"
    },
    "1009": {
        "name": "80's Arkade Bulls #1009",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1009.png",
        "dna": "1f4ecfbd31ba2107d54db8b38f5bee04dec38705",
        "edition": 1009,
        "date": 1654567825253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1009",
        "rarity_score": 60.697684140020236,
        "rarity_level": "COMMON"
    },
    "1010": {
        "name": "80's Arkade Bulls #1010",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1010.png",
        "dna": "408229d7c8dc6fb9e12adb768dbeedb2de7c6af0",
        "edition": 1010,
        "date": 1654567324274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.888888888888889,
        "id": "1010",
        "rarity_score": 306.8470513059385,
        "rarity_level": "RARE"
    },
    "1011": {
        "name": "80's Arkade Bulls #1011",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1011.png",
        "dna": "3968ff2e827febe8203eef3a2f48b997f1aecce5",
        "edition": 1011,
        "date": 1654567474635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "1011",
        "rarity_score": 97.6565833124921,
        "rarity_level": "COMMON"
    },
    "1012": {
        "name": "80's Arkade Bulls #1012",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1012.png",
        "dna": "75084a5843b4d1cb51a1ed28c357c479830be168",
        "edition": 1012,
        "date": 1654568052042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1012",
        "rarity_score": 55.51439158039112,
        "rarity_level": "COMMON"
    },
    "1013": {
        "name": "80's Arkade Bulls #1013",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1013.png",
        "dna": "14a099d1cbe0e9015015daca84b2d8108a92da2f",
        "edition": 1013,
        "date": 1654567248021,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1013",
        "rarity_score": 106.97513634610793,
        "rarity_level": "COMMON"
    },
    "1014": {
        "name": "80's Arkade Bulls #1014",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1014.png",
        "dna": "bcc038aad7d52254bccd2c53b9bf0581857b87a7",
        "edition": 1014,
        "date": 1654568149095,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1014",
        "rarity_score": 177.4996542724991,
        "rarity_level": "COMMON"
    },
    "1015": {
        "name": "80's Arkade Bulls #1015",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1015.png",
        "dna": "a6e3923b65e686e7b159610e75fe8e1fd15f49cd",
        "edition": 1015,
        "date": 1654568008409,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1015",
        "rarity_score": 97.62825085336188,
        "rarity_level": "COMMON"
    },
    "1016": {
        "name": "80's Arkade Bulls #1016",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1016.png",
        "dna": "2498763f305fb6781524af77a69df7f8972f4d1a",
        "edition": 1016,
        "date": 1654567320931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1016",
        "rarity_score": 215.9374600273826,
        "rarity_level": "RARE"
    },
    "1017": {
        "name": "80's Arkade Bulls #1017",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1017.png",
        "dna": "9e9a1fc311ee4a1cc028fef4234e65567db54714",
        "edition": 1017,
        "date": 1654567938904,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1017",
        "rarity_score": 102.74058340543195,
        "rarity_level": "COMMON"
    },
    "1018": {
        "name": "80's Arkade Bulls #1018",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1018.png",
        "dna": "7f255c8ffa2d0798dd60b51167d0e30446c590f5",
        "edition": 1018,
        "date": 1654567699977,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "1018",
        "rarity_score": 103.02414951224144,
        "rarity_level": "COMMON"
    },
    "1019": {
        "name": "80's Arkade Bulls #1019",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1019.png",
        "dna": "8aab77f14584040d489bb18a0663b2acc56d6794",
        "edition": 1019,
        "date": 1654567216167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "1019",
        "rarity_score": 93.79276392241677,
        "rarity_level": "COMMON"
    },
    "1020": {
        "name": "80's Arkade Bulls #1020",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1020.png",
        "dna": "33f62efade1680a40bda792ab97b344964f9166f",
        "edition": 1020,
        "date": 1654568031594,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "1020",
        "rarity_score": 58.78762463083879,
        "rarity_level": "COMMON"
    },
    "1021": {
        "name": "80's Arkade Bulls #1021",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1021.png",
        "dna": "e1b11ae22124115fa0c5d903a5c878ea1c16577d",
        "edition": 1021,
        "date": 1654567713781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1021",
        "rarity_score": 90.83532664565206,
        "rarity_level": "COMMON"
    },
    "1022": {
        "name": "80's Arkade Bulls #1022",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1022.png",
        "dna": "d2a2fbf90934e4743861bb72e61020ecac7c7145",
        "edition": 1022,
        "date": 1654568039779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1022",
        "rarity_score": 107.52093596059115,
        "rarity_level": "COMMON"
    },
    "1023": {
        "name": "80's Arkade Bulls #1023",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1023.png",
        "dna": "d607026153a12c2baf395fea57351a3dc44cc8de",
        "edition": 1023,
        "date": 1654567169864,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1023",
        "rarity_score": 92.37121745249826,
        "rarity_level": "COMMON"
    },
    "1024": {
        "name": "80's Arkade Bulls #1024",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1024.png",
        "dna": "f3d7bb1fddf0850de9f5ecdde6e60668634ab291",
        "edition": 1024,
        "date": 1654567466245,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1024",
        "rarity_score": 118.41846209010077,
        "rarity_level": "COMMON"
    },
    "1025": {
        "name": "80's Arkade Bulls #1025",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1025.png",
        "dna": "c38904ee8b37596f672db46894cf0ddbcdb19009",
        "edition": 1025,
        "date": 1654567197482,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1025",
        "rarity_score": 64.94641983710352,
        "rarity_level": "COMMON"
    },
    "1026": {
        "name": "80's Arkade Bulls #1026",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1026.png",
        "dna": "90b3acbf060a2344833a3f2c5d20e0a5b110292b",
        "edition": 1026,
        "date": 1654567227078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "1026",
        "rarity_score": 97.37036094372505,
        "rarity_level": "COMMON"
    },
    "1027": {
        "name": "80's Arkade Bulls #1027",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1027.png",
        "dna": "cfa06d2f7dbbb7e64ce0b4efc92ace4c14f440e6",
        "edition": 1027,
        "date": 1654567284912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1027",
        "rarity_score": 155.3139003970647,
        "rarity_level": "COMMON"
    },
    "1028": {
        "name": "80's Arkade Bulls #1028",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1028.png",
        "dna": "5f0be5badc1330be245cf0716f7cb0d6bbdbee34",
        "edition": 1028,
        "date": 1654567888507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1028",
        "rarity_score": 127.15650937741621,
        "rarity_level": "COMMON"
    },
    "1029": {
        "name": "80's Arkade Bulls #1029",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1029.png",
        "dna": "3856fc3f926378c03d8c39c871bd107f2b287ab7",
        "edition": 1029,
        "date": 1654567200330,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1029",
        "rarity_score": 74.86877443469646,
        "rarity_level": "COMMON"
    },
    "1030": {
        "name": "80's Arkade Bulls #1030",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1030.png",
        "dna": "122a2fd378ca1774a3bd6f464e653c8cb5fa5f49",
        "edition": 1030,
        "date": 1654567700549,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Epic",
        "rarity": 4.285714285714286,
        "id": "1030",
        "rarity_score": 154.1809157033456,
        "rarity_level": "COMMON"
    },
    "1031": {
        "name": "80's Arkade Bulls #1031",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1031.png",
        "dna": "108013f36dd6b9b27cf0ceedcd638daf1697bf3c",
        "edition": 1031,
        "date": 1654567673080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Epic",
        "rarity": 3.8571428571428568,
        "id": "1031",
        "rarity_score": 219.57095589518326,
        "rarity_level": "RARE"
    },
    "1032": {
        "name": "80's Arkade Bulls #1032",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1032.png",
        "dna": "b434fd22f2121eb3df965c526a6c57a1b0e0f942",
        "edition": 1032,
        "date": 1654567210492,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1032",
        "rarity_score": 105.7378458169194,
        "rarity_level": "COMMON"
    },
    "1033": {
        "name": "80's Arkade Bulls #1033",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1033.png",
        "dna": "f2e9ae248b4eeebc388f5eb2a01e2637b462df32",
        "edition": 1033,
        "date": 1654567820340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "1033",
        "rarity_score": 82.89276942763388,
        "rarity_level": "COMMON"
    },
    "1034": {
        "name": "80's Arkade Bulls #1034",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1034.png",
        "dna": "97b168e44ca3698020f816eae2bcd14aa34dafe2",
        "edition": 1034,
        "date": 1654567364953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666666,
        "id": "1034",
        "rarity_score": 163.1086213703739,
        "rarity_level": "COMMON"
    },
    "1035": {
        "name": "80's Arkade Bulls #1035",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1035.png",
        "dna": "1903ec22c64f64e02970793765952ed3a7260395",
        "edition": 1035,
        "date": 1654567921389,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1035",
        "rarity_score": 203.22475943082762,
        "rarity_level": "RARE"
    },
    "1036": {
        "name": "80's Arkade Bulls #1036",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1036.png",
        "dna": "c9e0c8b423345ce483ff1857ab710c0c6cc24521",
        "edition": 1036,
        "date": 1654567142613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1036",
        "rarity_score": 86.13943137205872,
        "rarity_level": "COMMON"
    },
    "1037": {
        "name": "80's Arkade Bulls #1037",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1037.png",
        "dna": "fdc98168051d48b0d6b9f89ec5b70ebde7b2cb89",
        "edition": 1037,
        "date": 1654568158252,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1037",
        "rarity_score": 257.49275899755867,
        "rarity_level": "RARE"
    },
    "1038": {
        "name": "80's Arkade Bulls #1038",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1038.png",
        "dna": "674a2d3804bcd08d368f18e9565feb00acf1db12",
        "edition": 1038,
        "date": 1654568167074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1038",
        "rarity_score": 315.36001255492783,
        "rarity_level": "RARE"
    },
    "1039": {
        "name": "80's Arkade Bulls #1039",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1039.png",
        "dna": "f274e94f5e1850ec2a528ab623658fe6042c7e29",
        "edition": 1039,
        "date": 1654567610667,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "1039",
        "rarity_score": 113.74679322209707,
        "rarity_level": "COMMON"
    },
    "1040": {
        "name": "80's Arkade Bulls #1040",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1040.png",
        "dna": "f9a288db9bb56704d037d8d10cd47a9d46b81f9f",
        "edition": 1040,
        "date": 1654567662282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 5.428571428571429,
        "rank": "Rare",
        "id": "1040",
        "rarity_score": 166.4578848600682,
        "rarity_level": "COMMON"
    },
    "1041": {
        "name": "80's Arkade Bulls #1041",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1041.png",
        "dna": "c1ede0e0366ac1938fb8abad26c5efc93ed15dc9",
        "edition": 1041,
        "date": 1654567983380,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1041",
        "rarity_score": 81.65418364553244,
        "rarity_level": "COMMON"
    },
    "1042": {
        "name": "80's Arkade Bulls #1042",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1042.png",
        "dna": "aa556a1fbbc6a37d22377f0cb95786836e5ac49f",
        "edition": 1042,
        "date": 1654567719877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "1042",
        "rarity_score": 91.21103189972315,
        "rarity_level": "COMMON"
    },
    "1043": {
        "name": "80's Arkade Bulls #1043",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1043.png",
        "dna": "5669c4f30862ddbf228478a23de068d8480d496b",
        "edition": 1043,
        "date": 1654567298810,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1043",
        "rarity_score": 84.05819385529581,
        "rarity_level": "COMMON"
    },
    "1044": {
        "name": "80's Arkade Bulls #1044",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1044.png",
        "dna": "dfc82e8b5848171793a4d2fb319f5ee1ed65bb79",
        "edition": 1044,
        "date": 1654568034880,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "1044",
        "rarity_score": 51.938022843891524,
        "rarity_level": "COMMON"
    },
    "1045": {
        "name": "80's Arkade Bulls #1045",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1045.png",
        "dna": "585a33b9a4b6d85e492a86ef287397f86aba63ab",
        "edition": 1045,
        "date": 1654567383493,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1045",
        "rarity_score": 162.94211236803312,
        "rarity_level": "COMMON"
    },
    "1046": {
        "name": "80's Arkade Bulls #1046",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1046.png",
        "dna": "6035bf6fa030ac069a0bd2e3d1e4b0efc60acd01",
        "edition": 1046,
        "date": 1654568048943,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1046",
        "rarity_score": 75.77537411603839,
        "rarity_level": "COMMON"
    },
    "1047": {
        "name": "80's Arkade Bulls #1047",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1047.png",
        "dna": "c73dcf46157b72004d26159c8ba43e1fa7a428a9",
        "edition": 1047,
        "date": 1654567871711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1047",
        "rarity_score": 99.68690718789881,
        "rarity_level": "COMMON"
    },
    "1048": {
        "name": "80's Arkade Bulls #1048",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1048.png",
        "dna": "b0f16db85eee9c871593449417a264610ef2bcb2",
        "edition": 1048,
        "date": 1654567823032,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "1048",
        "rarity_score": 167.36974848377633,
        "rarity_level": "COMMON"
    },
    "1049": {
        "name": "80's Arkade Bulls #1049",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1049.png",
        "dna": "47e019a75beb7b5bcc45c1136cbde61db2b9df39",
        "edition": 1049,
        "date": 1654567385473,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1049",
        "rarity_score": 175.58757826876453,
        "rarity_level": "COMMON"
    },
    "1050": {
        "name": "80's Arkade Bulls #1050",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1050.png",
        "dna": "9f3dfb8a545e517a7b0d20a2037d7d8b27ec801b",
        "edition": 1050,
        "date": 1654567660560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.285714285714286,
        "id": "1050",
        "rarity_score": 111.92064708901846,
        "rarity_level": "COMMON"
    },
    "1051": {
        "name": "80's Arkade Bulls #1051",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1051.png",
        "dna": "ea5d9a21e01b46ff1311f689af7afa28e9a2f2ef",
        "edition": 1051,
        "date": 1654567551186,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "1051",
        "rarity_score": 171.77463159176713,
        "rarity_level": "COMMON"
    },
    "1052": {
        "name": "80's Arkade Bulls #1052",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1052.png",
        "dna": "857019de8a6bf96efd8c908f36addd08469f6a54",
        "edition": 1052,
        "date": 1654567890705,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1052",
        "rarity_score": 98.7199512317753,
        "rarity_level": "COMMON"
    },
    "1053": {
        "name": "80's Arkade Bulls #1053",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1053.png",
        "dna": "94d21cb187608b40167d9e3590c771746d038c03",
        "edition": 1053,
        "date": 1654568137297,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1053",
        "rarity_score": 69.19324409697545,
        "rarity_level": "COMMON"
    },
    "1054": {
        "name": "80's Arkade Bulls #1054",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1054.png",
        "dna": "969f8e312874968d9cd937f7eb24ab65127c00de",
        "edition": 1054,
        "date": 1654567451625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1054",
        "rarity_score": 155.07783070350834,
        "rarity_level": "COMMON"
    },
    "1055": {
        "name": "80's Arkade Bulls #1055",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1055.png",
        "dna": "78a45de1784c05fe92f50395fc4c647f5a0b38cd",
        "edition": 1055,
        "date": 1654567602807,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.625,
        "id": "1055",
        "rarity_score": 113.90562925879709,
        "rarity_level": "COMMON"
    },
    "1056": {
        "name": "80's Arkade Bulls #1056",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1056.png",
        "dna": "eb6ec818dad34584cef51d382eee8e0af96c5aee",
        "edition": 1056,
        "date": 1654567632202,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Epic",
        "rarity": 4.25,
        "id": "1056",
        "rarity_score": 189.18331014310388,
        "rarity_level": "COMMON"
    },
    "1057": {
        "name": "80's Arkade Bulls #1057",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1057.png",
        "dna": "07b4317d4cc6368396a142fd0fd5cfddd70b3530",
        "edition": 1057,
        "date": 1654567184460,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "1057",
        "rarity_score": 192.4314411263564,
        "rarity_level": "COMMON"
    },
    "1058": {
        "name": "80's Arkade Bulls #1058",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1058.png",
        "dna": "164409025d30e49db35925b53980811c60dd18c7",
        "edition": 1058,
        "date": 1654568076688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1058",
        "rarity_score": 66.84147774252995,
        "rarity_level": "COMMON"
    },
    "1059": {
        "name": "80's Arkade Bulls #1059",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1059.png",
        "dna": "83f9bc63a3b06d8fe616c98f5cdb6a56016d85be",
        "edition": 1059,
        "date": 1654567211003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.166666666666666,
        "id": "1059",
        "rarity_score": 73.60422097690201,
        "rarity_level": "COMMON"
    },
    "1060": {
        "name": "80's Arkade Bulls #1060",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1060.png",
        "dna": "6bd6fca348608bb37656721f6c783d7e2b7a74b7",
        "edition": 1060,
        "date": 1654568026472,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1060",
        "rarity_score": 76.00604467581621,
        "rarity_level": "COMMON"
    },
    "1061": {
        "name": "80's Arkade Bulls #1061",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1061.png",
        "dna": "8d749dabd4416f9b5dfb6fe2fd0e8f00a07bd7dc",
        "edition": 1061,
        "date": 1654568200912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1061",
        "rarity_score": 88.93966311774989,
        "rarity_level": "COMMON"
    },
    "1062": {
        "name": "80's Arkade Bulls #1062",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1062.png",
        "dna": "978729795a69f3175bc5b5eb487e38a4d07d1b01",
        "edition": 1062,
        "date": 1654568157203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1062",
        "rarity_score": 150.28344050753097,
        "rarity_level": "COMMON"
    },
    "1063": {
        "name": "80's Arkade Bulls #1063",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1063.png",
        "dna": "d94ee67ebe4e1b1a7019a44356591418ac6134f2",
        "edition": 1063,
        "date": 1654568108425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1063",
        "rarity_score": 67.14338681552427,
        "rarity_level": "COMMON"
    },
    "1064": {
        "name": "80's Arkade Bulls #1064",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1064.png",
        "dna": "7aca7b19682317449e1de6a3e73886aa785fa986",
        "edition": 1064,
        "date": 1654567300334,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1064",
        "rarity_score": 95.96714655615799,
        "rarity_level": "COMMON"
    },
    "1065": {
        "name": "80's Arkade Bulls #1065",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1065.png",
        "dna": "2de6a65bbe0540e194b8ef1d0a2ca4f80db919a9",
        "edition": 1065,
        "date": 1654567656552,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1065",
        "rarity_score": 237.6703793438387,
        "rarity_level": "RARE"
    },
    "1066": {
        "name": "80's Arkade Bulls #1066",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1066.png",
        "dna": "fd775ec372b0d308e7a3364bebce39a13c8365fb",
        "edition": 1066,
        "date": 1654568045693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1066",
        "rarity_score": 69.04018385104254,
        "rarity_level": "COMMON"
    },
    "1067": {
        "name": "80's Arkade Bulls #1067",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1067.png",
        "dna": "94165a16fd7a9bd48464e1b69d0c999c8e8add8b",
        "edition": 1067,
        "date": 1654567415152,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1067",
        "rarity_score": 138.03227347752312,
        "rarity_level": "COMMON"
    },
    "1068": {
        "name": "80's Arkade Bulls #1068",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1068.png",
        "dna": "debd58f575f0a58011ea2c1b26dc70d2547b8f27",
        "edition": 1068,
        "date": 1654568178514,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1068",
        "rarity_score": 104.59595372707572,
        "rarity_level": "COMMON"
    },
    "1069": {
        "name": "80's Arkade Bulls #1069",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1069.png",
        "dna": "74e4dd1fbca63e168f97f01b368f2a6597cd31d1",
        "edition": 1069,
        "date": 1654567531406,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1069",
        "rarity_score": 169.93228685613212,
        "rarity_level": "COMMON"
    },
    "1070": {
        "name": "80's Arkade Bulls #1070",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1070.png",
        "dna": "cf01c8368e70c6721516a768e6772b8d60f69135",
        "edition": 1070,
        "date": 1654567345976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rarity": 5.444444444444445,
        "rank": "Rare",
        "id": "1070",
        "rarity_score": 217.47096781874683,
        "rarity_level": "RARE"
    },
    "1071": {
        "name": "80's Arkade Bulls #1071",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1071.png",
        "dna": "91108f36bd1934689a6b9f6a680f27d96fe767fc",
        "edition": 1071,
        "date": 1654567334624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "1071",
        "rarity_score": 191.70058798345264,
        "rarity_level": "COMMON"
    },
    "1072": {
        "name": "80's Arkade Bulls #1072",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1072.png",
        "dna": "be4f124f179061f6c8ea62c08fa41121392d868e",
        "edition": 1072,
        "date": 1654568141559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1072",
        "rarity_score": 86.10865587614356,
        "rarity_level": "COMMON"
    },
    "1073": {
        "name": "80's Arkade Bulls #1073",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1073.png",
        "dna": "44809d7971bf7ee19e8c32e746f9854b8cc65951",
        "edition": 1073,
        "date": 1654567472444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1073",
        "rarity_score": 208.4324200162709,
        "rarity_level": "RARE"
    },
    "1074": {
        "name": "80's Arkade Bulls #1074",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1074.png",
        "dna": "394e6798ae7d5a6f810f8fe77172777f03667ef1",
        "edition": 1074,
        "date": 1654567167422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Rare",
        "rarity": 5.2,
        "id": "1074",
        "rarity_score": 100.86210940281536,
        "rarity_level": "COMMON"
    },
    "1075": {
        "name": "80's Arkade Bulls #1075",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1075.png",
        "dna": "6d85385b8e8f28642caba022d15623067ca66414",
        "edition": 1075,
        "date": 1654567954203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1075",
        "rarity_score": 103.67720837241386,
        "rarity_level": "COMMON"
    },
    "1076": {
        "name": "80's Arkade Bulls #1076",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1076.png",
        "dna": "e10b2f909b0e38bf9803ae51654863b9d6079650",
        "edition": 1076,
        "date": 1654568233098,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Super",
        "rarity": 2.6,
        "id": "1076",
        "rarity_score": 238.91772545963966,
        "rarity_level": "RARE"
    },
    "1077": {
        "name": "80's Arkade Bulls #1077",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1077.png",
        "dna": "c55531667cb89465179c24547b7436b418aa1b8a",
        "edition": 1077,
        "date": 1654567194697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1077",
        "rarity_score": 76.63552275900945,
        "rarity_level": "COMMON"
    },
    "1078": {
        "name": "80's Arkade Bulls #1078",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1078.png",
        "dna": "1460ad6d97cb1d940aec1c044b91dfa697f51e51",
        "edition": 1078,
        "date": 1654567491559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1078",
        "rarity_score": 169.93244326761717,
        "rarity_level": "COMMON"
    },
    "1079": {
        "name": "80's Arkade Bulls #1079",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1079.png",
        "dna": "3a4e790c49cbe06779685912d027be6e11b8d9b0",
        "edition": 1079,
        "date": 1654567363631,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.555555555555555,
        "id": "1079",
        "rarity_score": 219.61619156878223,
        "rarity_level": "RARE"
    },
    "1080": {
        "name": "80's Arkade Bulls #1080",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1080.png",
        "dna": "f77c43610e01733ed9d23d93db151637a703dd9d",
        "edition": 1080,
        "date": 1654568100511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1080",
        "rarity_score": 58.154481031719364,
        "rarity_level": "COMMON"
    },
    "1081": {
        "name": "80's Arkade Bulls #1081",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1081.png",
        "dna": "8f82c419bf2560a172c25e6f6a375d1d5ee40bbe",
        "edition": 1081,
        "date": 1654568150633,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1081",
        "rarity_score": 155.26137880097747,
        "rarity_level": "COMMON"
    },
    "1082": {
        "name": "80's Arkade Bulls #1082",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1082.png",
        "dna": "c63b67a763908305310ba2d23e3b2b3ebde9bd7b",
        "edition": 1082,
        "date": 1654567821743,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666666,
        "id": "1082",
        "rarity_score": 169.03325051648218,
        "rarity_level": "COMMON"
    },
    "1083": {
        "name": "80's Arkade Bulls #1083",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1083.png",
        "dna": "f3e8698c4ad8d9a784fdd8c7e42eafe18e16327f",
        "edition": 1083,
        "date": 1654567168744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "1083",
        "rarity_score": 72.3335772690904,
        "rarity_level": "COMMON"
    },
    "1084": {
        "name": "80's Arkade Bulls #1084",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1084.png",
        "dna": "c05521f723fa28d71cd29ef0cca1edc1039af742",
        "edition": 1084,
        "date": 1654567167913,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Epic",
        "rarity": 4.2,
        "id": "1084",
        "rarity_score": 115.22117421183353,
        "rarity_level": "COMMON"
    },
    "1085": {
        "name": "80's Arkade Bulls #1085",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1085.png",
        "dna": "001998663ff72ccb0c64f234e759ea79abe6de27",
        "edition": 1085,
        "date": 1654567198881,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1085",
        "rarity_score": 69.25083386293737,
        "rarity_level": "COMMON"
    },
    "1086": {
        "name": "80's Arkade Bulls #1086",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1086.png",
        "dna": "cd2dc28210fedf3d6e565158c496ed9e45025475",
        "edition": 1086,
        "date": 1654567655463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "1086",
        "rarity_score": 175.73125606891946,
        "rarity_level": "COMMON"
    },
    "1087": {
        "name": "80's Arkade Bulls #1087",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1087.png",
        "dna": "6c30e0492bf55fb89bdc2a219eaa87b400ceca36",
        "edition": 1087,
        "date": 1654567599394,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1087",
        "rarity_score": 151.14278839337513,
        "rarity_level": "COMMON"
    },
    "1088": {
        "name": "80's Arkade Bulls #1088",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1088.png",
        "dna": "e7147dd5210bda287f63d87a4fa5b554f85090c6",
        "edition": 1088,
        "date": 1654567464541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1088",
        "rarity_score": 94.29977978569556,
        "rarity_level": "COMMON"
    },
    "1089": {
        "name": "80's Arkade Bulls #1089",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1089.png",
        "dna": "7e55cc206936dd8ccb4e9b1437fdca29b097accf",
        "edition": 1089,
        "date": 1654568218396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Super",
        "rarity": 3.166666666666667,
        "id": "1089",
        "rarity_score": 208.2147785616833,
        "rarity_level": "RARE"
    },
    "1090": {
        "name": "80's Arkade Bulls #1090",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1090.png",
        "dna": "f00709d830c5939f451ba40cb4f3036a69d1d48a",
        "edition": 1090,
        "date": 1654567843551,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "1090",
        "rarity_score": 113.205039902256,
        "rarity_level": "COMMON"
    },
    "1091": {
        "name": "80's Arkade Bulls #1091",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1091.png",
        "dna": "b4e092cd6ef46ab14733aad54f649d20c9877ae9",
        "edition": 1091,
        "date": 1654567961667,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1091",
        "rarity_score": 115.41296141483988,
        "rarity_level": "COMMON"
    },
    "1092": {
        "name": "80's Arkade Bulls #1092",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1092.png",
        "dna": "7b05d43d0d97f1acc150129136fe2b2c5a328c06",
        "edition": 1092,
        "date": 1654567176118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1092",
        "rarity_score": 110.46993664195074,
        "rarity_level": "COMMON"
    },
    "1093": {
        "name": "80's Arkade Bulls #1093",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1093.png",
        "dna": "fba9be113e20104e9f07619d7b45b5a4fc6c2a54",
        "edition": 1093,
        "date": 1654567946169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1093",
        "rarity_score": 142.37653168864898,
        "rarity_level": "COMMON"
    },
    "1094": {
        "name": "80's Arkade Bulls #1094",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1094.png",
        "dna": "a3f8a9234dc2c16153382682e0fbc96304ca9636",
        "edition": 1094,
        "date": 1654567679850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "1094",
        "rarity_score": 117.71685766649526,
        "rarity_level": "COMMON"
    },
    "1095": {
        "name": "80's Arkade Bulls #1095",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1095.png",
        "dna": "39df8c6f3aa6d1d15ae7c42d262d794eb2f6dad0",
        "edition": 1095,
        "date": 1654567283342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1095",
        "rarity_score": 162.46591371591373,
        "rarity_level": "COMMON"
    },
    "1096": {
        "name": "80's Arkade Bulls #1096",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1096.png",
        "dna": "ac6ebbb380c1d823a419332e41f02ce3f8ad7a8c",
        "edition": 1096,
        "date": 1654567537508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1096",
        "rarity_score": 160.06759431747963,
        "rarity_level": "COMMON"
    },
    "1097": {
        "name": "80's Arkade Bulls #1097",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1097.png",
        "dna": "8d33380b9770640593ca4e6f425152f09d71d44d",
        "edition": 1097,
        "date": 1654567526492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1097",
        "rarity_score": 219.95065137813478,
        "rarity_level": "RARE"
    },
    "1098": {
        "name": "80's Arkade Bulls #1098",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1098.png",
        "dna": "4da2eea823e9950a0c5d14b75a85d98722b487e3",
        "edition": 1098,
        "date": 1654567387952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1098",
        "rarity_score": 112.11977823498007,
        "rarity_level": "COMMON"
    },
    "1099": {
        "name": "80's Arkade Bulls #1099",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1099.png",
        "dna": "be308f1bcdf0e0642bc6e20212587bc7a46ece06",
        "edition": 1099,
        "date": 1654567982394,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1099",
        "rarity_score": 150.0532525042594,
        "rarity_level": "COMMON"
    },
    "1100": {
        "name": "80's Arkade Bulls #1100",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1100.png",
        "dna": "8fd01e74e44aa950f18f5b76c727132b22e02bb9",
        "edition": 1100,
        "date": 1654568145102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1100",
        "rarity_score": 156.8141644110579,
        "rarity_level": "COMMON"
    },
    "1101": {
        "name": "80's Arkade Bulls #1101",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1101.png",
        "dna": "7c3cd331e8573bb51a8323279b1ba2490abb4493",
        "edition": 1101,
        "date": 1654567898710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "1101",
        "rarity_score": 163.5463758505884,
        "rarity_level": "COMMON"
    },
    "1102": {
        "name": "80's Arkade Bulls #1102",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1102.png",
        "dna": "4520398061ad0c8a3e5260f2ea312584389b3dbe",
        "edition": 1102,
        "date": 1654567266511,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "1102",
        "rarity_score": 103.03192385380034,
        "rarity_level": "COMMON"
    },
    "1103": {
        "name": "80's Arkade Bulls #1103",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1103.png",
        "dna": "418f8ddbbd5f9d5165d5ef1416025d22dd9f5a30",
        "edition": 1103,
        "date": 1654567646185,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.875,
        "id": "1103",
        "rarity_score": 95.99690426062112,
        "rarity_level": "COMMON"
    },
    "1104": {
        "name": "80's Arkade Bulls #1104",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1104.png",
        "dna": "3e6d5830874fa22f4f24f24f26ccf32fb2ca8bc3",
        "edition": 1104,
        "date": 1654567249532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1104",
        "rarity_score": 106.22503579871172,
        "rarity_level": "COMMON"
    },
    "1105": {
        "name": "80's Arkade Bulls #1105",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1105.png",
        "dna": "25120cbed7e7a616ba0d6b715c102010a36e2c47",
        "edition": 1105,
        "date": 1654568023614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1105",
        "rarity_score": 69.66788424062658,
        "rarity_level": "COMMON"
    },
    "1106": {
        "name": "80's Arkade Bulls #1106",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1106.png",
        "dna": "d9437b1d5a0357d7d8c085dc964a9ae946792b30",
        "edition": 1106,
        "date": 1654567281188,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1106",
        "rarity_score": 118.88033411337702,
        "rarity_level": "COMMON"
    },
    "1107": {
        "name": "80's Arkade Bulls #1107",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1107.png",
        "dna": "7258a9968123c2c32ca1b4f13073baf6f5f64b39",
        "edition": 1107,
        "date": 1654567428674,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1107",
        "rarity_score": 131.18808359288192,
        "rarity_level": "COMMON"
    },
    "1108": {
        "name": "80's Arkade Bulls #1108",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1108.png",
        "dna": "01d953f33932a28e4008ce82fc611aca2b4dfb4f",
        "edition": 1108,
        "date": 1654567973056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1108",
        "rarity_score": 133.7573000493168,
        "rarity_level": "COMMON"
    },
    "1109": {
        "name": "80's Arkade Bulls #1109",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1109.png",
        "dna": "68186dd9a3290f08c25073a7fd5c0d0f6fe38480",
        "edition": 1109,
        "date": 1654568032508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1109",
        "rarity_score": 94.26161795053945,
        "rarity_level": "COMMON"
    },
    "1110": {
        "name": "80's Arkade Bulls #1110",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1110.png",
        "dna": "eae51d3208043e07ca5c957b9c1ace43649ee91d",
        "edition": 1110,
        "date": 1654567680387,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "1110",
        "rarity_score": 98.038036752116,
        "rarity_level": "COMMON"
    },
    "1111": {
        "name": "80's Arkade Bulls #1111",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1111.png",
        "dna": "7bb8d5718629b6b587a08e22020fc15294f9b4e6",
        "edition": 1111,
        "date": 1654567440982,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1111",
        "rarity_score": 147.45662954986216,
        "rarity_level": "COMMON"
    },
    "1112": {
        "name": "80's Arkade Bulls #1112",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1112.png",
        "dna": "0ccd7165678ac722ba7abf5d5894454b89654326",
        "edition": 1112,
        "date": 1654567216682,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1112",
        "rarity_score": 119.01279213097342,
        "rarity_level": "COMMON"
    },
    "1113": {
        "name": "80's Arkade Bulls #1113",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1113.png",
        "dna": "e7586708db0ad9dbd8833c3a4d6ccba35286fa14",
        "edition": 1113,
        "date": 1654567707652,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.333333333333334,
        "id": "1113",
        "rarity_score": 74.82015417271026,
        "rarity_level": "COMMON"
    },
    "1114": {
        "name": "80's Arkade Bulls #1114",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1114.png",
        "dna": "17cd6dd64d98834369f4b432370b455ad6e35acf",
        "edition": 1114,
        "date": 1654567190052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1114",
        "rarity_score": 87.44917065820097,
        "rarity_level": "COMMON"
    },
    "1115": {
        "name": "80's Arkade Bulls #1115",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1115.png",
        "dna": "4de3a7d30c81df858662241e34cf15c054865872",
        "edition": 1115,
        "date": 1654567165571,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1115",
        "rarity_score": 88.2051940932607,
        "rarity_level": "COMMON"
    },
    "1116": {
        "name": "80's Arkade Bulls #1116",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1116.png",
        "dna": "3d235ba499259adb4dda3a7eade049045252fe5a",
        "edition": 1116,
        "date": 1654567688815,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1116",
        "rarity_score": 130.8572356053485,
        "rarity_level": "COMMON"
    },
    "1117": {
        "name": "80's Arkade Bulls #1117",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1117.png",
        "dna": "267efd71e0d91642e182ca060e35cc7699322bb9",
        "edition": 1117,
        "date": 1654567628325,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.75,
        "id": "1117",
        "rarity_score": 99.62677939801921,
        "rarity_level": "COMMON"
    },
    "1118": {
        "name": "80's Arkade Bulls #1118",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1118.png",
        "dna": "a9977dc2cc6874f11e89ee2e0d5611a8a18a6ef5",
        "edition": 1118,
        "date": 1654567325573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1118",
        "rarity_score": 225.51627262580158,
        "rarity_level": "RARE"
    },
    "1119": {
        "name": "80's Arkade Bulls #1119",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1119.png",
        "dna": "f084f0a2fb87124bc87eff23ff5a80ae3533702b",
        "edition": 1119,
        "date": 1654568195178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1119",
        "rarity_score": 215.00015676057433,
        "rarity_level": "RARE"
    },
    "1120": {
        "name": "80's Arkade Bulls #1120",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1120.png",
        "dna": "ad73fbeac8ea536df2f6f838920fdf4a3d8c8cec",
        "edition": 1120,
        "date": 1654567970023,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1120",
        "rarity_score": 94.9370652098569,
        "rarity_level": "COMMON"
    },
    "1121": {
        "name": "80's Arkade Bulls #1121",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1121.png",
        "dna": "b3aa8d22eca46fa5d0f979201ac4ef8b85cc2e7d",
        "edition": 1121,
        "date": 1654567311578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1121",
        "rarity_score": 212.7051397948872,
        "rarity_level": "RARE"
    },
    "1122": {
        "name": "80's Arkade Bulls #1122",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1122.png",
        "dna": "874cd1cfae37bfe7e32f6ba50b0d214b43712258",
        "edition": 1122,
        "date": 1654567166957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1122",
        "rarity_score": 88.54204634558855,
        "rarity_level": "COMMON"
    },
    "1123": {
        "name": "80's Arkade Bulls #1123",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1123.png",
        "dna": "cad7655fcea6d8b404fb200e489c9507d9737f93",
        "edition": 1123,
        "date": 1654567273003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1123",
        "rarity_score": 175.84213937673798,
        "rarity_level": "COMMON"
    },
    "1124": {
        "name": "80's Arkade Bulls #1124",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1124.png",
        "dna": "af203e2ebf4245e57eb8ccc7a43bd4437ca3956a",
        "edition": 1124,
        "date": 1654568003271,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1124",
        "rarity_score": 158.11116091556656,
        "rarity_level": "COMMON"
    },
    "1125": {
        "name": "80's Arkade Bulls #1125",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1125.png",
        "dna": "641739c2bb88ef3f4a4ba299b8974c2a112c190f",
        "edition": 1125,
        "date": 1654567314275,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Super",
        "rarity": 3.4444444444444446,
        "id": "1125",
        "rarity_score": 303.9219619289796,
        "rarity_level": "RARE"
    },
    "1126": {
        "name": "80's Arkade Bulls #1126",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1126.png",
        "dna": "1bba83f6a84272b5d1d4aa648c4580e884f7e980",
        "edition": 1126,
        "date": 1654567939456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1126",
        "rarity_score": 77.62642288927407,
        "rarity_level": "COMMON"
    },
    "1127": {
        "name": "80's Arkade Bulls #1127",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1127.png",
        "dna": "82f66b65cb044068925928f8174e9b2e0ed6ff4a",
        "edition": 1127,
        "date": 1654567550621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1127",
        "rarity_score": 149.08227511886196,
        "rarity_level": "COMMON"
    },
    "1128": {
        "name": "80's Arkade Bulls #1128",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1128.png",
        "dna": "4586d2215757e90d1bb8bf3f5e94a150b6b17737",
        "edition": 1128,
        "date": 1654567229770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1128",
        "rarity_score": 86.72760977310742,
        "rarity_level": "COMMON"
    },
    "1129": {
        "name": "80's Arkade Bulls #1129",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1129.png",
        "dna": "47dd633036b4dce6484297cdd3fe0d02c60f7e29",
        "edition": 1129,
        "date": 1654567121137,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1129",
        "rarity_score": 99.69904113155154,
        "rarity_level": "COMMON"
    },
    "1130": {
        "name": "80's Arkade Bulls #1130",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1130.png",
        "dna": "93c35ad99efa903b95ed83b1e876a70d6eda2b2d",
        "edition": 1130,
        "date": 1654567372258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1130",
        "rarity_score": 332.1798967239911,
        "rarity_level": "RARE"
    },
    "1131": {
        "name": "80's Arkade Bulls #1131",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1131.png",
        "dna": "ddaf94a241fb490e73e2eced8115c0fa26120667",
        "edition": 1131,
        "date": 1654568099592,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1131",
        "rarity_score": 92.7398843174846,
        "rarity_level": "COMMON"
    },
    "1132": {
        "name": "80's Arkade Bulls #1132",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1132.png",
        "dna": "1a28109b3847888126d5d40efb042a7814a4a877",
        "edition": 1132,
        "date": 1654567384001,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1132",
        "rarity_score": 213.17406433099714,
        "rarity_level": "RARE"
    },
    "1133": {
        "name": "80's Arkade Bulls #1133",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1133.png",
        "dna": "b66e8092cb1b7fdedae159d0d08bd9c13bd49e1e",
        "edition": 1133,
        "date": 1654567872215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "1133",
        "rarity_score": 191.75101026226446,
        "rarity_level": "COMMON"
    },
    "1134": {
        "name": "80's Arkade Bulls #1134",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1134.png",
        "dna": "df20a8bf908bdd90182343d8d2c3ad6ca245a763",
        "edition": 1134,
        "date": 1654567375617,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1134",
        "rarity_score": 112.35702802533159,
        "rarity_level": "COMMON"
    },
    "1135": {
        "name": "80's Arkade Bulls #1135",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1135.png",
        "dna": "be18c0f606893184de66d1168cc02f6068a35c04",
        "edition": 1135,
        "date": 1654567658288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1135",
        "rarity_score": 239.7840917633964,
        "rarity_level": "RARE"
    },
    "1136": {
        "name": "80's Arkade Bulls #1136",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1136.png",
        "dna": "70613def7395f741c0a371b485a43beb1620a000",
        "edition": 1136,
        "date": 1654567392575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.166666666666667,
        "id": "1136",
        "rarity_score": 188.70711741432285,
        "rarity_level": "COMMON"
    },
    "1137": {
        "name": "80's Arkade Bulls #1137",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1137.png",
        "dna": "730d752ab83dbcff51e2b136b0335cf47fee10f4",
        "edition": 1137,
        "date": 1654567387003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 2.166666666666667,
        "id": "1137",
        "rarity_score": 248.5592398373266,
        "rarity_level": "RARE"
    },
    "1138": {
        "name": "80's Arkade Bulls #1138",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1138.png",
        "dna": "26854d67a3bb5270ab8eee7228a64405e9221089",
        "edition": 1138,
        "date": 1654568019578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 6.166666666666667,
        "rank": "Common",
        "id": "1138",
        "rarity_score": 131.70973812356888,
        "rarity_level": "COMMON"
    },
    "1139": {
        "name": "80's Arkade Bulls #1139",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1139.png",
        "dna": "01798cbc37e16524b42da25470145a595651456b",
        "edition": 1139,
        "date": 1654567585212,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1139",
        "rarity_score": 177.0529547314925,
        "rarity_level": "COMMON"
    },
    "1140": {
        "name": "80's Arkade Bulls #1140",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1140.png",
        "dna": "caf4d7f9793729a8c0277620ca9b72c94ea8dcdb",
        "edition": 1140,
        "date": 1654567908925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857142,
        "id": "1140",
        "rarity_score": 127.5083558902403,
        "rarity_level": "COMMON"
    },
    "1141": {
        "name": "80's Arkade Bulls #1141",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1141.png",
        "dna": "a0d34b4b25a4c51567fd54874bed59c2756063ac",
        "edition": 1141,
        "date": 1654567602171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1141",
        "rarity_score": 135.82056824419163,
        "rarity_level": "COMMON"
    },
    "1142": {
        "name": "80's Arkade Bulls #1142",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1142.png",
        "dna": "cab9427ef5fcd015aa5aaac4edb262e4ef7dbaad",
        "edition": 1142,
        "date": 1654567614318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1142",
        "rarity_score": 115.99237927119262,
        "rarity_level": "COMMON"
    },
    "1143": {
        "name": "80's Arkade Bulls #1143",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1143.png",
        "dna": "d13d46bf99dfdf145c25c9775cf37b07535f5064",
        "edition": 1143,
        "date": 1654567416120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1143",
        "rarity_score": 149.11728594157566,
        "rarity_level": "COMMON"
    },
    "1144": {
        "name": "80's Arkade Bulls #1144",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1144.png",
        "dna": "32f9c162f254d7bcb9b2fbc5dde25c60caef2aca",
        "edition": 1144,
        "date": 1654567495327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "1144",
        "rarity_score": 157.59110641592355,
        "rarity_level": "COMMON"
    },
    "1145": {
        "name": "80's Arkade Bulls #1145",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1145.png",
        "dna": "4760709f4da02e6b9eb8ba2e485e9d4b0223ffe4",
        "edition": 1145,
        "date": 1654567217697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333334,
        "id": "1145",
        "rarity_score": 84.99645410108188,
        "rarity_level": "COMMON"
    },
    "1146": {
        "name": "80's Arkade Bulls #1146",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1146.png",
        "dna": "54547ac210ad4ca2102745c94a8b5c886e1eb467",
        "edition": 1146,
        "date": 1654567542939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1146",
        "rarity_score": 166.9948231384506,
        "rarity_level": "COMMON"
    },
    "1147": {
        "name": "80's Arkade Bulls #1147",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1147.png",
        "dna": "af1933fbda8d84bc51d90f117f7576a054d0b6d4",
        "edition": 1147,
        "date": 1654567929560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1147",
        "rarity_score": 88.94887932679899,
        "rarity_level": "COMMON"
    },
    "1148": {
        "name": "80's Arkade Bulls #1148",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1148.png",
        "dna": "e515e8418fd45c209a810b38743bed815f653abc",
        "edition": 1148,
        "date": 1654567696670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1148",
        "rarity_score": 112.08537476982626,
        "rarity_level": "COMMON"
    },
    "1149": {
        "name": "80's Arkade Bulls #1149",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1149.png",
        "dna": "f9e4fb5d6347c64bd9ecd8f73fff9865ea810898",
        "edition": 1149,
        "date": 1654567702729,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1149",
        "rarity_score": 113.04578639521861,
        "rarity_level": "COMMON"
    },
    "1150": {
        "name": "80's Arkade Bulls #1150",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1150.png",
        "dna": "fec9482400b2118c91b71297be583886f63b9aa7",
        "edition": 1150,
        "date": 1654567533093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 3.8571428571428577,
        "id": "1150",
        "rarity_score": 174.0349771752923,
        "rarity_level": "COMMON"
    },
    "1151": {
        "name": "80's Arkade Bulls #1151",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1151.png",
        "dna": "38f683eb35cd9564b59755c641e4b93325ac960f",
        "edition": 1151,
        "date": 1654567376677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1151",
        "rarity_score": 226.36308358960022,
        "rarity_level": "RARE"
    },
    "1152": {
        "name": "80's Arkade Bulls #1152",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1152.png",
        "dna": "d9514871a28bb9dec3eb487b88b782a032c9ddb5",
        "edition": 1152,
        "date": 1654568040243,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8,
        "id": "1152",
        "rarity_score": 154.1457268406421,
        "rarity_level": "COMMON"
    },
    "1153": {
        "name": "80's Arkade Bulls #1153",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1153.png",
        "dna": "95bde43cfd6d160a919cb85451f1f43f3f541b6d",
        "edition": 1153,
        "date": 1654567337425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.444444444444445,
        "id": "1153",
        "rarity_score": 201.29407906380354,
        "rarity_level": "COMMON"
    },
    "1154": {
        "name": "80's Arkade Bulls #1154",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1154.png",
        "dna": "634b2a67f21c3d0a43577105f5f9ebcdc322bb34",
        "edition": 1154,
        "date": 1654568181590,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1154",
        "rarity_score": 178.6201690209351,
        "rarity_level": "COMMON"
    },
    "1155": {
        "name": "80's Arkade Bulls #1155",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1155.png",
        "dna": "b65716c028fe159fb963487b8e31371af7a05c41",
        "edition": 1155,
        "date": 1654567925201,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1155",
        "rarity_score": 105.21028558802976,
        "rarity_level": "COMMON"
    },
    "1156": {
        "name": "80's Arkade Bulls #1156",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1156.png",
        "dna": "bb16aeb4ea8ada97858406c9e11886568fbce4af",
        "edition": 1156,
        "date": 1654567401974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.833333333333333,
        "id": "1156",
        "rarity_score": 168.58620689655174,
        "rarity_level": "COMMON"
    },
    "1157": {
        "name": "80's Arkade Bulls #1157",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1157.png",
        "dna": "ecb14b91d9a0c32c44d2f47ae3ecbad6b38b8b1d",
        "edition": 1157,
        "date": 1654567330794,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1157",
        "rarity_score": 245.25567239142399,
        "rarity_level": "RARE"
    },
    "1158": {
        "name": "80's Arkade Bulls #1158",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1158.png",
        "dna": "351bdda5b5db9fd6cdf786bcf3e36b532fc0a913",
        "edition": 1158,
        "date": 1654567837482,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333334,
        "id": "1158",
        "rarity_score": 130.10734698438225,
        "rarity_level": "COMMON"
    },
    "1159": {
        "name": "80's Arkade Bulls #1159",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1159.png",
        "dna": "41e2594918ebad8868a49aeb93c8226b11acb200",
        "edition": 1159,
        "date": 1654567827802,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "1159",
        "rarity_score": 68.47765987018248,
        "rarity_level": "COMMON"
    },
    "1160": {
        "name": "80's Arkade Bulls #1160",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1160.png",
        "dna": "1da92efe69e51f627d820f8def83e1609add83c9",
        "edition": 1160,
        "date": 1654567133747,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1160",
        "rarity_score": 84.30791356375678,
        "rarity_level": "COMMON"
    },
    "1161": {
        "name": "80's Arkade Bulls #1161",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1161.png",
        "dna": "6e7250e63546337ad92948f5904e6902ea4f23b5",
        "edition": 1161,
        "date": 1654567900284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "1161",
        "rarity_score": 195.11973458284314,
        "rarity_level": "COMMON"
    },
    "1162": {
        "name": "80's Arkade Bulls #1162",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1162.png",
        "dna": "fa42a84c6301518168f25c5a697b749ca6c84f35",
        "edition": 1162,
        "date": 1654567149116,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1162",
        "rarity_score": 87.26668414796461,
        "rarity_level": "COMMON"
    },
    "1163": {
        "name": "80's Arkade Bulls #1163",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1163.png",
        "dna": "d56987102847bd2f8f00a4482e72937ea38104cf",
        "edition": 1163,
        "date": 1654567339489,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1163",
        "rarity_score": 233.68883234496562,
        "rarity_level": "RARE"
    },
    "1164": {
        "name": "80's Arkade Bulls #1164",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1164.png",
        "dna": "07550e58d0f74810cf6cf8a709f5c2c179ec609f",
        "edition": 1164,
        "date": 1654568037146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 2.2,
        "id": "1164",
        "rarity_score": 226.70055724850246,
        "rarity_level": "RARE"
    },
    "1165": {
        "name": "80's Arkade Bulls #1165",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1165.png",
        "dna": "0e6ff65107343dd14755caeb603150af8748c578",
        "edition": 1165,
        "date": 1654567222136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1165",
        "rarity_score": 90.58368112916871,
        "rarity_level": "COMMON"
    },
    "1166": {
        "name": "80's Arkade Bulls #1166",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1166.png",
        "dna": "453a49835459304fbb69f3f5a69e5ac1b7812a80",
        "edition": 1166,
        "date": 1654567398015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rarity": 4.666666666666667,
        "rank": "Rare",
        "id": "1166",
        "rarity_score": 229.58885037256917,
        "rarity_level": "RARE"
    },
    "1167": {
        "name": "80's Arkade Bulls #1167",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1167.png",
        "dna": "bc439caf11a346d925cbd10596e9df5759ae8c41",
        "edition": 1167,
        "date": 1654567665050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1167",
        "rarity_score": 108.74437443257489,
        "rarity_level": "COMMON"
    },
    "1168": {
        "name": "80's Arkade Bulls #1168",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1168.png",
        "dna": "5ca926267298d09e8c9ed1bf92122039f802b42d",
        "edition": 1168,
        "date": 1654567882720,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1168",
        "rarity_score": 109.88564271091775,
        "rarity_level": "COMMON"
    },
    "1169": {
        "name": "80's Arkade Bulls #1169",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1169.png",
        "dna": "1d4520ebcdfdce4719b51571d7407ce173ef586b",
        "edition": 1169,
        "date": 1654567536947,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1169",
        "rarity_score": 165.7947106895008,
        "rarity_level": "COMMON"
    },
    "1170": {
        "name": "80's Arkade Bulls #1170",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1170.png",
        "dna": "9d233b529097373d16c59ae1cec1e5d0b79981cc",
        "edition": 1170,
        "date": 1654567436574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "1170",
        "rarity_score": 94.37039788857285,
        "rarity_level": "COMMON"
    },
    "1171": {
        "name": "80's Arkade Bulls #1171",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1171.png",
        "dna": "f33d05924177321757348656f65d922d2eb294a4",
        "edition": 1171,
        "date": 1654567879017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1171",
        "rarity_score": 108.56070435508592,
        "rarity_level": "COMMON"
    },
    "1172": {
        "name": "80's Arkade Bulls #1172",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1172.png",
        "dna": "7efd994c354ca6536d1df41e213a58ce6d71beea",
        "edition": 1172,
        "date": 1654567921937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1172",
        "rarity_score": 105.17992349909619,
        "rarity_level": "COMMON"
    },
    "1173": {
        "name": "80's Arkade Bulls #1173",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1173.png",
        "dna": "a5d2c479331ad0ddaf8cceb982cfcae8a991b5fe",
        "edition": 1173,
        "date": 1654567372942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.111111111111111,
        "id": "1173",
        "rarity_score": 268.6630373915661,
        "rarity_level": "RARE"
    },
    "1174": {
        "name": "80's Arkade Bulls #1174",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1174.png",
        "dna": "2772c6c384b8d42ae62cb5040b7efe9495824e38",
        "edition": 1174,
        "date": 1654567149990,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1174",
        "rarity_score": 205.14572684064208,
        "rarity_level": "RARE"
    },
    "1175": {
        "name": "80's Arkade Bulls #1175",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1175.png",
        "dna": "123ecdb0275b55563e2585f676d3380a1cf126b6",
        "edition": 1175,
        "date": 1654567122048,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1175",
        "rarity_score": 63.70795580192494,
        "rarity_level": "COMMON"
    },
    "1176": {
        "name": "80's Arkade Bulls #1176",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1176.png",
        "dna": "b23085f28acaf6c2fdd94e673b617a9bec7fd15f",
        "edition": 1176,
        "date": 1654567920790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1176",
        "rarity_score": 107.98381831707097,
        "rarity_level": "COMMON"
    },
    "1177": {
        "name": "80's Arkade Bulls #1177",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1177.png",
        "dna": "42324388aef0e786895045891b086348dbe3cffa",
        "edition": 1177,
        "date": 1654567630429,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.25,
        "id": "1177",
        "rarity_score": 119.5798129890436,
        "rarity_level": "COMMON"
    },
    "1178": {
        "name": "80's Arkade Bulls #1178",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1178.png",
        "dna": "b4d106f42a822367f7e37a0f2925722db628f63c",
        "edition": 1178,
        "date": 1654567445450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Super",
        "rarity": 3.1428571428571423,
        "id": "1178",
        "rarity_score": 264.3653482754629,
        "rarity_level": "RARE"
    },
    "1179": {
        "name": "80's Arkade Bulls #1179",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1179.png",
        "dna": "bd0d2a89b36dbbdd0d9727d6faaa5f488777bb3e",
        "edition": 1179,
        "date": 1654567858148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "1179",
        "rarity_score": 101.34429843829524,
        "rarity_level": "COMMON"
    },
    "1180": {
        "name": "80's Arkade Bulls #1180",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1180.png",
        "dna": "82b9c7a2ea2aac4b0133dc05dd3adea4725ae549",
        "edition": 1180,
        "date": 1654568181075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "1180",
        "rarity_score": 162.46232349676131,
        "rarity_level": "COMMON"
    },
    "1181": {
        "name": "80's Arkade Bulls #1181",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1181.png",
        "dna": "21fdd436d4c67b540e19b78f0a0db2d18008f2d3",
        "edition": 1181,
        "date": 1654568233595,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 1.4,
        "id": "1181",
        "rarity_score": 361.9725274725275,
        "rarity_level": "RARE"
    },
    "1182": {
        "name": "80's Arkade Bulls #1182",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1182.png",
        "dna": "d80257db79efdb0aeb419d446f4577d2721ce6ae",
        "edition": 1182,
        "date": 1654568030678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1182",
        "rarity_score": 81.55574738550307,
        "rarity_level": "COMMON"
    },
    "1183": {
        "name": "80's Arkade Bulls #1183",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1183.png",
        "dna": "1b908c8675f646583fc127a0f387bc85f8be8cc3",
        "edition": 1183,
        "date": 1654568221108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Super",
        "rarity": 1.6,
        "id": "1183",
        "rarity_score": 475.3778195488722,
        "rarity_level": "RARE"
    },
    "1184": {
        "name": "80's Arkade Bulls #1184",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1184.png",
        "dna": "f6929f6fb770fd3ebf840416c39c896747142dbd",
        "edition": 1184,
        "date": 1654567547928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "1184",
        "rarity_score": 228.7689347076257,
        "rarity_level": "RARE"
    },
    "1185": {
        "name": "80's Arkade Bulls #1185",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1185.png",
        "dna": "229e71fb070a6438a867b106080adc6ca1306462",
        "edition": 1185,
        "date": 1654567253326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666666,
        "id": "1185",
        "rarity_score": 204.16380875404943,
        "rarity_level": "RARE"
    },
    "1186": {
        "name": "80's Arkade Bulls #1186",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1186.png",
        "dna": "fb7d39cb050865c18e13fc4c3f100990de53b049",
        "edition": 1186,
        "date": 1654567309616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Epic",
        "rarity": 3.7777777777777777,
        "id": "1186",
        "rarity_score": 350.4607632027074,
        "rarity_level": "RARE"
    },
    "1187": {
        "name": "80's Arkade Bulls #1187",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1187.png",
        "dna": "fca71860781d9ea038bf0bf7ee8d5236f5b2a87d",
        "edition": 1187,
        "date": 1654567859642,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "1187",
        "rarity_score": 89.08036178533727,
        "rarity_level": "COMMON"
    },
    "1188": {
        "name": "80's Arkade Bulls #1188",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1188.png",
        "dna": "26c18518dc8e5efdecb1f1430ab7c6613939d12c",
        "edition": 1188,
        "date": 1654567293584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Super",
        "rarity": 2,
        "id": "1188",
        "rarity_score": 250.57716505305385,
        "rarity_level": "RARE"
    },
    "1189": {
        "name": "80's Arkade Bulls #1189",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1189.png",
        "dna": "fa23308c86a2e13ce87c369801cc2b63bbfb25db",
        "edition": 1189,
        "date": 1654568208180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1189",
        "rarity_score": 104.13322295583944,
        "rarity_level": "COMMON"
    },
    "1190": {
        "name": "80's Arkade Bulls #1190",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1190.png",
        "dna": "f11936979bc1b6cee8cccc9a8fd3577d0edf1a07",
        "edition": 1190,
        "date": 1654567550061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1190",
        "rarity_score": 194.04671873067483,
        "rarity_level": "COMMON"
    },
    "1191": {
        "name": "80's Arkade Bulls #1191",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1191.png",
        "dna": "1e30469af09fe84187dbf0ad2cc5f91379c858de",
        "edition": 1191,
        "date": 1654568035360,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1191",
        "rarity_score": 99.72378936112142,
        "rarity_level": "COMMON"
    },
    "1192": {
        "name": "80's Arkade Bulls #1192",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1192.png",
        "dna": "9683b046e1834432ac815cf61c7154d2912edfd8",
        "edition": 1192,
        "date": 1654568114542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1192",
        "rarity_score": 127.66412624966922,
        "rarity_level": "COMMON"
    },
    "1193": {
        "name": "80's Arkade Bulls #1193",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1193.png",
        "dna": "f8c6fe04b20f512d26d77cb573210e9fd4a8277a",
        "edition": 1193,
        "date": 1654567608326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.375,
        "id": "1193",
        "rarity_score": 153.04217663170897,
        "rarity_level": "COMMON"
    },
    "1194": {
        "name": "80's Arkade Bulls #1194",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1194.png",
        "dna": "2b0e74168088d2e831f7305ea1f9fbf8530b9ce9",
        "edition": 1194,
        "date": 1654568051209,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1194",
        "rarity_score": 72.11363445378151,
        "rarity_level": "COMMON"
    },
    "1195": {
        "name": "80's Arkade Bulls #1195",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1195.png",
        "dna": "9a72a41b343ef7704bd57772fbc9bcbc7ba770e0",
        "edition": 1195,
        "date": 1654567451033,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1195",
        "rarity_score": 108.66048432226113,
        "rarity_level": "COMMON"
    },
    "1196": {
        "name": "80's Arkade Bulls #1196",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1196.png",
        "dna": "e43a85bca01e1c02755d7734d7884eb8c474038e",
        "edition": 1196,
        "date": 1654567191843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1196",
        "rarity_score": 211.85201024382587,
        "rarity_level": "RARE"
    },
    "1197": {
        "name": "80's Arkade Bulls #1197",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1197.png",
        "dna": "0ee0db91459b482379cf0812de19f6ef95bcd4be",
        "edition": 1197,
        "date": 1654567985894,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1197",
        "rarity_score": 155.53133065481734,
        "rarity_level": "COMMON"
    },
    "1198": {
        "name": "80's Arkade Bulls #1198",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1198.png",
        "dna": "17234f4120df3c8809bdfb65ab1679c3d8e75518",
        "edition": 1198,
        "date": 1654567689384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1198",
        "rarity_score": 126.00091292252694,
        "rarity_level": "COMMON"
    },
    "1199": {
        "name": "80's Arkade Bulls #1199",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1199.png",
        "dna": "304ceb0fe1df333f14dedfdbc4d75950c37cfd05",
        "edition": 1199,
        "date": 1654567289805,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1199",
        "rarity_score": 96.75940908780603,
        "rarity_level": "COMMON"
    },
    "1200": {
        "name": "80's Arkade Bulls #1200",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1200.png",
        "dna": "18f07066c79e522fc2bb6617e28b5d2fd766e608",
        "edition": 1200,
        "date": 1654567163758,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "1200",
        "rarity_score": 87.629733682394,
        "rarity_level": "COMMON"
    },
    "1201": {
        "name": "80's Arkade Bulls #1201",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1201.png",
        "dna": "cf20a680f4a22c564cbdc94288df93df86874f26",
        "edition": 1201,
        "date": 1654567957180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1201",
        "rarity_score": 83.66572684621914,
        "rarity_level": "COMMON"
    },
    "1202": {
        "name": "80's Arkade Bulls #1202",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1202.png",
        "dna": "07d647303443d360dd3b386d57924feba6cf25ae",
        "edition": 1202,
        "date": 1654567170877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1202",
        "rarity_score": 80.80316536739774,
        "rarity_level": "COMMON"
    },
    "1203": {
        "name": "80's Arkade Bulls #1203",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1203.png",
        "dna": "ea87cb29e8daf6578270a599c9462dd4e1b10e55",
        "edition": 1203,
        "date": 1654568147170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1203",
        "rarity_score": 223.88488811952135,
        "rarity_level": "RARE"
    },
    "1204": {
        "name": "80's Arkade Bulls #1204",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1204.png",
        "dna": "50c33adbe494f8ba2fdf1181bdfe643d5f54cded",
        "edition": 1204,
        "date": 1654567262370,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1204",
        "rarity_score": 155.15755890953994,
        "rarity_level": "COMMON"
    },
    "1205": {
        "name": "80's Arkade Bulls #1205",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1205.png",
        "dna": "dc30adbce798560725345867453189035666cc29",
        "edition": 1205,
        "date": 1654567264959,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1205",
        "rarity_score": 99.81723732096866,
        "rarity_level": "COMMON"
    },
    "1206": {
        "name": "80's Arkade Bulls #1206",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1206.png",
        "dna": "b977113c8f0242b044168e77d8ba2c43e8300bfb",
        "edition": 1206,
        "date": 1654567999249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1206",
        "rarity_score": 83.26643284328975,
        "rarity_level": "COMMON"
    },
    "1207": {
        "name": "80's Arkade Bulls #1207",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1207.png",
        "dna": "3baf27bf17cba4392ace60ce0286934927d3eccf",
        "edition": 1207,
        "date": 1654568231151,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Epic",
        "rarity": 3.8,
        "id": "1207",
        "rarity_score": 192.49009343690196,
        "rarity_level": "COMMON"
    },
    "1208": {
        "name": "80's Arkade Bulls #1208",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1208.png",
        "dna": "f212d0b1b68d5da71c39e79be7fb24d73cc1b289",
        "edition": 1208,
        "date": 1654567829013,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1208",
        "rarity_score": 106.94522810384007,
        "rarity_level": "COMMON"
    },
    "1209": {
        "name": "80's Arkade Bulls #1209",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1209.png",
        "dna": "e7238c479546769113862f00f974d2976cde0681",
        "edition": 1209,
        "date": 1654568013511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1209",
        "rarity_score": 87.47564068375522,
        "rarity_level": "COMMON"
    },
    "1210": {
        "name": "80's Arkade Bulls #1210",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1210.png",
        "dna": "d2b20a05842e03c3294c2bf1b725b5c01f28769d",
        "edition": 1210,
        "date": 1654567262886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1210",
        "rarity_score": 103.08352235474695,
        "rarity_level": "COMMON"
    },
    "1211": {
        "name": "80's Arkade Bulls #1211",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1211.png",
        "dna": "a8ab12b1dff6b100dfb8c9b102ac11e956db414d",
        "edition": 1211,
        "date": 1654567703834,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1211",
        "rarity_score": 142.78337854130768,
        "rarity_level": "COMMON"
    },
    "1212": {
        "name": "80's Arkade Bulls #1212",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1212.png",
        "dna": "537c5eab8d8ba1e04627581051dcfda54f1f119e",
        "edition": 1212,
        "date": 1654568125163,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1212",
        "rarity_score": 88.08180402429171,
        "rarity_level": "COMMON"
    },
    "1213": {
        "name": "80's Arkade Bulls #1213",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1213.png",
        "dna": "171d5a4d786d6931369598d30a265ad0be5cf796",
        "edition": 1213,
        "date": 1654567173308,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1213",
        "rarity_score": 74.16128533507397,
        "rarity_level": "COMMON"
    },
    "1214": {
        "name": "80's Arkade Bulls #1214",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1214.png",
        "dna": "c51eefd0510cc0013cdc49fbb3b1519b3e2a4819",
        "edition": 1214,
        "date": 1654567290858,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1214",
        "rarity_score": 78.22784073814061,
        "rarity_level": "COMMON"
    },
    "1215": {
        "name": "80's Arkade Bulls #1215",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1215.png",
        "dna": "6e495a119c39674b0488ddd0a8b3c229be476d40",
        "edition": 1215,
        "date": 1654567981915,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Epic",
        "rarity": 3.833333333333333,
        "id": "1215",
        "rarity_score": 150.59867443520255,
        "rarity_level": "COMMON"
    },
    "1216": {
        "name": "80's Arkade Bulls #1216",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1216.png",
        "dna": "417e6e8e954a02f81643ff50fccbe9c9a293ee80",
        "edition": 1216,
        "date": 1654567535851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.2857142857142865,
        "id": "1216",
        "rarity_score": 168.13284055904134,
        "rarity_level": "COMMON"
    },
    "1217": {
        "name": "80's Arkade Bulls #1217",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1217.png",
        "dna": "feda663beb8d1b01adcda5f37fee93726944d3ea",
        "edition": 1217,
        "date": 1654568106091,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1217",
        "rarity_score": 57.98330144877508,
        "rarity_level": "COMMON"
    },
    "1218": {
        "name": "80's Arkade Bulls #1218",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1218.png",
        "dna": "a096ed2bf1a7fcc5e259f5803b93b097250e4652",
        "edition": 1218,
        "date": 1654567507192,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1218",
        "rarity_score": 163.20591253000774,
        "rarity_level": "COMMON"
    },
    "1219": {
        "name": "80's Arkade Bulls #1219",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1219.png",
        "dna": "12cc7cf8e923dd69eb3a2ea36ba27c4140c8a351",
        "edition": 1219,
        "date": 1654567844419,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1219",
        "rarity_score": 87.84106768457099,
        "rarity_level": "COMMON"
    },
    "1220": {
        "name": "80's Arkade Bulls #1220",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1220.png",
        "dna": "afb70e2b977c44c22dfeac79e602a7042fc8de0b",
        "edition": 1220,
        "date": 1654567177942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1220",
        "rarity_score": 72.5411758406511,
        "rarity_level": "COMMON"
    },
    "1221": {
        "name": "80's Arkade Bulls #1221",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1221.png",
        "dna": "3d982a2ba95e4d125997dad716c668d53494ffa6",
        "edition": 1221,
        "date": 1654568228678,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Epic",
        "rarity": 3.5999999999999996,
        "id": "1221",
        "rarity_score": 226.903603959942,
        "rarity_level": "RARE"
    },
    "1222": {
        "name": "80's Arkade Bulls #1222",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1222.png",
        "dna": "ba627338431716f276556ec7b2bc83240a449ed6",
        "edition": 1222,
        "date": 1654567359079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "1222",
        "rarity_score": 193.26104621959706,
        "rarity_level": "COMMON"
    },
    "1223": {
        "name": "80's Arkade Bulls #1223",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1223.png",
        "dna": "7ff7873cd5442d80664ad5d553bd83e9992569b7",
        "edition": 1223,
        "date": 1654567523600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "1223",
        "rarity_score": 149.8075601378206,
        "rarity_level": "COMMON"
    },
    "1224": {
        "name": "80's Arkade Bulls #1224",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1224.png",
        "dna": "028e166eb32ca7a30c25d552654d79ec960cc4a3",
        "edition": 1224,
        "date": 1654567563313,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1224",
        "rarity_score": 180.90254324036428,
        "rarity_level": "COMMON"
    },
    "1225": {
        "name": "80's Arkade Bulls #1225",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1225.png",
        "dna": "4d19ad4ec4b05ca6834b3c4cc594c28980522fae",
        "edition": 1225,
        "date": 1654567411022,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1225",
        "rarity_score": 164.3415766233252,
        "rarity_level": "COMMON"
    },
    "1226": {
        "name": "80's Arkade Bulls #1226",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1226.png",
        "dna": "33d65db8a8622880725cd60986e92780f4bfad30",
        "edition": 1226,
        "date": 1654567409535,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1226",
        "rarity_score": 118.59820407543518,
        "rarity_level": "COMMON"
    },
    "1227": {
        "name": "80's Arkade Bulls #1227",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1227.png",
        "dna": "e60b3dcc58f116857ff149cf2daf67e83a0b9738",
        "edition": 1227,
        "date": 1654567817365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1227",
        "rarity_score": 68.70087835602017,
        "rarity_level": "COMMON"
    },
    "1228": {
        "name": "80's Arkade Bulls #1228",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1228.png",
        "dna": "fc0514b00ed93b371e12ea00d82095e25b537873",
        "edition": 1228,
        "date": 1654567454563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "1228",
        "rarity_score": 157.01414970284858,
        "rarity_level": "COMMON"
    },
    "1229": {
        "name": "80's Arkade Bulls #1229",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1229.png",
        "dna": "79a4e4cce5e8daddbff2e47d5f675097e98b3333",
        "edition": 1229,
        "date": 1654568034002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1229",
        "rarity_score": 146.87680296429494,
        "rarity_level": "COMMON"
    },
    "1230": {
        "name": "80's Arkade Bulls #1230",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1230.png",
        "dna": "e96f0b77475fd3741927e5fa522c81cbb23e62c3",
        "edition": 1230,
        "date": 1654567581776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1230",
        "rarity_score": 196.14883740473311,
        "rarity_level": "COMMON"
    },
    "1231": {
        "name": "80's Arkade Bulls #1231",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1231.png",
        "dna": "57f51a089828f4b72308d67cb61d35850c511413",
        "edition": 1231,
        "date": 1654567252610,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "1231",
        "rarity_score": 78.21286832198304,
        "rarity_level": "COMMON"
    },
    "1232": {
        "name": "80's Arkade Bulls #1232",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1232.png",
        "dna": "1233a1583a7e0b530b5e562ea215ff0e4ecb4175",
        "edition": 1232,
        "date": 1654568011966,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1232",
        "rarity_score": 80.5309924567085,
        "rarity_level": "COMMON"
    },
    "1233": {
        "name": "80's Arkade Bulls #1233",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1233.png",
        "dna": "22438a0337344b8c5ea89efeaddd7430acabd73d",
        "edition": 1233,
        "date": 1654568059026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "1233",
        "rarity_score": 91.09012178126132,
        "rarity_level": "COMMON"
    },
    "1234": {
        "name": "80's Arkade Bulls #1234",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1234.png",
        "dna": "1053bc4dd0695111bee570d021e8114eb24c250d",
        "edition": 1234,
        "date": 1654567863581,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1234",
        "rarity_score": 108.96972885086902,
        "rarity_level": "COMMON"
    },
    "1235": {
        "name": "80's Arkade Bulls #1235",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1235.png",
        "dna": "af0d06a0eff180eeff568e595dcb12ff65074624",
        "edition": 1235,
        "date": 1654567355061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.777777777777778,
        "id": "1235",
        "rarity_score": 193.81817557048367,
        "rarity_level": "COMMON"
    },
    "1236": {
        "name": "80's Arkade Bulls #1236",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1236.png",
        "dna": "b4161f23d4eb138e843a4500bbf37a1528f0d30a",
        "edition": 1236,
        "date": 1654567935629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "1236",
        "rarity_score": 107.89478487728614,
        "rarity_level": "COMMON"
    },
    "1237": {
        "name": "80's Arkade Bulls #1237",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1237.png",
        "dna": "ce152ebebaf777ea58f64addd6186baca9e0afe5",
        "edition": 1237,
        "date": 1654568060394,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1237",
        "rarity_score": 82.66216822726776,
        "rarity_level": "COMMON"
    },
    "1238": {
        "name": "80's Arkade Bulls #1238",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1238.png",
        "dna": "96dbca00cdfb00df40427d5d72d9baad0d80073c",
        "edition": 1238,
        "date": 1654567290342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1238",
        "rarity_score": 187.21207945431925,
        "rarity_level": "COMMON"
    },
    "1239": {
        "name": "80's Arkade Bulls #1239",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1239.png",
        "dna": "557882bbf9fd080c11b969f4574e010357357ade",
        "edition": 1239,
        "date": 1654568211767,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Pilot Goggles", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1239",
        "rarity_score": 129.54989968505538,
        "rarity_level": "COMMON"
    },
    "1240": {
        "name": "80's Arkade Bulls #1240",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1240.png",
        "dna": "10c6d0c567e8fa37ec64abfce3bd29770022751d",
        "edition": 1240,
        "date": 1654567966410,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1240",
        "rarity_score": 149.81535038932148,
        "rarity_level": "COMMON"
    },
    "1241": {
        "name": "80's Arkade Bulls #1241",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1241.png",
        "dna": "a2ce127c77dd5e0fc4f575e1693bb6728d3c390e",
        "edition": 1241,
        "date": 1654567889631,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1241",
        "rarity_score": 131.7564894070001,
        "rarity_level": "COMMON"
    },
    "1242": {
        "name": "80's Arkade Bulls #1242",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1242.png",
        "dna": "246765f6ad5621da9006b0a0bdd4092da083bf54",
        "edition": 1242,
        "date": 1654567239588,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1242",
        "rarity_score": 71.85376835430975,
        "rarity_level": "COMMON"
    },
    "1243": {
        "name": "80's Arkade Bulls #1243",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1243.png",
        "dna": "4031803d8f3ff41742151e8660ac08116fc00988",
        "edition": 1243,
        "date": 1654567625346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.375,
        "id": "1243",
        "rarity_score": 189.84198240815724,
        "rarity_level": "COMMON"
    },
    "1244": {
        "name": "80's Arkade Bulls #1244",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1244.png",
        "dna": "0cf4fc8c0de9366d8f3524d9c36bf752f9ba4420",
        "edition": 1244,
        "date": 1654568044810,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "1244",
        "rarity_score": 63.36423145643931,
        "rarity_level": "COMMON"
    },
    "1245": {
        "name": "80's Arkade Bulls #1245",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1245.png",
        "dna": "42a3e48b7e0e43910059fbba3a77848deb84c8df",
        "edition": 1245,
        "date": 1654567692757,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "1245",
        "rarity_score": 139.55159983094248,
        "rarity_level": "COMMON"
    },
    "1246": {
        "name": "80's Arkade Bulls #1246",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1246.png",
        "dna": "1c74ae60ca0b24930242f96895fc72b90ccc3272",
        "edition": 1246,
        "date": 1654568043887,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 9.8,
        "id": "1246",
        "rarity_score": 55.047508062326585,
        "rarity_level": "COMMON"
    },
    "1247": {
        "name": "80's Arkade Bulls #1247",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1247.png",
        "dna": "0b54cdc4bf0dda46d4402e475a7f697355c8813e",
        "edition": 1247,
        "date": 1654567447137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1247",
        "rarity_score": 111.38805346266956,
        "rarity_level": "COMMON"
    },
    "1248": {
        "name": "80's Arkade Bulls #1248",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1248.png",
        "dna": "026f180c414ad3dc427b0121ab28c6a63f003d0f",
        "edition": 1248,
        "date": 1654568063159,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1248",
        "rarity_score": 90.30074661629601,
        "rarity_level": "COMMON"
    },
    "1249": {
        "name": "80's Arkade Bulls #1249",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1249.png",
        "dna": "7d906a2823a7770f4fb72f281a0a9d5a1413a442",
        "edition": 1249,
        "date": 1654567994867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1249",
        "rarity_score": 86.2251357227725,
        "rarity_level": "COMMON"
    },
    "1250": {
        "name": "80's Arkade Bulls #1250",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1250.png",
        "dna": "9e3c15ffdd13a6eff87b6bc9688bcfa0672d171c",
        "edition": 1250,
        "date": 1654567303395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1250",
        "rarity_score": 99.07324868331241,
        "rarity_level": "COMMON"
    },
    "1251": {
        "name": "80's Arkade Bulls #1251",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1251.png",
        "dna": "083fedc289907e6837f02f52c874796cf1e6df58",
        "edition": 1251,
        "date": 1654567596872,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1251",
        "rarity_score": 196.53426098769017,
        "rarity_level": "COMMON"
    },
    "1252": {
        "name": "80's Arkade Bulls #1252",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1252.png",
        "dna": "68ba58dde33d717e0a0cf3bcd04a3c5d42187836",
        "edition": 1252,
        "date": 1654567349851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.222222222222222,
        "id": "1252",
        "rarity_score": 241.9366559782546,
        "rarity_level": "RARE"
    },
    "1253": {
        "name": "80's Arkade Bulls #1253",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1253.png",
        "dna": "2387e6836627e1e1c6e9800ecb343fb0f5a89435",
        "edition": 1253,
        "date": 1654567533658,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1253",
        "rarity_score": 192.61736732909725,
        "rarity_level": "COMMON"
    },
    "1254": {
        "name": "80's Arkade Bulls #1254",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1254.png",
        "dna": "0562cb98d5620696c56517969a0243084b7e8f3c",
        "edition": 1254,
        "date": 1654567129986,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1254",
        "rarity_score": 85.24678516567917,
        "rarity_level": "COMMON"
    },
    "1255": {
        "name": "80's Arkade Bulls #1255",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1255.png",
        "dna": "4354a26743b04cca057e1c7b0eeeb501fcb18114",
        "edition": 1255,
        "date": 1654568207148,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "1255",
        "rarity_score": 80.65675957948582,
        "rarity_level": "COMMON"
    },
    "1256": {
        "name": "80's Arkade Bulls #1256",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1256.png",
        "dna": "6925f80d09c76f63cbe53acb61b71e42419d9833",
        "edition": 1256,
        "date": 1654567518681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1256",
        "rarity_score": 215.6196510309452,
        "rarity_level": "RARE"
    },
    "1257": {
        "name": "80's Arkade Bulls #1257",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1257.png",
        "dna": "bb7bc1aebfb86826bf812f1587607338df03cc8b",
        "edition": 1257,
        "date": 1654567302364,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1257",
        "rarity_score": 91.57878953107962,
        "rarity_level": "COMMON"
    },
    "1258": {
        "name": "80's Arkade Bulls #1258",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1258.png",
        "dna": "653d7fad34e655f539c7253e8446d28f86c28583",
        "edition": 1258,
        "date": 1654567390916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1258",
        "rarity_score": 176.74423963133643,
        "rarity_level": "COMMON"
    },
    "1259": {
        "name": "80's Arkade Bulls #1259",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1259.png",
        "dna": "2704186f0bf2f4ff82b5b9d78d35b0e921a3bfca",
        "edition": 1259,
        "date": 1654567347269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.555555555555555,
        "id": "1259",
        "rarity_score": 176.7524549878376,
        "rarity_level": "COMMON"
    },
    "1260": {
        "name": "80's Arkade Bulls #1260",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1260.png",
        "dna": "1ce6d3e1b62c8ad3ea012ddd4466451a35300ec8",
        "edition": 1260,
        "date": 1654568146654,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 2.833333333333333,
        "id": "1260",
        "rarity_score": 232.8679089026915,
        "rarity_level": "RARE"
    },
    "1261": {
        "name": "80's Arkade Bulls #1261",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1261.png",
        "dna": "a1ebd24a905ededb3ac50d3ff888c6d0ddc784c4",
        "edition": 1261,
        "date": 1654567522491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857143,
        "id": "1261",
        "rarity_score": 231.7819511380371,
        "rarity_level": "RARE"
    },
    "1262": {
        "name": "80's Arkade Bulls #1262",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1262.png",
        "dna": "287789a25ed129fe88615c0195a957e66b773b8e",
        "edition": 1262,
        "date": 1654567503890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1262",
        "rarity_score": 317.41859812925304,
        "rarity_level": "RARE"
    },
    "1263": {
        "name": "80's Arkade Bulls #1263",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1263.png",
        "dna": "946d8f4f749d46d70b1699f730e10e19f1a0aed9",
        "edition": 1263,
        "date": 1654567316264,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1263",
        "rarity_score": 284.0655145097593,
        "rarity_level": "RARE"
    },
    "1264": {
        "name": "80's Arkade Bulls #1264",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1264.png",
        "dna": "787a464775464b6351dd88c4ac104372aefa9f17",
        "edition": 1264,
        "date": 1654568110774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1264",
        "rarity_score": 60.82973167226555,
        "rarity_level": "COMMON"
    },
    "1265": {
        "name": "80's Arkade Bulls #1265",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1265.png",
        "dna": "b2dfa7bd27a600fc158ae0636d732a1d97babba1",
        "edition": 1265,
        "date": 1654567182117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1265",
        "rarity_score": 80.46594056361954,
        "rarity_level": "COMMON"
    },
    "1266": {
        "name": "80's Arkade Bulls #1266",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1266.png",
        "dna": "74032060fe157400ead71291b894bf139f74e411",
        "edition": 1266,
        "date": 1654567869206,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1266",
        "rarity_score": 142.8456693998534,
        "rarity_level": "COMMON"
    },
    "1267": {
        "name": "80's Arkade Bulls #1267",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1267.png",
        "dna": "07d1c4c49418f74a033b98fd80a44e9f0c7cd22f",
        "edition": 1267,
        "date": 1654567831114,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rarity": 8.166666666666666,
        "rank": "Common",
        "id": "1267",
        "rarity_score": 94.75054760439839,
        "rarity_level": "COMMON"
    },
    "1268": {
        "name": "80's Arkade Bulls #1268",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1268.png",
        "dna": "aa35e0f7af2a14da4668ff65bfa0bb61edb45533",
        "edition": 1268,
        "date": 1654567893440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1268",
        "rarity_score": 194.4366124763305,
        "rarity_level": "COMMON"
    },
    "1269": {
        "name": "80's Arkade Bulls #1269",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1269.png",
        "dna": "3d5e45cdaa875ed22b24550aae3ab6e508eadc5b",
        "edition": 1269,
        "date": 1654567528711,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1269",
        "rarity_score": 265.3590942204954,
        "rarity_level": "RARE"
    },
    "1270": {
        "name": "80's Arkade Bulls #1270",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1270.png",
        "dna": "b391fe97bf4e97b6d30dc5d18985132d1c2e2e3b",
        "edition": 1270,
        "date": 1654567697759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857143,
        "id": "1270",
        "rarity_score": 141.1204049772071,
        "rarity_level": "COMMON"
    },
    "1271": {
        "name": "80's Arkade Bulls #1271",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1271.png",
        "dna": "1d7b9710881c4765214f0bb602a54e431a376e00",
        "edition": 1271,
        "date": 1654568054769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1271",
        "rarity_score": 82.36662375895321,
        "rarity_level": "COMMON"
    },
    "1272": {
        "name": "80's Arkade Bulls #1272",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1272.png",
        "dna": "20b687f3a5dc4cc27c6336c6faeecbc62fe8fc6f",
        "edition": 1272,
        "date": 1654567556405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1272",
        "rarity_score": 255.00661385636903,
        "rarity_level": "RARE"
    },
    "1273": {
        "name": "80's Arkade Bulls #1273",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1273.png",
        "dna": "9ba41f8b3c0537b2c6dc4d102dd036ecf285bb5e",
        "edition": 1273,
        "date": 1654568225639,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "1273",
        "rarity_score": 391.8700760940776,
        "rarity_level": "RARE"
    },
    "1274": {
        "name": "80's Arkade Bulls #1274",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1274.png",
        "dna": "468c70cfb5ea86d299c7990b38f777e7b4184051",
        "edition": 1274,
        "date": 1654567553475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1274",
        "rarity_score": 186.76551613081557,
        "rarity_level": "COMMON"
    },
    "1275": {
        "name": "80's Arkade Bulls #1275",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1275.png",
        "dna": "b0e0c5fd128f4d3d0ca5d25ee2cbd4c6cb6380b9",
        "edition": 1275,
        "date": 1654567455115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "1275",
        "rarity_score": 138.85257344560875,
        "rarity_level": "COMMON"
    },
    "1276": {
        "name": "80's Arkade Bulls #1276",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1276.png",
        "dna": "de6894e69875f58a1c19c9f6a6c31752b155508c",
        "edition": 1276,
        "date": 1654567326224,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.555555555555555,
        "id": "1276",
        "rarity_score": 172.33071494643139,
        "rarity_level": "COMMON"
    },
    "1277": {
        "name": "80's Arkade Bulls #1277",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1277.png",
        "dna": "6742aeb81114f44a25519f2c55bedf1eac20765d",
        "edition": 1277,
        "date": 1654567486960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1277",
        "rarity_score": 120.83983030318872,
        "rarity_level": "COMMON"
    },
    "1278": {
        "name": "80's Arkade Bulls #1278",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1278.png",
        "dna": "b7ffbefd5c4a286c9616e6263c2c3328937f3fcc",
        "edition": 1278,
        "date": 1654567600416,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1278",
        "rarity_score": 223.8360543040705,
        "rarity_level": "RARE"
    },
    "1279": {
        "name": "80's Arkade Bulls #1279",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1279.png",
        "dna": "2da7ab612b3c8371eabe0bbd1166d7a6d9c13bee",
        "edition": 1279,
        "date": 1654567478574,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "1279",
        "rarity_score": 187.644226831844,
        "rarity_level": "COMMON"
    },
    "1280": {
        "name": "80's Arkade Bulls #1280",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1280.png",
        "dna": "41f838260b66444924974f441e2a5f0696e31a3b",
        "edition": 1280,
        "date": 1654567332060,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.777777777777778,
        "id": "1280",
        "rarity_score": 183.20262964955535,
        "rarity_level": "COMMON"
    },
    "1281": {
        "name": "80's Arkade Bulls #1281",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1281.png",
        "dna": "74b6e980ffc7220bd2f26dc65ac35d6c2312df58",
        "edition": 1281,
        "date": 1654567149572,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1281",
        "rarity_score": 75.35392942304539,
        "rarity_level": "COMMON"
    },
    "1282": {
        "name": "80's Arkade Bulls #1282",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1282.png",
        "dna": "48ba4b6641e3c03c44a0ac2646e26afccdd71601",
        "edition": 1282,
        "date": 1654568075355,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1282",
        "rarity_score": 72.74335758265411,
        "rarity_level": "COMMON"
    },
    "1283": {
        "name": "80's Arkade Bulls #1283",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1283.png",
        "dna": "00b1ef97891c1ed07f8aae4d5deed41e5bdaa638",
        "edition": 1283,
        "date": 1654567478007,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1283",
        "rarity_score": 131.89018720342554,
        "rarity_level": "COMMON"
    },
    "1284": {
        "name": "80's Arkade Bulls #1284",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1284.png",
        "dna": "e62500c628f1d098b65e4494594e44dabd8f37e9",
        "edition": 1284,
        "date": 1654568126574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1284",
        "rarity_score": 90.14661132880427,
        "rarity_level": "COMMON"
    },
    "1285": {
        "name": "80's Arkade Bulls #1285",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1285.png",
        "dna": "22e0f2ce3c96980a9134f867160a445fba33feda",
        "edition": 1285,
        "date": 1654567385988,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1285",
        "rarity_score": 166.72465577288384,
        "rarity_level": "COMMON"
    },
    "1286": {
        "name": "80's Arkade Bulls #1286",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1286.png",
        "dna": "421b602c33b6ce0c8442ded95870aed0974a5798",
        "edition": 1286,
        "date": 1654568198293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1286",
        "rarity_score": 120.84511083278048,
        "rarity_level": "COMMON"
    },
    "1287": {
        "name": "80's Arkade Bulls #1287",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1287.png",
        "dna": "2501bd6eb271ce8d6bfd8a21e6bf5f60f76e89a5",
        "edition": 1287,
        "date": 1654568013032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "1287",
        "rarity_score": 73.35213417878407,
        "rarity_level": "COMMON"
    },
    "1288": {
        "name": "80's Arkade Bulls #1288",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1288.png",
        "dna": "07436ec4d1b42a03596b24cb79ba983f09ca5d67",
        "edition": 1288,
        "date": 1654568197780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1288",
        "rarity_score": 103.8499299776312,
        "rarity_level": "COMMON"
    },
    "1289": {
        "name": "80's Arkade Bulls #1289",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1289.png",
        "dna": "f59fe1ffa9e908e88de828b88dfc08ad83d573ae",
        "edition": 1289,
        "date": 1654567570464,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1289",
        "rarity_score": 171.3864713074429,
        "rarity_level": "COMMON"
    },
    "1290": {
        "name": "80's Arkade Bulls #1290",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1290.png",
        "dna": "4d9e24f95f6574ee52ecac6e80dab9048b011edf",
        "edition": 1290,
        "date": 1654567974533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1290",
        "rarity_score": 215.92079162411972,
        "rarity_level": "RARE"
    },
    "1291": {
        "name": "80's Arkade Bulls #1291",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1291.png",
        "dna": "5450cbaae06f16fc32567d231875e9ec35180e70",
        "edition": 1291,
        "date": 1654567835431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666666,
        "id": "1291",
        "rarity_score": 97.32708561706538,
        "rarity_level": "COMMON"
    },
    "1292": {
        "name": "80's Arkade Bulls #1292",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1292.png",
        "dna": "48b9c75d99d9d91865c420cc6d146bef3c649d5e",
        "edition": 1292,
        "date": 1654567586316,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "1292",
        "rarity_score": 212.81318454487246,
        "rarity_level": "RARE"
    },
    "1293": {
        "name": "80's Arkade Bulls #1293",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1293.png",
        "dna": "1e7d475fd1401a5ebb2d53ed7b1179a576d131c1",
        "edition": 1293,
        "date": 1654568112181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1293",
        "rarity_score": 96.13147296286832,
        "rarity_level": "COMMON"
    },
    "1294": {
        "name": "80's Arkade Bulls #1294",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1294.png",
        "dna": "b280c9974b24fd891a0029f960c7aaa7955cb59c",
        "edition": 1294,
        "date": 1654567973562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "1294",
        "rarity_score": 117.6679400769035,
        "rarity_level": "COMMON"
    },
    "1295": {
        "name": "80's Arkade Bulls #1295",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1295.png",
        "dna": "d9a19623ff85393cc5a849bc4498f10fdd6228a0",
        "edition": 1295,
        "date": 1654567114011,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Red Turtle Mask",
                "score": 1980.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "1295",
        "rarity_score": 2110.061773376645,
        "rarity_level": "EPIC"
    },
    "1296": {
        "name": "80's Arkade Bulls #1296",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1296.png",
        "dna": "e014bb37b26a4cb7eb36889b6f832089a66faf83",
        "edition": 1296,
        "date": 1654567120688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1296",
        "rarity_score": 86.30379816804496,
        "rarity_level": "COMMON"
    },
    "1297": {
        "name": "80's Arkade Bulls #1297",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1297.png",
        "dna": "dc9ec337e15381c7be3549920b018bca480746d5",
        "edition": 1297,
        "date": 1654568105628,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1297",
        "rarity_score": 63.68816366829973,
        "rarity_level": "COMMON"
    },
    "1298": {
        "name": "80's Arkade Bulls #1298",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1298.png",
        "dna": "27e22a12af1d0a67d1a4fb2d17cdb20c04e305de",
        "edition": 1298,
        "date": 1654567305442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1298",
        "rarity_score": 204.26532818414566,
        "rarity_level": "RARE"
    },
    "1299": {
        "name": "80's Arkade Bulls #1299",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1299.png",
        "dna": "f6ed20cb8430bf4a655c0cbc787b474261e73928",
        "edition": 1299,
        "date": 1654567186300,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1299",
        "rarity_score": 77.71438406109453,
        "rarity_level": "COMMON"
    },
    "1300": {
        "name": "80's Arkade Bulls #1300",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1300.png",
        "dna": "114fbe9f0a84f716f5a23711b430387dd5e84635",
        "edition": 1300,
        "date": 1654567871225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1300",
        "rarity_score": 101.89211727283654,
        "rarity_level": "COMMON"
    },
    "1301": {
        "name": "80's Arkade Bulls #1301",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1301.png",
        "dna": "c6ee516e1128bdf59692f02893ad2ccffe1b9074",
        "edition": 1301,
        "date": 1654567399040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1301",
        "rarity_score": 142.47253061744243,
        "rarity_level": "COMMON"
    },
    "1302": {
        "name": "80's Arkade Bulls #1302",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1302.png",
        "dna": "27c6da1549976a0c8a4ae08764f0ba1fe8386c4f",
        "edition": 1302,
        "date": 1654568226664,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Super",
        "rarity": 2.5,
        "id": "1302",
        "rarity_score": 292.82801120448175,
        "rarity_level": "RARE"
    },
    "1303": {
        "name": "80's Arkade Bulls #1303",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1303.png",
        "dna": "e50787140ddcb719b7935b37ac79df37559dd845",
        "edition": 1303,
        "date": 1654567395520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1303",
        "rarity_score": 215.9364212812718,
        "rarity_level": "RARE"
    },
    "1304": {
        "name": "80's Arkade Bulls #1304",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1304.png",
        "dna": "71f73e80b25bec9c3810e4dee4971a7af2e57a05",
        "edition": 1304,
        "date": 1654567490447,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1304",
        "rarity_score": 137.97676011608647,
        "rarity_level": "COMMON"
    },
    "1305": {
        "name": "80's Arkade Bulls #1305",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1305.png",
        "dna": "a25f743d35c534b74b97de6bd834fe46c27e6fe5",
        "edition": 1305,
        "date": 1654567468418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1305",
        "rarity_score": 104.66396742264025,
        "rarity_level": "COMMON"
    },
    "1306": {
        "name": "80's Arkade Bulls #1306",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1306.png",
        "dna": "94729ce8cf6910ff381c53ce101b1adfa924bc96",
        "edition": 1306,
        "date": 1654567433180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1306",
        "rarity_score": 151.22647607464455,
        "rarity_level": "COMMON"
    },
    "1307": {
        "name": "80's Arkade Bulls #1307",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1307.png",
        "dna": "ee4d69845a1ebd0acb1370a6fe65ad22df0da976",
        "edition": 1307,
        "date": 1654567260879,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1307",
        "rarity_score": 80.51180010372583,
        "rarity_level": "COMMON"
    },
    "1308": {
        "name": "80's Arkade Bulls #1308",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1308.png",
        "dna": "b7bfe035e783feed02714036797469c1830befec",
        "edition": 1308,
        "date": 1654567321622,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "1308",
        "rarity_score": 314.3358960621131,
        "rarity_level": "RARE"
    },
    "1309": {
        "name": "80's Arkade Bulls #1309",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1309.png",
        "dna": "a88e0919b11dc7ee23d3d13dd34664d0d7bc0ecb",
        "edition": 1309,
        "date": 1654567665571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "1309",
        "rarity_score": 105.69525519549248,
        "rarity_level": "COMMON"
    },
    "1310": {
        "name": "80's Arkade Bulls #1310",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1310.png",
        "dna": "f8998e8df3de2c05f14cd39af699f405e86a2972",
        "edition": 1310,
        "date": 1654567421699,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1310",
        "rarity_score": 180.11367698150718,
        "rarity_level": "COMMON"
    },
    "1311": {
        "name": "80's Arkade Bulls #1311",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1311.png",
        "dna": "6c9b4551409ad1b5b6f039da09b4bde62a2a714a",
        "edition": 1311,
        "date": 1654567188651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.2,
        "id": "1311",
        "rarity_score": 184.1648783992619,
        "rarity_level": "COMMON"
    },
    "1312": {
        "name": "80's Arkade Bulls #1312",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1312.png",
        "dna": "4dc1f316e3ebe70850273f9b5eaeb6e21e5328a9",
        "edition": 1312,
        "date": 1654568018561,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1312",
        "rarity_score": 82.34216381629469,
        "rarity_level": "COMMON"
    },
    "1313": {
        "name": "80's Arkade Bulls #1313",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1313.png",
        "dna": "63e7e229e8061c14e117b9f1f77ec4eee136360c",
        "edition": 1313,
        "date": 1654568028913,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1313",
        "rarity_score": 63.88421399870002,
        "rarity_level": "COMMON"
    },
    "1314": {
        "name": "80's Arkade Bulls #1314",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1314.png",
        "dna": "71e91932791dafbafaa8f7f07bf53e6079d87700",
        "edition": 1314,
        "date": 1654568187945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1314",
        "rarity_score": 84.83492651042273,
        "rarity_level": "COMMON"
    },
    "1315": {
        "name": "80's Arkade Bulls #1315",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1315.png",
        "dna": "dd468db6d7c402896206969dba528c58740a9025",
        "edition": 1315,
        "date": 1654567366983,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1315",
        "rarity_score": 205.89866509269825,
        "rarity_level": "RARE"
    },
    "1316": {
        "name": "80's Arkade Bulls #1316",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1316.png",
        "dna": "807276e001a608c63ab83473f12c5408190f9690",
        "edition": 1316,
        "date": 1654567566160,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1316",
        "rarity_score": 178.5071776508385,
        "rarity_level": "COMMON"
    },
    "1317": {
        "name": "80's Arkade Bulls #1317",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1317.png",
        "dna": "d52797e76f4d1abf31d0dd7828eece939d9db1ef",
        "edition": 1317,
        "date": 1654568049405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8,
        "id": "1317",
        "rarity_score": 144.60676776336942,
        "rarity_level": "COMMON"
    },
    "1318": {
        "name": "80's Arkade Bulls #1318",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1318.png",
        "dna": "c2cbd37aa4de7b20ddf7d93bd0c48338b564fb2c",
        "edition": 1318,
        "date": 1654568149603,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1318",
        "rarity_score": 175.5687857799801,
        "rarity_level": "COMMON"
    },
    "1319": {
        "name": "80's Arkade Bulls #1319",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1319.png",
        "dna": "2e145a707658e85cf1de3dc4694802d23d90f0af",
        "edition": 1319,
        "date": 1654567140792,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1319",
        "rarity_score": 76.96955128205127,
        "rarity_level": "COMMON"
    },
    "1320": {
        "name": "80's Arkade Bulls #1320",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1320.png",
        "dna": "83f2815cfa3282c6ea3df0749882b1497e79e61c",
        "edition": 1320,
        "date": 1654567517630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1320",
        "rarity_score": 201.99563287377651,
        "rarity_level": "COMMON"
    },
    "1321": {
        "name": "80's Arkade Bulls #1321",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1321.png",
        "dna": "bf60c00dbdbbbae898d054b6b769f9e0d0a9942f",
        "edition": 1321,
        "date": 1654567708167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1321",
        "rarity_score": 90.94053735593796,
        "rarity_level": "COMMON"
    },
    "1322": {
        "name": "80's Arkade Bulls #1322",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1322.png",
        "dna": "edfcc38af8495d8a06a121839801f54199d987a5",
        "edition": 1322,
        "date": 1654567231821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666666,
        "id": "1322",
        "rarity_score": 174.1493390409126,
        "rarity_level": "COMMON"
    },
    "1323": {
        "name": "80's Arkade Bulls #1323",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1323.png",
        "dna": "14676aec0eb95ba96bb17ece4fa2e71deaf14b32",
        "edition": 1323,
        "date": 1654567687101,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1323",
        "rarity_score": 94.33866427391482,
        "rarity_level": "COMMON"
    },
    "1324": {
        "name": "80's Arkade Bulls #1324",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1324.png",
        "dna": "facb8adb83f059def36d91d3f1c2ef78ec7caea6",
        "edition": 1324,
        "date": 1654567558295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "1324",
        "rarity_score": 146.211361388671,
        "rarity_level": "COMMON"
    },
    "1325": {
        "name": "80's Arkade Bulls #1325",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1325.png",
        "dna": "3088bc969aa9da3f174b216f55f6ea11af135d12",
        "edition": 1325,
        "date": 1654567509885,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1325",
        "rarity_score": 148.05416384728025,
        "rarity_level": "COMMON"
    },
    "1326": {
        "name": "80's Arkade Bulls #1326",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1326.png",
        "dna": "d1347a29fa4012e1a654b4e9ad8f2c2069896def",
        "edition": 1326,
        "date": 1654568215492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 3.833333333333333,
        "id": "1326",
        "rarity_score": 339.4913720145032,
        "rarity_level": "RARE"
    },
    "1327": {
        "name": "80's Arkade Bulls #1327",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1327.png",
        "dna": "1849c2cd46131a5edc2bbef413b157fbdea05da0",
        "edition": 1327,
        "date": 1654567434892,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1327",
        "rarity_score": 79.46818833635425,
        "rarity_level": "COMMON"
    },
    "1328": {
        "name": "80's Arkade Bulls #1328",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1328.png",
        "dna": "cf6d4dcdfcb725d23b1b34363bf3a1700819f492",
        "edition": 1328,
        "date": 1654567486441,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1328",
        "rarity_score": 122.11925275255709,
        "rarity_level": "COMMON"
    },
    "1329": {
        "name": "80's Arkade Bulls #1329",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1329.png",
        "dna": "1bcda6d768443e08eeb50d3e008ff98c5a6c79c4",
        "edition": 1329,
        "date": 1654567568868,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "1329",
        "rarity_score": 146.71825935946126,
        "rarity_level": "COMMON"
    },
    "1330": {
        "name": "80's Arkade Bulls #1330",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1330.png",
        "dna": "5e2ca2df87e3aac066cdbb11081c265adbf6230b",
        "edition": 1330,
        "date": 1654567215118,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1330",
        "rarity_score": 73.12154309321156,
        "rarity_level": "COMMON"
    },
    "1331": {
        "name": "80's Arkade Bulls #1331",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1331.png",
        "dna": "31dc08cdf52b2f9a64ea72dabaf886eda56b7655",
        "edition": 1331,
        "date": 1654567828190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333334,
        "id": "1331",
        "rarity_score": 151.34118398317182,
        "rarity_level": "COMMON"
    },
    "1332": {
        "name": "80's Arkade Bulls #1332",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1332.png",
        "dna": "6f6d06f109197e6d5c853cd4883a3f5b1ad86d01",
        "edition": 1332,
        "date": 1654567291371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1332",
        "rarity_score": 107.25702212679988,
        "rarity_level": "COMMON"
    },
    "1333": {
        "name": "80's Arkade Bulls #1333",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1333.png",
        "dna": "1bdda06bdf57b685a8b5914e4ba65cc186cd70eb",
        "edition": 1333,
        "date": 1654568045234,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1333",
        "rarity_score": 81.12545501685248,
        "rarity_level": "COMMON"
    },
    "1334": {
        "name": "80's Arkade Bulls #1334",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1334.png",
        "dna": "0522b062c6177e682341e5eb60d8fac6fd50e964",
        "edition": 1334,
        "date": 1654568064917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1334",
        "rarity_score": 69.52876439464674,
        "rarity_level": "COMMON"
    },
    "1335": {
        "name": "80's Arkade Bulls #1335",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1335.png",
        "dna": "ece75848aef85e84e527013a292154c4c3823ebe",
        "edition": 1335,
        "date": 1654567359745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1335",
        "rarity_score": 220.21980738951441,
        "rarity_level": "RARE"
    },
    "1336": {
        "name": "80's Arkade Bulls #1336",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1336.png",
        "dna": "5ebcfc4a9e74fe30e2f1f927ef9246809a3955b3",
        "edition": 1336,
        "date": 1654568177475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1336",
        "rarity_score": 83.47897161784292,
        "rarity_level": "COMMON"
    },
    "1337": {
        "name": "80's Arkade Bulls #1337",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1337.png",
        "dna": "ad04a868e48a15cde935bba88a317008b1220d65",
        "edition": 1337,
        "date": 1654567820759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1337",
        "rarity_score": 69.8999323867478,
        "rarity_level": "COMMON"
    },
    "1338": {
        "name": "80's Arkade Bulls #1338",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1338.png",
        "dna": "b7ea70dcab0aa67d001b518036ca587bb762bb6e",
        "edition": 1338,
        "date": 1654567959175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1338",
        "rarity_score": 107.1172978052665,
        "rarity_level": "COMMON"
    },
    "1339": {
        "name": "80's Arkade Bulls #1339",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1339.png",
        "dna": "22ec33a9f27aa3870a99d6c20ba64cfcad47b9e3",
        "edition": 1339,
        "date": 1654567465677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1339",
        "rarity_score": 120.87030555129792,
        "rarity_level": "COMMON"
    },
    "1340": {
        "name": "80's Arkade Bulls #1340",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1340.png",
        "dna": "aa364ddd281d6be07039cc9f0ba034a4573b4c1c",
        "edition": 1340,
        "date": 1654567274120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rarity": 6.5,
        "rank": "Common",
        "id": "1340",
        "rarity_score": 106.9383521535996,
        "rarity_level": "COMMON"
    },
    "1341": {
        "name": "80's Arkade Bulls #1341",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1341.png",
        "dna": "76a2c2933fe6351d368e31d5f294dfe9222c8025",
        "edition": 1341,
        "date": 1654568127530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1341",
        "rarity_score": 57.226252836501054,
        "rarity_level": "COMMON"
    },
    "1342": {
        "name": "80's Arkade Bulls #1342",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1342.png",
        "dna": "5d12f91be8d8ed442ac34ed9ce602266929ddd36",
        "edition": 1342,
        "date": 1654567988428,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1342",
        "rarity_score": 93.27414124060897,
        "rarity_level": "COMMON"
    },
    "1343": {
        "name": "80's Arkade Bulls #1343",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1343.png",
        "dna": "3a8c1a0e43b1acd11339eb3afa5c6f01e28c15b2",
        "edition": 1343,
        "date": 1654567865133,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1343",
        "rarity_score": 250.69885610006963,
        "rarity_level": "RARE"
    },
    "1344": {
        "name": "80's Arkade Bulls #1344",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1344.png",
        "dna": "75cf27725867881860a42410149968b780f287d7",
        "edition": 1344,
        "date": 1654567931188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1344",
        "rarity_score": 150.74436572002506,
        "rarity_level": "COMMON"
    },
    "1345": {
        "name": "80's Arkade Bulls #1345",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1345.png",
        "dna": "acbdb3c74c494b4c1d796a66d531d8b97fea46c6",
        "edition": 1345,
        "date": 1654568056702,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1345",
        "rarity_score": 69.60390536688372,
        "rarity_level": "COMMON"
    },
    "1346": {
        "name": "80's Arkade Bulls #1346",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1346.png",
        "dna": "0ea75f00262ce0e14195982e7e21c6cf15d62118",
        "edition": 1346,
        "date": 1654567832073,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666666,
        "id": "1346",
        "rarity_score": 139.35825554247276,
        "rarity_level": "COMMON"
    },
    "1347": {
        "name": "80's Arkade Bulls #1347",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1347.png",
        "dna": "da284468e96e4f3fa78b214733af268d5134d4b0",
        "edition": 1347,
        "date": 1654567180693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "1347",
        "rarity_score": 109.19817868322814,
        "rarity_level": "COMMON"
    },
    "1348": {
        "name": "80's Arkade Bulls #1348",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1348.png",
        "dna": "3138fad513385bec6dd79d2a39ff9b0f679296e4",
        "edition": 1348,
        "date": 1654567199351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1348",
        "rarity_score": 66.19939514960353,
        "rarity_level": "COMMON"
    },
    "1349": {
        "name": "80's Arkade Bulls #1349",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1349.png",
        "dna": "857bcba8ad09f1071b38df947b5f663d16243949",
        "edition": 1349,
        "date": 1654567452759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "1349",
        "rarity_score": 78.23437846977951,
        "rarity_level": "COMMON"
    },
    "1350": {
        "name": "80's Arkade Bulls #1350",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1350.png",
        "dna": "309b65e04bfbef7a68cc2c13cc6f88effac0b2e7",
        "edition": 1350,
        "date": 1654567460673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1350",
        "rarity_score": 110.04050791086952,
        "rarity_level": "COMMON"
    },
    "1351": {
        "name": "80's Arkade Bulls #1351",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1351.png",
        "dna": "a69e8e363a5051da29f4be3added5d78dc4445ee",
        "edition": 1351,
        "date": 1654568080692,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1351",
        "rarity_score": 90.73206820066815,
        "rarity_level": "COMMON"
    },
    "1352": {
        "name": "80's Arkade Bulls #1352",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1352.png",
        "dna": "19fddfc6b0f5d5aa78a24c8d942e16bf0b8eea13",
        "edition": 1352,
        "date": 1654567970534,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1352",
        "rarity_score": 115.81705189080822,
        "rarity_level": "COMMON"
    },
    "1353": {
        "name": "80's Arkade Bulls #1353",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1353.png",
        "dna": "d5d82fc3886a681fdc8a678589ec2a8e9cbb7718",
        "edition": 1353,
        "date": 1654567538594,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.7142857142857135,
        "id": "1353",
        "rarity_score": 221.9921769274257,
        "rarity_level": "RARE"
    },
    "1354": {
        "name": "80's Arkade Bulls #1354",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1354.png",
        "dna": "d5c6f299dd7d91795c78728042f2fd957df096a6",
        "edition": 1354,
        "date": 1654568063984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9.4,
        "id": "1354",
        "rarity_score": 55.5628921352364,
        "rarity_level": "COMMON"
    },
    "1355": {
        "name": "80's Arkade Bulls #1355",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1355.png",
        "dna": "2a709b2828eeac0b0491bb45f3ab94ebef3d4314",
        "edition": 1355,
        "date": 1654567948385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1355",
        "rarity_score": 92.48284905420687,
        "rarity_level": "COMMON"
    },
    "1356": {
        "name": "80's Arkade Bulls #1356",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1356.png",
        "dna": "abdcb99192ca98d6a3d41e511334bbbf6b49b89d",
        "edition": 1356,
        "date": 1654568033378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1356",
        "rarity_score": 60.298124711639936,
        "rarity_level": "COMMON"
    },
    "1357": {
        "name": "80's Arkade Bulls #1357",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1357.png",
        "dna": "b9b683087ee41b6b81e76b95766bd3af77cba154",
        "edition": 1357,
        "date": 1654567420734,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "1357",
        "rarity_score": 218.993400964975,
        "rarity_level": "RARE"
    },
    "1358": {
        "name": "80's Arkade Bulls #1358",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1358.png",
        "dna": "f6525769a37950ff4ac4b47d79e3fd50d42577e4",
        "edition": 1358,
        "date": 1654568138699,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1358",
        "rarity_score": 135.5381360321811,
        "rarity_level": "COMMON"
    },
    "1359": {
        "name": "80's Arkade Bulls #1359",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1359.png",
        "dna": "385c9d54913e1714dae6a71cf39502f7cfef8966",
        "edition": 1359,
        "date": 1654567378602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1359",
        "rarity_score": 223.25573036541917,
        "rarity_level": "RARE"
    },
    "1360": {
        "name": "80's Arkade Bulls #1360",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1360.png",
        "dna": "a5a1da672c640c8cb6b664189c167035fe2cc9ae",
        "edition": 1360,
        "date": 1654567473011,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1360",
        "rarity_score": 129.67702776964887,
        "rarity_level": "COMMON"
    },
    "1361": {
        "name": "80's Arkade Bulls #1361",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1361.png",
        "dna": "409ebfa48f3d68cae88729b5d90ce0b75e7502ee",
        "edition": 1361,
        "date": 1654568080266,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.8,
        "id": "1361",
        "rarity_score": 105.34114528739235,
        "rarity_level": "COMMON"
    },
    "1362": {
        "name": "80's Arkade Bulls #1362",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1362.png",
        "dna": "e37a24d433fd5058095d899a10e34589677a6ae4",
        "edition": 1362,
        "date": 1654567345352,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Rare",
        "rarity": 5.222222222222222,
        "id": "1362",
        "rarity_score": 331.22384048440176,
        "rarity_level": "RARE"
    },
    "1363": {
        "name": "80's Arkade Bulls #1363",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1363.png",
        "dna": "4c96e7bc168425dce7fde6b64bf6a0e06f2ce629",
        "edition": 1363,
        "date": 1654567291965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1363",
        "rarity_score": 82.38859670166055,
        "rarity_level": "COMMON"
    },
    "1364": {
        "name": "80's Arkade Bulls #1364",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1364.png",
        "dna": "044c6c499757bdb6580c3f766bbe98042766d7ff",
        "edition": 1364,
        "date": 1654568016536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1364",
        "rarity_score": 118.64465656113379,
        "rarity_level": "COMMON"
    },
    "1365": {
        "name": "80's Arkade Bulls #1365",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1365.png",
        "dna": "f7ca745c0e9d1c7081ac94472f3815538061199e",
        "edition": 1365,
        "date": 1654567876908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "1365",
        "rarity_score": 93.34164196602907,
        "rarity_level": "COMMON"
    },
    "1366": {
        "name": "80's Arkade Bulls #1366",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1366.png",
        "dna": "5cb9b6cefa2961ba3b5b19038b68e10a63d79920",
        "edition": 1366,
        "date": 1654567633367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1366",
        "rarity_score": 114.26629621669196,
        "rarity_level": "COMMON"
    },
    "1367": {
        "name": "80's Arkade Bulls #1367",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1367.png",
        "dna": "6cb17f8d68e0a713fd0433a789a30ea7950ca7b2",
        "edition": 1367,
        "date": 1654567562227,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "1367",
        "rarity_score": 252.6338456429274,
        "rarity_level": "RARE"
    },
    "1368": {
        "name": "80's Arkade Bulls #1368",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1368.png",
        "dna": "b8509600dc7b83535e2bbbde81e06566e567caac",
        "edition": 1368,
        "date": 1654567137142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1368",
        "rarity_score": 199.02876857361906,
        "rarity_level": "COMMON"
    },
    "1369": {
        "name": "80's Arkade Bulls #1369",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1369.png",
        "dna": "afbef700447a35d2d7f121747c92791790a13ea8",
        "edition": 1369,
        "date": 1654567878483,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "1369",
        "rarity_score": 147.87581634498224,
        "rarity_level": "COMMON"
    },
    "1370": {
        "name": "80's Arkade Bulls #1370",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1370.png",
        "dna": "b015f96d223c6349800558a775d6955882415ee0",
        "edition": 1370,
        "date": 1654567893985,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rarity": 5.714285714285714,
        "rank": "Rare",
        "id": "1370",
        "rarity_score": 161.53608449670458,
        "rarity_level": "COMMON"
    },
    "1371": {
        "name": "80's Arkade Bulls #1371",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1371.png",
        "dna": "8a4e8ab030eaffe4c31ced177f1cb5739f8c4732",
        "edition": 1371,
        "date": 1654567683805,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1371",
        "rarity_score": 106.8316420818468,
        "rarity_level": "COMMON"
    },
    "1372": {
        "name": "80's Arkade Bulls #1372",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1372.png",
        "dna": "31c09a9f195cbcade176a038a550ea82ccdc0982",
        "edition": 1372,
        "date": 1654568054279,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "1372",
        "rarity_score": 52.827997438380486,
        "rarity_level": "COMMON"
    },
    "1373": {
        "name": "80's Arkade Bulls #1373",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1373.png",
        "dna": "4f9af3ef488ad50bf86151372383653615a189f3",
        "edition": 1373,
        "date": 1654567583497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "1373",
        "rarity_score": 130.7528219065308,
        "rarity_level": "COMMON"
    },
    "1374": {
        "name": "80's Arkade Bulls #1374",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1374.png",
        "dna": "3fbf29c516cf9fda404a48d4d817d20e922b4dcb",
        "edition": 1374,
        "date": 1654567562785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "1374",
        "rarity_score": 136.65954604148473,
        "rarity_level": "COMMON"
    },
    "1375": {
        "name": "80's Arkade Bulls #1375",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1375.png",
        "dna": "d3289b250930f5ec948df04aabc35733d6f154bc",
        "edition": 1375,
        "date": 1654567716888,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333334,
        "id": "1375",
        "rarity_score": 123.64427388567013,
        "rarity_level": "COMMON"
    },
    "1376": {
        "name": "80's Arkade Bulls #1376",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1376.png",
        "dna": "22d1c525d8db2dfc6e84d79c69bd627991ab960e",
        "edition": 1376,
        "date": 1654568017032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1376",
        "rarity_score": 123.09268004204185,
        "rarity_level": "COMMON"
    },
    "1377": {
        "name": "80's Arkade Bulls #1377",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1377.png",
        "dna": "ab90e7144b14980166c32d55b591677d2ccd7cbb",
        "edition": 1377,
        "date": 1654567293080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1377",
        "rarity_score": 96.68634308714068,
        "rarity_level": "COMMON"
    },
    "1378": {
        "name": "80's Arkade Bulls #1378",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1378.png",
        "dna": "d02946710bce4ecc12df16ff14f5cd28eef3b452",
        "edition": 1378,
        "date": 1654568094040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1378",
        "rarity_score": 110.06063741622836,
        "rarity_level": "COMMON"
    },
    "1379": {
        "name": "80's Arkade Bulls #1379",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1379.png",
        "dna": "3bae40c99c47bc973564a51256a51466ce59dc61",
        "edition": 1379,
        "date": 1654568008922,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1379",
        "rarity_score": 122.87871985363127,
        "rarity_level": "COMMON"
    },
    "1380": {
        "name": "80's Arkade Bulls #1380",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1380.png",
        "dna": "9958ac379f1f4f85b353a058752cf22174ee15e6",
        "edition": 1380,
        "date": 1654568140103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1380",
        "rarity_score": 62.68725870771001,
        "rarity_level": "COMMON"
    },
    "1381": {
        "name": "80's Arkade Bulls #1381",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1381.png",
        "dna": "02149cd4ba5426c78fbe54984c03111f9e587417",
        "edition": 1381,
        "date": 1654568186868,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1381",
        "rarity_score": 144.6507808554148,
        "rarity_level": "COMMON"
    },
    "1382": {
        "name": "80's Arkade Bulls #1382",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1382.png",
        "dna": "5e3799bc68b5fa61ff1c6a91e04669d5b1f02c20",
        "edition": 1382,
        "date": 1654568003786,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1382",
        "rarity_score": 110.05606301939073,
        "rarity_level": "COMMON"
    },
    "1383": {
        "name": "80's Arkade Bulls #1383",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1383.png",
        "dna": "b5d0f27d8c31675cd16e56b569f39da77500e644",
        "edition": 1383,
        "date": 1654567926844,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "1383",
        "rarity_score": 219.44924660684407,
        "rarity_level": "RARE"
    },
    "1384": {
        "name": "80's Arkade Bulls #1384",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1384.png",
        "dna": "82d2f1145e6614ddaac55b486b741448eaf958d7",
        "edition": 1384,
        "date": 1654567282869,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1384",
        "rarity_score": 232.42210348526908,
        "rarity_level": "RARE"
    },
    "1385": {
        "name": "80's Arkade Bulls #1385",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1385.png",
        "dna": "b4ddd4dc460f9d6b86995ec482815a6eee92c91f",
        "edition": 1385,
        "date": 1654568130343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1385",
        "rarity_score": 82.51896697789888,
        "rarity_level": "COMMON"
    },
    "1386": {
        "name": "80's Arkade Bulls #1386",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1386.png",
        "dna": "71e1b556fb8a315d8d339871d87e2b5763bf07c6",
        "edition": 1386,
        "date": 1654567982904,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1386",
        "rarity_score": 95.15503675017291,
        "rarity_level": "COMMON"
    },
    "1387": {
        "name": "80's Arkade Bulls #1387",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1387.png",
        "dna": "7467501308eb8dc4b8ae522deb3530d81e2a6a65",
        "edition": 1387,
        "date": 1654568117296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1387",
        "rarity_score": 138.05490196078432,
        "rarity_level": "COMMON"
    },
    "1388": {
        "name": "80's Arkade Bulls #1388",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1388.png",
        "dna": "a7e988b4029415769251023582d6e77c3c6782df",
        "edition": 1388,
        "date": 1654567205311,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1388",
        "rarity_score": 105.20685721898917,
        "rarity_level": "COMMON"
    },
    "1389": {
        "name": "80's Arkade Bulls #1389",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1389.png",
        "dna": "57dbcedd114660bdff210011015caf2791076947",
        "edition": 1389,
        "date": 1654567690497,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1389",
        "rarity_score": 150.1089878253004,
        "rarity_level": "COMMON"
    },
    "1390": {
        "name": "80's Arkade Bulls #1390",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1390.png",
        "dna": "90ddcef0e3529a283ea7d4f8aab6a780f2f5d9e5",
        "edition": 1390,
        "date": 1654567613697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.875,
        "id": "1390",
        "rarity_score": 180.78703054920223,
        "rarity_level": "COMMON"
    },
    "1391": {
        "name": "80's Arkade Bulls #1391",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1391.png",
        "dna": "25ccd4daecb4a318ce31501060d1151ec9ee6d68",
        "edition": 1391,
        "date": 1654567620430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.125,
        "id": "1391",
        "rarity_score": 126.58346695324529,
        "rarity_level": "COMMON"
    },
    "1392": {
        "name": "80's Arkade Bulls #1392",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1392.png",
        "dna": "24ed2e94a5f19698223b7f013916b2d7d1c1b6f0",
        "edition": 1392,
        "date": 1654567874797,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1392",
        "rarity_score": 122.43336407240436,
        "rarity_level": "COMMON"
    },
    "1393": {
        "name": "80's Arkade Bulls #1393",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1393.png",
        "dna": "1aaa81cc06ec8c0fc60804610c6494fb0bf140ca",
        "edition": 1393,
        "date": 1654568070773,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1393",
        "rarity_score": 71.5881298735963,
        "rarity_level": "COMMON"
    },
    "1394": {
        "name": "80's Arkade Bulls #1394",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1394.png",
        "dna": "95b667423d0f4ff47e990c35bda7272568d190e4",
        "edition": 1394,
        "date": 1654567861165,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1394",
        "rarity_score": 141.69637255529912,
        "rarity_level": "COMMON"
    },
    "1395": {
        "name": "80's Arkade Bulls #1395",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1395.png",
        "dna": "43d1fa543d3518a3e3c4ca139362cd2b9033eb84",
        "edition": 1395,
        "date": 1654568061355,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1395",
        "rarity_score": 135.78867025756375,
        "rarity_level": "COMMON"
    },
    "1396": {
        "name": "80's Arkade Bulls #1396",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1396.png",
        "dna": "9a851636acbd47f16efa39ac6ad951d909a0f8d7",
        "edition": 1396,
        "date": 1654567404933,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1396",
        "rarity_score": 171.16634885322316,
        "rarity_level": "COMMON"
    },
    "1397": {
        "name": "80's Arkade Bulls #1397",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1397.png",
        "dna": "cb8f96625f580cca24cbebc34ede6d304326aa6b",
        "edition": 1397,
        "date": 1654568202468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1397",
        "rarity_score": 95.40465365489709,
        "rarity_level": "COMMON"
    },
    "1398": {
        "name": "80's Arkade Bulls #1398",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1398.png",
        "dna": "5d9fcbf9426374db94f71db9afe59d8a341c8864",
        "edition": 1398,
        "date": 1654568222614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Super",
        "rarity": 1.4,
        "id": "1398",
        "rarity_score": 286.80900072248164,
        "rarity_level": "RARE"
    },
    "1399": {
        "name": "80's Arkade Bulls #1399",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1399.png",
        "dna": "bd2bf80f91d899d48665b1083bb9140ffb6eaaff",
        "edition": 1399,
        "date": 1654567568304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "1399",
        "rarity_score": 140.82410968989439,
        "rarity_level": "COMMON"
    },
    "1400": {
        "name": "80's Arkade Bulls #1400",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1400.png",
        "dna": "337342fd410a19c870e311a47a23fdc4c2a7be17",
        "edition": 1400,
        "date": 1654568025995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1400",
        "rarity_score": 88.14104171563247,
        "rarity_level": "COMMON"
    },
    "1401": {
        "name": "80's Arkade Bulls #1401",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1401.png",
        "dna": "da3dcde656546bc0c0e6c1da511028a9f3741dd3",
        "edition": 1401,
        "date": 1654568096779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1401",
        "rarity_score": 60.90590678772915,
        "rarity_level": "COMMON"
    },
    "1402": {
        "name": "80's Arkade Bulls #1402",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1402.png",
        "dna": "2cdcff6deef80401c38509e06bf086ad9a094b40",
        "edition": 1402,
        "date": 1654567632775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "1402",
        "rarity_score": 114.38906641746206,
        "rarity_level": "COMMON"
    },
    "1403": {
        "name": "80's Arkade Bulls #1403",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1403.png",
        "dna": "bcb054f5e56534110a2e850fe1298eabb522bb0c",
        "edition": 1403,
        "date": 1654568101912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1403",
        "rarity_score": 68.72374842479093,
        "rarity_level": "COMMON"
    },
    "1404": {
        "name": "80's Arkade Bulls #1404",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1404.png",
        "dna": "769682abf5c88fc228ba8f8551b9d2ec619adf91",
        "edition": 1404,
        "date": 1654567720498,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333334,
        "id": "1404",
        "rarity_score": 125.23168713419034,
        "rarity_level": "COMMON"
    },
    "1405": {
        "name": "80's Arkade Bulls #1405",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1405.png",
        "dna": "7fa2d09437084b455f7b545420481c9f15313dfb",
        "edition": 1405,
        "date": 1654567288036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1405",
        "rarity_score": 120.08557675347217,
        "rarity_level": "COMMON"
    },
    "1406": {
        "name": "80's Arkade Bulls #1406",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1406.png",
        "dna": "4339ee76a307729608074979b8a384f2812dae7f",
        "edition": 1406,
        "date": 1654567370928,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.777777777777778,
        "id": "1406",
        "rarity_score": 317.4299894687065,
        "rarity_level": "RARE"
    },
    "1407": {
        "name": "80's Arkade Bulls #1407",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1407.png",
        "dna": "139ae97f476cbab07299e14680613ce0f791971e",
        "edition": 1407,
        "date": 1654567881143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1407",
        "rarity_score": 128.4257087215584,
        "rarity_level": "COMMON"
    },
    "1408": {
        "name": "80's Arkade Bulls #1408",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1408.png",
        "dna": "c673e40eaa50bc508e0e4c9f28c8501ecddb3ce8",
        "edition": 1408,
        "date": 1654567145433,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1408",
        "rarity_score": 185.23978158137115,
        "rarity_level": "COMMON"
    },
    "1409": {
        "name": "80's Arkade Bulls #1409",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1409.png",
        "dna": "5b65b41e34558c57fccc54f4b3b49b28228e4fb2",
        "edition": 1409,
        "date": 1654567525406,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1409",
        "rarity_score": 275.7917336188813,
        "rarity_level": "RARE"
    },
    "1410": {
        "name": "80's Arkade Bulls #1410",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1410.png",
        "dna": "b1568855a8688e2445a88336fba1dccfb2bffa5b",
        "edition": 1410,
        "date": 1654567559467,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "1410",
        "rarity_score": 230.45152891905224,
        "rarity_level": "RARE"
    },
    "1411": {
        "name": "80's Arkade Bulls #1411",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1411.png",
        "dna": "ccb80285260456bc01998d1a7ef810bde786038e",
        "edition": 1411,
        "date": 1654567361710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "1411",
        "rarity_score": 220.51105305297045,
        "rarity_level": "RARE"
    },
    "1412": {
        "name": "80's Arkade Bulls #1412",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1412.png",
        "dna": "db4b565502e909aabc482c6567838d2fbc8e0f82",
        "edition": 1412,
        "date": 1654567377592,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Super",
        "rarity": 1.8333333333333335,
        "id": "1412",
        "rarity_score": 311.57574708221114,
        "rarity_level": "RARE"
    },
    "1413": {
        "name": "80's Arkade Bulls #1413",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1413.png",
        "dna": "28d926320fa1c95b83ce16069ebe799160c71fa9",
        "edition": 1413,
        "date": 1654567681511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.285714285714286,
        "id": "1413",
        "rarity_score": 119.5291739168085,
        "rarity_level": "COMMON"
    },
    "1414": {
        "name": "80's Arkade Bulls #1414",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1414.png",
        "dna": "ed3f6307baf366ebc3b4bd4de41a8763303ba3b2",
        "edition": 1414,
        "date": 1654567976032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1414",
        "rarity_score": 77.22473816531769,
        "rarity_level": "COMMON"
    },
    "1415": {
        "name": "80's Arkade Bulls #1415",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1415.png",
        "dna": "a9bdc0feaf79891c681aa1cfd09166c36bb35749",
        "edition": 1415,
        "date": 1654567948964,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rarity": 4.2857142857142865,
        "rank": "Epic",
        "id": "1415",
        "rarity_score": 144.03711477100433,
        "rarity_level": "COMMON"
    },
    "1416": {
        "name": "80's Arkade Bulls #1416",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1416.png",
        "dna": "067bab769883e6a5ca9b6365df70514883e9aa33",
        "edition": 1416,
        "date": 1654567691067,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "1416",
        "rarity_score": 198.0598724312901,
        "rarity_level": "COMMON"
    },
    "1417": {
        "name": "80's Arkade Bulls #1417",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1417.png",
        "dna": "1e4cecf7e6ebcf4458a0d7c5744f1962b456616b",
        "edition": 1417,
        "date": 1654567237020,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1417",
        "rarity_score": 87.42020845202413,
        "rarity_level": "COMMON"
    },
    "1418": {
        "name": "80's Arkade Bulls #1418",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1418.png",
        "dna": "e1d5d8eac9fb61fc91e64e44b44d46b51791c367",
        "edition": 1418,
        "date": 1654567849642,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rarity": 6.2857142857142865,
        "rank": "Common",
        "id": "1418",
        "rarity_score": 118.33837954112515,
        "rarity_level": "COMMON"
    },
    "1419": {
        "name": "80's Arkade Bulls #1419",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1419.png",
        "dna": "ff2f3814bdb7385de8aaebf39a0aae7f0adae9ba",
        "edition": 1419,
        "date": 1654567129036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1419",
        "rarity_score": 78.72713576745835,
        "rarity_level": "COMMON"
    },
    "1420": {
        "name": "80's Arkade Bulls #1420",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1420.png",
        "dna": "5e9dc62122b5c87d9d56d556c0009cd7ade18222",
        "edition": 1420,
        "date": 1654567675337,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.285714285714286,
        "id": "1420",
        "rarity_score": 123.19504886655456,
        "rarity_level": "COMMON"
    },
    "1421": {
        "name": "80's Arkade Bulls #1421",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1421.png",
        "dna": "fc92c4690e099b408e4a6e649a3d4cfa6d77ff3f",
        "edition": 1421,
        "date": 1654567411503,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1421",
        "rarity_score": 191.8390176443512,
        "rarity_level": "COMMON"
    },
    "1422": {
        "name": "80's Arkade Bulls #1422",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1422.png",
        "dna": "4c957c588d4e0cbc626afeb1d604d97003033328",
        "edition": 1422,
        "date": 1654567831532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1422",
        "rarity_score": 64.4288451543318,
        "rarity_level": "COMMON"
    },
    "1423": {
        "name": "80's Arkade Bulls #1423",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1423.png",
        "dna": "a7e07dd3bd1d9c13eaa448e16a0610bc385d87d5",
        "edition": 1423,
        "date": 1654567852662,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1423",
        "rarity_score": 111.40300186907326,
        "rarity_level": "COMMON"
    },
    "1424": {
        "name": "80's Arkade Bulls #1424",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1424.png",
        "dna": "b537fbb3693a3e99dbf5b24488fb8fd5beb41206",
        "edition": 1424,
        "date": 1654568203009,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1424",
        "rarity_score": 191.11432468096294,
        "rarity_level": "COMMON"
    },
    "1425": {
        "name": "80's Arkade Bulls #1425",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1425.png",
        "dna": "aa4dc7e7fca6af398e1f240f118b36347908349f",
        "edition": 1425,
        "date": 1654568140614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Rare",
        "rarity": 5.2,
        "id": "1425",
        "rarity_score": 114.58549550118263,
        "rarity_level": "COMMON"
    },
    "1426": {
        "name": "80's Arkade Bulls #1426",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1426.png",
        "dna": "473cd98dd21a3de1cad280474561e34e5e2eed3c",
        "edition": 1426,
        "date": 1654567915256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rarity": 7.428571428571429,
        "rank": "Common",
        "id": "1426",
        "rarity_score": 96.38204943577941,
        "rarity_level": "COMMON"
    },
    "1427": {
        "name": "80's Arkade Bulls #1427",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1427.png",
        "dna": "d3d153a3d80a193a8654ebc8414b0998e44a6a1c",
        "edition": 1427,
        "date": 1654568085993,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.2,
        "id": "1427",
        "rarity_score": 177.96705547043132,
        "rarity_level": "COMMON"
    },
    "1428": {
        "name": "80's Arkade Bulls #1428",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1428.png",
        "dna": "ab4c58d3e83bef22d0aea67df059cd62752f6cbd",
        "edition": 1428,
        "date": 1654567971518,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "1428",
        "rarity_score": 92.75686352088735,
        "rarity_level": "COMMON"
    },
    "1429": {
        "name": "80's Arkade Bulls #1429",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1429.png",
        "dna": "09259227675623c8c2a93b3ff82a503f53f0a279",
        "edition": 1429,
        "date": 1654567403437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1429",
        "rarity_score": 150.38878633890832,
        "rarity_level": "COMMON"
    },
    "1430": {
        "name": "80's Arkade Bulls #1430",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1430.png",
        "dna": "b0db2af06cb3f7c43f248c6c8207b96df12c4206",
        "edition": 1430,
        "date": 1654567909959,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Epic",
        "rarity": 4.285714285714286,
        "id": "1430",
        "rarity_score": 193.71987524509916,
        "rarity_level": "COMMON"
    },
    "1431": {
        "name": "80's Arkade Bulls #1431",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1431.png",
        "dna": "fed6179d444bf5f54ee4f53bef7eaaa5333f4d05",
        "edition": 1431,
        "date": 1654568212276,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1431",
        "rarity_score": 131.66784956617215,
        "rarity_level": "COMMON"
    },
    "1432": {
        "name": "80's Arkade Bulls #1432",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1432.png",
        "dna": "fb6877c91c97cdb0677cdb8dab1da9aa346206d0",
        "edition": 1432,
        "date": 1654567431989,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "1432",
        "rarity_score": 100.29401848335834,
        "rarity_level": "COMMON"
    },
    "1433": {
        "name": "80's Arkade Bulls #1433",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1433.png",
        "dna": "b506d79b731e0a049e3bced4acf16ffacd7a87cd",
        "edition": 1433,
        "date": 1654567882181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1433",
        "rarity_score": 123.54281167299364,
        "rarity_level": "COMMON"
    },
    "1434": {
        "name": "80's Arkade Bulls #1434",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1434.png",
        "dna": "09e97ea7f1a60ae2f95156a247a61ff6d9a23f74",
        "edition": 1434,
        "date": 1654568005847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1434",
        "rarity_score": 69.9190144539185,
        "rarity_level": "COMMON"
    },
    "1435": {
        "name": "80's Arkade Bulls #1435",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1435.png",
        "dna": "5115b28de5f1c6c20453fb72cb573ca043e0d9ee",
        "edition": 1435,
        "date": 1654567593895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1435",
        "rarity_score": 193.269140508424,
        "rarity_level": "COMMON"
    },
    "1436": {
        "name": "80's Arkade Bulls #1436",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1436.png",
        "dna": "207190f4862fde5d6c90b565a8d7338321ad4cf2",
        "edition": 1436,
        "date": 1654567591357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1436",
        "rarity_score": 242.307881773399,
        "rarity_level": "RARE"
    },
    "1437": {
        "name": "80's Arkade Bulls #1437",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1437.png",
        "dna": "5668a556443292924b6858ba3093dab609eeebb9",
        "edition": 1437,
        "date": 1654568183661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1437",
        "rarity_score": 138.6950392698833,
        "rarity_level": "COMMON"
    },
    "1438": {
        "name": "80's Arkade Bulls #1438",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1438.png",
        "dna": "038f631f7ece671d5cf9b53b081f3d9ee274af48",
        "edition": 1438,
        "date": 1654568079803,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1438",
        "rarity_score": 67.77977882337879,
        "rarity_level": "COMMON"
    },
    "1439": {
        "name": "80's Arkade Bulls #1439",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1439.png",
        "dna": "47aa2a9beeb76cebe1d02ee9d5190e2c3969506e",
        "edition": 1439,
        "date": 1654567349226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1439",
        "rarity_score": 227.67186060036573,
        "rarity_level": "RARE"
    },
    "1440": {
        "name": "80's Arkade Bulls #1440",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1440.png",
        "dna": "2cc2fb91dfd85b29f487b24849515da349bf252f",
        "edition": 1440,
        "date": 1654567614907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1440",
        "rarity_score": 286.64721963610737,
        "rarity_level": "RARE"
    },
    "1441": {
        "name": "80's Arkade Bulls #1441",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1441.png",
        "dna": "dc724ef645682c8e7a0d3f412a73da0522e8a707",
        "edition": 1441,
        "date": 1654567824365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "1441",
        "rarity_score": 104.12720018171956,
        "rarity_level": "COMMON"
    },
    "1442": {
        "name": "80's Arkade Bulls #1442",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1442.png",
        "dna": "6e0925ede91f7b6c3c56bff7f965397d23e32820",
        "edition": 1442,
        "date": 1654567259302,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1442",
        "rarity_score": 141.6355317841784,
        "rarity_level": "COMMON"
    },
    "1443": {
        "name": "80's Arkade Bulls #1443",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1443.png",
        "dna": "3e3354c1c71b93f531d14ef8bc842ed557a6a372",
        "edition": 1443,
        "date": 1654567270254,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1443",
        "rarity_score": 75.44966926488358,
        "rarity_level": "COMMON"
    },
    "1444": {
        "name": "80's Arkade Bulls #1444",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1444.png",
        "dna": "891cd48be68b9fbe47335a29a6989232b77a0992",
        "edition": 1444,
        "date": 1654567903513,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.571428571428571,
        "id": "1444",
        "rarity_score": 75.56131854777087,
        "rarity_level": "COMMON"
    },
    "1445": {
        "name": "80's Arkade Bulls #1445",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1445.png",
        "dna": "030c89cb4c3d1cbb1bddc7871014c05280c9f70f",
        "edition": 1445,
        "date": 1654567391422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1445",
        "rarity_score": 189.7640512669002,
        "rarity_level": "COMMON"
    },
    "1446": {
        "name": "80's Arkade Bulls #1446",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1446.png",
        "dna": "02d35a1d97b07040602a13553e0717c30bed1fd2",
        "edition": 1446,
        "date": 1654567534217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1446",
        "rarity_score": 184.18871491010256,
        "rarity_level": "COMMON"
    },
    "1447": {
        "name": "80's Arkade Bulls #1447",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1447.png",
        "dna": "af46bb580429dc13c0fe775f5d13c41422c0c3be",
        "edition": 1447,
        "date": 1654567442688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1447",
        "rarity_score": 113.44033602199646,
        "rarity_level": "COMMON"
    },
    "1448": {
        "name": "80's Arkade Bulls #1448",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1448.png",
        "dna": "80fb5077fc1598538c4e2b9e21f1a6c01e6a008f",
        "edition": 1448,
        "date": 1654567419198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1448",
        "rarity_score": 172.50398327815887,
        "rarity_level": "COMMON"
    },
    "1449": {
        "name": "80's Arkade Bulls #1449",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1449.png",
        "dna": "391bc4b2bb3c4bfe2d8f197e7c326d1a568f2c2b",
        "edition": 1449,
        "date": 1654567818120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1449",
        "rarity_score": 90.1592334384997,
        "rarity_level": "COMMON"
    },
    "1450": {
        "name": "80's Arkade Bulls #1450",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1450.png",
        "dna": "fae8b751a775e8d3940bb2c5aa9337cf14cd7008",
        "edition": 1450,
        "date": 1654568076256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1450",
        "rarity_score": 79.39494158869067,
        "rarity_level": "COMMON"
    },
    "1451": {
        "name": "80's Arkade Bulls #1451",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1451.png",
        "dna": "0ac413ae560a1904ead1edb96abe9437558ad319",
        "edition": 1451,
        "date": 1654567229244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "1451",
        "rarity_score": 103.0739959840982,
        "rarity_level": "COMMON"
    },
    "1452": {
        "name": "80's Arkade Bulls #1452",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1452.png",
        "dna": "02bd9f9a7f662a27fbb069b849f0d8cf5ef2fca9",
        "edition": 1452,
        "date": 1654567917420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1452",
        "rarity_score": 95.40667101353537,
        "rarity_level": "COMMON"
    },
    "1453": {
        "name": "80's Arkade Bulls #1453",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1453.png",
        "dna": "2dadeeb90fd660cae67c57a3b7db7aa43cdfadf9",
        "edition": 1453,
        "date": 1654567991904,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1453",
        "rarity_score": 84.15778606965175,
        "rarity_level": "COMMON"
    },
    "1454": {
        "name": "80's Arkade Bulls #1454",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1454.png",
        "dna": "e6406d43697699852c08722b04958cdf21880578",
        "edition": 1454,
        "date": 1654567484057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1454",
        "rarity_score": 136.46895416296877,
        "rarity_level": "COMMON"
    },
    "1455": {
        "name": "80's Arkade Bulls #1455",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1455.png",
        "dna": "9f8d7f4c7b6a62264dcdfb9ce5735a39f65bb410",
        "edition": 1455,
        "date": 1654567890165,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "1455",
        "rarity_score": 204.03682563594,
        "rarity_level": "RARE"
    },
    "1456": {
        "name": "80's Arkade Bulls #1456",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1456.png",
        "dna": "1cb06a5913140def59a7f007229b6fcd608b9551",
        "edition": 1456,
        "date": 1654567987383,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1456",
        "rarity_score": 98.63187453210728,
        "rarity_level": "COMMON"
    },
    "1457": {
        "name": "80's Arkade Bulls #1457",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1457.png",
        "dna": "ee05575895d39256f88f2d447eff2c5a48a5d7b7",
        "edition": 1457,
        "date": 1654567595892,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1457",
        "rarity_score": 204.69665923049382,
        "rarity_level": "RARE"
    },
    "1458": {
        "name": "80's Arkade Bulls #1458",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1458.png",
        "dna": "47edc12381f96dca3791273db1880d5f918aa3e8",
        "edition": 1458,
        "date": 1654568152669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 2.5,
        "id": "1458",
        "rarity_score": 217.2495823498386,
        "rarity_level": "RARE"
    },
    "1459": {
        "name": "80's Arkade Bulls #1459",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1459.png",
        "dna": "bc4b49a4afa7fc68675656745852ab741b5217de",
        "edition": 1459,
        "date": 1654567112541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rarity": 0,
        "rank": "Legendary",
        "id": "1459",
        "rarity_score": 132.97000515436483,
        "rarity_level": "COMMON"
    },
    "1460": {
        "name": "80's Arkade Bulls #1460",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1460.png",
        "dna": "9784739797d56c0cfa3aa3988158d10dcdc8d4af",
        "edition": 1460,
        "date": 1654568224585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 1.4,
        "id": "1460",
        "rarity_score": 280.8356200658711,
        "rarity_level": "RARE"
    },
    "1461": {
        "name": "80's Arkade Bulls #1461",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1461.png",
        "dna": "af91ece6003e250ac0539def26abf89ec520090a",
        "edition": 1461,
        "date": 1654568129874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1461",
        "rarity_score": 66.90818619089168,
        "rarity_level": "COMMON"
    },
    "1462": {
        "name": "80's Arkade Bulls #1462",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1462.png",
        "dna": "6f9224720bb05cbba508271c2a342e7551c6659f",
        "edition": 1462,
        "date": 1654567261351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1462",
        "rarity_score": 209.13920964504948,
        "rarity_level": "RARE"
    },
    "1463": {
        "name": "80's Arkade Bulls #1463",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1463.png",
        "dna": "7a1ef39a4009f40a2a799c6f72bc8b70d89fcdd5",
        "edition": 1463,
        "date": 1654567884270,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.428571428571429,
        "id": "1463",
        "rarity_score": 82.69396886615993,
        "rarity_level": "COMMON"
    },
    "1464": {
        "name": "80's Arkade Bulls #1464",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1464.png",
        "dna": "fc8ec9d0fed89d0aa57aa5032e176b43612b443f",
        "edition": 1464,
        "date": 1654568059920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1464",
        "rarity_score": 108.70928492190663,
        "rarity_level": "COMMON"
    },
    "1465": {
        "name": "80's Arkade Bulls #1465",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1465.png",
        "dna": "ea56ba0053871eb5440eec2c7969276e262abf5e",
        "edition": 1465,
        "date": 1654568148138,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1465",
        "rarity_score": 143.11745108168344,
        "rarity_level": "COMMON"
    },
    "1466": {
        "name": "80's Arkade Bulls #1466",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1466.png",
        "dna": "4d15cd2c3450d14a3197f2bee46ff1459b580db0",
        "edition": 1466,
        "date": 1654567189124,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1466",
        "rarity_score": 74.80294951931576,
        "rarity_level": "COMMON"
    },
    "1467": {
        "name": "80's Arkade Bulls #1467",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1467.png",
        "dna": "e761e4fa6105780c208e1683cc6249b8d40f02ff",
        "edition": 1467,
        "date": 1654567971013,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1467",
        "rarity_score": 83.41206779884754,
        "rarity_level": "COMMON"
    },
    "1468": {
        "name": "80's Arkade Bulls #1468",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1468.png",
        "dna": "cf6ed929028ef0dc51053e212591f17ae4a014d9",
        "edition": 1468,
        "date": 1654567925718,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "1468",
        "rarity_score": 91.84579369086424,
        "rarity_level": "COMMON"
    },
    "1469": {
        "name": "80's Arkade Bulls #1469",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1469.png",
        "dna": "e55abdab5cb06a7214798bda75eef13a51442f67",
        "edition": 1469,
        "date": 1654567147725,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1469",
        "rarity_score": 64.29673595763833,
        "rarity_level": "COMMON"
    },
    "1470": {
        "name": "80's Arkade Bulls #1470",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1470.png",
        "dna": "4804dcbc6b30a29bd3d7c3b20dda96bad2b58d7b",
        "edition": 1470,
        "date": 1654567344047,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1470",
        "rarity_score": 239.9094690536816,
        "rarity_level": "RARE"
    },
    "1471": {
        "name": "80's Arkade Bulls #1471",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1471.png",
        "dna": "78a9132e5d66d2f60d0354bb84149e994b425d90",
        "edition": 1471,
        "date": 1654568102863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1471",
        "rarity_score": 49.994924504613316,
        "rarity_level": "COMMON"
    },
    "1472": {
        "name": "80's Arkade Bulls #1472",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1472.png",
        "dna": "1a68db799b21c945703f6a99519fa4a5cde0881a",
        "edition": 1472,
        "date": 1654567834599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1472",
        "rarity_score": 106.57334653803804,
        "rarity_level": "COMMON"
    },
    "1473": {
        "name": "80's Arkade Bulls #1473",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1473.png",
        "dna": "a11977f807e943b69e774e77da67a60805f1b52a",
        "edition": 1473,
        "date": 1654567279598,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1473",
        "rarity_score": 98.46294167429383,
        "rarity_level": "COMMON"
    },
    "1474": {
        "name": "80's Arkade Bulls #1474",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1474.png",
        "dna": "9dd6a18a68433e42f4370a014362b52601e82e7d",
        "edition": 1474,
        "date": 1654567638838,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.625,
        "id": "1474",
        "rarity_score": 139.74408433525315,
        "rarity_level": "COMMON"
    },
    "1475": {
        "name": "80's Arkade Bulls #1475",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1475.png",
        "dna": "dbb16aaf0a94b3e40e8a68a98fa529e6eef85413",
        "edition": 1475,
        "date": 1654567219281,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1475",
        "rarity_score": 75.55286382954262,
        "rarity_level": "COMMON"
    },
    "1476": {
        "name": "80's Arkade Bulls #1476",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1476.png",
        "dna": "404a1c5e98803d55a6af5cec46f8377d4c8fadcc",
        "edition": 1476,
        "date": 1654567670724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "1476",
        "rarity_score": 107.70316318887194,
        "rarity_level": "COMMON"
    },
    "1477": {
        "name": "80's Arkade Bulls #1477",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1477.png",
        "dna": "c9a0b93f7b0db12350c60fa46c94556173ace00f",
        "edition": 1477,
        "date": 1654567138053,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1477",
        "rarity_score": 57.800112277650435,
        "rarity_level": "COMMON"
    },
    "1478": {
        "name": "80's Arkade Bulls #1478",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1478.png",
        "dna": "6ca71c835128482453fa7e903ea043ca67152215",
        "edition": 1478,
        "date": 1654567179310,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1478",
        "rarity_score": 79.08356946078749,
        "rarity_level": "COMMON"
    },
    "1479": {
        "name": "80's Arkade Bulls #1479",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1479.png",
        "dna": "2ade04e26ca686a69ad388ebfe542b1cb3fd0c41",
        "edition": 1479,
        "date": 1654568192136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1479",
        "rarity_score": 91.04494091884229,
        "rarity_level": "COMMON"
    },
    "1480": {
        "name": "80's Arkade Bulls #1480",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1480.png",
        "dna": "d452d7b52467f23335374ebda4948356fe368fd0",
        "edition": 1480,
        "date": 1654567662846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.714285714285714,
        "id": "1480",
        "rarity_score": 128.82300987023862,
        "rarity_level": "COMMON"
    },
    "1481": {
        "name": "80's Arkade Bulls #1481",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1481.png",
        "dna": "64dae570a08387225d6ac5979f099b43894f115b",
        "edition": 1481,
        "date": 1654567932318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1481",
        "rarity_score": 111.9801660807635,
        "rarity_level": "COMMON"
    },
    "1482": {
        "name": "80's Arkade Bulls #1482",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1482.png",
        "dna": "0af6d8fbfb5d0aa889885a1652973bdad7842aa2",
        "edition": 1482,
        "date": 1654567420221,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1482",
        "rarity_score": 201.63787375415282,
        "rarity_level": "COMMON"
    },
    "1483": {
        "name": "80's Arkade Bulls #1483",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1483.png",
        "dna": "a8029c50452bf8cbcf364449502de6070e53d52b",
        "edition": 1483,
        "date": 1654568165673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1483",
        "rarity_score": 242.93658765831913,
        "rarity_level": "RARE"
    },
    "1484": {
        "name": "80's Arkade Bulls #1484",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1484.png",
        "dna": "eb2def4a1efaca6b8d8c71a9a105d60210c49484",
        "edition": 1484,
        "date": 1654567209407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1484",
        "rarity_score": 92.24586485081794,
        "rarity_level": "COMMON"
    },
    "1485": {
        "name": "80's Arkade Bulls #1485",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1485.png",
        "dna": "19c491814f8061bfd00cad48947cce6dafe2a40c",
        "edition": 1485,
        "date": 1654567643750,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Super",
        "rarity": 2.25,
        "id": "1485",
        "rarity_score": 283.9864602185022,
        "rarity_level": "RARE"
    },
    "1486": {
        "name": "80's Arkade Bulls #1486",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1486.png",
        "dna": "6d542309af3e28b15a8c5ed57ddb3c0125f5da42",
        "edition": 1486,
        "date": 1654567297743,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1486",
        "rarity_score": 134.1065676308472,
        "rarity_level": "COMMON"
    },
    "1487": {
        "name": "80's Arkade Bulls #1487",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1487.png",
        "dna": "64f9498c40ae8b0c615a96227ba96792aa5bc82e",
        "edition": 1487,
        "date": 1654568058067,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1487",
        "rarity_score": 59.091337617553336,
        "rarity_level": "COMMON"
    },
    "1488": {
        "name": "80's Arkade Bulls #1488",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1488.png",
        "dna": "175f0019bd8c27b6249936494ed82393933a5570",
        "edition": 1488,
        "date": 1654568115927,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 9.8,
        "id": "1488",
        "rarity_score": 54.90901531358651,
        "rarity_level": "COMMON"
    },
    "1489": {
        "name": "80's Arkade Bulls #1489",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1489.png",
        "dna": "e0d692cc17e56bc533c6bd72a3df6e5858a5e667",
        "edition": 1489,
        "date": 1654568193691,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1489",
        "rarity_score": 84.01976868524231,
        "rarity_level": "COMMON"
    },
    "1490": {
        "name": "80's Arkade Bulls #1490",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1490.png",
        "dna": "77207765af2a9a5d3b0dd1d6ffc0978576627000",
        "edition": 1490,
        "date": 1654567889103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1490",
        "rarity_score": 205.19471811963325,
        "rarity_level": "RARE"
    },
    "1491": {
        "name": "80's Arkade Bulls #1491",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1491.png",
        "dna": "e5a20a83f3296c5b90a2204b626b2781691f992b",
        "edition": 1491,
        "date": 1654567515508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1491",
        "rarity_score": 145.51544093373707,
        "rarity_level": "COMMON"
    },
    "1492": {
        "name": "80's Arkade Bulls #1492",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1492.png",
        "dna": "c34b06a1bdb112e02773e13116cecf8b14f0b1c7",
        "edition": 1492,
        "date": 1654567456254,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1492",
        "rarity_score": 109.79775719897073,
        "rarity_level": "COMMON"
    },
    "1493": {
        "name": "80's Arkade Bulls #1493",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1493.png",
        "dna": "6a9fc05f9b368ca60aa46b5af5c0b8fdac0d0338",
        "edition": 1493,
        "date": 1654567588812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1493",
        "rarity_score": 196.44693389157933,
        "rarity_level": "COMMON"
    },
    "1494": {
        "name": "80's Arkade Bulls #1494",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1494.png",
        "dna": "57a88cad9a7329a0f3a23ff38bfb18081494a562",
        "edition": 1494,
        "date": 1654568021568,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.833333333333333,
        "id": "1494",
        "rarity_score": 145.2517146231554,
        "rarity_level": "COMMON"
    },
    "1495": {
        "name": "80's Arkade Bulls #1495",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1495.png",
        "dna": "6cf494047dc11579c7e4f9614ebc2a778a6626c1",
        "edition": 1495,
        "date": 1654567253891,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rarity": 7.666666666666666,
        "rank": "Common",
        "id": "1495",
        "rarity_score": 93.94893047795786,
        "rarity_level": "COMMON"
    },
    "1496": {
        "name": "80's Arkade Bulls #1496",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1496.png",
        "dna": "f17187d524a484f7b212bd23e0db87c4409a29fb",
        "edition": 1496,
        "date": 1654567301852,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1496",
        "rarity_score": 119.16230826714697,
        "rarity_level": "COMMON"
    },
    "1497": {
        "name": "80's Arkade Bulls #1497",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1497.png",
        "dna": "fae26c4da3caf53f83c8352689111497001a79b1",
        "edition": 1497,
        "date": 1654568025047,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1497",
        "rarity_score": 141.46538111307515,
        "rarity_level": "COMMON"
    },
    "1498": {
        "name": "80's Arkade Bulls #1498",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1498.png",
        "dna": "ddbeb467a11a3048f975e0d1469a43dbb5530f5a",
        "edition": 1498,
        "date": 1654567249037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1498",
        "rarity_score": 86.11340428389154,
        "rarity_level": "COMMON"
    },
    "1499": {
        "name": "80's Arkade Bulls #1499",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1499.png",
        "dna": "92e87e11f48f6ccd1ccfb63d9c2f6201f4652f65",
        "edition": 1499,
        "date": 1654567531964,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1499",
        "rarity_score": 163.51547898755373,
        "rarity_level": "COMMON"
    },
    "1500": {
        "name": "80's Arkade Bulls #1500",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1500.png",
        "dna": "2c304dad10f4be910a4bd77e07409f1c8dc165ed",
        "edition": 1500,
        "date": 1654568018056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1500",
        "rarity_score": 149.40920842564208,
        "rarity_level": "COMMON"
    },
    "1501": {
        "name": "80's Arkade Bulls #1501",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1501.png",
        "dna": "49202ca556bc53e36fcdf4948df38ce29209272a",
        "edition": 1501,
        "date": 1654567202769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1501",
        "rarity_score": 145.14001360096697,
        "rarity_level": "COMMON"
    },
    "1502": {
        "name": "80's Arkade Bulls #1502",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1502.png",
        "dna": "1efa0e9fabf5490d16294ececf502c58fa3d0590",
        "edition": 1502,
        "date": 1654567986367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1502",
        "rarity_score": 119.06857142857143,
        "rarity_level": "COMMON"
    },
    "1503": {
        "name": "80's Arkade Bulls #1503",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1503.png",
        "dna": "e3437a9fb9a4854326109400aaa085456f2efa23",
        "edition": 1503,
        "date": 1654567185823,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1503",
        "rarity_score": 86.75769704433498,
        "rarity_level": "COMMON"
    },
    "1504": {
        "name": "80's Arkade Bulls #1504",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1504.png",
        "dna": "10f9e04dcdc04f91ce706515c953aac11328bd1d",
        "edition": 1504,
        "date": 1654567574858,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Marty",
                "score": 152.30769230769232
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "1504",
        "rarity_score": 225.99069459850364,
        "rarity_level": "RARE"
    },
    "1505": {
        "name": "80's Arkade Bulls #1505",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1505.png",
        "dna": "eea15406cbf70caf5300fc9a48fd9baa58bd3ff6",
        "edition": 1505,
        "date": 1654567159144,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1505",
        "rarity_score": 90.66461203112227,
        "rarity_level": "COMMON"
    },
    "1506": {
        "name": "80's Arkade Bulls #1506",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1506.png",
        "dna": "9c88543eafd5c1bbfa4e14492b9336b2fb730ef7",
        "edition": 1506,
        "date": 1654567552308,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "1506",
        "rarity_score": 183.7562952159097,
        "rarity_level": "COMMON"
    },
    "1507": {
        "name": "80's Arkade Bulls #1507",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1507.png",
        "dna": "696281bd351ed32ba328fdf053eea60e077e79d8",
        "edition": 1507,
        "date": 1654567123845,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1507",
        "rarity_score": 57.978431563533384,
        "rarity_level": "COMMON"
    },
    "1508": {
        "name": "80's Arkade Bulls #1508",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1508.png",
        "dna": "d84da2f0e21f066a40991e1ffb2cacadc25189ac",
        "edition": 1508,
        "date": 1654567446576,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1508",
        "rarity_score": 102.55224973370409,
        "rarity_level": "COMMON"
    },
    "1509": {
        "name": "80's Arkade Bulls #1509",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1509.png",
        "dna": "d8c09f0658ada1d77003e4419734f3f3ea288114",
        "edition": 1509,
        "date": 1654567641297,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Epic",
        "rarity": 4.125,
        "id": "1509",
        "rarity_score": 235.64650215845043,
        "rarity_level": "RARE"
    },
    "1510": {
        "name": "80's Arkade Bulls #1510",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1510.png",
        "dna": "392f01dc3d7fc27c28661abacd3f1a20a657a8b5",
        "edition": 1510,
        "date": 1654567467349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1510",
        "rarity_score": 112.37099409612847,
        "rarity_level": "COMMON"
    },
    "1511": {
        "name": "80's Arkade Bulls #1511",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1511.png",
        "dna": "22eb36a2e04d0512cae2646df30f9071de459f6d",
        "edition": 1511,
        "date": 1654568157727,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1511",
        "rarity_score": 140.42429826921352,
        "rarity_level": "COMMON"
    },
    "1512": {
        "name": "80's Arkade Bulls #1512",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1512.png",
        "dna": "7bf1134dbc1bb23fb5140a355c4ee7d5b193a066",
        "edition": 1512,
        "date": 1654567910471,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1512",
        "rarity_score": 103.39117082351518,
        "rarity_level": "COMMON"
    },
    "1513": {
        "name": "80's Arkade Bulls #1513",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1513.png",
        "dna": "ce6b2b5ccc4a1f0cc638eaa1a1dfd6263d1728b2",
        "edition": 1513,
        "date": 1654567164650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1513",
        "rarity_score": 108.94635056467008,
        "rarity_level": "COMMON"
    },
    "1514": {
        "name": "80's Arkade Bulls #1514",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1514.png",
        "dna": "186d9edb827638aac207dfebc88fced62573408b",
        "edition": 1514,
        "date": 1654567718761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333334,
        "id": "1514",
        "rarity_score": 156.15728935042236,
        "rarity_level": "COMMON"
    },
    "1515": {
        "name": "80's Arkade Bulls #1515",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1515.png",
        "dna": "1ecb88d9db59c4d11814ca4346cecbcd8cda99be",
        "edition": 1515,
        "date": 1654567648095,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.625,
        "id": "1515",
        "rarity_score": 165.46126829046705,
        "rarity_level": "COMMON"
    },
    "1516": {
        "name": "80's Arkade Bulls #1516",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1516.png",
        "dna": "93a3b5276ac9a793890c06ffc8546ac629d023ea",
        "edition": 1516,
        "date": 1654567204277,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1516",
        "rarity_score": 83.67027052764432,
        "rarity_level": "COMMON"
    },
    "1517": {
        "name": "80's Arkade Bulls #1517",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1517.png",
        "dna": "03707348ed1f35fba400a78b3b0ccac0fb4617f9",
        "edition": 1517,
        "date": 1654568209692,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1517",
        "rarity_score": 190.17819633479797,
        "rarity_level": "COMMON"
    },
    "1518": {
        "name": "80's Arkade Bulls #1518",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1518.png",
        "dna": "9ef8fba92d8bd7e84468d0430e7e9a97cc54e466",
        "edition": 1518,
        "date": 1654567230814,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333334,
        "id": "1518",
        "rarity_score": 103.68019822722718,
        "rarity_level": "COMMON"
    },
    "1519": {
        "name": "80's Arkade Bulls #1519",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1519.png",
        "dna": "ba6adeb027fe92a567bffdb2a4e541ab2d2627a9",
        "edition": 1519,
        "date": 1654567137596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1519",
        "rarity_score": 104.789535935268,
        "rarity_level": "COMMON"
    },
    "1520": {
        "name": "80's Arkade Bulls #1520",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1520.png",
        "dna": "4b3d392372c2548dd0e14d3f017cc639a5d8d78b",
        "edition": 1520,
        "date": 1654567130899,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "1520",
        "rarity_score": 122.87228607918264,
        "rarity_level": "COMMON"
    },
    "1521": {
        "name": "80's Arkade Bulls #1521",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1521.png",
        "dna": "4994dacd19b6ef31a019122a86a18474ddea1a6c",
        "edition": 1521,
        "date": 1654568006361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1521",
        "rarity_score": 108.93166526960795,
        "rarity_level": "COMMON"
    },
    "1522": {
        "name": "80's Arkade Bulls #1522",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1522.png",
        "dna": "29fc1d8a68e00bcdb7b7c4834c5471fedd4fe946",
        "edition": 1522,
        "date": 1654567437105,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1522",
        "rarity_score": 95.9827980001718,
        "rarity_level": "COMMON"
    },
    "1523": {
        "name": "80's Arkade Bulls #1523",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1523.png",
        "dna": "13d4b9ed78a8073f43da8a4e7696243e700b756b",
        "edition": 1523,
        "date": 1654567907295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "1523",
        "rarity_score": 106.43892940557284,
        "rarity_level": "COMMON"
    },
    "1524": {
        "name": "80's Arkade Bulls #1524",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1524.png",
        "dna": "9f0a4c907eeed541bd1cb5b1f70fdbedcd683965",
        "edition": 1524,
        "date": 1654567538068,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Kad Man Hair", "score": 123.75 }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "1524",
        "rarity_score": 203.16471756228265,
        "rarity_level": "RARE"
    },
    "1525": {
        "name": "80's Arkade Bulls #1525",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1525.png",
        "dna": "88e025c9674ac7b9c1126643f1e3aa4087116ffd",
        "edition": 1525,
        "date": 1654568095849,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1525",
        "rarity_score": 197.216026272578,
        "rarity_level": "COMMON"
    },
    "1526": {
        "name": "80's Arkade Bulls #1526",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1526.png",
        "dna": "3641fa2b257b00a70dc3bd184c3ee123e795b094",
        "edition": 1526,
        "date": 1654568179032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1526",
        "rarity_score": 99.51630738761332,
        "rarity_level": "COMMON"
    },
    "1527": {
        "name": "80's Arkade Bulls #1527",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1527.png",
        "dna": "f6a2ef8c57e6018b5814ffc6327011114a30fb62",
        "edition": 1527,
        "date": 1654567689957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.285714285714286,
        "id": "1527",
        "rarity_score": 133.7583297144185,
        "rarity_level": "COMMON"
    },
    "1528": {
        "name": "80's Arkade Bulls #1528",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1528.png",
        "dna": "0bf9423d0981dd1bd0897d4e3cc0e796188c1218",
        "edition": 1528,
        "date": 1654567967516,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 2.1666666666666665,
        "rank": "Super",
        "id": "1528",
        "rarity_score": 228.45925626464418,
        "rarity_level": "RARE"
    },
    "1529": {
        "name": "80's Arkade Bulls #1529",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1529.png",
        "dna": "ae762aae7048b260ec5ea55429626adffe5e5a8d",
        "edition": 1529,
        "date": 1654567587818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1529",
        "rarity_score": 190.7128163626486,
        "rarity_level": "COMMON"
    },
    "1530": {
        "name": "80's Arkade Bulls #1530",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1530.png",
        "dna": "f164d6fc10c09c9c57185b1eb8992425dcc8fca7",
        "edition": 1530,
        "date": 1654567822174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1530",
        "rarity_score": 90.4230171846303,
        "rarity_level": "COMMON"
    },
    "1531": {
        "name": "80's Arkade Bulls #1531",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1531.png",
        "dna": "5638aaf2182acc90b8aba62a6898ed66e58c8002",
        "edition": 1531,
        "date": 1654567666716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "1531",
        "rarity_score": 143.96716907322462,
        "rarity_level": "COMMON"
    },
    "1532": {
        "name": "80's Arkade Bulls #1532",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1532.png",
        "dna": "3e071908c8f0812bb5f12ab908269d451335c713",
        "edition": 1532,
        "date": 1654567437676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1532",
        "rarity_score": 91.29768081690017,
        "rarity_level": "COMMON"
    },
    "1533": {
        "name": "80's Arkade Bulls #1533",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1533.png",
        "dna": "6114355e95dde128cf25668c68dfded694968886",
        "edition": 1533,
        "date": 1654567849136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "1533",
        "rarity_score": 82.32199317409595,
        "rarity_level": "COMMON"
    },
    "1534": {
        "name": "80's Arkade Bulls #1534",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1534.png",
        "dna": "b84e5c7008b147ac543fdb245f15b9fc53890589",
        "edition": 1534,
        "date": 1654567426456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1534",
        "rarity_score": 161.61520227927434,
        "rarity_level": "COMMON"
    },
    "1535": {
        "name": "80's Arkade Bulls #1535",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1535.png",
        "dna": "68731b6f567b3d0be332e9ddcece19a7e5dfeed2",
        "edition": 1535,
        "date": 1654568038872,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "1535",
        "rarity_score": 109.5528069693717,
        "rarity_level": "COMMON"
    },
    "1536": {
        "name": "80's Arkade Bulls #1536",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1536.png",
        "dna": "2a9d6cfa47791f0f3a578e08bf6703fef0174f14",
        "edition": 1536,
        "date": 1654568123706,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1536",
        "rarity_score": 109.89190244054903,
        "rarity_level": "COMMON"
    },
    "1537": {
        "name": "80's Arkade Bulls #1537",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1537.png",
        "dna": "4eb588fa55c5408b8c4bc7296268620fa9da347b",
        "edition": 1537,
        "date": 1654567272454,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666667,
        "id": "1537",
        "rarity_score": 111.14564280408904,
        "rarity_level": "COMMON"
    },
    "1538": {
        "name": "80's Arkade Bulls #1538",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1538.png",
        "dna": "3615aa0928114e29618f1354a306239fa0abfc33",
        "edition": 1538,
        "date": 1654567203767,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1538",
        "rarity_score": 74.00522305419447,
        "rarity_level": "COMMON"
    },
    "1539": {
        "name": "80's Arkade Bulls #1539",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1539.png",
        "dna": "48e98fa24c364d02a6942059162a0fd46d14cbdd",
        "edition": 1539,
        "date": 1654567380562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1539",
        "rarity_score": 156.4631981676276,
        "rarity_level": "COMMON"
    },
    "1540": {
        "name": "80's Arkade Bulls #1540",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1540.png",
        "dna": "f798021a1bdd69273a215eba79a238c7e17cd0ef",
        "edition": 1540,
        "date": 1654567157284,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1540",
        "rarity_score": 95.44198782813442,
        "rarity_level": "COMMON"
    },
    "1541": {
        "name": "80's Arkade Bulls #1541",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1541.png",
        "dna": "0cb7d532a49b436e183cbb280b56e80a3706db4c",
        "edition": 1541,
        "date": 1654567912106,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1541",
        "rarity_score": 120.9380964380537,
        "rarity_level": "COMMON"
    },
    "1542": {
        "name": "80's Arkade Bulls #1542",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1542.png",
        "dna": "79db4c63e3f7c372f27f7714fe9678ca00a3c9c9",
        "edition": 1542,
        "date": 1654567169346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1542",
        "rarity_score": 112.6539155815026,
        "rarity_level": "COMMON"
    },
    "1543": {
        "name": "80's Arkade Bulls #1543",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1543.png",
        "dna": "78a1b886ee9fbb5b2dd0270f6050e0b9baaf8e84",
        "edition": 1543,
        "date": 1654568131276,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 10,
        "id": "1543",
        "rarity_score": 53.40900317956155,
        "rarity_level": "COMMON"
    },
    "1544": {
        "name": "80's Arkade Bulls #1544",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1544.png",
        "dna": "0787621afff66b7021522b168f00f3859a918650",
        "edition": 1544,
        "date": 1654567234471,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "1544",
        "rarity_score": 94.40144476581835,
        "rarity_level": "COMMON"
    },
    "1545": {
        "name": "80's Arkade Bulls #1545",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1545.png",
        "dna": "54cf292d63736037be4d0bbcf22364c8b8d4a894",
        "edition": 1545,
        "date": 1654567511457,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1545",
        "rarity_score": 146.22153271273035,
        "rarity_level": "COMMON"
    },
    "1546": {
        "name": "80's Arkade Bulls #1546",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1546.png",
        "dna": "fdbcd6bae69decf83691384a9092bdccc75e4636",
        "edition": 1546,
        "date": 1654567277475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "1546",
        "rarity_score": 130.60915186902764,
        "rarity_level": "COMMON"
    },
    "1547": {
        "name": "80's Arkade Bulls #1547",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1547.png",
        "dna": "9ac24322b1a97f2abaa948c190eb961ec90d6bd3",
        "edition": 1547,
        "date": 1654567417145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "1547",
        "rarity_score": 161.61057632236356,
        "rarity_level": "COMMON"
    },
    "1548": {
        "name": "80's Arkade Bulls #1548",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1548.png",
        "dna": "2cba43a2391ddf9066466bb16f97e9d3eae0ac39",
        "edition": 1548,
        "date": 1654567851650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1548",
        "rarity_score": 114.8920867140448,
        "rarity_level": "COMMON"
    },
    "1549": {
        "name": "80's Arkade Bulls #1549",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1549.png",
        "dna": "7284d9b74cb0e383893394145a43b5583224365a",
        "edition": 1549,
        "date": 1654567858656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "1549",
        "rarity_score": 107.74761635178055,
        "rarity_level": "COMMON"
    },
    "1550": {
        "name": "80's Arkade Bulls #1550",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1550.png",
        "dna": "0b60a619f47fb54f4c3b18c2245462fe0ea39f33",
        "edition": 1550,
        "date": 1654567226001,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1550",
        "rarity_score": 69.98214261748782,
        "rarity_level": "COMMON"
    },
    "1551": {
        "name": "80's Arkade Bulls #1551",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1551.png",
        "dna": "ec7fa379cdc94b641c7692589430ba6f619ace87",
        "edition": 1551,
        "date": 1654567618615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.875,
        "id": "1551",
        "rarity_score": 107.90281788195497,
        "rarity_level": "COMMON"
    },
    "1552": {
        "name": "80's Arkade Bulls #1552",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1552.png",
        "dna": "97214ae272953bbe10f2cb1e385a97fea0616697",
        "edition": 1552,
        "date": 1654568029374,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1552",
        "rarity_score": 69.11008307101605,
        "rarity_level": "COMMON"
    },
    "1553": {
        "name": "80's Arkade Bulls #1553",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1553.png",
        "dna": "a5b12a416b8c3ad5f2aa1ba3ea5612b0a69b1773",
        "edition": 1553,
        "date": 1654567134202,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1553",
        "rarity_score": 147.47358845123617,
        "rarity_level": "COMMON"
    },
    "1554": {
        "name": "80's Arkade Bulls #1554",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1554.png",
        "dna": "01f5821acb5643d1b85e2bfcd8d9120dda0dcf99",
        "edition": 1554,
        "date": 1654567504444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1554",
        "rarity_score": 152.1580552778286,
        "rarity_level": "COMMON"
    },
    "1555": {
        "name": "80's Arkade Bulls #1555",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1555.png",
        "dna": "2694e81d0f30802d33878b55edb5fdc72994b3aa",
        "edition": 1555,
        "date": 1654568025526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1555",
        "rarity_score": 83.47879890071451,
        "rarity_level": "COMMON"
    },
    "1556": {
        "name": "80's Arkade Bulls #1556",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1556.png",
        "dna": "1d4ff61f3195bc71fb90bce10a8282c959a01779",
        "edition": 1556,
        "date": 1654567232871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.833333333333334,
        "id": "1556",
        "rarity_score": 75.95535339126118,
        "rarity_level": "COMMON"
    },
    "1557": {
        "name": "80's Arkade Bulls #1557",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1557.png",
        "dna": "a2b85b69a2643796fc43527113320a2a27794d81",
        "edition": 1557,
        "date": 1654567512022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "1557",
        "rarity_score": 158.4921297954681,
        "rarity_level": "COMMON"
    },
    "1558": {
        "name": "80's Arkade Bulls #1558",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1558.png",
        "dna": "a3fb3a7721f8d9293a6a535adba8319c657edbd6",
        "edition": 1558,
        "date": 1654568083804,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1558",
        "rarity_score": 85.0123624928753,
        "rarity_level": "COMMON"
    },
    "1559": {
        "name": "80's Arkade Bulls #1559",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1559.png",
        "dna": "d410a1da62459f9fcc97c538831abdf0819b4081",
        "edition": 1559,
        "date": 1654567467877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.714285714285714,
        "id": "1559",
        "rarity_score": 75.76538778948081,
        "rarity_level": "COMMON"
    },
    "1560": {
        "name": "80's Arkade Bulls #1560",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1560.png",
        "dna": "b79b7244fc6b01112325ae94663a17cb757fc41e",
        "edition": 1560,
        "date": 1654567872739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1560",
        "rarity_score": 123.09516513870608,
        "rarity_level": "COMMON"
    },
    "1561": {
        "name": "80's Arkade Bulls #1561",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1561.png",
        "dna": "a7c92a7075a041e80f5f51a1564b4b016f565ce1",
        "edition": 1561,
        "date": 1654568071623,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "1561",
        "rarity_score": 55.679305706293476,
        "rarity_level": "COMMON"
    },
    "1562": {
        "name": "80's Arkade Bulls #1562",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1562.png",
        "dna": "6249f8b63d88779cc8a53afd477a6c63c261af77",
        "edition": 1562,
        "date": 1654567373595,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1562",
        "rarity_score": 380.63156725627016,
        "rarity_level": "RARE"
    },
    "1563": {
        "name": "80's Arkade Bulls #1563",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1563.png",
        "dna": "5eaefa59227f09d429b732074bbd6ca6bf86594a",
        "edition": 1563,
        "date": 1654567331431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.888888888888889,
        "id": "1563",
        "rarity_score": 167.92698457733275,
        "rarity_level": "COMMON"
    },
    "1564": {
        "name": "80's Arkade Bulls #1564",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1564.png",
        "dna": "e2476be441a4ea8fcc2d1130a68edd7749788f2e",
        "edition": 1564,
        "date": 1654568077153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1564",
        "rarity_score": 78.70978339917482,
        "rarity_level": "COMMON"
    },
    "1565": {
        "name": "80's Arkade Bulls #1565",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1565.png",
        "dna": "4b6333b1e06eabc858a4ad7926dacb44815ea0df",
        "edition": 1565,
        "date": 1654567223875,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333334,
        "id": "1565",
        "rarity_score": 136.2536412354528,
        "rarity_level": "COMMON"
    },
    "1566": {
        "name": "80's Arkade Bulls #1566",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1566.png",
        "dna": "30d371b24800c0d046003f52a2d5e8bf1295940b",
        "edition": 1566,
        "date": 1654567608911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.25,
        "id": "1566",
        "rarity_score": 106.88068763081742,
        "rarity_level": "COMMON"
    },
    "1567": {
        "name": "80's Arkade Bulls #1567",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1567.png",
        "dna": "8d5fdcce18a2662d57533b9fd5ee692740bb1581",
        "edition": 1567,
        "date": 1654568073481,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "1567",
        "rarity_score": 105.6471431932917,
        "rarity_level": "COMMON"
    },
    "1568": {
        "name": "80's Arkade Bulls #1568",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1568.png",
        "dna": "231455c776a357f448da4dd044816d0fd22e5693",
        "edition": 1568,
        "date": 1654567130465,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1568",
        "rarity_score": 67.61398487019198,
        "rarity_level": "COMMON"
    },
    "1569": {
        "name": "80's Arkade Bulls #1569",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1569.png",
        "dna": "e0ed00267e35af83fb692c74db0492e7155f3197",
        "edition": 1569,
        "date": 1654567678724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rarity": 3.428571428571429,
        "rank": "Super",
        "id": "1569",
        "rarity_score": 183.36347444041138,
        "rarity_level": "COMMON"
    },
    "1570": {
        "name": "80's Arkade Bulls #1570",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1570.png",
        "dna": "ff1d735473d20e62acd1a10f664db026401e2e41",
        "edition": 1570,
        "date": 1654568065376,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1570",
        "rarity_score": 106.66067054915496,
        "rarity_level": "COMMON"
    },
    "1571": {
        "name": "80's Arkade Bulls #1571",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1571.png",
        "dna": "28ee30a81794ec2a055ab15400c13f5bc0ec9534",
        "edition": 1571,
        "date": 1654568055306,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "1571",
        "rarity_score": 119.40326253567778,
        "rarity_level": "COMMON"
    },
    "1572": {
        "name": "80's Arkade Bulls #1572",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1572.png",
        "dna": "58a39aa17e274967c88d9684dd2aae4c3085cea8",
        "edition": 1572,
        "date": 1654567896121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "1572",
        "rarity_score": 87.04696421055792,
        "rarity_level": "COMMON"
    },
    "1573": {
        "name": "80's Arkade Bulls #1573",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1573.png",
        "dna": "503a742d77d1fa3ee82891afe4312ca35757fd8f",
        "edition": 1573,
        "date": 1654567853162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1573",
        "rarity_score": 125.57895119482419,
        "rarity_level": "COMMON"
    },
    "1574": {
        "name": "80's Arkade Bulls #1574",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1574.png",
        "dna": "803e354ff25cdc73d2d5340861ef43c85a20a102",
        "edition": 1574,
        "date": 1654567184889,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1574",
        "rarity_score": 117.55723748918476,
        "rarity_level": "COMMON"
    },
    "1575": {
        "name": "80's Arkade Bulls #1575",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1575.png",
        "dna": "11235b40b0feb926c37915d1d9150a4a713770f6",
        "edition": 1575,
        "date": 1654567633974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.625,
        "id": "1575",
        "rarity_score": 144.86105772644981,
        "rarity_level": "COMMON"
    },
    "1576": {
        "name": "80's Arkade Bulls #1576",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1576.png",
        "dna": "fe211ec6c23db524185476dc9883d8d443836511",
        "edition": 1576,
        "date": 1654568073957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1576",
        "rarity_score": 128.42827169084194,
        "rarity_level": "COMMON"
    },
    "1577": {
        "name": "80's Arkade Bulls #1577",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1577.png",
        "dna": "f68d4ff923bf762c1347baae3668ed086d9379f3",
        "edition": 1577,
        "date": 1654567203253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333334,
        "id": "1577",
        "rarity_score": 164.0631461509672,
        "rarity_level": "COMMON"
    },
    "1578": {
        "name": "80's Arkade Bulls #1578",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1578.png",
        "dna": "e77785b6b1dc998d97c11fe8d5af5d13df7c40ee",
        "edition": 1578,
        "date": 1654567479071,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1578",
        "rarity_score": 172.115139476146,
        "rarity_level": "COMMON"
    },
    "1579": {
        "name": "80's Arkade Bulls #1579",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1579.png",
        "dna": "8b6d456e3d8c98ddc532ab39c8b7a6be615a3a35",
        "edition": 1579,
        "date": 1654567152265,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1579",
        "rarity_score": 100.7885585459738,
        "rarity_level": "COMMON"
    },
    "1580": {
        "name": "80's Arkade Bulls #1580",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1580.png",
        "dna": "ed13208e1bd19071b0e90d27f850abc08321ca6b",
        "edition": 1580,
        "date": 1654567461828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "1580",
        "rarity_score": 181.03029996515298,
        "rarity_level": "COMMON"
    },
    "1581": {
        "name": "80's Arkade Bulls #1581",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1581.png",
        "dna": "e214b32f1406f014dd9ac96b67d62aec2489a377",
        "edition": 1581,
        "date": 1654568097238,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1581",
        "rarity_score": 150.48909917351043,
        "rarity_level": "COMMON"
    },
    "1582": {
        "name": "80's Arkade Bulls #1582",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1582.png",
        "dna": "dd8f93eb691eb6aaa30dce1fef2d5144ce44b47e",
        "edition": 1582,
        "date": 1654567716405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1582",
        "rarity_score": 100.2004327657074,
        "rarity_level": "COMMON"
    },
    "1583": {
        "name": "80's Arkade Bulls #1583",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1583.png",
        "dna": "49d662acc76e38ce5f317e258c9134b7762639c7",
        "edition": 1583,
        "date": 1654567176571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1583",
        "rarity_score": 72.48326211107884,
        "rarity_level": "COMMON"
    },
    "1584": {
        "name": "80's Arkade Bulls #1584",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1584.png",
        "dna": "7300c08e7b15b4c5ac385c649354d57364761233",
        "edition": 1584,
        "date": 1654567185358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1584",
        "rarity_score": 67.96188264035146,
        "rarity_level": "COMMON"
    },
    "1585": {
        "name": "80's Arkade Bulls #1585",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1585.png",
        "dna": "a943b78df51cfc5f7299a2cecc0a5590e735ecf6",
        "edition": 1585,
        "date": 1654568201938,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1585",
        "rarity_score": 175.93428225654796,
        "rarity_level": "COMMON"
    },
    "1586": {
        "name": "80's Arkade Bulls #1586",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1586.png",
        "dna": "d2ddd2a577f66388a733c94fec1476241f867a6b",
        "edition": 1586,
        "date": 1654567132814,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1586",
        "rarity_score": 123.9655862344938,
        "rarity_level": "COMMON"
    },
    "1587": {
        "name": "80's Arkade Bulls #1587",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1587.png",
        "dna": "61d4bc0d6ac60d1f432e284710f8dbb2d6b37c17",
        "edition": 1587,
        "date": 1654567400017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Head",
                "value": "Inspector Kadget",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1587",
        "rarity_score": 177.6638306134679,
        "rarity_level": "COMMON"
    },
    "1588": {
        "name": "80's Arkade Bulls #1588",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1588.png",
        "dna": "2097ba461355fe2cc4ed412a06a2af6d7aaf72d6",
        "edition": 1588,
        "date": 1654567263395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1588",
        "rarity_score": 101.76839823462541,
        "rarity_level": "COMMON"
    },
    "1589": {
        "name": "80's Arkade Bulls #1589",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1589.png",
        "dna": "dd3cf893397ba0dd225de9b6f78685ddc9625ca4",
        "edition": 1589,
        "date": 1654567867708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "1589",
        "rarity_score": 103.7712252869755,
        "rarity_level": "COMMON"
    },
    "1590": {
        "name": "80's Arkade Bulls #1590",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1590.png",
        "dna": "6959c74a81d26d662395124848641221a870ca3c",
        "edition": 1590,
        "date": 1654568119031,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1590",
        "rarity_score": 62.35582641644117,
        "rarity_level": "COMMON"
    },
    "1591": {
        "name": "80's Arkade Bulls #1591",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1591.png",
        "dna": "148d23814169bb786f637d1d269fe1675b123dd7",
        "edition": 1591,
        "date": 1654568091789,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1591",
        "rarity_score": 75.41082767879823,
        "rarity_level": "COMMON"
    },
    "1592": {
        "name": "80's Arkade Bulls #1592",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1592.png",
        "dna": "db426addeb357fea304c4114c57eb2038ff53e91",
        "edition": 1592,
        "date": 1654567544604,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4.2857142857142865,
        "id": "1592",
        "rarity_score": 174.77948213370996,
        "rarity_level": "COMMON"
    },
    "1593": {
        "name": "80's Arkade Bulls #1593",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1593.png",
        "dna": "f93dd8967f4ccff057927f7eed6d4e7b43bc43c5",
        "edition": 1593,
        "date": 1654567724178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666666,
        "id": "1593",
        "rarity_score": 95.4825162023975,
        "rarity_level": "COMMON"
    },
    "1594": {
        "name": "80's Arkade Bulls #1594",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1594.png",
        "dna": "ef277b8d8010e5b8557149dfad83c20436705815",
        "edition": 1594,
        "date": 1654568112685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1594",
        "rarity_score": 72.87543158103193,
        "rarity_level": "COMMON"
    },
    "1595": {
        "name": "80's Arkade Bulls #1595",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1595.png",
        "dna": "f6a5c3316ff63de65103a22053b438fdbb189a21",
        "edition": 1595,
        "date": 1654567524149,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1595",
        "rarity_score": 162.00372184818298,
        "rarity_level": "COMMON"
    },
    "1596": {
        "name": "80's Arkade Bulls #1596",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1596.png",
        "dna": "053e1ad4a2ac2b6e8dc6bfc6e7f424bf81828e2c",
        "edition": 1596,
        "date": 1654567678219,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "1596",
        "rarity_score": 112.46537119945279,
        "rarity_level": "COMMON"
    },
    "1597": {
        "name": "80's Arkade Bulls #1597",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1597.png",
        "dna": "946a30f1afa63d66d4549fecb0b052cac47f1699",
        "edition": 1597,
        "date": 1654567905654,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 6.428571428571429,
        "rank": "Common",
        "id": "1597",
        "rarity_score": 123.156726854907,
        "rarity_level": "COMMON"
    },
    "1598": {
        "name": "80's Arkade Bulls #1598",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1598.png",
        "dna": "3fb22baedae1dd1a71f21f0aa2b6bd66067c5715",
        "edition": 1598,
        "date": 1654567222668,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333334,
        "id": "1598",
        "rarity_score": 101.65247624758577,
        "rarity_level": "COMMON"
    },
    "1599": {
        "name": "80's Arkade Bulls #1599",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1599.png",
        "dna": "4edcda37e4dfb6aef8da3335d755a41789d4f36d",
        "edition": 1599,
        "date": 1654568234550,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Super",
        "rarity": 2.8,
        "id": "1599",
        "rarity_score": 209.5869619000495,
        "rarity_level": "RARE"
    },
    "1600": {
        "name": "80's Arkade Bulls #1600",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1600.png",
        "dna": "305249495d59352cd88ee94f79316c7b4a35d06e",
        "edition": 1600,
        "date": 1654567494763,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.428571428571429,
        "id": "1600",
        "rarity_score": 232.78281846066832,
        "rarity_level": "RARE"
    },
    "1601": {
        "name": "80's Arkade Bulls #1601",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1601.png",
        "dna": "06835a608a619fcdb9dd069243e046a608b1fddc",
        "edition": 1601,
        "date": 1654567546263,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "1601",
        "rarity_score": 219.20654934853718,
        "rarity_level": "RARE"
    },
    "1602": {
        "name": "80's Arkade Bulls #1602",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1602.png",
        "dna": "42e632131952a7a7cf78263216299510c8dcf869",
        "edition": 1602,
        "date": 1654567875851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1602",
        "rarity_score": 157.06381753331922,
        "rarity_level": "COMMON"
    },
    "1603": {
        "name": "80's Arkade Bulls #1603",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1603.png",
        "dna": "aa4005a1c9e6e061e69204e7f493eb7885dcbd7c",
        "edition": 1603,
        "date": 1654568223090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Super",
        "rarity": 1.2000000000000002,
        "id": "1603",
        "rarity_score": 310.3799655568312,
        "rarity_level": "RARE"
    },
    "1604": {
        "name": "80's Arkade Bulls #1604",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1604.png",
        "dna": "b932a3d60932b7dfdf6404b5b5a315faa324b1d0",
        "edition": 1604,
        "date": 1654567668457,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.714285714285714,
        "id": "1604",
        "rarity_score": 118.73914029399785,
        "rarity_level": "COMMON"
    },
    "1605": {
        "name": "80's Arkade Bulls #1605",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1605.png",
        "dna": "350243124282a67434b3c09c69521e5f9305cf1d",
        "edition": 1605,
        "date": 1654567714329,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1605",
        "rarity_score": 84.7652930245539,
        "rarity_level": "COMMON"
    },
    "1606": {
        "name": "80's Arkade Bulls #1606",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1606.png",
        "dna": "60fe0a4dbc934ea2e031a1123d19771d69e43964",
        "edition": 1606,
        "date": 1654568170742,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 2.166666666666667,
        "id": "1606",
        "rarity_score": 244.42376707829604,
        "rarity_level": "RARE"
    },
    "1607": {
        "name": "80's Arkade Bulls #1607",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1607.png",
        "dna": "9cc5be2a2a1beba0d0435cd39ba4747bc6f23e8a",
        "edition": 1607,
        "date": 1654567417632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1607",
        "rarity_score": 184.54852833070248,
        "rarity_level": "COMMON"
    },
    "1608": {
        "name": "80's Arkade Bulls #1608",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1608.png",
        "dna": "33914562200977f8589ab5b6823d7eb10d7cc16d",
        "edition": 1608,
        "date": 1654567227606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666666,
        "id": "1608",
        "rarity_score": 111.80972389107518,
        "rarity_level": "COMMON"
    },
    "1609": {
        "name": "80's Arkade Bulls #1609",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1609.png",
        "dna": "f8dd088b3c0624545f212ee5df11261da939e37a",
        "edition": 1609,
        "date": 1654568212801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666667,
        "id": "1609",
        "rarity_score": 113.2974678760393,
        "rarity_level": "COMMON"
    },
    "1610": {
        "name": "80's Arkade Bulls #1610",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1610.png",
        "dna": "5eb154a2fa9697ffe6ad19b9f6d4c78f099fe495",
        "edition": 1610,
        "date": 1654567868716,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "1610",
        "rarity_score": 188.97450024739243,
        "rarity_level": "COMMON"
    },
    "1611": {
        "name": "80's Arkade Bulls #1611",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1611.png",
        "dna": "a938511eef1d029019d733e685e0a04523162063",
        "edition": 1611,
        "date": 1654567270771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1611",
        "rarity_score": 86.93204270003747,
        "rarity_level": "COMMON"
    },
    "1612": {
        "name": "80's Arkade Bulls #1612",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1612.png",
        "dna": "6004aad0c51ae09f21944ff853e3554c80b3951b",
        "edition": 1612,
        "date": 1654568115005,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1612",
        "rarity_score": 63.6528983846947,
        "rarity_level": "COMMON"
    },
    "1613": {
        "name": "80's Arkade Bulls #1613",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1613.png",
        "dna": "192f87e7181d1e31edb4953da5b5fbaa79cb5c78",
        "edition": 1613,
        "date": 1654568066260,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "1613",
        "rarity_score": 53.533418527344764,
        "rarity_level": "COMMON"
    },
    "1614": {
        "name": "80's Arkade Bulls #1614",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1614.png",
        "dna": "356cefa67af30c6b67784cf99c592ff7ae98a212",
        "edition": 1614,
        "date": 1654567127629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1614",
        "rarity_score": 62.38787375415283,
        "rarity_level": "COMMON"
    },
    "1615": {
        "name": "80's Arkade Bulls #1615",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1615.png",
        "dna": "5ce253405a140c13ca912361741c5d8f306efae6",
        "edition": 1615,
        "date": 1654567165107,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1615",
        "rarity_score": 67.84048751051952,
        "rarity_level": "COMMON"
    },
    "1616": {
        "name": "80's Arkade Bulls #1616",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1616.png",
        "dna": "d01c8f0555b096de56395d528786b958b5911783",
        "edition": 1616,
        "date": 1654567308928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1616",
        "rarity_score": 228.49978752352038,
        "rarity_level": "RARE"
    },
    "1617": {
        "name": "80's Arkade Bulls #1617",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1617.png",
        "dna": "c50d0200f6139be3bc4476f09e1af80c647ced45",
        "edition": 1617,
        "date": 1654567267026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1617",
        "rarity_score": 122.92660156658772,
        "rarity_level": "COMMON"
    },
    "1618": {
        "name": "80's Arkade Bulls #1618",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1618.png",
        "dna": "a13b2b2398392689c46285f8d6414e705aa21659",
        "edition": 1618,
        "date": 1654567701661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "1618",
        "rarity_score": 136.95009871990342,
        "rarity_level": "COMMON"
    },
    "1619": {
        "name": "80's Arkade Bulls #1619",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1619.png",
        "dna": "be8e98edeb2a3eb2ed8a9ade85dbf7ac0e774d8f",
        "edition": 1619,
        "date": 1654567304406,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1619",
        "rarity_score": 116.30186657786086,
        "rarity_level": "COMMON"
    },
    "1620": {
        "name": "80's Arkade Bulls #1620",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1620.png",
        "dna": "10ec21a1e45f3f57d5a22f0431d1f8dcc9315934",
        "edition": 1620,
        "date": 1654568098173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1620",
        "rarity_score": 101.24015541700551,
        "rarity_level": "COMMON"
    },
    "1621": {
        "name": "80's Arkade Bulls #1621",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1621.png",
        "dna": "db9eb7e435f0d12f8c7e0cc49ae8b1b1ae7c2a9d",
        "edition": 1621,
        "date": 1654567452209,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1621",
        "rarity_score": 103.91895730490327,
        "rarity_level": "COMMON"
    },
    "1622": {
        "name": "80's Arkade Bulls #1622",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1622.png",
        "dna": "d7e0068bcc2abec226863b25e6446ded01e3eae1",
        "edition": 1622,
        "date": 1654567288739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1622",
        "rarity_score": 220.47993947244575,
        "rarity_level": "RARE"
    },
    "1623": {
        "name": "80's Arkade Bulls #1623",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1623.png",
        "dna": "e5ea2f9caa22dca0df42406de4456f772f7a92dd",
        "edition": 1623,
        "date": 1654567199814,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1623",
        "rarity_score": 71.81582272152991,
        "rarity_level": "COMMON"
    },
    "1624": {
        "name": "80's Arkade Bulls #1624",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1624.png",
        "dna": "8e68da968f52626673f6ef117b5bc0b1caff596f",
        "edition": 1624,
        "date": 1654567940557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1624",
        "rarity_score": 118.6535604508247,
        "rarity_level": "COMMON"
    },
    "1625": {
        "name": "80's Arkade Bulls #1625",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1625.png",
        "dna": "04be708d04bddcfb256011ec289acfbbc0dcb16b",
        "edition": 1625,
        "date": 1654567892899,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1625",
        "rarity_score": 94.14268614676126,
        "rarity_level": "COMMON"
    },
    "1626": {
        "name": "80's Arkade Bulls #1626",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1626.png",
        "dna": "ad7f9c6413f5ca8fd63a57a9661dfb4cdf1c0525",
        "edition": 1626,
        "date": 1654567258778,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Spiderbull Suit",
                "score": 110.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1626",
        "rarity_score": 181.07858274861476,
        "rarity_level": "COMMON"
    },
    "1627": {
        "name": "80's Arkade Bulls #1627",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1627.png",
        "dna": "f07aafec06988a3a198613abd5aae7a4ea2cd872",
        "edition": 1627,
        "date": 1654567854132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1627",
        "rarity_score": 125.10132069303702,
        "rarity_level": "COMMON"
    },
    "1628": {
        "name": "80's Arkade Bulls #1628",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1628.png",
        "dna": "d3c806af1469d4a87446a4cd05089703b3fcbe19",
        "edition": 1628,
        "date": 1654568199861,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1628",
        "rarity_score": 128.11256604238903,
        "rarity_level": "COMMON"
    },
    "1629": {
        "name": "80's Arkade Bulls #1629",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1629.png",
        "dna": "c9cb1d7cd06fdfc1077c040be53b49e3f1d920a8",
        "edition": 1629,
        "date": 1654568065839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1629",
        "rarity_score": 71.43992084510508,
        "rarity_level": "COMMON"
    },
    "1630": {
        "name": "80's Arkade Bulls #1630",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1630.png",
        "dna": "5227155525fd636479cbea97b18e1731547e0301",
        "edition": 1630,
        "date": 1654567962695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1630",
        "rarity_score": 155.55218698742482,
        "rarity_level": "COMMON"
    },
    "1631": {
        "name": "80's Arkade Bulls #1631",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1631.png",
        "dna": "598e67a43d37831e24f83354830d1afbbe457b0c",
        "edition": 1631,
        "date": 1654568073014,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "1631",
        "rarity_score": 78.11591478696741,
        "rarity_level": "COMMON"
    },
    "1632": {
        "name": "80's Arkade Bulls #1632",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1632.png",
        "dna": "506930fdc99f2a84906c4ab92b525bef58a08a9a",
        "edition": 1632,
        "date": 1654567600896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Forest Tunic Hat", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1632",
        "rarity_score": 207.62317595682447,
        "rarity_level": "RARE"
    },
    "1633": {
        "name": "80's Arkade Bulls #1633",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1633.png",
        "dna": "1978231e490f4fb98ecccb88ee931212256af050",
        "edition": 1633,
        "date": 1654567234981,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "1633",
        "rarity_score": 84.6864839194595,
        "rarity_level": "COMMON"
    },
    "1634": {
        "name": "80's Arkade Bulls #1634",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1634.png",
        "dna": "8c0e5a759828db94165f7aa5caff29c512679bc8",
        "edition": 1634,
        "date": 1654568069458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1634",
        "rarity_score": 87.14014620968288,
        "rarity_level": "COMMON"
    },
    "1635": {
        "name": "80's Arkade Bulls #1635",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1635.png",
        "dna": "3fbd6f257d1c351da7a7acf135059bc5761099de",
        "edition": 1635,
        "date": 1654568040709,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.4000000000000004,
        "id": "1635",
        "rarity_score": 149.68685713158322,
        "rarity_level": "COMMON"
    },
    "1636": {
        "name": "80's Arkade Bulls #1636",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1636.png",
        "dna": "aa576408d343bbee58b3beee74a7ff7ebb3a53a5",
        "edition": 1636,
        "date": 1654568032958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1636",
        "rarity_score": 63.525864499739406,
        "rarity_level": "COMMON"
    },
    "1637": {
        "name": "80's Arkade Bulls #1637",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1637.png",
        "dna": "330ef30258b5050d6fae40c6d0dc6e1f627d0631",
        "edition": 1637,
        "date": 1654567840039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1637",
        "rarity_score": 107.92667804696484,
        "rarity_level": "COMMON"
    },
    "1638": {
        "name": "80's Arkade Bulls #1638",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1638.png",
        "dna": "f0c69af2de3c9cf92cf27db97620a4eead6f5aa3",
        "edition": 1638,
        "date": 1654567990953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1638",
        "rarity_score": 90.08476483588224,
        "rarity_level": "COMMON"
    },
    "1639": {
        "name": "80's Arkade Bulls #1639",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1639.png",
        "dna": "f877e155ed5a6f1be9bff1ea0090e625d6e58649",
        "edition": 1639,
        "date": 1654567252082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1639",
        "rarity_score": 86.25928046416158,
        "rarity_level": "COMMON"
    },
    "1640": {
        "name": "80's Arkade Bulls #1640",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1640.png",
        "dna": "229c1fe8078572d0549058368efa6c5b8b3eae57",
        "edition": 1640,
        "date": 1654567418123,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Super",
        "rarity": 3.5,
        "id": "1640",
        "rarity_score": 216.1386986019962,
        "rarity_level": "RARE"
    },
    "1641": {
        "name": "80's Arkade Bulls #1641",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1641.png",
        "dna": "f4ea62ec6af28b7695eb66000eae7c70892a2e1b",
        "edition": 1641,
        "date": 1654568232143,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 1,
        "id": "1641",
        "rarity_score": 275.45471099377687,
        "rarity_level": "RARE"
    },
    "1642": {
        "name": "80's Arkade Bulls #1642",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1642.png",
        "dna": "7c6ea5047a2f80348f9f6c4e3973aeaede5a5473",
        "edition": 1642,
        "date": 1654567912660,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1642",
        "rarity_score": 115.25357761186092,
        "rarity_level": "COMMON"
    },
    "1643": {
        "name": "80's Arkade Bulls #1643",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1643.png",
        "dna": "4f209b0e67f5b0fb08f9feae8484a04aa1b4a4d6",
        "edition": 1643,
        "date": 1654568034425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1643",
        "rarity_score": 77.62778748884051,
        "rarity_level": "COMMON"
    },
    "1644": {
        "name": "80's Arkade Bulls #1644",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1644.png",
        "dna": "5727081fd0050641c22dc7fef0cccda939f716a4",
        "edition": 1644,
        "date": 1654567719321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1644",
        "rarity_score": 131.8019070655082,
        "rarity_level": "COMMON"
    },
    "1645": {
        "name": "80's Arkade Bulls #1645",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1645.png",
        "dna": "a2b1d6656d9212a4e1612c6fb80bc66b8030d9e1",
        "edition": 1645,
        "date": 1654567402478,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1645",
        "rarity_score": 167.62797243739868,
        "rarity_level": "COMMON"
    },
    "1646": {
        "name": "80's Arkade Bulls #1646",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1646.png",
        "dna": "7202f3200c904d8650f0588ed6238a92543bc371",
        "edition": 1646,
        "date": 1654567470121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.571428571428571,
        "id": "1646",
        "rarity_score": 171.31923387911866,
        "rarity_level": "COMMON"
    },
    "1647": {
        "name": "80's Arkade Bulls #1647",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1647.png",
        "dna": "40ffa200cb243514f48e5ae2a69f58d989a950cd",
        "edition": 1647,
        "date": 1654568058536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1647",
        "rarity_score": 79.48243347243441,
        "rarity_level": "COMMON"
    },
    "1648": {
        "name": "80's Arkade Bulls #1648",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1648.png",
        "dna": "f54439d4c49dad71b49c82f6a99c68fe71e256c8",
        "edition": 1648,
        "date": 1654567978571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1648",
        "rarity_score": 138.67357472021064,
        "rarity_level": "COMMON"
    },
    "1649": {
        "name": "80's Arkade Bulls #1649",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1649.png",
        "dna": "1be17faa845fa749d7ba4691536af508a145febf",
        "edition": 1649,
        "date": 1654567528151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "1649",
        "rarity_score": 146.0303416499539,
        "rarity_level": "COMMON"
    },
    "1650": {
        "name": "80's Arkade Bulls #1650",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1650.png",
        "dna": "5627ce4e412c41b8a22d19f354790538837d8933",
        "edition": 1650,
        "date": 1654567590848,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1650",
        "rarity_score": 214.31932773109241,
        "rarity_level": "RARE"
    },
    "1651": {
        "name": "80's Arkade Bulls #1651",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1651.png",
        "dna": "fadd4c2737911e5cf4cb14506dfaaf9b79ab9b86",
        "edition": 1651,
        "date": 1654568061783,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "1651",
        "rarity_score": 59.71095846565477,
        "rarity_level": "COMMON"
    },
    "1652": {
        "name": "80's Arkade Bulls #1652",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1652.png",
        "dna": "daeb10341f097804e49f7c35a222a35bf37cc5dd",
        "edition": 1652,
        "date": 1654567644365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.875,
        "id": "1652",
        "rarity_score": 121.32582278852604,
        "rarity_level": "COMMON"
    },
    "1653": {
        "name": "80's Arkade Bulls #1653",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1653.png",
        "dna": "96a6e824a196ccd075644b725484172d11ced9a7",
        "edition": 1653,
        "date": 1654567710199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333334,
        "id": "1653",
        "rarity_score": 129.00670477464234,
        "rarity_level": "COMMON"
    },
    "1654": {
        "name": "80's Arkade Bulls #1654",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1654.png",
        "dna": "6a00433f1e05f06adabd89aaf2104c924c545283",
        "edition": 1654,
        "date": 1654567649307,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.875,
        "id": "1654",
        "rarity_score": 126.59411541462862,
        "rarity_level": "COMMON"
    },
    "1655": {
        "name": "80's Arkade Bulls #1655",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1655.png",
        "dna": "e2ae53d33694793c866c289ccd8b00e1a89d3268",
        "edition": 1655,
        "date": 1654568016025,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1655",
        "rarity_score": 98.63586339524605,
        "rarity_level": "COMMON"
    },
    "1656": {
        "name": "80's Arkade Bulls #1656",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1656.png",
        "dna": "8cc8d29dc9e5b460a594255e72537a5acc1edd3b",
        "edition": 1656,
        "date": 1654567175193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1656",
        "rarity_score": 58.19254031957935,
        "rarity_level": "COMMON"
    },
    "1657": {
        "name": "80's Arkade Bulls #1657",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1657.png",
        "dna": "c83d6183a4402376df728df112e35433c02c74fa",
        "edition": 1657,
        "date": 1654567255492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1657",
        "rarity_score": 155.59013188398333,
        "rarity_level": "COMMON"
    },
    "1658": {
        "name": "80's Arkade Bulls #1658",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1658.png",
        "dna": "812e94897a404fc6578d82fb5a29f6275c889cf8",
        "edition": 1658,
        "date": 1654567864101,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1658",
        "rarity_score": 103.15806073679,
        "rarity_level": "COMMON"
    },
    "1659": {
        "name": "80's Arkade Bulls #1659",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1659.png",
        "dna": "f4b9c6a2ded5eb9e20febbb8482e6f87786a729c",
        "edition": 1659,
        "date": 1654567430888,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1659",
        "rarity_score": 94.49427945766791,
        "rarity_level": "COMMON"
    },
    "1660": {
        "name": "80's Arkade Bulls #1660",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1660.png",
        "dna": "c68571bfee5a9cf40f45a398491448b94ade3823",
        "edition": 1660,
        "date": 1654568136362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1660",
        "rarity_score": 57.750543391898525,
        "rarity_level": "COMMON"
    },
    "1661": {
        "name": "80's Arkade Bulls #1661",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1661.png",
        "dna": "00a019c08a7516c116fd157d5b604ab76d61784d",
        "edition": 1661,
        "date": 1654567184028,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1661",
        "rarity_score": 190.92157953281423,
        "rarity_level": "COMMON"
    },
    "1662": {
        "name": "80's Arkade Bulls #1662",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1662.png",
        "dna": "8245510a5be43ec697e9be8f807e820533a61ad4",
        "edition": 1662,
        "date": 1654567398530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1662",
        "rarity_score": 154.69246066405694,
        "rarity_level": "COMMON"
    },
    "1663": {
        "name": "80's Arkade Bulls #1663",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1663.png",
        "dna": "7b8ab10b4bb4efbebb23aa014a740aa7b7ebf674",
        "edition": 1663,
        "date": 1654568044354,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.800000000000001,
        "id": "1663",
        "rarity_score": 92.55677841104395,
        "rarity_level": "COMMON"
    },
    "1664": {
        "name": "80's Arkade Bulls #1664",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1664.png",
        "dna": "167d92450976c9925536584abe101e229c519a7a",
        "edition": 1664,
        "date": 1654567242808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1664",
        "rarity_score": 82.35607386636795,
        "rarity_level": "COMMON"
    },
    "1665": {
        "name": "80's Arkade Bulls #1665",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1665.png",
        "dna": "2507fc04d19f2383b4913c33296fbd13023968c4",
        "edition": 1665,
        "date": 1654567832501,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.166666666666666,
        "id": "1665",
        "rarity_score": 64.95013556065183,
        "rarity_level": "COMMON"
    },
    "1666": {
        "name": "80's Arkade Bulls #1666",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1666.png",
        "dna": "52306274f59baf61ffc35909af6b37589e73b611",
        "edition": 1666,
        "date": 1654567156817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1666",
        "rarity_score": 116.47086420775207,
        "rarity_level": "COMMON"
    },
    "1667": {
        "name": "80's Arkade Bulls #1667",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1667.png",
        "dna": "a64ded3d7e1f378ca993c8252fc14bfc464f4016",
        "edition": 1667,
        "date": 1654567368953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 3.7777777777777777,
        "id": "1667",
        "rarity_score": 272.9166713222378,
        "rarity_level": "RARE"
    },
    "1668": {
        "name": "80's Arkade Bulls #1668",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1668.png",
        "dna": "5b621212d83f60e6e00dff411248d48762dfee38",
        "edition": 1668,
        "date": 1654568048479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.6,
        "id": "1668",
        "rarity_score": 80.745476673428,
        "rarity_level": "COMMON"
    },
    "1669": {
        "name": "80's Arkade Bulls #1669",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1669.png",
        "dna": "635f25ec1a2c2741f3a4c45f5fa0d186842f1770",
        "edition": 1669,
        "date": 1654567144485,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1669",
        "rarity_score": 66.38253731236047,
        "rarity_level": "COMMON"
    },
    "1670": {
        "name": "80's Arkade Bulls #1670",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1670.png",
        "dna": "faebe61fff5e50243f8af1e9ded97dff94ddbdf7",
        "edition": 1670,
        "date": 1654567956675,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1670",
        "rarity_score": 165.71945235892767,
        "rarity_level": "COMMON"
    },
    "1671": {
        "name": "80's Arkade Bulls #1671",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1671.png",
        "dna": "1012cc8d056dd97613580128b258153bcdfffe85",
        "edition": 1671,
        "date": 1654568196215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1671",
        "rarity_score": 99.13346072157887,
        "rarity_level": "COMMON"
    },
    "1672": {
        "name": "80's Arkade Bulls #1672",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1672.png",
        "dna": "732b244ca349b7eef6b09714861a8bf766f41495",
        "edition": 1672,
        "date": 1654567388921,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1672",
        "rarity_score": 148.36095119858783,
        "rarity_level": "COMMON"
    },
    "1673": {
        "name": "80's Arkade Bulls #1673",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1673.png",
        "dna": "7e6e9242290b93e5b5eeac6ebf305367784da664",
        "edition": 1673,
        "date": 1654568128005,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1673",
        "rarity_score": 69.02745403467256,
        "rarity_level": "COMMON"
    },
    "1674": {
        "name": "80's Arkade Bulls #1674",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1674.png",
        "dna": "436b96c4c9312280a0026432938485631e88ba2c",
        "edition": 1674,
        "date": 1654567654385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857143,
        "id": "1674",
        "rarity_score": 167.02235165752063,
        "rarity_level": "COMMON"
    },
    "1675": {
        "name": "80's Arkade Bulls #1675",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1675.png",
        "dna": "5043cca92338c8cecdb45f475daea4f9c8719ff2",
        "edition": 1675,
        "date": 1654567488255,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.571428571428571,
        "id": "1675",
        "rarity_score": 182.39570306343953,
        "rarity_level": "COMMON"
    },
    "1676": {
        "name": "80's Arkade Bulls #1676",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1676.png",
        "dna": "810f3abb5242105720c2f0aaae450e304a4b50fd",
        "edition": 1676,
        "date": 1654567873257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8.142857142857142,
        "id": "1676",
        "rarity_score": 95.85577578990153,
        "rarity_level": "COMMON"
    },
    "1677": {
        "name": "80's Arkade Bulls #1677",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1677.png",
        "dna": "e2ae3627d19b7950a9b87965c13667a74a225d56",
        "edition": 1677,
        "date": 1654567936151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1677",
        "rarity_score": 98.22174132285896,
        "rarity_level": "COMMON"
    },
    "1678": {
        "name": "80's Arkade Bulls #1678",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1678.png",
        "dna": "716ec0f33d2d70b6e0d0d3b8b810bfa69db2698d",
        "edition": 1678,
        "date": 1654567476827,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1678",
        "rarity_score": 114.98875718242283,
        "rarity_level": "COMMON"
    },
    "1679": {
        "name": "80's Arkade Bulls #1679",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1679.png",
        "dna": "33da106c6a55bfcf06797ed7dffa12e8b30184fc",
        "edition": 1679,
        "date": 1654567914198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1679",
        "rarity_score": 91.13554599582167,
        "rarity_level": "COMMON"
    },
    "1680": {
        "name": "80's Arkade Bulls #1680",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1680.png",
        "dna": "ef0166f993cb139efde69ef978ae320e16a7cb94",
        "edition": 1680,
        "date": 1654567317580,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Super",
        "rarity": 3.4444444444444446,
        "id": "1680",
        "rarity_score": 340.43338220654584,
        "rarity_level": "RARE"
    },
    "1681": {
        "name": "80's Arkade Bulls #1681",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1681.png",
        "dna": "f3b8d711e5c2f11c370cb8418e28bbad4e56ae38",
        "edition": 1681,
        "date": 1654568205096,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1681",
        "rarity_score": 137.94333708661318,
        "rarity_level": "COMMON"
    },
    "1682": {
        "name": "80's Arkade Bulls #1682",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1682.png",
        "dna": "178dcbb2dc6132e95b5c7fa237516913bdcd148a",
        "edition": 1682,
        "date": 1654567520292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1682",
        "rarity_score": 153.56798544693498,
        "rarity_level": "COMMON"
    },
    "1683": {
        "name": "80's Arkade Bulls #1683",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1683.png",
        "dna": "caaf1f2287c48dbcd2e0219d8defe8bb4b48db9c",
        "edition": 1683,
        "date": 1654568173321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1683",
        "rarity_score": 142.81347814231015,
        "rarity_level": "COMMON"
    },
    "1684": {
        "name": "80's Arkade Bulls #1684",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1684.png",
        "dna": "da0788d8e864cd3e0df2bec07b8c7319445eccf7",
        "edition": 1684,
        "date": 1654568198817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1684",
        "rarity_score": 143.84895420141657,
        "rarity_level": "COMMON"
    },
    "1685": {
        "name": "80's Arkade Bulls #1685",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1685.png",
        "dna": "f3913665bed6fe249bc2a1b991293176a9c93772",
        "edition": 1685,
        "date": 1654567371567,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rarity": 5.666666666666667,
        "rank": "Rare",
        "id": "1685",
        "rarity_score": 201.4265929729508,
        "rarity_level": "COMMON"
    },
    "1686": {
        "name": "80's Arkade Bulls #1686",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1686.png",
        "dna": "4c5289603b81950cf6fc4e26d20b34d3c8d4fce1",
        "edition": 1686,
        "date": 1654567641937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.625,
        "id": "1686",
        "rarity_score": 159.5486916119519,
        "rarity_level": "COMMON"
    },
    "1687": {
        "name": "80's Arkade Bulls #1687",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1687.png",
        "dna": "faa4ad276167a2b567bfa1cb820f4f421428b3fe",
        "edition": 1687,
        "date": 1654568099102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1687",
        "rarity_score": 76.70546412113232,
        "rarity_level": "COMMON"
    },
    "1688": {
        "name": "80's Arkade Bulls #1688",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1688.png",
        "dna": "407a9b487ef4ca728c27a1ba033b7dcf61c928e6",
        "edition": 1688,
        "date": 1654568013983,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rarity": 5,
        "rank": "Rare",
        "id": "1688",
        "rarity_score": 242.96547921302604,
        "rarity_level": "RARE"
    },
    "1689": {
        "name": "80's Arkade Bulls #1689",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1689.png",
        "dna": "065e124749db17f09d07a93036d44299c72f570e",
        "edition": 1689,
        "date": 1654567446021,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "1689",
        "rarity_score": 85.29575877599416,
        "rarity_level": "COMMON"
    },
    "1690": {
        "name": "80's Arkade Bulls #1690",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1690.png",
        "dna": "fc3b95682ea569fe50cef0b9719068e8eb051a0b",
        "edition": 1690,
        "date": 1654567945596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1690",
        "rarity_score": 93.02155358770517,
        "rarity_level": "COMMON"
    },
    "1691": {
        "name": "80's Arkade Bulls #1691",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1691.png",
        "dna": "42f23a4dddeea3c7eb004d908c477d3777ab2149",
        "edition": 1691,
        "date": 1654567330134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.444444444444445,
        "id": "1691",
        "rarity_score": 246.39411289195016,
        "rarity_level": "RARE"
    },
    "1692": {
        "name": "80's Arkade Bulls #1692",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1692.png",
        "dna": "eb6419512e318fda0b114ac772be6bc7621d5a9b",
        "edition": 1692,
        "date": 1654567591862,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1692",
        "rarity_score": 284.0637058793741,
        "rarity_level": "RARE"
    },
    "1693": {
        "name": "80's Arkade Bulls #1693",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1693.png",
        "dna": "25010f872720b7ec08adb452aa2b03b143c2acd1",
        "edition": 1693,
        "date": 1654567170357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1693",
        "rarity_score": 78.513133640553,
        "rarity_level": "COMMON"
    },
    "1694": {
        "name": "80's Arkade Bulls #1694",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1694.png",
        "dna": "77a2918004e6aaaa1ff75f06cbf32a814081bc7e",
        "edition": 1694,
        "date": 1654567546841,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857143,
        "id": "1694",
        "rarity_score": 165.0146488664169,
        "rarity_level": "COMMON"
    },
    "1695": {
        "name": "80's Arkade Bulls #1695",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1695.png",
        "dna": "1c185952ce01f3e64fa2272a2fe55722b46237b0",
        "edition": 1695,
        "date": 1654567986871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1695",
        "rarity_score": 89.50746583313963,
        "rarity_level": "COMMON"
    },
    "1696": {
        "name": "80's Arkade Bulls #1696",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1696.png",
        "dna": "85cc02eabc6e09de7ae7c733acbdceaa4a66690d",
        "edition": 1696,
        "date": 1654567303880,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1696",
        "rarity_score": 187.29755857670995,
        "rarity_level": "COMMON"
    },
    "1697": {
        "name": "80's Arkade Bulls #1697",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1697.png",
        "dna": "113c9bae900c6f6063af32925bec1fed77f411a4",
        "edition": 1697,
        "date": 1654567990438,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1697",
        "rarity_score": 94.10822462516293,
        "rarity_level": "COMMON"
    },
    "1698": {
        "name": "80's Arkade Bulls #1698",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1698.png",
        "dna": "b059bdfcc89f962d32451f3b1caa5177029eec83",
        "edition": 1698,
        "date": 1654568022082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1698",
        "rarity_score": 76.90698010697133,
        "rarity_level": "COMMON"
    },
    "1699": {
        "name": "80's Arkade Bulls #1699",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1699.png",
        "dna": "c08752a3335ce834b8c7fdfc24ff65f7a9584d31",
        "edition": 1699,
        "date": 1654567178854,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 6.8,
        "id": "1699",
        "rarity_score": 73.24174510518891,
        "rarity_level": "COMMON"
    },
    "1700": {
        "name": "80's Arkade Bulls #1700",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1700.png",
        "dna": "d746dc012fb5a9c59065377a5a18a8cba35e915d",
        "edition": 1700,
        "date": 1654567295133,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1700",
        "rarity_score": 101.98082934449106,
        "rarity_level": "COMMON"
    },
    "1701": {
        "name": "80's Arkade Bulls #1701",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1701.png",
        "dna": "befbaf07233388dfe9297664e3a5bc59e839590b",
        "edition": 1701,
        "date": 1654567713258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333334,
        "id": "1701",
        "rarity_score": 120.5359866908183,
        "rarity_level": "COMMON"
    },
    "1702": {
        "name": "80's Arkade Bulls #1702",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1702.png",
        "dna": "99a55744477b0b426a97ff2a14b0d078f0fd3bf8",
        "edition": 1702,
        "date": 1654567485906,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "1702",
        "rarity_score": 191.57119023213346,
        "rarity_level": "COMMON"
    },
    "1703": {
        "name": "80's Arkade Bulls #1703",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1703.png",
        "dna": "be6848a3707af0a6f55d275df7648d97459f2cbe",
        "edition": 1703,
        "date": 1654567977635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1703",
        "rarity_score": 123.3243908743713,
        "rarity_level": "COMMON"
    },
    "1704": {
        "name": "80's Arkade Bulls #1704",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1704.png",
        "dna": "71c92808bfd8c668629260089c9b8879e2ab2603",
        "edition": 1704,
        "date": 1654568057170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9.2,
        "id": "1704",
        "rarity_score": 59.87741021449822,
        "rarity_level": "COMMON"
    },
    "1705": {
        "name": "80's Arkade Bulls #1705",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1705.png",
        "dna": "d5034f2ef31e4433bbdf8552e2d56d32e02cca96",
        "edition": 1705,
        "date": 1654568047592,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1705",
        "rarity_score": 99.19629807158684,
        "rarity_level": "COMMON"
    },
    "1706": {
        "name": "80's Arkade Bulls #1706",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1706.png",
        "dna": "29ea902ea748bc898938bf3371ef3b53b81fe1fa",
        "edition": 1706,
        "date": 1654567396561,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 3.833333333333333,
        "id": "1706",
        "rarity_score": 191.04091586890505,
        "rarity_level": "COMMON"
    },
    "1707": {
        "name": "80's Arkade Bulls #1707",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1707.png",
        "dna": "66fce6df15b862aea71fd73c2b7d01b89ec56761",
        "edition": 1707,
        "date": 1654567442117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1707",
        "rarity_score": 99.33300930119849,
        "rarity_level": "COMMON"
    },
    "1708": {
        "name": "80's Arkade Bulls #1708",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1708.png",
        "dna": "1beeb9a8eb0d188a137cb4425cd26b0dbe11bba1",
        "edition": 1708,
        "date": 1654567696136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rarity": 6.428571428571429,
        "rank": "Common",
        "id": "1708",
        "rarity_score": 135.66149472476985,
        "rarity_level": "COMMON"
    },
    "1709": {
        "name": "80's Arkade Bulls #1709",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1709.png",
        "dna": "7830ff53a519a6596bf9addcac16f50300aa9073",
        "edition": 1709,
        "date": 1654568224081,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 1.4,
        "id": "1709",
        "rarity_score": 291.67424830111395,
        "rarity_level": "RARE"
    },
    "1710": {
        "name": "80's Arkade Bulls #1710",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1710.png",
        "dna": "10ff68b62c3a740499d2743cbef0d5b6b54af18a",
        "edition": 1710,
        "date": 1654567299828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1710",
        "rarity_score": 114.48388525636429,
        "rarity_level": "COMMON"
    },
    "1711": {
        "name": "80's Arkade Bulls #1711",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1711.png",
        "dna": "f6d240aaa55754166240e2be6d11b11290defeb5",
        "edition": 1711,
        "date": 1654567209958,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1711",
        "rarity_score": 123.04243349857789,
        "rarity_level": "COMMON"
    },
    "1712": {
        "name": "80's Arkade Bulls #1712",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1712.png",
        "dna": "1fa51cab6043d1046c2a98cd5b4b1689e831baff",
        "edition": 1712,
        "date": 1654568005366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1712",
        "rarity_score": 123.45739989117294,
        "rarity_level": "COMMON"
    },
    "1713": {
        "name": "80's Arkade Bulls #1713",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1713.png",
        "dna": "d239616d0bad26d730f1d2691d572adf47cf8cda",
        "edition": 1713,
        "date": 1654567829444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1713",
        "rarity_score": 86.0504837385727,
        "rarity_level": "COMMON"
    },
    "1714": {
        "name": "80's Arkade Bulls #1714",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1714.png",
        "dna": "f5c3208ccc0815fbe780e087bd43c8b819e031f7",
        "edition": 1714,
        "date": 1654567382023,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1714",
        "rarity_score": 176.38555542095952,
        "rarity_level": "COMMON"
    },
    "1715": {
        "name": "80's Arkade Bulls #1715",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1715.png",
        "dna": "7fbee811221b2db30b46886755ec43753aa0d350",
        "edition": 1715,
        "date": 1654567264427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1715",
        "rarity_score": 77.2238000662756,
        "rarity_level": "COMMON"
    },
    "1716": {
        "name": "80's Arkade Bulls #1716",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1716.png",
        "dna": "bc6d38abd2d755f0d62073485987a5d0be548247",
        "edition": 1716,
        "date": 1654567999724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1716",
        "rarity_score": 78.23224102384265,
        "rarity_level": "COMMON"
    },
    "1717": {
        "name": "80's Arkade Bulls #1717",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1717.png",
        "dna": "e33528fff3fad229f0bb3d9bb4124b6394114a94",
        "edition": 1717,
        "date": 1654568007420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1717",
        "rarity_score": 152.61369278850367,
        "rarity_level": "COMMON"
    },
    "1718": {
        "name": "80's Arkade Bulls #1718",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1718.png",
        "dna": "0409e2bfa61d6917983bcc13c9ad7dd27362e6d1",
        "edition": 1718,
        "date": 1654567289257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1718",
        "rarity_score": 95.08726421727012,
        "rarity_level": "COMMON"
    },
    "1719": {
        "name": "80's Arkade Bulls #1719",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1719.png",
        "dna": "ea5d70056752ff434ff5843f27fd3f4695242fc8",
        "edition": 1719,
        "date": 1654567861630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Epic",
        "rarity": 4.142857142857142,
        "id": "1719",
        "rarity_score": 163.4820538185244,
        "rarity_level": "COMMON"
    },
    "1720": {
        "name": "80's Arkade Bulls #1720",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1720.png",
        "dna": "af1c4d02457a45396455e9e5f2d0d9c8975e3fd4",
        "edition": 1720,
        "date": 1654568090062,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1720",
        "rarity_score": 66.80248608994577,
        "rarity_level": "COMMON"
    },
    "1721": {
        "name": "80's Arkade Bulls #1721",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1721.png",
        "dna": "7edc6bcfc0b3824d26f57f27f6fd409e6569e83d",
        "edition": 1721,
        "date": 1654567636371,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "1721",
        "rarity_score": 109.72853819440445,
        "rarity_level": "COMMON"
    },
    "1722": {
        "name": "80's Arkade Bulls #1722",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1722.png",
        "dna": "7c9eb5673b026d1b9f49da852ef6dd34404c1ca8",
        "edition": 1722,
        "date": 1654567612464,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.25,
        "id": "1722",
        "rarity_score": 145.42042366901154,
        "rarity_level": "COMMON"
    },
    "1723": {
        "name": "80's Arkade Bulls #1723",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1723.png",
        "dna": "0d5c7a2eb5c5b43d1f93fc093e5b57e0b7804e22",
        "edition": 1723,
        "date": 1654567346646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Rare",
        "rarity": 5.111111111111111,
        "id": "1723",
        "rarity_score": 219.67926256938097,
        "rarity_level": "RARE"
    },
    "1724": {
        "name": "80's Arkade Bulls #1724",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1724.png",
        "dna": "824b7aac1ecd2c2136c899e779e5cac986f2435b",
        "edition": 1724,
        "date": 1654568143525,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.833333333333333,
        "id": "1724",
        "rarity_score": 139.95053791601154,
        "rarity_level": "COMMON"
    },
    "1725": {
        "name": "80's Arkade Bulls #1725",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1725.png",
        "dna": "f085b84a5b7298b16641065d41b90fe8ba181f58",
        "edition": 1725,
        "date": 1654567579115,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4.285714285714286,
        "id": "1725",
        "rarity_score": 206.431919951079,
        "rarity_level": "RARE"
    },
    "1726": {
        "name": "80's Arkade Bulls #1726",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1726.png",
        "dna": "e3c4804d9d147d765f9c94a42c465aaa35ef1388",
        "edition": 1726,
        "date": 1654567297224,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1726",
        "rarity_score": 134.16402993558165,
        "rarity_level": "COMMON"
    },
    "1727": {
        "name": "80's Arkade Bulls #1727",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1727.png",
        "dna": "d71c9f3939b4287cfadcc182d640388972021756",
        "edition": 1727,
        "date": 1654567182584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1727",
        "rarity_score": 60.234069765728876,
        "rarity_level": "COMMON"
    },
    "1728": {
        "name": "80's Arkade Bulls #1728",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1728.png",
        "dna": "422f4724bf76bc48db7ac5b2eb5714cd35f3de06",
        "edition": 1728,
        "date": 1654567876383,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1728",
        "rarity_score": 104.2734031324276,
        "rarity_level": "COMMON"
    },
    "1729": {
        "name": "80's Arkade Bulls #1729",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1729.png",
        "dna": "ae90d87849e0a1b966deef32ca4ca845f2761804",
        "edition": 1729,
        "date": 1654567984375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1729",
        "rarity_score": 87.03351693616258,
        "rarity_level": "COMMON"
    },
    "1730": {
        "name": "80's Arkade Bulls #1730",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1730.png",
        "dna": "6db1ba71830b1bff43c6700abcd78475145de08e",
        "edition": 1730,
        "date": 1654567245857,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rarity": 7.666666666666666,
        "rank": "Common",
        "id": "1730",
        "rarity_score": 94.55888623445823,
        "rarity_level": "COMMON"
    },
    "1731": {
        "name": "80's Arkade Bulls #1731",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1731.png",
        "dna": "e684fa49dd95bb273fd4ff7a38f8367e5872a677",
        "edition": 1731,
        "date": 1654567984884,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1731",
        "rarity_score": 84.23614245632488,
        "rarity_level": "COMMON"
    },
    "1732": {
        "name": "80's Arkade Bulls #1732",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1732.png",
        "dna": "e6df565f48282a2c9b964e9751742527b7944ef2",
        "edition": 1732,
        "date": 1654567128075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1732",
        "rarity_score": 232.95496975702787,
        "rarity_level": "RARE"
    },
    "1733": {
        "name": "80's Arkade Bulls #1733",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1733.png",
        "dna": "35df8d9415f035e02c1c9de33b9d6617e388a038",
        "edition": 1733,
        "date": 1654567422752,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1733",
        "rarity_score": 127.94161919810213,
        "rarity_level": "COMMON"
    },
    "1734": {
        "name": "80's Arkade Bulls #1734",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1734.png",
        "dna": "8dc3f43704b0c0195e1852cfc1e97cc6f74923e6",
        "edition": 1734,
        "date": 1654567481850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1734",
        "rarity_score": 211.62748910617705,
        "rarity_level": "RARE"
    },
    "1735": {
        "name": "80's Arkade Bulls #1735",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1735.png",
        "dna": "f3fcb051b416d9b9b5c3f07a76d2d37c068cd5fd",
        "edition": 1735,
        "date": 1654568177995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1735",
        "rarity_score": 81.95413526820873,
        "rarity_level": "COMMON"
    },
    "1736": {
        "name": "80's Arkade Bulls #1736",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1736.png",
        "dna": "ad11b8b5ea69fd6ffa52df5a2d147ad594212ae5",
        "edition": 1736,
        "date": 1654567972022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1736",
        "rarity_score": 94.6573336710802,
        "rarity_level": "COMMON"
    },
    "1737": {
        "name": "80's Arkade Bulls #1737",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1737.png",
        "dna": "293a6daad5532a235800619cc195708070e9acb4",
        "edition": 1737,
        "date": 1654567141247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.8,
        "id": "1737",
        "rarity_score": 70.56275166583936,
        "rarity_level": "COMMON"
    },
    "1738": {
        "name": "80's Arkade Bulls #1738",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1738.png",
        "dna": "c25dec60aa5f0ce67b6b8c6056898147bf779ac7",
        "edition": 1738,
        "date": 1654567273526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "1738",
        "rarity_score": 107.52338348112951,
        "rarity_level": "COMMON"
    },
    "1739": {
        "name": "80's Arkade Bulls #1739",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1739.png",
        "dna": "f4127af08096b54c05118f0003fcbed420125cf9",
        "edition": 1739,
        "date": 1654567975002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1739",
        "rarity_score": 83.93855484782807,
        "rarity_level": "COMMON"
    },
    "1740": {
        "name": "80's Arkade Bulls #1740",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1740.png",
        "dna": "fed87e83ebe7b23030e9c35207280add8160e0c5",
        "edition": 1740,
        "date": 1654568225066,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Super",
        "rarity": 1.2000000000000002,
        "id": "1740",
        "rarity_score": 243.19736842105263,
        "rarity_level": "RARE"
    },
    "1741": {
        "name": "80's Arkade Bulls #1741",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1741.png",
        "dna": "b6a7a4a4606a57e46a262c60142c7bca55b10f25",
        "edition": 1741,
        "date": 1654567316942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.555555555555555,
        "id": "1741",
        "rarity_score": 252.14669655225842,
        "rarity_level": "RARE"
    },
    "1742": {
        "name": "80's Arkade Bulls #1742",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1742.png",
        "dna": "126bc278dcbf5a50a3d5d72033c7fd071a02b870",
        "edition": 1742,
        "date": 1654567286469,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1742",
        "rarity_score": 124.68496682656956,
        "rarity_level": "COMMON"
    },
    "1743": {
        "name": "80's Arkade Bulls #1743",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1743.png",
        "dna": "a72e3a4952bbf140d8d59c095d50b7afb2107232",
        "edition": 1743,
        "date": 1654567230303,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1743",
        "rarity_score": 83.57489392848223,
        "rarity_level": "COMMON"
    },
    "1744": {
        "name": "80's Arkade Bulls #1744",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1744.png",
        "dna": "a6bf8616cd50600f3cd77735b19bb33055d73fb1",
        "edition": 1744,
        "date": 1654567188167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1744",
        "rarity_score": 80.08083059790728,
        "rarity_level": "COMMON"
    },
    "1745": {
        "name": "80's Arkade Bulls #1745",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1745.png",
        "dna": "d456b60c7686aa8c8b9d5edb00f60cc738be2595",
        "edition": 1745,
        "date": 1654567141701,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1745",
        "rarity_score": 101.432378423444,
        "rarity_level": "COMMON"
    },
    "1746": {
        "name": "80's Arkade Bulls #1746",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1746.png",
        "dna": "0893a5114d5fdc54929ffd686557ad76bacaba2b",
        "edition": 1746,
        "date": 1654567643134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.375,
        "id": "1746",
        "rarity_score": 110.36634497911831,
        "rarity_level": "COMMON"
    },
    "1747": {
        "name": "80's Arkade Bulls #1747",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1747.png",
        "dna": "a45683da199a466f1436747a6159e0dbd6d3588b",
        "edition": 1747,
        "date": 1654567684907,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1747",
        "rarity_score": 138.9980702129111,
        "rarity_level": "COMMON"
    },
    "1748": {
        "name": "80's Arkade Bulls #1748",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1748.png",
        "dna": "aeebe8685fa8e9e140e7e1082f47306b2110bd87",
        "edition": 1748,
        "date": 1654567954716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1748",
        "rarity_score": 121.3495463323895,
        "rarity_level": "COMMON"
    },
    "1749": {
        "name": "80's Arkade Bulls #1749",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1749.png",
        "dna": "74b57163ffd2f349e6d662295b7c164759ff0f56",
        "edition": 1749,
        "date": 1654567342102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.111111111111111,
        "id": "1749",
        "rarity_score": 233.45881137048065,
        "rarity_level": "RARE"
    },
    "1750": {
        "name": "80's Arkade Bulls #1750",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1750.png",
        "dna": "a13ad14d8c857feaeeaec9094bb1b3f88245c6b9",
        "edition": 1750,
        "date": 1654567892379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1750",
        "rarity_score": 99.26897857245017,
        "rarity_level": "COMMON"
    },
    "1751": {
        "name": "80's Arkade Bulls #1751",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1751.png",
        "dna": "48a430b292292db7ea26adb7cfee836b3b19b34b",
        "edition": 1751,
        "date": 1654567616080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.125,
        "id": "1751",
        "rarity_score": 177.06876599615475,
        "rarity_level": "COMMON"
    },
    "1752": {
        "name": "80's Arkade Bulls #1752",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1752.png",
        "dna": "2563f06549190ef475352e70a2f20b64aab63c2e",
        "edition": 1752,
        "date": 1654568047165,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1752",
        "rarity_score": 87.19135156535214,
        "rarity_level": "COMMON"
    },
    "1753": {
        "name": "80's Arkade Bulls #1753",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1753.png",
        "dna": "50555428da87123704ca90b286403de74f372457",
        "edition": 1753,
        "date": 1654567956204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1753",
        "rarity_score": 99.24629493362198,
        "rarity_level": "COMMON"
    },
    "1754": {
        "name": "80's Arkade Bulls #1754",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1754.png",
        "dna": "24d2fadaff848e0b73ba795c14b13ab3489c5933",
        "edition": 1754,
        "date": 1654567160982,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1754",
        "rarity_score": 101.17417835113176,
        "rarity_level": "COMMON"
    },
    "1755": {
        "name": "80's Arkade Bulls #1755",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1755.png",
        "dna": "d8c153b06583c489a635b4bad2fe8a539326f287",
        "edition": 1755,
        "date": 1654567625961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rarity": 7,
        "rank": "Common",
        "id": "1755",
        "rarity_score": 125.27775617655257,
        "rarity_level": "COMMON"
    },
    "1756": {
        "name": "80's Arkade Bulls #1756",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1756.png",
        "dna": "76eef222b13c75d961e8ae517a83ba45f54e9c31",
        "edition": 1756,
        "date": 1654567407022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1756",
        "rarity_score": 143.57175528613982,
        "rarity_level": "COMMON"
    },
    "1757": {
        "name": "80's Arkade Bulls #1757",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1757.png",
        "dna": "4c270bcd85c1a558ac1466c971571689157ec827",
        "edition": 1757,
        "date": 1654567598401,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1757",
        "rarity_score": 207.60696300208846,
        "rarity_level": "RARE"
    },
    "1758": {
        "name": "80's Arkade Bulls #1758",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1758.png",
        "dna": "4132cde917d439c064c024f8fc6f5bf60fc726ab",
        "edition": 1758,
        "date": 1654567158219,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1758",
        "rarity_score": 64.41080611767849,
        "rarity_level": "COMMON"
    },
    "1759": {
        "name": "80's Arkade Bulls #1759",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1759.png",
        "dna": "ce939c03f097d78f2e18a3b0aadf814c031851bd",
        "edition": 1759,
        "date": 1654567603425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Gold Ice",
                "score": 63.87096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.125,
        "id": "1759",
        "rarity_score": 159.6211709289069,
        "rarity_level": "COMMON"
    },
    "1760": {
        "name": "80's Arkade Bulls #1760",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1760.png",
        "dna": "79a2ad611e0234d1e830adf49d67386b720f5db6",
        "edition": 1760,
        "date": 1654568120424,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "1760",
        "rarity_score": 131.3470953965257,
        "rarity_level": "COMMON"
    },
    "1761": {
        "name": "80's Arkade Bulls #1761",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1761.png",
        "dna": "34b61d0d4e081565b836454573914a4b2358591f",
        "edition": 1761,
        "date": 1654567161448,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1761",
        "rarity_score": 92.00437872616101,
        "rarity_level": "COMMON"
    },
    "1762": {
        "name": "80's Arkade Bulls #1762",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1762.png",
        "dna": "14c8c7d45e1aa674fecc0053fc16ba1731f18863",
        "edition": 1762,
        "date": 1654567457369,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1762",
        "rarity_score": 77.27678752768196,
        "rarity_level": "COMMON"
    },
    "1763": {
        "name": "80's Arkade Bulls #1763",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1763.png",
        "dna": "7151d1d7f97ed72e3e431fc3d642aad759ff7fa6",
        "edition": 1763,
        "date": 1654568071197,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1763",
        "rarity_score": 62.6484638921786,
        "rarity_level": "COMMON"
    },
    "1764": {
        "name": "80's Arkade Bulls #1764",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1764.png",
        "dna": "bc00591787c57e565c30164ec39fbf7ca07fd1a7",
        "edition": 1764,
        "date": 1654568068518,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1764",
        "rarity_score": 90.74763989080205,
        "rarity_level": "COMMON"
    },
    "1765": {
        "name": "80's Arkade Bulls #1765",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1765.png",
        "dna": "45dbe9e62fa5f141c3286ef45e2fc1a9805878a8",
        "edition": 1765,
        "date": 1654567897128,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1765",
        "rarity_score": 81.6753411783853,
        "rarity_level": "COMMON"
    },
    "1766": {
        "name": "80's Arkade Bulls #1766",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1766.png",
        "dna": "74c8ca2e17f77c00010f88cbd8eddf47ed9eb108",
        "edition": 1766,
        "date": 1654567717424,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666666,
        "id": "1766",
        "rarity_score": 184.96548349864196,
        "rarity_level": "COMMON"
    },
    "1767": {
        "name": "80's Arkade Bulls #1767",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1767.png",
        "dna": "2dbf1a06a7ebe9d3cac1f173da4601d6096e0a78",
        "edition": 1767,
        "date": 1654567838350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1767",
        "rarity_score": 63.661184856108044,
        "rarity_level": "COMMON"
    },
    "1768": {
        "name": "80's Arkade Bulls #1768",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1768.png",
        "dna": "31769d64635c3af4c141cf8df82b2513abf20b94",
        "edition": 1768,
        "date": 1654568053823,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1768",
        "rarity_score": 58.856620340243225,
        "rarity_level": "COMMON"
    },
    "1769": {
        "name": "80's Arkade Bulls #1769",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1769.png",
        "dna": "aaeeddaa1375521a5035ff96c2fb7f8059ab24f2",
        "edition": 1769,
        "date": 1654567181154,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "1769",
        "rarity_score": 148.60623215014283,
        "rarity_level": "COMMON"
    },
    "1770": {
        "name": "80's Arkade Bulls #1770",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1770.png",
        "dna": "6cc3c8720ce6be2e6729d3d16fed8f6dd05def96",
        "edition": 1770,
        "date": 1654567239070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1770",
        "rarity_score": 74.57203000559785,
        "rarity_level": "COMMON"
    },
    "1771": {
        "name": "80's Arkade Bulls #1771",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1771.png",
        "dna": "af3b27a89f28aec44e61e4da280a695bb8571feb",
        "edition": 1771,
        "date": 1654567682651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1771",
        "rarity_score": 119.94126956935975,
        "rarity_level": "COMMON"
    },
    "1772": {
        "name": "80's Arkade Bulls #1772",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1772.png",
        "dna": "aa0a1f357a6f1a23b2c3f247b7f30261f8ac2c06",
        "edition": 1772,
        "date": 1654567218777,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "1772",
        "rarity_score": 76.7505816318683,
        "rarity_level": "COMMON"
    },
    "1773": {
        "name": "80's Arkade Bulls #1773",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1773.png",
        "dna": "2ff682bd982852b231b090bcf211350878df608f",
        "edition": 1773,
        "date": 1654567995888,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1773",
        "rarity_score": 88.84747883759067,
        "rarity_level": "COMMON"
    },
    "1774": {
        "name": "80's Arkade Bulls #1774",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1774.png",
        "dna": "911e9fc7857e752de7dbe9002037f4a71f19682a",
        "edition": 1774,
        "date": 1654567135660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 7.8,
        "id": "1774",
        "rarity_score": 84.39754649239151,
        "rarity_level": "COMMON"
    },
    "1775": {
        "name": "80's Arkade Bulls #1775",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1775.png",
        "dna": "a75f43f1c9171cedee2021726125dd865d80c9f5",
        "edition": 1775,
        "date": 1654567206335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter Red",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666666,
        "id": "1775",
        "rarity_score": 184.29648803914102,
        "rarity_level": "COMMON"
    },
    "1776": {
        "name": "80's Arkade Bulls #1776",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1776.png",
        "dna": "38bdbdd643cc781b9b987399a5575834b1cbd570",
        "edition": 1776,
        "date": 1654567659985,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.285714285714286,
        "id": "1776",
        "rarity_score": 158.70920188528075,
        "rarity_level": "COMMON"
    },
    "1777": {
        "name": "80's Arkade Bulls #1777",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1777.png",
        "dna": "6f2f1a08e590c578773ba18465028542b41ae4a0",
        "edition": 1777,
        "date": 1654567594908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Outfit",
                "value": "Optimint Prime",
                "score": 66.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1777",
        "rarity_score": 163.76052498776332,
        "rarity_level": "COMMON"
    },
    "1778": {
        "name": "80's Arkade Bulls #1778",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1778.png",
        "dna": "02964716250c072191dd37699a451da82a028d21",
        "edition": 1778,
        "date": 1654567343407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Epic",
        "rarity": 4.222222222222222,
        "id": "1778",
        "rarity_score": 359.1790169263485,
        "rarity_level": "RARE"
    },
    "1779": {
        "name": "80's Arkade Bulls #1779",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1779.png",
        "dna": "07067eb5faa29cc3821983d2470d33cae70e8190",
        "edition": 1779,
        "date": 1654567152719,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Epic",
        "rarity": 4.4,
        "id": "1779",
        "rarity_score": 147.34163589978533,
        "rarity_level": "COMMON"
    },
    "1780": {
        "name": "80's Arkade Bulls #1780",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1780.png",
        "dna": "9d1e7a6b079883cb902e945e30b9fd77456f7039",
        "edition": 1780,
        "date": 1654567394064,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666667,
        "id": "1780",
        "rarity_score": 165.84396227406916,
        "rarity_level": "COMMON"
    },
    "1781": {
        "name": "80's Arkade Bulls #1781",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1781.png",
        "dna": "e089b43b79b95b1f42acf6a026b69d01fe47c1bb",
        "edition": 1781,
        "date": 1654568211224,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1781",
        "rarity_score": 181.15457118566837,
        "rarity_level": "COMMON"
    },
    "1782": {
        "name": "80's Arkade Bulls #1782",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1782.png",
        "dna": "a320eea08be08ccb86faf242ebf5d2c1cfb4868d",
        "edition": 1782,
        "date": 1654567677658,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1782",
        "rarity_score": 136.57278346163187,
        "rarity_level": "COMMON"
    },
    "1783": {
        "name": "80's Arkade Bulls #1783",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1783.png",
        "dna": "b4562915df0dce5b968d4e0963981b905981ff34",
        "edition": 1783,
        "date": 1654567997345,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.166666666666667,
        "id": "1783",
        "rarity_score": 92.8254103343465,
        "rarity_level": "COMMON"
    },
    "1784": {
        "name": "80's Arkade Bulls #1784",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1784.png",
        "dna": "84f0ddb3afe86003ac3bf72d420b5c6c34a6b603",
        "edition": 1784,
        "date": 1654568009411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1784",
        "rarity_score": 202.53697169833512,
        "rarity_level": "RARE"
    },
    "1785": {
        "name": "80's Arkade Bulls #1785",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1785.png",
        "dna": "c2f665ccb5f16fe0d84bc1d8bce2b6d5ed9b8ef0",
        "edition": 1785,
        "date": 1654568130816,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.4,
        "id": "1785",
        "rarity_score": 88.06957174503411,
        "rarity_level": "COMMON"
    },
    "1786": {
        "name": "80's Arkade Bulls #1786",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1786.png",
        "dna": "da03093054c8d88168c9c50b396868ca474601ea",
        "edition": 1786,
        "date": 1654567242281,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333334,
        "id": "1786",
        "rarity_score": 68.93081064089284,
        "rarity_level": "COMMON"
    },
    "1787": {
        "name": "80's Arkade Bulls #1787",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1787.png",
        "dna": "a02082dbecf5653a474d6a0ffc16757cd6db46d9",
        "edition": 1787,
        "date": 1654568160199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1787",
        "rarity_score": 257.7900882368863,
        "rarity_level": "RARE"
    },
    "1788": {
        "name": "80's Arkade Bulls #1788",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1788.png",
        "dna": "1a46f3184e304aef663a7a2ecf594e9d6f216a91",
        "edition": 1788,
        "date": 1654567444862,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "1788",
        "rarity_score": 231.59400801359882,
        "rarity_level": "RARE"
    },
    "1789": {
        "name": "80's Arkade Bulls #1789",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1789.png",
        "dna": "fb803b58c9ce0ede7d3b0d268ff4217f6a8cd490",
        "edition": 1789,
        "date": 1654567980032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1789",
        "rarity_score": 87.54772692360346,
        "rarity_level": "COMMON"
    },
    "1790": {
        "name": "80's Arkade Bulls #1790",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1790.png",
        "dna": "cfe4d43dab0fab4a3f08d32816f5896b19a54077",
        "edition": 1790,
        "date": 1654567619821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 4.625,
        "id": "1790",
        "rarity_score": 161.04562712866021,
        "rarity_level": "COMMON"
    },
    "1791": {
        "name": "80's Arkade Bulls #1791",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1791.png",
        "dna": "4ddef067233e090b730ac639ef9d597761033025",
        "edition": 1791,
        "date": 1654568226147,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Kash Ketchum Hat",
                "score": 110.0
            }
        ],
        "rank": "Super",
        "rarity": 2.5,
        "id": "1791",
        "rarity_score": 288.9315007693218,
        "rarity_level": "RARE"
    },
    "1792": {
        "name": "80's Arkade Bulls #1792",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1792.png",
        "dna": "c6714265885084dae21f7161d0e4b35041ce95e4",
        "edition": 1792,
        "date": 1654567541274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857143,
        "id": "1792",
        "rarity_score": 160.44368913864966,
        "rarity_level": "COMMON"
    },
    "1793": {
        "name": "80's Arkade Bulls #1793",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1793.png",
        "dna": "1afcbce7e686a9ddf8253fa8a044c7cf6b7db375",
        "edition": 1793,
        "date": 1654567668999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "1793",
        "rarity_score": 137.43751184666593,
        "rarity_level": "COMMON"
    },
    "1794": {
        "name": "80's Arkade Bulls #1794",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1794.png",
        "dna": "6f32989e28984804f14b74a8a4647de28f109907",
        "edition": 1794,
        "date": 1654567357073,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Rare",
        "rarity": 4.888888888888889,
        "id": "1794",
        "rarity_score": 245.71262131744191,
        "rarity_level": "RARE"
    },
    "1795": {
        "name": "80's Arkade Bulls #1795",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1795.png",
        "dna": "e91b203126862bf685afc4a176738408293ff787",
        "edition": 1795,
        "date": 1654567508271,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "1795",
        "rarity_score": 155.4090999440894,
        "rarity_level": "COMMON"
    },
    "1796": {
        "name": "80's Arkade Bulls #1796",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1796.png",
        "dna": "ff08686c2ff55ecd11f212e052f927046807a56b",
        "edition": 1796,
        "date": 1654567443216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1796",
        "rarity_score": 195.7223130233526,
        "rarity_level": "COMMON"
    },
    "1797": {
        "name": "80's Arkade Bulls #1797",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1797.png",
        "dna": "d7c8cf9744f0ee7f8627c5387a00c5d7bd3cc516",
        "edition": 1797,
        "date": 1654567937806,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.142857142857142,
        "id": "1797",
        "rarity_score": 122.78013938628439,
        "rarity_level": "COMMON"
    },
    "1798": {
        "name": "80's Arkade Bulls #1798",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1798.png",
        "dna": "a00ed5d7e8380c9b184e7eb536916236f6364bb7",
        "edition": 1798,
        "date": 1654567601542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.125,
        "id": "1798",
        "rarity_score": 194.48569647960193,
        "rarity_level": "COMMON"
    },
    "1799": {
        "name": "80's Arkade Bulls #1799",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1799.png",
        "dna": "84c84bb8e68050c2fbb2dbe3b0f72cca3faa1cea",
        "edition": 1799,
        "date": 1654567703265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1799",
        "rarity_score": 148.76900989657202,
        "rarity_level": "COMMON"
    },
    "1800": {
        "name": "80's Arkade Bulls #1800",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1800.png",
        "dna": "e0be4d38c80e9edf83dd064e0d8974d97369423f",
        "edition": 1800,
        "date": 1654567351824,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 4.111111111111111,
        "id": "1800",
        "rarity_score": 236.75212294038187,
        "rarity_level": "RARE"
    },
    "1801": {
        "name": "80's Arkade Bulls #1801",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1801.png",
        "dna": "3fb3af036db1f6d85654562122eb0d51d2926d95",
        "edition": 1801,
        "date": 1654567987916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1801",
        "rarity_score": 107.99253662423482,
        "rarity_level": "COMMON"
    },
    "1802": {
        "name": "80's Arkade Bulls #1802",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1802.png",
        "dna": "4386acab34f3ad7d6979e979c663236c14a8294e",
        "edition": 1802,
        "date": 1654567298287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1802",
        "rarity_score": 107.47642660364463,
        "rarity_level": "COMMON"
    },
    "1803": {
        "name": "80's Arkade Bulls #1803",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1803.png",
        "dna": "4d8b63965b21271a7e7f27e038c2ffa0cd62cfae",
        "edition": 1803,
        "date": 1654567476294,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 3.1428571428571423,
        "id": "1803",
        "rarity_score": 201.56725105897561,
        "rarity_level": "COMMON"
    },
    "1804": {
        "name": "80's Arkade Bulls #1804",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1804.png",
        "dna": "6aba35cb2ebb0026bf3c547919d29ae2698b1b83",
        "edition": 1804,
        "date": 1654568015009,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1804",
        "rarity_score": 95.90385127088594,
        "rarity_level": "COMMON"
    },
    "1805": {
        "name": "80's Arkade Bulls #1805",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1805.png",
        "dna": "a44cc707741b7545e323957415f6e6b27838a3b3",
        "edition": 1805,
        "date": 1654567622179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1805",
        "rarity_score": 192.1777728667214,
        "rarity_level": "COMMON"
    },
    "1806": {
        "name": "80's Arkade Bulls #1806",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1806.png",
        "dna": "e605f6bd092cc90f88a990acbec740e2f8d3625d",
        "edition": 1806,
        "date": 1654567663389,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.714285714285714,
        "id": "1806",
        "rarity_score": 88.01010137248215,
        "rarity_level": "COMMON"
    },
    "1807": {
        "name": "80's Arkade Bulls #1807",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1807.png",
        "dna": "aa367a9c586a7c273b6f0bdd03063ddbe5d0aac0",
        "edition": 1807,
        "date": 1654567285957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            }
        ],
        "rank": "Common",
        "rarity": 6.166666666666667,
        "id": "1807",
        "rarity_score": 110.68365027690416,
        "rarity_level": "COMMON"
    },
    "1808": {
        "name": "80's Arkade Bulls #1808",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1808.png",
        "dna": "386d5ab29c2d85f3a8526e8d3da78b93409fc432",
        "edition": 1808,
        "date": 1654567965852,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1808",
        "rarity_score": 97.05334370536625,
        "rarity_level": "COMMON"
    },
    "1809": {
        "name": "80's Arkade Bulls #1809",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1809.png",
        "dna": "4e27fa04bf8db5adcb9d8fe10f2821324cac479f",
        "edition": 1809,
        "date": 1654567677090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            { "trait_type": "Mask", "value": "KDA Laser Eyes", "score": 90.0 }
        ],
        "rank": "Epic",
        "rarity": 3.8571428571428568,
        "id": "1809",
        "rarity_score": 218.70760105760436,
        "rarity_level": "RARE"
    },
    "1810": {
        "name": "80's Arkade Bulls #1810",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1810.png",
        "dna": "be466fb687c517066a4da1695b68a8c900325e52",
        "edition": 1810,
        "date": 1654567923017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.142857142857142,
        "id": "1810",
        "rarity_score": 120.9671690244742,
        "rarity_level": "COMMON"
    },
    "1811": {
        "name": "80's Arkade Bulls #1811",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1811.png",
        "dna": "7f41d81270d2751ef72baf58d0c161a7c2dc6512",
        "edition": 1811,
        "date": 1654567869724,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "1811",
        "rarity_score": 157.8759072890987,
        "rarity_level": "COMMON"
    },
    "1812": {
        "name": "80's Arkade Bulls #1812",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1812.png",
        "dna": "7cd24bfc756bf6c1138d7c1a49446ae3c9f15c96",
        "edition": 1812,
        "date": 1654567544052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "1812",
        "rarity_score": 164.89943531155976,
        "rarity_level": "COMMON"
    },
    "1813": {
        "name": "80's Arkade Bulls #1813",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1813.png",
        "dna": "f283bda02feaa0d5559c67cbc1f67ac85ea2d3ee",
        "edition": 1813,
        "date": 1654568093104,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1813",
        "rarity_score": 99.03972248000721,
        "rarity_level": "COMMON"
    },
    "1814": {
        "name": "80's Arkade Bulls #1814",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1814.png",
        "dna": "3fadc9a3995abcb4f82ebd069264553b1ac6d171",
        "edition": 1814,
        "date": 1654567463436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rarity": 7.857142857142858,
        "rank": "Common",
        "id": "1814",
        "rarity_score": 97.51813756860372,
        "rarity_level": "COMMON"
    },
    "1815": {
        "name": "80's Arkade Bulls #1815",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1815.png",
        "dna": "36a963fa95cccf675bffe4cb1864740e8b41c62e",
        "edition": 1815,
        "date": 1654567413029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Water Tunic Hat", "score": 66.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1815",
        "rarity_score": 238.97971928132242,
        "rarity_level": "RARE"
    },
    "1816": {
        "name": "80's Arkade Bulls #1816",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1816.png",
        "dna": "01d838853ea4f1810137cdb1c95e9ee3fdc9ba5a",
        "edition": 1816,
        "date": 1654567134695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1816",
        "rarity_score": 104.6935887711479,
        "rarity_level": "COMMON"
    },
    "1817": {
        "name": "80's Arkade Bulls #1817",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1817.png",
        "dna": "352fba70de7089e77870d8363d7c5ce432bd9f77",
        "edition": 1817,
        "date": 1654568162774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1817",
        "rarity_score": 129.76247465651971,
        "rarity_level": "COMMON"
    },
    "1818": {
        "name": "80's Arkade Bulls #1818",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1818.png",
        "dna": "fca36af804686f059b78a3525b99a55bbbd480ae",
        "edition": 1818,
        "date": 1654568066709,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1818",
        "rarity_score": 62.05669274937014,
        "rarity_level": "COMMON"
    },
    "1819": {
        "name": "80's Arkade Bulls #1819",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1819.png",
        "dna": "41e4135536430b1215fffbc240998bb8c0ed7649",
        "edition": 1819,
        "date": 1654568153694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Smoking Red", "score": 90.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 2.166666666666667,
        "id": "1819",
        "rarity_score": 232.42480999777402,
        "rarity_level": "RARE"
    },
    "1820": {
        "name": "80's Arkade Bulls #1820",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1820.png",
        "dna": "00058ce971e2ceaae9d741d63486bc1a71526fb0",
        "edition": 1820,
        "date": 1654567565609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1820",
        "rarity_score": 204.96988974340627,
        "rarity_level": "RARE"
    },
    "1821": {
        "name": "80's Arkade Bulls #1821",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1821.png",
        "dna": "783bb7c54cf2ad748cc14d77b12ab30181ec7506",
        "edition": 1821,
        "date": 1654567177024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1821",
        "rarity_score": 133.1898751619477,
        "rarity_level": "COMMON"
    },
    "1822": {
        "name": "80's Arkade Bulls #1822",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1822.png",
        "dna": "0ba15720799f31d01e5f43f0f9ce27a16c9975ab",
        "edition": 1822,
        "date": 1654567244368,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1822",
        "rarity_score": 87.18956646939641,
        "rarity_level": "COMMON"
    },
    "1823": {
        "name": "80's Arkade Bulls #1823",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1823.png",
        "dna": "b755c128267f60cce0f658dc945768cb50fc5aeb",
        "edition": 1823,
        "date": 1654567629079,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.25,
        "id": "1823",
        "rarity_score": 135.80523799585862,
        "rarity_level": "COMMON"
    },
    "1824": {
        "name": "80's Arkade Bulls #1824",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1824.png",
        "dna": "c43cd12b4aa214ea5de3d84e10e9766362b46983",
        "edition": 1824,
        "date": 1654567949528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "1824",
        "rarity_score": 137.0695842056685,
        "rarity_level": "COMMON"
    },
    "1825": {
        "name": "80's Arkade Bulls #1825",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1825.png",
        "dna": "121ee1138bd7feeef4403876fca98ee8514c9370",
        "edition": 1825,
        "date": 1654568070348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "1825",
        "rarity_score": 79.18775775831614,
        "rarity_level": "COMMON"
    },
    "1826": {
        "name": "80's Arkade Bulls #1826",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1826.png",
        "dna": "ac5ad16df9668d7980bb28780753bb447bbbca72",
        "edition": 1826,
        "date": 1654567126625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1826",
        "rarity_score": 114.65946378551422,
        "rarity_level": "COMMON"
    },
    "1827": {
        "name": "80's Arkade Bulls #1827",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1827.png",
        "dna": "8148854f58109d7034ed736e0757286fd0b45780",
        "edition": 1827,
        "date": 1654567981009,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1827",
        "rarity_score": 112.79021790248294,
        "rarity_level": "COMMON"
    },
    "1828": {
        "name": "80's Arkade Bulls #1828",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1828.png",
        "dna": "c44fdf5aaa542a3e1d4fe7e1da3d02f0c8b6b784",
        "edition": 1828,
        "date": 1654567193304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 9,
        "id": "1828",
        "rarity_score": 58.33405439952803,
        "rarity_level": "COMMON"
    },
    "1829": {
        "name": "80's Arkade Bulls #1829",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1829.png",
        "dna": "186478e759b5f1453b583483b1e56ae365b84e49",
        "edition": 1829,
        "date": 1654568216073,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1829",
        "rarity_score": 245.71647063632795,
        "rarity_level": "RARE"
    },
    "1830": {
        "name": "80's Arkade Bulls #1830",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1830.png",
        "dna": "d673abbd3e172a6a0103855e58b0410b0eaed491",
        "edition": 1830,
        "date": 1654568088240,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1830",
        "rarity_score": 58.447368006304174,
        "rarity_level": "COMMON"
    },
    "1831": {
        "name": "80's Arkade Bulls #1831",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1831.png",
        "dna": "58437bc320e59844fec3aa640ccb7a32e05cd434",
        "edition": 1831,
        "date": 1654567439305,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Epic",
        "rarity": 4.285714285714286,
        "id": "1831",
        "rarity_score": 235.84265983769959,
        "rarity_level": "RARE"
    },
    "1832": {
        "name": "80's Arkade Bulls #1832",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1832.png",
        "dna": "892e416a8c21fe191c64c5a95e8008a55ec9dc84",
        "edition": 1832,
        "date": 1654567225425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666666,
        "id": "1832",
        "rarity_score": 76.32938831143542,
        "rarity_level": "COMMON"
    },
    "1833": {
        "name": "80's Arkade Bulls #1833",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1833.png",
        "dna": "10d220563683bba821f5629b056efba1334108da",
        "edition": 1833,
        "date": 1654567364279,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.777777777777778,
        "id": "1833",
        "rarity_score": 241.8198028798428,
        "rarity_level": "RARE"
    },
    "1834": {
        "name": "80's Arkade Bulls #1834",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1834.png",
        "dna": "d8d8c90e9243a98969b2aa2339dddff89c27c30e",
        "edition": 1834,
        "date": 1654567213614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666666,
        "id": "1834",
        "rarity_score": 100.21208914249367,
        "rarity_level": "COMMON"
    },
    "1835": {
        "name": "80's Arkade Bulls #1835",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1835.png",
        "dna": "ba37e4fb397a87434764bc4de9643623607e8e4f",
        "edition": 1835,
        "date": 1654567942743,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1835",
        "rarity_score": 95.36519264079118,
        "rarity_level": "COMMON"
    },
    "1836": {
        "name": "80's Arkade Bulls #1836",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1836.png",
        "dna": "1fa79c892ce40cd7716bba12fb8cb6f334702591",
        "edition": 1836,
        "date": 1654567431437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "1836",
        "rarity_score": 86.61452361944364,
        "rarity_level": "COMMON"
    },
    "1837": {
        "name": "80's Arkade Bulls #1837",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1837.png",
        "dna": "abb1a7f1e09fec8dca298e98d153055258003a41",
        "edition": 1837,
        "date": 1654567853633,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1837",
        "rarity_score": 106.11602673650228,
        "rarity_level": "COMMON"
    },
    "1838": {
        "name": "80's Arkade Bulls #1838",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1838.png",
        "dna": "4e0354f7cde6543368858c20620a1e4d755d56ea",
        "edition": 1838,
        "date": 1654567964480,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 7.833333333333333,
        "id": "1838",
        "rarity_score": 105.28357969045632,
        "rarity_level": "COMMON"
    },
    "1839": {
        "name": "80's Arkade Bulls #1839",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1839.png",
        "dna": "320470bfc6f2e0564248c7a9504071c93601f844",
        "edition": 1839,
        "date": 1654568134509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1839",
        "rarity_score": 97.18022241320615,
        "rarity_level": "COMMON"
    },
    "1840": {
        "name": "80's Arkade Bulls #1840",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1840.png",
        "dna": "7a3feafb6ca2605bfbd86989123397a0c661cbc2",
        "edition": 1840,
        "date": 1654567943225,
        "attributes": [
            { "trait_type": "Background", "value": "Pact Man", "score": 132.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1840",
        "rarity_score": 218.00217303822936,
        "rarity_level": "RARE"
    },
    "1841": {
        "name": "80's Arkade Bulls #1841",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1841.png",
        "dna": "84450719b1d042e24c18e191fef6470ee270b049",
        "edition": 1841,
        "date": 1654567159602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rarity": 7,
        "rank": "Common",
        "id": "1841",
        "rarity_score": 89.71032090293356,
        "rarity_level": "COMMON"
    },
    "1842": {
        "name": "80's Arkade Bulls #1842",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1842.png",
        "dna": "058bd39c7c5d8d9a44fe648f04d0dde35888450f",
        "edition": 1842,
        "date": 1654567238040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1842",
        "rarity_score": 73.53150648564959,
        "rarity_level": "COMMON"
    },
    "1843": {
        "name": "80's Arkade Bulls #1843",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1843.png",
        "dna": "2df3bcc030d7471ddaaa45014e191cf37bd8c5ae",
        "edition": 1843,
        "date": 1654568136829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rarity": 7.6,
        "rank": "Common",
        "id": "1843",
        "rarity_score": 110.69942359111882,
        "rarity_level": "COMMON"
    },
    "1844": {
        "name": "80's Arkade Bulls #1844",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1844.png",
        "dna": "2c836af7eab257bae84d8b7c9415c49a2f453321",
        "edition": 1844,
        "date": 1654567712746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1844",
        "rarity_score": 192.3944990001937,
        "rarity_level": "COMMON"
    },
    "1845": {
        "name": "80's Arkade Bulls #1845",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1845.png",
        "dna": "76136674694543e7034af1ab001c0ef1f0428991",
        "edition": 1845,
        "date": 1654568155189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1845",
        "rarity_score": 117.6660993112845,
        "rarity_level": "COMMON"
    },
    "1846": {
        "name": "80's Arkade Bulls #1846",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1846.png",
        "dna": "dcec52b3d48018979d1541b6b2a52cdf03ef43a7",
        "edition": 1846,
        "date": 1654567367654,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1846",
        "rarity_score": 304.3769823426903,
        "rarity_level": "RARE"
    },
    "1847": {
        "name": "80's Arkade Bulls #1847",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1847.png",
        "dna": "086f7897059e7cdd3bf5dac114142441dae546d8",
        "edition": 1847,
        "date": 1654567604018,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Rare",
        "rarity": 5.875,
        "id": "1847",
        "rarity_score": 155.2302402251433,
        "rarity_level": "COMMON"
    },
    "1848": {
        "name": "80's Arkade Bulls #1848",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1848.png",
        "dna": "44eda37128a1d302ba8374c2a368d7d36d81ae33",
        "edition": 1848,
        "date": 1654567659444,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "1848",
        "rarity_score": 152.51376318663318,
        "rarity_level": "COMMON"
    },
    "1849": {
        "name": "80's Arkade Bulls #1849",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1849.png",
        "dna": "6c5f59a6c1bc73e62e29b60a4b745d836b940bc9",
        "edition": 1849,
        "date": 1654568208683,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1849",
        "rarity_score": 118.97117125405282,
        "rarity_level": "COMMON"
    },
    "1850": {
        "name": "80's Arkade Bulls #1850",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1850.png",
        "dna": "dd1520d2d34ecc0f15e874a30510fb847881ddd9",
        "edition": 1850,
        "date": 1654567282206,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1850",
        "rarity_score": 128.58792894276766,
        "rarity_level": "COMMON"
    },
    "1851": {
        "name": "80's Arkade Bulls #1851",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1851.png",
        "dna": "12043ce326a7441adcc255d65ac2af2d6099a58d",
        "edition": 1851,
        "date": 1654568176442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1851",
        "rarity_score": 172.10195120783357,
        "rarity_level": "COMMON"
    },
    "1852": {
        "name": "80's Arkade Bulls #1852",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1852.png",
        "dna": "6bd91c74866db57d5a0373de42d78cc3741f34da",
        "edition": 1852,
        "date": 1654567654935,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Planets",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rarity": 5.571428571428571,
        "rank": "Rare",
        "id": "1852",
        "rarity_score": 163.97272383975658,
        "rarity_level": "COMMON"
    },
    "1853": {
        "name": "80's Arkade Bulls #1853",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1853.png",
        "dna": "73b1c9b33bdf639c50e98aa035ddee19e11900ed",
        "edition": 1853,
        "date": 1654567275886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Radioactive",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            }
        ],
        "rank": "Super",
        "rarity": 2,
        "id": "1853",
        "rarity_score": 243.1563745779979,
        "rarity_level": "RARE"
    },
    "1854": {
        "name": "80's Arkade Bulls #1854",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1854.png",
        "dna": "455d7538395ad3c6d885471a155714b89873f12f",
        "edition": 1854,
        "date": 1654567711699,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333334,
        "id": "1854",
        "rarity_score": 140.36662754892592,
        "rarity_level": "COMMON"
    },
    "1855": {
        "name": "80's Arkade Bulls #1855",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1855.png",
        "dna": "147e97201eb5cceb1cce4ce148e43fc3cbb68f79",
        "edition": 1855,
        "date": 1654567860657,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1855",
        "rarity_score": 120.67317402275364,
        "rarity_level": "COMMON"
    },
    "1856": {
        "name": "80's Arkade Bulls #1856",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1856.png",
        "dna": "0340c5bf8f014a37c39f740282af0e2ad16b8d81",
        "edition": 1856,
        "date": 1654567386491,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1856",
        "rarity_score": 164.35511161469955,
        "rarity_level": "COMMON"
    },
    "1857": {
        "name": "80's Arkade Bulls #1857",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1857.png",
        "dna": "62d0993ed26c946d8798764f86a21697db08d829",
        "edition": 1857,
        "date": 1654567408058,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1857",
        "rarity_score": 248.4243534814174,
        "rarity_level": "RARE"
    },
    "1858": {
        "name": "80's Arkade Bulls #1858",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1858.png",
        "dna": "5a73f916a6ff4b789f58fc2654ffbdbfe4dd7b23",
        "edition": 1858,
        "date": 1654567475721,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.428571428571429,
        "id": "1858",
        "rarity_score": 83.23758039898536,
        "rarity_level": "COMMON"
    },
    "1859": {
        "name": "80's Arkade Bulls #1859",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1859.png",
        "dna": "c65e7f7e9c54f7f114df6133c3dfe72a19aee01b",
        "edition": 1859,
        "date": 1654567449929,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rarity": 6.857142857142858,
        "rank": "Common",
        "id": "1859",
        "rarity_score": 104.24971296509348,
        "rarity_level": "COMMON"
    },
    "1860": {
        "name": "80's Arkade Bulls #1860",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1860.png",
        "dna": "1c2ddce4ef724ee65eca65d8512a3d72657e2316",
        "edition": 1860,
        "date": 1654567455693,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.714285714285714,
        "id": "1860",
        "rarity_score": 119.75227381997574,
        "rarity_level": "COMMON"
    },
    "1861": {
        "name": "80's Arkade Bulls #1861",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1861.png",
        "dna": "13582f8e21148c7e379360cef0160873a78beb55",
        "edition": 1861,
        "date": 1654568017549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 8.166666666666666,
        "id": "1861",
        "rarity_score": 84.19998052788499,
        "rarity_level": "COMMON"
    },
    "1862": {
        "name": "80's Arkade Bulls #1862",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1862.png",
        "dna": "fced3d45f67e4faaba8bb08199a0bb7cebde54af",
        "edition": 1862,
        "date": 1654568023103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.666666666666667,
        "id": "1862",
        "rarity_score": 113.62768381455813,
        "rarity_level": "COMMON"
    },
    "1863": {
        "name": "80's Arkade Bulls #1863",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1863.png",
        "dna": "75f3646f6562f269bfb7117f1f8ea5b9a903a9ad",
        "edition": 1863,
        "date": 1654568092227,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1863",
        "rarity_score": 86.27464894794619,
        "rarity_level": "COMMON"
    },
    "1864": {
        "name": "80's Arkade Bulls #1864",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1864.png",
        "dna": "8cc3f688aedbc3cbd632cad6d1a8bb3733c09284",
        "edition": 1864,
        "date": 1654567932880,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 8.285714285714286,
        "id": "1864",
        "rarity_score": 78.41473058489089,
        "rarity_level": "COMMON"
    },
    "1865": {
        "name": "80's Arkade Bulls #1865",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1865.png",
        "dna": "298ec842761ee6cccee1124a56acc5450d80df72",
        "edition": 1865,
        "date": 1654567642535,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kadena",
                "score": 23.023255813953487
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "1865",
        "rarity_score": 119.82066423856608,
        "rarity_level": "COMMON"
    },
    "1866": {
        "name": "80's Arkade Bulls #1866",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1866.png",
        "dna": "88cd22136c5d95dfbad8e4229ff73ba87fd406ed",
        "edition": 1866,
        "date": 1654567955699,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1866",
        "rarity_score": 86.10817582023394,
        "rarity_level": "COMMON"
    },
    "1867": {
        "name": "80's Arkade Bulls #1867",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1867.png",
        "dna": "28f350ac9cd3db1c9c8943a2e566a09f93b83525",
        "edition": 1867,
        "date": 1654568196737,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1867",
        "rarity_score": 115.34784722095796,
        "rarity_level": "COMMON"
    },
    "1868": {
        "name": "80's Arkade Bulls #1868",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1868.png",
        "dna": "728a64820be7374429fefa3ae1a1ee6d21c78f3b",
        "edition": 1868,
        "date": 1654568001244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1868",
        "rarity_score": 98.72337544751575,
        "rarity_level": "COMMON"
    },
    "1869": {
        "name": "80's Arkade Bulls #1869",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1869.png",
        "dna": "563b8f57ab1c9e8b8a9318c90762086f3e18a4e1",
        "edition": 1869,
        "date": 1654567481284,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Dok Brown", "score": 165.0 }
        ],
        "rank": "Rare",
        "rarity": 5.142857142857142,
        "id": "1869",
        "rarity_score": 250.4826508903872,
        "rarity_level": "RARE"
    },
    "1870": {
        "name": "80's Arkade Bulls #1870",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1870.png",
        "dna": "b3067e0bdeff64c0c6d8bbec6bd0cc446655f627",
        "edition": 1870,
        "date": 1654567709189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1870",
        "rarity_score": 80.67442818834482,
        "rarity_level": "COMMON"
    },
    "1871": {
        "name": "80's Arkade Bulls #1871",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1871.png",
        "dna": "e162ffc33bc4846b432605fd005670e9acdf0f60",
        "edition": 1871,
        "date": 1654567584650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "1871",
        "rarity_score": 145.27537824883524,
        "rarity_level": "COMMON"
    },
    "1872": {
        "name": "80's Arkade Bulls #1872",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1872.png",
        "dna": "bcd3c2fe683c91728e372e78fa5098f365032b70",
        "edition": 1872,
        "date": 1654568144573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333334,
        "id": "1872",
        "rarity_score": 124.04620655589537,
        "rarity_level": "COMMON"
    },
    "1873": {
        "name": "80's Arkade Bulls #1873",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1873.png",
        "dna": "9a6b19cca230929844435b0484149bb27af9fe13",
        "edition": 1873,
        "date": 1654567530836,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1873",
        "rarity_score": 194.06524080379717,
        "rarity_level": "COMMON"
    },
    "1874": {
        "name": "80's Arkade Bulls #1874",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1874.png",
        "dna": "06b92176375dd0653559c630e787f162e2437375",
        "edition": 1874,
        "date": 1654567992405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5.166666666666667,
        "id": "1874",
        "rarity_score": 112.42567688151671,
        "rarity_level": "COMMON"
    },
    "1875": {
        "name": "80's Arkade Bulls #1875",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1875.png",
        "dna": "587de5f21f71cde05483707da83e26727556e8c5",
        "edition": 1875,
        "date": 1654567157750,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rank": "Common",
        "rarity": 8.4,
        "id": "1875",
        "rarity_score": 64.43506573375457,
        "rarity_level": "COMMON"
    },
    "1876": {
        "name": "80's Arkade Bulls #1876",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1876.png",
        "dna": "ce22eb971695478af1a6779b41b074d64ff987d0",
        "edition": 1876,
        "date": 1654568002237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1876",
        "rarity_score": 83.59717583159774,
        "rarity_level": "COMMON"
    },
    "1877": {
        "name": "80's Arkade Bulls #1877",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1877.png",
        "dna": "71292b009460de7a468b7259b996df7dddcaeaaf",
        "edition": 1877,
        "date": 1654567146790,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Epic",
        "rarity": 3.8,
        "id": "1877",
        "rarity_score": 135.19496189279909,
        "rarity_level": "COMMON"
    },
    "1878": {
        "name": "80's Arkade Bulls #1878",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1878.png",
        "dna": "2191d3869fe82949d13b1770098ee43a2c25c650",
        "edition": 1878,
        "date": 1654567824817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8,
        "id": "1878",
        "rarity_score": 94.48431738285255,
        "rarity_level": "COMMON"
    },
    "1879": {
        "name": "80's Arkade Bulls #1879",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1879.png",
        "dna": "fc9d694a15a3705a8cb04a83cea82ef10a37d924",
        "edition": 1879,
        "date": 1654568057606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "1879",
        "rarity_score": 65.44438155289103,
        "rarity_level": "COMMON"
    },
    "1880": {
        "name": "80's Arkade Bulls #1880",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1880.png",
        "dna": "3a23d586cf76365db93ee425956d1a887aaa3706",
        "edition": 1880,
        "date": 1654568164714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "00KDA",
                "score": 76.15384615384616
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1880",
        "rarity_score": 175.90835636098797,
        "rarity_level": "COMMON"
    },
    "1881": {
        "name": "80's Arkade Bulls #1881",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1881.png",
        "dna": "9289a960a106acdfd9ea03e236b19554f0dac121",
        "edition": 1881,
        "date": 1654567416632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1881",
        "rarity_score": 175.2967032967033,
        "rarity_level": "COMMON"
    },
    "1882": {
        "name": "80's Arkade Bulls #1882",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1882.png",
        "dna": "48203724b00711c2c488e8bd917c6ae7b53cb10a",
        "edition": 1882,
        "date": 1654567479599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1882",
        "rarity_score": 91.60723574774937,
        "rarity_level": "COMMON"
    },
    "1883": {
        "name": "80's Arkade Bulls #1883",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1883.png",
        "dna": "d45b9bbfb4561d7e33e2c5f74826311867f21286",
        "edition": 1883,
        "date": 1654568234089,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 1.6,
        "id": "1883",
        "rarity_score": 269.3916256157636,
        "rarity_level": "RARE"
    },
    "1884": {
        "name": "80's Arkade Bulls #1884",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1884.png",
        "dna": "852bf77e78807992f542aeddc9e8f3bd8ceaebef",
        "edition": 1884,
        "date": 1654567320230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 4.888888888888889,
        "id": "1884",
        "rarity_score": 236.97647706371015,
        "rarity_level": "RARE"
    },
    "1885": {
        "name": "80's Arkade Bulls #1885",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1885.png",
        "dna": "92c5b580dcd22f86dfc519232af99e280494e41b",
        "edition": 1885,
        "date": 1654567838739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1885",
        "rarity_score": 143.31305780235976,
        "rarity_level": "COMMON"
    },
    "1886": {
        "name": "80's Arkade Bulls #1886",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1886.png",
        "dna": "be211648332867fb34eb379249b90ec9ce43673b",
        "edition": 1886,
        "date": 1654568166635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Hat", "score": 42.12765957446808 }
        ],
        "rank": "Common",
        "rarity": 6.666666666666667,
        "id": "1886",
        "rarity_score": 116.27164258769969,
        "rarity_level": "COMMON"
    },
    "1887": {
        "name": "80's Arkade Bulls #1887",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1887.png",
        "dna": "233e45a1516790c09ff72cfd5aa2c5c65214f9ae",
        "edition": 1887,
        "date": 1654567837086,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1887",
        "rarity_score": 69.97253649537262,
        "rarity_level": "COMMON"
    },
    "1888": {
        "name": "80's Arkade Bulls #1888",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1888.png",
        "dna": "0d816a10dc13455f2ea45a9a33170a9975b8aca6",
        "edition": 1888,
        "date": 1654567498298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Green Mohawk", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1888",
        "rarity_score": 270.77294615819494,
        "rarity_level": "RARE"
    },
    "1889": {
        "name": "80's Arkade Bulls #1889",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1889.png",
        "dna": "02116b03e235b702e065c8a10eabb81557e0530d",
        "edition": 1889,
        "date": 1654567563841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Chain Fighter Red Headband",
                "score": 110.0
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1889",
        "rarity_score": 193.40192982843925,
        "rarity_level": "COMMON"
    },
    "1890": {
        "name": "80's Arkade Bulls #1890",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1890.png",
        "dna": "a33282140a0f5335ce20e5ccfe48d476f9d48261",
        "edition": 1890,
        "date": 1654567397547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blocks",
                "score": 86.08695652173913
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Epic",
        "rarity": 3.666666666666667,
        "id": "1890",
        "rarity_score": 243.71082942226622,
        "rarity_level": "RARE"
    },
    "1891": {
        "name": "80's Arkade Bulls #1891",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1891.png",
        "dna": "c9a3c0ebf9b113b2cb7b4bcf32f7b1400449208a",
        "edition": 1891,
        "date": 1654567936710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1891",
        "rarity_score": 136.9693457225117,
        "rarity_level": "COMMON"
    },
    "1892": {
        "name": "80's Arkade Bulls #1892",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1892.png",
        "dna": "d576dcb4ff15a4281a7699205db9e06c38d5e1e2",
        "edition": 1892,
        "date": 1654568101450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.6,
        "id": "1892",
        "rarity_score": 133.9267195767196,
        "rarity_level": "COMMON"
    },
    "1893": {
        "name": "80's Arkade Bulls #1893",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1893.png",
        "dna": "0cf3318a500dcfed60216d9cc0222645d5d4f87f",
        "edition": 1893,
        "date": 1654568010429,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Turtle Shell",
                "score": 60.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.333333333333333,
        "id": "1893",
        "rarity_score": 125.982219430257,
        "rarity_level": "COMMON"
    },
    "1894": {
        "name": "80's Arkade Bulls #1894",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1894.png",
        "dna": "fd902a462df9b271dbc7d935797fb0751e5920a8",
        "edition": 1894,
        "date": 1654567902450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Astronaut Suit",
                "score": 94.28571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            { "trait_type": "Mask", "value": "Astronaut Helmet", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142857,
        "id": "1894",
        "rarity_score": 215.78884959389163,
        "rarity_level": "RARE"
    },
    "1895": {
        "name": "80's Arkade Bulls #1895",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1895.png",
        "dna": "10da98f16f64210c813ab15cc081b9b589f0d12f",
        "edition": 1895,
        "date": 1654567477467,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Cyber",
                "score": 141.42857142857142
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1895",
        "rarity_score": 214.7046029909794,
        "rarity_level": "RARE"
    },
    "1896": {
        "name": "80's Arkade Bulls #1896",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1896.png",
        "dna": "7a81b1437981138130380882894b91d5c85a7b73",
        "edition": 1896,
        "date": 1654568062737,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1896",
        "rarity_score": 120.44371315145919,
        "rarity_level": "COMMON"
    },
    "1897": {
        "name": "80's Arkade Bulls #1897",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1897.png",
        "dna": "75592cc8d8553cf6242903c6c5b1283a3206800d",
        "edition": 1897,
        "date": 1654567667286,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1897",
        "rarity_score": 100.49099339320206,
        "rarity_level": "COMMON"
    },
    "1898": {
        "name": "80's Arkade Bulls #1898",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1898.png",
        "dna": "0101fcf1a5c3f4d112e4b67701cafb3ac028492f",
        "edition": 1898,
        "date": 1654567136078,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Rare",
        "rarity": 5.4,
        "id": "1898",
        "rarity_score": 106.8600125549278,
        "rarity_level": "COMMON"
    },
    "1899": {
        "name": "80's Arkade Bulls #1899",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1899.png",
        "dna": "7b458a989c9e7b780382745d872553d43ba9daaf",
        "edition": 1899,
        "date": 1654567459573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.7142857142857135,
        "id": "1899",
        "rarity_score": 110.69925120201242,
        "rarity_level": "COMMON"
    },
    "1900": {
        "name": "80's Arkade Bulls #1900",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1900.png",
        "dna": "4bc796158eae0c8f41dc95e7c3ce268f0bc23da8",
        "edition": 1900,
        "date": 1654567197948,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1900",
        "rarity_score": 76.02530010405258,
        "rarity_level": "COMMON"
    },
    "1901": {
        "name": "80's Arkade Bulls #1901",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1901.png",
        "dna": "3ee860de38b706e3a016c375b12a7ede1fb21115",
        "edition": 1901,
        "date": 1654567569938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 73.33333333333333
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "1901",
        "rarity_score": 143.75671618179393,
        "rarity_level": "COMMON"
    },
    "1902": {
        "name": "80's Arkade Bulls #1902",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1902.png",
        "dna": "3beca9636aad7dbf3fb5c4ebceec9f87db191f82",
        "edition": 1902,
        "date": 1654567929011,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.2857142857142865,
        "id": "1902",
        "rarity_score": 127.37870662144749,
        "rarity_level": "COMMON"
    },
    "1903": {
        "name": "80's Arkade Bulls #1903",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1903.png",
        "dna": "f5c70a84a4a1899ed3ddb07a02b2c4a6d42e2e2c",
        "edition": 1903,
        "date": 1654568001760,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mega Bull Suit",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1903",
        "rarity_score": 107.2489188705951,
        "rarity_level": "COMMON"
    },
    "1904": {
        "name": "80's Arkade Bulls #1904",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1904.png",
        "dna": "b78a94f94baabbea2231e13ffa9811c1903d4034",
        "edition": 1904,
        "date": 1654567480162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Rare",
        "rarity": 5.7142857142857135,
        "id": "1904",
        "rarity_score": 145.87614519891525,
        "rarity_level": "COMMON"
    },
    "1905": {
        "name": "80's Arkade Bulls #1905",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1905.png",
        "dna": "b2b08d1a386e2a187050b9b5953dfc61145374c4",
        "edition": 1905,
        "date": 1654567715341,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 7,
        "id": "1905",
        "rarity_score": 79.27386611829382,
        "rarity_level": "COMMON"
    },
    "1906": {
        "name": "80's Arkade Bulls #1906",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1906.png",
        "dna": "e16de8350a301397c28ad23c9247971d022c135f",
        "edition": 1906,
        "date": 1654568217839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Super Kadenian", "score": 110.0 }
        ],
        "rank": "Epic",
        "rarity": 4.5,
        "id": "1906",
        "rarity_score": 291.4332937680937,
        "rarity_level": "RARE"
    },
    "1907": {
        "name": "80's Arkade Bulls #1907",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1907.png",
        "dna": "e09a0df90fa14b50760583b06c5e4d3c20574463",
        "edition": 1907,
        "date": 1654568144050,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Super Kadenian",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 3,
        "id": "1907",
        "rarity_score": 262.51996153451967,
        "rarity_level": "RARE"
    },
    "1908": {
        "name": "80's Arkade Bulls #1908",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1908.png",
        "dna": "06c129430f4585961f00fd3c1e7f046c9920bf1c",
        "edition": 1908,
        "date": 1654567927945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 9.142857142857142,
        "id": "1908",
        "rarity_score": 74.08527909117763,
        "rarity_level": "COMMON"
    },
    "1909": {
        "name": "80's Arkade Bulls #1909",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1909.png",
        "dna": "57e5be1f290e797312b169db8000f94d2636f320",
        "edition": 1909,
        "date": 1654568111246,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 8.6,
        "id": "1909",
        "rarity_score": 80.60551998889005,
        "rarity_level": "COMMON"
    },
    "1910": {
        "name": "80's Arkade Bulls #1910",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1910.png",
        "dna": "04bfe3a09c82966b5179d957543cd73974799a0f",
        "edition": 1910,
        "date": 1654567396057,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.5,
        "id": "1910",
        "rarity_score": 140.43088624338625,
        "rarity_level": "COMMON"
    },
    "1911": {
        "name": "80's Arkade Bulls #1911",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1911.png",
        "dna": "2f0cd53372d2f1a8129bf60b41dcc524dd53feb1",
        "edition": 1911,
        "date": 1654567830283,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Rare",
        "rarity": 4.666666666666666,
        "id": "1911",
        "rarity_score": 111.00319796906781,
        "rarity_level": "COMMON"
    },
    "1912": {
        "name": "80's Arkade Bulls #1912",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1912.png",
        "dna": "c043989293c8049f4b6fbb30907d2f972ba0a82a",
        "edition": 1912,
        "date": 1654567674747,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1912",
        "rarity_score": 135.91212845084874,
        "rarity_level": "COMMON"
    },
    "1913": {
        "name": "80's Arkade Bulls #1913",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1913.png",
        "dna": "d70e306f1bf9b62df3151c749d8792550103fc3c",
        "edition": 1913,
        "date": 1654567946728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1913",
        "rarity_score": 110.57883738871692,
        "rarity_level": "COMMON"
    },
    "1914": {
        "name": "80's Arkade Bulls #1914",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1914.png",
        "dna": "e5fbc16f5a300c92c95bf421c14e7112e40fcc58",
        "edition": 1914,
        "date": 1654568167622,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Pilot", "score": 90.0 }
        ],
        "rank": "Super",
        "rarity": 2.5,
        "id": "1914",
        "rarity_score": 237.11247054621964,
        "rarity_level": "RARE"
    },
    "1915": {
        "name": "80's Arkade Bulls #1915",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1915.png",
        "dna": "bb20f4f97025d12daae0103048c83fd074025e7e",
        "edition": 1915,
        "date": 1654568189533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            }
        ],
        "rarity": 6.5,
        "rank": "Common",
        "id": "1915",
        "rarity_score": 147.56880215571533,
        "rarity_level": "COMMON"
    },
    "1916": {
        "name": "80's Arkade Bulls #1916",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1916.png",
        "dna": "68e389ab06155c7ed6639f149bd0a4c81c7b86e2",
        "edition": 1916,
        "date": 1654567413579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            { "trait_type": "Head", "value": "Banana Peel", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1916",
        "rarity_score": 193.11128178870115,
        "rarity_level": "COMMON"
    },
    "1917": {
        "name": "80's Arkade Bulls #1917",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1917.png",
        "dna": "0d168e765b89d332927471203f217c2d0055812b",
        "edition": 1917,
        "date": 1654568067136,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Smiling Purple",
                "score": 28.285714285714285
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.2,
        "id": "1917",
        "rarity_score": 79.97928571428572,
        "rarity_level": "COMMON"
    },
    "1918": {
        "name": "80's Arkade Bulls #1918",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1918.png",
        "dna": "ba4030b9dad3f91fd82b0d627cc255d6927df507",
        "edition": 1918,
        "date": 1654567443770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.428571428571429,
        "id": "1918",
        "rarity_score": 177.04042911842967,
        "rarity_level": "COMMON"
    },
    "1919": {
        "name": "80's Arkade Bulls #1919",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1919.png",
        "dna": "b31e63f1ef3a2ad0b6e489e18f6423701adc3c59",
        "edition": 1919,
        "date": 1654567549009,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Rare",
        "rarity": 4.714285714285714,
        "id": "1919",
        "rarity_score": 201.01986138654016,
        "rarity_level": "COMMON"
    },
    "1920": {
        "name": "80's Arkade Bulls #1920",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1920.png",
        "dna": "8ef658d08ab2fca04e6a82dac82136d05ff406cb",
        "edition": 1920,
        "date": 1654567916897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.2857142857142865,
        "id": "1920",
        "rarity_score": 130.75349244859845,
        "rarity_level": "COMMON"
    },
    "1921": {
        "name": "80's Arkade Bulls #1921",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1921.png",
        "dna": "4c8efe4c70ade427e8d05537359ca76476326456",
        "edition": 1921,
        "date": 1654567866681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.857142857142858,
        "id": "1921",
        "rarity_score": 110.0829504951266,
        "rarity_level": "COMMON"
    },
    "1922": {
        "name": "80's Arkade Bulls #1922",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1922.png",
        "dna": "ac17e027e76b2d53bacc5ab0f61084681dad23bc",
        "edition": 1922,
        "date": 1654568051626,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 8.2,
        "id": "1922",
        "rarity_score": 55.45943490480461,
        "rarity_level": "COMMON"
    },
    "1923": {
        "name": "80's Arkade Bulls #1923",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1923.png",
        "dna": "4fab52ac4a1085354bdab4ef3b9e2e9974c94e13",
        "edition": 1923,
        "date": 1654568235053,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Krown", "score": 220.0 }
        ],
        "rank": "Super",
        "rarity": 2.2,
        "id": "1923",
        "rarity_score": 371.1360924165684,
        "rarity_level": "RARE"
    },
    "1924": {
        "name": "80's Arkade Bulls #1924",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1924.png",
        "dna": "2760bbb8860ab237b3131e46fd28127c14526e0f",
        "edition": 1924,
        "date": 1654567154982,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 }
        ],
        "rank": "Common",
        "rarity": 6.4,
        "id": "1924",
        "rarity_score": 97.25185270558406,
        "rarity_level": "COMMON"
    },
    "1925": {
        "name": "80's Arkade Bulls #1925",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1925.png",
        "dna": "66e640f2a670159452a2aefcf1343b14c706a340",
        "edition": 1925,
        "date": 1654567928454,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.571428571428571,
        "id": "1925",
        "rarity_score": 106.56820594618802,
        "rarity_level": "COMMON"
    },
    "1926": {
        "name": "80's Arkade Bulls #1926",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1926.png",
        "dna": "df6f6f02fc1ff413763e6679923524089e4f73f9",
        "edition": 1926,
        "date": 1654567896625,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 8.857142857142858,
        "id": "1926",
        "rarity_score": 74.85472341062454,
        "rarity_level": "COMMON"
    },
    "1927": {
        "name": "80's Arkade Bulls #1927",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1927.png",
        "dna": "0a724a9553f40c6c88b444055fb8fd85f0c64000",
        "edition": 1927,
        "date": 1654567250038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Frowning Brown",
                "score": 17.678571428571427
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1927",
        "rarity_score": 76.08074934732575,
        "rarity_level": "COMMON"
    },
    "1928": {
        "name": "80's Arkade Bulls #1928",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1928.png",
        "dna": "b4b198dfc4f0cb8b0f54160de0641c0068b082ca",
        "edition": 1928,
        "date": 1654567322295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Kegeta Hair",
                "score": 76.15384615384616
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1928",
        "rarity_score": 207.0723768474509,
        "rarity_level": "RARE"
    },
    "1929": {
        "name": "80's Arkade Bulls #1929",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1929.png",
        "dna": "e0c8ab72e224a6d7614727dcd35066aaebae66b7",
        "edition": 1929,
        "date": 1654567419734,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Rare",
        "rarity": 4.833333333333333,
        "id": "1929",
        "rarity_score": 187.16779273113522,
        "rarity_level": "COMMON"
    },
    "1930": {
        "name": "80's Arkade Bulls #1930",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1930.png",
        "dna": "f509220014bc845494608bdc534f257b39b91266",
        "edition": 1930,
        "date": 1654567997780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Space Invaders",
                "score": 123.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Green",
                "score": 34.13793103448276
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Epic",
        "rarity": 4.333333333333333,
        "id": "1930",
        "rarity_score": 211.39227531903342,
        "rarity_level": "RARE"
    },
    "1931": {
        "name": "80's Arkade Bulls #1931",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1931.png",
        "dna": "f4346b812e2b57207c407a1069d9e9daea6d966c",
        "edition": 1931,
        "date": 1654567881640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.571428571428571,
        "id": "1931",
        "rarity_score": 89.13306611115289,
        "rarity_level": "COMMON"
    },
    "1932": {
        "name": "80's Arkade Bulls #1932",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1932.png",
        "dna": "5ab33ce9bf0d1dbbf1887637b39375d5f7cca385",
        "edition": 1932,
        "date": 1654567240101,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.5,
        "id": "1932",
        "rarity_score": 79.16439370495559,
        "rarity_level": "COMMON"
    },
    "1933": {
        "name": "80's Arkade Bulls #1933",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1933.png",
        "dna": "4f968c7f43708335ac0e31477ce2f4a5ce9ea3b9",
        "edition": 1933,
        "date": 1654568229657,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            {
                "trait_type": "Head",
                "value": "Afro",
                "score": 86.08695652173913
            }
        ],
        "rank": "Super",
        "rarity": 2,
        "id": "1933",
        "rarity_score": 253.444099378882,
        "rarity_level": "RARE"
    },
    "1934": {
        "name": "80's Arkade Bulls #1934",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1934.png",
        "dna": "7896e66161046e600fd5fd1897689b1171d5282d",
        "edition": 1934,
        "date": 1654568010939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Shirt",
                "score": 20.20408163265306
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Common",
        "rarity": 6.333333333333333,
        "id": "1934",
        "rarity_score": 91.47074647300757,
        "rarity_level": "COMMON"
    },
    "1935": {
        "name": "80's Arkade Bulls #1935",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1935.png",
        "dna": "f7e8d7283ce1eccbb6babda1d2522c8c6f61cfd7",
        "edition": 1935,
        "date": 1654567335945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Red Turtle Mask",
                "score": 41.25
            },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Rare",
        "rarity": 5.111111111111111,
        "id": "1935",
        "rarity_score": 186.59470760668964,
        "rarity_level": "COMMON"
    },
    "1936": {
        "name": "80's Arkade Bulls #1936",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1936.png",
        "dna": "d439e16f5381f323db6d1435e6948ec069e79586",
        "edition": 1936,
        "date": 1654567436037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.857142857142858,
        "id": "1936",
        "rarity_score": 89.33545272237517,
        "rarity_level": "COMMON"
    },
    "1937": {
        "name": "80's Arkade Bulls #1937",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1937.png",
        "dna": "949a34efbca5dd76fb26e1b9a4da7ceb5068729f",
        "edition": 1937,
        "date": 1654567190963,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            }
        ],
        "rank": "Epic",
        "rarity": 3.8,
        "id": "1937",
        "rarity_score": 152.68712235852195,
        "rarity_level": "COMMON"
    },
    "1938": {
        "name": "80's Arkade Bulls #1938",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1938.png",
        "dna": "2d94711fcbd11464e15be71db65be46f1025ff41",
        "edition": 1938,
        "date": 1654567153598,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            }
        ],
        "rank": "Rare",
        "rarity": 4.6,
        "id": "1938",
        "rarity_score": 132.58158328371897,
        "rarity_level": "COMMON"
    },
    "1939": {
        "name": "80's Arkade Bulls #1939",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1939.png",
        "dna": "53394ba98930511b52a0eddd4a1814ecb2485568",
        "edition": 1939,
        "date": 1654568223579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Super",
        "rarity": 1.6,
        "id": "1939",
        "rarity_score": 328.60816326530613,
        "rarity_level": "RARE"
    },
    "1940": {
        "name": "80's Arkade Bulls #1940",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1940.png",
        "dna": "23d7c803c702140735b3f1b03990a28d555729bf",
        "edition": 1940,
        "date": 1654567498876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Blue Turtle Mask",
                "score": 41.25
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1940",
        "rarity_score": 147.18254507374695,
        "rarity_level": "COMMON"
    },
    "1941": {
        "name": "80's Arkade Bulls #1941",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1941.png",
        "dna": "9676b736bf4f2632f2731be64d54d37590d4894e",
        "edition": 1941,
        "date": 1654567840898,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Leather Jacket",
                "score": 22.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 7.166666666666666,
        "id": "1941",
        "rarity_score": 104.5116099356225,
        "rarity_level": "COMMON"
    },
    "1942": {
        "name": "80's Arkade Bulls #1942",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1942.png",
        "dna": "59590b0b0d8125fd401e103763ca57a8d2aff4b8",
        "edition": 1942,
        "date": 1654567950608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 7.428571428571429,
        "id": "1942",
        "rarity_score": 118.71293592948865,
        "rarity_level": "COMMON"
    },
    "1943": {
        "name": "80's Arkade Bulls #1943",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1943.png",
        "dna": "f6ada08389d73fbdc0dfe2bf4f2e2bbda16edd51",
        "edition": 1943,
        "date": 1654568015542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Glowing Red Eyes",
                "score": 27.123287671232877
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1943",
        "rarity_score": 115.43910200327439,
        "rarity_level": "COMMON"
    },
    "1944": {
        "name": "80's Arkade Bulls #1944",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1944.png",
        "dna": "c6c52dc6751e84928588d180eb2805a09b51cae6",
        "edition": 1944,
        "date": 1654567214601,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 9.333333333333334,
        "id": "1944",
        "rarity_score": 64.19319611127852,
        "rarity_level": "COMMON"
    },
    "1945": {
        "name": "80's Arkade Bulls #1945",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1945.png",
        "dna": "61b14aee22fc183f521c88378535ea08dc052d94",
        "edition": 1945,
        "date": 1654568217274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "City",
                "score": 21.06382978723404
            },
            { "trait_type": "Base", "value": "Invisibull", "score": 99.0 },
            {
                "trait_type": "Outfit",
                "value": "Mined Diamond",
                "score": 36.666666666666664
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "Sunglasses",
                "score": 27.887323943661972
            },
            { "trait_type": "Head", "value": "Kalvin Hat", "score": 99.0 }
        ],
        "rank": "Super",
        "rarity": 3.333333333333333,
        "id": "1945",
        "rarity_score": 288.2657077215064,
        "rarity_level": "RARE"
    },
    "1946": {
        "name": "80's Arkade Bulls #1946",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1946.png",
        "dna": "9a7ebfc3018c24f75bef30bb274fb72b453be475",
        "edition": 1946,
        "date": 1654567868227,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142858,
        "id": "1946",
        "rarity_score": 179.23075460595032,
        "rarity_level": "COMMON"
    },
    "1947": {
        "name": "80's Arkade Bulls #1947",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1947.png",
        "dna": "3a8faf5999ad9530ae2dce7e1b072aa05e03e084",
        "edition": 1947,
        "date": 1654567826100,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rank": "Common",
        "rarity": 8.666666666666666,
        "id": "1947",
        "rarity_score": 83.01809811095723,
        "rarity_level": "COMMON"
    },
    "1948": {
        "name": "80's Arkade Bulls #1948",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1948.png",
        "dna": "5445dd5a85f68668c1801e5ed0820bf0b80b0b7b",
        "edition": 1948,
        "date": 1654567931741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Arctic",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Base",
                "value": "Frowning Red",
                "score": 32.459016393442624
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Common",
        "rarity": 6.7142857142857135,
        "id": "1948",
        "rarity_score": 106.70890074274362,
        "rarity_level": "COMMON"
    },
    "1949": {
        "name": "80's Arkade Bulls #1949",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1949.png",
        "dna": "46c3c7ab4165c0fd418ce59e51fb579a28f39d34",
        "edition": 1949,
        "date": 1654567961163,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1949",
        "rarity_score": 98.17270355188165,
        "rarity_level": "COMMON"
    },
    "1950": {
        "name": "80's Arkade Bulls #1950",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1950.png",
        "dna": "db39510c5971f7f2405eaec114cac5cad755c28c",
        "edition": 1950,
        "date": 1654568185314,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            }
        ],
        "rank": "Common",
        "rarity": 7.666666666666667,
        "id": "1950",
        "rarity_score": 116.13251797156848,
        "rarity_level": "COMMON"
    },
    "1951": {
        "name": "80's Arkade Bulls #1951",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1951.png",
        "dna": "b3d9eee5d9741d9ef012428219328384259fed3e",
        "edition": 1951,
        "date": 1654568137768,
        "attributes": [
            { "trait_type": "Background", "value": "Beach", "score": 30.9375 },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Eyes",
                "value": "Pink Angry Eyes",
                "score": 8.148148148148149
            }
        ],
        "rank": "Common",
        "rarity": 6.2,
        "id": "1951",
        "rarity_score": 167.98614075898558,
        "rarity_level": "COMMON"
    },
    "1952": {
        "name": "80's Arkade Bulls #1952",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1952.png",
        "dna": "ad2ce1c66421c781ca2edcdf7409eb19f6917cac",
        "edition": 1952,
        "date": 1654568159747,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            { "trait_type": "Outfit", "value": "Kitty Kad Tee", "score": 30.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Head",
                "value": "Mega Bull Mask",
                "score": 52.10526315789474
            }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1952",
        "rarity_score": 130.64786103189005,
        "rarity_level": "COMMON"
    },
    "1953": {
        "name": "80's Arkade Bulls #1953",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1953.png",
        "dna": "a6045d2bd85bd638f67f6333de0150efad04b458",
        "edition": 1953,
        "date": 1654568229168,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyewear",
                "value": "Orange Turtle Mask",
                "score": 27.5
            },
            { "trait_type": "Head", "value": "Pink Mohawk", "score": 79.2 }
        ],
        "rank": "Epic",
        "rarity": 3.5999999999999996,
        "id": "1953",
        "rarity_score": 231.31538461538463,
        "rarity_level": "RARE"
    },
    "1954": {
        "name": "80's Arkade Bulls #1954",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1954.png",
        "dna": "1d4c430b5c0db15a657467330cd0a2f9ea027ebb",
        "edition": 1954,
        "date": 1654567204804,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 10.588235294117647
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Striped Tee",
                "score": 14.347826086956522
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 8.333333333333334,
        "id": "1954",
        "rarity_score": 61.388123880874424,
        "rarity_level": "COMMON"
    },
    "1955": {
        "name": "80's Arkade Bulls #1955",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1955.png",
        "dna": "e46ef4bef045dc0725d7d6214c092f7c4bfec75a",
        "edition": 1955,
        "date": 1654567669568,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Cloud",
                "score": 20.842105263157894
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Turtleneck",
                "score": 21.29032258064516
            },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Black Spiderbull Mask",
                "score": 30.9375
            }
        ],
        "rank": "Rare",
        "rarity": 5.857142857142857,
        "id": "1955",
        "rarity_score": 139.05343892451947,
        "rarity_level": "COMMON"
    },
    "1956": {
        "name": "80's Arkade Bulls #1956",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1956.png",
        "dna": "188bf7a118eaffeba043938f9faa4b316f1b5a47",
        "edition": 1956,
        "date": 1654567617289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Smiling Gray",
                "score": 23.571428571428573
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Dok Brown",
                "score": 63.87096774193548
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 6,
        "id": "1956",
        "rarity_score": 152.6107386750347,
        "rarity_level": "COMMON"
    },
    "1957": {
        "name": "80's Arkade Bulls #1957",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1957.png",
        "dna": "62dbb9878d4b13c08db795225918e844a455f74e",
        "edition": 1957,
        "date": 1654567558856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 20.20408163265306
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            },
            { "trait_type": "Head", "value": "Mullet", "score": 82.5 }
        ],
        "rank": "Epic",
        "rarity": 4.428571428571429,
        "id": "1957",
        "rarity_score": 162.7677764790842,
        "rarity_level": "COMMON"
    },
    "1958": {
        "name": "80's Arkade Bulls #1958",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1958.png",
        "dna": "663ed36ce5487048aac11111f1e32e12afbfe703",
        "edition": 1958,
        "date": 1654567207871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple Sky",
                "score": 13.75
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Striped Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Epic",
        "rarity": 4,
        "id": "1958",
        "rarity_score": 116.930306310094,
        "rarity_level": "COMMON"
    },
    "1959": {
        "name": "80's Arkade Bulls #1959",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1959.png",
        "dna": "13c95ea48b05139f074e10729e030ffca75cf285",
        "edition": 1959,
        "date": 1654568139168,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            }
        ],
        "rank": "Common",
        "rarity": 7.6,
        "id": "1959",
        "rarity_score": 93.0778274744821,
        "rarity_level": "COMMON"
    },
    "1960": {
        "name": "80's Arkade Bulls #1960",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1960.png",
        "dna": "20711eb9cdbfa741e6d0f3e99a7f3383ffa7785d",
        "edition": 1960,
        "date": 1654567661143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Frowning Purple",
                "score": 52.10526315789474
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 5.428571428571429,
        "id": "1960",
        "rarity_score": 126.7866238252713,
        "rarity_level": "COMMON"
    },
    "1961": {
        "name": "80's Arkade Bulls #1961",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1961.png",
        "dna": "315fa7f547e4ca7a281b01893d70dd21a606cfa5",
        "edition": 1961,
        "date": 1654567299319,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 6.833333333333333,
        "id": "1961",
        "rarity_score": 105.9560550259703,
        "rarity_level": "COMMON"
    },
    "1962": {
        "name": "80's Arkade Bulls #1962",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1962.png",
        "dna": "f4c51641195cc7572e6e6efd9b5ecc71a8327dd3",
        "edition": 1962,
        "date": 1654567206843,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Grinning Purple",
                "score": 53.513513513513516
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rarity": 5.5,
        "rank": "Rare",
        "id": "1962",
        "rarity_score": 158.58233048252475,
        "rarity_level": "COMMON"
    },
    "1963": {
        "name": "80's Arkade Bulls #1963",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1963.png",
        "dna": "83bea12dd4d24908f9c11b8ce9b99c0ca4643665",
        "edition": 1963,
        "date": 1654567705517,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Super Kadenian",
                "score": 82.5
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rarity": 4.714285714285714,
        "rank": "Rare",
        "id": "1963",
        "rarity_score": 179.60955843711704,
        "rarity_level": "COMMON"
    },
    "1964": {
        "name": "80's Arkade Bulls #1964",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1964.png",
        "dna": "f1fd029cddd373f6fdaf40853c4a2c2f3ca0dfb6",
        "edition": 1964,
        "date": 1654567959677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Green Turtleneck",
                "score": 18.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1964",
        "rarity_score": 93.1879948429035,
        "rarity_level": "COMMON"
    },
    "1965": {
        "name": "80's Arkade Bulls #1965",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1965.png",
        "dna": "722d24506f75ae73bd85366fe2b724f79a1532ef",
        "edition": 1965,
        "date": 1654567302883,
        "attributes": [
            { "trait_type": "Background", "value": "Jungle", "score": 20.625 },
            {
                "trait_type": "Base",
                "value": "Grinning Silver",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 }
        ],
        "rank": "Common",
        "rarity": 7.333333333333333,
        "id": "1965",
        "rarity_score": 101.740806168399,
        "rarity_level": "COMMON"
    },
    "1966": {
        "name": "80's Arkade Bulls #1966",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1966.png",
        "dna": "3ddb894a9f918d5485144607d82335ba9d2e0705",
        "edition": 1966,
        "date": 1654568199336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Smoking Brown",
                "score": 11.314285714285715
            },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Pink",
                "score": 9.209302325581396
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 25.384615384615383
            }
        ],
        "rarity": 7,
        "rank": "Common",
        "id": "1966",
        "rarity_score": 106.794551883795,
        "rarity_level": "COMMON"
    },
    "1967": {
        "name": "80's Arkade Bulls #1967",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1967.png",
        "dna": "6d707892bf75f85c36c91ae856580591084b80fb",
        "edition": 1967,
        "date": 1654567685965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Kad Man Vest",
                "score": 48.292682926829265
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 6.857142857142857,
        "id": "1967",
        "rarity_score": 156.86907826415214,
        "rarity_level": "COMMON"
    },
    "1968": {
        "name": "80's Arkade Bulls #1968",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1968.png",
        "dna": "e860ea65bd2ac00d9247bf7d26bf2971e5043a76",
        "edition": 1968,
        "date": 1654567221621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Phone Home",
                "score": 40.40816326530612
            },
            {
                "trait_type": "Base",
                "value": "Frowning Gray",
                "score": 26.756756756756758
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Water Tunic",
                "score": 40.40816326530612
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            }
        ],
        "rank": "Common",
        "rarity": 6.5,
        "id": "1968",
        "rarity_score": 127.40806738550623,
        "rarity_level": "COMMON"
    },
    "1969": {
        "name": "80's Arkade Bulls #1969",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1969.png",
        "dna": "1b6afc4516b2cd5ad8fafd377615a4c48a96760e",
        "edition": 1969,
        "date": 1654567676458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Grinning Gray",
                "score": 18.857142857142858
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            { "trait_type": "Mask", "value": "KaDOOM", "score": 15.84 }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "1969",
        "rarity_score": 120.50812888960218,
        "rarity_level": "COMMON"
    },
    "1970": {
        "name": "80's Arkade Bulls #1970",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1970.png",
        "dna": "b373caa808ff0d30c38623e072d5e94e66685825",
        "edition": 1970,
        "date": 1654567151809,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Blue",
                "score": 6.666666666666667
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            {
                "trait_type": "Eyes",
                "value": "Green",
                "score": 7.615384615384615
            }
        ],
        "rarity": 7.8,
        "rank": "Common",
        "id": "1970",
        "rarity_score": 76.45228897178352,
        "rarity_level": "COMMON"
    },
    "1971": {
        "name": "80's Arkade Bulls #1971",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1971.png",
        "dna": "ce38a5a3bdf4a8bf7a4bf9b715a987c64ed54087",
        "edition": 1971,
        "date": 1654567694453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue",
                "score": 11.715976331360947
            },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "White",
                "score": 6.428571428571429
            },
            {
                "trait_type": "Outfit",
                "value": "Kalvin Turtleneck",
                "score": 21.75824175824176
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Mask",
                "value": "Batbull Mask",
                "score": 20.842105263157894
            }
        ],
        "rank": "Common",
        "rarity": 7.142857142857143,
        "id": "1971",
        "rarity_score": 103.64245575694177,
        "rarity_level": "COMMON"
    },
    "1972": {
        "name": "80's Arkade Bulls #1972",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1972.png",
        "dna": "35160a245eb90de95fc195f7537b97cc75b67009",
        "edition": 1972,
        "date": 1654567267545,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Blue Sky",
                "score": 15.96774193548387
            },
            {
                "trait_type": "Base",
                "value": "Smiling Brown",
                "score": 14.042553191489361
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Striped Tee",
                "score": 18.67924528301887
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            }
        ],
        "rank": "Common",
        "rarity": 7.5,
        "id": "1972",
        "rarity_score": 89.43501826214991,
        "rarity_level": "COMMON"
    },
    "1973": {
        "name": "80's Arkade Bulls #1973",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1973.png",
        "dna": "950101946c077e9bd0c83fc95ad3c8403d980f9f",
        "edition": 1973,
        "date": 1654567637563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling White",
                "score": 15.114503816793894
            },
            {
                "trait_type": "Horns",
                "value": "Purple",
                "score": 6.711864406779661
            },
            { "trait_type": "Outfit", "value": "Kegeta Suit", "score": 110.0 },
            {
                "trait_type": "Neck",
                "value": "Bronze Ice",
                "score": 35.357142857142854
            },
            {
                "trait_type": "Eyes",
                "value": "Green Angry Eyes",
                "score": 8.571428571428571
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Spiderbull Mask",
                "score": 25.384615384615383
            }
        ],
        "rank": "Rare",
        "rarity": 5,
        "id": "1973",
        "rarity_score": 215.83820378202384,
        "rarity_level": "RARE"
    },
    "1974": {
        "name": "80's Arkade Bulls #1974",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1974.png",
        "dna": "e05867a06a65dfa2f3d0dce649c5202d4c2568da",
        "edition": 1974,
        "date": 1654567224396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Base",
                "value": "Frowning White",
                "score": 17.52212389380531
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Batbull Suit",
                "score": 38.07692307692308
            },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            }
        ],
        "rarity": 7.833333333333334,
        "rank": "Common",
        "id": "1974",
        "rarity_score": 91.19743021072777,
        "rarity_level": "COMMON"
    },
    "1975": {
        "name": "80's Arkade Bulls #1975",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1975.png",
        "dna": "9ec8cafc630840d3607fee17d4f2fde3cbb1d904",
        "edition": 1975,
        "date": 1654567939977,
        "attributes": [
            { "trait_type": "Background", "value": "Duck Hunt", "score": 45.0 },
            {
                "trait_type": "Base",
                "value": "Frowning Silver",
                "score": 30.9375
            },
            {
                "trait_type": "Horns",
                "value": "Gold",
                "score": 11.379310344827585
            },
            {
                "trait_type": "Outfit",
                "value": "Orange Shirt",
                "score": 14.776119402985074
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadetroid",
                "score": 15.590551181102363
            }
        ],
        "rank": "Common",
        "rarity": 6.2857142857142865,
        "id": "1975",
        "rarity_score": 130.65069598395112,
        "rarity_level": "COMMON"
    },
    "1976": {
        "name": "80's Arkade Bulls #1976",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1976.png",
        "dna": "633e369aada637d7054d448e9459d6ac44088f7f",
        "edition": 1976,
        "date": 1654567646811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Sky",
                "score": 16.097560975609756
            },
            { "trait_type": "Base", "value": "Grinning Brown", "score": 15.0 },
            {
                "trait_type": "Horns",
                "value": "Black",
                "score": 6.711864406779661
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Tee",
                "score": 29.55223880597015
            },
            { "trait_type": "Neck", "value": "Skooby Collar", "score": 18.0 },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Mask",
                "value": "Chaintrooper Mask",
                "score": 20.412371134020617
            }
        ],
        "rank": "Common",
        "rarity": 7.125,
        "id": "1976",
        "rarity_score": 118.23590896072442,
        "rarity_level": "COMMON"
    },
    "1977": {
        "name": "80's Arkade Bulls #1977",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1977.png",
        "dna": "2169fbf3d297a777ec959487f844ed616162e557",
        "edition": 1977,
        "date": 1654567361061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink",
                "score": 10.050761421319796
            },
            {
                "trait_type": "Base",
                "value": "Smiling Silver",
                "score": 12.611464968152866
            },
            {
                "trait_type": "Horns",
                "value": "Tan",
                "score": 6.387096774193548
            },
            {
                "trait_type": "Outfit",
                "value": "Chain Fighter White",
                "score": 116.47058823529412
            },
            {
                "trait_type": "Eyes",
                "value": "Purple",
                "score": 8.148148148148149
            },
            {
                "trait_type": "Neck",
                "value": "Kong Tie",
                "score": 15.714285714285714
            },
            {
                "trait_type": "Weapon",
                "value": "Dual Sword",
                "score": 4.313725490196078
            },
            {
                "trait_type": "Eyewear",
                "value": "Pilot Goggles",
                "score": 55.0
            },
            { "trait_type": "Head", "value": "Flat Top", "score": 55.0 }
        ],
        "rank": "Rare",
        "rarity": 5.222222222222221,
        "id": "1977",
        "rarity_score": 283.69607075159024,
        "rarity_level": "RARE"
    },
    "1978": {
        "name": "80's Arkade Bulls #1978",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1978.png",
        "dna": "4c94fc333fa5b74277016bd6941da4b7c7bd5cf0",
        "edition": 1978,
        "date": 1654567905110,
        "attributes": [
            { "trait_type": "Background", "value": "Navi", "score": 55.0 },
            {
                "trait_type": "Base",
                "value": "Grinning White",
                "score": 16.097560975609756
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Forest Tunic",
                "score": 29.55223880597015
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Angry Eyes",
                "score": 8.53448275862069
            },
            {
                "trait_type": "Weapon",
                "value": "Lightsaber",
                "score": 4.647887323943662
            },
            {
                "trait_type": "Mask",
                "value": "Kadena Vader Mask",
                "score": 14.244604316546763
            }
        ],
        "rank": "Rare",
        "rarity": 4.857142857142858,
        "id": "1978",
        "rarity_score": 135.66298107724273,
        "rarity_level": "COMMON"
    },
    "1979": {
        "name": "80's Arkade Bulls #1979",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1979.png",
        "dna": "916d0290d3729bca22b2a9d6868e89ec0c663893",
        "edition": 1979,
        "date": 1654568182100,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 47.142857142857146
            },
            {
                "trait_type": "Base",
                "value": "Smoking Purple",
                "score": 46.04651162790697
            },
            {
                "trait_type": "Horns",
                "value": "Gray",
                "score": 7.586206896551724
            },
            {
                "trait_type": "Outfit",
                "value": "Black Spiderbull Suit",
                "score": 99.0
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Angry Eyes",
                "score": 8.319327731092438
            },
            { "trait_type": "Eyewear", "value": "Pilot Goggles", "score": 55.0 }
        ],
        "rank": "Epic",
        "rarity": 4.166666666666667,
        "id": "1979",
        "rarity_score": 263.0949033984083,
        "rarity_level": "RARE"
    },
    "1980": {
        "name": "80's Arkade Bulls #1980",
        "description": "Groovy baby",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-80s-bulls/1980.png",
        "dna": "1fe2f28c526e3f19fcf20512aeb502cc9a403472",
        "edition": 1980,
        "date": 1654568228177,
        "attributes": [
            { "trait_type": "Base", "value": "Glyph", "score": 99.0 },
            { "trait_type": "Eyes", "value": "Blue", "score": 8.8 },
            {
                "trait_type": "Neck",
                "value": "Silver Ice",
                "score": 25.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Purple Turtle Mask",
                "score": 34.73684210526316
            },
            { "trait_type": "Head", "value": "Smurk", "score": 165.0 }
        ],
        "rank": "Super",
        "rarity": 1.7999999999999998,
        "id": "1980",
        "rarity_score": 333.25112781954886,
        "rarity_level": "RARE"
    }
}



export default arkade_80s_bulls_metadata
import { sgk_weapons_metadata, weapons_rarity_map } from "../project_config_files/weapons_map";

function getNftImage(nftId) {
    nftId = nftId.split(":")[0];
    let nftGearRarity = weapons_rarity_map[nftId.split("-")[3]];
    let nftGearTypeData = sgk_weapons_metadata[nftId.split("-")[2]];
    let md = getBaseMd();
    let image = `${md["uri"]["data"]}${nftGearTypeData["type"]}/${nftGearTypeData["type"]}-${nftGearRarity}.png`

    return image;
}

function getNftName(subCollectionId, nftId) {
    return `${subCollectionId}:${nftId}`;
}

function getSubCollectionId(nftPrefix) {
    return nftPrefix.split("-")[2];
}

function getSubCollectionName(projectConfig, nftPrefix) {
    let id = getSubCollectionId(nftPrefix);
    if (projectConfig["child-collection-map"][id]) {
        return projectConfig["child-collection-map"][id]["name"];
    }
    return "N/A";
}

function getNftData(nftId) {
    let imageUri = getNftImage(nftId);
    let nftNum = nftId.split(":")[1];
    let nftCollection = nftId.split(":")[0];

    let nftGearRarity = weapons_rarity_map[nftCollection.split("-")[3]];
    let nftGearTypeData = sgk_weapons_metadata[nftCollection.split("-")[2]];
    let md = getBaseMd();

    md["uri"]["data"] = imageUri;
    md["datum"]["datum"]["name"] = `${nftGearTypeData["name"]} #${nftNum}`;
    md["datum"]["datum"]["attributes"][0]["value"] = nftGearTypeData["name"];
    md["datum"]["datum"]["attributes"][1]["value"] = nftGearRarity;

    return md;
}

function getBaseMd() {
    let md = {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes-weapons.nyc3.cdn.digitaloceanspaces.com/"
        },
        "datum": {
            "datum": {
                "name": "",
                "attributes": [
                    {
                        "trait_type": "Name",
                        "value": "",
                        "score": 1.0
                    },
                    { "trait_type": "Level", "value": "common" }
                ],
                "id": "1"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        },
        "rarity_score": 1.0,
        "rarity_level": "COMMON"
    }

    return md;
}

export {
    getNftImage,
    getNftName,
    getSubCollectionId,
    getSubCollectionName,
    getNftData,
    getBaseMd
}
import {
    Box,
    Button,
    CloseButton,
    Flex,
    FormLabel,
    Icon,
    IconButton,
    Input,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Textarea,
} from "@chakra-ui/react";

import React, { useContext, useState } from "react";
import { IsokoDialogContext} from "../IsokoContextProvider/IsokoDialogContextProvider";

import { FaTimes } from "react-icons/fa";



const ReportBug = () => {
    const { isOpenReportBug, onOpenReportBug, onCloseReportBug } =
        useContext(IsokoDialogContext);

    const initialData = {
        title: "",
        message: ""
    }
    const [feedBack, setFeedBack] = useState(initialData);

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFeedBack({ ...feedBack, [name]: value })
    }
    const { title, message } = feedBack

    return (
        <>

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpenReportBug}
                trapFocus={false}
                size="lg"
                onClose={onOpenReportBug}
                isCentered
            >
                <CloseButton onClick={onCloseReportBug} />
                <ModalOverlay />
                <ModalContent
                    bgColor="transparent"
                    className="latestDesign"
                    w="520px"
                >
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="1.75rem"
                        px="20px"
                    >
                        {" "}
                        <Flex justify={"space-between"} align="center">

                            <Text fontSize={["1.25rem"]} fontWeight={["900"]}>
                                Report a Bug
                            </Text>

                            <Icon
                                as={FaTimes}
                                onClick={onCloseReportBug}
                                color={"white"}
                                w="1.25rem"
                                h="1.25rem"
                                cursor={"pointer"}
                            />
                        </Flex>
                        <Box mt="1.38rem">
                            <Box>
                                <FormLabel fontSize={"1rem"} fontWeight={"900"}>
                                    Title
                                </FormLabel>
                                <Input name='title' placeholder="Example" borderRadius="0.25rem" border={"2px"} boxShadow={"rgba(0, 0, 0, 0.12)"} borderColor={"#bc3cf0"} _hover={{}} className="inputBox" borderStyle={"inset"} _focusVisible={{}} onChange={handleInputChange} value={title} />
                            </Box>
                        </Box>


                        <Box mt="1.38rem">
                            <Box>
                                <FormLabel fontSize={"1rem"} fontWeight={"900"}>
                                    Bug description
                                </FormLabel>
                                <Textarea name='message' placeholder="Let us know what the problem is" borderRadius="0.25rem" border={"2px"} boxShadow={"rgba(0, 0, 0, 0.12)"} borderColor={"#bc3cf0"} _hover={{}} className="inputBox" borderStyle={"inset"} _focusVisible={{}} height="8.375rem" onChange={handleInputChange} value={message} />
                            </Box>
                        </Box>

                        <Flex marginTop={"1.38rem"} justify={"flex-end"} gap="20px">


                            <Button
                                outline={"none"}
                                border="1px"
                                borderColor="#BD3DF0"
                                w="fit-content"
                                px="1rem"
                                py="0.375rem"
                                fontSize={"0.875rem"}
                                fontWeight={"700"}
                                bgColor="#BC3CF0"
                                _hover={{
                                    bgColor: "rgba(189, 61, 240, 0.33)",
                                }}
                                rounded={"full"}

                            >
                                Submit
                            </Button>
                        </Flex>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ReportBug;

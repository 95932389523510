import { Box, Divider } from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import { checkIfNullOrUndefined, useInterval } from "../utils/utils";
import {
    ATRIUM,
    BATTLE_HEROES_PROJECT_NAME_KEY,
    BOXING_BADGER_PROJECT_NAME_KEY,
    ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
    ISOKO,
    KADCARS_PROJECT_NAME_KEY,
    KDA_PUNKS_PROJECT_NAME_KEY,
    KISHU_KEN_PROJECT_NAME_KEY,
    LAZY_APES_PROJECT_NAME_KEY,
    LONG_POLLING_TIMER,
    PACT_RATS_PROJECT_NAME_KEY,
    PLATFORM,
    SECRET_GARDEN_OF_KADENA_GEN_0_PROJECT_NAME_KEY,
    SGK_WEAPONS_NAME_KEY,
    STACKED_TYPE,
} from "../utils/Constants";
import Footer from "../CommonComponents/Footer";
import ScreenSize from "../layouts/ScreenSize";
import HeroSlider from "./HeroSlider";
import Collections from "./Collections";
import ComingSoon from "./ComingSoon";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import Minted from "./Minted";
import BackToTopButton from "../scrollTo/BackToTopButton";

const HomePage = () => {
    const [marketplaceProjects, setMarketplaceProjects] = useState({});
    const [liveProjects, setLiveProjects] = useState({});
    const [upcomingProjects, setUpcomingProjects] = useState({});
    const navigate = useNavigate();
    const isokoCollectionsContext = useContext(IsokoCollectionsContext);

    useInterval(
        () => {
            refreshCollectionStatus();
        },
        LONG_POLLING_TIMER,
        false
    );

    useEffect(() => {
        if (!checkIfNullOrUndefined(isokoCollectionsContext.collections)) {
            Object.entries(isokoCollectionsContext.collections).map(
                ([projectName, projectConfig], index) => {
                    projectConfig.map((collectionConfig) => {
                        handleLaunchpadInclusion(projectName, collectionConfig);
                    });
                }
            );
        }
    }, []);

    useEffect(() => {
        async function getProjectInformation() {
            for (const [projectName, projectConfig] of Object.entries(
                isokoCollectionsContext.collections
            )) {
                for (const collection of projectConfig) {
                    handleProjectInclusionForPlatform(projectName, collection);
                }
            }
        }
        isokoCollectionsContext.collections && getProjectInformation();
    }, [isokoCollectionsContext.collections]);

    function checkMintWindowStatus(projectName, collectionConfig) {
        let projectEntry = {
            projectName: collectionConfig["projectName"],
            projectConfig: collectionConfig,
        };
        let mintStartTime =
            isokoCollectionsContext.getNetworkSpecificParameterFromProjectConfigDirectly(
                collectionConfig,
                "mint_start_time"
            );
        let mintEndTime =
            isokoCollectionsContext.getNetworkSpecificParameterFromProjectConfigDirectly(
                collectionConfig,
                "mint_end_time"
            );

        if (Date.now() < Date.parse(mintStartTime)) {
            setUpcomingProjects((upcomingProjects) => ({
                ...upcomingProjects,
                [collectionConfig["path"]]: collectionConfig,
            }));
        } else if (Date.now() > Date.parse(mintEndTime)) {
        } else if (
            Date.now() > Date.parse(mintStartTime) &&
            Date.now() < Date.parse(mintEndTime)
        ) {
            setLiveProjects((liveProjects) => ({
                ...liveProjects,
                [collectionConfig["path"]]: collectionConfig,
            }));
        }
    }

    function refreshCollectionStatus() {
        setLiveProjects({});
        setUpcomingProjects({});
        setMarketplaceProjects({});
        Object.entries(isokoCollectionsContext.collections).map(
            ([projectName, projectConfig], index) => {
                projectConfig.map((collectionConfig) => {
                    handleProjectInclusionForPlatform(
                        projectConfig["path"],
                        collectionConfig
                    );
                });
            }
        );
    }

    function handleProjectInclusionForPlatform(projectName, collectionConfig) {
        handleLaunchpadInclusion(projectName, collectionConfig);
        handleMarketplaceInclusion(collectionConfig);
    }

    function handleLaunchpadInclusion(projectName, collectionConfig) {
        if (collectionConfig["has_launchpad"]) {
            checkMintWindowStatus(projectName, collectionConfig);
        }
    }

    function handleMarketplaceInclusion(collectionConfig) {
        if (collectionConfig["has_marketplace"]) {
            setMarketplaceProjects((marketplaceProjects) => ({
                ...marketplaceProjects,
                [collectionConfig["path"]]: collectionConfig,
            }));
        } else if (collectionConfig["has_marketplace_test"]) {
            setMarketplaceProjects((marketplaceProjects) => ({
                ...marketplaceProjects,
                [collectionConfig["path"]]: collectionConfig,
            }));
        } else if (
            collectionConfig["policy-info"]["collection-name"] ===
            SGK_WEAPONS_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] ===
            PACT_RATS_PROJECT_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] ===
            KDA_PUNKS_PROJECT_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] ===
            BOXING_BADGER_PROJECT_NAME_KEY ||
            collectionConfig["policy-info"]["collection-name"] ===
            KISHU_KEN_PROJECT_NAME_KEY
        ) {
            if (PLATFORM === ATRIUM) {
                // checkMintWindowStatus(collectionConfig["path"], collectionConfig);
                setMarketplaceProjects((marketplaceProjects) => ({
                    ...marketplaceProjects,
                    [collectionConfig["path"]]: collectionConfig,
                }));
            }
        }
    }

    function handleLaunchpadCardClick(collectionConfig) {
        navigate(
            "/mint/" +
            collectionConfig["projectName"] +
            "-" +
            collectionConfig["path"] +
            "/"
        );
    }

    function handleMarketplaceCardClick(collectionConfig) {
        let projectName = collectionConfig["policy-info"]["project-name"];
        let collectionName = collectionConfig["policy-info"]["collection-name"];

        navigate(
            `${collectionConfig["is-collection-group"]
                ? "/group/"
                : "/marketplace/"
            }` +
            projectName +
            "-" +
            collectionName +
            "/"
        );
    }

    function handleUpcomingCardClick(collectionConfig) {
        navigate(
            "/mint/" +
            collectionConfig["projectName"] +
            "-" +
            collectionConfig["path"] +
            "/"
        );
    }

    return (
        <Box bgColor="#0A0A0A" className="latestDesign">
            <ScreenSize>
                <HeroSlider />
                <Collections
                    header={"Live on Marketplace"}
                    cardTypes={"marketplace"}
                    projects={marketplaceProjects}
                    cardClickCallback={handleMarketplaceCardClick}
                />

                <Divider
                    my="44px"
                    border="1px"
                    borderColor="#5E1E78"
                    id="collection"
                />

                <Box mt="5rem" id="mintcollections">
                    <Collections
                        header={"Mints Happening Now"}
                        cardTypes={"live"}
                        projects={liveProjects}
                        cardClickCallback={handleLaunchpadCardClick}
                    />
                </Box>

                {/* <Collections
                    header={"Live Mints"}
                    cardTypes={"live"}
                    projects={liveProjects}
                    cardClickCallback={handleLaunchpadCardClick}
                /> */}

                {/* <Divider my="44px" border="1px" borderColor="#5E1E78" />
                <Minted /> */}
                <Divider my="44px" border="1px" borderColor="#5E1E78" />
                {upcomingProjects?.length > 0 && (
                    <ComingSoon
                        projects={upcomingProjects}
                        cardClickCallback={handleUpcomingCardClick}
                    />
                )}
            </ScreenSize>
        </Box>
    );
};

export default HomePage;

const sgk_weapons_metadata = {
    1: {
        "type": "axe1",
        "name": "Axe of Lightning",
    },
    2: {
        "type": "axe2",
        "name": "Axe of Thunder",
    },
    3: {
        "type": "axe3",
        "name": "Axe of Fire",
    },
    4: {
        "type": "axe4",
        "name": "Axe of Earth",
    },
    5: {
        "type": "axe5",
        "name": "Axe of Ice",
    },
    6: {
        "type": "helmet1",
        "name": "Helmet of Rage",
    },
    7: {
        "type": "helmet2",
        "name": "Helmet of Healing",
    },
    8: {
        "type": "helmet3",
        "name": "Helmet of Dodge",
    },
    9: {
        "type": "helmet4",
        "name": "Helmet of Range",
    },
    10: {
        "type": "helmet5",
        "name": "Helmet of Crit",
    },
    11: {
        "type": "barbarian-outfit1",
        "name": "Armor of Swiftness",
    },
    12: {
        "type": "barbarian-outfit2",
        "name": "Armor of Cool",
    },
    13: {
        "type": "barbarian-outfit3",
        "name": "Armor of Life",
    },
    14: {
        "type": "shield",
        "name": "Shield of Defense",
    },
    15: {
        "type": "cape",
        "name": "Cape of Power",
    },
    16: {
        "type": "wings",
        "name": "Wings of Speed",
    },
    17: {
        "type": "wand1",
        "name": "Wand of Fire",
    },
    18: {
        "type": "wand2",
        "name": "Wand of Poison",
    },
    19: {
        "type": "wand3",
        "name": "Wand of Ice",
    },
    20: {
        "type": "wand4",
        "name": "Wand of Air",
    },
    21: {
        "type": "wand5",
        "name": "Wand of Arcane",
    },
    22: {
        "type": "hat1",
        "name": "Hat of Dodge",
    },
    23: {
        "type": "hat2",
        "name": "Hat of Cool",
    },
    24: {
        "type": "hat3",
        "name": "Hat of Invincibility",
    },
    25: {
        "type": "hat4",
        "name": "Hat of Crit",
    },
    26: {
        "type": "hat5",
        "name": "Hat of Range",
    },
    27: {
        "type": "orb",
        "name": "Orb of Swiftness",
    },
    28: {
        "type": "mage-outfit1",
        "name": "Robe of Defense",
    },
    29: {
        "type": "mage-outfit2",
        "name": "Robe of Speed",
    },
    30: {
        "type": "mage-outfit3",
        "name": "Robe of Life",
    },
    31: {
        "type": "scroll",
        "name": "Scroll of Power",
    },
    32: {
        "type": "spellbook",
        "name": "Book of Healing",
    },
}

const weapons_md_schema = {
    "uri": {
        "scheme": "https",
        "data": "https://sgk-battle-heroes-weapons.nyc3.cdn.digitaloceanspaces.com/"
    },
    "datum": {
        "datum": {
            "name": "",
            "attributes": [
                {
                    "trait_type": "Name",
                    "value": "",
                    "score": 1.0
                },
                { "trait_type": "Level", "value": "common" }
            ],
            "id": "1"
        },
        "uri": { "scheme": "pact:schema", "data": "nft-datum" }
    },
    "rarity_score": 1.0,
    "rarity_level": "COMMON"
}

const weapons_rarity_map = {
    "1": "common",
    "2": "rare",
    "3": "epic",
    "4": "legendary"
}

export {
    sgk_weapons_metadata,
    weapons_rarity_map,
    weapons_md_schema,
}
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import dummyCar from "../assets/images/dummyCar.svg";

const CollectionStack = ({
    collectionConfig,
    collectionName,
    nftImage,
    nftCount,
    cardCallback,
}) => {
    return (
        <Box
            onClick={() => {
                cardCallback(collectionConfig);
            }}
            pos="relative"
            maxW="310px"
            w="100%"
        >
            <Box
                style={{ cursor: "pointer", zIndex: 1000 }}
                className="kadCard latestDesign"
                // bgColor="#160d19"
            >
                <div className="">
                    <Image
                        src={nftImage}
                        alt="nftCard"
                        width={"100%"}
                        height="328px"
                        objectFit="cover"
                        className="cardImage"
                    />
                </div>
                <Box style={{ padding: "16px 16px 16px 21px" }}>
                    <Box>
                        <Flex align="center" justify="space-between" mb="10px">
                            {" "}
                            <Text fontWeight="700" fontSize="17px">
                                {collectionName}
                            </Text>
                            <Text fontWeight="700" fontSize="17px">
                                total owned: {nftCount}
                            </Text>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CollectionStack;

import { useContext, useState } from "react";
import ListedNFTCard from "./ListedNFTCard";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { CartModeContext } from "../Context/CartContext";

const NftCardGroup = ({
    collectionName,
    nftsInCollection,
    onClick,
    currentHeight,
    currentBatch,
    limit = null,
    moveUp,
}) => {
    const { showFilter } = useContext(CartModeContext);

    return (
        <SimpleGrid
            columns={[1, 2, 3, showFilter ? 4 : 5]}
            spacing="1.25rem"
            className=""
        >
            {nftsInCollection && collectionName
                ? nftsInCollection.map((nftData, index) => {
                      if (limit) {
                          if (
                              index >= currentBatch * limit &&
                              index <= currentBatch * limit + limit
                          ) {
                              return (
                                  <ListedNFTCard
                                      key={index}
                                      nftData={nftData}
                                      cardCallback={onClick}
                                      collectionName={collectionName}
                                      currentHeight={currentHeight}
                                      moveUp={moveUp}
                                  />
                              );
                          } else {
                              return null;
                          }
                      } else {
                          return (
                              <ListedNFTCard
                                  key={index}
                                  nftData={nftData}
                                  cardCallback={onClick}
                                  collectionName={collectionName}
                                  currentHeight={currentHeight}
                                  moveUp={moveUp}
                              />
                          );
                      }
                  })
                : null}
        </SimpleGrid>
    );
};

export { NftCardGroup };

import {
    Box,
    Text,
    Flex,
    Image,
    Button,
    SimpleGrid,
    Icon,
    AccordionPanel,
    AccordionIcon,
    Accordion,
    AccordionItem,
    AccordionButton,
} from "@chakra-ui/react";
import React, { memo, useContext, useEffect, useState } from "react";
import { PactContext } from "../pact/PactContextProvider";
import {
    BOXING_BADGER_PROJECT_NAME_KEY,
    BUTTER_TYPE,
    CONNECTED_WALLET_TYPE,
    FEE_PERCENTAGE,
    MARMALADE_TYPE,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
    STACKED_TYPE,
    ZELCORE_WALLET_NAME,
} from "../utils/Constants";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import { PiFilesFill } from "react-icons/pi";
import { checkIfNullOrUndefined, tryLoadLocal, useGetNftDetails } from "../utils/utils";

const NftProductPageHero = memo(
    ({
        nftData,
        getNftState,
        collectionData,
        currentHeight,
        actionButtonCallback,
    }) => {
        const { account } = useContext(PactContext);
        const { onOpenConnectModal } = useContext(IsokoDialogContext);
        const details = useGetNftDetails(nftData);
        const [traits, setTraits] = useState(null);
        const [nftState, setNftState] = useState(null);
        const [feeDetails, setFeeDetails] = useState(null);
        const [actionButtonActive, setActionButtonActive] = useState(true);

        useEffect(() => {
            if (!checkIfNullOrUndefined(nftData)) {
                let nftTraits = getNftTraits();
                let state = getNftState(nftData);
                let feeDetails = getTxFeeFieldValue();

                setNftState(state);
                setTraits(nftTraits);
                setFeeDetails(feeDetails);

                state && nftData && checkIfButtonDisabled();
            }
        }, [nftData]);

        function getColorForRarityLevel(level) {
            let color = "#BC3CF0";

            switch (level) {
                case "COMMON":
                    color = "#00994F";
                    break;
                case "RARE":
                    color = "#1E77FF";
                    break;
                case "EPIC":
                    color = "#BC3CF0";
                    break;
                case "LEGENDARY":
                    color = "#EA780E";
                    break;
                case "ONE OF ONE":
                    color = "#FFD700";
                    break;
                default:
                    break;
            }

            return color;
        }

        function getActionButtonState() {
            let actionButtonState = "list";

            if (
                nftData &&
                nftData["listing-details"] &&
                nftData["listing-details"]["price"]
            ) {
                if (nftData["current-owner"] !== account.account) {
                    actionButtonState = "purchase";
                }
            } else {
                if (nftData["current-owner"] !== account.account) {
                    actionButtonState = "none";
                }
            }

            return actionButtonState;
        }

        function executeNftProductAction() {
            let action = getActionButtonState();
            actionButtonCallback(action, nftData["nft-id"]);
        }

        function getCallToActionButtonText() {
            let ctaText = "This NFT is not for sale";

            if (tryLoadLocal(CONNECTED_WALLET_TYPE) === ZELCORE_WALLET_NAME && nftData["standard"] !== BUTTER_TYPE) {
                ctaText = "Cannot purchase NFTs with Zelcore at this time";
                return ctaText;
            }

            if (nftData && nftData["current-owner"] === account.account) {
                if (nftData["listing-details"]) {
                    if (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE) {
                        if (nftData["listing-details"]["timeout"] <= currentHeight) {
                            ctaText = "NFT listing expired, please relist or withdraw";
                        } else {
                            ctaText = "Manage your NFT";
                        }
                    } else {
                        ctaText = "Manage your NFT";
                    }
                } else {
                    ctaText = "Manage your NFT";
                }
            } else {
                if (nftData["policy-info"]["collection-name"] === SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY) {
                    return "This NFT cannot be purchased at this time";
                }
                if (nftData["listing-details"]) {
                    if (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE) {
                        if (nftData["listing-details"]["timeout"] <= currentHeight) {
                            ctaText = "This NFT cannot be purchased at this time";
                        } else {
                            ctaText = "Purchase NFT";
                        }
                    } else {
                        ctaText = "Purchase NFT";
                    }
                } else {
                    ctaText = "This NFT is not for sale";
                }
            }

            return ctaText;
        }

        function checkIfButtonDisabled() {
            if (
                (tryLoadLocal(CONNECTED_WALLET_TYPE) === ZELCORE_WALLET_NAME && nftData["standard"] !== BUTTER_TYPE) ||
                (nftState === "Expired" && nftData["current-owner"] !== account?.account)
            ) {
                setActionButtonActive(false);
            } else {
                setActionButtonActive(true);
            }
        }

        function getTxFeeFieldValue() {
            let data = {}
            if (checkIfNullOrUndefined(nftData)) {
                return;
            } else {
                if (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE) {
                    data["feePercentage"] = 0;
                    data["txFee"] = 0;
                } else {
                    data["feePercentage"] = FEE_PERCENTAGE * 100;
                    data["txFee"] = nftData["transaction-fee"];
                }
            }

            if (!checkIfNullOrUndefined(nftData["royalty-fee"])) {
                data["royalties"] = nftData["royalty-fee"].toFixed(3);
            } else {
                data["royalties"] = "N/A";
            }

            return data;
        }

        function getNftTraits() {
            let traits = [];
            if (!nftData) {
                return traits;
            }

            collectionData["collection_traits"].forEach((traitName) => {
                if (!checkIfNullOrUndefined(nftData["formatted_attributes"][traitName])) {
                    traits.push({
                        name: nftData["formatted_attributes"][traitName]["name"],
                        frequency: nftData["formatted_attributes"][traitName]["frequency"]
                    })
                }
            });

            return traits;
        }

        return (
            <Box mt="5px">
                <Flex
                    flexDir={["column-reverse", null, "row-reverse"]}
                    // align="center"
                    // bgColor="#160D1A"
                    // border="1px"
                    // borderColor="#5E1E78"
                    // borderRadius="8px"
                    gap="2.5rem"
                    py="48px"
                // px="20px"
                >
                    <Box w={["100%", null, "100%"]}>
                        <Box
                            bgColor="#160D1A"
                            border="1px"
                            borderColor="#5E1E78"
                            borderRadius="8px"
                            p="1.25rem"
                        >
                            <Flex
                                align="center"
                                justify="space-between"
                                w="100%"
                            >
                                <Text fontSize="36px" fontWeight="700">
                                    {" "}
                                    {nftData && nftData["name"]}
                                </Text>
                                <Text as="strong">
                                    RARITY RANK:{" "}
                                    <Text
                                        as="strong"
                                        color={() =>
                                            getColorForRarityLevel(
                                                nftData &&
                                                nftData["rarity_level"]
                                            )
                                        }
                                    >
                                        {nftData && nftData["rarity_level"]}
                                    </Text>{" "}
                                </Text>
                            </Flex>

                            <Box py="24px">
                                {nftData && feeDetails && (
                                    <>
                                        <ProductPageHeroField label={"CHAIN ID"}>
                                            {nftData["policy-info"]["chain-id"]}
                                        </ProductPageHeroField>
                                        <ProductPageHeroField label={"PRICE"}>
                                            {nftData["listing-details"] &&
                                                (
                                                    nftData["listing-details"]["price"] -
                                                    nftData["royalty-fee"]
                                                ).toFixed(3)
                                            }
                                        </ProductPageHeroField>
                                        <ProductPageHeroField label={"FEES"}>
                                            ({feeDetails["feePercentage"]} %) {feeDetails["txFee"]} KDA
                                        </ProductPageHeroField>
                                        <ProductPageHeroField label={"ROYALTIES"}>
                                            {feeDetails["royalties"]} KDA
                                        </ProductPageHeroField>
                                    </>
                                )}
                            </Box>

                            <Box p="8px">
                                <Flex
                                    py="4px"
                                    justify="space-between"
                                    align="center"
                                >
                                    <Text>TOTAL </Text>
                                    <Text fontSize="28px" fontWeight="700">
                                        {nftState && nftState}
                                    </Text>
                                </Flex>
                            </Box>
                            <Box mt="30px">
                                {
                                    /*TODO: CHECK OWNER HERERERERE*/
                                    !account ? (
                                        <Button
                                            _hover={{}}
                                            _active={{}}
                                            focus={{}}
                                            bgColor="#BC3CF0"
                                            rounded="full"
                                            color="white"
                                            w="100%"
                                            onClick={() => {
                                                onOpenConnectModal();
                                            }}
                                        >
                                            Connect Wallet to Continue
                                        </Button>
                                    ) : (
                                        <Button
                                            _hover={{}}
                                            _active={{}}
                                            focus={{}}
                                            bgColor="#BC3CF0"
                                            rounded="full"
                                            color="white"
                                            w="100%"
                                            isDisabled={!actionButtonActive}
                                            onClick={() =>
                                                executeNftProductAction()
                                            }
                                        >
                                            {nftData &&
                                                getCallToActionButtonText()}
                                        </Button>
                                    )
                                }
                            </Box>
                        </Box>

                        <Box
                            mt="1.25rem"
                            bgColor="#160D1A"
                            border="1px"
                            borderColor="#5E1E78"
                            borderRadius="8px"
                        >
                            <Accordion defaultIndex={[0]} allowMultiple>
                                {/* trwo  */}
                                <AccordionItem borderTop="0px">
                                    <h2>
                                        <AccordionButton
                                            p="10px"
                                            px="16px"
                                            _hover={{ bgColor: "button" }}
                                            className="button"
                                        >
                                            <Text
                                                as="span"
                                                fontSize="20px"
                                                fontWeight="700"
                                                flex="1"
                                                textAlign="left"
                                            >
                                                Attributes
                                            </Text>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        pb={4}
                                        fontSize="14px"
                                        pt="0"
                                        mt="0"
                                    >
                                        {true ? (
                                            <SimpleGrid
                                                columns={[2, 3]}
                                                spacing="20px"
                                                mt="20px"
                                            >
                                                {traits?.map(
                                                    (traitObj, index) => {
                                                        return (
                                                            <Box
                                                                key={index}
                                                                bgColor="rgba(189, 61, 240, 0.3)"
                                                                rounded="8px"
                                                                border="1px"
                                                                borderColor="#5E1E78"
                                                                p="8px"
                                                            >
                                                                <Text fontWeight="900">
                                                                    {
                                                                        traitObj["name"]
                                                                    }
                                                                </Text>
                                                                <Text
                                                                    py="8px"
                                                                    fontWeight="500"
                                                                >
                                                                    {traitObj["name"]}
                                                                </Text>
                                                                <Text
                                                                    fontSize="14px"
                                                                    fontWeight="400"
                                                                >
                                                                    {traitObj["frequency"]}
                                                                    % have
                                                                    this
                                                                    trait
                                                                </Text>
                                                            </Box>
                                                        );
                                                    }
                                                )}
                                            </SimpleGrid>
                                        ) : (
                                            <>
                                                <Box mt="1.25rem" minH="13rem">
                                                    <Text
                                                        fontSize={"1.45rem"}
                                                        fontWeight={"700"}
                                                        textAlign={"center"}
                                                    >
                                                        This collection does not
                                                        have attributes
                                                    </Text>
                                                    <Flex
                                                        align={"center"}
                                                        justify={"center"}
                                                    >
                                                        <Icon
                                                            as={PiFilesFill}
                                                            boxSize={"10rem"}
                                                        />
                                                    </Flex>
                                                </Box>
                                            </>
                                        )}
                                    </AccordionPanel>
                                </AccordionItem>

                                {/* // three */}
                                <AccordionItem
                                    borderBottom={"0px"}
                                    borderTopColor={"#5E1E78"}
                                >
                                    <h2>
                                        <AccordionButton
                                            p="10px"
                                            px="16px"
                                            className="button"
                                        >
                                            <Text
                                                as="span"
                                                fontSize="20px"
                                                fontWeight="700"
                                                flex="1"
                                                textAlign="left"
                                            >
                                                Details
                                            </Text>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        pb={4}
                                        fontSize="14px"
                                        pt="0"
                                        mt="0"
                                    >
                                        <Box>
                                            {details?.map((item, index) => {
                                                return (
                                                    <Flex
                                                        key={index}
                                                        align="center"
                                                        justify="space-between"
                                                        mb="8px"
                                                        gap="20px"
                                                    >
                                                        <Text fontSize="12px">
                                                            {item.itemName}
                                                        </Text>
                                                        <Text
                                                            fontSize="16px"
                                                            fontWeight="700"
                                                        >
                                                            {item.value}
                                                        </Text>
                                                    </Flex>
                                                );
                                            })}
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Box>
                    <Flex w={["100%", null, "100%"]}>
                        <Box>
                            <Image
                                src={nftData && nftData["nft-uri"]}
                                alt="Hero"
                                w="100%"
                                objectFit="cover"
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        );
    }
);

export default NftProductPageHero;

const ProductPageHeroField = ({ label, children }) => {
    return (
        <Flex py="4px" justify="space-between" align="center" px="8px">
            <Text>{label}</Text>
            <Text>{children}</Text>
        </Flex>
    );
}

import {
    Box,
    Flex,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Text,
    Icon,
    Radio,
    RadioGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FilterToggle from "./FilterToggle";
import { BsFillInfoCircleFill } from "react-icons/bs";

const SweepTheFloor = () => {
    const [isDisabled, setIsDisabled] = useState(false);
    // const handleChecked = () => {
    //     setIsDisabled(false);
    // };

    return (
        <Box py="16px" px="32px" borderTop="1px" borderColor="#424242">
            <Flex
                align="center"
                justify="space-between"
                gap="30px"
                flexDir={["column", null, "column", "row"]}
            >
                <Flex
                    align="center"
                    gap="30px"
                    w="100%"
                    flexDir={["column", null, "row"]}
                >
                    <FilterToggle
                        isChecked={true}
                        label={"Sweep the floor"}
                        toggleState={isDisabled}
                        toggleCallback={setIsDisabled}
                        gap="1rem"
                    />
                    {/* SLider Section  */}
                    <Flex w="100%" maxW="330px" align="center" gap="12px">
                        <Box w="100%" maxW="136px">
                            <Slider
                                aria-label="slider-ex-2"
                                defaultValue={30}
                                isDisabled={isDisabled}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack bg="#C685E0" />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </Box>

                        <Box w="100%" maxW="126px">
                            <NumberInput
                                // size="md"
                                maxW={1000}
                                defaultValue={6}
                                min={0}
                                fontSize="14px"
                                _focusVisible={{}}
                                _focus={{}}
                                isDisabled={isDisabled}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Box>

                        <Box>
                            <Flex
                                align="center"
                                gap="4px"
                                cursor={isDisabled ? "not-allowed" : "default"}
                                className={`${isDisabled ? "dimColor" : ""}`}
                            >
                                <Text fontSize="16px">Max </Text>

                                <Icon
                                    as={BsFillInfoCircleFill}
                                    boxSize="24px"
                                    color={isDisabled ? "#D9D9D9" : "white"}
                                />
                            </Flex>
                        </Box>
                    </Flex>
                    {/* Radio section */}
                    <RadioGroup defaultValue="nft" isDisabled={isDisabled}>
                        <Flex align="center" gap="16px">
                            <Radio
                                size="md"
                                name="1"
                                value="nft"
                                colorScheme="pink"
                                _focusVisible={{}}
                                defaultChecked
                            >
                                NFT count
                            </Radio>

                            <Radio
                                size="md"
                                name="1"
                                value="kda"
                                colorScheme="pink"
                                _focusVisible={{}}
                            >
                                KDA
                            </Radio>
                        </Flex>
                    </RadioGroup>
                </Flex>
                <Box
                    flexShrink={0}
                    w="fit-content"
                    rounded="8px"
                    fontSize="14px"
                    // as="button"
                    bgColor={isDisabled ? "#9E9E9E" : "#C685E0"}
                    mr="-1px"
                    fontWeight="700"
                >
                    <Text py="6px" px="16px" color="black" lineHeight="20px">
                        {isDisabled
                            ? "Activate sweep the floor to use"
                            : "Sweep 6 NFTs for 860.7 KAD"}
                    </Text>
                </Box>
            </Flex>
        </Box>
    );
};

export default SweepTheFloor;

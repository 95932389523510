import { useDisclosure } from "@chakra-ui/react";
import { createContext, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage";
import MoveFundsModal from "../Mint/MoveFundsModal";
import { FailedQuickBuyModal } from "../DialogueModals/StatusModals";
import { wait } from "../utils/utils";
import { EZBuyProgressBar, EZ_BUY_NEUTRAL_STEP } from "./EZBuyProgressBar";

const CrossChainContext = createContext();

const SHOW_EZ_BUY_PROGRESS_BAR = "SHOW_EZ_BUY_PROGRESS_BAR";
const EZ_BUY_TRANSACTION_STEP_DATA = "EZ_BAR_TRANSACTION_STEP_DATA";

const CrossChainContextProvider = ({ children }) => {
    const [chainsWithFunds, setChainsWithFunds] = useState();
    const [quickSignCmdMap, setQuickSignCmdMap] = useState({});
    const [quickBuyErrorMessage, setQuickBuyErrorMessage] = useState();
    const [quickBuyTransferDetails, setQuickBuyTransferDetails] = useState();
    const [ezBuyHeader, setEzBuyHeader] = useState("EZ-Buy");
    const [quickBuyTargetChainId, setQuickBuyTargetChainId] = useState(null);
    const [quickBuyBusinessTransactionsList, setQuickBuyBusinessTransactionsList] = useState(null);
    const [showEZBarProgress, setShowEzBarProgress] = useLocalStorage(SHOW_EZ_BUY_PROGRESS_BAR, false);
    const { isOpen: isOpenQuickBuyModal, onOpen: onOpenQuickBuyModal, onClose: onCloseQuickBuyModal } = useDisclosure();
    const { isOpen: isOpenFailedQuickBuyModal, onOpen: onOpenFailedQuickBuyModal, onClose: onCloseFailedQuickBuyModal } = useDisclosure();
    const [transactionSteps, setTransactionSteps] = useLocalStorage(EZ_BUY_TRANSACTION_STEP_DATA,
        [
            { label: "Building transactions", value: "1", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Signing transactions", value: "2", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Transferring funds", value: "3", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Continuing transfer", value: "4", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Executing Business Transaction", value: "5", status: EZ_BUY_NEUTRAL_STEP },
        ]
    );

    async function resetEzBuyTransactionSteps() {
        await wait(3000);

        let defaultStepData = [
            { label: "Building transactions", value: "1", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Signing transactions", value: "2", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Transferring funds", value: "3", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Continuing transfer", value: "4", status: EZ_BUY_NEUTRAL_STEP },
            { label: "Executing Business Transaction", value: "5", status: EZ_BUY_NEUTRAL_STEP },
        ];

        setTransactionSteps(defaultStepData);
        setShowEzBarProgress(false);
    }

    function changeTransactionStepData(stepNumber, attribute, value) {
        let txStepsCopy = transactionSteps.map((step, i) => {
            if (i === stepNumber) {
                step[attribute] = value;
            }
            return step;
        });

        setTransactionSteps(txStepsCopy);
    }

    return (
        <CrossChainContext.Provider
            value={{
                transactionSteps,
                chainsWithFunds,
                quickSignCmdMap,
                quickBuyErrorMessage,
                quickBuyTransferDetails,
                quickBuyTargetChainId,
                showEZBarProgress,
                quickBuyBusinessTransactionsList,
                setChainsWithFunds,
                setQuickSignCmdMap,
                setQuickBuyErrorMessage,
                setQuickBuyTransferDetails,
                setQuickBuyTargetChainId,
                setTransactionSteps,
                setQuickBuyBusinessTransactionsList,
                changeTransactionStepData,
                setShowEzBarProgress,
                resetEzBuyTransactionSteps,
                isOpenQuickBuyModal,
                onOpenQuickBuyModal,
                onCloseQuickBuyModal,
                setEzBuyHeader,
                isOpenFailedQuickBuyModal,
                onOpenFailedQuickBuyModal,
                onCloseFailedQuickBuyModal
            }}
        >
            <MoveFundsModal
                isOpen={isOpenQuickBuyModal}
                onOpen={onOpenQuickBuyModal}
                onClose={onCloseQuickBuyModal}
                transferBreakdown={quickBuyTransferDetails}
                ezBuyHeader={ezBuyHeader}
                targetChainId={quickBuyTargetChainId}
                businessTxList={quickBuyBusinessTransactionsList}
                setShowEzBarProgress={setShowEzBarProgress}
            />

            <FailedQuickBuyModal
                isOpen={isOpenFailedQuickBuyModal}
                onOpen={onOpenFailedQuickBuyModal}
                onClose={onCloseFailedQuickBuyModal}
                errorMessage={quickBuyErrorMessage}
            />

            {children}

            {
                showEZBarProgress && <EZBuyProgressBar transactionStepData={transactionSteps} />
            }
        </CrossChainContext.Provider>
    )
}

export {
    CrossChainContextProvider,
    CrossChainContext
}
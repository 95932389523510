import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import DummyImg from "../../assets/images/dummyImg.png";

const DetailsSection = () => {
    const DetailsData = [
        { id: 1, title: "MINT ADDRESS", value: "UhgKj...P9q" },
        { id: 2, title: "TOKEN ADDRESS", value: "d4BsF...hy3" },
        { id: 3, title: "OWNER:", value: "ILdfe...54f" },
    ];
    return (
        <Box>
            <Box>
                <Image
                    src={DummyImg}
                    alt="image alt"
                    boxSize={"436px"}
                    objectFit={"cover"}
                    rounded="8px"
                />

                <Box mt="8px" px="16px">
                    <Text py="8px" fontSize="20px" fontWeight="500">
                        Details
                    </Text>
                    <Box>
                        <Flex>
                            <Text
                                fontSize="12px"
                                fontWeight="500"
                                w="100%"
                                textAlign={"left"}
                            >
                                Chain ID
                            </Text>
                            <Text
                                fontSize="14px"
                                fontWeight="700"
                                w="100%"
                                textAlign={"right"}
                            >
                                Chain 8
                            </Text>
                        </Flex>

                        {/* Details section  */}

                        <Stack spacing={"8px"} mt="8px">
                            {DetailsData?.map((item) => {
                                return (
                                    <Flex key={item?.id}>
                                        <Text
                                            fontSize="12px"
                                            fontWeight="500"
                                            w="100%"
                                            textAlign={"left"}
                                            textTransform={"uppercase"}
                                        >
                                            {item?.title}
                                        </Text>
                                        <Text
                                            fontSize="14px"
                                            fontWeight="700"
                                            w="100%"
                                            textAlign={"right"}
                                        >
                                            {item?.value}
                                        </Text>
                                    </Flex>
                                );
                            })}
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DetailsSection;

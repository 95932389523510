// 1. Import the extendTheme function

import { extendTheme, defineStyleConfig, defineStyle } from "@chakra-ui/react";
import { switchTheme } from "./SwitchTheme";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
    light_1: "#e4e4e4",
    light_2: "#b197ef",
    light_3: "#f79eae",
};

export const theme = extendTheme({
    colors,
    components: {
        Switch: switchTheme,
    },
});

/**
 * This hook is used to update local forge data from firestore
 * @param {object} query - The query to subscribe to
 * @param {function} postProcessFn - A function to run on the data before storing it in localforge
 */

import { useEffect, useState } from "react";
import { useQuery } from "./FirestoreService";
import localforage from "localforage";

const useLocalFirestore = (localKey, query, postProcessFn, storageType = null, dataType = null) => {
    const queryResult = useQuery(query);
    const [data, setData] = useState(() => {
        let localData = localStorage.getItem(localKey);
        let processedData = null;
        if (localData !== null && localData !== undefined && localData !== "undefined") {
            processedData = JSON.parse(localData);
        }
        return processedData;
    });

    useEffect(() => {
        async function updateLocalStore() {
            if (queryResult) {
                let queryData = null;

                if (dataType === "doc") {
                    queryData = await queryResult.data();
                } else {
                    queryData = await queryResult.docs;
                }

                if (postProcessFn) {
                    postProcessFn(queryData).then((data) => {
                        setData(data);
                    });
                } else {
                    setData(queryData);
                }
            }
        }
        updateLocalStore();
    }, [queryResult]);

    useEffect(() => {
        async function storeInLocalForage() {
            await localforage.setItem(localKey, data);
        }
        async function getFromLocalForage() {
            let currentValue = await localforage.getItem(localKey);
            setData(currentValue);
        }

        if (storageType === "localforage") {
            data ? storeInLocalForage() : getFromLocalForage();
        } else {
            let parsedData = data ? JSON.stringify(data) : null;
            localStorage.setItem(localKey, parsedData);
        }

    }, [data, localKey]);

    return data;
}

export default useLocalFirestore;

import { Box, Icon, Flex, Text, Stack } from "@chakra-ui/react";
import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
const InProgressTask = () => {
    return (
        <Stack spacing={"8px"} maxH="">
            {[1, 2, 3, 4, 5].map((item) => {
                return (
                    <Flex
                        key={item}
                        borderLeft={"4px"}
                        borderColor="#AEC9F2"
                        rounded="8px"
                        py="12px"
                        pl="24px"
                        pr="12px"
                        align="center"
                        minH="110px"
                        gap="16px"
                        bg="#333333"
                    >
                        <Box className="infoIcon">
                            <Icon as={BsFillInfoCircleFill} boxSize="20px" />
                        </Box>

                        <Box>
                            <Box minH="58px">
                                <Text fontSize={"14px"} fontWeight={"700"}>
                                    Purchasing Kadcar #234 for 3.2KDA
                                </Text>
                                <Text fontSize={"12px"} mt="2px">
                                    this may take a few minutes
                                </Text>
                            </Box>
                            <Flex align="center" justify={"right"}>
                                <Text
                                    as="button"
                                    w="fit-content"
                                    fontSize="12px"
                                    fontWeight={"900"}
                                    color="#AEC9F2"
                                >
                                    View tx in explorer
                                </Text>
                            </Flex>
                        </Box>
                    </Flex>
                );
            })}
        </Stack>
    );
};

export default InProgressTask;

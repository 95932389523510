import React, { createContext, useState } from "react";

export const CartModeContext = createContext("");

const CartContextProvider = ({ children }) => {
    //this state handles the summary box
    const [isCartActive, setIsCartActive] = useState(false);
    //THIS handle the fliter on marketplace page
    const [showFilter, setShowFilter] = useState(true);

    // this state handle the cart box pop up
    const [showCart, setShowCart] = useState(false);

    // ATC Mode for card
    const [isAtcMode, setIsAtcMode] = useState(true);

    // this function toggle the SUMMARY BOX VISIBILITY
    const ToggleCartStatus = () => {
        setIsCartActive(!isCartActive);
        setShowCart(false);
    };

    const ToggleFilter = () => {
        setShowFilter(!showFilter);
    };
    // this function Toggle the cart Box pop up
    const ToggleShowCart = () => {
        setShowCart(!showCart);
    };

    const sharedValue = {
        isCartActive,
        ToggleCartStatus,
        showCart,
        ToggleShowCart,
        showFilter,
        ToggleFilter

    };

    return (
        <CartModeContext.Provider value={sharedValue}>
            {children}
        </CartModeContext.Provider>
    );
};

export default CartContextProvider;

import React, { useEffect } from "react";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { app, db, useQuery } from "../Firestore/FirestoreService";
import { shortenAccountKey } from "../utils/utils";

/**
 * This component is used to display the activity of a NFT
 * The activity is the list of transactions that have been made on a NFT
 * a single transaction is composed of the following fields:
 * Event: the type of transaction (mint, transfer, burn, ...)
 * From: the address of the sender
 * To: the address of the receiver
 * Price: the price of the transaction
 * Date: the date of the transaction
 * @param {string} nftId in the form of [collection name]:[nft number]
 * @returns {JSX.Element}
 */
const Activity = ({ queryField, queryValue }) => {
    // TODO: polish the UI
    // TODO: upload project_configs to firestore
    // TODO: change references to project_configs to use firestore
    const q = query(
        collection(db, `transactions`),
        where(queryField, "==", queryValue)
    );
    const snapshot = useQuery(q);


    useEffect(() => {
        async function processSnapshot() {
            console.log(queryValue)
            snapshot.forEach((doc) => {
                console.log(doc.data())
            })
        }
        snapshot && processSnapshot();
    }, [snapshot])

    if (!snapshot) return <p>Loading...</p>;

    return (
        <Box mt="45px" mb="45px">
            <Text fontSize="36px" fontWeight="700">
                Activity
            </Text>
            <Box
                mt="20px"
                border="1px"
                borderColor="#5E1E78"
                rounded={"10px"}
                maxHeight={"300px"}
                overflowY={"auto"}
            >
                <Table>
                    <Thead px="16px">
                        <Tr>
                            <ActivityTableHeader label={"Event"} />
                            <ActivityTableHeader label={"Platform"} />
                            <ActivityTableHeader label={"Price"} />
                            <ActivityTableHeader label={"From"} />
                            <ActivityTableHeader label={"To"} />
                            <ActivityTableHeader label={"Date"} />
                        </Tr>
                    </Thead>
                    <Tbody>
                        {snapshot?.docs?.map((transaction) => {
                            const { eventId, platform, from, to, price, time } = transaction.data();
                            // Timestamp to Date
                            return (
                                <Tr
                                    key={eventId + platform + from + to + time}
                                    _odd={{ bgColor: "#160D1A" }}
                                >
                                    <ActivityTableCell value={eventId} />
                                    <ActivityTableCell value={platform} />
                                    <ActivityTableCell value={price} />
                                    <ActivityTableCell value={shortenAccountKey(from)} />
                                    <ActivityTableCell value={shortenAccountKey(to)} />
                                    <ActivityTableCell value={time} />
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default Activity;

const ActivityTableHeader = ({ label }) => {
    return (
        < Th
            pl="16px"
            py="14px"
            fontSize="14px"
            fontWeight="500"
            color="white"
            borderBottomColor={"#5E1E78"}
        >
            {label}
        </Th >
    )
}

const ActivityTableCell = ({ value }) => {
    return (
        <Td
            px="16px"
            py="14px"
            border={"none"}
            fontSize={"14px"}
        >
            {value}
        </Td>
    )
}
const kawaii_k9s_metadata = {
    "728": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #728",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Moe Niden",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "White House",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Suit with Blue Tie",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Puddin' Cup",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Come on man!",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Not all there",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Flag Pin",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White Hair",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "728",
                "rarity_score": 11000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/728.png",
            "scheme": "https"
        }
    },
    "682": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #682",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "682",
                "rarity_score": 577.4298059505198,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/682.png",
            "scheme": "https"
        }
    },
    "378": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #378",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Ronald Gump",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "White House",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Suit With Red Tie",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Orange",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "You're fired!",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Blue", "score": 750.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "American Flag Pin",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Yuge",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "378",
                "rarity_score": 10250.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/378.png",
            "scheme": "https"
        }
    },
    "395": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #395",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "395",
                "rarity_score": 373.15734400528703,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/395.png",
            "scheme": "https"
        }
    },
    "1208": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1208",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1208",
                "rarity_score": 388.5621439564817,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1208.png",
            "scheme": "https"
        }
    },
    "815": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #815",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "815",
                "rarity_score": 551.320849729874,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/815.png",
            "scheme": "https"
        }
    },
    "114": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #114",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "114",
                "rarity_score": 553.1446535031445,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/114.png",
            "scheme": "https"
        }
    },
    "1119": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1119",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1119",
                "rarity_score": 491.9815478639008,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1119.png",
            "scheme": "https"
        }
    },
    "544": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #544",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "544",
                "rarity_score": 502.4274006762796,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/544.png",
            "scheme": "https"
        }
    },
    "1064": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1064",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1064",
                "rarity_score": 360.6861888111888,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1064.png",
            "scheme": "https"
        }
    },
    "1434": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1434",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1434",
                "rarity_score": 499.1071428571429,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1434.png",
            "scheme": "https"
        }
    },
    "399": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #399",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "399",
                "rarity_score": 477.63044782087167,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/399.png",
            "scheme": "https"
        }
    },
    "952": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #952",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "952",
                "rarity_score": 409.4812291533603,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/952.png",
            "scheme": "https"
        }
    },
    "283": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #283",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "283",
                "rarity_score": 636.9755244755245,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/283.png",
            "scheme": "https"
        }
    },
    "790": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #790",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 4",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "790",
                "rarity_score": 596.8346870520784,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/790.png",
            "scheme": "https"
        }
    },
    "511": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #511",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "511",
                "rarity_score": 473.49845276674546,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/511.png",
            "scheme": "https"
        }
    },
    "1176": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1176",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 1",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1176",
                "rarity_score": 728.2214663078815,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1176.png",
            "scheme": "https"
        }
    },
    "141": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #141",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "141",
                "rarity_score": 515.4347993926448,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/141.png",
            "scheme": "https"
        }
    },
    "840": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #840",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "840",
                "rarity_score": 461.0455907244674,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/840.png",
            "scheme": "https"
        }
    },
    "903": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #903",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Sad Eyes 01",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "903",
                "rarity_score": 641.4444444444445,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/903.png",
            "scheme": "https"
        }
    },
    "1197": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1197",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1197",
                "rarity_score": 442.53142762065204,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1197.png",
            "scheme": "https"
        }
    },
    "456": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #456",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "456",
                "rarity_score": 376.8534812423842,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/456.png",
            "scheme": "https"
        }
    },
    "1461": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1461",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1461",
                "rarity_score": 336.01656314699795,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1461.png",
            "scheme": "https"
        }
    },
    "1031": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1031",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 01",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1031",
                "rarity_score": 511.7269001490313,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1031.png",
            "scheme": "https"
        }
    },
    "291": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #291",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "291",
                "rarity_score": 524.2086466165414,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/291.png",
            "scheme": "https"
        }
    },
    "767": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #767",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "767",
                "rarity_score": 522.3672127914467,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/767.png",
            "scheme": "https"
        }
    },
    "337": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #337",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 024",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "337",
                "rarity_score": 870.483808573033,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/337.png",
            "scheme": "https"
        }
    },
    "786": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #786",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "786",
                "rarity_score": 599.9854312354313,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/786.png",
            "scheme": "https"
        }
    },
    "1247": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1247",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1247",
                "rarity_score": 458.04248023177377,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1247.png",
            "scheme": "https"
        }
    },
    "856": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #856",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "856",
                "rarity_score": 299.89121280179677,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/856.png",
            "scheme": "https"
        }
    },
    "153": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #153",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 2",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "153",
                "rarity_score": 602.3989694563463,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/153.png",
            "scheme": "https"
        }
    },
    "1160": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1160",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1160",
                "rarity_score": 403.06941445651125,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1160.png",
            "scheme": "https"
        }
    },
    "503": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #503",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "503",
                "rarity_score": 321.96080987139385,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/503.png",
            "scheme": "https"
        }
    },
    "1023": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1023",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 003",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 02",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1023",
                "rarity_score": 1719.4204325783273,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1023.png",
            "scheme": "https"
        }
    },
    "1473": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1473",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1473",
                "rarity_score": 456.2112085954768,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1473.png",
            "scheme": "https"
        }
    },
    "440": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #440",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "440",
                "rarity_score": 462.89515806322527,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/440.png",
            "scheme": "https"
        }
    },
    "911": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #911",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "911",
                "rarity_score": 482.33895187110545,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/911.png",
            "scheme": "https"
        }
    },
    "694": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #694",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "694",
                "rarity_score": 457.6517994200921,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/694.png",
            "scheme": "https"
        }
    },
    "379": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #379",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 10",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "379",
                "rarity_score": 597.7612823865685,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/379.png",
            "scheme": "https"
        }
    },
    "552": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #552",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 6",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "552",
                "rarity_score": 819.4379391100703,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/552.png",
            "scheme": "https"
        }
    },
    "1135": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1135",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1135",
                "rarity_score": 505.5889348746491,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1135.png",
            "scheme": "https"
        }
    },
    "102": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #102",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 020",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "102",
                "rarity_score": 752.9851327534254,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/102.png",
            "scheme": "https"
        }
    },
    "799": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #799",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "799",
                "rarity_score": 521.5611858086868,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/799.png",
            "scheme": "https"
        }
    },
    "944": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #944",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Kawaii Brown & Green Eyes",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Green Cn Hood",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "944",
                "rarity_score": 843.8948412698412,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/944.png",
            "scheme": "https"
        }
    },
    "415": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #415",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "415",
                "rarity_score": 401.2892904197252,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/415.png",
            "scheme": "https"
        }
    },
    "1422": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1422",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1422",
                "rarity_score": 440.1110583280165,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1422.png",
            "scheme": "https"
        }
    },
    "1072": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1072",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1072",
                "rarity_score": 515.5534230262491,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1072.png",
            "scheme": "https"
        }
    },
    "1263": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1263",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1263",
                "rarity_score": 510.1842669525596,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1263.png",
            "scheme": "https"
        }
    },
    "250": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #250",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "250",
                "rarity_score": 361.93930126795243,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/250.png",
            "scheme": "https"
        }
    },
    "600": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #600",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 14",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "600",
                "rarity_score": 596.0080971659919,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/600.png",
            "scheme": "https"
        }
    },
    "313": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #313",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 023",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "313",
                "rarity_score": 738.1709070770968,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/313.png",
            "scheme": "https"
        }
    },
    "1320": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1320",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1320",
                "rarity_score": 343.4098065677013,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1320.png",
            "scheme": "https"
        }
    },
    "743": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #743",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "743",
                "rarity_score": 462.50373217586326,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/743.png",
            "scheme": "https"
        }
    },
    "893": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #893",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "893",
                "rarity_score": 439.6970117478429,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/893.png",
            "scheme": "https"
        }
    },
    "196": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #196",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "196",
                "rarity_score": 440.7266714507247,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/196.png",
            "scheme": "https"
        }
    },
    "1457": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1457",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1457",
                "rarity_score": 536.6212121212121,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1457.png",
            "scheme": "https"
        }
    },
    "1007": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1007",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1007",
                "rarity_score": 557.7095037398037,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1007.png",
            "scheme": "https"
        }
    },
    "527": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #527",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "527",
                "rarity_score": 568.2782844896997,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/527.png",
            "scheme": "https"
        }
    },
    "481": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #481",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "481",
                "rarity_score": 560.0057718149997,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/481.png",
            "scheme": "https"
        }
    },
    "177": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #177",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "177",
                "rarity_score": 466.13272311212813,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/177.png",
            "scheme": "https"
        }
    },
    "19": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #19",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "19",
                "rarity_score": 380.42411780765815,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/19.png",
            "scheme": "https"
        }
    },
    "655": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #655",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "655",
                "rarity_score": 489.86528713600103,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/655.png",
            "scheme": "https"
        }
    },
    "1398": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1398",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1398",
                "rarity_score": 485.0028450920695,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1398.png",
            "scheme": "https"
        }
    },
    "205": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #205",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "205",
                "rarity_score": 553.9416056864569,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/205.png",
            "scheme": "https"
        }
    },
    "1232": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1232",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Chicken Nuggs",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1232",
                "rarity_score": 664.4886363636364,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1232.png",
            "scheme": "https"
        }
    },
    "712": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #712",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "712",
                "rarity_score": 417.60200668896323,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/712.png",
            "scheme": "https"
        }
    },
    "1375": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1375",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1375",
                "rarity_score": 408.5240274599542,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1375.png",
            "scheme": "https"
        }
    },
    "342": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #342",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "342",
                "rarity_score": 437.18660392662736,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/342.png",
            "scheme": "https"
        }
    },
    "589": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #589",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 5",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "589",
                "rarity_score": 761.8520066889632,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/589.png",
            "scheme": "https"
        }
    },
    "97": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #97",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Kawaii Brown & Green Eyes",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "97",
                "rarity_score": 523.7607735721582,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/97.png",
            "scheme": "https"
        }
    },
    "968": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #968",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "968",
                "rarity_score": 424.686004784689,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/968.png",
            "scheme": "https"
        }
    },
    "985": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #985",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "985",
                "rarity_score": 316.6650974484512,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/985.png",
            "scheme": "https"
        }
    },
    "76": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #76",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 12",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "76",
                "rarity_score": 626.9907270828184,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/76.png",
            "scheme": "https"
        }
    },
    "1224": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1224",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 2",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1224",
                "rarity_score": 704.8898232641532,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1224.png",
            "scheme": "https"
        }
    },
    "209": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #209",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "209",
                "rarity_score": 547.6907894736842,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/209.png",
            "scheme": "https"
        }
    },
    "639": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #639",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "639",
                "rarity_score": 574.905303030303,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/639.png",
            "scheme": "https"
        }
    },
    "354": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #354",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "354",
                "rarity_score": 472.9476107516177,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/354.png",
            "scheme": "https"
        }
    },
    "1359": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1359",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 021",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1359",
                "rarity_score": 644.4150020559656,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1359.png",
            "scheme": "https"
        }
    },
    "704": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #704",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "704",
                "rarity_score": 393.45404595404597,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/704.png",
            "scheme": "https"
        }
    },
    "1418": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1418",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1418",
                "rarity_score": 501.34933962498525,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1418.png",
            "scheme": "https"
        }
    },
    "1048": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1048",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple $kda",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1048",
                "rarity_score": 1176.958525345622,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1048.png",
            "scheme": "https"
        }
    },
    "585": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #585",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "585",
                "rarity_score": 440.6771981384134,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/585.png",
            "scheme": "https"
        }
    },
    "568": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #568",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Chicken Nuggs",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "568",
                "rarity_score": 573.6283309468097,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/568.png",
            "scheme": "https"
        }
    },
    "138": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #138",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "138",
                "rarity_score": 371.4756258234519,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/138.png",
            "scheme": "https"
        }
    },
    "60": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #60",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "60",
                "rarity_score": 409.99331103678935,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/60.png",
            "scheme": "https"
        }
    },
    "989": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #989",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "989",
                "rarity_score": 513.6407757471175,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/989.png",
            "scheme": "https"
        }
    },
    "616": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #616",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "616",
                "rarity_score": 407.8012454255042,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/616.png",
            "scheme": "https"
        }
    },
    "246": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #246",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "246",
                "rarity_score": 470.74275362318843,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/246.png",
            "scheme": "https"
        }
    },
    "1271": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1271",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1271",
                "rarity_score": 397.2332015810277,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1271.png",
            "scheme": "https"
        }
    },
    "751": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #751",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "751",
                "rarity_score": 447.0621323803788,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/751.png",
            "scheme": "https"
        }
    },
    "1336": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1336",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1336",
                "rarity_score": 449.72837023306386,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1336.png",
            "scheme": "https"
        }
    },
    "301": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #301",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Suit",
                        "score": 250.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "301",
                "rarity_score": 707.7868155236577,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/301.png",
            "scheme": "https"
        }
    },
    "180": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #180",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 6",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "180",
                "rarity_score": 1184.2827342747112,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/180.png",
            "scheme": "https"
        }
    },
    "927": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #927",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "927",
                "rarity_score": 412.89087373760077,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/927.png",
            "scheme": "https"
        }
    },
    "881": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #881",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 11",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "881",
                "rarity_score": 753.2443422913719,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/881.png",
            "scheme": "https"
        }
    },
    "35": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #35",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 01",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "35",
                "rarity_score": 456.67903902398126,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/35.png",
            "scheme": "https"
        }
    },
    "493": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #493",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "493",
                "rarity_score": 406.0504712678626,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/493.png",
            "scheme": "https"
        }
    },
    "548": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #548",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "548",
                "rarity_score": 455.6520035436827,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/548.png",
            "scheme": "https"
        }
    },
    "1085": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1085",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1085",
                "rarity_score": 576.4945652173913,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1085.png",
            "scheme": "https"
        }
    },
    "118": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #118",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "118",
                "rarity_score": 535.7058060618432,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/118.png",
            "scheme": "https"
        }
    },
    "40": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #40",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "40",
                "rarity_score": 629.7763686274052,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/40.png",
            "scheme": "https"
        }
    },
    "1438": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1438",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1438",
                "rarity_score": 405.951157538114,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1438.png",
            "scheme": "https"
        }
    },
    "1068": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1068",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1068",
                "rarity_score": 393.0293115269823,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1068.png",
            "scheme": "https"
        }
    },
    "374": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #374",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "374",
                "rarity_score": 282.4660633484163,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/374.png",
            "scheme": "https"
        }
    },
    "1339": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1339",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 1",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mushroom Hat",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1339",
                "rarity_score": 934.3546952862248,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1339.png",
            "scheme": "https"
        }
    },
    "724": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #724",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "724",
                "rarity_score": 675.8846447669978,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/724.png",
            "scheme": "https"
        }
    },
    "1204": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1204",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1204",
                "rarity_score": 507.47895902547066,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1204.png",
            "scheme": "https"
        }
    },
    "229": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #229",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "229",
                "rarity_score": 548.1866673472513,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/229.png",
            "scheme": "https"
        }
    },
    "659": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #659",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "659",
                "rarity_score": 508.57467916079304,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/659.png",
            "scheme": "https"
        }
    },
    "15": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #15",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "15",
                "rarity_score": 422.9876332080535,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/15.png",
            "scheme": "https"
        }
    },
    "1480": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1480",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1480",
                "rarity_score": 436.5862573099415,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1480.png",
            "scheme": "https"
        }
    },
    "1193": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1193",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1193",
                "rarity_score": 303.9683856289696,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1193.png",
            "scheme": "https"
        }
    },
    "907": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #907",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "907",
                "rarity_score": 412.6275835738818,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/907.png",
            "scheme": "https"
        }
    },
    "287": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #287",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "287",
                "rarity_score": 505.0762254397402,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/287.png",
            "scheme": "https"
        }
    },
    "771": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #771",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Cn Hood",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "771",
                "rarity_score": 852.1921791658634,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/771.png",
            "scheme": "https"
        }
    },
    "1316": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1316",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1316",
                "rarity_score": 491.27656096763644,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1316.png",
            "scheme": "https"
        }
    },
    "321": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #321",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "321",
                "rarity_score": 401.17624651264236,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/321.png",
            "scheme": "https"
        }
    },
    "636": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #636",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "636",
                "rarity_score": 707.5990164805954,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/636.png",
            "scheme": "https"
        }
    },
    "266": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #266",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "266",
                "rarity_score": 323.76486218699335,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/266.png",
            "scheme": "https"
        }
    },
    "1251": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1251",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1251",
                "rarity_score": 558.5328414275783,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1251.png",
            "scheme": "https"
        }
    },
    "507": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #507",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "507",
                "rarity_score": 569.1300547278809,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/507.png",
            "scheme": "https"
        }
    },
    "1496": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1496",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1496",
                "rarity_score": 614.113263785395,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1496.png",
            "scheme": "https"
        }
    },
    "157": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #157",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 4",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "157",
                "rarity_score": 606.6471571906354,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/157.png",
            "scheme": "https"
        }
    },
    "1181": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1181",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1181",
                "rarity_score": 420.3970495407253,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1181.png",
            "scheme": "https"
        }
    },
    "1477": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1477",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1477",
                "rarity_score": 586.8004992718952,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1477.png",
            "scheme": "https"
        }
    },
    "1027": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1027",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1027",
                "rarity_score": 652.8306957875923,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1027.png",
            "scheme": "https"
        }
    },
    "333": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #333",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Sad Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "333",
                "rarity_score": 828.1855049712193,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/333.png",
            "scheme": "https"
        }
    },
    "1300": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1300",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1300",
                "rarity_score": 308.38024680237794,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1300.png",
            "scheme": "https"
        }
    },
    "763": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #763",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "763",
                "rarity_score": 585.242578767954,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/763.png",
            "scheme": "https"
        }
    },
    "1243": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1243",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1243",
                "rarity_score": 484.10063752276864,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1243.png",
            "scheme": "https"
        }
    },
    "270": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #270",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 02",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "270",
                "rarity_score": 1143.9114528101802,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/270.png",
            "scheme": "https"
        }
    },
    "620": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #620",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "620",
                "rarity_score": 453.3538235185655,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/620.png",
            "scheme": "https"
        }
    },
    "56": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #56",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 15",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "56",
                "rarity_score": 770.813221331514,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/56.png",
            "scheme": "https"
        }
    },
    "1089": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1089",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 5",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "1089",
                "rarity_score": 1845.9959212850054,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1089.png",
            "scheme": "https"
        }
    },
    "948": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #948",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 013",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "948",
                "rarity_score": 702.6997588332993,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/948.png",
            "scheme": "https"
        }
    },
    "732": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #732",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "732",
                "rarity_score": 442.2486504723347,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/732.png",
            "scheme": "https"
        }
    },
    "1355": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1355",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1355",
                "rarity_score": 668.9027587319513,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1355.png",
            "scheme": "https"
        }
    },
    "698": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #698",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "698",
                "rarity_score": 485.2584772207198,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/698.png",
            "scheme": "https"
        }
    },
    "362": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #362",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "362",
                "rarity_score": 507.2498159735002,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/362.png",
            "scheme": "https"
        }
    },
    "675": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #675",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "675",
                "rarity_score": 428.9164941338854,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/675.png",
            "scheme": "https"
        }
    },
    "225": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #225",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "225",
                "rarity_score": 330.13616817964646,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/225.png",
            "scheme": "https"
        }
    },
    "1212": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1212",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1212",
                "rarity_score": 472.73157334656196,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1212.png",
            "scheme": "https"
        }
    },
    "1003": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1003",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1003",
                "rarity_score": 592.0437324395479,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1003.png",
            "scheme": "https"
        }
    },
    "1453": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1453",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey Nighvision",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1453",
                "rarity_score": 811.3528327270915,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1453.png",
            "scheme": "https"
        }
    },
    "460": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #460",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "460",
                "rarity_score": 440.625,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/460.png",
            "scheme": "https"
        }
    },
    "931": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #931",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "931",
                "rarity_score": 337.7198654547883,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/931.png",
            "scheme": "https"
        }
    },
    "897": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #897",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 3",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "897",
                "rarity_score": 734.8626702245124,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/897.png",
            "scheme": "https"
        }
    },
    "876": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #876",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "876",
                "rarity_score": 386.1154410416285,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/876.png",
            "scheme": "https"
        }
    },
    "173": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #173",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "173",
                "rarity_score": 457.68614325002585,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/173.png",
            "scheme": "https"
        }
    },
    "1140": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1140",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1140",
                "rarity_score": 482.1381951381951,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1140.png",
            "scheme": "https"
        }
    },
    "523": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #523",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "523",
                "rarity_score": 472.41513171985434,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/523.png",
            "scheme": "https"
        }
    },
    "1267": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1267",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1267",
                "rarity_score": 398.2105885832594,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1267.png",
            "scheme": "https"
        }
    },
    "1286": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1286",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1286",
                "rarity_score": 311.65277777777777,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1286.png",
            "scheme": "https"
        }
    },
    "747": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #747",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "747",
                "rarity_score": 326.4829017731495,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/747.png",
            "scheme": "https"
        }
    },
    "317": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #317",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "317",
                "rarity_score": 467.02839123051893,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/317.png",
            "scheme": "https"
        }
    },
    "964": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #964",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Top Hat",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "964",
                "rarity_score": 728.054176634431,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/964.png",
            "scheme": "https"
        }
    },
    "93": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #93",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 3",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "93",
                "rarity_score": 1050.745374364874,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/93.png",
            "scheme": "https"
        }
    },
    "435": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #435",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "435",
                "rarity_score": 408.2644697223152,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/435.png",
            "scheme": "https"
        }
    },
    "1402": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1402",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1402",
                "rarity_score": 336.77700462335963,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1402.png",
            "scheme": "https"
        }
    },
    "1052": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1052",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 02",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemoncello",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1052",
                "rarity_score": 1092.8582687804,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1052.png",
            "scheme": "https"
        }
    },
    "572": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #572",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 2",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "572",
                "rarity_score": 999.9429142286285,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/572.png",
            "scheme": "https"
        }
    },
    "1115": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1115",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1115",
                "rarity_score": 474.59686429316247,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1115.png",
            "scheme": "https"
        }
    },
    "122": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #122",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 024",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "122",
                "rarity_score": 1099.8912128017969,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/122.png",
            "scheme": "https"
        }
    },
    "819": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #819",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "819",
                "rarity_score": 407.0866633366633,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/819.png",
            "scheme": "https"
        }
    },
    "1394": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1394",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 3",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1394",
                "rarity_score": 720.7221350078493,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1394.png",
            "scheme": "https"
        }
    },
    "1044": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1044",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1044",
                "rarity_score": 326.03049124788254,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1044.png",
            "scheme": "https"
        }
    },
    "1414": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1414",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 023",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1414",
                "rarity_score": 722.6138051982858,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1414.png",
            "scheme": "https"
        }
    },
    "419": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #419",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 3",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "419",
                "rarity_score": 583.288511149292,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/419.png",
            "scheme": "https"
        }
    },
    "81": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #81",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "81",
                "rarity_score": 402.7276295133438,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/81.png",
            "scheme": "https"
        }
    },
    "5": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #5",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "5",
                "rarity_score": 374.8729531338227,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/5.png",
            "scheme": "https"
        }
    },
    "972": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #972",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "972",
                "rarity_score": 451.30874709838764,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/972.png",
            "scheme": "https"
        }
    },
    "835": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #835",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 6",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "835",
                "rarity_score": 872.7336956521739,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/835.png",
            "scheme": "https"
        }
    },
    "134": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #134",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "134",
                "rarity_score": 666.714582269305,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/134.png",
            "scheme": "https"
        }
    },
    "1099": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1099",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 4",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1099",
                "rarity_score": 1899.503080824591,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1099.png",
            "scheme": "https"
        }
    },
    "564": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #564",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "564",
                "rarity_score": 438.4938831660143,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/564.png",
            "scheme": "https"
        }
    },
    "1382": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1382",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Green Eyes 01",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1382",
                "rarity_score": 1154.9213943950788,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1382.png",
            "scheme": "https"
        }
    },
    "1228": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1228",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1228",
                "rarity_score": 473.44520797846275,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1228.png",
            "scheme": "https"
        }
    },
    "708": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #708",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "708",
                "rarity_score": 541.2054150925119,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/708.png",
            "scheme": "https"
        }
    },
    "358": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #358",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "358",
                "rarity_score": 408.36930517994585,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/358.png",
            "scheme": "https"
        }
    },
    "923": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #923",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "923",
                "rarity_score": 478.2919783193587,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/923.png",
            "scheme": "https"
        }
    },
    "476": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #476",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "476",
                "rarity_score": 440.4120308078461,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/476.png",
            "scheme": "https"
        }
    },
    "1441": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1441",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1441",
                "rarity_score": 296.2113944876446,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1441.png",
            "scheme": "https"
        }
    },
    "1011": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1011",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Yellow",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1011",
                "rarity_score": 1808.3958666792205,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1011.png",
            "scheme": "https"
        }
    },
    "531": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #531",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "531",
                "rarity_score": 568.6096153784736,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/531.png",
            "scheme": "https"
        }
    },
    "497": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #497",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "497",
                "rarity_score": 459.1894919051013,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/497.png",
            "scheme": "https"
        }
    },
    "1156": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1156",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1156",
                "rarity_score": 422.96937408047495,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1156.png",
            "scheme": "https"
        }
    },
    "161": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #161",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "161",
                "rarity_score": 376.324990798675,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/161.png",
            "scheme": "https"
        }
    },
    "860": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #860",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "860",
                "rarity_score": 536.2519354125193,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/860.png",
            "scheme": "https"
        }
    },
    "1290": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1290",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1290",
                "rarity_score": 439.2515527950311,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1290.png",
            "scheme": "https"
        }
    },
    "1289": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1289",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Top Hat",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1289",
                "rarity_score": 774.3453347604635,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1289.png",
            "scheme": "https"
        }
    },
    "924": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #924",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "924",
                "rarity_score": 452.31373181683733,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/924.png",
            "scheme": "https"
        }
    },
    "475": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #475",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 10",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "475",
                "rarity_score": 937.6466528640442,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/475.png",
            "scheme": "https"
        }
    },
    "1442": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1442",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1442",
                "rarity_score": 458.607909099886,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1442.png",
            "scheme": "https"
        }
    },
    "1012": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1012",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 02",
                        "score": 375.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "1012",
                "rarity_score": 722.9166666666667,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1012.png",
            "scheme": "https"
        }
    },
    "532": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #532",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "532",
                "rarity_score": 423.2729627820504,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/532.png",
            "scheme": "https"
        }
    },
    "1155": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1155",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1155",
                "rarity_score": 442.2919768895401,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1155.png",
            "scheme": "https"
        }
    },
    "498": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #498",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "498",
                "rarity_score": 504.94858893630055,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/498.png",
            "scheme": "https"
        }
    },
    "162": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #162",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "162",
                "rarity_score": 455.6252778366186,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/162.png",
            "scheme": "https"
        }
    },
    "859": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #859",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "859",
                "rarity_score": 345.2548396854204,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/859.png",
            "scheme": "https"
        }
    },
    "1381": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1381",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 02",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1381",
                "rarity_score": 487.4237288135593,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1381.png",
            "scheme": "https"
        }
    },
    "1227": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1227",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 5",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1227",
                "rarity_score": 913.877698388568,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1227.png",
            "scheme": "https"
        }
    },
    "707": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #707",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 3",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "707",
                "rarity_score": 719.5238095238094,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/707.png",
            "scheme": "https"
        }
    },
    "357": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #357",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "357",
                "rarity_score": 494.7802197802198,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/357.png",
            "scheme": "https"
        }
    },
    "1043": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1043",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Top Hat",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1043",
                "rarity_score": 782.8830018189285,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1043.png",
            "scheme": "https"
        }
    },
    "1413": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1413",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1413",
                "rarity_score": 428.2025908750968,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1413.png",
            "scheme": "https"
        }
    },
    "420": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #420",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "420",
                "rarity_score": 316.00274725274727,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/420.png",
            "scheme": "https"
        }
    },
    "971": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #971",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "971",
                "rarity_score": 301.4569264998634,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/971.png",
            "scheme": "https"
        }
    },
    "6": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #6",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "6",
                "rarity_score": 532.8212232261326,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/6.png",
            "scheme": "https"
        }
    },
    "82": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #82",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "82",
                "rarity_score": 529.8570218601275,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/82.png",
            "scheme": "https"
        }
    },
    "836": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #836",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemoncello",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "836",
                "rarity_score": 678.0914763782722,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/836.png",
            "scheme": "https"
        }
    },
    "133": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #133",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "133",
                "rarity_score": 439.1520979020979,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/133.png",
            "scheme": "https"
        }
    },
    "1100": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1100",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1100",
                "rarity_score": 520.5719461697722,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1100.png",
            "scheme": "https"
        }
    },
    "563": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #563",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "563",
                "rarity_score": 402.8083028083028,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/563.png",
            "scheme": "https"
        }
    },
    "1393": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1393",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1393",
                "rarity_score": 489.283303330333,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1393.png",
            "scheme": "https"
        }
    },
    "94": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #94",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 9",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "94",
                "rarity_score": 639.3747181695201,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/94.png",
            "scheme": "https"
        }
    },
    "963": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #963",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "963",
                "rarity_score": 722.4184782608696,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/963.png",
            "scheme": "https"
        }
    },
    "436": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #436",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "436",
                "rarity_score": 315.4346122393349,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/436.png",
            "scheme": "https"
        }
    },
    "1401": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1401",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 9",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1401",
                "rarity_score": 740.2130912565696,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1401.png",
            "scheme": "https"
        }
    },
    "1051": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1051",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mauve",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1051",
                "rarity_score": 859.7002986881407,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1051.png",
            "scheme": "https"
        }
    },
    "571": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #571",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "571",
                "rarity_score": 351.56115000744825,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/571.png",
            "scheme": "https"
        }
    },
    "1116": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1116",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1116",
                "rarity_score": 464.93505054300283,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1116.png",
            "scheme": "https"
        }
    },
    "121": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #121",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "121",
                "rarity_score": 301.8747181695201,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/121.png",
            "scheme": "https"
        }
    },
    "820": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #820",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "820",
                "rarity_score": 751.9777380242497,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/820.png",
            "scheme": "https"
        }
    },
    "1268": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1268",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1268",
                "rarity_score": 511.58296308050353,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1268.png",
            "scheme": "https"
        }
    },
    "748": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #748",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "748",
                "rarity_score": 462.93448673216744,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/748.png",
            "scheme": "https"
        }
    },
    "1285": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1285",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1285",
                "rarity_score": 420.68983957219257,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1285.png",
            "scheme": "https"
        }
    },
    "318": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #318",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Sad Eyes 01",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "318",
                "rarity_score": 727.0603551038333,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/318.png",
            "scheme": "https"
        }
    },
    "1004": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1004",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1004",
                "rarity_score": 406.10969570020364,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1004.png",
            "scheme": "https"
        }
    },
    "1454": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1454",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1454",
                "rarity_score": 337.17454278933405,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1454.png",
            "scheme": "https"
        }
    },
    "459": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #459",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "459",
                "rarity_score": 485.66849816849816,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/459.png",
            "scheme": "https"
        }
    },
    "932": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #932",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "932",
                "rarity_score": 504.369957741068,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/932.png",
            "scheme": "https"
        }
    },
    "898": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #898",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "898",
                "rarity_score": 378.16835411533305,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/898.png",
            "scheme": "https"
        }
    },
    "875": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #875",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 5",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "875",
                "rarity_score": 696.5122596701544,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/875.png",
            "scheme": "https"
        }
    },
    "174": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #174",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "174",
                "rarity_score": 407.1524966261808,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/174.png",
            "scheme": "https"
        }
    },
    "1139": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1139",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1139",
                "rarity_score": 555.5857508827805,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1139.png",
            "scheme": "https"
        }
    },
    "524": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #524",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "524",
                "rarity_score": 426.4409269592196,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/524.png",
            "scheme": "https"
        }
    },
    "731": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #731",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "731",
                "rarity_score": 504.283436354761,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/731.png",
            "scheme": "https"
        }
    },
    "697": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #697",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "697",
                "rarity_score": 454.7869963660586,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/697.png",
            "scheme": "https"
        }
    },
    "1356": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1356",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1356",
                "rarity_score": 314.70085470085473,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1356.png",
            "scheme": "https"
        }
    },
    "361": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #361",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 025",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "361",
                "rarity_score": 719.4175824175824,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/361.png",
            "scheme": "https"
        }
    },
    "676": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #676",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "676",
                "rarity_score": 619.208035257989,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/676.png",
            "scheme": "https"
        }
    },
    "226": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #226",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Chicken Nuggs",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "226",
                "rarity_score": 502.7722277722278,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/226.png",
            "scheme": "https"
        }
    },
    "1211": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1211",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 14",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1211",
                "rarity_score": 660.8606305974728,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1211.png",
            "scheme": "https"
        }
    },
    "55": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #55",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "55",
                "rarity_score": 373.3244802937898,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/55.png",
            "scheme": "https"
        }
    },
    "1090": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1090",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1090",
                "rarity_score": 353.7985588972431,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1090.png",
            "scheme": "https"
        }
    },
    "947": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #947",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "947",
                "rarity_score": 429.31657996885883,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/947.png",
            "scheme": "https"
        }
    },
    "334": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #334",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "334",
                "rarity_score": 629.0787591632397,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/334.png",
            "scheme": "https"
        }
    },
    "1299": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1299",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1299",
                "rarity_score": 340.6832298136646,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1299.png",
            "scheme": "https"
        }
    },
    "764": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #764",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 8",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "764",
                "rarity_score": 1130.0091575091574,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/764.png",
            "scheme": "https"
        }
    },
    "1244": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1244",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1244",
                "rarity_score": 368.0194993937582,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1244.png",
            "scheme": "https"
        }
    },
    "269": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #269",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "269",
                "rarity_score": 337.18315437422984,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/269.png",
            "scheme": "https"
        }
    },
    "619": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #619",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "619",
                "rarity_score": 480.67790585373706,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/619.png",
            "scheme": "https"
        }
    },
    "508": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #508",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "508",
                "rarity_score": 290.7496297471702,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/508.png",
            "scheme": "https"
        }
    },
    "158": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #158",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "158",
                "rarity_score": 412.11415396198004,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/158.png",
            "scheme": "https"
        }
    },
    "1495": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1495",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Chicken Nuggie",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1495",
                "rarity_score": 772.665513833992,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1495.png",
            "scheme": "https"
        }
    },
    "1182": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1182",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 2",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1182",
                "rarity_score": 1099.4642857142858,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1182.png",
            "scheme": "https"
        }
    },
    "1478": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1478",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1478",
                "rarity_score": 301.2787802230948,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1478.png",
            "scheme": "https"
        }
    },
    "1028": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1028",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 2",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1028",
                "rarity_score": 617.3626849438402,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1028.png",
            "scheme": "https"
        }
    },
    "288": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #288",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mushroom Hat",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "288",
                "rarity_score": 651.6732496826482,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/288.png",
            "scheme": "https"
        }
    },
    "772": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #772",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "772",
                "rarity_score": 349.5165290196346,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/772.png",
            "scheme": "https"
        }
    },
    "1315": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1315",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1315",
                "rarity_score": 284.94053464266227,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1315.png",
            "scheme": "https"
        }
    },
    "322": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #322",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "322",
                "rarity_score": 514.4098202522116,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/322.png",
            "scheme": "https"
        }
    },
    "635": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #635",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "635",
                "rarity_score": 426.11409503671956,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/635.png",
            "scheme": "https"
        }
    },
    "265": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #265",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "265",
                "rarity_score": 437.20415736120947,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/265.png",
            "scheme": "https"
        }
    },
    "1252": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1252",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Chicken Nuggs",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1252",
                "rarity_score": 714.9637825624667,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1252.png",
            "scheme": "https"
        }
    },
    "16": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #16",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "16",
                "rarity_score": 493.32343828581713,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/16.png",
            "scheme": "https"
        }
    },
    "1479": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1479",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 1",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1479",
                "rarity_score": 722.2365305275492,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1479.png",
            "scheme": "https"
        }
    },
    "1194": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1194",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Sad Eyes 01",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1194",
                "rarity_score": 988.5481366459627,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1194.png",
            "scheme": "https"
        }
    },
    "908": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #908",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue $kda",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "908",
                "rarity_score": 614.217032967033,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/908.png",
            "scheme": "https"
        }
    },
    "373": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #373",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 11",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "373",
                "rarity_score": 559.2828224407172,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/373.png",
            "scheme": "https"
        }
    },
    "1340": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1340",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 04",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1340",
                "rarity_score": 672.0047134874853,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1340.png",
            "scheme": "https"
        }
    },
    "723": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #723",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "723",
                "rarity_score": 275.8063399778516,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/723.png",
            "scheme": "https"
        }
    },
    "1203": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1203",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1203",
                "rarity_score": 323.75622622991045,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1203.png",
            "scheme": "https"
        }
    },
    "230": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #230",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "230",
                "rarity_score": 548.7612576355165,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/230.png",
            "scheme": "https"
        }
    },
    "660": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #660",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Cn Hood",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "660",
                "rarity_score": 847.6866883116884,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/660.png",
            "scheme": "https"
        }
    },
    "1086": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1086",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1086",
                "rarity_score": 465.53146853146853,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1086.png",
            "scheme": "https"
        }
    },
    "547": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #547",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "547",
                "rarity_score": 368.14585123787236,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/547.png",
            "scheme": "https"
        }
    },
    "117": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #117",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "117",
                "rarity_score": 556.3708869315277,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/117.png",
            "scheme": "https"
        }
    },
    "39": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #39",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "39",
                "rarity_score": 558.2408322167767,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/39.png",
            "scheme": "https"
        }
    },
    "1437": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1437",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1437",
                "rarity_score": 449.03846153846155,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1437.png",
            "scheme": "https"
        }
    },
    "1067": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1067",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1067",
                "rarity_score": 428.93592518592516,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1067.png",
            "scheme": "https"
        }
    },
    "179": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #179",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "179",
                "rarity_score": 450.07703636190803,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/179.png",
            "scheme": "https"
        }
    },
    "928": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #928",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "928",
                "rarity_score": 595.178621039402,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/928.png",
            "scheme": "https"
        }
    },
    "882": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #882",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "882",
                "rarity_score": 517.1065670806204,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/882.png",
            "scheme": "https"
        }
    },
    "36": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #36",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Light Side",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "White Lazers",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Jedy Robes",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Lightside Blue",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Derp",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "You are my father?",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "36",
                "rarity_score": 9057.692307692309,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/36.png",
            "scheme": "https"
        }
    },
    "494": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #494",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "494",
                "rarity_score": 411.6245568401663,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/494.png",
            "scheme": "https"
        }
    },
    "615": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #615",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "615",
                "rarity_score": 682.606910098887,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/615.png",
            "scheme": "https"
        }
    },
    "245": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #245",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    { "trait_type": "Clothes", "value": "Kda", "score": 300.0 },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "245",
                "rarity_score": 747.1143309983173,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/245.png",
            "scheme": "https"
        }
    },
    "1272": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1272",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 005",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1272",
                "rarity_score": 450.3432494279176,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1272.png",
            "scheme": "https"
        }
    },
    "752": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #752",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "752",
                "rarity_score": 436.9242770959018,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/752.png",
            "scheme": "https"
        }
    },
    "1335": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1335",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1335",
                "rarity_score": 474.27028666747015,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1335.png",
            "scheme": "https"
        }
    },
    "302": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #302",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "302",
                "rarity_score": 502.9908748950569,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/302.png",
            "scheme": "https"
        }
    },
    "1417": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1417",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1417",
                "rarity_score": 616.5755297334244,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1417.png",
            "scheme": "https"
        }
    },
    "586": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #586",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "586",
                "rarity_score": 518.6045066045067,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/586.png",
            "scheme": "https"
        }
    },
    "1047": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1047",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1047",
                "rarity_score": 379.41507080490135,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1047.png",
            "scheme": "https"
        }
    },
    "567": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #567",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 013",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "567",
                "rarity_score": 659.9911057501934,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/567.png",
            "scheme": "https"
        }
    },
    "137": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #137",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "137",
                "rarity_score": 339.630246802378,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/137.png",
            "scheme": "https"
        }
    },
    "990": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #990",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "990",
                "rarity_score": 464.93059881295176,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/990.png",
            "scheme": "https"
        }
    },
    "59": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #59",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "59",
                "rarity_score": 432.8567324017239,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/59.png",
            "scheme": "https"
        }
    },
    "1223": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1223",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1223",
                "rarity_score": 468.44586249986946,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1223.png",
            "scheme": "https"
        }
    },
    "210": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #210",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "210",
                "rarity_score": 418.09370595448695,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/210.png",
            "scheme": "https"
        }
    },
    "640": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #640",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "640",
                "rarity_score": 369.04761904761904,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/640.png",
            "scheme": "https"
        }
    },
    "353": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #353",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 2",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "353",
                "rarity_score": 724.3916865809801,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/353.png",
            "scheme": "https"
        }
    },
    "1360": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1360",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1360",
                "rarity_score": 446.97242272158013,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1360.png",
            "scheme": "https"
        }
    },
    "703": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #703",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Bell Baby",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunset",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Cow Onesie",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Carmello",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Stoned",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Grey", "score": 1500.0 }
                ],
                "compiler": "nft-inator.com",
                "id": "703",
                "rarity_score": 9000.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/703.png",
            "scheme": "https"
        }
    },
    "590": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #590",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 03",
                        "score": 500.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "590",
                "rarity_score": 877.7173913043478,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/590.png",
            "scheme": "https"
        }
    },
    "967": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #967",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "967",
                "rarity_score": 363.3165859564165,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/967.png",
            "scheme": "https"
        }
    },
    "98": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #98",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "98",
                "rarity_score": 674.0188383045526,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/98.png",
            "scheme": "https"
        }
    },
    "75": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #75",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "75",
                "rarity_score": 397.2950819672131,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/75.png",
            "scheme": "https"
        }
    },
    "986": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #986",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "986",
                "rarity_score": 453.4980519295816,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/986.png",
            "scheme": "https"
        }
    },
    "1397": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1397",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1397",
                "rarity_score": 654.3480639864902,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1397.png",
            "scheme": "https"
        }
    },
    "656": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #656",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "656",
                "rarity_score": 479.3698246880711,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/656.png",
            "scheme": "https"
        }
    },
    "206": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #206",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "206",
                "rarity_score": 413.42651888341544,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/206.png",
            "scheme": "https"
        }
    },
    "1231": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1231",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 1",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1231",
                "rarity_score": 565.3738719451947,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1231.png",
            "scheme": "https"
        }
    },
    "711": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #711",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 10",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "711",
                "rarity_score": 1014.5209176788123,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/711.png",
            "scheme": "https"
        }
    },
    "1376": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1376",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1376",
                "rarity_score": 362.26833631484794,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1376.png",
            "scheme": "https"
        }
    },
    "341": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #341",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "341",
                "rarity_score": 415.6751292186075,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/341.png",
            "scheme": "https"
        }
    },
    "894": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #894",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "894",
                "rarity_score": 498.78005657708627,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/894.png",
            "scheme": "https"
        }
    },
    "1458": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1458",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1458",
                "rarity_score": 439.9935289405079,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1458.png",
            "scheme": "https"
        }
    },
    "195": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #195",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "195",
                "rarity_score": 452.5309364548495,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/195.png",
            "scheme": "https"
        }
    },
    "1008": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1008",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 16",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1008",
                "rarity_score": 595.5357142857143,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1008.png",
            "scheme": "https"
        }
    },
    "528": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #528",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Brown Eyes 01",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "528",
                "rarity_score": 674.2175436481244,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/528.png",
            "scheme": "https"
        }
    },
    "482": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #482",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "482",
                "rarity_score": 450.6444099378882,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/482.png",
            "scheme": "https"
        }
    },
    "178": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #178",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 7",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "178",
                "rarity_score": 582.0125994694961,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/178.png",
            "scheme": "https"
        }
    },
    "20": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #20",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "20",
                "rarity_score": 843.3131313131313,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/20.png",
            "scheme": "https"
        }
    },
    "1264": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1264",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 01",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1264",
                "rarity_score": 1111.0052116550771,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1264.png",
            "scheme": "https"
        }
    },
    "249": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #249",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "249",
                "rarity_score": 428.7042990013287,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/249.png",
            "scheme": "https"
        }
    },
    "599": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #599",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "599",
                "rarity_score": 380.9473828669018,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/599.png",
            "scheme": "https"
        }
    },
    "314": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #314",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "314",
                "rarity_score": 494.06929186626024,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/314.png",
            "scheme": "https"
        }
    },
    "1319": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1319",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1319",
                "rarity_score": 316.5867176301959,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1319.png",
            "scheme": "https"
        }
    },
    "744": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #744",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Stripes",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "744",
                "rarity_score": 1274.9214755373455,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/744.png",
            "scheme": "https"
        }
    },
    "551": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #551",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 16",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "551",
                "rarity_score": 598.3486842105262,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/551.png",
            "scheme": "https"
        }
    },
    "1136": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1136",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1136",
                "rarity_score": 577.1848924022837,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1136.png",
            "scheme": "https"
        }
    },
    "101": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #101",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "101",
                "rarity_score": 445.1348861848399,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/101.png",
            "scheme": "https"
        }
    },
    "800": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #800",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 03",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "800",
                "rarity_score": 480.62937062937067,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/800.png",
            "scheme": "https"
        }
    },
    "943": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #943",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "943",
                "rarity_score": 418.38938129996524,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/943.png",
            "scheme": "https"
        }
    },
    "416": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #416",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "416",
                "rarity_score": 514.8391450469904,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/416.png",
            "scheme": "https"
        }
    },
    "1421": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1421",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1421",
                "rarity_score": 436.89385614385617,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1421.png",
            "scheme": "https"
        }
    },
    "1071": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1071",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 2",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 02",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1071",
                "rarity_score": 1160.7940630797773,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1071.png",
            "scheme": "https"
        }
    },
    "693": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #693",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 9",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "693",
                "rarity_score": 685.4204678149175,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/693.png",
            "scheme": "https"
        }
    },
    "380": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #380",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "380",
                "rarity_score": 360.5809178743961,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/380.png",
            "scheme": "https"
        }
    },
    "855": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #855",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 9",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "855",
                "rarity_score": 795.6367403088715,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/855.png",
            "scheme": "https"
        }
    },
    "154": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #154",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Bobby Rossy",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Painter's Escape",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Denim Shirt",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Pale", "score": 1500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Happy Little Painter",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Phthalo Blue",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Beard",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Vandyke Brown Fro",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "154",
                "rarity_score": 12000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/154.png",
            "scheme": "https"
        }
    },
    "1159": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1159",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 5",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1159",
                "rarity_score": 856.8564325370133,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1159.png",
            "scheme": "https"
        }
    },
    "504": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #504",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "504",
                "rarity_score": 554.686617730096,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/504.png",
            "scheme": "https"
        }
    },
    "1024": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1024",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1024",
                "rarity_score": 560.1658305462654,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1024.png",
            "scheme": "https"
        }
    },
    "1474": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1474",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 04",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1474",
                "rarity_score": 797.58303522642,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1474.png",
            "scheme": "https"
        }
    },
    "439": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #439",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "439",
                "rarity_score": 513.5332752354029,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/439.png",
            "scheme": "https"
        }
    },
    "912": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #912",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "912",
                "rarity_score": 476.1050678644664,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/912.png",
            "scheme": "https"
        }
    },
    "292": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #292",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 04",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "292",
                "rarity_score": 891.3132919711867,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/292.png",
            "scheme": "https"
        }
    },
    "768": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #768",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Suit",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "768",
                "rarity_score": 675.5322384428223,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/768.png",
            "scheme": "https"
        }
    },
    "338": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #338",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "338",
                "rarity_score": 376.2541806020067,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/338.png",
            "scheme": "https"
        }
    },
    "1248": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1248",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1248",
                "rarity_score": 482.94068869726766,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1248.png",
            "scheme": "https"
        }
    },
    "785": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #785",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "785",
                "rarity_score": 432.61185486526307,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/785.png",
            "scheme": "https"
        }
    },
    "512": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #512",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "512",
                "rarity_score": 436.88719755271734,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/512.png",
            "scheme": "https"
        }
    },
    "1175": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1175",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1175",
                "rarity_score": 572.4724360307885,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1175.png",
            "scheme": "https"
        }
    },
    "142": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #142",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "142",
                "rarity_score": 681.0375883560671,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/142.png",
            "scheme": "https"
        }
    },
    "839": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #839",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    { "trait_type": "Clothes", "value": "Kda", "score": 300.0 },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "839",
                "rarity_score": 698.6470931274083,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/839.png",
            "scheme": "https"
        }
    },
    "904": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #904",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Kawaii Brown & Green Eyes",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "904",
                "rarity_score": 616.7389940017624,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/904.png",
            "scheme": "https"
        }
    },
    "455": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #455",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "455",
                "rarity_score": 444.03705814309535,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/455.png",
            "scheme": "https"
        }
    },
    "1198": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1198",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1198",
                "rarity_score": 246.8526189353629,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1198.png",
            "scheme": "https"
        }
    },
    "1462": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1462",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1462",
                "rarity_score": 309.7202757478366,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1462.png",
            "scheme": "https"
        }
    },
    "1032": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1032",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1032",
                "rarity_score": 601.0964122159118,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1032.png",
            "scheme": "https"
        }
    },
    "284": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #284",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "284",
                "rarity_score": 786.4144370122631,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/284.png",
            "scheme": "https"
        }
    },
    "789": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #789",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 3",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "789",
                "rarity_score": 964.8230390299356,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/789.png",
            "scheme": "https"
        }
    },
    "816": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #816",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "816",
                "rarity_score": 454.4348856190121,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/816.png",
            "scheme": "https"
        }
    },
    "113": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #113",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "113",
                "rarity_score": 453.9685314685315,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/113.png",
            "scheme": "https"
        }
    },
    "1120": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1120",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pink Cn Hood",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1120",
                "rarity_score": 1231.1708459979736,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1120.png",
            "scheme": "https"
        }
    },
    "543": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #543",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "KDA Colors 002 .PNG",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hon",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "543",
                "rarity_score": 1021.7935635792778,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/543.png",
            "scheme": "https"
        }
    },
    "1063": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1063",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1063",
                "rarity_score": 505.34893048128345,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1063.png",
            "scheme": "https"
        }
    },
    "1433": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1433",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1433",
                "rarity_score": 413.9262820512821,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1433.png",
            "scheme": "https"
        }
    },
    "400": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #400",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "400",
                "rarity_score": 344.3797727696033,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/400.png",
            "scheme": "https"
        }
    },
    "951": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #951",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "951",
                "rarity_score": 648.8573926073926,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/951.png",
            "scheme": "https"
        }
    },
    "727": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #727",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "727",
                "rarity_score": 312.3272568129408,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/727.png",
            "scheme": "https"
        }
    },
    "681": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #681",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue $kda",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "681",
                "rarity_score": 542.8637987265104,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/681.png",
            "scheme": "https"
        }
    },
    "377": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #377",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 015",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "377",
                "rarity_score": 586.7113435763322,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/377.png",
            "scheme": "https"
        }
    },
    "396": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #396",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 5",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "396",
                "rarity_score": 1000.3045525902669,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/396.png",
            "scheme": "https"
        }
    },
    "1207": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1207",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Chicken Nuggie",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Green Cn Hood",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1207",
                "rarity_score": 1045.2621462162083,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1207.png",
            "scheme": "https"
        }
    },
    "46": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #46",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "46",
                "rarity_score": 629.6960143681456,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/46.png",
            "scheme": "https"
        }
    },
    "1079": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1079",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1079",
                "rarity_score": 441.8567549401499,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1079.png",
            "scheme": "https"
        }
    },
    "958": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #958",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "958",
                "rarity_score": 534.040338543444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/958.png",
            "scheme": "https"
        }
    },
    "688": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #688",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "688",
                "rarity_score": 497.38539531884624,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/688.png",
            "scheme": "https"
        }
    },
    "1345": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1345",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1345",
                "rarity_score": 516.0024072037802,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1345.png",
            "scheme": "https"
        }
    },
    "372": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #372",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "372",
                "rarity_score": 481.48360030511054,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/372.png",
            "scheme": "https"
        }
    },
    "722": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #722",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "722",
                "rarity_score": 443.7020714166623,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/722.png",
            "scheme": "https"
        }
    },
    "235": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #235",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "235",
                "rarity_score": 423.74641148325355,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/235.png",
            "scheme": "https"
        }
    },
    "1202": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1202",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Santa Paws",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "North Pole",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Have a Holly Jolly Christmas",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Christmas",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Jolly",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Nice", "score": 1500.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Naughty Beard",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Santa Hat",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1202",
                "rarity_score": 12000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1202.png",
            "scheme": "https"
        }
    },
    "665": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #665",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Howlie",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "HNL 808",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Got Musubi",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Sunburned",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "On Vacation",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Spaced Out",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "The Aloha Spirit",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aloha Print Hat",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "665",
                "rarity_score": 12000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/665.png",
            "scheme": "https"
        }
    },
    "147": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #147",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "147",
                "rarity_score": 597.2865435579417,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/147.png",
            "scheme": "https"
        }
    },
    "1486": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1486",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1486",
                "rarity_score": 577.4652371069885,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1486.png",
            "scheme": "https"
        }
    },
    "517": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #517",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "517",
                "rarity_score": 470.53818839343757,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/517.png",
            "scheme": "https"
        }
    },
    "9": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #9",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "9",
                "rarity_score": 574.5762281197065,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/9.png",
            "scheme": "https"
        }
    },
    "1037": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1037",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1037",
                "rarity_score": 423.3573195810037,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1037.png",
            "scheme": "https"
        }
    },
    "1191": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1191",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1191",
                "rarity_score": 440.2490317702876,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1191.png",
            "scheme": "https"
        }
    },
    "1467": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1467",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1467",
                "rarity_score": 406.6193609022556,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1467.png",
            "scheme": "https"
        }
    },
    "773": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #773",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 12",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "773",
                "rarity_score": 577.0300592484913,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/773.png",
            "scheme": "https"
        }
    },
    "323": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #323",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "323",
                "rarity_score": 497.0,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/323.png",
            "scheme": "https"
        }
    },
    "1310": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1310",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1310",
                "rarity_score": 352.51342860038517,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1310.png",
            "scheme": "https"
        }
    },
    "630": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #630",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 02",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "630",
                "rarity_score": 693.4215641806519,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/630.png",
            "scheme": "https"
        }
    },
    "1253": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1253",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "1253",
                "rarity_score": 580.4194352159468,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1253.png",
            "scheme": "https"
        }
    },
    "260": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #260",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "260",
                "rarity_score": 493.3594762768991,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/260.png",
            "scheme": "https"
        }
    },
    "1490": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1490",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1490",
                "rarity_score": 337.9698457998717,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1490.png",
            "scheme": "https"
        }
    },
    "1183": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1183",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Blue Sad Eyes 01",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1183",
                "rarity_score": 756.7346020316318,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1183.png",
            "scheme": "https"
        }
    },
    "917": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #917",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 11",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "917",
                "rarity_score": 689.0257064364207,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/917.png",
            "scheme": "https"
        }
    },
    "1306": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1306",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1306",
                "rarity_score": 591.1270748843859,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1306.png",
            "scheme": "https"
        }
    },
    "331": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #331",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "331",
                "rarity_score": 919.8849721706865,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/331.png",
            "scheme": "https"
        }
    },
    "297": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #297",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "297",
                "rarity_score": 562.2590965155721,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/297.png",
            "scheme": "https"
        }
    },
    "761": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #761",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "761",
                "rarity_score": 555.0791430791431,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/761.png",
            "scheme": "https"
        }
    },
    "276": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #276",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "276",
                "rarity_score": 627.3711733174714,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/276.png",
            "scheme": "https"
        }
    },
    "1241": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1241",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1241",
                "rarity_score": 418.91266920652225,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1241.png",
            "scheme": "https"
        }
    },
    "626": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #626",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "626",
                "rarity_score": 789.3231200654955,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/626.png",
            "scheme": "https"
        }
    },
    "108": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #108",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 02",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "108",
                "rarity_score": 561.3890495469443,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/108.png",
            "scheme": "https"
        }
    },
    "1095": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1095",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1095",
                "rarity_score": 579.5813866216344,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1095.png",
            "scheme": "https"
        }
    },
    "558": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #558",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "558",
                "rarity_score": 532.7637828880418,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/558.png",
            "scheme": "https"
        }
    },
    "50": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #50",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "50",
                "rarity_score": 536.3088165159121,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/50.png",
            "scheme": "https"
        }
    },
    "1078": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1078",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1078",
                "rarity_score": 396.65202936763876,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1078.png",
            "scheme": "https"
        }
    },
    "1428": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1428",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1428",
                "rarity_score": 504.7995090016367,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1428.png",
            "scheme": "https"
        }
    },
    "734": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #734",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "734",
                "rarity_score": 403.1442098047937,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/734.png",
            "scheme": "https"
        }
    },
    "364": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #364",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "364",
                "rarity_score": 571.2437618044025,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/364.png",
            "scheme": "https"
        }
    },
    "1349": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1349",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1349",
                "rarity_score": 526.9743843113408,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1349.png",
            "scheme": "https"
        }
    },
    "669": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #669",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 5",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "669",
                "rarity_score": 651.4240764955867,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/669.png",
            "scheme": "https"
        }
    },
    "1214": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1214",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1214",
                "rarity_score": 451.2250155536667,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1214.png",
            "scheme": "https"
        }
    },
    "219": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #219",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "219",
                "rarity_score": 332.7560121038382,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/219.png",
            "scheme": "https"
        }
    },
    "933": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #933",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "933",
                "rarity_score": 537.235337903075,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/933.png",
            "scheme": "https"
        }
    },
    "1001": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1001",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1001",
                "rarity_score": 435.4897214360197,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1001.png",
            "scheme": "https"
        }
    },
    "466": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #466",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 7",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "466",
                "rarity_score": 475.80720470463075,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/466.png",
            "scheme": "https"
        }
    },
    "1451": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1451",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1451",
                "rarity_score": 522.9654403567447,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1451.png",
            "scheme": "https"
        }
    },
    "1146": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1146",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1146",
                "rarity_score": 408.10193096377304,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1146.png",
            "scheme": "https"
        }
    },
    "487": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #487",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 010",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "487",
                "rarity_score": 554.6504008633981,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/487.png",
            "scheme": "https"
        }
    },
    "171": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #171",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "171",
                "rarity_score": 437.19561430086344,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/171.png",
            "scheme": "https"
        }
    },
    "521": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #521",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "521",
                "rarity_score": 444.74147123901173,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/521.png",
            "scheme": "https"
        }
    },
    "870": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #870",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "870",
                "rarity_score": 513.4106185433462,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/870.png",
            "scheme": "https"
        }
    },
    "1280": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1280",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hon",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1280",
                "rarity_score": 688.0558069381599,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1280.png",
            "scheme": "https"
        }
    },
    "1404": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1404",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1404",
                "rarity_score": 466.61803109171524,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1404.png",
            "scheme": "https"
        }
    },
    "429": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #429",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "429",
                "rarity_score": 458.1836216124982,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/429.png",
            "scheme": "https"
        }
    },
    "1054": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1054",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 10",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1054",
                "rarity_score": 561.2577639751553,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1054.png",
            "scheme": "https"
        }
    },
    "962": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #962",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "962",
                "rarity_score": 337.36638361638364,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/962.png",
            "scheme": "https"
        }
    },
    "91": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #91",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "91",
                "rarity_score": 514.892328707168,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/91.png",
            "scheme": "https"
        }
    },
    "825": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #825",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "825",
                "rarity_score": 526.562280782223,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/825.png",
            "scheme": "https"
        }
    },
    "574": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #574",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "574",
                "rarity_score": 461.55226352594775,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/574.png",
            "scheme": "https"
        }
    },
    "124": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #124",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 08",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "124",
                "rarity_score": 598.136972142107,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/124.png",
            "scheme": "https"
        }
    },
    "1109": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1109",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1109",
                "rarity_score": 403.0757143782803,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1109.png",
            "scheme": "https"
        }
    },
    "1238": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1238",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1238",
                "rarity_score": 518.9172525515207,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1238.png",
            "scheme": "https"
        }
    },
    "1392": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1392",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1392",
                "rarity_score": 534.715716887848,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1392.png",
            "scheme": "https"
        }
    },
    "348": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #348",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "348",
                "rarity_score": 532.76474152414,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/348.png",
            "scheme": "https"
        }
    },
    "718": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #718",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "718",
                "rarity_score": 296.2577639751553,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/718.png",
            "scheme": "https"
        }
    },
    "83": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #83",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 021",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "83",
                "rarity_score": 726.4628963713632,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/83.png",
            "scheme": "https"
        }
    },
    "974": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #974",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "974",
                "rarity_score": 421.2608854323971,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/974.png",
            "scheme": "https"
        }
    },
    "1042": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1042",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1042",
                "rarity_score": 521.5571095571096,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1042.png",
            "scheme": "https"
        }
    },
    "425": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #425",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "425",
                "rarity_score": 466.47102897102894,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/425.png",
            "scheme": "https"
        }
    },
    "1412": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1412",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 7",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1412",
                "rarity_score": 846.1155717347668,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1412.png",
            "scheme": "https"
        }
    },
    "1105": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1105",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1105",
                "rarity_score": 352.10025481764615,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1105.png",
            "scheme": "https"
        }
    },
    "132": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #132",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 15",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Strawberry Milk",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "132",
                "rarity_score": 1464.4852529073842,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/132.png",
            "scheme": "https"
        }
    },
    "562": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #562",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "562",
                "rarity_score": 502.63363537047746,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/562.png",
            "scheme": "https"
        }
    },
    "829": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #829",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "829",
                "rarity_score": 518.479662033669,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/829.png",
            "scheme": "https"
        }
    },
    "1384": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1384",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1384",
                "rarity_score": 486.64772727272725,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1384.png",
            "scheme": "https"
        }
    },
    "1443": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1443",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1443",
                "rarity_score": 582.5484092242211,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1443.png",
            "scheme": "https"
        }
    },
    "470": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #470",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "470",
                "rarity_score": 457.5562228660403,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/470.png",
            "scheme": "https"
        }
    },
    "1013": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1013",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 8",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1013",
                "rarity_score": 1006.4204001045266,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1013.png",
            "scheme": "https"
        }
    },
    "887": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #887",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "887",
                "rarity_score": 455.7190191411503,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/887.png",
            "scheme": "https"
        }
    },
    "921": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #921",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "921",
                "rarity_score": 610.3176384426384,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/921.png",
            "scheme": "https"
        }
    },
    "866": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #866",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "866",
                "rarity_score": 339.70085470085473,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/866.png",
            "scheme": "https"
        }
    },
    "533": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #533",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemoncello",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "533",
                "rarity_score": 1036.2482517482517,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/533.png",
            "scheme": "https"
        }
    },
    "163": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #163",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "163",
                "rarity_score": 646.9551282051283,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/163.png",
            "scheme": "https"
        }
    },
    "1150": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1150",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1150",
                "rarity_score": 631.030701754386,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1150.png",
            "scheme": "https"
        }
    },
    "1277": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1277",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 7",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1277",
                "rarity_score": 595.9400611398834,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1277.png",
            "scheme": "https"
        }
    },
    "307": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #307",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "307",
                "rarity_score": 340.5107935542718,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/307.png",
            "scheme": "https"
        }
    },
    "757": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #757",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 3",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "757",
                "rarity_score": 645.5707736668179,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/757.png",
            "scheme": "https"
        }
    },
    "1296": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1296",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1296",
                "rarity_score": 351.35540583219284,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1296.png",
            "scheme": "https"
        }
    },
    "684": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #684",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "684",
                "rarity_score": 599.8802398974024,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/684.png",
            "scheme": "https"
        }
    },
    "389": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #389",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "389",
                "rarity_score": 613.3573552051813,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/389.png",
            "scheme": "https"
        }
    },
    "1125": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1125",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1125",
                "rarity_score": 434.9042624042624,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1125.png",
            "scheme": "https"
        }
    },
    "112": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #112",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "112",
                "rarity_score": 438.3988706029328,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/112.png",
            "scheme": "https"
        }
    },
    "542": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #542",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "542",
                "rarity_score": 472.8943308464585,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/542.png",
            "scheme": "https"
        }
    },
    "809": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #809",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "809",
                "rarity_score": 319.99710815500293,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/809.png",
            "scheme": "https"
        }
    },
    "954": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #954",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "954",
                "rarity_score": 286.9704491725768,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/954.png",
            "scheme": "https"
        }
    },
    "1062": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1062",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1062",
                "rarity_score": 426.4250955856795,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1062.png",
            "scheme": "https"
        }
    },
    "405": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #405",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "405",
                "rarity_score": 371.3869463869464,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/405.png",
            "scheme": "https"
        }
    },
    "1432": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1432",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1432",
                "rarity_score": 596.4714168241287,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1432.png",
            "scheme": "https"
        }
    },
    "327": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #327",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "327",
                "rarity_score": 657.2279137819207,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/327.png",
            "scheme": "https"
        }
    },
    "281": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #281",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 005",
                        "score": 250.0
                    },
                    { "trait_type": "Clothes", "value": "Kda", "score": 300.0 },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kda Hood",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "281",
                "rarity_score": 1665.530303030303,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/281.png",
            "scheme": "https"
        }
    },
    "777": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #777",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "777",
                "rarity_score": 492.885173337301,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/777.png",
            "scheme": "https"
        }
    },
    "1257": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1257",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1257",
                "rarity_score": 301.9498746867168,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1257.png",
            "scheme": "https"
        }
    },
    "796": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #796",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "796",
                "rarity_score": 307.3537573537574,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/796.png",
            "scheme": "https"
        }
    },
    "846": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #846",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "846",
                "rarity_score": 344.3654865023968,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/846.png",
            "scheme": "https"
        }
    },
    "513": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #513",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Multicolor Wagmi",
                        "score": 750.0
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "513",
                "rarity_score": 1169.5405138339922,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/513.png",
            "scheme": "https"
        }
    },
    "143": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #143",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "143",
                "rarity_score": 441.3003663003663,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/143.png",
            "scheme": "https"
        }
    },
    "1170": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1170",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1170",
                "rarity_score": 549.4594917277843,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1170.png",
            "scheme": "https"
        }
    },
    "1463": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1463",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 8",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1463",
                "rarity_score": 1010.1211689237349,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1463.png",
            "scheme": "https"
        }
    },
    "450": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #450",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "450",
                "rarity_score": 307.54503027520934,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/450.png",
            "scheme": "https"
        }
    },
    "1033": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1033",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1033",
                "rarity_score": 443.2300618636022,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1033.png",
            "scheme": "https"
        }
    },
    "901": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #901",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "901",
                "rarity_score": 482.0089519701673,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/901.png",
            "scheme": "https"
        }
    },
    "293": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #293",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 010",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "293",
                "rarity_score": 711.5679157798722,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/293.png",
            "scheme": "https"
        }
    },
    "780": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #780",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "780",
                "rarity_score": 384.82586492454914,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/780.png",
            "scheme": "https"
        }
    },
    "1166": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1166",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1166",
                "rarity_score": 495.70875732623784,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1166.png",
            "scheme": "https"
        }
    },
    "151": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #151",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "151",
                "rarity_score": 425.98680692397437,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/151.png",
            "scheme": "https"
        }
    },
    "501": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #501",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "501",
                "rarity_score": 494.159502759247,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/501.png",
            "scheme": "https"
        }
    },
    "850": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #850",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 04",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "850",
                "rarity_score": 987.6447323168635,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/850.png",
            "scheme": "https"
        }
    },
    "913": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #913",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 020",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "913",
                "rarity_score": 726.3181257198164,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/913.png",
            "scheme": "https"
        }
    },
    "1021": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1021",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1021",
                "rarity_score": 480.52089364730915,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1021.png",
            "scheme": "https"
        }
    },
    "446": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #446",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "446",
                "rarity_score": 376.9778166741149,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/446.png",
            "scheme": "https"
        }
    },
    "1187": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1187",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1187",
                "rarity_score": 341.406930394587,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1187.png",
            "scheme": "https"
        }
    },
    "1471": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1471",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Kadena Mining Club",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 014",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kadena Mining Club Shirt",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1471",
                "rarity_score": 4777.43358164143,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1471.png",
            "scheme": "https"
        }
    },
    "692": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #692",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 013",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "692",
                "rarity_score": 768.515507602464,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/692.png",
            "scheme": "https"
        }
    },
    "368": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #368",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "368",
                "rarity_score": 325.4206488087226,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/368.png",
            "scheme": "https"
        }
    },
    "738": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #738",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "738",
                "rarity_score": 353.32067706654476,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/738.png",
            "scheme": "https"
        }
    },
    "1218": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1218",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1218",
                "rarity_score": 364.217032967033,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1218.png",
            "scheme": "https"
        }
    },
    "385": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #385",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "385",
                "rarity_score": 378.29380764163375,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/385.png",
            "scheme": "https"
        }
    },
    "805": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #805",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "805",
                "rarity_score": 565.0647963147964,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/805.png",
            "scheme": "https"
        }
    },
    "554": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #554",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "554",
                "rarity_score": 494.8773081034462,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/554.png",
            "scheme": "https"
        }
    },
    "104": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #104",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "104",
                "rarity_score": 602.2456898699486,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/104.png",
            "scheme": "https"
        }
    },
    "1129": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1129",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1129",
                "rarity_score": 689.0358188323305,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1129.png",
            "scheme": "https"
        }
    },
    "1424": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1424",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1424",
                "rarity_score": 396.0746949913729,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1424.png",
            "scheme": "https"
        }
    },
    "409": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #409",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "409",
                "rarity_score": 567.4685066706344,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/409.png",
            "scheme": "https"
        }
    },
    "1074": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1074",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1074",
                "rarity_score": 435.3179596271875,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1074.png",
            "scheme": "https"
        }
    },
    "942": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #942",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "942",
                "rarity_score": 323.46288242072785,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/942.png",
            "scheme": "https"
        }
    },
    "256": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #256",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 08",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "256",
                "rarity_score": 625.007631993316,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/256.png",
            "scheme": "https"
        }
    },
    "1261": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1261",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1261",
                "rarity_score": 500.32275551317935,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1261.png",
            "scheme": "https"
        }
    },
    "606": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #606",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 12",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "606",
                "rarity_score": 528.2040882933127,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/606.png",
            "scheme": "https"
        }
    },
    "1326": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1326",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1326",
                "rarity_score": 447.9122740701688,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1326.png",
            "scheme": "https"
        }
    },
    "311": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #311",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "311",
                "rarity_score": 468.17213657086404,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/311.png",
            "scheme": "https"
        }
    },
    "741": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #741",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "741",
                "rarity_score": 488.4722222222222,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/741.png",
            "scheme": "https"
        }
    },
    "190": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #190",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 2",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "190",
                "rarity_score": 822.832524618239,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/190.png",
            "scheme": "https"
        }
    },
    "891": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #891",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 005",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "891",
                "rarity_score": 614.7963933833499,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/891.png",
            "scheme": "https"
        }
    },
    "937": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #937",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "937",
                "rarity_score": 293.95321795950974,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/937.png",
            "scheme": "https"
        }
    },
    "25": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #25",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "25",
                "rarity_score": 545.7973899579739,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/25.png",
            "scheme": "https"
        }
    },
    "483": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #483",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "483",
                "rarity_score": 389.5974025974026,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/483.png",
            "scheme": "https"
        }
    },
    "649": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #649",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "649",
                "rarity_score": 599.8509630016086,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/649.png",
            "scheme": "https"
        }
    },
    "1234": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1234",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1234",
                "rarity_score": 466.00420800731354,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1234.png",
            "scheme": "https"
        }
    },
    "199": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #199",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "199",
                "rarity_score": 531.7060680547522,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/199.png",
            "scheme": "https"
        }
    },
    "714": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #714",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "714",
                "rarity_score": 546.6498151564269,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/714.png",
            "scheme": "https"
        }
    },
    "344": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #344",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "344",
                "rarity_score": 472.062969924812,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/344.png",
            "scheme": "https"
        }
    },
    "1369": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1369",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Dark Side",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Dark Lazers",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Sithy",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Grey Poupon",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Dull",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Teal", "score": 1500.0 }
                ],
                "compiler": "nft-inator.com",
                "id": "1369",
                "rarity_score": 9000.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1369.png",
            "scheme": "https"
        }
    },
    "595": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #595",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 2",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "595",
                "rarity_score": 986.1912393162394,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/595.png",
            "scheme": "https"
        }
    },
    "1058": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1058",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1058",
                "rarity_score": 548.3918128654971,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1058.png",
            "scheme": "https"
        }
    },
    "1408": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1408",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1408",
                "rarity_score": 723.8339326577313,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1408.png",
            "scheme": "https"
        }
    },
    "128": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #128",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "128",
                "rarity_score": 512.3862248862249,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/128.png",
            "scheme": "https"
        }
    },
    "578": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #578",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "578",
                "rarity_score": 423.05557000208034,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/578.png",
            "scheme": "https"
        }
    },
    "979": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #979",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "979",
                "rarity_score": 564.1065589000372,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/979.png",
            "scheme": "https"
        }
    },
    "70": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #70",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "70",
                "rarity_score": 382.7050641419011,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/70.png",
            "scheme": "https"
        }
    },
    "215": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #215",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "215",
                "rarity_score": 315.0124732715609,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/215.png",
            "scheme": "https"
        }
    },
    "1222": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1222",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1222",
                "rarity_score": 362.5950136819702,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1222.png",
            "scheme": "https"
        }
    },
    "1388": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1388",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Cn Hood",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1388",
                "rarity_score": 876.2489562934752,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1388.png",
            "scheme": "https"
        }
    },
    "645": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #645",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "645",
                "rarity_score": 638.5989010989011,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/645.png",
            "scheme": "https"
        }
    },
    "1365": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1365",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1365",
                "rarity_score": 634.4358865649142,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1365.png",
            "scheme": "https"
        }
    },
    "352": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #352",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "352",
                "rarity_score": 522.6255175983437,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/352.png",
            "scheme": "https"
        }
    },
    "702": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #702",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "702",
                "rarity_score": 371.4848052873712,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/702.png",
            "scheme": "https"
        }
    },
    "579": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #579",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "579",
                "rarity_score": 315.3571428571429,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/579.png",
            "scheme": "https"
        }
    },
    "978": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #978",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "978",
                "rarity_score": 300.34907782276207,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/978.png",
            "scheme": "https"
        }
    },
    "87": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #87",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "87",
                "rarity_score": 451.4961398692611,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/87.png",
            "scheme": "https"
        }
    },
    "66": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #66",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "66",
                "rarity_score": 929.05982614257,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/66.png",
            "scheme": "https"
        }
    },
    "995": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #995",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mushroom Hat",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "995",
                "rarity_score": 698.8804478208716,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/995.png",
            "scheme": "https"
        }
    },
    "610": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #610",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Baby Bell",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Cow Onesie",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Latte", "score": 1500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Blushing",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Brown", "score": 750.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Cowbell",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "610",
                "rarity_score": 9750.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/610.png",
            "scheme": "https"
        }
    },
    "1273": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1273",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1273",
                "rarity_score": 379.1023119392684,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1273.png",
            "scheme": "https"
        }
    },
    "240": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #240",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 4",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "240",
                "rarity_score": 703.5275535275536,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/240.png",
            "scheme": "https"
        }
    },
    "753": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #753",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "753",
                "rarity_score": 584.5159226738174,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/753.png",
            "scheme": "https"
        }
    },
    "303": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #303",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "303",
                "rarity_score": 469.9453043727688,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/303.png",
            "scheme": "https"
        }
    },
    "1330": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1330",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 5",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1330",
                "rarity_score": 1089.9593160462725,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1330.png",
            "scheme": "https"
        }
    },
    "883": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #883",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "883",
                "rarity_score": 530.0043706293707,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/883.png",
            "scheme": "https"
        }
    },
    "1017": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1017",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1017",
                "rarity_score": 395.99186763768296,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1017.png",
            "scheme": "https"
        }
    },
    "1447": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1447",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1447",
                "rarity_score": 477.91364734299515,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1447.png",
            "scheme": "https"
        }
    },
    "186": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #186",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "186",
                "rarity_score": 336.1788196766004,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/186.png",
            "scheme": "https"
        }
    },
    "491": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #491",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "491",
                "rarity_score": 760.050274795126,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/491.png",
            "scheme": "https"
        }
    },
    "167": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #167",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "KDA Colors 002 .PNG",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "167",
                "rarity_score": 668.6688311688312,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/167.png",
            "scheme": "https"
        }
    },
    "537": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #537",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "537",
                "rarity_score": 316.3269230769231,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/537.png",
            "scheme": "https"
        }
    },
    "29": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #29",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "29",
                "rarity_score": 471.3854169726944,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/29.png",
            "scheme": "https"
        }
    },
    "884": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #884",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "884",
                "rarity_score": 381.3991022470452,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/884.png",
            "scheme": "https"
        }
    },
    "1018": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1018",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 020",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1018",
                "rarity_score": 722.6072498929641,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1018.png",
            "scheme": "https"
        }
    },
    "185": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #185",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "185",
                "rarity_score": 395.1516819102223,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/185.png",
            "scheme": "https"
        }
    },
    "1448": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1448",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 021",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Feather Headband",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1448",
                "rarity_score": 923.1394188110027,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1448.png",
            "scheme": "https"
        }
    },
    "492": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #492",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "492",
                "rarity_score": 502.1214896214897,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/492.png",
            "scheme": "https"
        }
    },
    "168": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #168",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "168",
                "rarity_score": 338.09621229834,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/168.png",
            "scheme": "https"
        }
    },
    "538": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #538",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "538",
                "rarity_score": 756.4364207221349,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/538.png",
            "scheme": "https"
        }
    },
    "30": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #30",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "30",
                "rarity_score": 275.45121253558074,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/30.png",
            "scheme": "https"
        }
    },
    "609": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #609",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 5",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "609",
                "rarity_score": 1055.9654680637866,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/609.png",
            "scheme": "https"
        }
    },
    "1274": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1274",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1274",
                "rarity_score": 260.658501069085,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1274.png",
            "scheme": "https"
        }
    },
    "239": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #239",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 04",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "239",
                "rarity_score": 692.2846889952153,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/239.png",
            "scheme": "https"
        }
    },
    "754": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #754",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "754",
                "rarity_score": 459.29403621355516,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/754.png",
            "scheme": "https"
        }
    },
    "304": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #304",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 10",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "304",
                "rarity_score": 1714.7408637873755,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/304.png",
            "scheme": "https"
        }
    },
    "1329": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1329",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Green Cn Hood",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1329",
                "rarity_score": 726.686507936508,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1329.png",
            "scheme": "https"
        }
    },
    "580": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #580",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "580",
                "rarity_score": 410.738287250384,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/580.png",
            "scheme": "https"
        }
    },
    "88": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #88",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "88",
                "rarity_score": 511.9230769230769,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/88.png",
            "scheme": "https"
        }
    },
    "977": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #977",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "977",
                "rarity_score": 554.3970651501708,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/977.png",
            "scheme": "https"
        }
    },
    "996": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #996",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "996",
                "rarity_score": 341.89560439560444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/996.png",
            "scheme": "https"
        }
    },
    "65": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #65",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "65",
                "rarity_score": 290.6320861678005,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/65.png",
            "scheme": "https"
        }
    },
    "216": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #216",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Chicken Nuggs",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "216",
                "rarity_score": 683.9685382368309,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/216.png",
            "scheme": "https"
        }
    },
    "1221": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1221",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1221",
                "rarity_score": 417.23243752946723,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1221.png",
            "scheme": "https"
        }
    },
    "646": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #646",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "646",
                "rarity_score": 432.76397515527947,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/646.png",
            "scheme": "https"
        }
    },
    "1387": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1387",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1387",
                "rarity_score": 319.4896628794934,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1387.png",
            "scheme": "https"
        }
    },
    "1366": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1366",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 04",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1366",
                "rarity_score": 880.3724640067322,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1366.png",
            "scheme": "https"
        }
    },
    "351": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #351",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 4",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "351",
                "rarity_score": 1080.9573798627002,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/351.png",
            "scheme": "https"
        }
    },
    "701": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #701",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "701",
                "rarity_score": 431.7581723831724,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/701.png",
            "scheme": "https"
        }
    },
    "1057": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1057",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 7",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1057",
                "rarity_score": 1170.349028795327,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1057.png",
            "scheme": "https"
        }
    },
    "596": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #596",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "596",
                "rarity_score": 477.6954929128842,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/596.png",
            "scheme": "https"
        }
    },
    "1407": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1407",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mauve",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1407",
                "rarity_score": 706.7179444325353,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1407.png",
            "scheme": "https"
        }
    },
    "127": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #127",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "127",
                "rarity_score": 475.15215997968056,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/127.png",
            "scheme": "https"
        }
    },
    "577": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #577",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "577",
                "rarity_score": 378.4453971694504,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/577.png",
            "scheme": "https"
        }
    },
    "69": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #69",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "69",
                "rarity_score": 439.51234320531756,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/69.png",
            "scheme": "https"
        }
    },
    "980": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #980",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "980",
                "rarity_score": 491.9191543214472,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/980.png",
            "scheme": "https"
        }
    },
    "650": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #650",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Cn Hood",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "650",
                "rarity_score": 987.6842669525596,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/650.png",
            "scheme": "https"
        }
    },
    "1233": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1233",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "New Year - New Kadena",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fireworks",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Long Sleeve",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "KDA Green",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Toothsome",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy New Year 2024",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Kawaii K-9s 2.0",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Happy New Year Party Cap",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1233",
                "rarity_score": 12000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1233.png",
            "scheme": "https"
        }
    },
    "200": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #200",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "200",
                "rarity_score": 471.7218592218592,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/200.png",
            "scheme": "https"
        }
    },
    "713": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #713",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "713",
                "rarity_score": 509.83407382091593,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/713.png",
            "scheme": "https"
        }
    },
    "343": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #343",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "343",
                "rarity_score": 392.64939664693713,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/343.png",
            "scheme": "https"
        }
    },
    "1370": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1370",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1370",
                "rarity_score": 553.2430323299889,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1370.png",
            "scheme": "https"
        }
    },
    "189": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #189",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "189",
                "rarity_score": 458.65520349216,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/189.png",
            "scheme": "https"
        }
    },
    "892": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #892",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "892",
                "rarity_score": 546.3834776334777,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/892.png",
            "scheme": "https"
        }
    },
    "938": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #938",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "938",
                "rarity_score": 376.8580531336987,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/938.png",
            "scheme": "https"
        }
    },
    "26": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #26",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "26",
                "rarity_score": 426.091193359486,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/26.png",
            "scheme": "https"
        }
    },
    "484": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #484",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 023",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "484",
                "rarity_score": 812.1753246753248,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/484.png",
            "scheme": "https"
        }
    },
    "255": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #255",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "255",
                "rarity_score": 488.465598756925,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/255.png",
            "scheme": "https"
        }
    },
    "1262": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1262",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Sad Eyes 02",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1262",
                "rarity_score": 585.1200218221495,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1262.png",
            "scheme": "https"
        }
    },
    "605": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #605",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "605",
                "rarity_score": 639.3581959020489,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/605.png",
            "scheme": "https"
        }
    },
    "1325": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1325",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1325",
                "rarity_score": 482.0693203050043,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1325.png",
            "scheme": "https"
        }
    },
    "312": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #312",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "312",
                "rarity_score": 526.7521557117831,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/312.png",
            "scheme": "https"
        }
    },
    "742": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #742",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "742",
                "rarity_score": 488.5793554271815,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/742.png",
            "scheme": "https"
        }
    },
    "806": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #806",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "806",
                "rarity_score": 524.1637786844159,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/806.png",
            "scheme": "https"
        }
    },
    "553": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #553",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "553",
                "rarity_score": 303.6632775119617,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/553.png",
            "scheme": "https"
        }
    },
    "103": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #103",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "103",
                "rarity_score": 398.2023411371237,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/103.png",
            "scheme": "https"
        }
    },
    "1130": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1130",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1130",
                "rarity_score": 435.46766045963733,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1130.png",
            "scheme": "https"
        }
    },
    "1423": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1423",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 010",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1423",
                "rarity_score": 676.1570610066287,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1423.png",
            "scheme": "https"
        }
    },
    "410": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #410",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "410",
                "rarity_score": 552.934458789708,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/410.png",
            "scheme": "https"
        }
    },
    "1073": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1073",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1073",
                "rarity_score": 481.9238535695675,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1073.png",
            "scheme": "https"
        }
    },
    "941": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #941",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "941",
                "rarity_score": 801.5108202608202,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/941.png",
            "scheme": "https"
        }
    },
    "691": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #691",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "691",
                "rarity_score": 415.23657179057875,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/691.png",
            "scheme": "https"
        }
    },
    "367": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #367",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "367",
                "rarity_score": 460.61841238471675,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/367.png",
            "scheme": "https"
        }
    },
    "737": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #737",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 15",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "737",
                "rarity_score": 749.8757821126243,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/737.png",
            "scheme": "https"
        }
    },
    "1217": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1217",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1217",
                "rarity_score": 495.7418166939444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1217.png",
            "scheme": "https"
        }
    },
    "386": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #386",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "386",
                "rarity_score": 444.3669386169386,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/386.png",
            "scheme": "https"
        }
    },
    "1165": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1165",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 024",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1165",
                "rarity_score": 880.5325227113993,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1165.png",
            "scheme": "https"
        }
    },
    "152": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #152",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "152",
                "rarity_score": 426.0503875968992,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/152.png",
            "scheme": "https"
        }
    },
    "502": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #502",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "502",
                "rarity_score": 500.14935064935065,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/502.png",
            "scheme": "https"
        }
    },
    "849": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #849",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "849",
                "rarity_score": 459.8110411852999,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/849.png",
            "scheme": "https"
        }
    },
    "914": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #914",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 001",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "914",
                "rarity_score": 670.1923076923076,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/914.png",
            "scheme": "https"
        }
    },
    "1022": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1022",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1022",
                "rarity_score": 530.7006045846923,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1022.png",
            "scheme": "https"
        }
    },
    "1188": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1188",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1188",
                "rarity_score": 357.0877192982456,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1188.png",
            "scheme": "https"
        }
    },
    "445": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #445",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 04",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "445",
                "rarity_score": 912.5276735459662,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/445.png",
            "scheme": "https"
        }
    },
    "1472": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1472",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1472",
                "rarity_score": 441.9242424242424,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1472.png",
            "scheme": "https"
        }
    },
    "294": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #294",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "294",
                "rarity_score": 453.3279220779221,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/294.png",
            "scheme": "https"
        }
    },
    "779": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #779",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 11",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "779",
                "rarity_score": 680.3830227743271,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/779.png",
            "scheme": "https"
        }
    },
    "845": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #845",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "845",
                "rarity_score": 446.13398615114863,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/845.png",
            "scheme": "https"
        }
    },
    "514": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #514",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "514",
                "rarity_score": 523.4898456119557,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/514.png",
            "scheme": "https"
        }
    },
    "144": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #144",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "144",
                "rarity_score": 777.2502927724761,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/144.png",
            "scheme": "https"
        }
    },
    "1169": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1169",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1169",
                "rarity_score": 466.0439960497169,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1169.png",
            "scheme": "https"
        }
    },
    "1464": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1464",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1464",
                "rarity_score": 454.60171968621665,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1464.png",
            "scheme": "https"
        }
    },
    "449": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #449",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 1",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "449",
                "rarity_score": 636.7226443768998,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/449.png",
            "scheme": "https"
        }
    },
    "1034": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1034",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1034",
                "rarity_score": 556.0297148135867,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1034.png",
            "scheme": "https"
        }
    },
    "902": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #902",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "902",
                "rarity_score": 499.71104456553263,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/902.png",
            "scheme": "https"
        }
    },
    "328": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #328",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "328",
                "rarity_score": 270.373048004627,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/328.png",
            "scheme": "https"
        }
    },
    "282": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #282",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "282",
                "rarity_score": 612.1547735418703,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/282.png",
            "scheme": "https"
        }
    },
    "778": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #778",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "778",
                "rarity_score": 317.23243752946723,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/778.png",
            "scheme": "https"
        }
    },
    "795": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #795",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "795",
                "rarity_score": 930.6985998591837,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/795.png",
            "scheme": "https"
        }
    },
    "1258": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1258",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1258",
                "rarity_score": 521.0380690737834,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1258.png",
            "scheme": "https"
        }
    },
    "1126": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1126",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 06",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1126",
                "rarity_score": 768.9141331589844,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1126.png",
            "scheme": "https"
        }
    },
    "111": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #111",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "111",
                "rarity_score": 508.5309617918314,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/111.png",
            "scheme": "https"
        }
    },
    "541": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #541",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "541",
                "rarity_score": 442.0018153889122,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/541.png",
            "scheme": "https"
        }
    },
    "810": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #810",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "810",
                "rarity_score": 560.7781954887218,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/810.png",
            "scheme": "https"
        }
    },
    "953": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #953",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "953",
                "rarity_score": 397.39721485411144,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/953.png",
            "scheme": "https"
        }
    },
    "1061": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1061",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1061",
                "rarity_score": 302.97204256876967,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1061.png",
            "scheme": "https"
        }
    },
    "406": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #406",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "406",
                "rarity_score": 341.99421849175894,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/406.png",
            "scheme": "https"
        }
    },
    "1431": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1431",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1431",
                "rarity_score": 719.3104740787668,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1431.png",
            "scheme": "https"
        }
    },
    "683": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #683",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "683",
                "rarity_score": 464.27272727272725,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/683.png",
            "scheme": "https"
        }
    },
    "390": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #390",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "390",
                "rarity_score": 467.9040419000226,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/390.png",
            "scheme": "https"
        }
    },
    "1278": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1278",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 4",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1278",
                "rarity_score": 1055.283562801013,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1278.png",
            "scheme": "https"
        }
    },
    "308": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #308",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "308",
                "rarity_score": 519.3990220306009,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/308.png",
            "scheme": "https"
        }
    },
    "1295": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1295",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1295",
                "rarity_score": 321.11801242236027,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1295.png",
            "scheme": "https"
        }
    },
    "758": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #758",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "758",
                "rarity_score": 398.77137063834755,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/758.png",
            "scheme": "https"
        }
    },
    "1444": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1444",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1444",
                "rarity_score": 446.84995855208626,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1444.png",
            "scheme": "https"
        }
    },
    "469": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #469",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "469",
                "rarity_score": 478.75869451191005,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/469.png",
            "scheme": "https"
        }
    },
    "1014": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1014",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 3",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 02",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1014",
                "rarity_score": 1152.1795816563688,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1014.png",
            "scheme": "https"
        }
    },
    "888": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #888",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "888",
                "rarity_score": 325.8491508491509,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/888.png",
            "scheme": "https"
        }
    },
    "922": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #922",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "922",
                "rarity_score": 569.449855378732,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/922.png",
            "scheme": "https"
        }
    },
    "865": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #865",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 8",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "865",
                "rarity_score": 1062.5382775119617,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/865.png",
            "scheme": "https"
        }
    },
    "534": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #534",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "534",
                "rarity_score": 358.44890510948903,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/534.png",
            "scheme": "https"
        }
    },
    "1499": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1499",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Mr Moneybags",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cityscape",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tux",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Greenback",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Show me the money!",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Hazel", "score": 500.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Money Tie",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Money Top Hat",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1499",
                "rarity_score": 11000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1499.png",
            "scheme": "https"
        }
    },
    "164": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #164",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 6",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 10",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "164",
                "rarity_score": 1273.1815363513383,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/164.png",
            "scheme": "https"
        }
    },
    "1149": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1149",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1149",
                "rarity_score": 345.59800664451825,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1149.png",
            "scheme": "https"
        }
    },
    "1383": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1383",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1383",
                "rarity_score": 539.9603991062145,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1383.png",
            "scheme": "https"
        }
    },
    "973": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #973",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "973",
                "rarity_score": 495.3237826566068,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/973.png",
            "scheme": "https"
        }
    },
    "0": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #0",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "The King",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Green Castle",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sweater",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Brown", "score": 1500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Gansta",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Scar", "score": 1500.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "KDA Diamond Chain",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Golden Crown",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "0",
                "rarity_score": 12000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/0.png",
            "scheme": "https"
        }
    },
    "84": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #84",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "84",
                "rarity_score": 382.9271129328338,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/84.png",
            "scheme": "https"
        }
    },
    "1041": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1041",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1041",
                "rarity_score": 519.1748248522894,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1041.png",
            "scheme": "https"
        }
    },
    "426": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #426",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "426",
                "rarity_score": 361.75961538461536,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/426.png",
            "scheme": "https"
        }
    },
    "1411": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1411",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1411",
                "rarity_score": 507.421380154152,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1411.png",
            "scheme": "https"
        }
    },
    "1106": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1106",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1106",
                "rarity_score": 485.6866059022153,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1106.png",
            "scheme": "https"
        }
    },
    "131": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #131",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "131",
                "rarity_score": 345.72213500784926,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/131.png",
            "scheme": "https"
        }
    },
    "561": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #561",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "561",
                "rarity_score": 385.53697178409095,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/561.png",
            "scheme": "https"
        }
    },
    "830": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #830",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "830",
                "rarity_score": 709.0899537620849,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/830.png",
            "scheme": "https"
        }
    },
    "1237": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1237",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1237",
                "rarity_score": 630.9462949331371,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1237.png",
            "scheme": "https"
        }
    },
    "1391": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1391",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 14",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 02",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1391",
                "rarity_score": 928.5726489253609,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1391.png",
            "scheme": "https"
        }
    },
    "347": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #347",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "347",
                "rarity_score": 388.58175577283123,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/347.png",
            "scheme": "https"
        }
    },
    "717": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #717",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "717",
                "rarity_score": 470.83534816247584,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/717.png",
            "scheme": "https"
        }
    },
    "1403": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1403",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1403",
                "rarity_score": 572.2762744501875,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1403.png",
            "scheme": "https"
        }
    },
    "430": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #430",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 12",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "430",
                "rarity_score": 694.7471935958778,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/430.png",
            "scheme": "https"
        }
    },
    "1053": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1053",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1053",
                "rarity_score": 494.96344616481923,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1053.png",
            "scheme": "https"
        }
    },
    "92": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #92",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "92",
                "rarity_score": 475.68813413443235,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/92.png",
            "scheme": "https"
        }
    },
    "961": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #961",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 5",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 08",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "961",
                "rarity_score": 1202.5175828532508,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/961.png",
            "scheme": "https"
        }
    },
    "826": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #826",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "826",
                "rarity_score": 509.64127737620026,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/826.png",
            "scheme": "https"
        }
    },
    "573": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #573",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "573",
                "rarity_score": 444.05594405594405,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/573.png",
            "scheme": "https"
        }
    },
    "123": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #123",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 04",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "123",
                "rarity_score": 732.1816770186335,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/123.png",
            "scheme": "https"
        }
    },
    "1110": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1110",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1110",
                "rarity_score": 381.5252139620742,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1110.png",
            "scheme": "https"
        }
    },
    "1279": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1279",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1279",
                "rarity_score": 567.1466214944476,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1279.png",
            "scheme": "https"
        }
    },
    "934": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #934",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "934",
                "rarity_score": 440.78787878787875,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/934.png",
            "scheme": "https"
        }
    },
    "1002": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1002",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1002",
                "rarity_score": 442.37511153987134,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1002.png",
            "scheme": "https"
        }
    },
    "465": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #465",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "465",
                "rarity_score": 494.20937119146834,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/465.png",
            "scheme": "https"
        }
    },
    "1452": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1452",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1452",
                "rarity_score": 628.7848031192838,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1452.png",
            "scheme": "https"
        }
    },
    "488": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #488",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 8",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "488",
                "rarity_score": 1039.6507064364207,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/488.png",
            "scheme": "https"
        }
    },
    "1145": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1145",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1145",
                "rarity_score": 650.3425184017464,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1145.png",
            "scheme": "https"
        }
    },
    "172": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #172",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "172",
                "rarity_score": 418.0382879315806,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/172.png",
            "scheme": "https"
        }
    },
    "522": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #522",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "522",
                "rarity_score": 397.42757242757244,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/522.png",
            "scheme": "https"
        }
    },
    "869": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #869",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "869",
                "rarity_score": 526.3846447669978,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/869.png",
            "scheme": "https"
        }
    },
    "733": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #733",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Chicken Nuggs",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "733",
                "rarity_score": 528.2388663967612,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/733.png",
            "scheme": "https"
        }
    },
    "363": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #363",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "363",
                "rarity_score": 495.13257575757575,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/363.png",
            "scheme": "https"
        }
    },
    "1350": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1350",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1350",
                "rarity_score": 332.3120957989379,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1350.png",
            "scheme": "https"
        }
    },
    "670": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #670",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "670",
                "rarity_score": 578.6082132803444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/670.png",
            "scheme": "https"
        }
    },
    "1213": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1213",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1213",
                "rarity_score": 340.53780321061424,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1213.png",
            "scheme": "https"
        }
    },
    "220": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #220",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "220",
                "rarity_score": 542.3295454545455,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/220.png",
            "scheme": "https"
        }
    },
    "107": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #107",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "107",
                "rarity_score": 366.89560439560444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/107.png",
            "scheme": "https"
        }
    },
    "557": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #557",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "557",
                "rarity_score": 359.21788737578214,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/557.png",
            "scheme": "https"
        }
    },
    "1096": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1096",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1096",
                "rarity_score": 430.4470597153524,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1096.png",
            "scheme": "https"
        }
    },
    "49": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #49",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 06",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "49",
                "rarity_score": 1892.016993128094,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/49.png",
            "scheme": "https"
        }
    },
    "1077": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1077",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1077",
                "rarity_score": 627.2173913043478,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1077.png",
            "scheme": "https"
        }
    },
    "1427": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1427",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1427",
                "rarity_score": 418.94715685774077,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1427.png",
            "scheme": "https"
        }
    },
    "1305": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1305",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1305",
                "rarity_score": 295.69154661367776,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1305.png",
            "scheme": "https"
        }
    },
    "332": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #332",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "332",
                "rarity_score": 401.0749067935496,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/332.png",
            "scheme": "https"
        }
    },
    "298": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #298",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "298",
                "rarity_score": 525.5528891398455,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/298.png",
            "scheme": "https"
        }
    },
    "762": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #762",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "762",
                "rarity_score": 368.786231884058,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/762.png",
            "scheme": "https"
        }
    },
    "275": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #275",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "275",
                "rarity_score": 405.6363897822052,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/275.png",
            "scheme": "https"
        }
    },
    "1242": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1242",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Suit",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1242",
                "rarity_score": 700.9248398904492,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1242.png",
            "scheme": "https"
        }
    },
    "625": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #625",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 03",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "625",
                "rarity_score": 473.3118145757175,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/625.png",
            "scheme": "https"
        }
    },
    "1489": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1489",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1489",
                "rarity_score": 340.976578976579,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1489.png",
            "scheme": "https"
        }
    },
    "1184": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1184",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1184",
                "rarity_score": 559.7872670807453,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1184.png",
            "scheme": "https"
        }
    },
    "918": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #918",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "918",
                "rarity_score": 352.0528043385186,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/918.png",
            "scheme": "https"
        }
    },
    "774": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #774",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "774",
                "rarity_score": 611.3346602750842,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/774.png",
            "scheme": "https"
        }
    },
    "324": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #324",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "324",
                "rarity_score": 441.65151214901306,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/324.png",
            "scheme": "https"
        }
    },
    "1309": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1309",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1309",
                "rarity_score": 433.4540459540459,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1309.png",
            "scheme": "https"
        }
    },
    "629": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #629",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "629",
                "rarity_score": 630.5845678552818,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/629.png",
            "scheme": "https"
        }
    },
    "1254": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1254",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 7",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Red Toupee",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1254",
                "rarity_score": 2102.3240243465807,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1254.png",
            "scheme": "https"
        }
    },
    "259": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #259",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "259",
                "rarity_score": 413.1655844155844,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/259.png",
            "scheme": "https"
        }
    },
    "1485": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1485",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1485",
                "rarity_score": 843.1487527427462,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1485.png",
            "scheme": "https"
        }
    },
    "148": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #148",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Chicken Nuggs",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "148",
                "rarity_score": 732.2621457489879,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/148.png",
            "scheme": "https"
        }
    },
    "518": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #518",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 7",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "518",
                "rarity_score": 514.6616541353383,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/518.png",
            "scheme": "https"
        }
    },
    "10": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #10",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 02",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "10",
                "rarity_score": 675.3737445486983,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/10.png",
            "scheme": "https"
        }
    },
    "1038": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1038",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1038",
                "rarity_score": 482.67613237826,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1038.png",
            "scheme": "https"
        }
    },
    "1192": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1192",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 04",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1192",
                "rarity_score": 594.9601808006887,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1192.png",
            "scheme": "https"
        }
    },
    "1468": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1468",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1468",
                "rarity_score": 337.2646945831733,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1468.png",
            "scheme": "https"
        }
    },
    "1346": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1346",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 04",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1346",
                "rarity_score": 645.6665321704585,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1346.png",
            "scheme": "https"
        }
    },
    "687": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #687",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "687",
                "rarity_score": 419.1467718816947,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/687.png",
            "scheme": "https"
        }
    },
    "371": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #371",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 3",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "371",
                "rarity_score": 778.1401098901099,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/371.png",
            "scheme": "https"
        }
    },
    "721": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #721",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 2",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "721",
                "rarity_score": 1758.1748035111996,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/721.png",
            "scheme": "https"
        }
    },
    "236": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #236",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "236",
                "rarity_score": 352.0222832722833,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/236.png",
            "scheme": "https"
        }
    },
    "1201": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1201",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1201",
                "rarity_score": 348.46288242072785,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1201.png",
            "scheme": "https"
        }
    },
    "666": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #666",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 001",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "666",
                "rarity_score": 837.7422402159243,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/666.png",
            "scheme": "https"
        }
    },
    "45": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #45",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "45",
                "rarity_score": 500.22466568519195,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/45.png",
            "scheme": "https"
        }
    },
    "1080": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1080",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1080",
                "rarity_score": 550.393880468679,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1080.png",
            "scheme": "https"
        }
    },
    "957": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #957",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "957",
                "rarity_score": 527.9368027416616,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/957.png",
            "scheme": "https"
        }
    },
    "1294": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1294",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Suit",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1294",
                "rarity_score": 900.5628881987577,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1294.png",
            "scheme": "https"
        }
    },
    "535": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #535",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 7",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "535",
                "rarity_score": 963.4822270507057,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/535.png",
            "scheme": "https"
        }
    },
    "165": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #165",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "165",
                "rarity_score": 368.3495423340961,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/165.png",
            "scheme": "https"
        }
    },
    "1152": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1152",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 013",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue $kda",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1152",
                "rarity_score": 746.7318272554652,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1152.png",
            "scheme": "https"
        }
    },
    "864": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #864",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 2",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Green Eyes 01",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "864",
                "rarity_score": 1520.6631088210033,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/864.png",
            "scheme": "https"
        }
    },
    "919": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #919",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Corona Inu",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "City Background",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Corona Inu With Mask",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Corona Inu",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "919",
                "rarity_score": 6249.083402473233,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/919.png",
            "scheme": "https"
        }
    },
    "1445": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1445",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1445",
                "rarity_score": 595.4328714763496,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1445.png",
            "scheme": "https"
        }
    },
    "188": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #188",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "188",
                "rarity_score": 542.4524246245558,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/188.png",
            "scheme": "https"
        }
    },
    "472": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #472",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 04",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "472",
                "rarity_score": 548.229606863875,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/472.png",
            "scheme": "https"
        }
    },
    "1015": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1015",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1015",
                "rarity_score": 509.07973259802526,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1015.png",
            "scheme": "https"
        }
    },
    "1367": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1367",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1367",
                "rarity_score": 597.8530074357515,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1367.png",
            "scheme": "https"
        }
    },
    "1386": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1386",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Green Cn Hood",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1386",
                "rarity_score": 666.6311282157964,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1386.png",
            "scheme": "https"
        }
    },
    "647": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #647",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "647",
                "rarity_score": 451.2989426032904,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/647.png",
            "scheme": "https"
        }
    },
    "217": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #217",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 7",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "217",
                "rarity_score": 992.7496274217586,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/217.png",
            "scheme": "https"
        }
    },
    "831": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #831",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "831",
                "rarity_score": 492.6288708063353,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/831.png",
            "scheme": "https"
        }
    },
    "68": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #68",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "68",
                "rarity_score": 330.95404595404597,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/68.png",
            "scheme": "https"
        }
    },
    "997": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #997",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 5",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "997",
                "rarity_score": 779.1155878827502,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/997.png",
            "scheme": "https"
        }
    },
    "1103": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1103",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 9",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1103",
                "rarity_score": 610.0903192509031,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1103.png",
            "scheme": "https"
        }
    },
    "130": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #130",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "130",
                "rarity_score": 409.94431808943943,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/130.png",
            "scheme": "https"
        }
    },
    "560": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #560",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "560",
                "rarity_score": 554.1471571906354,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/560.png",
            "scheme": "https"
        }
    },
    "1040": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1040",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1040",
                "rarity_score": 376.24066019850557,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1040.png",
            "scheme": "https"
        }
    },
    "423": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #423",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "423",
                "rarity_score": 424.4859307359307,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/423.png",
            "scheme": "https"
        }
    },
    "1410": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1410",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1410",
                "rarity_score": 479.99617049617046,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1410.png",
            "scheme": "https"
        }
    },
    "976": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #976",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "976",
                "rarity_score": 436.54312411463445,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/976.png",
            "scheme": "https"
        }
    },
    "1": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1",
                "rarity_score": 510.1137205571993,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1.png",
            "scheme": "https"
        }
    },
    "85": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #85",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "85",
                "rarity_score": 528.6672978278817,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/85.png",
            "scheme": "https"
        }
    },
    "1390": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1390",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1390",
                "rarity_score": 439.46652397739354,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1390.png",
            "scheme": "https"
        }
    },
    "576": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #576",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "576",
                "rarity_score": 525.608977311105,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/576.png",
            "scheme": "https"
        }
    },
    "126": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #126",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "126",
                "rarity_score": 336.4138203716658,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/126.png",
            "scheme": "https"
        }
    },
    "1111": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1111",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1111",
                "rarity_score": 496.48419247270715,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1111.png",
            "scheme": "https"
        }
    },
    "823": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #823",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "823",
                "rarity_score": 464.64016340996864,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/823.png",
            "scheme": "https"
        }
    },
    "89": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #89",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey Nighvision",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "89",
                "rarity_score": 775.1010260231571,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/89.png",
            "scheme": "https"
        }
    },
    "960": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #960",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "960",
                "rarity_score": 498.6638361638361,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/960.png",
            "scheme": "https"
        }
    },
    "1406": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1406",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 10",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Strawberry Milk",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1406",
                "rarity_score": 1343.3015754737066,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1406.png",
            "scheme": "https"
        }
    },
    "431": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #431",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "431",
                "rarity_score": 279.9906601985056,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/431.png",
            "scheme": "https"
        }
    },
    "597": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #597",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "597",
                "rarity_score": 438.7153072531836,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/597.png",
            "scheme": "https"
        }
    },
    "1056": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1056",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1056",
                "rarity_score": 504.92328709541823,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1056.png",
            "scheme": "https"
        }
    },
    "1282": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1282",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1282",
                "rarity_score": 483.33005442979874,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1282.png",
            "scheme": "https"
        }
    },
    "1328": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1328",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1328",
                "rarity_score": 606.8090774771447,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1328.png",
            "scheme": "https"
        }
    },
    "608": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #608",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "608",
                "rarity_score": 440.2816695499622,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/608.png",
            "scheme": "https"
        }
    },
    "258": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #258",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "258",
                "rarity_score": 366.1116367423576,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/258.png",
            "scheme": "https"
        }
    },
    "872": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #872",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "872",
                "rarity_score": 343.55760076166297,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/872.png",
            "scheme": "https"
        }
    },
    "27": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #27",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 9",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "27",
                "rarity_score": 694.2727534185688,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/27.png",
            "scheme": "https"
        }
    },
    "1144": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1144",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1144",
                "rarity_score": 469.23407083678273,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1144.png",
            "scheme": "https"
        }
    },
    "169": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #169",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "169",
                "rarity_score": 448.39996210685865,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/169.png",
            "scheme": "https"
        }
    },
    "519": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #519",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "519",
                "rarity_score": 572.5198412698412,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/519.png",
            "scheme": "https"
        }
    },
    "999": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #999",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "999",
                "rarity_score": 458.1476968282776,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/999.png",
            "scheme": "https"
        }
    },
    "464": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #464",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "464",
                "rarity_score": 398.3923035250313,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/464.png",
            "scheme": "https"
        }
    },
    "1449": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1449",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1449",
                "rarity_score": 396.53080386365235,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1449.png",
            "scheme": "https"
        }
    },
    "935": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #935",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "935",
                "rarity_score": 443.0565196998123,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/935.png",
            "scheme": "https"
        }
    },
    "387": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #387",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 1",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "387",
                "rarity_score": 1115.0141687511043,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/387.png",
            "scheme": "https"
        }
    },
    "671": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #671",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "671",
                "rarity_score": 465.7013248471402,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/671.png",
            "scheme": "https"
        }
    },
    "1216": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1216",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mauve",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1216",
                "rarity_score": 713.2111074461473,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1216.png",
            "scheme": "https"
        }
    },
    "221": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #221",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 07",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "221",
                "rarity_score": 633.8586238323081,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/221.png",
            "scheme": "https"
        }
    },
    "736": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #736",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "736",
                "rarity_score": 579.5111900294827,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/736.png",
            "scheme": "https"
        }
    },
    "366": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #366",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "366",
                "rarity_score": 628.0192562568435,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/366.png",
            "scheme": "https"
        }
    },
    "1351": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1351",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1351",
                "rarity_score": 655.2039337474121,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1351.png",
            "scheme": "https"
        }
    },
    "807": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #807",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 2",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "807",
                "rarity_score": 907.016421802136,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/807.png",
            "scheme": "https"
        }
    },
    "52": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #52",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "52",
                "rarity_score": 443.9444176513142,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/52.png",
            "scheme": "https"
        }
    },
    "1093": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1093",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1093",
                "rarity_score": 419.34343434343435,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1093.png",
            "scheme": "https"
        }
    },
    "274": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #274",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "274",
                "rarity_score": 329.2979550953596,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/274.png",
            "scheme": "https"
        }
    },
    "1239": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1239",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1239",
                "rarity_score": 576.376536625176,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1239.png",
            "scheme": "https"
        }
    },
    "624": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #624",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "624",
                "rarity_score": 544.3709609230144,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/624.png",
            "scheme": "https"
        }
    },
    "1304": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1304",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "KDA Colors 002 .PNG",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1304",
                "rarity_score": 790.1957215172318,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1304.png",
            "scheme": "https"
        }
    },
    "329": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #329",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Sad Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "329",
                "rarity_score": 610.3949968795507,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/329.png",
            "scheme": "https"
        }
    },
    "759": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #759",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "759",
                "rarity_score": 497.07123729455583,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/759.png",
            "scheme": "https"
        }
    },
    "448": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #448",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "448",
                "rarity_score": 377.4941221883005,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/448.png",
            "scheme": "https"
        }
    },
    "1185": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1185",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 03",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1185",
                "rarity_score": 619.23813891027,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1185.png",
            "scheme": "https"
        }
    },
    "1492": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1492",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1492",
                "rarity_score": 383.0337183308495,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1492.png",
            "scheme": "https"
        }
    },
    "1168": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1168",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1168",
                "rarity_score": 457.76084408730156,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1168.png",
            "scheme": "https"
        }
    },
    "632": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #632",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 5",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 01",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "632",
                "rarity_score": 1233.268556005398,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/632.png",
            "scheme": "https"
        }
    },
    "1255": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1255",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1255",
                "rarity_score": 452.60897435897436,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1255.png",
            "scheme": "https"
        }
    },
    "798": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #798",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "798",
                "rarity_score": 465.8602366193243,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/798.png",
            "scheme": "https"
        }
    },
    "262": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #262",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "262",
                "rarity_score": 287.6762627191996,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/262.png",
            "scheme": "https"
        }
    },
    "775": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #775",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "775",
                "rarity_score": 290.22562582345194,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/775.png",
            "scheme": "https"
        }
    },
    "325": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #325",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "325",
                "rarity_score": 423.1073274551535,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/325.png",
            "scheme": "https"
        }
    },
    "1312": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1312",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1312",
                "rarity_score": 477.7406624520032,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1312.png",
            "scheme": "https"
        }
    },
    "1189": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1189",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1189",
                "rarity_score": 426.75585284280936,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1189.png",
            "scheme": "https"
        }
    },
    "848": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #848",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 015",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "848",
                "rarity_score": 434.3645484949833,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/848.png",
            "scheme": "https"
        }
    },
    "11": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #11",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 3",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "11",
                "rarity_score": 595.2208066048943,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/11.png",
            "scheme": "https"
        }
    },
    "1484": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1484",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 023",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1484",
                "rarity_score": 571.4155844155844,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1484.png",
            "scheme": "https"
        }
    },
    "233": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #233",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "233",
                "rarity_score": 475.21994735572054,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/233.png",
            "scheme": "https"
        }
    },
    "1200": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1200",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Blue Sad Eyes 01",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1200",
                "rarity_score": 737.0088916459883,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1200.png",
            "scheme": "https"
        }
    },
    "663": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #663",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "663",
                "rarity_score": 377.22540617277457,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/663.png",
            "scheme": "https"
        }
    },
    "1343": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1343",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1343",
                "rarity_score": 407.2985207206518,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1343.png",
            "scheme": "https"
        }
    },
    "370": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #370",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "370",
                "rarity_score": 410.2822163849226,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/370.png",
            "scheme": "https"
        }
    },
    "720": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #720",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "720",
                "rarity_score": 339.7173913043478,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/720.png",
            "scheme": "https"
        }
    },
    "407": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #407",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "407",
                "rarity_score": 466.7195440597424,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/407.png",
            "scheme": "https"
        }
    },
    "1081": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1081",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Medium Side",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Red Lazers",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Sithy",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Charcoal",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Shocked",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Hollow", "score": 1500.0 }
                ],
                "compiler": "nft-inator.com",
                "id": "1081",
                "rarity_score": 9000.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1081.png",
            "scheme": "https"
        }
    },
    "1127": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1127",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1127",
                "rarity_score": 367.1507064364207,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1127.png",
            "scheme": "https"
        }
    },
    "44": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #44",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 7",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "44",
                "rarity_score": 654.908906882591,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/44.png",
            "scheme": "https"
        }
    },
    "868": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #868",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "868",
                "rarity_score": 496.91298667575506,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/868.png",
            "scheme": "https"
        }
    },
    "31": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #31",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "31",
                "rarity_score": 379.5622125141576,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/31.png",
            "scheme": "https"
        }
    },
    "489": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #489",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "489",
                "rarity_score": 516.2069602460444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/489.png",
            "scheme": "https"
        }
    },
    "184": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #184",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "184",
                "rarity_score": 362.95211023769417,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/184.png",
            "scheme": "https"
        }
    },
    "885": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #885",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "885",
                "rarity_score": 674.3887691256112,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/885.png",
            "scheme": "https"
        }
    },
    "755": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #755",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "755",
                "rarity_score": 453.69980787841655,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/755.png",
            "scheme": "https"
        }
    },
    "1298": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1298",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1298",
                "rarity_score": 448.79792774224234,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1298.png",
            "scheme": "https"
        }
    },
    "305": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #305",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "305",
                "rarity_score": 309.6427400102205,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/305.png",
            "scheme": "https"
        }
    },
    "1332": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1332",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1332",
                "rarity_score": 571.1309523809524,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1332.png",
            "scheme": "https"
        }
    },
    "612": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #612",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "612",
                "rarity_score": 457.0079381753553,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/612.png",
            "scheme": "https"
        }
    },
    "1275": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1275",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1275",
                "rarity_score": 424.87177940857526,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1275.png",
            "scheme": "https"
        }
    },
    "242": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #242",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 3",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "242",
                "rarity_score": 489.4736842105263,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/242.png",
            "scheme": "https"
        }
    },
    "1107": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1107",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Feather Headband",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1107",
                "rarity_score": 781.6005081874647,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1107.png",
            "scheme": "https"
        }
    },
    "993": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #993",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 02",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "993",
                "rarity_score": 752.2482269503546,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/993.png",
            "scheme": "https"
        }
    },
    "64": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #64",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "64",
                "rarity_score": 424.155705989101,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/64.png",
            "scheme": "https"
        }
    },
    "427": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #427",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 1",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "427",
                "rarity_score": 791.8595753398245,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/427.png",
            "scheme": "https"
        }
    },
    "581": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #581",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "581",
                "rarity_score": 793.3883622098724,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/581.png",
            "scheme": "https"
        }
    },
    "1363": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1363",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1363",
                "rarity_score": 388.54321802000504,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1363.png",
            "scheme": "https"
        }
    },
    "350": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #350",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "350",
                "rarity_score": 474.06267415189853,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/350.png",
            "scheme": "https"
        }
    },
    "700": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #700",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 3",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "700",
                "rarity_score": 1041.791550371805,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/700.png",
            "scheme": "https"
        }
    },
    "213": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #213",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "213",
                "rarity_score": 493.1977494147075,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/213.png",
            "scheme": "https"
        }
    },
    "1220": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1220",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1220",
                "rarity_score": 737.3093443307093,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1220.png",
            "scheme": "https"
        }
    },
    "643": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #643",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 02",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "643",
                "rarity_score": 474.6500377364529,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/643.png",
            "scheme": "https"
        }
    },
    "827": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #827",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Chicken Nuggs",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "827",
                "rarity_score": 767.5865989608576,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/827.png",
            "scheme": "https"
        }
    },
    "72": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #72",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "72",
                "rarity_score": 466.3820310124658,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/72.png",
            "scheme": "https"
        }
    },
    "981": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #981",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "981",
                "rarity_score": 490.9160442913303,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/981.png",
            "scheme": "https"
        }
    },
    "593": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #593",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "593",
                "rarity_score": 880.0357142857142,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/593.png",
            "scheme": "https"
        }
    },
    "716": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #716",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "716",
                "rarity_score": 428.1294571764869,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/716.png",
            "scheme": "https"
        }
    },
    "346": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #346",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 1",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "346",
                "rarity_score": 689.907044175337,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/346.png",
            "scheme": "https"
        }
    },
    "1371": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1371",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1371",
                "rarity_score": 558.8147470639044,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1371.png",
            "scheme": "https"
        }
    },
    "651": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #651",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "651",
                "rarity_score": 495.4271991911021,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/651.png",
            "scheme": "https"
        }
    },
    "1236": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1236",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 015",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Chicken Nuggs",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 01",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "1236",
                "rarity_score": 1343.112244897959,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1236.png",
            "scheme": "https"
        }
    },
    "201": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #201",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kda Hood",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "201",
                "rarity_score": 1483.1613593627321,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/201.png",
            "scheme": "https"
        }
    },
    "1148": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1148",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Chicken Nuggs",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1148",
                "rarity_score": 692.7670869276709,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1148.png",
            "scheme": "https"
        }
    },
    "485": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #485",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 5",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "485",
                "rarity_score": 1065.967622773222,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/485.png",
            "scheme": "https"
        }
    },
    "23": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #23",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "23",
                "rarity_score": 349.54256270045744,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/23.png",
            "scheme": "https"
        }
    },
    "889": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #889",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "889",
                "rarity_score": 860.3666858592967,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/889.png",
            "scheme": "https"
        }
    },
    "192": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #192",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "192",
                "rarity_score": 347.78485612536406,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/192.png",
            "scheme": "https"
        }
    },
    "468": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #468",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "468",
                "rarity_score": 550.4011553273428,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/468.png",
            "scheme": "https"
        }
    },
    "1324": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1324",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1324",
                "rarity_score": 420.53121829592124,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1324.png",
            "scheme": "https"
        }
    },
    "309": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #309",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "309",
                "rarity_score": 429.4388944388944,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/309.png",
            "scheme": "https"
        }
    },
    "739": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #739",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "739",
                "rarity_score": 499.7271867430198,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/739.png",
            "scheme": "https"
        }
    },
    "254": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #254",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "254",
                "rarity_score": 454.28880817863865,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/254.png",
            "scheme": "https"
        }
    },
    "1259": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1259",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 9",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1259",
                "rarity_score": 631.0485114037607,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1259.png",
            "scheme": "https"
        }
    },
    "604": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #604",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "604",
                "rarity_score": 275.1275835738818,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/604.png",
            "scheme": "https"
        }
    },
    "940": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #940",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "940",
                "rarity_score": 340.1359910581222,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/940.png",
            "scheme": "https"
        }
    },
    "1426": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1426",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1426",
                "rarity_score": 333.9823220258003,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1426.png",
            "scheme": "https"
        }
    },
    "411": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #411",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 3",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "411",
                "rarity_score": 462.6447323168634,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/411.png",
            "scheme": "https"
        }
    },
    "1076": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1076",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1076",
                "rarity_score": 394.55796310791686,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1076.png",
            "scheme": "https"
        }
    },
    "1097": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1097",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1097",
                "rarity_score": 271.09807485747336,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1097.png",
            "scheme": "https"
        }
    },
    "556": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #556",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "556",
                "rarity_score": 389.4721350078493,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/556.png",
            "scheme": "https"
        }
    },
    "106": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #106",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "106",
                "rarity_score": 445.5531282687377,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/106.png",
            "scheme": "https"
        }
    },
    "1131": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1131",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1131",
                "rarity_score": 504.21454512979284,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1131.png",
            "scheme": "https"
        }
    },
    "803": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #803",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 01",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "803",
                "rarity_score": 700.333850931677,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/803.png",
            "scheme": "https"
        }
    },
    "383": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #383",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "383",
                "rarity_score": 573.3695652173913,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/383.png",
            "scheme": "https"
        }
    },
    "690": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #690",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "690",
                "rarity_score": 547.13974152414,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/690.png",
            "scheme": "https"
        }
    },
    "1019": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1019",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1019",
                "rarity_score": 347.2032972748076,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1019.png",
            "scheme": "https"
        }
    },
    "444": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #444",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "444",
                "rarity_score": 581.879641040225,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/444.png",
            "scheme": "https"
        }
    },
    "1469": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1469",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1469",
                "rarity_score": 371.9822669515764,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1469.png",
            "scheme": "https"
        }
    },
    "915": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #915",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "915",
                "rarity_score": 369.9148823178416,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/915.png",
            "scheme": "https"
        }
    },
    "852": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #852",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue $kda",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "852",
                "rarity_score": 654.7504750475048,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/852.png",
            "scheme": "https"
        }
    },
    "1164": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1164",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1164",
                "rarity_score": 607.34160372559,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1164.png",
            "scheme": "https"
        }
    },
    "149": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #149",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 04",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "149",
                "rarity_score": 911.7269001490314,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/149.png",
            "scheme": "https"
        }
    },
    "499": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #499",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "499",
                "rarity_score": 500.4778265213048,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/499.png",
            "scheme": "https"
        }
    },
    "628": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #628",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 01",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "628",
                "rarity_score": 696.8774618299134,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/628.png",
            "scheme": "https"
        }
    },
    "782": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #782",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "782",
                "rarity_score": 848.3904442383873,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/782.png",
            "scheme": "https"
        }
    },
    "278": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #278",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 015",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "278",
                "rarity_score": 631.6243906469471,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/278.png",
            "scheme": "https"
        }
    },
    "295": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #295",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "295",
                "rarity_score": 281.4836003051106,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/295.png",
            "scheme": "https"
        }
    },
    "1308": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1308",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1308",
                "rarity_score": 508.75054896794023,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1308.png",
            "scheme": "https"
        }
    },
    "899": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #899",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 15",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "899",
                "rarity_score": 799.5289287047406,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/899.png",
            "scheme": "https"
        }
    },
    "1465": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1465",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 6",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1465",
                "rarity_score": 765.4053612291599,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1465.png",
            "scheme": "https"
        }
    },
    "452": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #452",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "452",
                "rarity_score": 499.7950819672131,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/452.png",
            "scheme": "https"
        }
    },
    "1035": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1035",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1035",
                "rarity_score": 329.80304719435156,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1035.png",
            "scheme": "https"
        }
    },
    "515": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #515",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pink Cn Hood",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "515",
                "rarity_score": 1098.931989063568,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/515.png",
            "scheme": "https"
        }
    },
    "145": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #145",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "145",
                "rarity_score": 314.38125734645575,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/145.png",
            "scheme": "https"
        }
    },
    "1488": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1488",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1488",
                "rarity_score": 401.0850847193529,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1488.png",
            "scheme": "https"
        }
    },
    "1172": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1172",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1172",
                "rarity_score": 464.18198781625597,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1172.png",
            "scheme": "https"
        }
    },
    "844": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #844",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hon",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "844",
                "rarity_score": 624.2935635792778,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/844.png",
            "scheme": "https"
        }
    },
    "794": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #794",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "794",
                "rarity_score": 544.7651515151515,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/794.png",
            "scheme": "https"
        }
    },
    "279": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #279",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "279",
                "rarity_score": 502.43414918414913,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/279.png",
            "scheme": "https"
        }
    },
    "1060": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1060",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1060",
                "rarity_score": 518.1891422417245,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1060.png",
            "scheme": "https"
        }
    },
    "403": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #403",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "403",
                "rarity_score": 397.2960372960373,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/403.png",
            "scheme": "https"
        }
    },
    "1430": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1430",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 5",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1430",
                "rarity_score": 637.5115321836633,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1430.png",
            "scheme": "https"
        }
    },
    "956": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #956",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 5",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Towel",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "956",
                "rarity_score": 3305.634098044812,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/956.png",
            "scheme": "https"
        }
    },
    "811": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #811",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Green Cn Hood",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "811",
                "rarity_score": 764.8215127649914,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/811.png",
            "scheme": "https"
        }
    },
    "48": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #48",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 04",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "48",
                "rarity_score": 941.306763970756,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/48.png",
            "scheme": "https"
        }
    },
    "1123": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1123",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1123",
                "rarity_score": 425.35425101214577,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1123.png",
            "scheme": "https"
        }
    },
    "110": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #110",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "110",
                "rarity_score": 506.29492578060973,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/110.png",
            "scheme": "https"
        }
    },
    "540": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #540",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "540",
                "rarity_score": 382.7445652173913,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/540.png",
            "scheme": "https"
        }
    },
    "391": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #391",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 6",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "391",
                "rarity_score": 2013.95235199583,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/391.png",
            "scheme": "https"
        }
    },
    "667": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #667",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 3",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "667",
                "rarity_score": 805.1338737410956,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/667.png",
            "scheme": "https"
        }
    },
    "237": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #237",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "237",
                "rarity_score": 301.1630853089007,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/237.png",
            "scheme": "https"
        }
    },
    "686": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #686",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "686",
                "rarity_score": 365.5241935483871,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/686.png",
            "scheme": "https"
        }
    },
    "1347": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1347",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1347",
                "rarity_score": 486.98405690986874,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1347.png",
            "scheme": "https"
        }
    },
    "392": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #392",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "392",
                "rarity_score": 452.58429458740017,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/392.png",
            "scheme": "https"
        }
    },
    "668": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #668",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "668",
                "rarity_score": 561.0958485958486,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/668.png",
            "scheme": "https"
        }
    },
    "238": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #238",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "238",
                "rarity_score": 753.0157363562442,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/238.png",
            "scheme": "https"
        }
    },
    "1348": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1348",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1348",
                "rarity_score": 382.55122070911546,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1348.png",
            "scheme": "https"
        }
    },
    "685": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #685",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "685",
                "rarity_score": 408.8655717761557,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/685.png",
            "scheme": "https"
        }
    },
    "1059": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1059",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1059",
                "rarity_score": 885.545265052821,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1059.png",
            "scheme": "https"
        }
    },
    "404": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #404",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 3",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "404",
                "rarity_score": 705.0500917522194,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/404.png",
            "scheme": "https"
        }
    },
    "1429": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1429",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1429",
                "rarity_score": 373.50933355413554,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1429.png",
            "scheme": "https"
        }
    },
    "955": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #955",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "955",
                "rarity_score": 416.66485926354346,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/955.png",
            "scheme": "https"
        }
    },
    "812": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #812",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Sharkbait",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Blue Chicky Nuggies",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Sharksin Onesie",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Tofu", "score": 750.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Stayin' Alive",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Hazel", "score": 500.0 }
                ],
                "compiler": "nft-inator.com",
                "id": "812",
                "rarity_score": 7250.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/812.png",
            "scheme": "https"
        }
    },
    "47": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #47",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "47",
                "rarity_score": 477.8726708074534,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/47.png",
            "scheme": "https"
        }
    },
    "1124": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1124",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1124",
                "rarity_score": 416.1432010108119,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1124.png",
            "scheme": "https"
        }
    },
    "109": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #109",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 4",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "109",
                "rarity_score": 703.5861459968602,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/109.png",
            "scheme": "https"
        }
    },
    "539": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #539",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "539",
                "rarity_score": 323.5352005442882,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/539.png",
            "scheme": "https"
        }
    },
    "793": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #793",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 3",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "793",
                "rarity_score": 1757.4305700020805,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/793.png",
            "scheme": "https"
        }
    },
    "280": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #280",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "280",
                "rarity_score": 557.4980574980575,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/280.png",
            "scheme": "https"
        }
    },
    "900": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #900",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "900",
                "rarity_score": 469.0398822126932,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/900.png",
            "scheme": "https"
        }
    },
    "1466": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1466",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1466",
                "rarity_score": 368.135195054714,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1466.png",
            "scheme": "https"
        }
    },
    "451": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #451",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "451",
                "rarity_score": 464.5472536342101,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/451.png",
            "scheme": "https"
        }
    },
    "1036": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1036",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1036",
                "rarity_score": 565.8467117988395,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1036.png",
            "scheme": "https"
        }
    },
    "516": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #516",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "516",
                "rarity_score": 322.0090060315624,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/516.png",
            "scheme": "https"
        }
    },
    "1487": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1487",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple $kda",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1487",
                "rarity_score": 729.9433485768889,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1487.png",
            "scheme": "https"
        }
    },
    "146": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #146",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 6",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "146",
                "rarity_score": 958.7500584521861,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/146.png",
            "scheme": "https"
        }
    },
    "1171": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1171",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1171",
                "rarity_score": 379.31971971405744,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1171.png",
            "scheme": "https"
        }
    },
    "843": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #843",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "843",
                "rarity_score": 522.7347511245816,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/843.png",
            "scheme": "https"
        }
    },
    "627": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #627",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Kawaii Brown & Green Eyes",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "627",
                "rarity_score": 714.1771910861644,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/627.png",
            "scheme": "https"
        }
    },
    "781": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #781",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "781",
                "rarity_score": 412.01415877355726,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/781.png",
            "scheme": "https"
        }
    },
    "277": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #277",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "277",
                "rarity_score": 403.9059773375069,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/277.png",
            "scheme": "https"
        }
    },
    "296": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #296",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "296",
                "rarity_score": 657.1196555745801,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/296.png",
            "scheme": "https"
        }
    },
    "1307": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1307",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Sad Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1307",
                "rarity_score": 612.9745968090939,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1307.png",
            "scheme": "https"
        }
    },
    "1020": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1020",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1020",
                "rarity_score": 371.2677125506073,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1020.png",
            "scheme": "https"
        }
    },
    "443": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #443",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "443",
                "rarity_score": 389.4731122702434,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/443.png",
            "scheme": "https"
        }
    },
    "1470": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1470",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1470",
                "rarity_score": 340.95028358738034,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1470.png",
            "scheme": "https"
        }
    },
    "916": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #916",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "916",
                "rarity_score": 567.952380952381,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/916.png",
            "scheme": "https"
        }
    },
    "851": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #851",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "851",
                "rarity_score": 479.0372670807453,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/851.png",
            "scheme": "https"
        }
    },
    "1163": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1163",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1163",
                "rarity_score": 397.4953296993919,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1163.png",
            "scheme": "https"
        }
    },
    "150": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #150",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 14",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "150",
                "rarity_score": 690.2777777777778,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/150.png",
            "scheme": "https"
        }
    },
    "500": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #500",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "500",
                "rarity_score": 378.5952819482428,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/500.png",
            "scheme": "https"
        }
    },
    "384": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #384",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "384",
                "rarity_score": 323.9084685805998,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/384.png",
            "scheme": "https"
        }
    },
    "689": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #689",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 7",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "689",
                "rarity_score": 727.4197722567287,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/689.png",
            "scheme": "https"
        }
    },
    "939": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #939",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "939",
                "rarity_score": 402.9150197628459,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/939.png",
            "scheme": "https"
        }
    },
    "1425": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1425",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1425",
                "rarity_score": 452.98564140777256,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1425.png",
            "scheme": "https"
        }
    },
    "412": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #412",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "412",
                "rarity_score": 320.78891030553143,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/412.png",
            "scheme": "https"
        }
    },
    "1075": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1075",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1075",
                "rarity_score": 372.85657929341636,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1075.png",
            "scheme": "https"
        }
    },
    "555": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #555",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "555",
                "rarity_score": 392.4918166939443,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/555.png",
            "scheme": "https"
        }
    },
    "1098": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1098",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 02",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1098",
                "rarity_score": 1111.7930448775255,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1098.png",
            "scheme": "https"
        }
    },
    "105": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #105",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "105",
                "rarity_score": 455.95080512604187,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/105.png",
            "scheme": "https"
        }
    },
    "1132": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1132",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 6",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1132",
                "rarity_score": 956.6889632107024,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1132.png",
            "scheme": "https"
        }
    },
    "804": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #804",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Stripes",
                        "score": 750.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "804",
                "rarity_score": 1127.460729746444,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/804.png",
            "scheme": "https"
        }
    },
    "1323": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1323",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1323",
                "rarity_score": 310.71912688760517,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1323.png",
            "scheme": "https"
        }
    },
    "310": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #310",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "310",
                "rarity_score": 302.22628295033627,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/310.png",
            "scheme": "https"
        }
    },
    "740": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #740",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 03",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "740",
                "rarity_score": 518.1458748064588,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/740.png",
            "scheme": "https"
        }
    },
    "253": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #253",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "253",
                "rarity_score": 394.1603815439219,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/253.png",
            "scheme": "https"
        }
    },
    "1260": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1260",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1260",
                "rarity_score": 442.3402255639097,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1260.png",
            "scheme": "https"
        }
    },
    "603": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #603",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 02",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "603",
                "rarity_score": 496.1347716892558,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/603.png",
            "scheme": "https"
        }
    },
    "486": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #486",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "The Queen",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Purple Castle",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Dress",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Grey", "score": 500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Tata",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Blue", "score": 750.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Robe",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Crown Jewels",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "486",
                "rarity_score": 10250.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/486.png",
            "scheme": "https"
        }
    },
    "1147": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1147",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1147",
                "rarity_score": 428.27213743272137,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1147.png",
            "scheme": "https"
        }
    },
    "24": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #24",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "24",
                "rarity_score": 475.01003001575083,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/24.png",
            "scheme": "https"
        }
    },
    "890": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #890",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 009",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Feather Headband",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "890",
                "rarity_score": 701.5863671035296,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/890.png",
            "scheme": "https"
        }
    },
    "191": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #191",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "191",
                "rarity_score": 247.37087912087912,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/191.png",
            "scheme": "https"
        }
    },
    "467": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #467",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "467",
                "rarity_score": 577.6155717761558,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/467.png",
            "scheme": "https"
        }
    },
    "715": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #715",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "715",
                "rarity_score": 463.30037049144596,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/715.png",
            "scheme": "https"
        }
    },
    "345": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #345",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "345",
                "rarity_score": 275.36955088974673,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/345.png",
            "scheme": "https"
        }
    },
    "1372": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1372",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple $kda",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1372",
                "rarity_score": 710.4020979020979,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1372.png",
            "scheme": "https"
        }
    },
    "652": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #652",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 02",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "652",
                "rarity_score": 573.1848184818482,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/652.png",
            "scheme": "https"
        }
    },
    "1235": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1235",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1235",
                "rarity_score": 307.0859291084855,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1235.png",
            "scheme": "https"
        }
    },
    "202": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #202",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 7",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 01",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "202",
                "rarity_score": 1236.8012422360246,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/202.png",
            "scheme": "https"
        }
    },
    "828": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #828",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "828",
                "rarity_score": 382.4651215121512,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/828.png",
            "scheme": "https"
        }
    },
    "982": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #982",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "982",
                "rarity_score": 465.9228928965771,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/982.png",
            "scheme": "https"
        }
    },
    "71": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #71",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "71",
                "rarity_score": 424.4984141111257,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/71.png",
            "scheme": "https"
        }
    },
    "594": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #594",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "594",
                "rarity_score": 621.3944099378882,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/594.png",
            "scheme": "https"
        }
    },
    "1364": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1364",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1364",
                "rarity_score": 383.75974068557184,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1364.png",
            "scheme": "https"
        }
    },
    "349": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #349",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "349",
                "rarity_score": 490.37949755162873,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/349.png",
            "scheme": "https"
        }
    },
    "699": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #699",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "699",
                "rarity_score": 497.9505788329318,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/699.png",
            "scheme": "https"
        }
    },
    "214": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #214",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Froggy",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Lilypad",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Frog Onesie",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Grey", "score": 500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Happy Little Frog",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Green", "score": 1500.0 }
                ],
                "compiler": "nft-inator.com",
                "id": "214",
                "rarity_score": 8000.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/214.png",
            "scheme": "https"
        }
    },
    "1219": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1219",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Top Hat",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1219",
                "rarity_score": 732.1930236964319,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1219.png",
            "scheme": "https"
        }
    },
    "644": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #644",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "644",
                "rarity_score": 372.7814041556629,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/644.png",
            "scheme": "https"
        }
    },
    "1108": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1108",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1108",
                "rarity_score": 411.9681244421777,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1108.png",
            "scheme": "https"
        }
    },
    "63": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #63",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "63",
                "rarity_score": 456.8935294029279,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/63.png",
            "scheme": "https"
        }
    },
    "994": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #994",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "994",
                "rarity_score": 472.56380782713256,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/994.png",
            "scheme": "https"
        }
    },
    "428": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #428",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Multicolor Wagmi",
                        "score": 750.0
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "428",
                "rarity_score": 1448.4203296703295,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/428.png",
            "scheme": "https"
        }
    },
    "582": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #582",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "582",
                "rarity_score": 445.4897225200429,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/582.png",
            "scheme": "https"
        }
    },
    "1297": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1297",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1297",
                "rarity_score": 588.0526577247888,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1297.png",
            "scheme": "https"
        }
    },
    "756": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #756",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "756",
                "rarity_score": 440.8107079476182,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/756.png",
            "scheme": "https"
        }
    },
    "306": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #306",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "306",
                "rarity_score": 396.965034965035,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/306.png",
            "scheme": "https"
        }
    },
    "1331": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1331",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1331",
                "rarity_score": 500.9758984017103,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1331.png",
            "scheme": "https"
        }
    },
    "611": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #611",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "611",
                "rarity_score": 662.5115955473098,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/611.png",
            "scheme": "https"
        }
    },
    "1276": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1276",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1276",
                "rarity_score": 441.5755329591903,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1276.png",
            "scheme": "https"
        }
    },
    "241": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #241",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Brown Eyes 01",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "241",
                "rarity_score": 720.6513057608187,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/241.png",
            "scheme": "https"
        }
    },
    "867": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #867",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "867",
                "rarity_score": 474.10936253823917,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/867.png",
            "scheme": "https"
        }
    },
    "32": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #32",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "32",
                "rarity_score": 506.0768036311514,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/32.png",
            "scheme": "https"
        }
    },
    "490": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #490",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "490",
                "rarity_score": 488.8405993419748,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/490.png",
            "scheme": "https"
        }
    },
    "183": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #183",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "183",
                "rarity_score": 505.66552731134266,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/183.png",
            "scheme": "https"
        }
    },
    "886": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #886",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "886",
                "rarity_score": 296.98535256983314,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/886.png",
            "scheme": "https"
        }
    },
    "408": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #408",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 015",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "408",
                "rarity_score": 713.6477546684685,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/408.png",
            "scheme": "https"
        }
    },
    "1082": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1082",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1082",
                "rarity_score": 423.25407979316395,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1082.png",
            "scheme": "https"
        }
    },
    "1128": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1128",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1128",
                "rarity_score": 311.50855723224146,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1128.png",
            "scheme": "https"
        }
    },
    "43": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #43",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "43",
                "rarity_score": 438.9623205741627,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/43.png",
            "scheme": "https"
        }
    },
    "234": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #234",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 1",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "234",
                "rarity_score": 676.0831122900089,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/234.png",
            "scheme": "https"
        }
    },
    "1199": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1199",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1199",
                "rarity_score": 482.99317644530413,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1199.png",
            "scheme": "https"
        }
    },
    "664": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #664",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 11",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "664",
                "rarity_score": 702.064836003051,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/664.png",
            "scheme": "https"
        }
    },
    "1344": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1344",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 3",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 01",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1344",
                "rarity_score": 885.768049155146,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1344.png",
            "scheme": "https"
        }
    },
    "369": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #369",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "369",
                "rarity_score": 365.0694588909692,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/369.png",
            "scheme": "https"
        }
    },
    "719": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #719",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "719",
                "rarity_score": 312.18267134325527,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/719.png",
            "scheme": "https"
        }
    },
    "1190": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1190",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1190",
                "rarity_score": 457.0334059738299,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1190.png",
            "scheme": "https"
        }
    },
    "847": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #847",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "847",
                "rarity_score": 531.1027099959132,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/847.png",
            "scheme": "https"
        }
    },
    "12": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #12",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "12",
                "rarity_score": 746.276913875598,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/12.png",
            "scheme": "https"
        }
    },
    "1483": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1483",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Grey Eyes 04",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1483",
                "rarity_score": 504.3791806020067,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1483.png",
            "scheme": "https"
        }
    },
    "631": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #631",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "631",
                "rarity_score": 675.695999402896,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/631.png",
            "scheme": "https"
        }
    },
    "797": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #797",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "797",
                "rarity_score": 489.42276429233414,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/797.png",
            "scheme": "https"
        }
    },
    "1256": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1256",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1256",
                "rarity_score": 334.08763018737443,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1256.png",
            "scheme": "https"
        }
    },
    "261": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #261",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Humpty",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Grey Spotlights",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Creepy Clown",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Grey", "score": 500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Boooo",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hopeless",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Nose",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Cotton Candy Wig",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "261",
                "rarity_score": 11000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/261.png",
            "scheme": "https"
        }
    },
    "776": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #776",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "776",
                "rarity_score": 546.5021248774109,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/776.png",
            "scheme": "https"
        }
    },
    "326": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #326",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "326",
                "rarity_score": 498.89249406056126,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/326.png",
            "scheme": "https"
        }
    },
    "1311": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1311",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Wen Lambo",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1311",
                "rarity_score": 582.6884652466301,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1311.png",
            "scheme": "https"
        }
    },
    "1186": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1186",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1186",
                "rarity_score": 523.4790272384257,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1186.png",
            "scheme": "https"
        }
    },
    "447": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #447",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "447",
                "rarity_score": 399.6190737648891,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/447.png",
            "scheme": "https"
        }
    },
    "1491": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1491",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 02",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1491",
                "rarity_score": 696.9308628529941,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1491.png",
            "scheme": "https"
        }
    },
    "1167": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1167",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 6",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1167",
                "rarity_score": 1002.6013706605986,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1167.png",
            "scheme": "https"
        }
    },
    "273": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #273",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "273",
                "rarity_score": 497.44071717755924,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/273.png",
            "scheme": "https"
        }
    },
    "1240": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1240",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1240",
                "rarity_score": 391.4148936170213,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1240.png",
            "scheme": "https"
        }
    },
    "623": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #623",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Brown Eyes 01",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "623",
                "rarity_score": 671.9138055299244,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/623.png",
            "scheme": "https"
        }
    },
    "1303": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1303",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey Nighvision",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1303",
                "rarity_score": 810.6238273921201,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1303.png",
            "scheme": "https"
        }
    },
    "330": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #330",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "330",
                "rarity_score": 406.57529604898025,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/330.png",
            "scheme": "https"
        }
    },
    "760": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #760",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "760",
                "rarity_score": 420.8728771228772,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/760.png",
            "scheme": "https"
        }
    },
    "808": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #808",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "808",
                "rarity_score": 621.8109812574916,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/808.png",
            "scheme": "https"
        }
    },
    "51": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #51",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "51",
                "rarity_score": 365.0066810566348,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/51.png",
            "scheme": "https"
        }
    },
    "1094": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1094",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1094",
                "rarity_score": 414.89248456461564,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1094.png",
            "scheme": "https"
        }
    },
    "388": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #388",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "388",
                "rarity_score": 346.73069985569987,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/388.png",
            "scheme": "https"
        }
    },
    "672": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #672",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 3",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "672",
                "rarity_score": 578.2337662337662,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/672.png",
            "scheme": "https"
        }
    },
    "1215": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1215",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1215",
                "rarity_score": 524.7700585250823,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1215.png",
            "scheme": "https"
        }
    },
    "222": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #222",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "222",
                "rarity_score": 853.8961038961039,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/222.png",
            "scheme": "https"
        }
    },
    "735": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #735",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "735",
                "rarity_score": 335.05698379952617,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/735.png",
            "scheme": "https"
        }
    },
    "365": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #365",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 2",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "365",
                "rarity_score": 784.2650103519669,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/365.png",
            "scheme": "https"
        }
    },
    "1352": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1352",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1352",
                "rarity_score": 523.0487476730775,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1352.png",
            "scheme": "https"
        }
    },
    "871": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #871",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "871",
                "rarity_score": 565.4501748251748,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/871.png",
            "scheme": "https"
        }
    },
    "28": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #28",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "28",
                "rarity_score": 626.5206284943127,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/28.png",
            "scheme": "https"
        }
    },
    "1143": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1143",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1143",
                "rarity_score": 567.1807548050136,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1143.png",
            "scheme": "https"
        }
    },
    "170": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #170",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "170",
                "rarity_score": 319.2803760848602,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/170.png",
            "scheme": "https"
        }
    },
    "520": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #520",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "520",
                "rarity_score": 455.8045456316733,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/520.png",
            "scheme": "https"
        }
    },
    "1000": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1000",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1000",
                "rarity_score": 424.76469458317325,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1000.png",
            "scheme": "https"
        }
    },
    "463": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #463",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 2",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "463",
                "rarity_score": 818.6068272554652,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/463.png",
            "scheme": "https"
        }
    },
    "1450": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1450",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 03",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1450",
                "rarity_score": 663.3544830455586,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1450.png",
            "scheme": "https"
        }
    },
    "936": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #936",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "936",
                "rarity_score": 768.8761097659401,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/936.png",
            "scheme": "https"
        }
    },
    "1281": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1281",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1281",
                "rarity_score": 483.7749920660108,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1281.png",
            "scheme": "https"
        }
    },
    "1327": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1327",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1327",
                "rarity_score": 475.39509469957886,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1327.png",
            "scheme": "https"
        }
    },
    "607": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #607",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "607",
                "rarity_score": 371.58237986270024,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/607.png",
            "scheme": "https"
        }
    },
    "257": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #257",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "257",
                "rarity_score": 336.87071591774566,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/257.png",
            "scheme": "https"
        }
    },
    "575": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #575",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Gold Crown",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "575",
                "rarity_score": 578.9683856289696,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/575.png",
            "scheme": "https"
        }
    },
    "125": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #125",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "125",
                "rarity_score": 344.6314647962068,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/125.png",
            "scheme": "https"
        }
    },
    "1112": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1112",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1112",
                "rarity_score": 528.5521869312146,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1112.png",
            "scheme": "https"
        }
    },
    "824": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #824",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "824",
                "rarity_score": 537.692171032679,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/824.png",
            "scheme": "https"
        }
    },
    "959": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #959",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 3",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "959",
                "rarity_score": 910.967207074429,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/959.png",
            "scheme": "https"
        }
    },
    "90": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #90",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "90",
                "rarity_score": 426.4496040156155,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/90.png",
            "scheme": "https"
        }
    },
    "1405": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1405",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1405",
                "rarity_score": 523.2256723055967,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1405.png",
            "scheme": "https"
        }
    },
    "432": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #432",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "432",
                "rarity_score": 558.7649003169537,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/432.png",
            "scheme": "https"
        }
    },
    "1055": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1055",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1055",
                "rarity_score": 330.53739364844887,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1055.png",
            "scheme": "https"
        }
    },
    "598": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #598",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 08",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "598",
                "rarity_score": 405.60934517466757,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/598.png",
            "scheme": "https"
        }
    },
    "1389": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1389",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1389",
                "rarity_score": 422.26467331118494,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1389.png",
            "scheme": "https"
        }
    },
    "832": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #832",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "832",
                "rarity_score": 513.3383642930856,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/832.png",
            "scheme": "https"
        }
    },
    "998": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #998",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "998",
                "rarity_score": 393.799010282782,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/998.png",
            "scheme": "https"
        }
    },
    "67": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #67",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "67",
                "rarity_score": 533.2747915699861,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/67.png",
            "scheme": "https"
        }
    },
    "1104": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1104",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1104",
                "rarity_score": 342.5642984466514,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1104.png",
            "scheme": "https"
        }
    },
    "129": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #129",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "129",
                "rarity_score": 461.3147296981978,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/129.png",
            "scheme": "https"
        }
    },
    "559": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #559",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "559",
                "rarity_score": 577.1447060273636,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/559.png",
            "scheme": "https"
        }
    },
    "1039": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1039",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1039",
                "rarity_score": 499.00396371363195,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1039.png",
            "scheme": "https"
        }
    },
    "424": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #424",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "424",
                "rarity_score": 331.4616672147728,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/424.png",
            "scheme": "https"
        }
    },
    "1409": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1409",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1409",
                "rarity_score": 307.77389903329754,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1409.png",
            "scheme": "https"
        }
    },
    "86": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #86",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 064",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 07",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "86",
                "rarity_score": 1832.1080931263857,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/86.png",
            "scheme": "https"
        }
    },
    "2": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #2",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "2",
                "rarity_score": 294.0833594791749,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/2.png",
            "scheme": "https"
        }
    },
    "975": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #975",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "975",
                "rarity_score": 572.7433606539446,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/975.png",
            "scheme": "https"
        }
    },
    "1368": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1368",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 025",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1368",
                "rarity_score": 789.6699162412144,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1368.png",
            "scheme": "https"
        }
    },
    "648": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #648",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "648",
                "rarity_score": 373.1678004535147,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/648.png",
            "scheme": "https"
        }
    },
    "1385": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1385",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1385",
                "rarity_score": 300.2195909688498,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1385.png",
            "scheme": "https"
        }
    },
    "218": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #218",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "218",
                "rarity_score": 341.19247979696786,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/218.png",
            "scheme": "https"
        }
    },
    "536": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #536",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "536",
                "rarity_score": 659.0151484346674,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/536.png",
            "scheme": "https"
        }
    },
    "166": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #166",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 2",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "166",
                "rarity_score": 720.669074219028,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/166.png",
            "scheme": "https"
        }
    },
    "1151": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1151",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1151",
                "rarity_score": 595.0035123945354,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1151.png",
            "scheme": "https"
        }
    },
    "863": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #863",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "863",
                "rarity_score": 471.6205951671068,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/863.png",
            "scheme": "https"
        }
    },
    "920": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #920",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 2",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "920",
                "rarity_score": 819.5134898105196,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/920.png",
            "scheme": "https"
        }
    },
    "187": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #187",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "187",
                "rarity_score": 311.2573473038589,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/187.png",
            "scheme": "https"
        }
    },
    "1446": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1446",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1446",
                "rarity_score": 451.66673972122385,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1446.png",
            "scheme": "https"
        }
    },
    "471": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #471",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "471",
                "rarity_score": 322.29156712852364,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/471.png",
            "scheme": "https"
        }
    },
    "1016": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1016",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1016",
                "rarity_score": 626.9182490540222,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1016.png",
            "scheme": "https"
        }
    },
    "1293": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1293",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1293",
                "rarity_score": 452.7392508201568,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1293.png",
            "scheme": "https"
        }
    },
    "495": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #495",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "495",
                "rarity_score": 448.7012987012987,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/495.png",
            "scheme": "https"
        }
    },
    "1158": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1158",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1158",
                "rarity_score": 516.5727555131793,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1158.png",
            "scheme": "https"
        }
    },
    "33": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #33",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 066",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "33",
                "rarity_score": 622.5453893932155,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/33.png",
            "scheme": "https"
        }
    },
    "879": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #879",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "879",
                "rarity_score": 513.2992613521382,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/879.png",
            "scheme": "https"
        }
    },
    "182": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #182",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "182",
                "rarity_score": 444.39693969396944,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/182.png",
            "scheme": "https"
        }
    },
    "478": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #478",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "478",
                "rarity_score": 397.27034978751226,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/478.png",
            "scheme": "https"
        }
    },
    "749": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #749",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "749",
                "rarity_score": 457.18665786723864,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/749.png",
            "scheme": "https"
        }
    },
    "1334": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1334",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 060",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1334",
                "rarity_score": 477.5537634408602,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1334.png",
            "scheme": "https"
        }
    },
    "299": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #299",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "299",
                "rarity_score": 449.07828662102366,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/299.png",
            "scheme": "https"
        }
    },
    "614": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #614",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "614",
                "rarity_score": 363.5716953136722,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/614.png",
            "scheme": "https"
        }
    },
    "244": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #244",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "244",
                "rarity_score": 478.3906248804014,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/244.png",
            "scheme": "https"
        }
    },
    "1269": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1269",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 5",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1269",
                "rarity_score": 1019.3722943722944,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1269.png",
            "scheme": "https"
        }
    },
    "991": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #991",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "991",
                "rarity_score": 469.46570593921786,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/991.png",
            "scheme": "https"
        }
    },
    "62": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #62",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "62",
                "rarity_score": 459.4474153297683,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/62.png",
            "scheme": "https"
        }
    },
    "837": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #837",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "837",
                "rarity_score": 337.0344397693626,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/837.png",
            "scheme": "https"
        }
    },
    "583": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #583",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "583",
                "rarity_score": 759.8822230401177,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/583.png",
            "scheme": "https"
        }
    },
    "7": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #7",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "7",
                "rarity_score": 451.19674185463657,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/7.png",
            "scheme": "https"
        }
    },
    "356": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #356",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "356",
                "rarity_score": 524.7548324924891,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/356.png",
            "scheme": "https"
        }
    },
    "1361": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1361",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1361",
                "rarity_score": 583.260344727736,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1361.png",
            "scheme": "https"
        }
    },
    "706": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #706",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "706",
                "rarity_score": 577.4089817209434,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/706.png",
            "scheme": "https"
        }
    },
    "1226": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1226",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1226",
                "rarity_score": 525.5783082463755,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1226.png",
            "scheme": "https"
        }
    },
    "211": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #211",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "211",
                "rarity_score": 492.33828693992035,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/211.png",
            "scheme": "https"
        }
    },
    "641": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #641",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "641",
                "rarity_score": 326.01209677419354,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/641.png",
            "scheme": "https"
        }
    },
    "1117": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1117",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 2",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Brown Eyes 01",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1117",
                "rarity_score": 803.4099584673354,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1117.png",
            "scheme": "https"
        }
    },
    "74": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #74",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 16",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "74",
                "rarity_score": 710.813823857302,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/74.png",
            "scheme": "https"
        }
    },
    "983": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #983",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "983",
                "rarity_score": 534.6849237718803,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/983.png",
            "scheme": "https"
        }
    },
    "591": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #591",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "591",
                "rarity_score": 439.2720437457279,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/591.png",
            "scheme": "https"
        }
    },
    "437": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #437",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "437",
                "rarity_score": 602.0046628452723,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/437.png",
            "scheme": "https"
        }
    },
    "710": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #710",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 012",
                        "score": 136.36363636363637
                    },
                    { "trait_type": "Clothes", "value": "Kda", "score": 300.0 },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "710",
                "rarity_score": 703.7307609348231,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/710.png",
            "scheme": "https"
        }
    },
    "1373": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1373",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1373",
                "rarity_score": 403.2132073722316,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1373.png",
            "scheme": "https"
        }
    },
    "340": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #340",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0392",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "340",
                "rarity_score": 466.83198380566796,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/340.png",
            "scheme": "https"
        }
    },
    "653": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #653",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 04",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Feather Headband",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "653",
                "rarity_score": 1340.405129814103,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/653.png",
            "scheme": "https"
        }
    },
    "203": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #203",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "203",
                "rarity_score": 721.3822104668787,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/203.png",
            "scheme": "https"
        }
    },
    "1230": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1230",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1230",
                "rarity_score": 542.2720129309862,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1230.png",
            "scheme": "https"
        }
    },
    "21": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #21",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "21",
                "rarity_score": 462.4237460580142,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/21.png",
            "scheme": "https"
        }
    },
    "878": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #878",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 7",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "878",
                "rarity_score": 561.1854636591479,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/878.png",
            "scheme": "https"
        }
    },
    "479": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #479",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 06",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "479",
                "rarity_score": 889.9642656165445,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/479.png",
            "scheme": "https"
        }
    },
    "194": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #194",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "194",
                "rarity_score": 545.3594976452118,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/194.png",
            "scheme": "https"
        }
    },
    "895": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #895",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "895",
                "rarity_score": 498.3122171945701,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/895.png",
            "scheme": "https"
        }
    },
    "315": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #315",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 01",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey Nighvision",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "315",
                "rarity_score": 957.8919631093545,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/315.png",
            "scheme": "https"
        }
    },
    "1322": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1322",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1322",
                "rarity_score": 487.76326222587,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1322.png",
            "scheme": "https"
        }
    },
    "1288": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1288",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Firstblood",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1288",
                "rarity_score": 532.8626298692262,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1288.png",
            "scheme": "https"
        }
    },
    "745": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #745",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 020",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "745",
                "rarity_score": 683.592720685112,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/745.png",
            "scheme": "https"
        }
    },
    "1265": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1265",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1265",
                "rarity_score": 460.2938114517062,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1265.png",
            "scheme": "https"
        }
    },
    "252": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #252",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 7",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "252",
                "rarity_score": 633.8345864661654,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/252.png",
            "scheme": "https"
        }
    },
    "602": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #602",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 08",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "602",
                "rarity_score": 526.2065602836878,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/602.png",
            "scheme": "https"
        }
    },
    "413": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #413",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "413",
                "rarity_score": 498.49213286713285,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/413.png",
            "scheme": "https"
        }
    },
    "1420": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1420",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1420",
                "rarity_score": 426.6726123315856,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1420.png",
            "scheme": "https"
        }
    },
    "1070": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1070",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Kawaii Brown & Green Eyes",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1070",
                "rarity_score": 631.9142512077294,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1070.png",
            "scheme": "https"
        }
    },
    "946": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #946",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "946",
                "rarity_score": 505.5527440395862,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/946.png",
            "scheme": "https"
        }
    },
    "58": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #58",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "58",
                "rarity_score": 809.9007235765355,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/58.png",
            "scheme": "https"
        }
    },
    "801": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #801",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 038",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "801",
                "rarity_score": 517.4117521010457,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/801.png",
            "scheme": "https"
        }
    },
    "550": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #550",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "550",
                "rarity_score": 391.79347826086956,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/550.png",
            "scheme": "https"
        }
    },
    "1133": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1133",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 06",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1133",
                "rarity_score": 967.2101449275362,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1133.png",
            "scheme": "https"
        }
    },
    "100": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #100",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 1",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "100",
                "rarity_score": 523.8358657448389,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/100.png",
            "scheme": "https"
        }
    },
    "227": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #227",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "227",
                "rarity_score": 564.3313103853174,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/227.png",
            "scheme": "https"
        }
    },
    "381": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #381",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hon",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "381",
                "rarity_score": 721.2503573231342,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/381.png",
            "scheme": "https"
        }
    },
    "677": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #677",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Depressed Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "677",
                "rarity_score": 445.03981420039815,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/677.png",
            "scheme": "https"
        }
    },
    "1357": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1357",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1357",
                "rarity_score": 570.211038961039,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1357.png",
            "scheme": "https"
        }
    },
    "696": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #696",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 6",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "696",
                "rarity_score": 691.7822384428224,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/696.png",
            "scheme": "https"
        }
    },
    "909": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #909",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "909",
                "rarity_score": 527.4646771668049,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/909.png",
            "scheme": "https"
        }
    },
    "1025": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1025",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 02",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1025",
                "rarity_score": 614.9905141895989,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1025.png",
            "scheme": "https"
        }
    },
    "1475": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1475",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1475",
                "rarity_score": 356.7012032085562,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1475.png",
            "scheme": "https"
        }
    },
    "442": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #442",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "442",
                "rarity_score": 503.45413250116223,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/442.png",
            "scheme": "https"
        }
    },
    "1498": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1498",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1498",
                "rarity_score": 589.9468474080627,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1498.png",
            "scheme": "https"
        }
    },
    "155": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #155",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "155",
                "rarity_score": 496.78573501818306,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/155.png",
            "scheme": "https"
        }
    },
    "1162": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1162",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 005",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1162",
                "rarity_score": 548.6912414067493,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1162.png",
            "scheme": "https"
        }
    },
    "505": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #505",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Cn Hood",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "505",
                "rarity_score": 805.154029396381,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/505.png",
            "scheme": "https"
        }
    },
    "854": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #854",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "854",
                "rarity_score": 443.89360000613203,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/854.png",
            "scheme": "https"
        }
    },
    "784": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #784",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "784",
                "rarity_score": 431.76070662549796,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/784.png",
            "scheme": "https"
        }
    },
    "289": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #289",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue & Brown Eyes 01",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "289",
                "rarity_score": 763.3878255212936,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/289.png",
            "scheme": "https"
        }
    },
    "454": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #454",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "454",
                "rarity_score": 325.13971742543174,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/454.png",
            "scheme": "https"
        }
    },
    "1459": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1459",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1459",
                "rarity_score": 505.6915661201748,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1459.png",
            "scheme": "https"
        }
    },
    "1029": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1029",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1029",
                "rarity_score": 445.3384252294482,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1029.png",
            "scheme": "https"
        }
    },
    "905": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #905",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "905",
                "rarity_score": 435.0699855699856,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/905.png",
            "scheme": "https"
        }
    },
    "17": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #17",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 8",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "17",
                "rarity_score": 1107.616036505867,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/17.png",
            "scheme": "https"
        }
    },
    "842": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #842",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "842",
                "rarity_score": 610.2170262669799,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/842.png",
            "scheme": "https"
        }
    },
    "509": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #509",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "509",
                "rarity_score": 532.6934560929715,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/509.png",
            "scheme": "https"
        }
    },
    "1174": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1174",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 9",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1174",
                "rarity_score": 648.8928810825438,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1174.png",
            "scheme": "https"
        }
    },
    "139": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #139",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "139",
                "rarity_score": 661.9061848347934,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/139.png",
            "scheme": "https"
        }
    },
    "792": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #792",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 11",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "792",
                "rarity_score": 913.8368210736633,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/792.png",
            "scheme": "https"
        }
    },
    "268": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #268",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Dumpty",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Purple Stage Light",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Creepy Clown",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Aqua", "score": 1500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Boo",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Dead", "score": 1500.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Nose",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Wig",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "268",
                "rarity_score": 12000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/268.png",
            "scheme": "https"
        }
    },
    "638": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #638",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "638",
                "rarity_score": 291.9332343901309,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/638.png",
            "scheme": "https"
        }
    },
    "1318": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1318",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1318",
                "rarity_score": 590.8791007686233,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1318.png",
            "scheme": "https"
        }
    },
    "285": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #285",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "285",
                "rarity_score": 497.3593628593629,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/285.png",
            "scheme": "https"
        }
    },
    "950": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #950",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "950",
                "rarity_score": 482.5668704774545,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/950.png",
            "scheme": "https"
        }
    },
    "1066": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1066",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1066",
                "rarity_score": 371.3328335832084,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1066.png",
            "scheme": "https"
        }
    },
    "1436": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1436",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 04",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1436",
                "rarity_score": 483.57012022971725,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1436.png",
            "scheme": "https"
        }
    },
    "401": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #401",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "401",
                "rarity_score": 372.8649068322982,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/401.png",
            "scheme": "https"
        }
    },
    "116": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #116",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mauve",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "116",
                "rarity_score": 794.0101931111888,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/116.png",
            "scheme": "https"
        }
    },
    "1121": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1121",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1121",
                "rarity_score": 412.2299808279432,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1121.png",
            "scheme": "https"
        }
    },
    "546": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #546",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 7",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Cn Hood",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "546",
                "rarity_score": 1168.9434623224902,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/546.png",
            "scheme": "https"
        }
    },
    "1087": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1087",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1087",
                "rarity_score": 470.7958707958708,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1087.png",
            "scheme": "https"
        }
    },
    "813": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #813",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 16",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pale Pink",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "813",
                "rarity_score": 718.5927085557058,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/813.png",
            "scheme": "https"
        }
    },
    "393": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #393",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "393",
                "rarity_score": 281.56116606042485,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/393.png",
            "scheme": "https"
        }
    },
    "680": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #680",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 001",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "680",
                "rarity_score": 700.8354362029168,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/680.png",
            "scheme": "https"
        }
    },
    "1338": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1338",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Aqua",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1338",
                "rarity_score": 537.5379617883366,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1338.png",
            "scheme": "https"
        }
    },
    "1292": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1292",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 11",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 12",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1292",
                "rarity_score": 607.7745863964145,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1292.png",
            "scheme": "https"
        }
    },
    "248": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #248",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "248",
                "rarity_score": 479.2098808813925,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/248.png",
            "scheme": "https"
        }
    },
    "618": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #618",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 06",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "618",
                "rarity_score": 471.66640398488266,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/618.png",
            "scheme": "https"
        }
    },
    "37": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #37",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemoncello",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "37",
                "rarity_score": 691.597718538623,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/37.png",
            "scheme": "https"
        }
    },
    "862": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #862",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 03",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "862",
                "rarity_score": 786.0466358493823,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/862.png",
            "scheme": "https"
        }
    },
    "529": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #529",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 015",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 03",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "529",
                "rarity_score": 787.9718519693924,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/529.png",
            "scheme": "https"
        }
    },
    "1154": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1154",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1154",
                "rarity_score": 423.5832794656324,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1154.png",
            "scheme": "https"
        }
    },
    "159": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #159",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 04",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "159",
                "rarity_score": 314.8962735305417,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/159.png",
            "scheme": "https"
        }
    },
    "474": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #474",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 068",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "474",
                "rarity_score": 373.9468864468864,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/474.png",
            "scheme": "https"
        }
    },
    "1439": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1439",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Chicken Nuggie",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1439",
                "rarity_score": 876.7431972789115,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1439.png",
            "scheme": "https"
        }
    },
    "1009": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1009",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 053",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1009",
                "rarity_score": 495.7046452711247,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1009.png",
            "scheme": "https"
        }
    },
    "925": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #925",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "925",
                "rarity_score": 692.0137011221922,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/925.png",
            "scheme": "https"
        }
    },
    "1380": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1380",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1380",
                "rarity_score": 694.8912612810918,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1380.png",
            "scheme": "https"
        }
    },
    "136": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #136",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 7",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "136",
                "rarity_score": 607.0859291084855,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/136.png",
            "scheme": "https"
        }
    },
    "1101": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1101",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 02",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1101",
                "rarity_score": 503.25,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1101.png",
            "scheme": "https"
        }
    },
    "566": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #566",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "566",
                "rarity_score": 385.9415561096233,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/566.png",
            "scheme": "https"
        }
    },
    "833": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #833",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "833",
                "rarity_score": 371.9442047742307,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/833.png",
            "scheme": "https"
        }
    },
    "970": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #970",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "970",
                "rarity_score": 371.8546952862249,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/970.png",
            "scheme": "https"
        }
    },
    "3": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #3",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 045",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "3",
                "rarity_score": 560.0035922746753,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/3.png",
            "scheme": "https"
        }
    },
    "79": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #79",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 001",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "79",
                "rarity_score": 672.8786598846092,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/79.png",
            "scheme": "https"
        }
    },
    "1046": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1046",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1046",
                "rarity_score": 475.85809312638577,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1046.png",
            "scheme": "https"
        }
    },
    "587": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #587",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "KDA Colors 002 .PNG",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "587",
                "rarity_score": 688.6325602783756,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/587.png",
            "scheme": "https"
        }
    },
    "1416": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1416",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1416",
                "rarity_score": 419.0134724033029,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1416.png",
            "scheme": "https"
        }
    },
    "421": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #421",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "421",
                "rarity_score": 378.2306937913345,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/421.png",
            "scheme": "https"
        }
    },
    "1377": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1377",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 29",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1377",
                "rarity_score": 838.7687969924812,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1377.png",
            "scheme": "https"
        }
    },
    "207": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #207",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 074",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "207",
                "rarity_score": 540.5158369832283,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/207.png",
            "scheme": "https"
        }
    },
    "657": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #657",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 003",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 13",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "657",
                "rarity_score": 1066.5184815184814,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/657.png",
            "scheme": "https"
        }
    },
    "1396": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1396",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1396",
                "rarity_score": 396.3564845292956,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1396.png",
            "scheme": "https"
        }
    },
    "987": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #987",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "987",
                "rarity_score": 581.6273964131107,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/987.png",
            "scheme": "https"
        }
    },
    "78": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #78",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "78",
                "rarity_score": 449.89179376605256,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/78.png",
            "scheme": "https"
        }
    },
    "821": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #821",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "821",
                "rarity_score": 363.27176113360326,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/821.png",
            "scheme": "https"
        }
    },
    "570": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #570",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 2",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 04",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "570",
                "rarity_score": 1106.9439967234744,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/570.png",
            "scheme": "https"
        }
    },
    "1113": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1113",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1113",
                "rarity_score": 509.2684811959457,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1113.png",
            "scheme": "https"
        }
    },
    "120": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #120",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "120",
                "rarity_score": 401.5283815623548,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/120.png",
            "scheme": "https"
        }
    },
    "433": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #433",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 01",
                        "score": 500.0
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "433",
                "rarity_score": 887.9237288135594,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/433.png",
            "scheme": "https"
        }
    },
    "1400": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1400",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 017",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1400",
                "rarity_score": 529.2152292152292,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1400.png",
            "scheme": "https"
        }
    },
    "1050": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1050",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 08",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1050",
                "rarity_score": 755.2084178087746,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1050.png",
            "scheme": "https"
        }
    },
    "95": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #95",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 069",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "95",
                "rarity_score": 612.8409090909091,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/95.png",
            "scheme": "https"
        }
    },
    "966": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #966",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "966",
                "rarity_score": 475.1353427895981,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/966.png",
            "scheme": "https"
        }
    },
    "1284": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1284",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1284",
                "rarity_score": 517.7735247208932,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1284.png",
            "scheme": "https"
        }
    },
    "175": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #175",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 1",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "175",
                "rarity_score": 941.4818101153504,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/175.png",
            "scheme": "https"
        }
    },
    "1142": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1142",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1142",
                "rarity_score": 376.8989631058597,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1142.png",
            "scheme": "https"
        }
    },
    "525": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #525",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue $kda",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 02",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "525",
                "rarity_score": 715.950386247416,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/525.png",
            "scheme": "https"
        }
    },
    "874": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #874",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 5",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "874",
                "rarity_score": 666.9165737643998,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/874.png",
            "scheme": "https"
        }
    },
    "929": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #929",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 3",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "929",
                "rarity_score": 869.2943847132378,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/929.png",
            "scheme": "https"
        }
    },
    "1005": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1005",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 027",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1005",
                "rarity_score": 519.6944058812661,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1005.png",
            "scheme": "https"
        }
    },
    "198": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #198",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "198",
                "rarity_score": 445.7619261627653,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/198.png",
            "scheme": "https"
        }
    },
    "1455": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1455",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 029",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1455",
                "rarity_score": 556.8200392285061,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1455.png",
            "scheme": "https"
        }
    },
    "462": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #462",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "462",
                "rarity_score": 508.5464671366655,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/462.png",
            "scheme": "https"
        }
    },
    "673": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #673",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "673",
                "rarity_score": 498.7914005364651,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/673.png",
            "scheme": "https"
        }
    },
    "223": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #223",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "223",
                "rarity_score": 457.48747546814695,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/223.png",
            "scheme": "https"
        }
    },
    "1210": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1210",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1210",
                "rarity_score": 462.66225091089024,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1210.png",
            "scheme": "https"
        }
    },
    "730": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #730",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Sad Eyes 02",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "730",
                "rarity_score": 478.79706337980735,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/730.png",
            "scheme": "https"
        }
    },
    "1353": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1353",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1353",
                "rarity_score": 411.3605032104325,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1353.png",
            "scheme": "https"
        }
    },
    "360": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #360",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "360",
                "rarity_score": 439.3923014750455,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/360.png",
            "scheme": "https"
        }
    },
    "417": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #417",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 6",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "417",
                "rarity_score": 529.3073348984939,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/417.png",
            "scheme": "https"
        }
    },
    "1137": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1137",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 044",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1137",
                "rarity_score": 576.5528083028082,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1137.png",
            "scheme": "https"
        }
    },
    "1091": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1091",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1091",
                "rarity_score": 570.0663655710591,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1091.png",
            "scheme": "https"
        }
    },
    "54": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #54",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Sushi Lovin'",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "54",
                "rarity_score": 439.8410799726589,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/54.png",
            "scheme": "https"
        }
    },
    "788": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #788",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 004",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Violet",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "788",
                "rarity_score": 616.1376795247763,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/788.png",
            "scheme": "https"
        }
    },
    "1245": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1245",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "1245",
                "rarity_score": 494.3188604873387,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1245.png",
            "scheme": "https"
        }
    },
    "272": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #272",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 033",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mushroom Hat",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "272",
                "rarity_score": 824.5328442049754,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/272.png",
            "scheme": "https"
        }
    },
    "622": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #622",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "622",
                "rarity_score": 568.2984809308339,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/622.png",
            "scheme": "https"
        }
    },
    "335": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #335",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "335",
                "rarity_score": 552.4252708076239,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/335.png",
            "scheme": "https"
        }
    },
    "1302": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1302",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1302",
                "rarity_score": 412.8432494279176,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1302.png",
            "scheme": "https"
        }
    },
    "765": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #765",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 15",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "765",
                "rarity_score": 463.3491605695809,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/765.png",
            "scheme": "https"
        }
    },
    "1179": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1179",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 056",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1179",
                "rarity_score": 453.9646216991755,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1179.png",
            "scheme": "https"
        }
    },
    "858": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #858",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 10",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "858",
                "rarity_score": 576.4364961086274,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/858.png",
            "scheme": "https"
        }
    },
    "1494": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1494",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1494",
                "rarity_score": 353.37528355560437,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1494.png",
            "scheme": "https"
        }
    },
    "634": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #634",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 10",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "634",
                "rarity_score": 461.22184964398076,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/634.png",
            "scheme": "https"
        }
    },
    "264": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #264",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 08",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "264",
                "rarity_score": 1936.1190892440893,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/264.png",
            "scheme": "https"
        }
    },
    "1249": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1249",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1249",
                "rarity_score": 414.7287862513426,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1249.png",
            "scheme": "https"
        }
    },
    "769": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #769",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 2",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "769",
                "rarity_score": 843.1268678299432,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/769.png",
            "scheme": "https"
        }
    },
    "1314": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1314",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1314",
                "rarity_score": 504.98228300446647,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1314.png",
            "scheme": "https"
        }
    },
    "319": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #319",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Spearmint",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "319",
                "rarity_score": 419.87208589279976,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/319.png",
            "scheme": "https"
        }
    },
    "1195": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1195",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1195",
                "rarity_score": 513.7244660101803,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1195.png",
            "scheme": "https"
        }
    },
    "458": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #458",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 01",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Feather Headband",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "458",
                "rarity_score": 764.7322190005116,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/458.png",
            "scheme": "https"
        }
    },
    "1482": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1482",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Wool Scarf",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1482",
                "rarity_score": 676.5396365844385,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1482.png",
            "scheme": "https"
        }
    },
    "1178": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1178",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Pamela Ferris",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "White House",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pantsuit",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Coco", "score": 1500.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Fweedom",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Brown", "score": 750.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Pearl Necklace",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Brown Wig",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1178",
                "rarity_score": 10250.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1178.png",
            "scheme": "https"
        }
    },
    "13": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #13",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 7",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "13",
                "rarity_score": 382.27921603423977,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/13.png",
            "scheme": "https"
        }
    },
    "1206": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1206",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1206",
                "rarity_score": 349.8965495289025,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1206.png",
            "scheme": "https"
        }
    },
    "231": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #231",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Suit",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "231",
                "rarity_score": 412.6648936170213,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/231.png",
            "scheme": "https"
        }
    },
    "397": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #397",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "397",
                "rarity_score": 359.2099606976173,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/397.png",
            "scheme": "https"
        }
    },
    "661": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #661",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange 2",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "661",
                "rarity_score": 552.7051323962079,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/661.png",
            "scheme": "https"
        }
    },
    "376": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #376",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 012",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "376",
                "rarity_score": 369.1775481218627,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/376.png",
            "scheme": "https"
        }
    },
    "1341": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1341",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 005",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1341",
                "rarity_score": 547.295081967213,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1341.png",
            "scheme": "https"
        }
    },
    "726": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #726",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 02",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Wizard Hat",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "726",
                "rarity_score": 559.5523265554322,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/726.png",
            "scheme": "https"
        }
    },
    "42": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #42",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "42",
                "rarity_score": 578.6227916227915,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/42.png",
            "scheme": "https"
        }
    },
    "817": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #817",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 09",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Super Kawaii K 9",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "817",
                "rarity_score": 747.7872670807453,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/817.png",
            "scheme": "https"
        }
    },
    "1083": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1083",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple $kda",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1083",
                "rarity_score": 633.8698214633628,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1083.png",
            "scheme": "https"
        }
    },
    "41": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #41",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 009",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "41",
                "rarity_score": 560.3084415584415,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/41.png",
            "scheme": "https"
        }
    },
    "818": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #818",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Wagmi",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "818",
                "rarity_score": 333.3443126921388,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/818.png",
            "scheme": "https"
        }
    },
    "1084": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1084",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dragon Mask",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1084",
                "rarity_score": 539.1372764972989,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1084.png",
            "scheme": "https"
        }
    },
    "1205": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1205",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 11",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1205",
                "rarity_score": 363.2530512965296,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1205.png",
            "scheme": "https"
        }
    },
    "232": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #232",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "232",
                "rarity_score": 433.84576630266287,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/232.png",
            "scheme": "https"
        }
    },
    "398": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #398",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 030",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 04",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "398",
                "rarity_score": 608.3101317650562,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/398.png",
            "scheme": "https"
        }
    },
    "662": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #662",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey & Blue Eyes 01",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "662",
                "rarity_score": 800.8928571428571,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/662.png",
            "scheme": "https"
        }
    },
    "375": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #375",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 9",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "375",
                "rarity_score": 898.319537605252,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/375.png",
            "scheme": "https"
        }
    },
    "1342": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1342",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 11",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1342",
                "rarity_score": 401.4473103642928,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1342.png",
            "scheme": "https"
        }
    },
    "725": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #725",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Ramen",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "725",
                "rarity_score": 604.5209542230818,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/725.png",
            "scheme": "https"
        }
    },
    "457": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #457",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Chicken Nuggie",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "457",
                "rarity_score": 727.8756052058595,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/457.png",
            "scheme": "https"
        }
    },
    "1196": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1196",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1196",
                "rarity_score": 393.9148936170213,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1196.png",
            "scheme": "https"
        }
    },
    "1481": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1481",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 016",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1481",
                "rarity_score": 470.8180708180708,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1481.png",
            "scheme": "https"
        }
    },
    "1177": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1177",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1177",
                "rarity_score": 405.9296814296814,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1177.png",
            "scheme": "https"
        }
    },
    "14": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #14",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "14",
                "rarity_score": 348.538961038961,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/14.png",
            "scheme": "https"
        }
    },
    "633": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #633",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "633",
                "rarity_score": 437.62071262071254,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/633.png",
            "scheme": "https"
        }
    },
    "263": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #263",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Medium Purple",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "263",
                "rarity_score": 440.83521033831596,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/263.png",
            "scheme": "https"
        }
    },
    "1250": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1250",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1250",
                "rarity_score": 411.7577298477919,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1250.png",
            "scheme": "https"
        }
    },
    "770": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #770",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "770",
                "rarity_score": 388.18793911007026,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/770.png",
            "scheme": "https"
        }
    },
    "1313": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1313",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1313",
                "rarity_score": 434.2984895539243,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1313.png",
            "scheme": "https"
        }
    },
    "320": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #320",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 03",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "320",
                "rarity_score": 388.5918905655748,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/320.png",
            "scheme": "https"
        }
    },
    "1180": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1180",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1180",
                "rarity_score": 541.1448115008486,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1180.png",
            "scheme": "https"
        }
    },
    "857": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #857",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "857",
                "rarity_score": 360.80593419660084,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/857.png",
            "scheme": "https"
        }
    },
    "1493": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1493",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1493",
                "rarity_score": 483.5033302618706,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1493.png",
            "scheme": "https"
        }
    },
    "1246": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1246",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1246",
                "rarity_score": 501.17255130490423,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1246.png",
            "scheme": "https"
        }
    },
    "787": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #787",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "787",
                "rarity_score": 453.3730129557569,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/787.png",
            "scheme": "https"
        }
    },
    "271": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #271",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 3",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "271",
                "rarity_score": 731.3748863516734,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/271.png",
            "scheme": "https"
        }
    },
    "621": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #621",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 026",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "621",
                "rarity_score": 573.596938466807,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/621.png",
            "scheme": "https"
        }
    },
    "336": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #336",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 07",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "336",
                "rarity_score": 474.8430141287284,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/336.png",
            "scheme": "https"
        }
    },
    "1301": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1301",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1301",
                "rarity_score": 433.64772727272725,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1301.png",
            "scheme": "https"
        }
    },
    "766": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #766",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 011",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "766",
                "rarity_score": 552.0100672274585,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/766.png",
            "scheme": "https"
        }
    },
    "418": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #418",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 008",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "418",
                "rarity_score": 318.8673701312731,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/418.png",
            "scheme": "https"
        }
    },
    "1138": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1138",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1138",
                "rarity_score": 337.70353034062714,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1138.png",
            "scheme": "https"
        }
    },
    "1092": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1092",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1092",
                "rarity_score": 357.4545454545455,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1092.png",
            "scheme": "https"
        }
    },
    "53": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #53",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 052",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Eyes 01",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "53",
                "rarity_score": 446.77926311848756,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/53.png",
            "scheme": "https"
        }
    },
    "674": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #674",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 015",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "674",
                "rarity_score": 452.70748965628826,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/674.png",
            "scheme": "https"
        }
    },
    "224": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #224",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "224",
                "rarity_score": 358.7469380665242,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/224.png",
            "scheme": "https"
        }
    },
    "1209": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1209",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 09",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1209",
                "rarity_score": 452.3654916512059,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1209.png",
            "scheme": "https"
        }
    },
    "729": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #729",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Crown",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "729",
                "rarity_score": 625.2950478398498,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/729.png",
            "scheme": "https"
        }
    },
    "1354": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1354",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1354",
                "rarity_score": 359.42736688858224,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1354.png",
            "scheme": "https"
        }
    },
    "359": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #359",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "359",
                "rarity_score": 502.26368229208913,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/359.png",
            "scheme": "https"
        }
    },
    "176": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #176",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "176",
                "rarity_score": 251.91829403095062,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/176.png",
            "scheme": "https"
        }
    },
    "1141": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1141",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 010",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1141",
                "rarity_score": 523.2216323822163,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1141.png",
            "scheme": "https"
        }
    },
    "526": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #526",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Chain",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemoncello",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "526",
                "rarity_score": 1110.1150793650793,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/526.png",
            "scheme": "https"
        }
    },
    "873": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #873",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 039",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "873",
                "rarity_score": 498.0420418635522,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/873.png",
            "scheme": "https"
        }
    },
    "930": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #930",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 02",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "930",
                "rarity_score": 425.1778589450214,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/930.png",
            "scheme": "https"
        }
    },
    "1006": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1006",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Purple Sharkie",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Pink Chickie Nuggies",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Shark Skin",
                        "score": 1500.0
                    },
                    { "trait_type": "Skin", "value": "Tofu", "score": 750.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Toothless",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Pink", "score": 1500.0 }
                ],
                "compiler": "nft-inator.com",
                "id": "1006",
                "rarity_score": 8250.0,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1006.png",
            "scheme": "https"
        }
    },
    "1456": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1456",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Shower Cap",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1456",
                "rarity_score": 325.36691043878983,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1456.png",
            "scheme": "https"
        }
    },
    "197": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #197",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "197",
                "rarity_score": 659.7080764763691,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/197.png",
            "scheme": "https"
        }
    },
    "461": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #461",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 03",
                        "score": 375.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "461",
                "rarity_score": 590.0359457545885,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/461.png",
            "scheme": "https"
        }
    },
    "1283": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1283",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 03",
                        "score": 500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1283",
                "rarity_score": 978.3229813664597,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1283.png",
            "scheme": "https"
        }
    },
    "77": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #77",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 016",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 04",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "77",
                "rarity_score": 362.32897537245367,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/77.png",
            "scheme": "https"
        }
    },
    "988": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #988",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 11",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "988",
                "rarity_score": 437.81713738245975,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/988.png",
            "scheme": "https"
        }
    },
    "822": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #822",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 04",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "822",
                "rarity_score": 572.689393939394,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/822.png",
            "scheme": "https"
        }
    },
    "569": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #569",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple To The Moon",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "569",
                "rarity_score": 417.5019396912333,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/569.png",
            "scheme": "https"
        }
    },
    "1114": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1114",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Chicken Nuggs",
                        "score": 250.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 32",
                        "score": 25.423728813559322
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 04",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hot Pink",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1114",
                "rarity_score": 769.067085456916,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1114.png",
            "scheme": "https"
        }
    },
    "119": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #119",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "119",
                "rarity_score": 335.265369305617,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/119.png",
            "scheme": "https"
        }
    },
    "434": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #434",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Headphones",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "434",
                "rarity_score": 547.1133705004673,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/434.png",
            "scheme": "https"
        }
    },
    "1399": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1399",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1399",
                "rarity_score": 535.8424618859401,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1399.png",
            "scheme": "https"
        }
    },
    "1049": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1049",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 06",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1049",
                "rarity_score": 545.1581462016245,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1049.png",
            "scheme": "https"
        }
    },
    "965": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #965",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 043",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 10",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "965",
                "rarity_score": 442.2792170677599,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/965.png",
            "scheme": "https"
        }
    },
    "96": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #96",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Shades",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Toxic Green",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "96",
                "rarity_score": 600.7371249440215,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/96.png",
            "scheme": "https"
        }
    },
    "1378": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1378",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 04",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1378",
                "rarity_score": 494.7054074510134,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1378.png",
            "scheme": "https"
        }
    },
    "208": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #208",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "208",
                "rarity_score": 626.8577225272415,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/208.png",
            "scheme": "https"
        }
    },
    "1395": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1395",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Kadena",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1395",
                "rarity_score": 328.5138240983047,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1395.png",
            "scheme": "https"
        }
    },
    "658": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #658",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 11",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Lemonaid",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "658",
                "rarity_score": 506.314220723194,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/658.png",
            "scheme": "https"
        }
    },
    "135": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #135",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "135",
                "rarity_score": 297.46919746919747,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/135.png",
            "scheme": "https"
        }
    },
    "1102": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1102",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mint Green",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1102",
                "rarity_score": 467.7783080515892,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1102.png",
            "scheme": "https"
        }
    },
    "565": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #565",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 03",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "565",
                "rarity_score": 420.45864661654133,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/565.png",
            "scheme": "https"
        }
    },
    "834": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #834",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "834",
                "rarity_score": 334.32361194787075,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/834.png",
            "scheme": "https"
        }
    },
    "80": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #80",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 027",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "80",
                "rarity_score": 341.8354743083004,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/80.png",
            "scheme": "https"
        }
    },
    "4": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #4",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Suit",
                        "score": 250.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "4",
                "rarity_score": 595.4931764453041,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/4.png",
            "scheme": "https"
        }
    },
    "969": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #969",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 028",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 03",
                        "score": 214.28571428571428
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "969",
                "rarity_score": 434.5596133190118,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/969.png",
            "scheme": "https"
        }
    },
    "588": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #588",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 020",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 03",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "588",
                "rarity_score": 1855.7888198757762,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/588.png",
            "scheme": "https"
        }
    },
    "1045": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1045",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1045",
                "rarity_score": 441.9402251886969,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1045.png",
            "scheme": "https"
        }
    },
    "1415": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1415",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1415",
                "rarity_score": 324.0231332807723,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1415.png",
            "scheme": "https"
        }
    },
    "422": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #422",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 05",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "422",
                "rarity_score": 352.2642816121077,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/422.png",
            "scheme": "https"
        }
    },
    "1379": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1379",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 022",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1379",
                "rarity_score": 288.43615000744825,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1379.png",
            "scheme": "https"
        }
    },
    "38": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #38",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "38",
                "rarity_score": 412.91742531109196,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/38.png",
            "scheme": "https"
        }
    },
    "861": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #861",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "861",
                "rarity_score": 288.2314481856816,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/861.png",
            "scheme": "https"
        }
    },
    "530": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #530",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Spearmint Solid",
                        "score": 53.57142857142857
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 02",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "White Shades",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "530",
                "rarity_score": 469.9710012210012,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/530.png",
            "scheme": "https"
        }
    },
    "1153": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1153",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 5",
                        "score": 750.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Sad Eyes 01",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1153",
                "rarity_score": 1309.3645484949834,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1153.png",
            "scheme": "https"
        }
    },
    "160": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #160",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 001",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Red Suit",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "160",
                "rarity_score": 507.55671450638266,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/160.png",
            "scheme": "https"
        }
    },
    "473": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #473",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Red Velvet",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "473",
                "rarity_score": 506.0751748251748,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/473.png",
            "scheme": "https"
        }
    },
    "1440": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1440",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 013",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 03",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1440",
                "rarity_score": 628.0845556514237,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1440.png",
            "scheme": "https"
        }
    },
    "1010": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1010",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 011",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ball Cap",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1010",
                "rarity_score": 425.6035819778408,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1010.png",
            "scheme": "https"
        }
    },
    "926": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #926",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Sushi Lovin'",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "926",
                "rarity_score": 432.30866564917363,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/926.png",
            "scheme": "https"
        }
    },
    "1337": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1337",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Olive Solid",
                        "score": 55.55555555555556
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Grey Eyes 04",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1337",
                "rarity_score": 873.3888888888889,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1337.png",
            "scheme": "https"
        }
    },
    "1291": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1291",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue To The Moon",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 13",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1291",
                "rarity_score": 604.8007246376812,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1291.png",
            "scheme": "https"
        }
    },
    "247": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #247",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 12",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "247",
                "rarity_score": 629.7833295289356,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/247.png",
            "scheme": "https"
        }
    },
    "617": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #617",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 1",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 05",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "617",
                "rarity_score": 504.882955987444,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/617.png",
            "scheme": "https"
        }
    },
    "394": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #394",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    },
                    { "trait_type": "Headgear", "value": "Halo", "score": 62.5 }
                ],
                "compiler": "nft-inator.com",
                "id": "394",
                "rarity_score": 510.17955259026684,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/394.png",
            "scheme": "https"
        }
    },
    "679": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #679",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 067",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "679",
                "rarity_score": 533.4691795618569,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/679.png",
            "scheme": "https"
        }
    },
    "949": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #949",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 11",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "949",
                "rarity_score": 666.033180778032,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/949.png",
            "scheme": "https"
        }
    },
    "1065": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1065",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 019",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 15",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1065",
                "rarity_score": 369.4883545331565,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1065.png",
            "scheme": "https"
        }
    },
    "1435": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1435",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1435",
                "rarity_score": 433.9127834408155,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1435.png",
            "scheme": "https"
        }
    },
    "402": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #402",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 016",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 07",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Magenta",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "402",
                "rarity_score": 499.2544590194693,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/402.png",
            "scheme": "https"
        }
    },
    "115": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #115",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Hon",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 08",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "115",
                "rarity_score": 351.8808423459393,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/115.png",
            "scheme": "https"
        }
    },
    "1122": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1122",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 037",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1122",
                "rarity_score": 319.4261088350821,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1122.png",
            "scheme": "https"
        }
    },
    "1088": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1088",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Blueberry Milk 3",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1088",
                "rarity_score": 547.0686346774353,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1088.png",
            "scheme": "https"
        }
    },
    "545": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #545",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 06",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "545",
                "rarity_score": 342.9177253905515,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/545.png",
            "scheme": "https"
        }
    },
    "814": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #814",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 08",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "814",
                "rarity_score": 368.3109361212066,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/814.png",
            "scheme": "https"
        }
    },
    "791": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #791",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 018",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "791",
                "rarity_score": 570.8338386288301,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/791.png",
            "scheme": "https"
        }
    },
    "267": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #267",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 021",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 07",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "267",
                "rarity_score": 808.7575468010251,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/267.png",
            "scheme": "https"
        }
    },
    "637": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #637",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 050",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Hodl",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 08",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "637",
                "rarity_score": 431.85577061967354,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/637.png",
            "scheme": "https"
        }
    },
    "1317": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1317",
                "attributes": [
                    {
                        "trait_type": "1 of 1",
                        "value": "Pit Bull Bushido",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Castle",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Samurai Armor",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Matcha",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Heroic",
                        "score": 1500.0
                    },
                    { "trait_type": "Eyes", "value": "Hazel", "score": 500.0 },
                    {
                        "trait_type": "Accessories",
                        "value": "Katana",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Samurai Helmet",
                        "score": 1500.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1317",
                "rarity_score": 11000.0,
                "rarity_level": "LEGENDARY"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1317.png",
            "scheme": "https"
        }
    },
    "286": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #286",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 009",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 08",
                        "score": 250.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "286",
                "rarity_score": 509.03846153846155,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/286.png",
            "scheme": "https"
        }
    },
    "453": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #453",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "453",
                "rarity_score": 326.66673972122385,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/453.png",
            "scheme": "https"
        }
    },
    "1460": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1460",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kadena",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 08",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1460",
                "rarity_score": 376.9949509909317,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1460.png",
            "scheme": "https"
        }
    },
    "1030": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1030",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1030",
                "rarity_score": 532.9160422910422,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1030.png",
            "scheme": "https"
        }
    },
    "906": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #906",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 010",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 36",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Sad Eyes 02",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "906",
                "rarity_score": 412.1101987705838,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/906.png",
            "scheme": "https"
        }
    },
    "18": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #18",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 007",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 20",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 06",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "18",
                "rarity_score": 345.7053986159826,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/18.png",
            "scheme": "https"
        }
    },
    "841": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #841",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 006",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blueish Green Diamond Hands",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 14",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Orange Creamsicle",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "841",
                "rarity_score": 527.3345548633308,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/841.png",
            "scheme": "https"
        }
    },
    "510": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #510",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 015",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kitsune",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "510",
                "rarity_score": 496.12220831150194,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/510.png",
            "scheme": "https"
        }
    },
    "1173": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1173",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 03",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Wig",
                        "score": 100.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1173",
                "rarity_score": 550.0092992114269,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1173.png",
            "scheme": "https"
        }
    },
    "140": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #140",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 03",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "140",
                "rarity_score": 401.8273128437535,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/140.png",
            "scheme": "https"
        }
    },
    "783": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #783",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 029",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Strawberry Milk",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Chain",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "783",
                "rarity_score": 429.48615748208726,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/783.png",
            "scheme": "https"
        }
    },
    "290": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #290",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 004",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 02",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Sunglasses",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "290",
                "rarity_score": 545.2729468599034,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/290.png",
            "scheme": "https"
        }
    },
    "910": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #910",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 001",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Diamond Hands",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "910",
                "rarity_score": 380.3860005540678,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/910.png",
            "scheme": "https"
        }
    },
    "1026": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1026",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Img_0373",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Blue Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 24",
                        "score": 34.883720930232556
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueberry",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1026",
                "rarity_score": 464.3189544125972,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1026.png",
            "scheme": "https"
        }
    },
    "1476": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1476",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 061",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 12",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1476",
                "rarity_score": 451.70634095119226,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1476.png",
            "scheme": "https"
        }
    },
    "441": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #441",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 017",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Radio Mic",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "441",
                "rarity_score": 493.7092327961893,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/441.png",
            "scheme": "https"
        }
    },
    "156": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #156",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 1",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Periwinkle Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 03",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Periwinkle",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "156",
                "rarity_score": 1262.6430205949657,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/156.png",
            "scheme": "https"
        }
    },
    "1497": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1497",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 014",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 06",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1497",
                "rarity_score": 635.0223546944858,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1497.png",
            "scheme": "https"
        }
    },
    "1161": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1161",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Whale",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1161",
                "rarity_score": 416.6196907964503,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1161.png",
            "scheme": "https"
        }
    },
    "506": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #506",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 01",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "506",
                "rarity_score": 545.2623914465179,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/506.png",
            "scheme": "https"
        }
    },
    "853": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #853",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 051",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Whale",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "853",
                "rarity_score": 377.11151553364664,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/853.png",
            "scheme": "https"
        }
    },
    "228": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #228",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 026",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Suit",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Dizzy Eyes 01",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mushroom Hat",
                        "score": 300.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "228",
                "rarity_score": 905.795341437893,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/228.png",
            "scheme": "https"
        }
    },
    "382": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #382",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Green_milk 1",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 31",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Copper Chain",
                        "score": 45.45454545454545
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "382",
                "rarity_score": 596.6516775143891,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/382.png",
            "scheme": "https"
        }
    },
    "678": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #678",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Diamond Hands",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "678",
                "rarity_score": 332.14452214452217,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/678.png",
            "scheme": "https"
        }
    },
    "695": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #695",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 003",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dark Pink",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 14",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "695",
                "rarity_score": 379.65734265734267,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/695.png",
            "scheme": "https"
        }
    },
    "1358": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1358",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 054",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Hodl",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 27",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1358",
                "rarity_score": 376.3906783997661,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1358.png",
            "scheme": "https"
        }
    },
    "414": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #414",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 13",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 09",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "414",
                "rarity_score": 717.8333333333333,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/414.png",
            "scheme": "https"
        }
    },
    "1419": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1419",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 006",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Grey Hodl",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 17",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1419",
                "rarity_score": 424.53484015984014,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1419.png",
            "scheme": "https"
        }
    },
    "1069": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1069",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 03",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Grey",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1069",
                "rarity_score": 552.5673731030873,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1069.png",
            "scheme": "https"
        }
    },
    "945": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #945",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    { "trait_type": "Skin", "value": "Kadena", "score": 93.75 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Afro",
                        "score": 68.18181818181819
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "945",
                "rarity_score": 548.6844139569703,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/945.png",
            "scheme": "https"
        }
    },
    "57": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #57",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 059",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Ramen",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 10",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Hairbow",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "57",
                "rarity_score": 485.5795454545455,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/57.png",
            "scheme": "https"
        }
    },
    "802": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #802",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 028",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kiwi",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Headphones",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "802",
                "rarity_score": 423.28697237164056,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/802.png",
            "scheme": "https"
        }
    },
    "549": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #549",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Grapefruit 4",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Diamond Hands Hot Pink",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "549",
                "rarity_score": 1778.7970633798075,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/549.png",
            "scheme": "https"
        }
    },
    "1134": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1134",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 002",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Ice Ice Baby",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 01",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Shades",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1134",
                "rarity_score": 510.91058064742276,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1134.png",
            "scheme": "https"
        }
    },
    "99": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #99",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 021",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Hon",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "99",
                "rarity_score": 395.39250074775,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/99.png",
            "scheme": "https"
        }
    },
    "316": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #316",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 002",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 12",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "316",
                "rarity_score": 421.79450983798813,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/316.png",
            "scheme": "https"
        }
    },
    "1321": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1321",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemonaid Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 02",
                        "score": 136.36363636363637
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1321",
                "rarity_score": 617.9075582802291,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1321.png",
            "scheme": "https"
        }
    },
    "746": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #746",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 03",
                        "score": 107.14285714285714
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "746",
                "rarity_score": 278.51597160603376,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/746.png",
            "scheme": "https"
        }
    },
    "1287": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1287",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 040",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue Whale",
                        "score": 71.42857142857143
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 11",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Tan Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Headphones",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blonde Toupee",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1287",
                "rarity_score": 495.4315575605853,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1287.png",
            "scheme": "https"
        }
    },
    "1266": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1266",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 014",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 03",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1266",
                "rarity_score": 454.38930353511887,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1266.png",
            "scheme": "https"
        }
    },
    "251": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #251",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 032",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 08",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "251",
                "rarity_score": 299.0803856978662,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/251.png",
            "scheme": "https"
        }
    },
    "601": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #601",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 3",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Fruit Salad Hat",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "601",
                "rarity_score": 640.953490953491,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/601.png",
            "scheme": "https"
        }
    },
    "22": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #22",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 062",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Blue $kda",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Blue Sad Eyes 01",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "22",
                "rarity_score": 986.5921042329976,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/22.png",
            "scheme": "https"
        }
    },
    "877": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #877",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Suit",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "877",
                "rarity_score": 340.6129675950648,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/877.png",
            "scheme": "https"
        }
    },
    "480": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #480",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 05",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Chain",
                        "score": 68.18181818181819
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "480",
                "rarity_score": 422.74723740267257,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/480.png",
            "scheme": "https"
        }
    },
    "193": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #193",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 031",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Mint Ice Cream",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 5",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 03",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Nightvision",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "193",
                "rarity_score": 474.86064140777256,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/193.png",
            "scheme": "https"
        }
    },
    "896": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #896",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 010",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Wagmi",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 18",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 07",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Green Sunglasses",
                        "score": 40.54054054054054
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "896",
                "rarity_score": 476.005076005076,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/896.png",
            "scheme": "https"
        }
    },
    "709": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #709",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Strawberry Milk 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Hot Pink Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Eyes 01",
                        "score": 115.38461538461539
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Round Glasses",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blue Towel",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "709",
                "rarity_score": 709.5567722713632,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/709.png",
            "scheme": "https"
        }
    },
    "1374": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1374",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 022",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 09",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "White",
                        "score": 48.38709677419355
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1374",
                "rarity_score": 499.59909204912253,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1374.png",
            "scheme": "https"
        }
    },
    "339": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #339",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 025",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 9",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 03",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Headphones",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Green",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "339",
                "rarity_score": 610.4532828282828,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/339.png",
            "scheme": "https"
        }
    },
    "654": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #654",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 042",
                        "score": 150.0
                    },
                    { "trait_type": "Clothes", "value": "Kda", "score": 300.0 },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 4",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 05",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dark Purple",
                        "score": 50.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "654",
                "rarity_score": 740.4419351140663,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/654.png",
            "scheme": "https"
        }
    },
    "204": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #204",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 008",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tiffany Solid",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 03",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Blueish Green",
                        "score": 57.69230769230769
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "204",
                "rarity_score": 509.4626736986732,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/204.png",
            "scheme": "https"
        }
    },
    "1229": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1229",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 007",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Mint Blue Solid",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Diamond Head",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1229",
                "rarity_score": 602.7811004784688,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1229.png",
            "scheme": "https"
        }
    },
    "1118": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1118",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 034",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Dusty Pink",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 1",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Sad Eyes 02",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Nose Ring",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Toupee",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1118",
                "rarity_score": 475.983325037332,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1118.png",
            "scheme": "https"
        }
    },
    "984": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #984",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 072",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Ramen",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 13",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "984",
                "rarity_score": 488.06996825054335,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/984.png",
            "scheme": "https"
        }
    },
    "73": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #73",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 048",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 21",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 06",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Blue Shades",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "73",
                "rarity_score": 482.3561621444917,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/73.png",
            "scheme": "https"
        }
    },
    "592": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #592",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Limes 6",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 33",
                        "score": 31.25
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Hazel Eyes 11",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "592",
                "rarity_score": 575.3613234591495,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/592.png",
            "scheme": "https"
        }
    },
    "438": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #438",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 010",
                        "score": 300.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green & Brown Eyes 01",
                        "score": 115.38461538461539
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "438",
                "rarity_score": 542.196209587514,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/438.png",
            "scheme": "https"
        }
    },
    "355": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #355",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 005",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 14",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 02",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Rose",
                        "score": 65.21739130434783
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "355",
                "rarity_score": 606.6810001592611,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/355.png",
            "scheme": "https"
        }
    },
    "1362": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1362",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Purple Solid",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 07",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Streamer",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Purple Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1362",
                "rarity_score": 628.0953436018191,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1362.png",
            "scheme": "https"
        }
    },
    "705": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #705",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 012",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 10",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Sad Eyes 01",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Ice Baby",
                        "score": 60.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "705",
                "rarity_score": 840.9808979840035,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/705.png",
            "scheme": "https"
        }
    },
    "1225": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1225",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 013",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Kda",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 22",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 12",
                        "score": 150.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1225",
                "rarity_score": 427.4804905239688,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1225.png",
            "scheme": "https"
        }
    },
    "212": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #212",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 057",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink To The Moon",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 30",
                        "score": 32.608695652173914
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 11",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Mask",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kiwi",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "212",
                "rarity_score": 556.9504579045201,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/212.png",
            "scheme": "https"
        }
    },
    "642": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #642",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 017",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Wagmi",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blue Velvet",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Angry Eyes 02",
                        "score": 75.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Valkyrie",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "642",
                "rarity_score": 427.1381578947369,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/642.png",
            "scheme": "https"
        }
    },
    "61": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #61",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Green Whale",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 25",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Worried Sad Eyes 02",
                        "score": 250.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Black Sunglasses",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Ice Pack",
                        "score": 88.23529411764706
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "61",
                "rarity_score": 560.7074161734071,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/61.png",
            "scheme": "https"
        }
    },
    "992": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #992",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 019",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "White",
                        "score": 51.724137931034484
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 34",
                        "score": 27.272727272727273
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "992",
                "rarity_score": 311.73731316160104,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/992.png",
            "scheme": "https"
        }
    },
    "838": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #838",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 071",
                        "score": 136.36363636363637
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Chicken Nuggs",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 16",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Happy Eyes 01",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sunset Wig",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "838",
                "rarity_score": 693.1662940884253,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/838.png",
            "scheme": "https"
        }
    },
    "584": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #584",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 070",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Kadena",
                        "score": 42.857142857142854
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Blueberry Cheesecake",
                        "score": 10.869565217391305
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 23",
                        "score": 35.714285714285715
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 01",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Pink Headphones",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Pirate",
                        "score": 78.94736842105263
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "584",
                "rarity_score": 480.8883622098725,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/584.png",
            "scheme": "https"
        }
    },
    "8": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #8",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Orange Milk 5",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Medium Purple Solid",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 09",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Red Chain",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sky Blue",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "8",
                "rarity_score": 858.3870967741935,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/8.png",
            "scheme": "https"
        }
    },
    "750": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #750",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 004",
                        "score": 93.75
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Kda Burn",
                        "score": 214.28571428571428
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Sad Eyes 01",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Gold Chain",
                        "score": 34.09090909090909
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Dusty Pink",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "750",
                "rarity_score": 558.0301642801643,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/750.png",
            "scheme": "https"
        }
    },
    "1333": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1333",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 005",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Tiramisu",
                        "score": 13.043478260869565
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 19",
                        "score": 37.5
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown Eyes 12",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Grey Sunglasses",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Purple",
                        "score": 55.55555555555556
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1333",
                "rarity_score": 559.6246748420662,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1333.png",
            "scheme": "https"
        }
    },
    "300": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #300",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 023",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Ramen",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Peachy Keen",
                        "score": 12.295081967213115
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 2",
                        "score": 41.666666666666664
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Sad Eyes 01",
                        "score": 83.33333333333333
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Karate K 9",
                        "score": 31.914893617021278
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Viking",
                        "score": 93.75
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "300",
                "rarity_score": 393.1683089175677,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/300.png",
            "scheme": "https"
        }
    },
    "613": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #613",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 075",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Yellow Sushi Lovin'",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 12",
                        "score": 27.77777777777778
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Top Hat",
                        "score": 71.42857142857143
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "613",
                "rarity_score": 450.9622020491586,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/613.png",
            "scheme": "https"
        }
    },
    "243": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #243",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Lemons 4",
                        "score": 375.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Light Yellow",
                        "score": 55.55555555555556
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Hot Coacoa",
                        "score": 12.0
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 3",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Eyes 09",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "243",
                "rarity_score": 660.6678004535147,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/243.png",
            "scheme": "https"
        }
    },
    "1270": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1270",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 003",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Tangello Solid",
                        "score": 60.0
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 6",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Light Blue Eyes 05",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Miner's Helmet",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1270",
                "rarity_score": 850.7114845938376,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1270.png",
            "scheme": "https"
        }
    },
    "1157": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #1157",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 018",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Pink Hon",
                        "score": 125.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "White Chocolate",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 8",
                        "score": 39.473684210526315
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes 03",
                        "score": 75.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "1157",
                "rarity_score": 438.51214574898785,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/1157.png",
            "scheme": "https"
        }
    },
    "496": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #496",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 041",
                        "score": 88.23529411764706
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Sushi Lovin'",
                        "score": 60.0
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Rubarb",
                        "score": 11.538461538461538
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 7",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes 05",
                        "score": 187.5
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "496",
                "rarity_score": 426.2211240771612,
                "rarity_level": "COMMON"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/496.png",
            "scheme": "https"
        }
    },
    "34": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #34",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 063",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Black Suit",
                        "score": 65.21739130434783
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chai",
                        "score": 10.714285714285714
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 35",
                        "score": 36.58536585365854
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Tan Sad Eyes 01",
                        "score": 187.5
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Eye Patch",
                        "score": 38.46153846153846
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "34",
                "rarity_score": 671.8119146671638,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/34.png",
            "scheme": "https"
        }
    },
    "880": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #880",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gradient 005",
                        "score": 107.14285714285714
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Purple Solid",
                        "score": 46.875
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Strawberry Cheesecake",
                        "score": 14.851485148514852
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 28",
                        "score": 48.38709677419355
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Brown & Green Eyes 05",
                        "score": 1500.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Orange Shades",
                        "score": 78.94736842105263
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Straw Hat",
                        "score": 83.33333333333333
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "880",
                "rarity_score": 1879.5371408199514,
                "rarity_level": "EPIC"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/880.png",
            "scheme": "https"
        }
    },
    "181": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #181",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Kda Colors 025",
                        "score": 500.0
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Lemoncello Solid",
                        "score": 57.69230769230769
                    },
                    {
                        "trait_type": "Skin",
                        "value": "Chocolate",
                        "score": 10.94890510948905
                    },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 26",
                        "score": 26.31578947368421
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Grey Eyes 07",
                        "score": 125.0
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "181",
                "rarity_score": 719.9570022754809,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/181.png",
            "scheme": "https"
        }
    },
    "477": {
        "datum": {
            "datum": {
                "name": "Kawaii K-9 #477",
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Color Solid 073",
                        "score": 166.66666666666666
                    },
                    {
                        "trait_type": "Clothes",
                        "value": "Orange Creamsicle",
                        "score": 57.69230769230769
                    },
                    { "trait_type": "Skin", "value": "Mocha", "score": 12.0 },
                    {
                        "trait_type": "Expression",
                        "value": "Mouth 15",
                        "score": 30.612244897959183
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Pink Sad Eyes 02",
                        "score": 150.0
                    },
                    {
                        "trait_type": "Accessories",
                        "value": "Purple Sunglasses",
                        "score": 62.5
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Light Blue",
                        "score": 166.66666666666666
                    }
                ],
                "compiler": "nft-inator.com",
                "id": "477",
                "rarity_score": 646.1378859236002,
                "rarity_level": "RARE"
            },
            "uri": { "data": "nft-data", "scheme": "pact:schema" }
        },
        "uri": {
            "data": "https://kawaii-k9s.nyc3.cdn.digitaloceanspaces.com/477.png",
            "scheme": "https"
        }
    }
}



export {
    kawaii_k9s_metadata
}
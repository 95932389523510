import { Box, Flex, Icon, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import SearchInput from "../CommonComponents/SearchInput";
import RecentlyListed from "./RecentlyAdded";
import { RiLayoutGridFill } from "react-icons/ri";
import { TfiLayoutGrid3Alt } from "react-icons/tfi";
import CollectionCard, {
    CollectionCardProfile,
    LunchPadCollectionCard,
} from "./Card/CollectionCard";

const CardSection = () => {
    const cardDummyData = [
        {
            id: "",
            label: "",
            isSelected: false,
            profileCard: true,
        },
        {
            id: "",
            label: "",
            isSelected: false,
            profileCard: false,
            launchPad: true,
        },
        {
            id: "",
            label: "",
            isSelected: true,
            profileCard: false,
        },
        {
            id: "",
            label: "",
            isSelected: true,
            profileCard: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
        {
            id: "",
            label: "",
            isSelected: false,
        },
    ];
    return (
        <Box py="17px" px="17px">
            <Flex align="center" gap="17px">
                <SearchInput
                    placeholder={"Search NFT ID"}
                    fontSize="14px"
                    maxW="700px"
                />
                <RecentlyListed />

                <Flex align="center" gap="12px">
                    <Box className={"icon"}>
                        <Icon
                            as={RiLayoutGridFill}
                            boxSize={"24px"}
                            color="#ABFCA9"
                            cursor="pointer"
                        />
                    </Box>
                    <Box className={"icon_dark"}>
                        <Icon
                            as={TfiLayoutGrid3Alt}
                            boxSize={"24px"}
                            color="#"
                            cursor="pointer"
                        />
                    </Box>
                </Flex>
            </Flex>
            <Box mt="11px" maxH="472px" overflowY={"auto"}>
                <SimpleGrid
                    columns={[2, 4, 6]}
                    spacingX="8px"
                    spacingY={"12px"}
                >
                    {cardDummyData?.map((item, i) => {
                        return (
                            <Box key={i}>
                                {item?.profileCard ? (
                                    <CollectionCardProfile />
                                ) : item?.launchPad ? (
                                    <LunchPadCollectionCard />
                                ) : (
                                    <CollectionCard item={item} />
                                )}
                            </Box>
                        );
                    })}
                </SimpleGrid>
            </Box>
        </Box>
    );
};

export default CardSection;

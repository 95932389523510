import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import UserProfileDetails from "./UserProfileDetails";
import ScreenSize from "../layouts/ScreenSize";
import FilterTab from "./FilterTab";
import SweepTheFloor from "./SweepTheFloor";
import CardSection from "./CardSection";
import TaskTracker from "./TaskTracker/TaskTracker";
import CollectionModal from "./ScrollablePopUp/CollectionModal";

const CollectionPage = () => {
    return (
        <Box>
            <ScreenSize>
                <Box borderTop="1px" borderColor="#424242">
                    <CollectionModal />
                    <Flex>
                        <Box
                            w="100%"
                            maxW="260px"
                            borderRight="1px"
                            borderColor="#424242"
                        >
                            <FilterTab />
                        </Box>

                        <Box w="100%">
                            <UserProfileDetails />
                            <CardSection />
                        </Box>
                    </Flex>
                    <SweepTheFloor />
                </Box>
            </ScreenSize>

            {/* Task Tracker  */}
            <TaskTracker />
        </Box>
    );
};

export default CollectionPage;

import "swiper/css/bundle";

import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { db, useQuery } from "./Firestore/FirestoreService";
import { doc, query } from "firebase/firestore";

import { CrossChainContextProvider } from "./CrossChainOperations/CrossChainContextProvider";
//Auth
import HomePage from "./HomePage/HomePage";
import { IsokoCollectionsContextProvider } from "./IsokoContextProvider/IsokoCollectionsContextProvider";
import { IsokoDialogContextProvider } from "./IsokoContextProvider/IsokoDialogContextProvider";
import { KILL_SWITCH } from "./utils/Constants";
import LaunchPad from "./LaunchPad/LaunchPad";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import Marketplace from "./MarketplaceContext/MarketplaceCollection";
import { MarketplaceContextProvider } from "./MarketplaceContext/MarketplaceContextProvider";
import Mint from "./Mint/Mint";
import NftProductPage from "./NftProductPage/NftProductPage";
import NftSellPage from "./NftListPage/NftListPage";
// Views
import { PactContextProvider } from "./pact/PactContextProvider";
import ProjectCollectionsPage from "./ProjectPage/ProjectCollectionsPage";
import ReactGA from "react-ga";
import ScrollReveal from "./utils/ScrollReveal";
import { UserContextProvider } from "./UserProfilePage/UserContextProvider";
import UserProfile from "./UserProfilePage/UserProfile";
import { checkIfNullOrUndefined } from "./utils/utils";
import useFirestore from "./Firestore/useFirestore";
import CollectionPage from "./NewCollection/CollectionPage";

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize("UA-000000-01");

const trackPage = (page) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const App = () => {
    const childRef = useRef();
    const [siteOperationControl, setSiteOperationControl] = useState({
        KILL_SWITCH: false,
    });
    const adminControlCollectionQuery = useFirestore(
        query(doc(db, "/admin_control/operation")),
        async (result) => {
            setSiteOperationControl(result);
            return result;
        },
        "doc"
    );
    let location = useLocation();

    function killSite() {
        if (checkIfNullOrUndefined(siteOperationControl)) {
            return true;
        }
        return siteOperationControl[KILL_SWITCH];
    }

    useEffect(() => {
        const page = location.pathname;
        document.body.classList.add("is-loaded");
        childRef?.current?.init();
        trackPage(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            {killSite() === true ? (
                <span style={{ fontSize: "32px" }}>
                    Site is under maintenance, thank you for your patience!
                </span>
            ) : (
                <ScrollReveal
                    ref={childRef}
                    children={() => (
                        <Routes>
                            <Route
                                path="/new_collection"
                                element={<CollectionPage />}
                            />
                            <Route
                                element={
                                    <PactContextProvider>
                                        <CrossChainContextProvider>
                                            <IsokoDialogContextProvider>
                                                <IsokoCollectionsContextProvider>
                                                    <UserContextProvider>
                                                        <LayoutDefault>
                                                            <Outlet />
                                                        </LayoutDefault>
                                                    </UserContextProvider>
                                                </IsokoCollectionsContextProvider>
                                            </IsokoDialogContextProvider>
                                        </CrossChainContextProvider>
                                    </PactContextProvider>
                                }
                            >
                                <Route path="/" element={<HomePage />} />

                                <Route
                                    path="/launchpad"
                                    element={<LaunchPad />}
                                />

                                <Route path="/userProfile/:userId">
                                    <Route index element={<UserProfile />} />
                                </Route>

                                <Route path="/listNft/:projectId">
                                    <Route
                                        path=":subCollectionId/:nftId"
                                        element={<NftSellPage />}
                                    />
                                    <Route
                                        path=":nftId"
                                        element={<NftSellPage />}
                                    />
                                </Route>

                                <Route path="/group/:projectId">
                                    <Route
                                        index
                                        element={<ProjectCollectionsPage />}
                                    />
                                </Route>

                                <Route element={<MarketplaceContextProvider />}>
                                    <Route path="/marketplace/:projectId">
                                        <Route
                                            index
                                            element={<Marketplace />}
                                        />
                                        {/* <Route path="group/:subCollectionId">
                                            <Route
                                                index
                                                element={<Marketplace />}
                                            />
                                            <Route
                                                path=":nftId"
                                                element={<NftProductPage />}
                                            />
                                        </Route> */}
                                        <Route
                                            path=":nftId"
                                            element={<NftProductPage />}
                                        />
                                    </Route>
                                    <Route path="marketplace/group/:projectId">
                                        {/* <Route
                                            index
                                            element={<ProjectCollectionsPage />}
                                        /> */}
                                        <Route path=":subCollectionId">
                                            <Route
                                                index
                                                element={<Marketplace />}
                                            />
                                            <Route
                                                path=":nftId"
                                                element={<NftProductPage />}
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route
                                    path="/mint/:projectId"
                                    element={<Mint />}
                                />
                                <Route
                                    path="*"
                                    element={<Navigate to="/" replace />}
                                />
                                {/* New Maret place */}
                                {/* <Route
                                            path="/market"
                                            element={<MarketPlace />}
                                        /> */}
                            </Route>
                        </Routes>
                    )}
                />
            )}
        </>
    );
};

export default App;

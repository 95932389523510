import { Box, Text } from "@chakra-ui/react";
import React from "react";
import CollectionCardGroup from "../Cards/CollectionCardGroup";

const Collections = ({ header, cardTypes, projects, cardClickCallback }) => {
    return (
        <Box pb="4.38rem" id="collections">
            <Box>
                <Text fontSize={["24px", null, "36px"]} fontWeight={"900"}>
                    {header}
                </Text>
            </Box>
            {/* Cards */}

            <Box mt="30px">
                {projects && (
                    <CollectionCardGroup
                        cardTypes={cardTypes}
                        cardConfigsObject={projects}
                        onClick={cardClickCallback}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Collections;

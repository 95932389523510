import { buildCollectionQuery, firestoreGetDocument, firestoreQueryCollection } from "../Firestore/FirestoreService";
import { getBlockHeightForChain } from "../pact/PactUtils";
import { BOXING_BADGER_PROJECT_NAME_KEY } from "../utils/Constants";
import { buildNftPath } from "../utils/dbUtils";
import { checkIfNullOrUndefined, getAmountByPercentage } from "../utils/utils";
import { getCollectionNftsMetadata } from "./NftMetadataProvider";


function useMarmaladeCollectionProvider(collectionConfig) {
    const policy = collectionConfig["policy-info"];
    const projectName = policy["project-name"];
    const collectionName = policy["collection-name"];

    async function getNftImage(nftId) {
        let metadata = await getCollectionNftsMetadata(policy["collection-name"]);
        let image = metadata[nftId.split(collectionConfig["nft-id-separator-token"])[1]]["uri"]["data"];

        return image;
    }

    async function getNftListingData(nftId, payload) {
        let nftData = {};
        let getNetworkSpecificParameterFromProjectConfig = payload["getNetworkSpecificParameterFromProjectConfig"];
        let nftDataSnapshot = await firestoreGetDocument(buildNftPath(collectionConfig, nftId));
        let getNftTraitStats = payload["getNftTraitStats"];
        let mintProvider = payload["mintProvider"];

        nftData["rarity_level"] = nftDataSnapshot["nft-metadata"]["datum"]["datum"]["rarity_level"];
        nftData["nft-uri"] = nftDataSnapshot["nft-metadata"]["uri"]["data"];
        nftData["name"] = nftDataSnapshot["nft-metadata"]["datum"]["datum"]["name"];
        nftData["formatted_attributes"] = getNftTraitStats(nftDataSnapshot["nft-metadata"]["datum"]["datum"]["attributes"]);

        nftData["status"] = nftDataSnapshot["status"];
        nftData["nft-id"] = nftDataSnapshot["nft-id"];
        nftData["nft-id-on-chain"] = nftDataSnapshot["nft-id-on-chain"];
        nftData["chain-id"] = nftDataSnapshot["chain-id"];
        nftData["current-owner"] = nftDataSnapshot["current-owner"];
        nftData["listing-details"] = nftDataSnapshot["listing-details"];
        nftData["price"] = nftDataSnapshot["listing-details"] && nftDataSnapshot["listing-details"]["price"];

        // nftData["rarity_score"] = nftDataSnapshot["nft-metadata"]["datum"]["datum"]["rarity_score"].nftScore(3);
        // nftData["rarity_rank"] = "COMMON";
        // nftData["rarity_level"] = nftData["datum"]["datum"]["rarity_level"];

        if (collectionName === BOXING_BADGER_PROJECT_NAME_KEY) {
            let formattedSkills = {};
            let nftSkills = nftDataSnapshot["nft-metadata"]["datum"]["datum"]["skills"];
            nftSkills.forEach((skillObject) => {
                let skillFrequency = mintProvider.getSkillDistribution(
                    skillObject["id"],
                    skillObject["name"]
                );
                formattedSkills[skillObject["name"]] = {
                    name: skillObject["name"],
                    frequency: skillFrequency,
                };
            });
            nftData["formatted_attributes"] = {
                ...nftData["formatted_attributes"],
                extras: {
                    collection_skills: getNetworkSpecificParameterFromProjectConfig(projectName, collectionName, "collection_skills"),
                    skills: formattedSkills,
                    description: nftDataSnapshot["nft-metadata"]["datum"]["datum"]["description"],
                },
            };
        }

        return nftData;
    }

    async function getCollectionFloor() {
        let floor = null;
        let currentHeight = await getBlockHeightForChain(policy["chain-id"])
        let docPath = `projects/${projectName}/nft-collections/${collectionName}/NFTs`;

        // let conditions = ["listing-details.price", "!=", null, "&&", "listing-details.timeout", ">", currentHeight];
        let conditions = ["listing-details.price", "!=", null];
        floor = (await firestoreQueryCollection(docPath, "listing-details.price", conditions, 1))[0];

        if (!checkIfNullOrUndefined(floor)) {
            floor = floor["listing-details"]["price"];
        } else {
            floor = null;
        }
        return floor;
    }

    function getMarketplaceCollectionDetails(payload) {
        let collMetadata = {};

        collMetadata["total_supply"] = payload["getTotalSupply"]();
        collMetadata["collection_name"] = payload["getCollectionName"]();
        collMetadata["collection_traits"] = payload["getCollectionTraits"]();
        collMetadata["collection_trait_map"] = payload["getTraitCountMap"]();

        collMetadata = {
            ...collMetadata,
            ...collectionConfig
        }

        return collMetadata;
    }

    async function processUserNftMaps(nftMap, fullMap) {
        nftMap.forEach((entry) => {
            processUserNftMap(entry, fullMap)
        });

        return nftMap;
    }

    async function processUserNftMap(map, fullMap) {
        let nftCount = 0;
        for (const [user, nfts] of Object.entries(map)) {
            if (Object.values(nfts).length === 0) {
                continue;
            }
            let nftsNew = {};
            if (!fullMap[user]) {
                fullMap[user] = {};
            }
            fullMap[user] = {
                ...fullMap[user],
                ...nfts
            }
        }
        return fullMap;
    }

    function sortNfts(nftsList, currentHeight) {
        let unlistedNfts = null;
        let listedNfts = null;

        unlistedNfts = nftsList.filter((nft) => {
            return (
                checkIfNullOrUndefined(nft["listing-details"]) ||
                checkIfNullOrUndefined(nft["listing-details"]["price"]) ||
                (
                    !checkIfNullOrUndefined(nft["listing-details"]) &&
                    nft["listing-details"]["timeout"] < currentHeight
                )
            )
        });

        listedNfts = nftsList.filter((nft) => {
            return (
                !checkIfNullOrUndefined(nft["listing-details"]) &&
                !checkIfNullOrUndefined(nft["listing-details"]["price"]) &&
                nft["listing-details"]["timeout"] > currentHeight
            );
        });

        return {
            listed: listedNfts,
            unlisted: unlistedNfts
        }
    }

    return {
        sortNfts,
        getNftImage,
        getNftListingData,
        getCollectionFloor,
        processUserNftMaps,
        getMarketplaceCollectionDetails
    }
}

export default useMarmaladeCollectionProvider;
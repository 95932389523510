import { checkIfNullOrUndefined } from "./utils";

function buildNftPath(collectionConfig, nftId, subCollectionId) {
    let projectName = collectionConfig["policy-info"]["project-name"];
    let collectionName = collectionConfig["policy-info"]["collection-name"];
    let path = `/projects/${projectName}/nft-collections/${collectionName}/NFTs/${nftId}`;
    
    if (!checkIfNullOrUndefined(subCollectionId)) {
        path = `/projects/${projectName}/nft-collections/${collectionName}/NFTs-${subCollectionId}/${nftId}`;
    }

    return path;
}

function buildCollectionPath(collectionConfig, subCollectionId = null) {
    let projectName = collectionConfig["policy-info"]["project-name"];
    let collectionName = collectionConfig["policy-info"]["collection-name"];
    let path = `/projects/${projectName}/nft-collections/${collectionName}/nft-map`;

    if (!checkIfNullOrUndefined(subCollectionId)) {
        path = path.concat(`${subCollectionId}`);
    }

    return path;
}

export {
    buildNftPath,
    buildCollectionPath
}
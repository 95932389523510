function useMarmaladeV2CollectionProvider(collectionConfig) {
    const policy = collectionConfig["policy-info"];
    const projectName = policy["project-name"];
    const collectionName = policy["collection-name"];

    function getMarketplaceCollectionDetails(payload) {
        let collMetadata = {};

        collMetadata["total_supply"] = payload["getTotalSupply"]();
        collMetadata["collection_name"] = payload["getCollectionName"]();
        collMetadata["collection_traits"] = payload["getCollectionTraits"]();
        collMetadata["collection_trait_map"] = payload["getTraitCountMap"]();

        collMetadata = {
            ...collMetadata,
            ...collectionConfig
        }

        return collMetadata;
    }

    return {
        getMarketplaceCollectionDetails
    }
}

export default useMarmaladeV2CollectionProvider;
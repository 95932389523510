import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    UnorderedList,
    ListItem,
    ListIcon,
    Stack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
    BsFillCalendarPlusFill,
    BsFillQuestionCircleFill,
} from "react-icons/bs";
import { AiOutlineClose, AiOutlineQuestionCircle } from "react-icons/ai";
import { FaDotCircle } from "react-icons/fa";

function CustomCalendar({ callback }) {
    const [date, setDate] = useState(new Date());
    const [dateSelected, setDateSelected] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [showHelperModal, setShowHelperModal] = useState(false);

    const hanldeShowHelperModal = () => {
        setShowHelperModal(!showHelperModal);
    };

    const handleShowDate = () => {
        setShowDate(!showDate);
    };

    const onChange = (newDate) => {
        setDate(newDate);
        setDateSelected(true);
        console.log(newDate);
        setShowDate(!showDate);

        callback && callback(newDate);
    };

    const formatDate = (date) => {
        return format(new Date(date), "do MMM yyyy");
    };
    const minDate = new Date();
    return (
        <Box mt="1.44rem" className="calenadaBtn" pos="relative" zIndex={1000}>
            <Flex gap="0.5rem" align="center">
                <>
                    {dateSelected ? (
                        <Flex
                            gap="1rem"
                            align={"center"}
                            cursor={"pointer"}
                            onClick={handleShowDate}
                        >
                            <Text
                                color="#BD3DF0"
                                fontSize={"1rem"}
                                fontWeight={"900"}
                                textDecor={"underline"}
                            >
                                Listing expires on {formatDate(date)}
                            </Text>
                            <Icon
                                as={BsFillCalendarPlusFill}
                                boxSize={"1rem"}
                                className="primaryColor"
                            />
                        </Flex>
                    ) : (
                        <Button
                            outline={"none"}
                            border="2px"
                            borderColor="#BD3DF0"
                            w="100%"
                            py="20px"
                            bgColor="transparent"
                            onClick={handleShowDate}
                            rounded={"full"}
                            maxW="17rem"
                            display={"flex"}
                            color="#BD3DF0"
                            gap="1rem"
                            _hover={{}}
                        >
                            Select withdraw date
                            <Icon
                                as={BsFillCalendarPlusFill}
                                boxSize={"1rem"}
                                className="primaryColor"
                            />
                        </Button>
                    )}
                </>

                <Box className="coloredIcon">
                    <Text
                        as="span"
                        fontSize="0.875rem"
                        fontWeight="500"
                        textDecor={"underline"}
                        color="#BC3CF0"
                        onClick={hanldeShowHelperModal}
                        cursor={"pointer"}
                    >
                        Why withdraw date?
                    </Text>
                    <Icon
                        as={BsFillQuestionCircleFill}
                        cursor={"pointer"}
                        onClick={hanldeShowHelperModal}
                        mt="0.3rem"
                        ml="0.38rem"
                    />
                </Box>
            </Flex>
            {showDate ? (
                <Box position={"absolute"} top="2.4rem">
                    <Calendar
                        onChange={onChange}
                        value={date}
                        view="month"
                        minDate={new Date(minDate.getTime() + 86400000)}
                    />{" "}
                </Box>
            ) : (
                ""
            )}

            {/* Helper modal */}
            {showHelperModal && (
                <Flex
                    zIndex={1000}
                    pos={"fixed"}
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    bgColor={"rgba(0,0,0,.8)"}
                    px="12px"
                    align="center"
                    justify={"center"}
                    onClick={hanldeShowHelperModal}
                >
                    <Box
                        w="100%"
                        maxW="32rem"
                        mx="auto"
                        p="2rem"
                        rounded={"2rem"}
                        bgColor={"#160D1A"}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Text fontSize="0.875rem" textAlign={"center"}>
                            {" "}
                            ISOKO helper
                        </Text>

                        <Flex align={"center"} justify="space-between">
                            <Text
                                fontSize={["", "", "2.25rem"]}
                                fontWeight={900}
                            >
                                Kadena Withdraw{" "}
                            </Text>

                            <Icon
                                as={AiOutlineClose}
                                boxSize={"1.5rem"}
                                cursor="pointer"
                                onClick={hanldeShowHelperModal}
                            />
                        </Flex>
                        <Box py="1rem">
                            <Text fontSize={["1.25rem"]} fontWeight={"700"}>
                                What is it?
                            </Text>
                            <Text fontSize={"0.875rem"} mt="0.5rem">
                                By default, the Kadena protocol requires for NFT
                                items to have a withdraw time.
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize={"1.25rem"} fontWeight={700} mt="">
                                How it works
                            </Text>

                            <UnorderedList
                                listStyleType="disc"
                                pl=".5rem"
                                mt="0.5rem"
                            >
                                <ListItem>
                                    Once you set a withdraw date, your NFT will
                                    be available for purchase by other
                                    collectors and traders until your set
                                    withdraw date.
                                </ListItem>
                                <ListItem>
                                    During the time your NFT is available for
                                    purchase (before your withdraw date passes),
                                    you will not be able to manually withdraw
                                    your NFT from the marketplace.{" "}
                                </ListItem>
                                <ListItem>
                                    Once your withdraw date has past, your NFT
                                    will no longer be available for purchase,
                                    and you can re-list it.
                                </ListItem>
                            </UnorderedList>
                        </Box>
                        <Flex justify={"flex-end"}>
                            <Button
                                mt="1rem"
                                outline={"none"}
                                border="2px"
                                borderColor="#BD3DF0"
                                w="100%"
                                py="20px"
                                px="1rem"
                                bgColor="#BC3CF0"
                                rounded={"full"}
                                maxW="fit-content"
                                display={"flex"}
                                color="white"
                                gap="1rem"
                                _hover={{}}
                                onClick={hanldeShowHelperModal}
                            >
                                Close
                            </Button>
                        </Flex>
                    </Box>
                </Flex>
            )}
        </Box>
    );
}

export default CustomCalendar;

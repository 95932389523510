import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import React, { useState } from "react";
import CustomPopover from "./CustomPopover";

const traitDummyData = [
    {
        id: 1,
        label: "Background",
        subMenu: [
            { id: 1, label: "Blue", number: "23", percent: "10.4%" },
            { id: 2, label: "Black", number: "23", percent: "10.4%" },
            { id: 3, label: "Gold", number: "23", percent: "10.4%" },
            { id: 4, label: "Yellow", number: "23", percent: "10.4%" },
            { id: 5, label: "Green", number: "23", percent: "10.4%" },
            { id: 6, label: "Dark", number: "23", percent: "10.4%" },
            { id: 7, label: "Brown", number: "23", percent: "10.4%" },
            { id: 8, label: "Purprle", number: "23", percent: "10.4%" },
            { id: 9, label: "Cyan", number: "23", percent: "10.4%" },
        ],
    },
    {
        id: 2,
        label: "Car",
        subMenu: [
            { id: 1, label: "Blue", number: "23", percent: "10.4%" },
            { id: 2, label: "Black", number: "23", percent: "10.4%" },
            { id: 3, label: "Gold", number: "23", percent: "10.4%" },
            { id: 4, label: "Yellow", number: "23", percent: "10.4%" },
        ],
    },
    {
        id: 3,
        label: "Book",
        subMenu: [
            { id: 4, label: "Yellow", number: "23", percent: "10.4%" },
            { id: 5, label: "Green", number: "23", percent: "10.4%" },
            { id: 6, label: "Dark", number: "23", percent: "10.4%" },
            { id: 7, label: "Brown", number: "23", percent: "10.4%" },
            { id: 8, label: "Purprle", number: "23", percent: "10.4%" },
            { id: 9, label: "Cyan", number: "23", percent: "10.4%" },
        ],
    },
];
const Traits = () => {
    return (
        <Box className="latestDesign">
            <Flex align="center" gap="1rem" justify="space-between">
                <Text fontSize="20px" fontWeight="900">
                    Traits
                </Text>

                {/* clear all  */}
                <Flex
                    as="button"
                    w="fit-content"
                    bgColor="#9E9E9E"
                    rounded="8px"
                    align="center"
                    px="4px"
                    py="2px"
                    gap="2px"
                >
                    <Text
                        textTransform={"capitalize"}
                        fontWeight="700"
                        lineHeight={"16px"}
                        fontSize="12px"
                    >
                        Clear all
                    </Text>{" "}
                    <Icon as={RxCross2} fontSize="12px" />
                </Flex>
            </Flex>

            {/* Trait global box */}
            <Box mt="12px" maxH="476px" h="476px" overflowY={"auto"}>
                {traitDummyData?.map((item) => {
                    return <TraitCard key={item} item={item} />;
                })}
            </Box>
        </Box>
    );
};

export default Traits;

const TraitCard = ({ item, ...props }) => {
    const [showTraits, setShowTraits] = useState(false);
    const ToggleDropDown = () => {
        setShowTraits(!showTraits);
    };

    const [selectedTraits, setSelectedTraits] = useState([
        // { id: 1, label: "blue" },
        // { id: 2, label: "blue" },
    ]);
    const handleSelectTrait = (newTrait) => {
        setSelectedTraits([...selectedTraits, newTrait]);
    };
    const handleRemoveSelected = (itemId) => {
        const currentTraits = selectedTraits;
        const fillteredTrait = currentTraits?.filter(
            (item) => item?.id !== itemId
        );

        setSelectedTraits(fillteredTrait);
    };

    return (
        <Box {...props} mt="7px">
            <Text fontSize="14px" fontWeight="500">
                {" "}
                {item?.label} ({item?.subMenu?.length}){" "}
            </Text>

            <Box pos="relative">
                {/* Trait toggler Button  */}
                <Flex
                    _hover={{ border: "1px solid #757575" }}
                    align="center"
                    justify="space-between"
                    bgColor="#424242"
                    gap="12px"
                    py="8px"
                    pl="8px"
                    pr="12px"
                    rounded={"8px"}
                    cursor="pointer"
                    border="1px"
                    borderColor={
                        selectedTraits?.length > 1 ? "#757575" : "transparent"
                    }
                    onClick={ToggleDropDown}
                >
                    {/* Toggle apperance of selectof submenu  */}
                    {selectedTraits?.length > 0 ? (
                        <Flex
                            align="center"
                            gap="4px"
                            maxW="80%"
                            flexWrap="wrap"
                        >
                            {/* selected menu boxes */}
                            {selectedTraits?.map((item) => {
                                return (
                                    <Flex
                                        key={item?.id}
                                        w="fit-content"
                                        bgColor="#9E9E9E"
                                        rounded="8px"
                                        align="center"
                                        px="4px"
                                        py="2px"
                                        gap="2px"
                                        flexShrink={0}
                                    >
                                        <Text
                                            textTransform={"capitalize"}
                                            fontWeight="700"
                                            lineHeight={"16px"}
                                            fontSize="12px"
                                        >
                                            {item?.label}
                                        </Text>{" "}
                                        <Icon
                                            as={RxCross2}
                                            fontSize="12px"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveSelected(item?.id);
                                            }}
                                        />
                                    </Flex>
                                );
                            })}
                        </Flex>
                    ) : (
                        <Text
                            fontSize="12px"
                            fontWeight={"500"}
                            color="#C2C2C2"
                        >
                            Select
                        </Text>
                    )}
                    <Icon as={BsChevronDown} fontSize="14px" />
                </Flex>

                <TraitSubCard
                    // key={item}
                    handleSelectTrait={handleSelectTrait}
                    ToggleDropDown={ToggleDropDown}
                    showTraits={showTraits}
                    item={item?.subMenu}
                />
            </Box>
        </Box>
    );
};

const TraitSubCard = ({
    item,
    showTraits,
    handleSelectTrait,
    ToggleDropDown,
    ...props
}) => {
    return (
        <Box
            pos="absolute"
            top="36px"
            left="0"
            right="0"
            bgColor="transparent"
            maxH="250px"
            // minH="250px"
            // overflowY={"auto"}
            rounded="8px"
            display={showTraits ? "block" : "none"}
            zIndex={"1000"}
            overflowY={"auto"}
        >
            {item?.map((item) => {
                return (
                    <Flex
                        key={item}
                        {...props}
                        bgColor="#424242"
                        _hover={{ bgColor: "#757575" }}
                        align="flex-start"
                        justify="space-between"
                        px="12px"
                        py="6px"
                        cursor="pointer"
                        onClick={() => {
                            ToggleDropDown();
                            handleSelectTrait(item);
                        }}
                    >
                        <Text fontSize="14px">{item?.label}</Text>
                        <Box textAlign="right">
                            <Text fontSize="12px">(23)</Text>

                            <CustomPopover
                                content={
                                    "10.4% of NFTs in collection have this trait"
                                }
                            >
                                <Text fontSize="12px" mt="2px" color="#C2C2C2">
                                    <i>{10.4}%</i>
                                </Text>
                            </CustomPopover>
                        </Box>
                    </Flex>
                );
            })}
        </Box>
    );
};

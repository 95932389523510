import {
    BOXING_BADGER_PROJECT_NAME_KEY,
    ARKADE_90S_BULLS_PROJECT_NAME_KEY,
    ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
    SECRET_GARDEN_OF_KADENA_GEN_0_PROJECT_NAME_KEY,
    KADENA_SKELLIES_PROJECT_NAME_KEY,
    BATTLE_HEROES_PROJECT_NAME_KEY,
    ARKADE_80S_BULLS_PROJECT_NAME_KEY,
    ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY,
    KDA_PUNKS_PROJECT_NAME_KEY,
    PACT_RATS_PROJECT_NAME_KEY,
    ISOKO_TEST_COLLECTION_NAME_KEY,
    SGK_WEAPONS_NAME_KEY,
    KAWAII_K9S_PROJECT_NAME_KEY,
    KISHU_KEN_PROJECT_NAME_KEY,
    LAZY_APES_PROJECT_NAME_KEY
} from "../utils/Constants";
import React from "react";

async function getCollectionNftsMetadata(collectionName) {
    let metadata = null;

    switch (collectionName) {
        case BOXING_BADGER_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case ARKADE_90S_BULLS_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case SECRET_GARDEN_OF_KADENA_GEN_0_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case KADENA_SKELLIES_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case BATTLE_HEROES_PROJECT_NAME_KEY:
            console.log(`../project_config_files/${collectionName}_metadata`)
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            console.log(metadata.default)
            break;
        case ARKADE_80S_BULLS_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case KDA_PUNKS_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case PACT_RATS_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case ISOKO_TEST_COLLECTION_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case SGK_WEAPONS_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case KAWAII_K9S_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case KISHU_KEN_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
        case LAZY_APES_PROJECT_NAME_KEY:
            metadata = await import(`../project_config_files/${collectionName}_metadata`);
            break;
    }

    return metadata.default;
}

export {
    getCollectionNftsMetadata
}
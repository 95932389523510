import React, { useCallback, useEffect, useState } from "react";

import { Box, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

const WINDOW_SIZE = 5;

function getWindowSize(numPages) {
  if (numPages?.length < WINDOW_SIZE) {
    return numPages.length;
  }
  return WINDOW_SIZE;
}

const CollectionPagination = ({ numPages, currentBatch, setCurrentBatch }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [windowSize, setWindowSize] = useState(() => getWindowSize(numPages));
  const [currentWindowTop, setCurrentWindowTop] = useState(windowSize);
  const [currentWindowBottom, setCurrentWindowBottom] = useState(0);

  const resetPage = useCallback(() => {
    if (currentBatch === 0) {
      let size = getWindowSize(numPages);
      setWindowSize(size);
      setCurrentPage(0);
      setCurrentWindowBottom(0);
      setCurrentWindowTop(size);
    }
  }, [currentBatch, numPages]);

  useEffect(() => {
    resetPage();
  }, [resetPage]);

  const handleCurrentPage = (i) => {
    setCurrentPage(i);
    setCurrentBatch(i);

    if (numPages.length <= WINDOW_SIZE) {
      setCurrentWindowBottom(0);
      setCurrentWindowTop(numPages.length);
    } else {
      if (i === 0) {
        setCurrentWindowBottom(i);
        setCurrentWindowTop(i + windowSize);
      } else if (i === numPages.length - 1) {
        setCurrentWindowBottom(i - windowSize);
        setCurrentWindowTop(i);
      }
    }
  };

  const handlePrevious = (step) => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - step);
      setCurrentBatch(currentPage - step);

      if (currentPage - step < currentWindowBottom) {
        setCurrentWindowBottom(currentWindowBottom - windowSize);
        setCurrentWindowTop(currentWindowTop - windowSize);
      }
    }
  };

  const handleNext = (step) => {
    if (currentPage < numPages.length - 1) {
      setCurrentPage(currentPage + step);
      setCurrentBatch(currentPage + step);

      if (currentPage + step >= currentWindowTop) {
        setCurrentWindowBottom(currentWindowBottom + windowSize);
        setCurrentWindowTop(currentWindowTop + windowSize);
      }
    }
  };

  return (
    <Box>
      <Box py="3.94rem" bgColor="transparent">
        <HStack maxW="31.5rem" mx="auto" spacing={"0.5rem"}>
          <Flex
            py="0px"
            mx="1rem"
            gap="0.25rem"
            align="center"
            cursor="pointer"
            onClick={() => handlePrevious(1)}
          >
            <Icon as={FiChevronLeft} color="white" boxSize="1rem" />

            <Text fontSize="0.875rem" color="white" _highlighted={false}>
              Previous
            </Text>
          </Flex>

          {currentPage > windowSize &&
            < Text
              cursor="pointer"
              fontSize="0.875rem"
              p="0.62rem"

              fontWeight={700}
              bgColor={
                currentPage === 0
                  ? "#BC3CF0"
                  : "transparent"
              }
              rounded="1.25rem"
              color="white"
              onClick={() => handleCurrentPage(0)}
            >
              1
            </Text>
          }

          {currentPage > windowSize &&
            < Text
              cursor="pointer"
              fontSize="0.875rem"
              p="0.62rem"
              fontWeight={700}
              rounded="1.25rem"
              color="white"
            >
              ...
            </Text>
          }

          {numPages.slice(currentWindowBottom, currentWindowTop).map((i) => {
            return (
              <Text
                cursor="pointer"
                fontSize="0.875rem"
                p="0.62rem"
                key={i}
                fontWeight={700}
                bgColor={
                  currentPage === i
                    ? "#BC3CF0"
                    : "transparent"
                }
                rounded="1.25rem"
                color="white"
                onClick={() => handleCurrentPage(i)}
              >
                {i + 1}
              </Text>
            );
          })}


          {currentPage < numPages?.length - windowSize &&
            < Text
              cursor="pointer"
              fontSize="0.875rem"
              p="0.62rem"
              fontWeight={700}
              rounded="1.25rem"
              color="white"
            >
              ...
            </Text>
          }

          {numPages?.length > WINDOW_SIZE &&
            < Text
              cursor="pointer"
              fontSize="0.875rem"
              p="0.62rem"

              fontWeight={700}
              bgColor={
                numPages.length - 1 === currentPage
                  ? "#BC3CF0"
                  : "transparent"
              }
              rounded="1.25rem"
              color="white"
              onClick={() => handleCurrentPage(numPages.length - 1)}
            >
              {numPages.length}
            </Text>
          }

          <Flex
            py="0px"
            mx="1rem"
            gap="0.25rem"
            align="center"
            cursor="pointer"
            onClick={() => handleNext(1)}
          >
            <Text fontSize="0.875rem" color="white">
              Next
            </Text>

            <Icon as={FiChevronRight} color="white" boxSize="1rem" />
          </Flex>
        </HStack>
      </Box>
    </Box >
  );
};

export default CollectionPagination;
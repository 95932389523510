import { useContext } from "react";
import { toast } from "react-toastify";
import Pact from "pact-lang-api";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { PactContext } from "../pact/PactContextProvider";
import {
    createUniqueTransferCapabilities,
    getPactDecimal,
} from "../pact/PactUtils";
import {
    CHAIN_ID_KEY,
    COLLECTION_HAS_MARKETPLACE,
    COLLECTION_NAME_KEY,
    COLLECTION_TRAITS_KEY,
    COLLECTION_TRAIT_DISTRIBUTION_KEY,
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_PRICE,
    ISOKO_CONTRACTS,
    ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
    LOCAL_ACCOUNT_KEY,
    MAINNET_NETWORK_ID,
    MARMALADE_TYPE,
    MINT_AMOUNT_LIMIT_KEY,
    MINT_PHASES_KEY,
    NETWORK_ID,
    NFT_SUPPLY_KEY,
    SECRET_GARDEN_OF_KADENA,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
    TESTNET_NETWORK_ID,
} from "../utils/Constants";
import { checkIfNullOrUndefined, tryLoadLocal } from "../utils/utils";
import secret_garden_of_kadena_metadata from "../project_config_files/secret_garden_of_kadena_metadata";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";

const SECRET_GARDEN_OF_KADENA_CONTRACTS =
    "free.secret-garden-of-kadena-gen-1-nfts";
const SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS =
    "free.secret-garden-of-kadena-policy-gen0-v1";
const SECRET_GARDEN_OF_KADENA_BASE_URL =
    "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/";
const SECRET_GARDEN_OF_KADENA_CREATOR_WALLET =
    "k:67a044c7585344504de4d3ae116b866e5929031113ee24f7d48fa4013dd67c4c";
const SECRET_GARDEN_OF_KADENA_TESTNET_CREATOR_WALLET =
    "k:04e21d9dbe39517574a3772de910d606baa00a84359ba6c468206c76695650d4";
const SECRET_GARDEN_OF_KADENA_ADMIN_ADDRESS =
    "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const SECRET_GARDEN_OF_KADENA_FEE_PERCENTAGE = 0.03;
const SECRET_GARDEN_OF_KADENA_COLLECTION_NAME = "sgk-gen-1";
const SECRET_GARDEN_OF_KADENA_TESTNET_COLLECTION_NAME = "sgk-gen-1";

function useSecretGardenOfKadenaProvider() {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const { getNetworkSpecificParameterFromProjectConfig, getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);

    /**
     *
     ******************************************
     ********* Marketplace Functions **********
     ******************************************
     *
     */
    async function mint(amount, priceToPay, transactionCompleteCallback = null) {
        let priceToPayContract = 0.97 * priceToPay;
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let priceToPayLPFee = getTransactionFee(priceToPay);
        let totalPrice = priceToPay;

        let cantMint = await checkIfUserCantMint(pactContext.account.account);
        console.log(pactContext.account.account);
        console.log(cantMint);
        if (cantMint) {
            toast.error("Mint Window not Open for this Account");
            return;
        }

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk 
            ${amount} 
            "${SECRET_GARDEN_OF_KADENA_COLLECTION_NAME}" 
            "${pactContext.account.account}" 
            (read-keyset "user-ks"))`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk 
                ${amount} 
                "${SECRET_GARDEN_OF_KADENA_TESTNET_COLLECTION_NAME}" 
                "${pactContext.account.account}" 
                (read-keyset "user-ks"))`;
        }
        let pactCodeToTransferFunds = `(coin.transfer "${pactContext.account.account
            }" "${ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS}" ${parseFloat(
                priceToPayLPFee
            ).toFixed(8)})`;
        pactCode = pactCode + pactCodeToTransferFunds;

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        const capDataList = [
            {
                role: "Launchpad fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
                amount: { decimal: parseFloat(priceToPayLPFee.toFixed(8)) }, //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
                // amount: getPactDecimal(parseFloat(priceToPayLPFee)), //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
            },
            {
                role: "Project mint fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver:
                    NETWORK_ID === MAINNET_NETWORK_ID
                        ? SECRET_GARDEN_OF_KADENA_CREATOR_WALLET
                        : SECRET_GARDEN_OF_KADENA_TESTNET_CREATOR_WALLET,
                amount: { decimal: parseFloat(priceToPayContract.toFixed(8)) },
                // amount: getPactDecimal(priceToPayContract),
            },
        ];
        let caps = createUniqueTransferCapabilities(capDataList);
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Account Guard",
                "Account Guard Capability",
                `${NETWORK_ID === MAINNET_NETWORK_ID
                    ? SECRET_GARDEN_OF_KADENA_CONTRACTS
                    : SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS
                }.ACCOUNT-GUARD`,
                [accountDetails.account]
            )
        );

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = (
            <p>
                Recruiting {amount} Gnomes to Explore Kadena's Secret Garden for{" "}
                {parseFloat(totalPrice.toFixed(4))} KDA
            </p>
        );

        //For quickbuy
        let signers = [
            {
                publicKey: accountDetails.accountGuard.keys[0],
                clist: caps.map((cap) => {
                    return cap.cap;
                }),
            },
        ];
        let meta = Pact.lang.mkMeta(
            pactContext.account.account,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200
        );
        let bzCommand = JSON.parse(
            Pact.simple.exec.createCommand(
                signers,
                (Date.now() / 1000).toString(),
                pactCode,
                envData,
                meta,
                NETWORK_ID
            ).cmds[0]["cmd"]
        );
        let cmdPubKeyObj = {
            cmd: bzCommand,
            pubKey: accountDetails.accountGuard.keys[0],
        };

        let txData = {
            cmdType: "exec",
            cmd: cmd,
            bzCommand: cmdPubKeyObj,
            previewContent: previewContent,
            transactionMessage: `Recruiting ${amount} Gnome${amount > 1 ? "s" : ""
                } to Explore Kadena's Secret Garden for ${parseFloat(
                    totalPrice.toFixed(4)
                )} KDA`,
            callback: transactionCompleteCallback,
            errorHandler: transactionCompleteCallback,
            errorMessage: "",
            shouldSign: true,
        };

        quickBuy.shouldExecuteQuickBuy(txData, totalPrice, chainId);
    }

    async function mintBulk(amount) {
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 100 "${SECRET_GARDEN_OF_KADENA_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 100 "${SECRET_GARDEN_OF_KADENA_TESTNET_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`;
        }

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        let caps = [];
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "reserved",
                "reserved",
                "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-orchestrator.ADMIN-OR-COL-OWNER",
                [SECRET_GARDEN_OF_KADENA_COLLECTION_NAME]
            )
        );

        const previewContent = <p>Training {amount} Badger to box for KDA</p>;

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        pactContext.sendTransaction(
            cmd,
            previewContent,
            "",
            null,
            null,
            null,
            true
        );
    }

    /**
     * 
     **************************************************
     ********* Collection Provider Functions **********
     **************************************************
     *
     */
    async function transfer(receiver, nftId) {
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        let pactCode = `(marmalade.ledger.transfer-create "${nftId}" "${pactContext.account.account}" "${receiver}" (read-keyset "user-ks") 1.0)`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(marmalade.ledger.transfer-create "${nftId}" "${pactContext.account.account}" "${receiver}" (read-keyset "user-ks") 1.0)`;
        }

        const envData = {
            "user-ks": {
                keys: [receiver.split(":")[1]],
                pred: "keys-all",
            },
            account: receiver,
        };

        let caps = [];
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Transfer",
                "trasnfer",
                "marmalade.ledger.TRANSFER",
                [nftId, pactContext.account.account, receiver, 1.0]
            )
        );

        const previewContent = <p>Training Badger to box for KDA</p>;

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        pactContext.sendTransaction(
            cmd,
            previewContent,
            "",
            null,
            null,
            null,
            true
        );
    }

    async function transferNft(
        nftId,
        sender,
        receiver,
        transactionCompleteCallback
    ) {
        let pactCode = `(${SECRET_GARDEN_OF_KADENA_CONTRACTS}.transfer "${receiver}" "${nftId}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS}.transfer "${receiver}" "${nftId}")`;
        }

        let chainId = getChain();

        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        let envData = {
            "user-ks": {
                keys: [receiver.split(":")[1]],
                pred: "keys-all",
            },
            account: receiver,
        };

        let caps = [
            Pact.lang.mkCap(
                "Transfer Capability",
                "transfer",
                `coin.TRANSFER`,
                [nftId, sender, receiver, 1.0]
            ),
            Pact.lang.mkCap(
                "Verify your account",
                "Verify your account",
                `${NETWORK_ID === MAINNET_NETWORK_ID
                    ? SECRET_GARDEN_OF_KADENA_CONTRACTS
                    : SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS
                }.ACCOUNT_GUARD`,
                [sender]
            ),
            Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
        ];

        const cmd = {
            pactCode,
            caps,
            sender: sender,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = <p>Transferring token {nftId}</p>;

        let callback = transactionCompleteCallback;
        let errorHandler = transferNftErrorHandler;
        pactContext.sendTransaction(
            cmd,
            previewContent,
            `Transfering ${nftId}`,
            callback ?? (() => alert(`transfer succeeded`)),
            errorHandler ?? null,
            "",
            true
        );
    }

    function transferNftErrorHandler() { }

    async function getMintedTotal() {
        let pactCode = `(${SECRET_GARDEN_OF_KADENA_CONTRACTS}.get-minted-supply)`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS}.get-minted-supply)`;
        }

        let chainId = getChain();

        let mintedTotal = await queryBlockChainForData(pactCode, chainId);

        return mintedTotal;
    }

    function getPolicyInfo() {
        let policy = {
            "standard": MARMALADE_TYPE,
            "project-name": SECRET_GARDEN_OF_KADENA,
            "collection-name": SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            "policy-name": SECRET_GARDEN_OF_KADENA_CONTRACTS,
            "creator-wallet": SECRET_GARDEN_OF_KADENA_CREATOR_WALLET,
            "chain-id": getChain(),
            "nft-metadata": secret_garden_of_kadena_metadata
        };

        return policy;
    }

    async function checkIfUserCantMint(account) {
        let cantMint = false;
        let isInOgWindow = getIsInOgWindow();
        let isInPrivateWhitelist = getIsInPrivateWhitelist();
        let isInPublicWindow = getIsInPublicWindow();
        let accountIsOg = false;
        let accountHasWhitelist = false;
        console.log(isInPublicWindow);
        console.log(isInPrivateWhitelist);

        let accountWlData = await getAccountWhitelistData(account);
        console.log(accountWlData);
        console.log(account);

        if (accountWlData.role === "og-role") {
            accountIsOg = true;
            accountHasWhitelist = true;
        }

        if (!checkIfNullOrUndefined(accountWlData["wl-remaining"]["int"])) {
            if (accountWlData["wl-remaining"]["int"] > 0) {
                accountHasWhitelist = true;
            }
        } else if (accountWlData["wl-remaining"] > 0) {
            if (accountWlData["wl-remaining"] > 0) {
                accountHasWhitelist = true;
            }
        }

        if (isInOgWindow) {
            if (accountIsOg) {
                return false;
            }
        }
        if (isInPrivateWhitelist) {
            if (accountHasWhitelist) {
                return false;
            }
        } else if (isInPublicWindow) {
            return false;
        }

        return true;
    }

    function getIsInOgWindow() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();

        if (currentTime > Date.parse(mintPhases["og_sale"]["start_time"]) &&
            currentTime < Date.parse(mintPhases["og_sale"]["end_time"])) {
            return true;
        }
        return false;
    }

    function getIsInPrivateWhitelist() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();

        if (currentTime > Date.parse(mintPhases["private_wl"]["start_time"]) &&
            currentTime < Date.parse(mintPhases["private_wl"]["end_time"])) {
            return true;
        }
        return false;
    }

    function getIsInPublicWindow() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();
        console.log(mintPhases);
        if (currentTime > Date.parse(mintPhases["public_sale"]["start_time"])) {
            return true;
        }
        return false;
    }


    function getNextPrice() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            MINT_PHASES_KEY
        );
        let currentTime = Date.now();
        let privateSaleWindow = new Date(
            mintPhases["private_wl"]["start_time"]
        );
        let publicSaleWindow = new Date(
            mintPhases["public_sale"]["start_time"]
        );
        let price = 0;

        if (currentTime < privateSaleWindow) {
            price = NETWORK_ID === MAINNET_NETWORK_ID ? 13 : 13;
            return price;
        } else if (currentTime < publicSaleWindow) {
            price = NETWORK_ID === MAINNET_NETWORK_ID ? 17 : 17;
            return price;
        } else {
            price = NETWORK_ID === MAINNET_NETWORK_ID ? 20 : 20;
            return price;
        }
    }

    function getChain() {
        let chainId = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            CHAIN_ID_KEY
        );

        return chainId;
    }

    function getTotalSupply() {
        let totalSupply = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            NFT_SUPPLY_KEY
        );

        return totalSupply;
    }

    function getNftAmountLimit() {
        let nftAmountLimit = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            MINT_AMOUNT_LIMIT_KEY
        );

        return nftAmountLimit;
    }

    function getCollectionName() {
        let collectionName = getParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            COLLECTION_NAME_KEY
        );

        return collectionName;
    }

    function getNftImage(nftId) {
        let image = secret_garden_of_kadena_metadata[nftId]["uri"]["data"];

        return image;
    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            MINT_PHASES_KEY
        );

        return mintPhases;
    }

    function getTransactionFee(price) {
        let txFee = parseFloat(
            (price * SECRET_GARDEN_OF_KADENA_FEE_PERCENTAGE).toFixed(8)
        );
        return txFee;
    }

    function getRoyaltyFeeFromPrice(price) {

    }

    function getNftData(nftId) {
        let fullMetadataCopy = { ...secret_garden_of_kadena_metadata };
        let nftData = fullMetadataCopy[nftId];
        console.log(nftData);
        return nftData;
    }

    async function getMarketplaceFieldsForId() {
        return null;
    }

    async function getNftsForUser(account) {
        let chainId = getChain();

        let pactCode = `(${SECRET_GARDEN_OF_KADENA_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        }

        let userNfts = await queryBlockChainForData(pactCode, chainId);
        let formattedUserNftIds = userNfts.sort((nftA, nftB) => {
            let first = Date.parse(nftA["lock-change-time"]["timep"]);
            let second = Date.parse(nftB["lock-change-time"]["timep"]);

            return second - first;
        }).map((nft) => {
            let obj = {
                nftId: nft["token-id"].split(":")[1],
            };
            return obj;
        });

        return formattedUserNftIds;
    }

    async function getAccountWhitelistData(account) {
        let chainId = getChain();
        let pactCode = `(${SECRET_GARDEN_OF_KADENA_CONTRACTS}.get-account-whitelist-info "${account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${SECRET_GARDEN_OF_KADENA_TESTNET_CONTRACTS}.get-account-whitelist-info "${account}")`;
        }

        let response = await queryBlockChainForData(pactCode, chainId);

        return response;
    }

    async function getPhaseParticipationRequirement(account, phase) {
        let requirementDescription = "";
        let whitelistInfo = null;

        if (phase === "public_sale") {
            requirementDescription = "No Special Requirements";
            return requirementDescription;
        } else if (phase === "og_sale") {
            requirementDescription = "OG Role Required";
        } else if (phase === "private_wl") {
            requirementDescription = "OG Role or Whitelist Required";
        }

        if (account) {
            whitelistInfo = await getAccountWhitelistData(account);
        } else {
            return requirementDescription;
        }

        if (phase === "og_sale") {
            if (whitelistInfo["role"] === "og-role") {
                requirementDescription = `This Account is an OG`;
            } else {
                requirementDescription = "Account is not an OG";
            }
        }

        if (phase === "private_wl") {
            if (whitelistInfo["role"] === "og-role") {
                requirementDescription = `Account has unlimited whitelists!`;
            } else {
                requirementDescription = "Account is Not Whitelisted";

                if (!checkIfNullOrUndefined(whitelistInfo["wl-remaining"]["int"])) {
                    let wl = whitelistInfo["wl-remaining"]["int"];
                    if (wl > 0) {
                        requirementDescription = `Account has ${whitelistInfo["wl-remaining"]["int"]
                            } whitelist${wl > 1 ? "s" : ""}!`;
                    }
                } else if (whitelistInfo["wl-remaining"] > 0) {
                    let wl = whitelistInfo["wl-remaining"];
                    if (whitelistInfo["wl-remaining"] > 0) {
                        requirementDescription = `Account has ${whitelistInfo["wl-remaining"]
                            } whitelist${wl > 1 ? "s" : ""}!`;
                    }
                }
            }
        }

        return requirementDescription;
    }

    function getCollectionTraits() {
        let traitsList = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            COLLECTION_TRAITS_KEY
        );

        return traitsList;
    }

    function getTraitDistribution(trait, traitValue) {
        let traitCountMap = getParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            COLLECTION_TRAIT_DISTRIBUTION_KEY
        );
        let totalSupply = getTotalSupply();
        // let traitFrequency = (traitCountMap[trait][traitValue] / traitCountMap[trait]["total"] * 100.0).toFixed(2);
        let traitFrequency = (
            (traitCountMap[trait][traitValue] / totalSupply) *
            100.0
        ).toFixed(2);

        return traitFrequency;
    }

    function formatNftTraits(nftTraits) {
        let formattedTraits = {};
        let traitsList = getCollectionTraits();

        nftTraits.forEach((traitObject) => {
            let traitFrequency = getTraitDistribution(traitObject["trait_type"], traitObject["value"]);

            formattedTraits[traitObject["trait_type"]] = {
                name: traitObject["value"],
                frequency: traitFrequency
            };
        });

        return formattedTraits;
    }

    function formatNftTraitsInNftDataObject(nftData) {
        let formattedTraits = {};
        let formattedSkills = {};
        let traitsList = getCollectionTraits();
        let nftTraits = nftData["datum"]["datum"]["attributes"];
        let nftScore = nftData["datum"]["datum"]["rarity_score"];
        console.log(nftData);

        nftTraits.forEach((traitObject) => {
            let traitFrequency = getTraitDistribution(
                traitObject["trait_type"],
                traitObject["value"]
            );

            formattedTraits[traitObject["trait_type"]] = {
                name: traitObject["value"],
                frequency: traitFrequency,
            };
        });

        console.log(formattedTraits);

        let formattedNftData = {
            id: nftData["datum"]["datum"]["id"],
            name: nftData["datum"]["datum"]["name"],
            attributes: formattedTraits,
            // image: nftData["datum"]["datum"]["thumbnailUrl"],
            image: nftData["uri"]["data"],
            rarity_rank: "COMMON",
            rarity_level: nftData["datum"]["datum"]["rarity_level"],
            rarity_score: nftScore.toFixed(3),
            collection_traits: getCollectionTraits(),
            extras: {
                description: nftData["datum"]["datum"]["description"],
            },
        };

        return formattedNftData;
    }

    function formatNftDataForNftPopup(nftId) {
        let data = getNftData(nftId);
        let formattedData = formatNftTraitsInNftDataObject(data);

        return formattedData;
    }

    function getAllNftsMetadata() {
        let fullCollectionMetadata = secret_garden_of_kadena_metadata;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            fullCollectionMetadata = secret_garden_of_kadena_metadata;
        }

        return fullCollectionMetadata;
    }

    return {
        mint,
        mintBulk,
        transfer,
        getNextPrice,
        getChain,
        getPolicyInfo,
        getNftData,
        getTotalSupply,
        getMintedTotal,
        getTransactionFee,
        getNftAmountLimit,
        getNftsForUser,
        getCollectionName,
        getNftImage,
        getMintPhases,
        getAccountWhitelistData,
        getPhaseParticipationRequirement,
        formatNftTraits,
        formatNftTraitsInNftDataObject,
        formatNftDataForNftPopup,
        getAllNftsMetadata,
        getMarketplaceFieldsForId
    };
}

export { useSecretGardenOfKadenaProvider };

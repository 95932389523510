import { Box, Text, Button, Flex } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { NftCardGroup } from "../Cards/NftCardGroup";

const More = ({ projectId, collectionName, nftsInCollection, onClick }) => {
    return (
        <Box>
            <Text fontSize="36px" fontWeight="700" mt="1.25rem">
                More from this collection
            </Text>

            <Box className="upcomingCards" mt="50px"></Box>
            {nftsInCollection.length > 0 ? (
                <NftCardGroup
                    currentBatch={0}
                    collectionName={collectionName}
                    nftsInCollection={nftsInCollection}
                    onClick={onClick}
                    limit={5}
                    moveUp={true}
                />
            ) : (
                <Flex align="center" justify="center">
                    <Text fontSize="20px" fontWeight="700">
                        No NFTs found
                    </Text>
                </Flex>
            )}
            {/* <Box mt="50px" align="right">
                <Link to={`/marketplace/${projectId}`}>
                    <Button
                        _hover={{}}
                        _active={{}}
                        focus={{}}
                        bgColor="#BC3CF0"
                        rounded="full"
                        color="white"
                        w="100%"
                        maxW="329px"
                    >
                        View full collection
                    </Button>
                </Link>
            </Box> */}
        </Box>
    );
};

export default More;

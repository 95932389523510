import React from "react";
import ScreenSize from "../layouts/ScreenSize";
import dummyCar from "../../src/assets/images/dummyCar.svg";
import { useNavigate } from "react-router-dom";
import LaunchPadCard from "../Cards/LaunchPadCard";
import { Box, Text } from "@chakra-ui/react";
import { BOXING_BADGER, ISLANDS_OF_DAMAYA, KADCARS_PROJECT_NAME_KEY, SECRET_GARDEN_OF_KADENA } from "../utils/Constants";

const Upcoming = ({ projects }) => {
    const navigate = useNavigate();

    function handleCardClick(collectionConfig) {
        navigate(
            "/mint/" +
            collectionConfig["policy-info"]["project-name"] + "-" +
            collectionConfig["policy-info"]["collection-name"] + "/"
        );
    }

    return (
        <ScreenSize>
            <div className="Upcoming">
                <Text fontSize="36px" fontWeight="700">
                    Upcoming Mints
                </Text>

                <Box mt="30px" className="upcomingCards">
                    {
                        projects && projects.map((collectionConfig) => {
                            return (
                                <LaunchPadCard
                                    key={collectionConfig["policy-info"]["project-name"]}
                                    clickable={collectionConfig["policy-info"]["project-name"] === KADCARS_PROJECT_NAME_KEY ? true : false}
                                    type={"startingSoon"}
                                    projectConfig={collectionConfig}
                                    cardCallback={() => handleCardClick(collectionConfig)}
                                />
                            );
                        })
                    }
                </Box>
            </div>
        </ScreenSize>
    );
};

export default Upcoming;
// const LaunchPadCard = ({ projectConfig }) => {
//     return (
//         <div className="kadCard">
//             <div className="">
//                 <img
//                     src={projectConfig["collection_thumbnail_image"]}
//                     alt="nftCard"
//                     width={"100%"}
//                     height="100%"
//                     className="cardImage"
//                 />
//             </div>
//             <div style={{ padding: "0px 16px" }}>
//                 <h4 className="kadcarsTitle"> { projectConfig['project-name'] } </h4>
//                 <p className="pre_title"> { projectConfig['project_description'] } </p>
//                 <div className="totalItems">
//                     <div className="cardDetails">
//                         <p>Total items</p>
//                         <p className="details_2">2400</p>
//                     </div>

//                     <div className="cardDetails  ">
//                         <p>Floor</p>
//                         <p className="details_2">15 KDA</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
